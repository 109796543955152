export const ContentPrizes = () => {
  return (
    <div>
      <h3>Prizes</h3>
      <p>
        The top players will be eligible for PGA TOUR Superstore gift cards. The prizes are as follows:
      </p>
      <ul>
        <li><strong>1st Place:</strong> $300 PGATSS Gift Card</li>
        <li><strong>2nd Place:</strong> $200 PGATSS Gift Card</li>
        <li><strong>3rd Place:</strong> $200 PGATSS Gift Card</li>
        <li><strong>4th – 10th Place:</strong> $100 PGATSS Gift Card</li>
      </ul>
      <p>
        Check the official rules for detailed information about prize distribution and eligibility.
      </p>
    </div>
  );
};
