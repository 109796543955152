import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import "../../App.scss";
import "./discover.scss";

import { Helmet } from "react-helmet";
// replace with the actual path to your image
import appLogo from "../../Group 1.png"; // replace with the actual path to your image
import { makeStyles as makeMuiStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import crossover_logo from "../../assets/nba.svg";
import ReactGA from "react-ga";
import { Analytics } from "@vercel/analytics/react";
import { Button, Container } from "@mui/material";
import Twitter from "../../assets/twitter.svg";
import TwitterWhite from "../../assets/twitter_white.svg";
import useTheme from "@mui/material/styles/useTheme";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { AutoTextSize } from "auto-text-size";
import FlipNumbers from "../FlipNumbers/index";
import crossover_trivia_logo from "../../assets/crossover_trivia.svg";
// import { useParallax } from "react-scroll-parallax";

import sports from "../../sports";
import {
  VerticalBarSeries,
  XYPlot,
  makeWidthFlexible,
  LabelSeries,
  XAxis,
  HorizontalGridLines,
} from "react-vis";
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";

<script defer src="/_vercel/insights/script.js"></script>;
<script
  defer
  src="https://go.metabet.io/js/global.js?siteID=crossovergrid"
></script>;

const SERVER_URL_NEW = "https://crossoverapi.com";
const FlexibleXYPlot = makeWidthFlexible(XYPlot);
// DiscoverQuizzes.jsx

const DiscoverQuizzes = () => {
  const placementName = "crossovergrid_sticky_footer";
  const publisher = "crossovergrid-com";
  const targeting = { key1: "value1", key2: "value2" };
  const theme = useTheme();
  const isDesktop = window.innerWidth >= 800;
  const [quizData, setQuizData] = useState([]);
  const [adRefresh, setAdRefresh] = React.useState(0);

  // const parallax = useParallax({
  //   speed: -15,
  // });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Define the desired order of sport IDs
        const desiredOrder = ["1", "7", "8", "12", "5", "3","10", "11", "6", "9"];
  
        // Filter the sports array to include only the desired sport IDs
        const filteredSports = sports.filter((sport) =>
          desiredOrder.includes(sport.id)
        );
  
        // Fetch quizzes data for the filtered sports
        const idsArray = filteredSports.map((sport) => sport.id);
        const response = await Axios.post(`${SERVER_URL_NEW}/quiz/current`, {
          sport_ids: idsArray,
        });
  
        console.log(response.data);
        setQuizData(response.data);
      } catch (error) {
        console.error(`Error fetching quiz data`, error);
      }
    };
  
    fetchData();
  }, []);
  
  


  const useStyles = makeMuiStyles((theme) => ({
    root: {
      flexGrow: 1,
      // padding: 2,  // 2 or '2px' or '2rem', as per your requirements
    },
    card: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    close: {
      position: "absolute",
      // right: 1,  // 1 or '1px' or '1rem', as per your requirements
      // top: 1,  // 1 or '1px' or '1rem', as per your requirements
      color: "#bdbdbd", // color from theme.palette.grey[500]
    },
    boxCard: {
      backgroundColor: theme.palette.background.gridCard,
      "&:hover": {
        backgroundColor: theme.palette.background.gridCardHover,
      },
    },
    boxCardSelected: {
      backgroundColor: theme.palette.background.gridCardSelect,
    },
    link: {
      color: theme.palette.text.link,
      fontSize: isDesktop ? "16px" : "12px",
      fontWeight: "600",
    },
    searchBox: {
      backgroundColor: theme.palette.background.blurCard,
      position: "relative",
      backdropFilter: "blur(10px)",
      color: theme.palette.text.primary,
    },
    searchInput: {
      color: theme.palette.text.primary,
    },
    result: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      cursor: "pointer",
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: theme.palette.background.resultHover,
      },
    },
    sponsorshipLink: {
      marginTop: "20px",
      color: theme.palette.text.link,
      textDecoration: "none",
      borderBottom: `1px solid ${theme.palette.text.link}`,
    },
    sponsorshipLinkUnderline: {
      color: theme.palette.text.link,
      textDecoration: "none",
    },
    remainingGuesses: {
      position: "absolute",
      right: isDesktop ? "min(calc(90vw - 400px), 5vw)" : 0,
      top: "50%",
    },
    barLabels: {
      fontSize: "12px",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
    barLabelsXAxis: {
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: "bold",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
  }));

  const classes = useStyles();

  function getNameById(id) {
    const sport = sports.find((sport) => sport.id === id);
    return sport ? sport.name : null;
  }

  return (
    <div className="app-container">
      {/* Navigation Bar - Minimalistic */}

      <div className="app-header">
        <nav className="navbar">
          <Container>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={crossover_trivia_logo}
                alt="Logo"
                className="navbar-logo"
              />
              <p
                style={{
                  marginLeft: "10px",
                  fontWeight: "bold",
                  fontSize: 20,
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                CROSSOVER TRIVIA
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            ></div>
          </Container>
        </nav>
      </div>

      <div
        style={{
          height: "375px",
          width: "100%",
          position: "relative",
          // marginTop: "-65px",
          marginBottom: 0,
        }}
        // ref={isDesktop ? parallax.ref : null}
      >
        <img
          src={require("../../assets/cover_img.png")}
          loading="lazy"
          height={"100%"}
          width={"100%"}
          style={{
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.2,
          }}
        />
        <Container
          style={{ position: "relative", zIndex: 1000, height: "100%" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
            }}
          >
            {/* header text */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              <Typography
                variant="h2"
                style={{
                  zIndex: 1000,
                  position: "relative",
                  fontWeight: "600",
                }}
              >
                Fresh trivia
              </Typography>
              <Typography
                variant="h4"
                style={{
                  zIndex: 1000,
                  position: "relative",
                  fontWeight: "600",
                }}
              >
                Served daily
              </Typography>
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "#A2E5B2",
                  marginTop: 15,
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 20,
                  alignSelf: "flex-start",
                }}
              >
                <Typography
                  style={{
                    padding: 0,
                    textAlign: "center",
                    color: "#484848",
                    fontWeight: "bold",
                  }}
                >
                  Over 5,000,000 games played!
                </Typography>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="discover-outer">
          <div className="discover-container">
            {/* Quiz Sections */}
            {Object.entries(quizData).map(([sport, quizzes]) => (
              <section key={sport} className="sport-section">
                <h2 style={{ marginLeft: "20px" }}>
                  {getNameById(sport).toUpperCase()}
                </h2>
                <div className="quizzes-slider">
                  {quizzes.map((quiz, index) => (
                    <>
                      {index === 0 && (
                        <div style={{ width: "20px", height: "20px" }}></div>
                      )}
<Link
  key={quiz.quiz_id}
  to={`../quiz/${quiz.grid_id}/${quiz.box_number}`}
  state={{ sport_id: quiz.sport_id }}
  className="quiz-card"  /* ← Ensure this class is used */
>
                        <div className="diagonal-container">
                          <div className="diagonal-left">
                            <img
                              src={quiz.img_left}
                              loading="lazy"
                              // src={require("../../assets/nba_silhouette.png")}
                              alt="Left"
                              className="left-image"
                              style={{
                                width: "55%",
                                height: "70%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                zIndex: 200,
                                objectFit: "contain",
                              }}
                            />
                            <img
                              src={quiz.img_left}
                              // src={require("../../assets/nba_silhouette.png")}
                              alt="Left"
                              className="left-image-background"
                              style={{ opacity: 0.25 }}
                              loading="lazy"
                            />
                          </div>
                          <div className="diagonal-right">
                            <img
                              src={quiz.img_right}
                              // src={require("../../assets/nba_silhouette.png")}
                              alt="Right"
                              className="right-image-background"
                              style={{ opacity: 0.25 }}
                              loading="lazy"
                            />
                            <img
                              src={quiz.img_right}
                              // src={require("../../assets/nba_silhouette.png")}
                              alt="Right"
                              className="right-image"
                              style={{
                                width: "55%",
                                height: "70%",
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                zIndex: 200,
                                objectFit: "contain",
                              }}
                              loading="lazy"
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              right: 0,
                              backgroundColor: "#00000080",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              backdropFilter: "blur(5px)",
                            }}
                          >
                            <Typography
                              style={{
                                marginLeft: "10px",
                                color: "#ffffff",
                                fontWeight: "600",
                                fontSize: isDesktop ? "14px" : "12px",
                              }}
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                              }}
                            >
                              {`${quiz.team_left} & ${quiz.team_right}`}
                            </Typography>
                          </div>
                        </div>
                      </Link>
                    </>
                  ))}
                </div>
              </section>
            ))}
          </div>
        </div>
      </Container>
      {/* <div className="newsletter-embed">
        <iframe
          title="Newsletter Signup"
          src="https://embeds.beehiiv.com/cad8a297-9171-4e31-8789-a71b0b3e1115"
          data-test-id="beehiiv-embed"
          width="100%"
          height="320"
          frameborder="0"
          scrolling="no"
          style={{
            borderRadius: "12px",
            border: "0px",
            marginBottom: "16px",
          }}
        />
      </div> */}
      <i>
        Please note: CROSSOVER SPORTS ENTERTAINMENT LLC dba Crossover Grid does
        not own any of the team, league or event logos depicted within this
        site. All sports logos contained within this site are properties of
        their respective leagues, teams, ownership groups and/or organizations.
      </i>
      <br />
      <div>
        <span
          className={`${classes.sponsorshipLink} ${classes.firstLink}`}
          style={{ marginRight: "20px" }}
        >
          <a
            className={classes.sponsorshipLinkUnderline}
            href="mailto:sponsorships@crossovergrid.com"
          >
            Advertise with us
          </a>
        </span>
        <span
          className={`${classes.sponsorshipLink} ${classes.firstLink}`}
          style={{ marginRight: "20px" }}
        >
          <a
            className={classes.sponsorshipLinkUnderline}
            href="mailto:feedback@crossovergrid.com"
          >
            Feedback & Support
          </a>
        </span>
        <span
          className={classes.sponsorshipLink}
          style={{ marginRight: "20px" }}
        >
          <a className={classes.sponsorshipLinkUnderline} href="/privacypolicy">
            Privacy Policy
          </a>
        </span>
      </div>
      <div>
        <span
          className={classes.sponsorshipLink}
          style={{ marginRight: "20px" }}
        >
          <a
            className={classes.sponsorshipLinkUnderline}
            onclick="window.__uspapi('displayUspUi');"
          >
            Do Not Sell My Personal Information
          </a>
        </span>
        <span className={classes.sponsorshipLink}>
          <a
            className={classes.sponsorshipLinkUnderline}
            onclick="window.__tcfapi('displayConsentUi', 2, function() {} );"
          >
            Change Consent
          </a>
        </span>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          textAlign: "center",
        }}
      >
        <FreestarAdSlot
          publisher={publisher}
          placementName={placementName}
          targeting={targeting}
          channel="custom_channel"
          adRefresh={adRefresh}
          onNewAdSlotsHook={(placementName) =>
            console.log("creating ad", placementName)
          }
          onDeleteAdSlotsHook={(placementName) =>
            console.log("destroying ad", placementName)
          }
          onAdRefreshHook={(placementName) =>
            console.log("refreshing ad", placementName)
          }
        />
      </div>
      <footer style={{ marginBottom: 150 }}></footer>
    </div>
  );
};

export default DiscoverQuizzes;
