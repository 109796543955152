import React from "react";
import Paper from "@mui/material/Paper";
import useTheme from "@mui/material/styles/useTheme";

export default ({ children, styles }) => {
  const theme = useTheme();
  return (
    <Paper
      style={{
        width: "80%",
        maxWidth: 500,
        margin: "auto",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: theme.palette.background.paper,
        ...styles,
      }}
    >
      {children}
    </Paper>
  );
};
