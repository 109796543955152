
import { yellow } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './module.ConnectionsGame.css';
import crossover_logo from "../../assets/nba.svg";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Select from "@mui/material/Select"; // Import Select component for dropdown
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem for dropdown items


const baseURL = "crossovergrid.com/NBA/connections";
const sports = [
  { id: '1', name: 'Basketball' },
  { id: '2', name: 'Football' },
  // Add more sports as needed
];

// Function to shuffle array items
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
const ConnectionsGame = () => {
  const [currentDate, setCurrentDate] = useState('');
  const [dates, setDates] = useState([]);
  const [currentGameData, setCurrentGameData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [foundCategories, setFoundCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [gameEndedSuccessfully, setGameEndedSuccessfully] = useState(false);
  const [categories, setCategories] = useState([]); // Initialize to empty or default
  const maxAttempts = 5;
  const [hintsUsed, setHintsUsed] = useState(categories.map(() => ({ bigHint: false, smallHint: false })));
  const [howToPlayOpen, setHowToPlayOpen] = useState(false);
  const [gameHasEnded, setGameHasEnded] = useState(false);
  const [theme, setTheme] = useState('light'); // default theme
  const [page_name, setPageName] = useState(""); // Define page_name state
  const [sport_id, setSportId] = useState(""); // Define sport_id state
  const currentSport = sports.find((sport) => sport.id === sport_id.toString());
  
  const onAdRefresh = () => {
    // Example of ad refresh logic
    console.log('Ads refreshed');
    // Implement your actual ad refresh logic here
  };
  useEffect(() => {
    const generateDateList = async () => {
      try {
        const context = require.context('./games', false, /\.json$/);
        const fileNames = context.keys();
        const today = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }));
        const todayFormatted = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

        const dateList = fileNames.map((fileName) => fileName.match(/\d{4}-\d{2}-\d{2}/)[0])
                          .filter(date => date <= todayFormatted)
                          .sort((a, b) => b.localeCompare(a));

        setDates(dateList);
        
        const savedDate = localStorage.getItem('lastSelectedDate');
        const latestAvailableDate = savedDate && dateList.includes(savedDate) ? savedDate : dateList[0];
        setCurrentDate(latestAvailableDate);
        loadGameData(latestAvailableDate);
      } catch (error) {
        console.error("Error generating date list:", error);
        setError("Failed to generate date list.");
      }
    };

    generateDateList();
  }, []);

  useEffect(() => {
    onAdRefresh();  
  }, []);

  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${currentDate}`));
    if (storedState) {
      setAllItems(storedState.allItems || []);
      setSelectedItems(storedState.selectedItems || []);
      setFoundCategories(storedState.foundCategories || []);
      setAttempts(storedState.attempts || 0);
      setGuesses(storedState.guesses || []);
    } else if (currentGameData && currentGameData.categories) {
      const newCategories = currentGameData.categories;
      setCategories(newCategories);
      setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
      const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
      setAllItems(shuffledItems);
      setSelectedItems([]);
      setFoundCategories([]);
      setAttempts(0);
      setGuesses([]);
    }
  }, [currentDate, currentGameData]);



  useEffect(() => {
    if (currentGameData) {
      const newCategories = currentGameData.categories ? currentGameData.categories : [];
      setCategories(newCategories);
      setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
      const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
      setAllItems(shuffledItems);
      setSelectedItems([]);
      setFoundCategories([]);
      setAttempts(0);
      setGuesses([]);
    }
  }, [currentGameData]);

  useEffect(() => {
    if (foundCategories.length === categories.length) {
      handleEndGame(true); // Game ends successfully
    } else if (attempts >= maxAttempts) {
      handleEndGame(false); // Game ends unsuccessfully
    }
  }, [foundCategories, attempts]);

  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${currentDate}`));
    if (storedState) {
      setGuesses(storedState.guesses || []);
      setSelectedItems(storedState.selectedItems || []);
      setFoundCategories(storedState.foundCategories || []);
      setAttempts(storedState.attempts || 0);
    }
  }, [currentDate]);
  

  // Initial setup of game state based on selected date
useEffect(() => {
  const storedState = JSON.parse(localStorage.getItem(`gameState-${currentDate}`));
  if (storedState) {
    // Load and apply the stored game state
    setAllItems(storedState.allItems || []);
    setSelectedItems(storedState.selectedItems || []);
    setFoundCategories(storedState.foundCategories || []);
    setAttempts(storedState.attempts || 0);
    setGuesses(storedState.guesses || []);
    // Additional state restoration as necessary
  } else if (currentGameData && currentGameData.categories) {
    // Load new categories based on the selected date
    const newCategories = currentGameData.categories;
    setCategories(newCategories);

    // Update other states as necessary based on new game data
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    setAllItems(shuffledItems);

    // Reset game state to initial values
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setGuesses([]);
    setGameEndedSuccessfully(false);
    setGameHasEnded(false);
  }
}, [currentDate, currentGameData]); // Depend on currentDate and currentGameData

// Function to handle year range change and automatically close menu
useEffect(() => {
  onAdRefresh();  
}, []);

// Updated useEffect for handling category updates based on currentGameData
useEffect(() => {
  if (currentGameData) {
    // Assuming currentGameData has a structure from which categories can be extracted
    const newCategories = currentGameData.categories ? currentGameData.categories : [];
    setCategories(newCategories);

    // Update other states as necessary based on new game data
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    setAllItems(shuffledItems);

    // Reset game state to initial values
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setGuesses([]);
    setGameEndedSuccessfully(false);
    setGameHasEnded(false);
  }
}, [currentGameData]); // Depend on currentGameData

  useEffect(() => {
    const generateDateList = async () => {
      try {
        const context = require.context('./games', false, /\.json$/);
        const fileNames = context.keys();
        const today = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }));
        const todayFormatted = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

        const dateList = fileNames.map((fileName) => fileName.match(/\d{4}-\d{2}-\d{2}/)[0])
                          .filter(date => date <= todayFormatted) // Only include dates up to today
                          .sort((a, b) => b.localeCompare(a)); // Sort dates in descending order

        setDates(dateList);
        
        const savedDate = localStorage.getItem('lastSelectedDate');
        const latestAvailableDate = savedDate && dateList.includes(savedDate) ? savedDate : dateList[0];
        setCurrentDate(latestAvailableDate); // Use the latest available date
        loadGameData(latestAvailableDate);
      } catch (error) {
        console.error("Error generating date list:", error);
        setError("Failed to generate date list.");
      }
    };

    generateDateList();
  }, []);

  const loadGameData = async (date) => {
    setLoading(true);
    setError('');
    try {
      const gameData = await import(`./games/${date}.json`);
      setCurrentGameData(gameData.default || gameData);
    } catch (error) {
      console.error("Failed to load game data:", error);
      setError("Failed to load game data.");
      setCurrentGameData(null);
    } finally {
      setLoading(false);
    }
  };const handleDateChange = (e) => {
    const newDate = e.target.value;
    setCurrentDate(newDate);
    localStorage.setItem('lastSelectedDate', newDate);
    // Assuming loadGameData function is correctly updated to load data based on date
    loadGameData(newDate); 
  };
  
  // Updated useEffect for handling category updates based on currentGameData
  useEffect(() => {
    if (currentGameData) {
      // Assuming currentGameData has a structure from which categories can be extracted
      const newCategories = currentGameData.categories ? currentGameData.categories : [];
      setCategories(newCategories);
  
      // Update other states as necessary based on new game data
      setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
      const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
      setAllItems(shuffledItems);
  
      // Reset game state to initial values
      setSelectedItems([]);
      setFoundCategories([]);
      setAttempts(0);
      setGuesses([]);
      setGameEndedSuccessfully(false);
      setGameHasEnded(false);
    }
  }, [currentGameData]); // Depend on currentGameData
  
  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  
  }, []);

  // Omitting existing useEffects and functions for brevity...
// Assuming you're no longer using puzzle numbers but dates to load game data
useEffect(() => {
  // Attempt to load game state from localStorage based on currentDate
  const storedState = JSON.parse(localStorage.getItem(`gameState-${currentDate}`));
  if (storedState) {
    // Load and apply the stored game state
    // Ensure your stored state structure matches what you're expecting here
    setAllItems(storedState.allItems || []);
    setSelectedItems(storedState.selectedItems || []);
    setFoundCategories(storedState.foundCategories || []);
    setAttempts(storedState.attempts || 0);
    setGuesses(storedState.guesses || []);
    // Additional state restoration as necessary
  } else {
    // This block runs if no stored state exists for the currentDate
    if(currentGameData && currentGameData.categories) {
      const categories = currentGameData.categories; // Assuming categories are part of your gameData structure
      setCategories(categories);

      // Update hints based on loaded categories
      setHintsUsed(categories.map(() => ({ bigHint: false, smallHint: false })));
      
      // Shuffle items in the new categories and reset game state
      const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
      setAllItems(shuffledItems);
      resetGameState(false); // Pass false to indicate not to clear localStorage here
    }
  }
}, [currentDate, currentGameData]); // React to changes in currentDate or currentGameData

const resetGameState = (clearLocalStorage = true) => {
  setSelectedItems([]);
  setFoundCategories([]);
  setAttempts(0);
  setModalOpen(false);
  setGuesses([]);

  if (clearLocalStorage) {
    localStorage.removeItem(`gameState-${currentDate}`);
    localStorage.removeItem(`guesses-${currentDate}`); // Now using currentDate
  }
};
const saveGuessToLocalStorage = (guess) => {
  const key = `guesses-${currentDate}`; // Use currentDate
  const guesses = JSON.parse(localStorage.getItem(key)) || [];
  guesses.push(guess);
  localStorage.setItem(key, JSON.stringify(guesses));
};


  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  }, []);
    const updateFoundCategories = () => {
      let remainingItems = [...allItems];
    
      categories.forEach((category, index) => {
        const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
        const isCategoryCompletelySelected = selectedInCategory.length === category.items.length;
    
        if (isCategoryCompletelySelected && !foundCategories.includes(category)) {
          setFoundCategories(prevFoundCategories => [...prevFoundCategories, category]);
          setHintsUsed(hints => {
            const newHints = [...hints];
            newHints[index].found = true;
            return newHints;
          });
          remainingItems = remainingItems.filter(item => !category.items.includes(item));
        }
      });
    
      setAllItems(remainingItems);
    };
    
 
  // Add this useEffect hook after your existing useEffect hooks
  useEffect(() => {
    // Check if all categories have been found
    if (foundCategories.length === categories.length) {
      handleEndGame(true); // Game ends successfully
    } else if (attempts >= maxAttempts) {
      // Check if max attempts have been reached without finding all categories
      handleEndGame(false); // Game ends unsuccessfully
    }
  }, [foundCategories, attempts]); // Depend on foundCategories and attempts

  // Initial setup of game state
  useEffect(() => {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState();
  }, []);
  // Handle toggling between displaying item names and images
  const toggleDisplay = () => {
    setShowImages(prevState => !prevState);
  };
  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${currentDate}`));
    if (storedState) {
      setGuesses(storedState.guesses || []);
      setSelectedItems(storedState.selectedItems || []);
      setFoundCategories(storedState.foundCategories || []);
      setAttempts(storedState.attempts || 0);
      // Ensure modalOpen and other UI states are reset or set appropriately here
    }
    // Add currentDate to the dependency array to react to its changes
  }, [currentDate]);
  

// Emoji map for category colors
const colorEmojiMap = {
  'blue': '🟦',
  'red': '🟥',
  'orange': '🟧',
  'yellow': '🟨',
  'purple': '🟪',
  'green': '🟩',

};
const HowToPlayModal = ({ isOpen, onClose, sportName, howToPlayText, examples }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <h2>How to Play {sportName} Connections</h2>
        <button className="modal-close" onClick={onClose}>×</button>
        {/* Dynamic instructions based on the sport */}
        {howToPlayText.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        <h3>Category Examples</h3>
        {/* Dynamic examples based on the sport */}
        {examples.map((example, index) => (
          <p key={index}>{example.icon} {example.text}</p>
        ))}
        <p>Ready to put your {sportName.toLowerCase()} knowledge to the test? Enjoy {sportName} Connections!</p>
      </div>
    </div>
  );



  
};const StatsModal = ({
  isOpen,
  onClose,
  guesses,
  congratulations = false,
  currentGameData, // Use currentGameData directly
  sportEmoji,
  sportName,
  colorEmojiMap,
  baseURL = "https://crossovergrid.com"
}) => {
  if (!isOpen || !currentGameData) return null; // Also check for currentGameData

  // Adjust text to use relevant game data identifiers instead of currentDate
  const gameIdentifier = currentGameData.date; // Assuming currentGameData contains a date or similar identifier

  const safeGuesses = Array.isArray(guesses) ? guesses : [];

  const copyToClipboard = async () => {
    const textToCopy = `Crossover Connections ${sportEmoji} Date: ${gameIdentifier} ${baseURL}/\n` +
      safeGuesses.map(guess =>
        guess.map(color => colorEmojiMap[color] || '⬜').join('')
      ).join('\n');
    await navigator.clipboard.writeText(textToCopy);
    toast.success('Guesses copied to clipboard!');
  };

  // Similar adjustment for shareOnTwitter function...
  
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <h2>Crossover Connections {sportEmoji} Date: {gameIdentifier}</h2>
        {/* Modal content continues... */}
      </div>
    </div>
  );
};
useEffect(() => {
  if (currentGameData) {
    // Use a unique identifier within currentGameData to load guesses
    const gameIdentifier = currentGameData.date; // Example
    const storedGuessesKey = `guesses-${gameIdentifier}`;
    const storedGuesses = JSON.parse(localStorage.getItem(storedGuessesKey)) || [];
    setGuesses(storedGuesses);
  }
}, [currentGameData]); // Depend on currentGameData


// Handle item clicks to select or deselect items
const handleItemClick = (item) => {
  setSelectedItems(prevItems =>
    prevItems.includes(item) ? prevItems.filter(i => i !== item) : [...prevItems, item]
  );
};

const handleHintClick = (categoryIndex, hintSize) => {
  const category = categories[categoryIndex];
  if (hintSize === 'big') {
    toast.info(`The category is: ${category.category}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].bigHint = true;
      return newHints;
    });
  } else if (hintSize === 'small') {
    const miniHintItems = [category.items[0], category.items[1]]; // Take the first two items for the mini hint
    toast.info(`Two items in this category are: ${miniHintItems.join(' and ')}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].smallHint = true;
      return newHints;
    });
  }
};const handleSubmit = () => {
  // Dismiss all existing toasts before showing a new one
  toast.dismiss();

  // Prevent any submissions if the game has ended
  if (gameEndedSuccessfully || attempts >= maxAttempts) {
    toast.error("Game has ended. No more submissions allowed.");
    return;
  }

  // Check for the correct number of selected items
  if (selectedItems.length !== 4) {
    toast.error("Please select exactly 4 items.");
    return;
  }

  // Extract the colors of the selected items
  const selectedColors = selectedItems.map(item =>
    categories.find(cat => cat.items.includes(item)).color
  );

  // Check if the guess has already been made
  const currentGuessSorted = [...selectedItems].sort().join(',');
  const guessAlreadyMade = guesses.some(guess =>
    [...guess].sort().join(',') === currentGuessSorted
  );

  if (guessAlreadyMade) {
    toast.error("You've already made this guess. Try a different combination.");
    return;
  }

  // Save the guess in state and localStorage
  setGuesses(prevGuesses => [...prevGuesses, selectedColors]);
  saveGuessToLocalStorage(selectedColors);

  // Find if a complete category is matched
  const matchedCategory = categories.find(category =>
    selectedItems.every(item => category.items.includes(item)) &&
    category.items.every(item => selectedItems.includes(item))
  );

  // Check for incorrect guesses
  if (!matchedCategory) {
    const threeOfAKind = categories.some(category => {
      const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
      return selectedInCategory.length === 3 && !foundCategories.includes(category);
    });

    // Provide feedback based on the correctness of the guess
    if (threeOfAKind) {
      toast.info("3 of a kind. One Away");
    } else {
      toast.error("That's not a correct group. Try again!");
    }

    // Update the number of attempts
    setAttempts(prevAttempts => prevAttempts + 1);

    // Check if the game should end
    if (attempts + 1 >= maxAttempts) {
      handleEndGame(false);
    }
  } else {
    // Handle a successful category match
    updateFoundCategories();
    setSelectedItems([]); // Clear selection for a new guess
    toast.success("Category Found!"); // Notify the user of a successful match
  }
};

// Apply toast.dismiss() similarly in other functions where toast notifications are shown

const handleEndGame = (success) => {
  setGameEndedSuccessfully(success);
  setGameHasEnded(true); // Indicate that the game has ended
  setModalOpen(true); // Optionally open the stats modal immediately if you prefer
};


const handleResetGame = () => {
  if (window.confirm("Are you sure you want to reset the game? All progress will be lost.")) {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState(); // Function to reset game state
    // Also clear any relevant localStorage entries here
  }
};
const [showImages, setShowImages] = useState(false);
useEffect(() => {
  document.body.setAttribute('data-theme', theme);
}, [theme]);

return (
  <div className="connections-game">
   <nav className="navbar">
        <a
          href="https://crossovergrid.com"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img src={crossover_logo} alt="Logo" className="navbar-logo" />
          <p
            style={{
              marginLeft: "10px",
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            CROSSOVER GRID
          </p>
        </a>
        
                {/* Here is the FadeMenu component for the hamburger menu */}
                <IconButton onClick={() => (true)}>
                <IconButton onClick={() => setHowToPlayOpen(true)}>
  <HelpOutlineIcon />
</IconButton>
<HowToPlayModal isOpen={howToPlayOpen} onClose={() => setHowToPlayOpen(false)} />
</IconButton>
</nav>
<ToastContainer position="top-center" />
<h1>{currentSport?.name} Connections</h1> {/* Use currentSport.name safely */}
<Select
  value={currentDate} // Use currentDate instead of puzzleNumber
  onChange={handleDateChange} // Ensure this function updates currentDate appropriately
  displayEmpty
  inputProps={{ 'aria-label': 'Select game date' }}
>
  {dates.map((date) => ( // Assuming `dates` is an array of date strings
    <MenuItem key={date} value={date}>
      Game Date: {date}
    </MenuItem>
  ))}
</Select>
<StatsModal
  isOpen={modalOpen}
  onClose={() => setModalOpen(false)}
  guesses={guesses}
  gameEndedSuccessfully={gameEndedSuccessfully}
  currentGameData={currentGameData} // Pass the entire game data object if needed
  currentDate={currentDate} // Assuming you're tracking the currently selected date
  sportEmoji={currentSport?.emoji} // Example of how you might want to pass sport-specific info
  sportName={currentSport?.name} // Make sure `currentSport` is derived from your state
  colorEmojiMap={colorEmojiMap} // Pass this prop if it's relevant to your StatsModal
/>
{!gameHasEnded && (
  <>
<div className="items-container">
  {allItems.map((item, index) => {
    const category = categories.find(category => category.items.includes(item));
    const itemIndexInCategory = category.items.indexOf(item);
    const imageUrl = category.images[itemIndexInCategory];
    const isSelected = selectedItems.includes(item);
    
    const itemStyle = isSelected
    ? theme === 'dark'
      ? { backgroundColor: '#ffcc00', color: 'black' } // Dark mode: bright background, dark text
      : { backgroundColor: '#ddd', color: 'black' } // Light mode: light background, dark text
    : {};
  
  return (
    <div
      key={index}
      className={`item ${showImages ? 'item-showing-image' : ''} ${isSelected ? 'selected' : ''}`}
      onClick={() => handleItemClick(item)}
      aria-pressed={isSelected ? 'true' : 'false'}
      style={itemStyle} // Apply the inline styles
    >
      {showImages ? (
        <img src={imageUrl} alt={item} />
      ) : (
        // The text color will change based on the style conditionally set above
        <span>{item}</span>
      )}
    </div>
  );
})}
</div>

      </>
    )}
 <div className="controls">
 {!gameHasEnded && (
      <> 
      <button onClick={() => setSelectedItems([])} disabled={selectedItems.length === 0}>
        Deselect All
      </button>
      <button onClick={handleSubmit} disabled={gameEndedSuccessfully || attempts >= maxAttempts}>
        Submit
      </button>
      <button onClick={toggleDisplay}>
        {showImages ? "Show Item Names" : "Show Images"}
      </button> </>
   )}
      {/* Conditionally render the View Stats button if the game has ended */}

  </div>
  



    {gameHasEnded && (
        <button onClick={() => setModalOpen(true)}>
          View Stats
        </button>
      )}
   {gameHasEnded && (
      <div className="end-game-display">
        <p>{gameEndedSuccessfully ? "Congratulations! You've completed the game!" : "Game Over. Better luck next time!"}</p>
        <div className="correct-answers">
          <h2>Correct Answers:</h2>
          {categories.map((category, index) => (
            <div key={index} className="category-display" style={{ borderColor: category.color, borderWidth: 1, borderStyle: 'solid', borderRadius: '10px', margin: '.5px 0', padding: '2px' }}>
              <h3 style={{ textAlign: 'center', color: category.color, margin: '2px 0', borderRadius: '10px' }}>{category.category}</h3>
              <div style={{ display: 'flex', justifyContent: 'space-around', padding: '2px', flexWrap: 'wrap' }}>
                {category.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="category-item" style={{ margin: '0px', textAlign: 'center', maxWidth: '120px' }}>
                    <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '2px', width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto 0px', backgroundColor: '#fff' }}>
                      <img src={category.images[itemIndex]} alt={item} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', borderRadius: '8px' }} />
                    </div>
                    <p style={{ fontSize: '0.8em', margin: '0' }}>{item}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )}



{!gameHasEnded && (
      <> 
    {/* Status display section */}
    <div className="status">
      <p>Mistakes Remaining: {'⚫️'.repeat(maxAttempts - attempts) + '⚪️'.repeat(attempts)}</p>
      <p>Found Categories: {foundCategories.map(c => c.category).join(", ")}</p>
    </div>

    {/* Hints section */}
    <div className="hints-section">
      <h2>HINTS</h2>
      {categories.map((category, index) => {
        const isCategoryFound = foundCategories.includes(category);
        const categoryStyle = {
          borderColor: category.color,
          borderStyle: 'solid',
          borderWidth: '2px',
          backgroundColor: isCategoryFound ? category.color : 'transparent',
        };

        return (
          <div className="hint-category" key={index} style={categoryStyle}>
            <div className="category-name">{isCategoryFound ? category.category : `Category ${index + 1}`}</div>
            {!isCategoryFound && !hintsUsed[index].bigHint && (
              <div className="hints-buttons">
                <button onClick={() => handleHintClick(index, 'big')}>Big Hint</button>
                <button onClick={() => handleHintClick(index, 'small')}>Small Hint</button>
              </div>
            )}
            {isCategoryFound && (
              <div className="found-items">
                <p>Category Found!</p>
                <p>{category.items.join(", ")}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
    </>
   )}
    {/* Reset game button */}
    <button onClick={handleResetGame} className="reset-game-button">
      Reset Game
    </button>
  </div>
);
};

export default ConnectionsGame;