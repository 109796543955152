import React from "react";
import { makeStyles as makeMuiStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import prime_games from "../../../assets/prime_gaming_logo.png";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Backdrop from "@mui/material/Backdrop";
import useTheme from "@mui/material/styles/useTheme";
import Link from "@mui/material/Link";

const useStyles = makeMuiStyles((theme) => ({
  drawer: {
    width: "100vw",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "100%",
    maxWidth: "600px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  ctaSection: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100], // Toggle background color based on theme mode
    color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[900], // Toggle text color based on theme mode
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(2, 0),
    textAlign: 'center',
    width: '90%',
    maxWidth: '500px',
    boxShadow: theme.palette.mode === 'dark' ? '0px 4px 8px rgba(255, 255, 255, 0.1)' : '0px 4px 8px rgba(0, 0, 0, 0.05)', // Toggle shadow color based on theme mode
    alignSelf: 'center',
  },
  toolbar: theme.mixins.toolbar,
}));

const PrimeUnauth = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.drawerPaper}>
    <div className={classes.ctaSection}>
      <img
        src={prime_games}
        alt="Prime Gaming Logo"
        style={{
          width: "100px",
          filter: theme.palette.mode === "dark" ? "invert(1)" : "invert(0)",
        }}
      />
      <Typography variant="h6" style={{ margin: "10px", fontWeight: "bold" }}>
        Crossover Trivia
      </Typography>
      {/* Apply the custom font to the whole Typography component containing "Prime" */}
      <Typography
        variant="body1"
        style={{
          paddingLeft: "10px",
          
          paddingRight: "10px",
          maxWidth: "500px",
          textAlign: "center",
          fontFamily: "'font-prime', sans-serif" // Apply 'font-prime' font family directly

        }}
      >
        A daily bonus challenge, included with Prime at <b>no added cost</b>.
      </Typography>
      <Link
        href="https://gaming.amazon.com/web-games?ref_=SM_CrossoverGrid_IGP"
        target="_blank"
        rel="noopener"
      >
        <Button
          variant="contained"
          endIcon={<OpenInNewIcon />}
          style={{
            width: "calc(100% - 40px)",
            maxWidth: "500px",
            margin: "20px 0",
            fontFamily: "'font-prime', sans-serif" // Apply 'font-prime' font family directly

          }}
        >
          Get bonus trivia with Prime
        </Button>
        
      </Link>

    </div>
    <Link href="/" style={{ textDecoration: "none", marginBottom: 15,            fontFamily: "'font-prime', sans-serif" // Apply 'font-prime' font family directly
 }}>
        <Typography variant="caption">
          Don't have Prime? Go back to the grids.
        </Typography>
      </Link>
  </div>
);
};


export default PrimeUnauth;