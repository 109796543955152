import { memo } from "react";
import propsTypes from "prop-types";

import loadingComponent from "../LoadingComponent";

const Component = ({ sportId }) => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loadingComponent("Get ready", sportId)}
    </div>
  );
};

Component.propsTypes = {
  sportId: propsTypes.number.isRequired,
};

export const LoadingTicTacToe = memo(Component);
