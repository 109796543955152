// Timer.js
import React, { useEffect, useState } from "react";
import { useMultiplayerContext } from "../contexts/MultiplayerContext";

const TimerBar = ({ initialTime, isMyTurn }) => {
  const { isTimerRunning } = useMultiplayerContext();
  const [timer, setTimer] = useState(initialTime);
  const textStyle = {
    flex: 1,
    fontSize: "28pt",
    maxWidth: "110px",
    flexGrow: 2,
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 22,
  };

  useEffect(() => {
    let timerInterval;
    if (isTimerRunning) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => Math.max(prevTimer - 1, 0));
      }, 1000);
    }

    // Clear the interval when the component is unmounted or when the timer is stopped
    return () => clearInterval(timerInterval);
  }, [isTimerRunning]);

  useEffect(() => {
    setTimer(initialTime);
  }, [initialTime]);

  if (timer <= 5) {
    textStyle.color = "#CC5500";
  }
  return (
    <p style={textStyle} className="scoreboard-font">
      <span
        style={
          isMyTurn
            ? { opacity: 1, fontSize: "8pt" }
            : { opacity: 0.2, fontSize: "8pt", color: "black" }
        }
      >
        ◀
      </span>
      {timer}
      <span
        style={
          !isMyTurn
            ? { opacity: 1, fontSize: "8pt" }
            : { opacity: 0.5, fontSize: "8pt", color: "black" }
        }
      >
        ▶
      </span>
    </p>
  );
};
export default TimerBar;
