// Filename: UserInputComponent.js

import React, { useState, useEffect, useRef, useMemo } from "react";
// import GameController from "./GameController";
// import { Button, Typography, Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";

import { useNavigate, useSearchParams } from "react-router-dom";

import { makeStyles as makeMuiStyles } from "@mui/styles";

// import lockerRoomImage from "../assets/locker.jpg";
// import boardImage from "../assets/board.png";
// import supportedSports from "./supportedSports";
// import Lottie from "lottie-react";
import Navbar from "../Nav";
// import SportButton from "./SportButton";
// import Leaderboard from "./Leaderboard";
import Helmet from "react-helmet";
import GameInterface from "./GameInterface/GameInterface";
import { CrossoverGridTrivia } from "./CrossoverGridTrivia";
import crossover_logo from "../../assets/fc.svg";
import Twitter from "../../assets/twitter.svg";
import TwitterWhite from "../../assets/twitter_white.svg";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FadeMenu from "./FadeMenu";
import StatisticsModal from "./Modals/StatisticsModal";
import Backdrop from "@mui/material/Backdrop";
import { Checkbox, Container, Dialog, FormControlLabel } from "@mui/material";
import HowToPlayModal from "./Modals/HowToPlay"; // Import the modal component
import axios from "axios";
import "../../App.scss";
import { Link } from "react-router-dom";
import sports from "../../sports";

const formatDate = (dateString) => {
  // Manually splits the 'YYYY-MM-DD' string and formats it as 'Month DD, YYYY'
  const [year, month, day] = dateString.split("-");
  // Create a new date object using UTC methods
  const date = new Date(Date.UTC(year, month - 1, day));
  // Return the formatted date string
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC", // Explicitly set timeZone to UTC
  });
};
const {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} = require("unique-names-generator");

const CrossoverGridTriviaPage = () => {
  const [passwordValidated, setPasswordValidated] = useState(true);
  const [passwordInput, setPasswordInput] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const autoJoin = false;
  const imageOriginalHeight = 7900;
  const isDesktop = window.innerWidth >= 800;
  const windowHeight = window.innerHeight;
  const scalingFactor = windowHeight / imageOriginalHeight;
  const newY = isDesktop ? 250 * scalingFactor : 200 * scalingFactor;
  const isWaitlistMode = true;
  // Function to close the How to Play modal
  const handleCloseHowToPlay = () => {
    setShowHowToPlay(false);
  };

  // const [statsOpen, setStatsOpen] = useState(false);
  const [isPrimeAuthed, setIsPrimeAuthed] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false); // Used to toggle the How to Play modal
  const [rememberMe, setRememberMe] = useState(false);
  const [page_name, setPageName] = useState(""); // Define page_name state
  const [sport_id, setSportId] = useState(""); // Define sport_id state
  const currentSport = sports.find((sport) => sport.id === sport_id.toString());
  const [currentGameData, setCurrentGameData] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [dates, setDates] = useState([]);

  const theme = useTheme();
  const navigate = useNavigate();
  const signature = new URLSearchParams(window.location.search).get(
    "Signature"
  );

  useEffect(() => {
    const generateDateList = async () => {
      const context = require.context("./games", false, /\.json$/);
      const fileNames = context.keys();
      const today = new Date(
        new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
      );
      const todayFormatted = `${today.getFullYear()}-${String(
        today.getMonth() + 1
      ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

      const dateList = fileNames
        .map((fileName) => fileName.match(/\d{4}-\d{2}-\d{2}/)[0])
        .filter((date) => date <= todayFormatted); // Only include dates up to today
      setDates(dateList);

      const savedDate = localStorage.getItem("lastSelectedDate");
      if (savedDate && dateList.includes(savedDate)) {
        setCurrentDate(savedDate); // Use saved date if it's in the allowed range
      } else {
        setCurrentDate(dateList[dateList.length - 1]); // Default to the most recent available date
      }
    };

    generateDateList();
  }, []);
  // This useEffect is responsible for loading game data whenever currentDate changes
  useEffect(() => {
    if (currentDate) {
      loadGameData(currentDate);
      // Call the ad refresh function here to refresh ads whenever the game data changes
      onAdRefresh();
    }
  }, [currentDate]); // Include onAdRefresh in the dependency array if it's defined outside the useEffect or may change

  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };

  // The loadGameData function remains mostly unchanged, but ensure it uses the dynamic import correctly
  const loadGameData = async (date) => {
    try {
      const gameData = await import(`./games/${date}.json`);
      setCurrentGameData(gameData.default || gameData);
    } catch (error) {
      console.error("Failed to load game data:", error);
      setCurrentGameData(null); // Consider setting to null or a default state if loading fails
    }
  };
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setCurrentDate(newDate);
    localStorage.setItem("lastSelectedDate", newDate);
  };

  // Destructuring the current game data
  const {
    date = "",
    problem: {
      prompt = "",
      options = [],
      sourceText = "",
      sourceLink = "",
      facts = [],
    } = {}, // Provide a default empty object for problem to avoid errors
  } = currentGameData || {}; // Use currentGameData or fallback to an empty object if null

  let statisticsPageText = "stats";
  // console.log(options);

  // const shuffledOptions = options
  //   .map((player, index) => {
  //     // console.log(player);
  //     return { text: player.name, img_url: player.img_url, id: index };
  //   })
  //   .sort(() => Math.random() - 0.5);

  const shuffledOptions = useMemo(() => {
    return options
      .map((player, index) => {
        // console.log(player);
        return { text: player.name, img_url: player.img_url, id: index };
      })
      .sort(() => Math.random() - 0.5);
  }, [options]);

  const game = new CrossoverGridTrivia({
    options: shuffledOptions,
    isLoaded: true,
    date,
  });

  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();
  }, []);

  //   const { username } = useUsername();
  // Check if it's the user's first visit to show the How to Play modal

  // Comment out the useEffect hook that checks for the first visit
  // useEffect(() => {
  //   const isFirstVisit = localStorage.getItem('isFirstVisit') === null;
  //   if (isFirstVisit) {
  //     setShowHowToPlay(true); // Show the modal
  //     localStorage.setItem('isFirstVisit', 'false'); // Set the visit flag in local storage
  //   }
  // }, []);

  const animationRefs = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    if (autoJoin) {
      navigate(`/play/waiting`);
    }
  }, []);

  const useStyles = makeMuiStyles((theme, prefersDarkMode) => ({
    root: {
      flexGrow: 1,
      // padding: 2,  // 2 or '2px' or '2rem', as per your requirements
    },
    card: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    close: {
      position: "absolute",
      // right: 1,  // 1 or '1px' or '1rem', as per your requirements
      // top: 1,  // 1 or '1px' or '1rem', as per your requirements
      color: "#bdbdbd", // color from theme.palette.grey[500]
    },
    boxCard: {
      backgroundColor: theme.palette.background.gridCard,
      "&:hover": {
        backgroundColor: theme.palette.background.gridCardHover,
      },
    },
    boxCardSelected: {
      backgroundColor: theme.palette.background.gridCardSelect,
    },
    link: {
      color: theme.palette.text.link,
      fontSize: isDesktop ? "16px" : "12px",
      fontWeight: "600",
    },
    searchBox: {
      backgroundColor: "#58D18490",
      position: "relative",
      backdropFilter: "blur(10px)",
      color: "white",
      boxShadow: "0 0 15px rgba(0, 0, 0, 0.4)",
    },
    searchInput: {
      color: theme.palette.text.primary,
    },
    result: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      cursor: "pointer",
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: theme.palette.background.resultHover,
      },
    },
    sponsorshipLink: {
      marginTop: "20px",
      color: theme.palette.text.link,
      textDecoration: "none",
      borderBottom: `1px solid ${theme.palette.text.link}`,
    },
    sponsorshipLinkUnderline: {
      color: theme.palette.text.link,
      textDecoration: "none",
    },
    remainingGuesses: {
      position: "absolute",
      right: isDesktop ? "min(calc(90vw - 400px), 5vw)" : 0,
      top: "50%",
    },
    bars: {
      fontSize: "12px",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
    barLabelsXAxis: {
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: "bold",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
    boardImage: {
      filter: theme.palette.mode === "dark" ? "invert(1)" : "invert(0)",
      marginTop: 20,
    },
  }));

  const classes = useStyles();
  return (
    <div className="app">
      <Container>
        <nav className="navbar">
          {/* Navbar content */}
          <a
            href="https://crossovergrid.com"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center", // This ensures vertical alignment
              justifyContent: "center", // This centers the content horizontally if the a-tag is a flex container
            }}
          >
            <img src={crossover_logo} alt="Logo" className="navbar-logo" />
            <p
              style={{
                margin: 0, // Removes default margin that might offset alignment
                fontWeight: "bold",
                fontSize: 20,
                display: "flex", // Makes the paragraph itself a flex container
                alignItems: "center", // Centers the text inside the p-tag vertically
                justifyContent: "center", // Centers the text inside the p-tag horizontally
              }}
            >
              CROSSOVER GRID FC
            </p>
          </a>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FadeMenu /> {/* FadeMenu component for the hamburger menu */}
            <IconButton onClick={() => setShowHowToPlay(true)}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </nav>
      </Container>

      {showHowToPlay && <HowToPlayModal onClose={handleCloseHowToPlay} />}

      <div className="sport-picker">
        {/* Sport picker content */}
        <div className="links">
          {sports.map((sport) => (
            <Link
              key={sport.id}
              className={`sportLink ${
                sport.id === "21" ? "prime-gaming-logo" : ""
              } ${page_name === sport.id ? "selected" : ""}`}
              to={sport.path}
            >
              {sport.emoji.startsWith("http") ? (
                <img src={sport.emoji} alt={sport.name} />
              ) : (
                <span>{sport.emoji}</span>
              )}
              <br />
              {sport.name.length < 4 ? (
                <span>{sport.name}</span>
              ) : (
                <span style={{ fontSize: 12 }}>{sport.name}</span>
              )}
            </Link>
          ))}
        </div>
      </div>

      <div className="date-selector" style={{ marginTop: "10px" }}>
        <select
          id="date-select"
          value={currentDate}
          onChange={handleDateChange}
          style={{
            width: "100%", // Ensures the select box takes up the full container width
            height: "40px", // Fixed height for the select box itself
            overflow: "auto", // Allows for scrolling
          }}
        >
          {dates.map((date) => (
            <option key={date} value={date}>
              {formatDate(date)}
            </option>
          ))}
        </select>
      </div>
      <GameInterface game={game} prompt={prompt} options={shuffledOptions} />

      {/* Additional component content if any */}
    </div>
  );
};

export default CrossoverGridTriviaPage;
