import React, { useState, useEffect } from "react";
import styles from "./dash.module.scss";
import { useNavigate } from "react-router-dom";
import basketballIcon from "../../assets/nba.svg";
import footballIcon from "../../assets/nfl.svg";
import baseballIcon from "../../assets/mlb.svg";
import soccerIcon from "../../assets/fc.svg";
import tennisIcon from "../../assets/nhl.svg";
import PageHeader from "../PageHeader/PageHeader";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
const sportsData = [
  {
    id: 1,
    name: "Basketball",
    emoji: "🏀",
    icon: basketballIcon,
    gameModes: [
      { id: 1, name: "Trivia" },
      { id: 2, name: "Grids" },
      { id: 3, name: "Connections" },
      { id: 4, name: "Random-Generator" },

      { id: 7, name: "Multiplayer", route: "/play" },
  
    ],
  },
  {
    id: 2,
    name: "Football",
    emoji: "🏈",
    icon: footballIcon,
    gameModes: [
      { id: 1, name: "Trivia" },
      { id: 2, name: "Grids" },
      { id: 3, name: "Connections" },
      { id: 4, name: "Random-Generator" },
      { id: 5, name: "Guesser" },
      { id: 6, name: "Multiplayer", route: "/play" },
    ],
  },
  {
    id: 3,
    name: "Baseball",
    emoji: "⚾",
    icon: baseballIcon,
    gameModes: [
      { id: 1, name: "Trivia" },
      { id: 2, name: "Grids" },
      { id: 3, name: "Connections" },
      { id: 4, name: "Multiplayer", route: "/play" },
    ],
  },
  {
    id: 4,
    name: "Soccer",
    emoji: "⚽",
    icon: soccerIcon,
    gameModes: [
      { id: 1, name: "Trivia" },
      { id: 2, name: "Grids" },
    ],
  },
  {
    id: 5,
    name: "Hockey",
    emoji: "🏒",
    icon: tennisIcon,
    gameModes: [
      { id: 1, name: "Grids" },
      { id: 2, name: "Connections" },
      { id: 3, name: "Multiplayer", route: "/play" },
    ],
  },
  {
    id: 6,
    name: "Golf",
    emoji: "⛳",
    icon: footballIcon,
    gameModes: [{ id: 2, name: "Grids" }],
  },
  {
    id: 7,
    name: "WWE",
    emoji: "🤼",
    icon: footballIcon,
    gameModes: [{ id: 2, name: "Grids" }],
  },
  {
    id: 8,
    name: "Movies",
    emoji: "🎬",
    icon: footballIcon,
    gameModes: [
      { id: 2, name: "Grids" },
      { id: 3, name: "Movie Quest" },
    ],
  },
  {
    id: 9,
    name: "F1",
    emoji: "🏎️",
    icon: footballIcon,
    gameModes: [{ id: 2, name: "Grids" }],
  },
  {
    id: 10,
    name: "College Basketball",
    emoji: "🎓🏀",
    icon: footballIcon,
    gameModes: [{ id: 2, name: "Grids" }],
  },
  {
    id: 11,
    name: "College Football",
    emoji: "🎓🏈",
    icon: footballIcon,
    gameModes: [{ id: 2, name: "Grids" }],
  },
  {
    id: 12,
    name: "Pokemon",
    emoji: "🐉",
    icon: footballIcon,
    gameModes: [{ id: 1, name: "Multiplayer", route: "/play" }],
  },
];


const games = [
  {
    id: "Grids",
    name: "Grids",
    description: "Fill a 3x3 grid with the correct criteria",
    icon: "https://www.nytimes.com/games-assets/v2/assets/expansion-games/wordle-card-icon.svg",
    color: "#F7DF94",
  },
  {
    id: "Trivia",
    name: "Trivia",
    description: "Select the leaders in order for the provided criteria",
    icon: "https://www.nytimes.com/games-assets/v2/assets/expansion-games/spelling-bee-card-icon.svg",
    color: "#E8E8E8",
  },
  {
    id: "Connections",
    name: "Connections",
    description: "Find the connections between concepts",
    icon: "https://www.nytimes.com/games-assets/v2/assets/expansion-games/connections.svg",
    color: "#C8AAE5",
  },  {
    id: "Multiplayer",
    name: "Multiplayer",
    description:
      "Play others in live unlimited tic-tac-toe grids",
    icon: "https://www.nytimes.com/games-assets/v2/assets/expansion-games/vertex-card-icon.svg",
    color: "#C8AAE5",
  },
/*   {
    id: "AI",
    name: "AI",
    description:
      "Explore the convergence of sports and comparisons between other sports",
    icon: "https://www.nytimes.com/games-assets/v2/assets/expansion-games/vertex-card-icon.svg",
    color: "#C8AAE5",
  }, */
];

const GameCard = ({ id, name, description, icon, onClick, onPlay, isDarkMode }) => (
  <div className={styles.gameCard} onClick={() => onClick(id)} style={{
    backgroundColor: isDarkMode ? "#2C2C2E" : "#fff",
    color: isDarkMode ? "#F0F0F0" : "#000",
  }}>
    <img src={icon} alt={`${name} icon`} className={styles.gameIcon} />
    <h3 className={styles.gameTitle}>{name}</h3>
    <p className={styles.gameDescription}>{description}</p>
    <div className={styles.gameActions}>
      {name !== "Multiplayer" && (
        <div
          className={`${styles.gameButton} ${styles.modernButton}`}
          onClick={() => onPlay(id)}
          tabIndex="0"
        >
          Play
        </div>
      )}
      {id === "spelling-bee" && (
        <Link to={`/${id}/past`} className={`${styles.gameButton} ${styles.modernButton}`}>
          Past Puzzles
        </Link>
      )}
    </div>
  </div>
);

const GamesContainer = ({ onClick, onPlay, isDarkMode }) => (
  <div className={styles.gamesContainer}>
    {games.map((game) => (
      <GameCard key={game.id} {...game} onClick={onClick} onPlay={onPlay} isDarkMode={isDarkMode} />
    ))}
  </div>
);

const SportCard = ({ id, name, emoji, icon, gameModes, isDarkMode }) => (
  <div className={styles.sportCard} style={{
    backgroundColor: isDarkMode ? "#2C2C2E" : "#fff",
    color: isDarkMode ? "#F0F0F0" : "#000",
  }}>
    <div className={styles.sportIconContainer}>
      <img src={icon} alt={`${name} icon`} className={styles.sportIcon} />
    </div>
    <div className={styles.sportContent}>
      <h3 className={styles.sportTitle}>
        <span role="img" aria-label={name} className={styles.sportEmoji}>
          {emoji}
        </span>{" "}
        {name}
      </h3>
      <div className={styles.gameModes}>
        {gameModes.map((mode) => (
          <Link
            to={`/${id}/${mode.id}`}
            key={mode.id}
            className={`${styles.playButton} ${styles.modernButton}`}
          >
            {mode.name}
          </Link>
        ))}
      </div>
    </div>
  </div>
);

const SportsContainer = ({ sports, isDarkMode }) => (
  <div className={styles.sportsContainer}>
    {sports.map((sport) => (
      <SportCard key={sport.id} {...sport} isDarkMode={isDarkMode} />
    ))}
  </div>
);

const SportsPicker = ({ sports, showAll, onToggleShowMore, onSelectSport, isDarkMode }) => (
  <div className={styles.sportsPicker}>
    <div className={styles.pickerContent}>
      <h2 className={styles.pickerTitle} style={{ color: isDarkMode ? "#F0F0F0" : "#000" }}>Discover Daily & Unlimited Trivia</h2>
      <div className={styles.pickerItems}>
        {sports.slice(0, showAll ? sports.length : 5).map((sport) => (
          <button
            key={sport.id}
            className={`${styles.sportPickerItem} ${styles.modernButton}`}
            onClick={() => onSelectSport(sport)}
            aria-label={`Select ${sport.name}`}
          >
            <span role="img" aria-hidden="true" className={styles.sportEmoji}>
              {sport.emoji}
            </span>
            {sport.name}
          </button>
        ))}
      </div>
      <button className={`${styles.showMoreButton} ${styles.modernButton}`} onClick={onToggleShowMore}>
        {showAll ? "Show Less" : "More Sports"}
      </button>
    </div>
  </div>
);

const CustomDialog = ({
  isOpen,
  onClose,
  title,
  content,
  actions,
  isDarkMode,
}) => {
  const dialogStyles = {
    borderRadius: "10px",
    padding: "20px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
    backgroundColor: isDarkMode ? "#2C2C2E" : "#f4f4f4",
    color: isDarkMode ? "#F0F0F0" : "#000",
  };
  const titleStyles = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${isDarkMode ? "#3A3A3C" : "#333333"}`,
  };
  const buttonStyles = {
    flex: "1 0 auto",
    margin: "8px",
    padding: "12px 24px",
    borderRadius: "30px",
    textTransform: "uppercase",
    transition: "background-color 0.2s ease-in-out",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: isDarkMode ? "#3A3A3C" : "#0070c9",
    border: "none",
    cursor: "pointer",
    outline: "none",
  };

  return isOpen ? (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialog} style={dialogStyles}>
        <div className={styles.dialogTitle} style={titleStyles}>
          {title}
          <button
            className={styles.closeButton}
            onClick={onClose}
            style={{ color: isDarkMode ? "#aaa" : "#666" }}
          >
            &times;
          </button>
        </div>
        <div className={styles.dialogContent}>{content}</div>
        <div className={styles.dialogActions}>
          {actions.map((action, index) => (
            <button
              key={index}
              className={styles.dialogButton}
              style={buttonStyles}
              onClick={action.onClick}
            >
              {action.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

const SportsPage = () => {
  const [showAll, setShowAll] = useState(false);
  const [isSportDialogOpen, setIsSportDialogOpen] = useState(false);
  const [isGameModeDialogOpen, setIsGameModeDialogOpen] = useState(false);
  const [selectedGameMode, setSelectedGameMode] = useState({});
  const [selectedSport, setSelectedSport] = useState({ gameModes: [] });
  const [filteredSports, setFilteredSports] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false); // Initially set to false to test light mode
  const navigate = useNavigate();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(mediaQuery.matches);

    const handleChange = (e) => setIsDarkMode(e.matches);
    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const navigateToGameModeForSport = (sportId) => {
    const sport = sportsData.find((sport) => sport.id === sportId);

    if (!sport) {
      console.error("Sport not found with id:", sportId);
      return;
    }

    if (!selectedGameMode || !selectedGameMode.name) {
      console.error("Selected game mode is not defined");
      return;
    }

    const urlMap = {
      1: "/nba",
      2: "/nfl",
      3: "/mlb",
      4: "/soccer",
      5: "/nhl",
      6: "/pga",
      7: "/wrestling",
      8: "/movies",
      9: "/racing",
      10: "/cfb",
      11: "/cbb",
    };

    let url = urlMap[sportId] || "/default-path";

    if (selectedGameMode.name === "Grids") {
      navigate(url);
    } else if (
      ["Connections", "Trivia", "Random-Generator", "Guesser"].includes(
        selectedGameMode.name
      )
    ) {
      url += `/${selectedGameMode.name.toLowerCase()}`;
      navigate(url);
    } else if (selectedGameMode.name === "Movie Quest") {
      navigate("/unlimited");
    } else {
      setSelectedGameMode(selectedGameMode);
      setIsGameModeDialogOpen(true);
    }

    setShowGameModeSportsDialog(false);
  };

  const handleToggleShowMore = () => {
    setShowAll(!showAll);
  };

  const handleOpenSportDialog = (sport) => {
    setSelectedSport(sport);
    setIsSportDialogOpen(true);
  };

  const [showGameModeSportsDialog, setShowGameModeSportsDialog] =
    useState(false);
  const [sportsForGameMode, setSportsForGameMode] = useState([]);
  const handlePlayClick = (gameModeName) => {
    const filteredSports = sportsData.filter((sport) =>
      sport.gameModes.some((mode) => mode.name === gameModeName)
    );
    setSelectedGameMode(gameModeName);
    setSportsForGameMode(filteredSports);
    setShowGameModeSportsDialog(true);
  };

  const handleGameModeClick = (gameModeName) => {
    if (gameModeName === "Multiplayer") {
      navigate("/play");
      return;
    }

    setSelectedGameMode({ name: gameModeName });

    const filteredSports = sportsData.filter((sport) =>
      sport.gameModes.some((mode) => mode.name === gameModeName)
    );
    setSportsForGameMode(filteredSports);
    setShowGameModeSportsDialog(true);
  };

  const handleCloseGameModeSportsDialog = () => {
    setIsGameModeDialogOpen(false);
  };

  const handleCloseGameModeDialog = () => {
    setIsGameModeDialogOpen(false);
  };
  const handleOpenGameModeDialog = (gameMode, sportId) => {
    // Check if the selected game mode is "Multiplayer"
    if (gameMode.name === "Multiplayer") {
      navigate("/play");
      return; // Ensure no further logic is executed
    }
  
    const urlMap = {
      1: "/nba",
      2: "/nfl",
      3: "/mlb",
      4: "/soccer",
      5: "/nhl",
      6: "/pga",
      7: "/wrestling",
      8: "/movies",
      9: "/racing",
      10: "/cfb",
      11: "/cbb",
    };
  
    let url = urlMap[sportId] || "/default-path";
  
    if (gameMode.name === "Grids") {
      navigate(url);
    } else if (
      ["Connections", "Trivia", "Random-Generator", "Guesser"].includes(
        gameMode.name
      )
    ) {
      url += `/${gameMode.name.toLowerCase()}`;
      navigate(url);
    } else if (gameMode.name === "Movie Quest") {
      navigate("/unlimited");
    } else {
      setSelectedGameMode(gameMode);
      setIsGameModeDialogOpen(true);
    }
  };
  
  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };

  useEffect(() => {
    onAdRefresh();
  }, []);

  return (
    <div className={styles.sportsPage} style={{
      backgroundColor: isDarkMode ? "#1C1C1E" : "#f4f4f4",
      color: isDarkMode ? "#F0F0F0" : "#000",
    }}>
      <PageHeader />
      <div className={styles.announcementBanner}>
        <div className={styles.announcementText}>
          <span>Introducing our new multiplayer mode!</span>
          <br />
          Challenge others in live unlimited tic-tac-toe grids 🏀🏈⚾⚽🏒
        </div>
        <button className={styles.announcementPlayButton} onClick={() => navigate("/play")}>
          Play Now
        </button>
      </div>
      <div className={styles.pageContentWrapper}>
        <div className={styles.mainContent}>
          <SportsPicker
            sports={sportsData}
            showAll={showAll}
            onToggleShowMore={handleToggleShowMore}
            onSelectSport={handleOpenSportDialog}
            isDarkMode={isDarkMode}
          />
          <GamesContainer onClick={handleGameModeClick} onPlay={handlePlayClick} isDarkMode={isDarkMode} />
          <div className={styles.pageContent}>
            <div className={styles.sportsContainer}>
              {sportsData.slice(0, showAll ? sportsData.length : 5).map((sport) => (
                <div
                  key={sport.id}
                  className={styles.sportCard}
                  onClick={() => handleOpenSportDialog(sport)}
                  style={{
                    backgroundColor: isDarkMode ? "#2C2C2E" : "#fff",
                    color: isDarkMode ? "#F0F0F0" : "#000",
                  }}
                >
                  <div className={styles.sportIconContainer}>
                    <img
                      src={sport.icon}
                      alt={`${sport.name} icon`}
                      className={styles.sportIcon}
                    />
                  </div>
                  <div className={styles.sportContent}>
                    <h3 className={styles.sportTitle}>
                      <span
                        role="img"
                        aria-label={sport.name}
                        className={styles.sportEmoji}
                      >
                        {sport.emoji}
                      </span>{" "}
                      {sport.name}
                    </h3>
                    <button className={`${styles.playButton} ${styles.modernButton}`}>See Game Modes</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <footer className={styles.footer}>
        © 2024 Crossover Sports Entertainment LLC. All rights reserved.
        <button id="pmLink">Privacy Manager</button>
      </footer>

      <CustomDialog
        isOpen={isSportDialogOpen}
        onClose={() => setIsSportDialogOpen(false)}
        title={`Choose a ${selectedSport.name} Game Mode`}
        content={selectedSport.gameModes.map((mode) => (
          <button
            key={mode.id}
            className={`${styles.dialogButton} ${styles.modernButton}`}
            onClick={() => handleOpenGameModeDialog(mode, selectedSport.id)}
          >
            {mode.name}
          </button>
        ))}
        actions={[
          { label: "Close", onClick: () => setIsSportDialogOpen(false) },
        ]}
        isDarkMode={isDarkMode}
      />

      <CustomDialog
        isOpen={showGameModeSportsDialog}
        onClose={() => setShowGameModeSportsDialog(false)}
        title={`Select a Sport for ${selectedGameMode.name}`}
        content={
          <div className={styles.sportsOptions}>
            {sportsForGameMode.map((sport) => (
              <button
                key={sport.id}
                className={`${styles.dialogButton} ${styles.modernButton}`}
                onClick={() => navigateToGameModeForSport(sport.id)}
              >
                {sport.name}
              </button>
            ))}
          </div>
        }
        actions={[
          { label: "Cancel", onClick: () => setShowGameModeSportsDialog(false) },
        ]}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default SportsPage;
