import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AlertModal = ({ isOpen, onClose, title, contentText, actions }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "#2A2A2A80",
          backdropFilter: "blur(10px)",
          borderRadius: 8,
          // boxShadow: "0px 5px 10px #58D18450",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ color: "white" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: "white" }}
        >
          {contentText}
        </DialogContentText>
      </DialogContent>
      {/* <DialogActions> */}
      {actions.map((action) => (
        <Button key={action.label} onClick={action.onClick} color="primary">
          {action.label}
        </Button>
      ))}
      {/* </DialogActions> */}
    </Dialog>
  );
};

export default AlertModal;
