// src/hooks/useRandomPlayer.js
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { decrypt, uuidToAesKey } from "../../../utilities/cryptoUtils";
import API from "../../../network/apiClient";
import { queryKeys } from "../../../network/queryKeys";

export const useRandomPlayer = (sport = "nfl") => {
  const [randomPlayer, setRandomPlayer] = useState(null);
  const [randomKey, setRandomKey] = useState(uuidv4());

  // Function to generate a new UUID and set the key state
  const generateUniqueId = useCallback(() => {
    const newUUID = uuidv4(); // Generate a new UUID
    console.log("🚀 ~ generateUniqueId ~ newUUID:", newUUID);
    setRandomKey(newUUID);
  }, []);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: queryKeys.player.random(randomKey),
    queryFn: async () => {
      const key = uuidToAesKey(randomKey);
      const params = { sport };
      const response = await API.player.getRandomPlayer(params, {
        headers: {
          key,
        },
      });

      // Decrypt the data
      const decryptedPlayer = decrypt(response?.data?.player, key);
      console.log("🚀 ~ queryFn: ~ decryptedPlayer:", decryptedPlayer);

      setRandomPlayer(decryptedPlayer); // Update state with decrypted data
      return decryptedPlayer;
    },
    enabled: !!randomKey, // Only run the query if the key is available
    refetchOnWindowFocus: false, // Disable refetching on window focus
  });

  // Effectively manage side effects when key changes
  useEffect(() => {
    if (randomKey) {
      refetch(); // Refetch the data when a new key is generated
    }
  }, [randomKey, refetch]);

  return {
    response: data,
    generateUniqueId,
    randomPlayer,
    isLoading,
    error,
    refetch,
  };
};
