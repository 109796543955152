import { useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Lottie from "lottie-react";
import football_animation from "../assets/lottie/football_icon.json";
import { useSpring, animated } from "@react-spring/web";
// import { useControls } from "leva";

const isDesktop = window.innerWidth >= 800;
// const SportText = styled("h4")(({ theme }) => ({
//   color: theme.palette.text.primary,
// }));

const SportButton = ({ index, sport, onClick }) => {
  // const bounce = useSpring({
  //   from: { transform: "translateY(10px)" },
  //   to: [{ transform: "translateY(-10px)" }, { transform: "translateY(10px)" }],
  //   config: { duration: "1000" },
  //   loop: true,
  // });
  const cardRef = useRef(null);
  const config = {
    mass: 1,
    tension: 170,
    friction: 26,
    clamp: false,
    precision: 0.01,
    velocity: 0,
  };
  const [{ xys }, api] = useSpring(
    () => ({ xys: [0, 0, 1], config }),
    [config]
  );

  const handleMouseLeave = () =>
    api.start({
      xys: [0, 0, 1],
    });

  const handleMouseMove = (e) => {
    const rect = cardRef.current.getBoundingClientRect();
    api.start({
      xys: calc(e.clientX, e.clientY, rect),
    });
  };

  return (
    <div
      className="card-main"
      style={{
        width: "calc(100% / 3 - 20px)",
        maxWidth: 200,
        textAlign: "center",
        borderRadius: 8,

        cursor: "pointer",
        // backgroundColor: "white",
        position: "relative",
        backdropFilter: "blur(10px)",
        color: "white",

        marginBottom: 20,
        aspectRatio: "1.618033988749894/1",
        // width: "100%",
        // height: "100%",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        // overflow: "hidden",
      }}
      ref={cardRef}
      onClick={onClick}
    >
      <animated.div
        style={{
          width: "100%",
          maxWidth: 200,
          aspectRatio: "1.618033988749894/1",
          backgroundColor: "#A3E3BA",
          borderRadius: "8px",
          boxShadow: "0px 5px 10px #58D18495",
          transition: "box-shadow 0.5s",
          willChange: "transform",
          transform: xys.to(trans),
        }}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <h4
          // variant="h4"
          style={{
            fontWeight: "bold",
            margin: 0,
            textDecoration: "underline",
            paddingTop: 8,
            marginBottom: 8,
            color: "#2A2A2A",
          }}
          className={"sign-font"}
        >
          {sport.name}
        </h4>
        {/* <Lottie
        animationData={football_animation}
        style={{ width: 100, height: 100, margin: "auto" }}
        // initialSegment={[0, 100]}
      /> */}
        <animated.p
          style={
            isDesktop
              ? { fontSize: "50px", margin: 0 }
              : { fontSize: "40px", margin: 0 }
          }
        >
          {sport.emoji}
        </animated.p>
        {/* <p style={{ margin: 0, fontSize: "0.9em" }}>{sport.subtext}</p> */}
      </animated.div>
    </div>
  );
};

const calc = (x, y, rect) => [
  -(y - rect.top - rect.height / 2) / 5,
  (x - rect.left - rect.width / 2) / 5,
  1.1,
];

const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export default SportButton;
