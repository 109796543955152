import React, { useState, useEffect } from 'react';
import styles from './tool.module.css';
import Modal from './Modal'; // Make sure you have a Modal component similar to the one used in SportsAnalogyFinder
import crossoverTriviaLogo from "../../assets/crossover_trivia.svg";
import { Link } from "react-router-dom";
import sports from "../../sports";
import { makeStyles as makeMuiStyles } from "@mui/styles";
import ReactGA from "react-ga";

const SportsAnalogyFinder = () => {
  const [playerMovement, setPlayerMovement] = useState('');
  const [targetSport, setTargetSport] = useState('');
  const [analogyExplanation, setAnalogyExplanation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const validSports = ['Basketball', 'American Football(NFL)', 'NASCAR', 'Golf', 'Soccer', 'Hockey', 'Baseball'];
  const [analogyPrompt, setAnalogyPrompt] = useState(''); // To store and display the generated analogy prompt
  const [theme, setTheme] = useState('light'); // New state for theme
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to control modal visibility
  const [page_name, setPageName] = useState(""); // Define page_name state
const [sport_id, setSportId] = useState(""); // Define sport_id state
const currentSport = sports.find((sport) => sport.id === sport_id.toString());
const useStyles = makeMuiStyles((theme, prefersDarkMode) => ({  }));

const classes = useStyles();

  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };
  const shareAnalogy = () => {
    let content = `Player Movement: ${playerMovement}, Target Sport: ${targetSport}\n`;
  
    // Check if analogyExplanation exists and is not null
    if (analogyExplanation) {
      analogyExplanation.forEach((analogy, index) => {
        content += `Analogy Explanation ${index + 1}:\n`;
  
        // Iterate over each key-value pair in the analogy object
        Object.entries(analogy).forEach(([key, value]) => {
          content += `${key}: ${value}\n`; // Concatenate key-value pair with a colon
        });
  
        content += '\n'; // Add a newline after each analogy explanation
      });
    } else {
      content += 'Analogy Explanation: N/A'; // Provide a message indicating that no analogy explanation is available
    }
  
    content += '@crossoverGrid Crossovergrid.com/ai'; // Add the CrossoverGrid information
  
    // Logic to share content
  };
  
  const copyAnalogy = () => {
    let content = `Player Movement: ${playerMovement}, Target Sport: ${targetSport}\n`;
  
    // Check if analogyExplanation exists and is not null
    if (analogyExplanation) {
      analogyExplanation.forEach((analogy, index) => {
        content += `Analogy Explanation ${index + 1}:\n`;
  
        // Iterate over each key-value pair in the analogy object
        Object.entries(analogy).forEach(([key, value]) => {
          content += `${key}: ${value}\n`; // Concatenate key-value pair with a colon
        });
  
        content += '\n'; // Add a newline after each analogy explanation
      });
    } else {
      content += 'Analogy Explanation: N/A'; // Provide a message indicating that no analogy explanation is available
    }
  
    content += '@crossoverGrid Crossovergrid.com/ai'; // Add the CrossoverGrid information
  
    navigator.clipboard.writeText(content); // Copy the content to clipboard
  };
    

  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  }, []);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  // Example data to display in the modal
  const exampleData = {
    source_sport: "American Football",
    source_position: "Wide Receiver",
    target_sport: "Basketball",
    target_position: "Point Guard",
    source_player: "Julian Edelman",
    target_player: "Fred VanVleet",
    rationale: "Edelman's critical playmaking in football mirrors VanVleet's in basketball."
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "playerMovement") setPlayerMovement(value);
    else if (name === "targetSport") setTargetSport(value);
  };
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };
  
  
  // Other state initializations remain the same
// Add a method to parse and display the JSON data in a tabular format
const renderValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    // If the value is an object, try to break it down into key-value pairs
    return (
      <ul>
        {Object.entries(value).map(([key, val]) => (
          <li key={key}>
            <strong>{key}:</strong> {val}
          </li>
        ))}
      </ul>
    );
  } else {
    // Otherwise, display the value as text
    return <span>{value}</span>;
  }
}
const renderAnalogyExplanationTable = (analogyData) => {
  try {
    if (!Array.isArray(analogyData)) {
      analogyData = [analogyData]; // Ensure it's an array for consistent processing
    }

    const standardColumns = [
      'source_position', 'source_player', 'target_sport','target_player',   'target_position', 'source_sport', 

    ];

    return (
      <div className={styles.analogyContainer}>
        {analogyData.map((data, index) => {
          // Separate the standard columns from additional information
          const standardData = {};
          const additionalInfo = {};

          Object.entries(data).forEach(([key, value]) => {
            if (standardColumns.includes(key)) {
              standardData[key] = value;
            } else {
              additionalInfo[key] = value;
            }
          });

          return (
            <React.Fragment key={index}>
              <div className={styles.tableWrapper}>
                <table className={styles.analogyTable}>
                  <thead>
                    <tr>
                      {standardColumns.map((column, columnIndex) => (
                        <th key={columnIndex}>{column.replace(/_/g, ' ')}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {standardColumns.map((column, columnIndex) => (
                        <td key={columnIndex}>{standardData[column] ? renderValue(standardData[column]) : 'N/A'}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Render additional information like rationale outside the table */}
              <div className={styles.additionalInfoContainer}>
                {Object.entries(additionalInfo).map(([key, value]) => (
                  <div key={key} className={styles.additionalInfo}>
                    <strong>{key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}:</strong>
                    {renderValue(value)}
                  </div>
                ))}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  } catch (e) {
    console.error('Failed to parse analogy explanation:', e);
    return <p>Failed to load analogy explanation.</p>;
  }
};


  const generateAnalogyPrompt = (details) => {
    setIsLoading(true);

    // Constructing the details string dynamically from the player's details
    const detailsString = `
    - Age: ${details.age}
    - Team: ${details.team}
    - Achievements: ${details.achievements}
    - Playing Style: ${details.playingStyle}
    - Leadership: ${details.leadership}
    - Positional Importance: ${details.positionalImportance}
    - Original Team's History: ${details.team1History}
    - New Team's History: ${details.team2History}
    - Draft Position: ${details.draftPosition}
    - Years With Each Team: ${details.yearsWithEachTeam}
    - Statistical Performance: ${details.statisticalPerformance}
    ${(details.newInformation ? "- New Information: " + details.newInformation : "")}
    `;
    
    return `Imagine a scenario similar to ${playerMovement}, but translated into ${targetSport} terms. Here are the details:
    ${detailsString}
    
  Given these specifics, identify a player in ${targetSport} who closely matches this profile, taking into account factors such as playing style, impact on the team, leadership qualities, and statistical achievements. Feel free to use any era or any specific point of a players career ie Steph Curry his first few years battling injuries. The goal is to find a truly comparable player, avoiding overhype. Provide an honest and critical assessment to reflect the player's potential and current standing accurately. Also compare the new teams to the rst of teams in its sport to find a good matching for it in the other sport. An example of a bad comparison is adam thielen to sam hauser since adam thielen is a very good player and hauser is a 10th rotation guy.
  
  Whatever you do Absolutely do not have any text or space before or after the json it should only be the json object.
  See example answers below
  
  
{
  "source_sport": "American Football",
  "source_position": "Wide Receiver",
  "target_sport": "Basketball",
  "target_position": "Point Guard",
  "source_player": "Julian Edelman",
  "target_player": "Fred VanVleet",
  "rationale": "Julian Edelman, known for his exceptional performances in critical playoff moments and Super Bowl victories, mirrors Fred VanVleet's journey and impact in the NBA. Both athletes have been instrumental in their teams' championship runs, showcasing their ability to perform at the highest level under pressure. Edelman's knack for making pivotal catches in crucial moments is akin to VanVleet's clutch three-point shooting and defensive plays during the Raptors' 2019 championship run. Moreover, their leadership, both on and off the field or court, has been vital to their teams' successes. Edelman's transition to basketball would see him embodying a role similar to VanVleet's, where resilience, determination, and the ability to deliver when it matters most define their careers."
}
  {
    "source_sport": "American Football",
    "source_position": "Running Back",
    "target_sport": "Basketball",
    "target_position": "Small Forward",
    "source_player": "DeAndre Swift",
    "target_player": "Mikal Bridges",
    "rationale": "Swift's agility and playmaking parallel Bridges' defensive prowess and versatility, both offering key contributions beyond stats."
  },
  {
    "source_sport": "American Football",
    "source_position": "Utility Player",
    "target_sport": "Basketball",
    "target_position": "Shooting Guard",
    "source_player": "Taysom Hill",
    "target_player": "Josh Hart",
    "rationale": "Hill's unique versatility and physicality in football mirror Hart's all-around contributions in basketball, including defense and hustle plays."
  },
  { "source_sport": "Basketball", "source_position": "Guard", "target_sport": "American Football", "target_position": "Safety", "source_player": "Patrick Beverley", "target_player": "Tyrann Mathieu", "rationale": "Patrick Beverley, recognized for his relentless defense, leadership, and ability to challenge opponents, shares many qualities with NFL's Tyrann Mathieu, also known as 'The Honey Badger'. Just as Beverley is celebrated for his defensive prowess and vocal leadership on the basketball court, Mathieu mirrors these attributes on the football field with his exceptional ball skills, tenacity, and knack for creating turnovers. Both players have a significant impact on their teams' defensive schemes, often tasked with shutting down the other team's best scorer or receiver, respectively. Mathieu, like Beverley, has been recognized for his defensive achievements, including multiple Pro Bowl and All-Pro selections reflecting his status as one of the top defenders in the NFL. They both bring a blend of skill, leadership, and an aggressive edge that elevates their team's competitive spirit. Regarding team dynamics, as Beverley's move to the Lakers is meant to enhance their defense and competitiveness, Mathieu’s presence in any NFL team similarly bolsters defensive reliability and provides a veteran presence. While Mathieu's draft position (3rd round in the 2013 NFL Draft) was slightly higher relative to his sport's draft structure, both players have outperformed their draft slots to become key contributors on their teams." }

`;
  
};
  
const fetchPlayerDetails = async () => {
  setError('');
  setIsLoading(true);

  try {
    const playerInfoPrompt = `
      Given ${playerMovement}, provide detailed information in JSON format about the player. If you think the information is hypothetical, still provide the information on the player. The information should include:
      
      - "age": Player's current age,
      - "team": Current team of the player,
      - "achievements": Notable achievements of the player,
      - "playingStyle": Description of the player's playing style,
      - "leadership": Insights into the player's leadership qualities,
      - "positionalImportance": The importance of the player's position in the team,
      - "team1History": History and current situation of the player's original team,
      - "team2History": History and current situation of the player's new team if applicable,
      - "draftPosition": The player's position in the draft,
      - "yearsWithEachTeam": Breakdown of years spent with each team,
      - "statisticalPerformance": Statistical performance of the player compared to other running backs,
      - "newInformation": Any brand new information that might be relevant to understanding the player's profile.
      
      Please format the response as follows:
      
      \`\`\`json
      {
        "age": "Player's Age",
        "team": "Player's Team",
        "achievements": "Player's Achievements",
        "playingStyle": "Player's Playing Style",
        "leadership": "Player's Leadership Qualities",
        "positionalImportance": "Player's Positional Importance",
        "team1History": "Original Team's History and Current Situation",
        "team2History": "New Team's History and Current Situation",
        "draftPosition": "Player's Draft Position",
        "yearsWithEachTeam": "Years Spent with Each Team",
        "statisticalPerformance": "Statistical Performance Comparison",
        "newInformation": "Any Other Relevant New Information"
      }
      \`\`\`
      
      This comprehensive request ensures all critical aspects of the player's career and attributes are captured, and it remains flexible to include any new or unexpected information that might emerge. This approach aids in creating a well-rounded profile that can serve as a solid basis for generating an analogy or further analysis.`;
    
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: 'gpt-4-turbo-preview',
        messages: [
          { role: "system", content: "You are an AI knowledgeable about all sports." },
          { role: "user", content: playerInfoPrompt },
        ]
      })
    };

    // Making the fetch request to the API
    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);

    if (!response.ok) {
      // If the response is not OK, throw to catch block with status text
      throw new Error(`HTTP Error: ${response.statusText}`);
    }

    const data = await response.json();

    if (data.error) {
      // If the API returned an error message, throw to catch block with API error
      throw new Error(`API Error: ${data.error.message}`);
    }

    // Validating the API response structure
    if (!data.choices || data.choices.length === 0 || !data.choices[0].message || !data.choices[0].message.content) {
      throw new Error('Invalid response from the API');
    }

    // Extracting and cleaning the JSON string from the response
    const content = data.choices[0].message.content;
// Extracting and cleaning the JSON string from the response
const jsonStringMatch = content.match(/```json\s*([\s\S]*?)\s*```|```[\s\S]*?"content"\s*:\s*"([\s\S]*?)"\s*```/);
if (!jsonStringMatch || jsonStringMatch.length < 3) {
  throw new Error('Invalid JSON string in the response');
}
const jsonString = jsonStringMatch[1] || jsonStringMatch[2];

    // Attempt to parse the JSON string
    const details = JSON.parse(jsonString);

    // Use the details to generate the analogy prompt
    const generatedPrompt = generateAnalogyPrompt(details);
    setAnalogyPrompt(generatedPrompt); // Update state with the generated analogy prompt

    return details; // Return the parsed details for further processing
  } catch (error) {
    // Handle any errors that occurred during the fetch request or JSON parsing
    console.error('Error fetching player details:', error);
    setError(`Failed to fetch player details: ${error.message || 'Please try again.'}`);
    return null;
  } finally {
    // Regardless of success or failure, indicate loading is complete
    setIsLoading(false);
  }
};
const fetchAnalogy = async () => {
  setIsLoading(true);
  setError('');
  try {
    const playerDetails = await fetchPlayerDetails();
    if (!playerDetails) {
      throw new Error('No player details returned.');
    }
    const detailedAnalogyPrompt = generateAnalogyPrompt(playerDetails);
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: 'gpt-4-turbo-preview',
        messages: [{ role: "system", content: "You are an AI knowledgeable about all sports." }, { role: "user", content: detailedAnalogyPrompt }],
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.statusText}`);
    }
    const data = await response.json();
    if (data.error) {
      throw new Error(`API Error: ${data.error.message}`);
    }
    const explanations = data.choices.map(choice => choice.message.content);
    // Parse each JSON response separately
    const parsedExplanations = explanations.map(explanation => JSON.parse(explanation));
    setAnalogyExplanation(parsedExplanations);
  } catch (error) {
    console.error('Error:', error);
    setError(`Error: ${error.message}`);
  } finally {
  }
};

const Header = () => (
    <div className="app-header">
      <nav className="navbar">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <a href="https://www.crossovergrid.com" target="_blank" rel="noopener noreferrer">
                <img
                  src={crossoverTriviaLogo}
                  alt="Logo"
                  className="navbar-logo"
                />
                <p
                  style={{
                    marginLeft: "10px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "0",
                    marginBottom: "0",
                  }}
                >
                  CROSSOVER GRID
                </p>
              </a>
            </div>
            <div>
              {/* Potential space for navigation or user actions */}
            </div>
          </div>
      </nav>
    </div>
  );
  
// Modified handleSubmit function
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validSports.includes(targetSport)) {
    setError('Please select a valid sport for the analogy.');
    return;
  }
  setAnalogyPrompt(''); // Reset analogy prompt on new submission
  setError(''); // Clear any existing errors
  setIsLoading(true); // Set loading state at the beginning of the operation

  try {
    await fetchAnalogy(); // Await completion of the fetchAnalogy process which includes fetching player details
    // After fetchAnalogy completes, setLoading will be set to false at the end
  } catch (error) {
    // Handle any errors that might occur during the fetch
    console.error('Error during fetch operations:', error);
    setError(`Error: ${error.message}`);
  } finally {
    setIsLoading(false); // Ensure loading is turned off after operations complete or if an error occurs
  }
};

// Adjusted fetchPlayerDetails and fetchAnalogy functions
// - Remove setIsLoading(true) from the beginning of these functions
// - Remove setIsLoading(false) from the finally block of these functions

// Now, the loading indicator will show for the entire process from when the user submits the form until all data fetching and processing are complete.
return (
  <div className={styles.container}>
          <Header />
          <div className="sport-picker">
  <div className="links">
    {sports.map((sport) => (
      <Link
        key={sport.id}
        className={`sportLink ${sport.id === "21" ? "prime-gaming-logo" : ""} ${classes.link} ${page_name === sport.id ? "selected" : ""}`}
        to={sport.path}
        // onClick={() => onAdRefresh()}
      >
        {sport.emoji.startsWith("http") ? (
          <img src={sport.emoji} alt={sport.name} />
        ) : (
          <span>{sport.emoji}</span>
        )}
        <br />
        {sport.name.length < 4 ? (
          <span>{sport.name}</span>
        ) : (
          <span style={{ fontSize: 12 }}>{sport.name}</span>
        )}
      </Link>
    ))}
  </div>
</div>

    <h1>Sports Analogy Finder</h1>
    <button onClick={toggleModal} className={styles.infoButton}>How It Works</button>
    <Modal isOpen={modalIsOpen} onClose={toggleModal} title="How It Works">
  <h2>Get to Know the Sports Analogy Finder!</h2>
  <p>
    Ever wondered which basketball player matches the vibe of your favorite football star? That's where we come in. 
    The Sports Analogy Finder is like a sports translator, turning stats and plays into insights you can chat about 
    during game night, no Ph.D. required.
  </p>
  <h3>How to Kick Things Off:</h3>
  <ol>
    <li>
      <strong>Player Movement:</strong> Drop in the name of an athlete or any big moves they’ve made lately.
    </li>
    <li>
      <strong>Target Sport:</strong> Pick the sport you want to compare with from our list. We've got a pretty good lineup, 
      from the court to the ice.
    </li>
    <li>
      <strong>Find That Analogy:</strong> Hit 'Find Analogy' and let our system work its magic, pairing up athletes across 
      sports like a pro matchmaker.
    </li>
  </ol>
  <h3>What’s the Game Plan?</h3>
  <p>
    You'll get a breakdown that goes beyond the numbers. Think of it like getting the scoop on why a player from your 
    go-to sport is a mirror image of the one you've tossed into the mix, all boiled down to what they do best on the 
    field, track, or court.
  </p>
  <h3>Here’s the Play-by-Play:</h3>
  <p>
    Curious if Julian Edelman’s gridiron greatness has a twin on the hardwood? Pop his name in, select basketball, 
    and you might get a side-by-side with Fred VanVleet, spotlighting how both athletes step up when the heat’s on.
  </p>
  <table className={styles.exampleTable}>
    <thead>
      <tr>
        <th>Source Sport</th>
        <th>Target Sport</th>
        <th>Analogy</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{exampleData.source_sport}</td>
        <td>{exampleData.target_sport}</td>
        <td>{exampleData.rationale}</td>
      </tr>
    </tbody>
  </table>
</Modal>


    <form onSubmit={handleSubmit} className={styles.form}>
      <label>Player Movement</label>
      <input
  type="text"
  name="playerMovement"
  placeholder="Enter an athlete or an athlete moving to a new team"
  value={playerMovement}
  onChange={handleInputChange}
  required
  className={styles.input}
/>

      <label>Target Sport for Analogy</label>
      <select name="targetSport" value={targetSport} onChange={handleInputChange} className={styles.input} required>
        <option value="">Select a Sport</option>
        {validSports.map(sport => (
          <option key={sport} value={sport}>{sport}</option>
        ))}
      </select>
      {/* Only display the button if not loading */}
      {!isLoading && <button type="submit" className={styles.button}>Find Analogy</button>}
    </form>
    {/* Loading Indicator */}
    {isLoading && (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p className={styles.loadingText}>Loading...</p>
      </div>
    )}
    {error && <p className={styles.error}>{error}</p>}
    {analogyExplanation && (
        
      <div className={styles.output}>
             <div className={styles.shareCopyButtons}>
          <button onClick={shareAnalogy} className={styles.button}>Share</button>
          <button onClick={copyAnalogy} className={styles.button}>Copy</button>
        </div>
        <h2>Analogy Explanation</h2>
        {renderAnalogyExplanationTable(analogyExplanation)}
      </div>
    )}
    </div>
  );
};


export default SportsAnalogyFinder;
