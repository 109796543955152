export const APP_ROUTES = {
  AUTH_GOOGLE_CALLBACK: "/auth/google/callback",
  AUTH_TWITTER_CALLBACK: "/auth/twitter/callback",
  AUTH_APPLE_CALLBACK: "/auth/apple/callback",
};

export const AUTH_PROVIDERS = {
  GOOGLE: "google",
  TWITTER: "twitter",
  APPLE: "apple",
  EMAIL: "email",
};

export const AUTH_METHODS = {
  OPTIONS: [
    {
      provider: AUTH_PROVIDERS.GOOGLE,
      label: "Gmail",
      icon: "/brands/google.svg",
      authUrl: `${process.env.REACT_APP_API_URL}/auth/google`,
    },
/*     {
      provider: AUTH_PROVIDERS.TWITTER,
      label: "Twitter",
      icon: "/brands/twitter.svg",
      authUrl: `${process.env.REACT_APP_API_URL}/auth/twitter`,
    },
    {
      provider: AUTH_PROVIDERS.APPLE,
      label: "Apple Id",
      icon: "/brands/apple.svg",
      authUrl: `${process.env.REACT_APP_API_URL}/auth/apple`,
    }, */
    {
      provider: AUTH_PROVIDERS.EMAIL,
      label: "Email & Password",
      icon: "/icons/email.svg",
      authUrl: `/auth/email`,
    },
  ],
};
