// 1	"nba"
// 2	"nfl"
// 3	"nhl"
// 4	"fc"
// 5	"mlb"
// 6	"golf
// "
// 7	"nfl2"
// 8	"soccer2"
// 9	"f1"
// 10	"cfb"
// 11	"cbb"
// 12	"Movies"
// 20	"temp soccer"
// 13	"TV"
// 14	"Wrestling"
// 15	"Pokemon"
// 16	"pga
// "
// 17	"Sony Grid"
// 18	"Players Grid"
// 21	"pokemon"
// 22	"MOVIES"

export const SPORTS = [
  { id: 1, value: "nba", label: "NBA" },
  { id: 7, value: "nfl", label: "NFL" },
  { id: 3, value: "nhl", label: "NHL" },
  { id: 5, value: "mlb", label: "MLB" },


  { id: 21, value: "pokemon", label: "Pokemon" },

];
