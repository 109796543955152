const SPORT_IDS = {
  NBA: 1,
  NFL: 7,
  MLB: 5,
  NHL: 3,
  POKEMON: 21,
  MOVIES: 22,
};

const STATIC_HEADERS = {
  winner: "Winner!",
  loser: "Better luck next time...",
};

const GAME_OVER_TEXT = {
  [SPORT_IDS.NBA]: {
    winner: {
      header: STATIC_HEADERS.winner,
      body: "Slam Dunk! You nailed it! 🏀",
    },
    loser: {
      header: STATIC_HEADERS.loser,
      body: "Missed the shot. Try again! 🏀",
    },
  },
  [SPORT_IDS.NFL]: {
    winner: {
      header: STATIC_HEADERS.winner,
      body: "Touchdown! Victory is yours! 🏈",
    },
    loser: {
      header: STATIC_HEADERS.loser,
      body: "Fumbled the play. Next time! 🏈",
    },
  },
  [SPORT_IDS.MLB]: {
    winner: {
      header: STATIC_HEADERS.winner,
      body: "Home Run! You knocked it out of the park! ⚾️",
    },
    loser: {
      header: STATIC_HEADERS.loser,
      body: "Struck out. Step up again! ⚾️",
    },
  },
  [SPORT_IDS.NHL]: {
    winner: {
      header: STATIC_HEADERS.winner,
      body: "Goal! You scored! 🏒",
    },
    loser: {
      header: STATIC_HEADERS.loser,
      body: "Blocked shot. Keep pushing! 🏒",
    },
  },
  [SPORT_IDS.MOVIES]: {
    winner: {
      header: STATIC_HEADERS.winner,
      body: "Oscar-worthy performance! 🎬",
    },
    loser: {
      header: STATIC_HEADERS.loser,
      body: "Cut! Try another take! 🎬",
    },
  },
  [SPORT_IDS.POKEMON]: {
    winner: {
      header: STATIC_HEADERS.winner,
      body: "You caught 'em all! 🐾",
    },
    loser: {
      header: STATIC_HEADERS.loser,
      body: "Better train more. Keep going! 🐾",
    },
  },
};

export { GAME_OVER_TEXT, SPORT_IDS, STATIC_HEADERS };
