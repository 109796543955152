import { memo } from "react";
import propsTypes from "prop-types";
import TimerBar from "../TimerBar";

const Component = ({
  userId,
  isMyTurn,
  correctGuesses,
  isTimerRunning,
  currentTimer,
  username,
  opponentUserId,
  opponentUserName,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="scoreboard-font"
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-around",
          alignSelf: "center",
          width: "100%",
          maxWidth: "400px",
          margin: 0,
          fontFamily: "'DS-Digital', sans-serif", // Use DS-Digital font
        }}
      >
        <div
          style={isMyTurn ? { opacity: 1, flex: 2 } : { opacity: 0.4, flex: 2 }}
        >
          <p style={{ color: "#58D184", margin: 0 }}>Home</p>
          <p
            className="scoreboard-font"
            style={{ fontSize: "18pt", margin: 0 }}
          >
            {correctGuesses[userId]}
          </p>
          <p style={{ fontSize: "7pt", margin: 0 }} className="regular-font">
            {username}
          </p>
        </div>
        <TimerBar
          isTimerRunning={isTimerRunning}
          initialTime={currentTimer}
          isMyTurn={isMyTurn}
        />
        <div
          style={
            !isMyTurn ? { opacity: 1, flex: 2 } : { opacity: 0.4, flex: 2 }
          }
        >
          <p style={{ margin: 0, color: "#CC5500" }}>Away</p>
          <p
            className="scoreboard-font"
            style={{ margin: 0, fontSize: "18pt" }}
          >
            {correctGuesses[opponentUserId]}
          </p>
          <p style={{ fontSize: "7pt", margin: 0 }} className="regular-font">
            {opponentUserName}
          </p>
        </div>
      </div>
    </div>
  );
};

Component.propTypes = {
  userId: propsTypes.string.isRequired,
  isMyTurn: propsTypes.bool.isRequired,
  correctGuesses: propsTypes.object.isRequired,
  isTimerRunning: propsTypes.bool.isRequired,
  currentTimer: propsTypes.number.isRequired,
  username: propsTypes.string.isRequired,
  opponentUserId: propsTypes.string,
  opponentUserName: propsTypes.string,
};

export const Scoreboard = memo(Component);
