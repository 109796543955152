exports.getStatistics = (date) => {
  let localStorage;
  try {
    localStorage = window.localStorage;
  } catch (e) {
    // Access denied
    return {};
  }

  if (!localStorage) return {};

  const storageKey = `CrossoverGridTriviaFC-app-statistics-${date}`; // Dynamic key based on date
  try {
    return JSON.parse(localStorage.getItem(storageKey) || '{}');
  } catch {
    return {};
  }
};
exports.setStatistics = (date, object) => {
  let localStorage;
  try {
    localStorage = window.localStorage;
  } catch (e) {
    // Access denied
    return;
  }

  if (!localStorage) return;

  const storageKey = `CrossoverGridTriviaFC-app-statistics-${date}`; // Dynamic key based on date
  localStorage.setItem(storageKey, JSON.stringify(object));
};
