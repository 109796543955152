import React, { useState, useMemo } from "react";
import Navbar from "./Nav";
import { useQueryStats } from "./UserStats/useQueryStats";
import { SPORTS } from "./UserStats/userStats.constants";
import styles from "./userstats.module.scss";
import Lottie from "lottie-react";
import basketballAnimation from "../assets/lottie/basketball.json";
import { useTheme } from "@mui/material/styles";

const UserStats = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const {
    isLoading,
    username,
    stats,
    sportsStats,
    userGames,
  } = useQueryStats();

  const [selectedSport, setSelectedSport] = useState(null);

  const filteredGames = useMemo(() => {
    if (!selectedSport) {
      console.log("No sport selected, showing all games");
      return userGames;
    }
    console.log("Filtering games for sport ID:", selectedSport.sport_id);
    return userGames.filter(game => game.sport_id === selectedSport.sport_id);
  }, [userGames, selectedSport]);

  const handleTabClick = (sport) => {
    if (!sport?.id) {
      console.log("All sports selected");
      setSelectedSport(null);
    } else {
      const sportData = sportsStats?.find(
        ({ sport_id }) => sport_id === sport?.id
      );
      console.log("Selected sport data:", sportData);
      setSelectedSport(sportData);
    }
  };

  if (isLoading)
    return (
      <div className={styles.loader}>
        <Lottie
          animationData={basketballAnimation}
          style={{ width: 200, height: 200, marginBottom: 40 }}
        />
        <div>Loading...</div>
      </div>
    );

  return (
    <div className={`${styles.container} ${isDarkMode ? styles.darkMode : styles.lightMode}`}>
      <Navbar />
      <div className={styles.innerContainer}>
        <div className={styles.headerContainer}>
          <h1 className={styles.header}>{username}'s Stats</h1>
          <div className={styles.overallSummary}>
             
            <div className={styles.overallItem}>
              <p>Total Wins</p>
              <h3>{stats.wins}</h3>
            </div>
            <div className={styles.overallItem}>
              <p>Total Losses</p>
              <h3>{stats.losses}</h3>
            </div>
            <div className={styles.overallItem}>
              <p>Total Draws</p>
              <h3>{stats.draws}</h3>
            </div>
            <div className={styles.overallItem}>
              <p>Ranking</p>
              <h3>{stats.elo}</h3>
            </div>
          </div>
        </div>
        <div className={styles.tabContainer}>
          <button
            className={styles.tabButton}
            onClick={() => handleTabClick(null)}
          >
            All Sports
          </button>
          {SPORTS.map((sport) => (
            <button
              key={sport?.id}
              className={`${styles.tabButton} ${selectedSport?.sport_id === sport.id ? styles.active : ''}`}
              onClick={() => handleTabClick(sport)}
              disabled={
                sportsStats?.find(({ sport_id }) => sport_id === sport?.id) ===
                undefined
              }
            >
              {sport?.label}
            </button>
          ))}
        </div>
        <div className={styles.scrollableContent}>
          {!selectedSport ? (
            <div className={styles.summaryBox}>
              <h2 className={styles.subHeader}>All Sports Summary</h2>
              <div className={styles.summaryItem}>
                <p>Rank</p>
                <h3>{stats?.rank}</h3>
              </div>
              <div className={styles.summaryItem}>
                <p>Total Wins</p>
                <h3>{stats?.wins}</h3>
              </div>
              <div className={styles.summaryItem}>
                <p>Total Losses</p>
                <h3>{stats?.losses}</h3>
              </div>
              <div className={styles.summaryItem}>
                <p>Total Draws</p>
                <h3>{stats?.draws}</h3>
              </div>
              <div className={styles.summaryItem}>
                <p>Rating</p>
                <h3>{stats?.elo}</h3>
              </div>
            </div>
          ) : (
            <div>
              <div className={styles.summaryBox}>
                <h2 className={styles.subHeader}>
                  {`${selectedSport?.sport_name?.toUpperCase()} Summary`}
                </h2>
                <div className={styles.summaryItem}>
                  <p>Rank</p>
                  <h3>{selectedSport?.rank}</h3>
                </div>
                <div className={styles.summaryItem}>
                  <p>Wins</p>
                  <h3>{selectedSport?.wins}</h3>
                </div>
                <div className={styles.summaryItem}>
                  <p>Losses</p>
                  <h3>{selectedSport?.losses}</h3>
                </div>
                <div className={styles.summaryItem}>
                  <p>Draws</p>
                  <h3>{selectedSport?.draws}</h3>
                </div>
                <div className={styles.summaryItem}>
                  <p>Rating</p>
                  <h3>{selectedSport?.elo}</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeaderRow}>
              <th className={styles.tableHeader}>Date</th>
              <th className={styles.tableHeader}>Sport</th>
              <th className={styles.tableHeader}>Result</th>
              <th className={styles.tableHeader}>Opponent</th>
              <th className={styles.tableHeader}>Duration</th>
            </tr>
          </thead>
          <tbody>
            {filteredGames?.map((game, index) => (
              <tr
                key={`${game?.created_at}-${game?.sport_id}-${index}`}
                className={
                  index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd
                }
              >
                <td className={styles.tableCell}>{game?.created_at}</td>
                <td className={styles.tableCell}>
                  {game?.sport_name?.toUpperCase()}
                </td>
                <td className={styles.tableCell}>{game?.result}</td>
                <td className={styles.tableCell}>{game?.opponent}</td>
                <td className={styles.tableCell}>{game?.duration}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserStats;
