import Typography from "@mui/material/Typography";
import React from "react";
import "../../src/App.scss";
import mlb_logo from "../assets/mlb.svg";
import nba_logo from "../assets/nba.svg";
import nfl_logo from "../assets/nfl.svg";
import nhl_logo from "../assets/nhl.svg";

import fallbackPlayerImageNBA from "../assets/nba_silhouette.png";
import {
  abbreviateFullName,
  setBoxStyle,
  setNameContainerStyle,
} from "../utilities/boxStyles";

const GameBox = ({
  userId,
  row,
  col,
  selectedBoxIndex,
  handleBoxClick,
  playerInfo,
  guessedByUser,
  classes,
  maxLength,
  order,
  isMyTurn,
  sportId,
}) => {
  // we refer to the box number as follows:
  //
  //    1   2   3
  //    4   5   6
  //    7   8   9
  //

  const mySymbol = order === 0 ? "❌" : "⭕️";
  const opponentSymbol = order === 0 ? "⭕️" : "❌";
  const myColor = "#58D18475";
  const opponentColor = "#CC550060";

  const guessedByMe = playerInfo?.guessedBy === userId;

  // get the index
  const box_number = (row - 1) * 3 + col;
  const index = box_number - 1;

  const setBackgroundColor = () => {
    let s = setBoxStyle(index);
    if (guessedByMe) {
      //combine the setBoxStyle with the border style below
      return { ...s, border: `6px solid ${myColor}` };
      //   return { border: `6px solid ${myColor}` };
    } else if (playerInfo?.guessedBy !== userId && playerInfo) {
      return { ...s, border: `6px solid ${opponentColor}` };
    } else {
      return s;
    }
  };

  const getImageForSport = () => {
    switch (parseInt(sportId)) {
      case 1: // NBA
        return nba_logo;
      case 5: // MLB
        return mlb_logo;
      case 7: // NFL
        return nfl_logo;
      case 7: // NFL
        return nhl_logo;
      default:
        break;
    }
  };

  return (
    <div
      key={index}
      id={`box-${index}`}
      className={`box ${classes?.boxCard} ${
        selectedBoxIndex === index ? classes?.boxCardSelected : ""
      }`}
      style={(setBoxStyle(index), setBackgroundColor())}
      onClick={() => {
        if (!isMyTurn) return;
        // Only allow selection if the player has not been used
        // TODO FIX
        handleBoxClick(index);
      }}
    >
      {/* THIS SETS THE WATERMARK FOR THE GRID - FEELS A LITTLE HACKY BUT WORKS
           WOULD BE NICE IF WE COULD USE A DIFFERENT APPROACH, WHICH WOULD ENABLE US
           TO USE OVERFLOW: HIDDEN ON BOXES IN THE GRID AND HELP WITH HEADSHOT SIZE */}
      {index === 4 && (
        <img
          src={getImageForSport()}
          style={{
            position: "absolute",
            top: "-35%",
            left: "-30%",
            padding: 0,
            // zIndex: 1110,
            width: `165%`,
            height: "165%",
            maxWidth: "165%",
            opacity: 0.15,
            pointerEvents: "none",
          }}
        />
      )}
      {playerInfo && (
        <>
          {/* <p style={{ zIndex: 10000 }}>{playerInfo.guessedBy}</p> */}
          <div
            className="rarity"
            style={{
              position: "absolute",
              top: -6,
              left: -6,
              borderBottomRightRadius: "6px",
              borderTopLeftRadius: index === 0 ? "6px" : "0px",
            }}
          >
            <Typography
              variant="caption"
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                color: "transparent",
                // textShadow: `0 0 0 ${guessedByMe}`,
                textShadow: `0 0 0 white`,
              }}
            >
              {guessedByMe ? mySymbol : opponentSymbol}
            </Typography>
          </div>
          <div className="player-box">
            <img
              src={playerInfo && playerInfo?.img_url}
              alt={""}
              className="player-image"
              style={setBoxStyle(index)}
              onError={(event) => {
                // Replace the image source with the fallback image source
                event.target.src = fallbackPlayerImageNBA;
              }}
            />

            <div className="player-image-container"></div>
            <div
              className="player-name-container"
              style={{
                ...setNameContainerStyle(index),
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                zIndex: 1000,
                position: "absolute",
                bottom: -6,
                left: -6,
                right: -6,
                width: "calc(100% + 12px)",
              }}
            >
              <p
                variant="caption"
                className="player-name"
                style={{ maxLines: 1, color: "white", padding: 0, margin: 0 }}
              >
                {playerInfo?.name?.length > maxLength
                  ? abbreviateFullName(playerInfo?.name)
                  : playerInfo?.name}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GameBox;
