import PropTypes from "prop-types";
import React, { createContext } from "react";
import { MultiplayerProvider } from "./MultiplayerContext";
import { useUserContext } from "./UserContext";
import { useWebSocket } from "./hooks/useWebSocket";

// Create a context to provide the WebSocket connection to other components
const WebSocketContext = createContext(null);

export function WebSocketProvider({ children }) {
  const { username, userId } = useUserContext();

  const wsURL = `${process.env.REACT_APP_WS_URL}/${userId}/${username}`;
  // const wsURL = `wss://crossoverapi.com/${userId}/${username}`;

  const socket = useWebSocket(wsURL);

  return (
    <WebSocketContext.Provider value={socket}>
      <MultiplayerProvider>{children}</MultiplayerProvider>
    </WebSocketContext.Provider>
  );
}

WebSocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useWebSocketContext = () => {
  const context = React.useContext(WebSocketContext);

  return context;
};
