import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import throttle from "lodash.throttle"; // Import throttle instead of debounce

const useThrottle = (callback) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const throttledCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    // Adjust the time interval (e.g., 300ms) as needed
    return throttle(func, 500);
  }, []);

  return throttledCallback;
};

export default useThrottle;
