import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./OAuthCallbackHandler.module.scss";
import { useUserContext } from "../../contexts/UserContext";

const OAuthCallbackHandler = ({ provider }) => {
  const { checkAuth } = useUserContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const headers = { "Content-Type": "application/json" };
    const apiUrl = `${process.env.REACT_APP_API_URL}/auth/${provider}/callback?${searchParams}`;

    // Send GET request to backend
    fetch(apiUrl, {
      method: "GET",
      headers,
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Authentication successful:", data);
        checkAuth();
        navigate("/");
      })
      .catch((error) => {
        console.error("Error during authentication:", error);
        setError(error.message);
      })
      .finally(() => setLoading(false));
  }, [provider, navigate, checkAuth]);

  if (loading) {
    return <div className={styles.centered}>Signing you in...</div>;
  }

  if (error) {
    return <div className={styles.error}>Error: {error}</div>;
  }

  return <div></div>;
};

export default OAuthCallbackHandler;
