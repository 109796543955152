import React, { useState, useEffect, useRef, useMemo } from "react";
import Axios from "axios";
import "../../App.scss";
import { Helmet } from "react-helmet";
// replace with the actual path to your image
import appLogo from "../../Group 1.png"; // replace with the actual path to your image
import { makeStyles as makeMuiStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { Analytics } from "@vercel/analytics/react";
import { Button, Container } from "@mui/material";
import Twitter from "../../assets/twitter.svg";
import TwitterWhite from "../../assets/twitter_white.svg";
import useTheme from "@mui/material/styles/useTheme";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { AutoTextSize } from "auto-text-size";
import FlipNumbers from "../FlipNumbers/index";
import {
  VerticalBarSeries,
  XYPlot,
  makeWidthFlexible,
  LabelSeries,
  XAxis,
  HorizontalGridLines,
} from "react-vis";
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";
import { makeStyles } from "@mui/styles";
import useKeyPress from "../../hooks/keypress";
import MobileNavbar from "./MobileNavbar";
import fallbackPlayerImage from "../../assets/nba_silhouette.png";
import { useParams, useLocation } from "react-router-dom";
import Fuse from "fuse.js";
import styles from './sporcle.module.css'; // Importing sporcle styles

import sports from "../../sports";
import useDebounce from "../../utilities/debounce";

const SERVER_URL_NEW = "https://crossoverapi.com";
// const SERVER_URL_NEW = "https://dec9-71-219-22-1.ngrok-free.app";

const Quiz = ({ page_name, quiz_name }) => {
  let { state } = useLocation();
  let sport_id = state?.sport_id || null;
  const { grid_id, box_id } = useParams();

  const isDesktop = window.innerWidth >= 800;
  const maxLength = isDesktop >= 800 ? 30 : 18;
  const [shareText, setShareText] = useState("");
  const [gameStarted, setGameStarted] = useState(false);

  //KEYPRESSES
  const [selected, setSelected] = useState(undefined);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [img_left, setImgLeft] = React.useState(state?.img_left || "");
  const [img_right, setImgRight] = React.useState(state?.img_right || "");
  const [team_left, setTeamLeft] = React.useState(state?.team_left || "");
  const [team_right, setTeamRight] = React.useState(state?.team_right || "");

  const [playerData, setPlayerData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [guessedPlayers, setGuessedPlayers] = useState([]);
  const [timePassed, setTimePassed] = useState(null);
  const [isGameOver, setIsGameOver] = useState(false);
  const [useTimer, setUseTimer] = useState(false);
  const [boxElements, setBoxElements] = useState();
  const [answerKey, setAnswerKey] = useState([]);
  const [answerKeyNames, setAnswerKeyNames] = useState([]);
  const [focused, setFocused] = useState(false);
  const debouncedRequest = useDebounce(async () => {
    let sr = await Axios.get(
      `${SERVER_URL_NEW}/game/fuse/search/players?sportId=${sport_id}&name=${searchInput}`,
      {}
    );
    setSearchResults(sr.data);
  });

  const handleInputChange = async (event) => {
    const input = event.target.value;
    setSearchInput(input);
    debouncedRequest();

    // if (input.trim().length > 1) {
    // const sanitizedInput = removeAccents(input.toLowerCase()).replace(
    //   /[~']/g,
    //   ""
    // );
    // const filteredResults = playerData.filter((item) => {
    // const sanitizedPlayerName = removeAccents(
    //   item.name.toLowerCase()
    // ).replace(/[~']/g, "");
    // console.log(sanitizedPlayerName);
    //   item.name.toLowerCase().includes(sanitizedInput);
    // });
    // const filteredResults = playerData.filter((item) =>
    //   removeAccents(item.name.toLowerCase()).includes(
    //     removeAccents(input.toLowerCase())
    //   )
    // );

    // setSearchResults(results);
    // } else {
    // setSearchResults([]);
    // }
  };
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useEffect(() => {
    if (searchResults.length && downPress) {
      setCursor((prevState) =>
        prevState < searchResults.length - 1 ? prevState + 1 : prevState
      );
    }
  }, [downPress]);
  useEffect(() => {
    if (searchResults.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);
  useEffect(() => {
    if (searchResults.length && enterPress) {
      handleResultsClick(searchResults[cursor]);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (searchResults.length && hovered) {
      setCursor(searchResults.indexOf(hovered));
    }
  }, [hovered]);

  const useStyles = makeMuiStyles((theme) => ({
    root: {
      flexGrow: 1,
      // padding: 2,  // 2 or '2px' or '2rem', as per your requirements
    },
    card: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    close: {
      position: "absolute",
      // right: 1,  // 1 or '1px' or '1rem', as per your requirements
      // top: 1,  // 1 or '1px' or '1rem', as per your requirements
      color: "#bdbdbd", // color from theme.palette.grey[500]
    },
    boxCard: {
      backgroundColor: theme.palette.background.gridCard,
      "&:hover": {
        backgroundColor: theme.palette.background.gridCardHover,
      },
    },
    boxCardSelected: {
      backgroundColor: theme.palette.background.gridCardSelect,
    },
    link: {
      color: theme.palette.text.link,
      fontSize: isDesktop ? "16px" : "12px",
      fontWeight: "600",
    },
    searchBox: {
      backgroundColor: theme.palette.background.blurCard,
      position: "relative",
      backdropFilter: "blur(10px)",
      color: theme.palette.text.primary,
    },
    searchInput: {
      color: theme.palette.text.primary,
    },
    result: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      cursor: "pointer",
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: theme.palette.background.resultHover,
      },
    },
    resultActive: {
      backgroundColor: theme.palette.background.resultHover,
    },
    sponsorshipLink: {
      marginTop: "20px",
      color: theme.palette.text.link,
      textDecoration: "none",
      borderBottom: `1px solid ${theme.palette.text.link}`,
    },
    sponsorshipLinkUnderline: {
      color: theme.palette.text.link,
      textDecoration: "none",
    },
    remainingGuesses: {
      position: "absolute",
      right: isDesktop ? "min(calc(90vw - 400px), 5vw)" : 0,
      top: "50%",
    },
    barLabels: {
      fontSize: "12px",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
    barLabelsXAxis: {
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: "bold",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
    stickyContainer: {
      backgroundColor: theme.palette.background.default,
    },
    searchInput: {
      border: "none",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      width: "100%",
      paddingTop: "5px",
      paddingBottom: "5px",
      marginTop: "10px",
      fontSize: "16px",
    },
  }));

  const classes = useStyles();
  let currentSport = sports.find((sport) => sport.id === sport_id?.toString());
  let sportEmoji = currentSport?.emoji;

  const restartGame = () => {
    setIsGameOver(false);
    setGuessedPlayers([]);
    // setBoxElements(initialBoxes);
    setFeedbackMessage("");
    setSearchInput("");
    // setSearchResults([]);
    setTimePassed(0);
    setUseTimer(true);
  };
  const inputRef = useRef(null);
  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const [adRefresh, setAdRefresh] = React.useState(0);

  const onAdRefresh = () => {
    setAdRefresh(adRefresh + 1);
  };

  const placementName = "crossovergrid_sticky_footer";
  const publisher = "crossovergrid-com";
  const targeting = { key1: "value1", key2: "value2" };

  // Initialize Google Analytics
  ReactGA.initialize("G-MHGRFQTFDL");
  useEffect(() => {
    if (isGameOver) {
      const scoreText = `Score: ${guessedPlayers.length} / ${answerKey.length}`;
      const timeText = `Time: ${Math.floor(timePassed / 60)}:${String(
        timePassed % 60
      ).padStart(2, "0")}`;
      const shareContent = `Crossover Trivia - ${team_left} & ${team_right} ${sportEmoji}\n🏆${scoreText}\n⏲️${timeText}\nPlay here: ${window.location.href}`;
      setShareText(shareContent);
    }
  }, [isGameOver, guessedPlayers, answerKey, quiz_name]);

  useEffect(() => {
    // Use page_name for Google Analytics
    ReactGA.pageview(`/${page_name}`);
  }, [sport_id, page_name]);

  useEffect(() => {
    console.log("HELLO?");
    Axios.get(`${SERVER_URL_NEW}/quiz/${grid_id}/${box_id}`, {})
      .then((res) => {
        let { data } = res;
        let { answer_key, img_left, img_right, team_left, team_right } = data;
        setTeamLeft(team_left);
        setTeamRight(team_right);
        setImgLeft(img_left);
        setImgRight(img_right);
        Axios.get(`${SERVER_URL_NEW}/game/players/${data.sport_id}`, {}).then(
          (response) => {
            setPlayerData(response.data);
            // Filter the answerKey to ensure all players exist in the fetched playerData
            const validAnswerKey = answer_key.filter((p) =>
              response.data.some((player) => player.id === p.id)
            );
            setAnswerKey(validAnswerKey.sort((a, b) => a.seasons - b.seasons));
            setBoxElements(
              Array.from({ length: validAnswerKey.length }).fill(null)
            );
          }
        );
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (useTimer && timePassed !== null && timePassed >= 0) {
      const timer = setTimeout(() => setTimePassed(timePassed + 1), 1000);
      return () => clearTimeout(timer);
    }
    // else if (useTimer && timePassed === 0) {
    //   setIsGameOver(true);
    // }
  }, [timePassed, useTimer]);



  const theme = useTheme();

  if (theme.palette.mode === "dark") {
    document.body.setAttribute("data-theme", "dark");
  } else {
    document.body.removeAttribute("data-theme");
  }
  const handleResultsClick = async (player) => {
    console.log("Selected Player:", player);
    
    // Here, we check for correct answers by comparing both the id and the name.
    const answerIsCorrect = answerKey.some((obj) => obj.id === player.id && obj.name === player.name);
    const alreadyGuessed = guessedPlayers.some((obj) => obj.id === player.id && obj.name === player.name);
  
    if (answerIsCorrect && !alreadyGuessed) {
      setGuessedPlayers((prev) => [...prev, { id: player.id, name: player.name }]);
      setBoxElements((prevState) => {
        const newState = [...prevState];
        const index = answerKey.findIndex((obj) => obj.id === player.id && obj.name === player.name);
  
        if (index !== -1) {
          newState[index] = { ...player, selected: true };
        }
  
        return newState;
      });
      setFeedbackMessage("Correct!");
    } else if (alreadyGuessed) {
      setFeedbackMessage("Already guessed!");
    } else {
      setFeedbackMessage("Incorrect. Try again.");
    }
    setSearchInput("");
    focusInput();
  
    await debouncedRequest();
  };
  
  


  const handleStartGame = () => {
    setGameStarted(true);
  };

  const endGame = () => {
    setIsGameOver(true);
    if (useTimer) setUseTimer(false);
  };
  return (
    <div className="quiz-sporcle-container">
      <MobileNavbar />
      <Container maxWidth={"md"}>
        <Helmet>
          <title>{quiz_name}</title>
        </Helmet>
  
        <div
          style={{
            width: "100%",
            minHeight: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant={"h2"}>
            <Typography
              component={"h1"}
              variant={"h4"}
              style={{ fontWeight: "600", textAlign: "center" }}
            >
              {team_left} & {team_right}
            </Typography>
          </Typography>
          <Typography sx={{ textAlign: "center", marginTop: "20px" }}>
            🌟 Can You Guess Them All? 🌟
            <br />
            Guess the players that fit in both categories above, and see who you
            missed! Give up any time to see all the answers.
          </Typography>
        </div>
     
        <main>
        <div className={styles.stickyHeader}>
  <div className={styles.scoreCounter}>Score: {guessedPlayers.length} / {answerKey?.length}</div>
  <div className="inputAndButtonContainer">
  <input
    ref={inputRef}
    value={searchInput}
    onFocus={onFocus}
    onBlur={onBlur}
    onChange={handleInputChange}
    placeholder="Enter player name..."
    className="search-input"
    disabled={isGameOver}
  />
  <div className="search-results">
    {searchResults.map((result, i) => (
      <div
        key={result.refIndex}
        onClick={() => !isGameOver && handleResultsClick(result.item)}
        onTouchEnd={() => !isGameOver && handleResultsClick(result.item)}
        onMouseOver={() => setHovered(result.item)}
        onMouseOut={() => setHovered(undefined)}
        className={`search-result-item ${i === cursor ? 'resultActive' : ''}`}
        style={{ cursor: isGameOver ? "default" : "pointer" }}
      >
        {result.item.name}
                      {i === cursor && isDesktop && (
                        <div
                          style={{
                            opacity: 0.5,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={require("../../assets/return.png")}
                            width={"30px"}
                            height={"30px"}
                          />

                        </div>
                        
                      )}
                    </div>
                  ))}
        </div>
        {/* Conditional rendering for See Answers button */}
     {/* See Answers button */}
     {gameStarted && !isGameOver && (
      <button
        onClick={endGame}
        className="seeAnswersButton"
      >
        See Answers
      </button>
    )}
  </div>
    
  {/* Timer display */}
  {!gameStarted ? (
    <button
      onClick={() => {
        setGameStarted(true);
        setUseTimer(true);
        setTimePassed(0);
      }}
      className="startButton"
    >
      ⏱ Start
    </button>
  ) : (
    <div className="timerDisplay">
      {Math.floor(timePassed / 60)}:{String(timePassed % 60).padStart(2, "0")}
    </div>
  )}

    
        </div>
          <div className="share-container">
            {isGameOver && (
              <div className="share-buttons">
                <button
                  onClick={() => navigator.clipboard.writeText(shareText)}
                >
                  Copy Score
                </button>
                <a
                  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    shareText
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share on Twitter
                </a>
              </div>
            )}
          </div>
  
  
          {answerKey && answerKey.length > 0 && (
            <div className="guessed-players-list">
              {answerKey.map((player, index) => {
                const isGuessed = guessedPlayers.some((gp) => gp.id === player.id && gp.name === player.name);
                const isCorrectlyGuessed = isGuessed && player;
  
                return (
                  <div
                    key={index}
                    className={`player-item ${
                      isCorrectlyGuessed ? "guessed" : ""
                    } ${isGameOver && !player ? "missed" : ""}`}
                  >
                    <div className="square-container">
                      <div className="square">
                        {gameStarted ? (
                          <img
                            src={player?.img_url}
                            alt="Square"
                            className={`square-image ${
                              isCorrectlyGuessed ? "guessed-border" : ""
                            }`}
                            onError={(event) => {
                              // Replace the image source with the fallback image source
                              event.target.src = fallbackPlayerImage;
                            }}
                            style={{
                              filter:
                                isGameOver || isGuessed ? "none" : "blur(8px)",
                            }}
                          />
                        ) : (
                          <img
                            src={require("../../assets/nba_silhouette.png")}
                            alt="Square"
                            className="square-image"
                            onError={(event) => {
                              // Replace the image source with the fallback image source
                              event.target.src = fallbackPlayerImage;
                            }}
                            style={{ filter: "blur(8px)" }}
                          />
                        )}
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: "30px",
                            backgroundColor: "#00000080",
                            color: "#ffffff",
                            filter: gameStarted ? "none" : "blur(5px)",
                            overflow: "hidden",
                          }}
                        >
                          {(isCorrectlyGuessed || isGameOver) &&
                            (player?.name || "N/A")}
                          {!isCorrectlyGuessed &&
                            player &&
                            !isGameOver &&
                            (player?.seasons || "N/A")}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </main>
      </Container>
    </div>
  );
};

export default Quiz;