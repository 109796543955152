import React from "react";
import { observer } from "mobx-react-lite";
import TileRow from "../TileRow";
import KeyboardTile from "./KeyboardTile";

const Keyboard = observer(({ options, game, onSelect, onBackspace, onEnter }) => {
  const rows = [
    options.slice(0, 5),
    options.slice(5, 10),
    options.slice(10, 15),
    options.slice(15, 20),
  ];

  const lastRow = [
    { id: 'enter', text: 'ENTER', img_url: '', onClick: onEnter },
    ...options.slice(20),
    { id: 'backspace', text: '⌫', img_url: '', onClick: onBackspace }
  ];

  return (
    <div style={{ maxWidth: '95%' }}>
      {rows.map((row, i) => (
        <TileRow key={i} variant="keyboard">
          {row.map((option) => (
            <KeyboardTile
              key={option.id}
              text={option.text}
              img_url={option.img_url}
              onClick={() => onSelect(option)}
              styles={game.keyboardColors[option.id]
                ? { backgroundColor: game.keyboardColors[option.id] }
                : {}
              }
            />
          ))}
        </TileRow>
      ))}
      <TileRow variant="keyboard">
        {lastRow.map((option) => {
          const isSpecialKey = option.id === 'enter' || option.id === 'backspace';
          return (
            <KeyboardTile
              key={option.id}
              text={option.text}
              img_url={option.img_url}
              onClick={isSpecialKey ? option.onClick : () => onSelect(option)}
              styles={{
                ...(isSpecialKey && { borderRadius: '17px' }),
                ...(game.keyboardColors[option.id]
                  ? { backgroundColor: game.keyboardColors[option.id] }
                  : {}),
              }}
              textStyle={isSpecialKey ? { fontSize: '1rem' } : {}}
            />
          );
        })}
      </TileRow>
    </div>
  );
});

export default Keyboard;
