import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

const UserMenu = (props) => {
  const { anchorEl, onClose, open } = props;
  const { logout } = useContext(UserContext);

  const handleClose = () => {
    onClose();
  };

  const handleLogOut = (authUrl) => {
    logout();
    onClose();
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
    </Menu>
  );
};

export default UserMenu;
