import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Check, Close, ModeEdit } from "@mui/icons-material";
import { useUserContext } from "../../../contexts/UserContext";
import Navbar from "../../Nav";
import SportButton from "../../SportButton";
import supportedSports from "../../supportedSports";
import { ImageTicTacToe, NeedLogin } from "./components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Page = () => {
  const [isEditUsername, setIsEditUserName] = useState(false);
  const navigate = useNavigate();
  const profile = useUserContext();
  const username = profile?.user?.username;
  const isAuthenticated = !!username;
  const isDesktop = window.innerWidth >= 800;
  const [newUsername, setNewUserName] = useState(username);

  const handleEditUser = () => {
    if (isEditUsername) {
      // Save on Click
      profile?.onChangeUsername?.(newUsername);
    }

    setIsEditUserName((prev) => !prev);
  };

  useEffect(() => {
    setNewUserName(username);
  }, [username]);

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        overflow: "scroll",
        paddingBottom: isDesktop ? 20 : 100, // Adjusted padding for mobile devices
      }}
    >
      <Navbar />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {!isAuthenticated ? (
        <NeedLogin />
      ) : (
        <div
          style={{
            top: 20,
            position: "absolute",
            left: 0,
            width: "100%",
            textAlign: "center",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            paddingBottom: 100, // Added padding to the bottom
          }}
        >
          <ImageTicTacToe />
          <Typography>Welcome to Crossover Live</Typography>
          <p
            style={{
              fontSize: 26,
              fontWeight: "bold",
              marginTop: 10,
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
            }}
            className={`sign-font`}
          >
            {!isEditUsername && <>{username ?? ""}</>}
            {!isEditUsername && (
              <IconButton onClick={handleEditUser}>
                <ModeEdit />
              </IconButton>
            )}
            {!!isEditUsername && (
              <TextField
                autoFocus
                defaultValue={username}
                variant="filled"
                onChange={(e) => setNewUserName(e.target.value)}
              />
            )}
            {!!isEditUsername && (
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <IconButton
                  onClick={handleEditUser}
                  sx={{ border: "1px solid #fff" }}
                >
                  <Check />
                </IconButton>
                <IconButton
                  onClick={() => setIsEditUserName(false)}
                  sx={{ border: "1px solid #fff" }}
                >
                  <Close />
                </IconButton>
              </Box>
            )}
          </p>
          <Typography
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              fontSize: 16,
            }}
          >
            Ready to play? {isDesktop ? "Click " : "Tap "} a sport below to play
            head to head against other sports fans.
          </Typography>
          <div
            style={{
              marginTop: 40,
              display: "flex",
              flexDirection: "row",
              flexFlow: "row wrap",
              width: "100vw",
              alignItems: "center",
              justifyContent: "center",
              gap: isDesktop ? 25 : 10,
            }}
          >
            {supportedSports.map((sport, index) => (
              <SportButton
                key={sport.id}
                index={index}
                sport={sport}
                onClick={() =>
                  navigate(`/play/waiting`, { state: { sport_id: sport.id } })
                }
              />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <button
              onClick={() => navigate("/user/stats")}
              style={styles.button}
            >
              View Your Stats
            </button>
            <button
              onClick={() => navigate("/leaderboard")}
              style={styles.button}
            >
              View Leaderboard
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  button: {
    backgroundColor: "#4682B4", // Complementary color to #A3E3BA
    color: "#ffffff",
    border: "none",
    padding: "10px 20px",
    borderRadius: 4,
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "16px",
    transition: "background-color 0.3s ease",
    margin: "0 5px",
  },
};

export const LockerRoom = Page;
export default LockerRoom;
