import React from 'react';
import User from "../../../Auth/User";
import styles from './NeedLogin.module.scss';
import screenshot from "../../../../assets/screenshot.png";
import useTheme from "@mui/material/styles/useTheme";

export const NeedLogin = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <div className={`${styles.needLoginContainer} ${isDarkMode ? styles.darkMode : styles.lightMode}`}>
      <div className={styles.contentWrapper}>
        <h1 className={styles.needLoginTitle}>Welcome to Crossover Live</h1>
        <p className={styles.needLoginText}>
          Log in to participate in Crossover Live, an interactive game that combines sports trivia, movie knowledge, and Pokémon mastery in a dynamic and competitive format.
        </p>
        <div className={styles.signInButtonWrapper}>
          <User />
        </div>
        <div className={styles.imageWrapper}>
          <img 
            src={screenshot} 
            alt="Screenshot of the game" 
            className={styles.embedImage}
          />
        </div>
      </div>
    </div>
  );
};
