import { memo } from "react";
import { ColumnHeader, RowHeader } from "../GameHeader";
import GameBox from "../GameBox";

import propsTypes from "prop-types";

const Component = ({
  classes,
  cols,
  rows,
  board,
  maxLength,
  isMyTurn,
  sportId,
  order,
  username,
  userId,
  onSelectedBoxIndex,
  onBoxClick,
}) => {
  const renderGameBox = (row, col) => {
    const box_number = (row - 1) * 3 + col;
    const index = box_number - 1;

    return (
      <GameBox
        key={(row - 1) * 3 + col - 1}
        row={row}
        col={col}
        selectedBoxIndex={onSelectedBoxIndex}
        playerInfo={board[index]}
        guessedByUser={board[index]?.guessedBy === userId}
        classes={classes}
        handleBoxClick={onBoxClick}
        maxLength={maxLength}
        username={username}
        userId={userId}
        order={order}
        isMyTurn={isMyTurn}
        sportId={sportId}
      />
    );
  };

  return (
    <div className="grid">
      <div className="placeholder-header" />
      <ColumnHeader teamData={cols[0]} />
      <ColumnHeader teamData={cols[1]} />
      <ColumnHeader teamData={cols[2]} />
      <RowHeader teamData={rows[0]} />
      {renderGameBox(1, 1)}
      {renderGameBox(1, 2)}
      {renderGameBox(1, 3)}
      <RowHeader teamData={rows[1]} />
      {renderGameBox(2, 1)}
      {renderGameBox(2, 2)}
      {renderGameBox(2, 3)}
      <RowHeader teamData={rows[2]} />
      {renderGameBox(3, 1)}
      {renderGameBox(3, 2)}
      {renderGameBox(3, 3)}
    </div>
  );
};

Component.propTypes = {
  classes: propsTypes.object.isRequired,
  cols: propsTypes.array.isRequired,
  rows: propsTypes.array.isRequired,
  board: propsTypes.array.isRequired,
  maxLength: propsTypes.number.isRequired,
  isMyTurn: propsTypes.bool.isRequired,
  sportId: propsTypes.number.isRequired,
  order: propsTypes.number.isRequired,
  username: propsTypes.string.isRequired,
  userId: propsTypes.string.isRequired,
  onSelectedBoxIndex: propsTypes.func.isRequired,
  onBoxClick: propsTypes.func.isRequired,
};

export const PlaceholderGrid = memo(Component);
