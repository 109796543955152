// Filename: UserInputComponent.js

import React, { useState, useEffect, useRef, useMemo } from "react";
// import GameController from "./GameController";
// import { Button, Typography, Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";

import { useNavigate, useSearchParams } from "react-router-dom";
import { makeStyles as makeMuiStyles } from "@mui/styles";

// import lockerRoomImage from "../assets/locker.jpg";
// import boardImage from "../assets/board.png";
// import supportedSports from "./supportedSports";
// import Lottie from "lottie-react";
import Navbar from "../Nav";
// import SportButton from "./SportButton";
// import Leaderboard from "./Leaderboard";
import Helmet from "react-helmet";
import GameInterface from "./GameInterface/GameInterface";
import { CrossoverGridTrivia } from "./CrossoverGridTrivia";
import crossover_logo from "../../assets/nba.svg";
import Twitter from "../../assets/twitter.svg";
import TwitterWhite from "../../assets/twitter_white.svg";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FadeMenu from "./FadeMenu";
import StatisticsModal from "./Modals/StatisticsModal";
import PrimeUnauth from "./Prime/PrimeUnauth";
import Backdrop from "@mui/material/Backdrop";
import { Checkbox, Container, Dialog, FormControlLabel } from "@mui/material";
import HowToPlayModal from "./Modals/HowToPlay"; // Import the modal component
import axios from "axios";
import { Link } from "react-router-dom";
import sports from "../../sports";

const {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} = require("unique-names-generator");
let mvp = {
  problem: {
    prompt: "MVP Voting 2023-24",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Nikola Joki\u0107",
        WS: "17.0",
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 2,
        name: "Shai Gilgeous-Alexander",
        WS: "14.6",
        "Player-additional": "gilgesh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628983.png",
      },
      {
        id: 3,
        name: "Giannis Antetokounmpo",
        WS: "13.2",
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203507.png",
      },
      {
        id: 4,
        name: "Domantas Sabonis",
        WS: "12.6",
        "Player-additional": "sabondo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627734.png",
      },
      {
        id: 5,
        name: "Jalen Brunson",
        WS: "11.2",
        "Player-additional": "brunsja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628973.png",
      },
      {
        id: 8,
        name: "Luka Don\u010di\u0107",
        WS: "12.0",
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 6,
        name: "Anthony Davis",
        WS: "11.8",
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203076.png",
      },
      {
        id: 7,
        name: "Rudy Gobert",
        WS: "11.6",
        "Player-additional": "goberru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203497.png",
      },

      {
        id: 9,
        name: "Jarrett Allen",
        WS: "10.7",
        "Player-additional": "allenja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628386.png",
      },
      {
        id: 10,
        name: "Jayson Tatum",
        WS: "10.4",
        "Player-additional": "tatumja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628369.png",
      },
      {
        id: 11,
        name: "Devin Booker",
        WS: "9.2",
        "Player-additional": "bookede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626164.png",
      },
      {
        id: 12,
        name: "DeMar DeRozan",
        WS: "9.2",
        "Player-additional": "derozde01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201942.png",
      },
      {
        id: 13,
        name: "Jimmy Butler",
        WS: "9.1",
        "Player-additional": "butleji01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202710.png",
      },
      {
        id: 14,
        name: "Tyrese Haliburton",
        WS: "9.0",
        "Player-additional": "halibty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630169.png",
      },
      {
        id: 15,
        name: "Chet Holmgren",
        WS: "8.9",
        "Player-additional": "holmgch01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631096.png",
      },
      {
        id: 16,
        name: "Kawhi Leonard",
        WS: "8.9",
        "Player-additional": "leonaka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202695.png",
      },
      {
        id: 17,
        name: "LeBron James",
        WS: "8.5",
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 18,
        name: "Derrick White",
        WS: "8.5",
        "Player-additional": "whitede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628401.png",
      },
      {
        id: 19,
        name: "Fred VanVleet",
        WS: "8.4",
        "Player-additional": "vanvlfr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627832.png",
      },
      {
        id: 20,
        name: "James Harden",
        WS: "8.4",
        "Player-additional": "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 21,
        name: "Kevin Durant",
        WS: "8.3",
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 22,
        name: "Mike Conley",
        WS: "8.2",
        "Player-additional": "conlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201144.png",
      },
      {
        id: 23,
        name: "Tyrese Maxey",
        WS: "8.1",
        "Player-additional": "maxeyty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630178.png",
      },
    ],
  },
};
let apg12345 = {
  problem: {
    prompt: "AST/G from 2000-2010-11",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Chris Paul",
        "AST/G": "9.9",
        "Player-additional": "paulch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101108.png",
      },
      {
        id: 2,
        name: "Steve Nash",
        "AST/G": "9.8",
        "Player-additional": "nashst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/959.png",
      },
      {
        id: 3,
        name: "Deron Williams",
        "AST/G": "9.2",
        "Player-additional": "willide01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101114.png",
      },
      {
        id: 4,
        name: "Jason Kidd",
        "AST/G": "9.1",
        "Player-additional": "kiddja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/429.png",
      },
      {
        id: 5,
        name: "John Wall",
        "AST/G": "8.3",
        "Player-additional": "walljo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202322.png",
      },
      {
        id: 6,
        name: "John Stockton",
        "AST/G": "8.2",
        "Player-additional": "stockjo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/304.png",
      },
      {
        id: 7,
        name: "Baron Davis",
        "AST/G": "7.7",
        "Player-additional": "davisba01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1884.png",
      },
      {
        id: 8,
        name: "Terrell Brandon",
        "AST/G": "7.7",
        "Player-additional": "brandte01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/210.png",
      },
      {
        id: 9,
        name: "Rajon Rondo",
        "AST/G": "7.6",
        "Player-additional": "rondora01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200765.png",
      },
      {
        id: 10,
        name: "Andre Miller",
        "AST/G": "7.3",
        "Player-additional": "millean02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1889.png",
      },
      {
        id: 11,
        name: "Stephon Marbury",
        "AST/G": "7.3",
        "Player-additional": "marbust01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/950.png",
      },
      {
        id: 12,
        name: "Russell Westbrook",
        "AST/G": "7.1",
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 13,
        name: "LeBron James",
        "AST/G": "7.0",
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 14,
        name: "Jos\u00e9 Calder\u00f3n",
        "AST/G": "7.0",
        "Player-additional": "caldejo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101181.png",
      },
      {
        id: 15,
        name: "Raymond Felton",
        "AST/G": "6.7",
        "Player-additional": "feltora01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101109.png",
      },
      {
        id: 16,
        name: "Derrick Rose",
        "AST/G": "6.7",
        "Player-additional": "rosede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201565.png",
      },
      {
        id: 17,
        name: "Jamaal Tinsley",
        "AST/G": "6.6",
        "Player-additional": "tinslja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2224.png",
      },
      {
        id: 18,
        name: "Dwyane Wade",
        "AST/G": "6.3",
        "Player-additional": "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2548.png",
      },
      {
        id: 19,
        name: "Allen Iverson",
        "AST/G": "6.3",
        "Player-additional": "iversal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/947.png",
      },
      {
        id: 20,
        name: "Gary Payton",
        "AST/G": "6.2",
        "Player-additional": "paytoga01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/56.png",
      },
      {
        id: 21,
        name: "Mark Jackson",
        "AST/G": "6.1",
        "Player-additional": "jacksma01",
        img_url:
          "https://www.si.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MjAyMjU2MjYxMjkzNTQ4NTU2/mark-jackson.jpg",
      },
      {
        id: 22,
        name: "Sam Cassell",
        "AST/G": "6.1",
        "Player-additional": "cassesa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/208.png",
      },
      {
        id: 23,
        name: "Steve Francis",
        "AST/G": "5.9",
        "Player-additional": "francst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1883.png",
      },
    ],
  },
};
let conferencefinalsblocks = {
  problem: {
    prompt:
      "Most Blocks Amongst Center in Conference Championship Games Since 1981",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Shaquille O'Neal",
        BLK: "103",
        "Player-additional": "onealsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/406.png",
      },
      {
        id: 2,
        name: "Hakeem Olajuwon",
        BLK: "88",
        "Player-additional": "olajuha01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/165.png",
      },
      {
        id: 3,
        name: "Kareem Abdul-Jabbar",
        BLK: "69",
        "Player-additional": "abdulka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76003.png",
      },
      {
        id: 4,
        name: "Robert Parish",
        BLK: "58",
        "Player-additional": "parisro01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/305.png",
      },
      {
        id: 5,
        name: "David Robinson",
        BLK: "45",
        "Player-additional": "robinda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/764.png",
      },
      {
        id: 6,
        name: "Alonzo Mourning",
        BLK: "41",
        "Player-additional": "mournal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/297.png",
      },
      {
        id: 7,
        name: "Dwight Howard",
        BLK: "39",
        "Player-additional": "howardw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2730.png",
      },
      {
        id: 8,
        name: "Patrick Ewing",
        BLK: "37",
        "Player-additional": "ewingpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/121.png",
      },
      {
        id: 9,
        name: "Pau Gasol",
        BLK: "34",
        "Player-additional": "gasolpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2200.png",
      },
      {
        id: 10,
        name: "Kendrick Perkins",
        BLK: "30",
        "Player-additional": "perkike01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2570.png",
      },
      {
        id: 11,
        name: "Bob McAdoo",
        BLK: "29",
        "Player-additional": "mcadobo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77498.png",
      },
      {
        id: 12,
        name: "Al Horford",
        BLK: "28",
        "Player-additional": "horfoal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201143.png",
      },
      {
        id: 13,
        name: "Mark West",
        BLK: "28",
        "Player-additional": "westma01",
        img_url:
          "https://cdn.nba.com/teams/legacy/www.nba.com/suns/sites/suns/files/markwest.jpg",
      },
      {
        id: 14,
        name: "Chris Andersen",
        BLK: "26",
        "Player-additional": "anderch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2365.png",
      },
      {
        id: 15,
        name: "Greg Ostertag",
        BLK: "26",
        "Player-additional": "ostergr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/731.png",
      },
      {
        id: 16,
        name: "Marcus Camby",
        BLK: "25",
        "Player-additional": "cambyma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/948.png",
      },
      {
        id: 17,
        name: "Alton Lister",
        BLK: "24",
        "Player-additional": "listeal01",
        img_url:
          "https://www.legendsofbasketball.com/wp-content/uploads/2013/07/alton-lister.jpg",
      },
      {
        id: 18,
        name: "Rik Smits",
        BLK: "24",
        "Player-additional": "smitsri01",
        img_url:
          "https://vz.cnwimg.com/wp-content/uploads/2014/12/riksmits.jpg",
      },
      {
        id: 19,
        name: "Vlade Divac",
        BLK: "23",
        "Player-additional": "divacvl01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/124.png",
      },
      {
        id: 20,
        name: "Joel Anthony",
        BLK: "22",
        "Player-additional": "anthojo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201202.png",
      },
      {
        id: 21,
        name: "Ervin Johnson",
        BLK: "22",
        "Player-additional": "johnser02",
        img_url:
          "https://cdn.nba.com/teams/legacy/www.nba.com/timberwolves/sites/timberwolves/files/legacy/photos/johnson2_254_031020.jpg",
      },
      {
        id: 22,
        name: "Robert Williams",
        BLK: "21",
        "Player-additional": "williro04",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4066211.png&w=350&h=254",
      },
      {
        id: 23,
        name: "Marc Gasol",
        BLK: "20",
        "Player-additional": "gasolma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201188.png",
      },
    ],
  },
};
let conferencefinalspoints = {
  problem: {
    prompt: "Most Conference Finals Points Scored",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "LeBron James",
        PTS: "1995",
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 2,
        name: "Kareem Abdul-Jabbar",
        PTS: "1588",
        "Player-additional": "abdulka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76003.png",
      },
      {
        id: 3,
        name: "Michael Jordan",
        PTS: "1396",
        "Player-additional": "jordami01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/893.png",
      },
      {
        id: 4,
        name: "Shaquille O'Neal",
        PTS: "1325",
        "Player-additional": "onealsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/406.png",
      },
      {
        id: 5,
        name: "Kobe Bryant",
        PTS: "1204",
        "Player-additional": "bryanko01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/977.png",
      },
      {
        id: 6,
        name: "Larry Bird",
        PTS: "1077",
        "Player-additional": "birdla01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1449.png",
      },
      {
        id: 7,
        name: "Kevin Durant",
        PTS: "1007",
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 8,
        name: "Tim Duncan",
        PTS: "956",
        "Player-additional": "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 9,
        name: "Magic Johnson",
        PTS: "956",
        "Player-additional": "johnsma02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77142.png",
      },
      {
        id: 10,
        name: "Stephen Curry",
        PTS: "917",
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201939.png",
      },
      {
        id: 11,
        name: "Scottie Pippen",
        PTS: "896",
        "Player-additional": "pippesc01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/937.png",
      },
      {
        id: 12,
        name: "Julius Erving",
        PTS: "860",
        "Player-additional": "ervinju01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76681.png",
      },
      {
        id: 13,
        name: "Karl Malone",
        PTS: "821",
        "Player-additional": "malonka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/252.png",
      },
      {
        id: 14,
        name: "James Worthy",
        PTS: "816",
        "Player-additional": "worthja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1460.png",
      },
      {
        id: 15,
        name: "Dwyane Wade",
        PTS: "786",
        "Player-additional": "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2548.png",
      },
      {
        id: 16,
        name: "Richard Hamilton",
        PTS: "770",
        "Player-additional": "hamilri01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/294.png",
      },
      {
        id: 17,
        name: "Dennis Johnson",
        PTS: "766",
        "Player-additional": "johnsde01",
        img_url:
          "https://www.hoophall.com/files/cache/97afd33578682c1247f5e12ecdae2d96_f437.jpg",
      },
      {
        id: 18,
        name: "Reggie Miller",
        PTS: "760",
        "Player-additional": "millere01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/397.png",
      },
      {
        id: 19,
        name: "Byron Scott",
        PTS: "735",
        "Player-additional": "scottby01",
        img_url:
          "https://cdn.nba.com/teams/legacy/www.nba.com/cavaliers/sites/cavaliers/files/legacy/main_photo/byron_121211.jpg",
      },
      {
        id: 20,
        name: "Kevin McHale",
        PTS: "731",
        "Player-additional": "mchalke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1450.png",
      },
      {
        id: 21,
        name: "Tony Parker",
        PTS: "704",
        "Player-additional": "parketo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2225.png",
      },
      {
        id: 22,
        name: "Rasheed Wallace",
        PTS: "694",
        "Player-additional": "wallara01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/739.png",
      },
      {
        id: 23,
        name: "Hakeem Olajuwon",
        PTS: "669",
        "Player-additional": "olajuha01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/165.png",
      },
    ],
  },
};
let game7points = {
  problem: {
    prompt: "Points in a Game 7",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Jayson Tatum",
        Points: "51",
        "Player-additional": "tatumja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628369.png",
      },
      {
        id: 2,
        name: "Stephen Curry",
        Points: "50",
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201939.png",
      },
      {
        id: 3,
        name: "Kevin Durant",
        Points: "48",
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 4,
        name: "Sam Jones",
        Points: "47",
        "Player-additional": "jonessa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77196.png",
      },
      {
        id: 5,
        name: "Dominique Wilkins",
        Points: "47",
        "Player-additional": "wilkido01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1122.png",
      },
      {
        id: 6,
        name: "Luka Don\u010di\u0107",
        Points: "46",
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 7,
        name: "Kevin Johnson",
        Points: "46",
        "Player-additional": "johnske02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/134.png",
      },
      {
        id: 8,
        name: "LeBron James",
        Points: "45",
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 10,
        name: "Charles Barkley",
        Points: "44",
        "Player-additional": "barklch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/787.png",
      },
      {
        id: 11,
        name: "Allen Iverson",
        Points: "44",
        "Player-additional": "iversal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/947.png",
      },
      {
        id: 12,
        name: "Oscar Robertson",
        Points: "43",
        "Player-additional": "roberos01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/600015.png",
      },
      {
        id: 13,
        name: "George Gervin",
        Points: "42",
        "Player-additional": "gervige01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76804.png",
      },
      {
        id: 14,
        name: "Michael Jordan",
        Points: "42",
        "Player-additional": "jordami01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/893.png",
      },
      {
        id: 15,
        name: "Calvin Murphy",
        Points: "42",
        "Player-additional": "murphca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77669.png",
      },
      {
        id: 16,
        name: "Jerry West",
        Points: "42",
        "Player-additional": "westje01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/78497.png",
      },
      {
        id: 17,
        name: "Elgin Baylor",
        Points: "41",
        "Player-additional": "bayloel01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76127.png",
      },
      {
        id: 18,
        name: "Tim Duncan",
        Points: "41",
        "Player-additional": "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 19,
        name: "Kawhi Leonard",
        Points: "41",
        "Player-additional": "leonaka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202695.png",
      },
      {
        id: 20,
        name: "Paul Pierce",
        Points: "41",
        "Player-additional": "piercpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1718.png",
      },
      {
        id: 21,
        name: "Giannis Antetokounmpo",
        Points: "40",
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203507.png",
      },
      {
        id: 22,
        name: "Jamal Murray",
        Points: "40",
        "Player-additional": "murraja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627750.png",
      },
      {
        id: 24,
        name: "Larry Bird",
        Points: "39",
        "Player-additional": "birdla01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1449.png",
      },
      {
        id: 25,
        name: "Wilt Chamberlain",
        Points: "39",
        "Player-additional": "chambwi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76375.png",
      },
    ],
  },
};

let assists1819 = {
  problem: {
    prompt: "Total Assists 2018-2019 Season",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Russell Westbrook",
        AST: "784",
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 2,
        name: "Trae Young",
        AST: "653",
        "Player-additional": "youngtr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629027.png",
      },
      {
        id: 3,
        name: "Ben Simmons",
        AST: "610",
        "Player-additional": "simmobe01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627732.png",
      },
      {
        id: 4,
        name: "De'Aaron Fox",
        AST: "590",
        "Player-additional": "foxde01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628368.png",
      },
      {
        id: 5,
        name: "James Harden",
        AST: "586",
        "Player-additional": "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 6,
        name: "Nikola Joki\u0107",
        AST: "580",
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 7,
        name: "Kyle Lowry",
        AST: "564",
        "Player-additional": "lowryky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200768.png",
      },
      {
        id: 8,
        name: "D'Angelo Russell",
        AST: "563",
        "Player-additional": "russeda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626156.png",
      },
      {
        id: 9,
        name: "Damian Lillard",
        AST: "551",
        "Player-additional": "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203081.png",
      },
      {
        id: 10,
        name: "Jrue Holiday",
        AST: "518",
        "Player-additional": "holidjr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201950.png",
      },
      {
        id: 11,
        name: "Kemba Walker",
        AST: "484",
        "Player-additional": "walkeke02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202689.png",
      },
      {
        id: 12,
        name: "DeMar DeRozan",
        AST: "475",
        "Player-additional": "derozde01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201942.png",
      },
      {
        id: 13,
        name: "Chris Paul",
        AST: "473",
        "Player-additional": "paulch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101108.png",
      },
      {
        id: 14,
        name: "Joe Ingles",
        AST: "469",
        "Player-additional": "inglejo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/204060.png",
      },
      {
        id: 15,
        name: "Kyrie Irving",
        AST: "464",
        "Player-additional": "irvinky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202681.png",
      },
      {
        id: 16,
        name: "Darren Collison",
        AST: "459",
        "Player-additional": "collida01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201954.png",
      },
      {
        id: 17,
        name: "Kevin Durant",
        AST: "457",
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 18,
        name: "Draymond Green",
        AST: "454",
        "Player-additional": "greendr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203110.png",
      },
      {
        id: 19,
        name: "LeBron James",
        AST: "454",
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 20,
        name: "Mike Conley",
        AST: "449",
        "Player-additional": "conlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201144.png",
      },
      {
        id: 21,
        name: "Bradley Beal",
        AST: "448",
        "Player-additional": "bealbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203078.png",
      },
      {
        id: 22,
        name: "Devin Booker",
        AST: "433",
        "Player-additional": "bookede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626164.png",
      },
      {
        id: 23,
        name: "Eric Bledsoe",
        AST: "430",
        "Player-additional": "bledser01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202339.png",
      },
    ],
  },
};
let fgplayoffs = {
  problem: {
    prompt: "Highest FG percentage this Postseason",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Luka Garza",
        "FG%": ".778",
        "Player-additional": "garzalu01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630568.png",
      },
      {
        id: 2,
        name: "Luke Kornet",
        "FG%": ".688",
        "Player-additional": "kornelu01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628436.png",
      },
      {
        id: 3,
        name: "Jarrett Allen",
        "FG%": ".676",
        "Player-additional": "allenja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628386.png",
      },
      {
        id: 4,
        name: "Anthony Davis",
        "FG%": ".634",
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203076.png",
      },
      {
        id: 5,
        name: "Rudy Gobert",
        "FG%": ".629",
        "Player-additional": "goberru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203497.png",
      },
      {
        id: 6,
        name: "Isaiah Hartenstein",
        "FG%": ".620",
        "Player-additional": "harteis01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628392.png",
      },
      {
        id: 7,
        name: "Nassir Little",
        "FG%": ".600",
        "Player-additional": "littlna01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629642.png",
      },
      {
        id: 8,
        name: "Delon Wright",
        "FG%": ".600",
        "Player-additional": "wrighde01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626153.png",
      },
      {
        id: 9,
        name: "Ivica Zubac",
        "FG%": ".600",
        "Player-additional": "zubaciv01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627826.png",
      },
      {
        id: 10,
        name: "Aaron Gordon",
        "FG%": ".593",
        "Player-additional": "gordoaa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203932.png",
      },
      {
        id: 11,
        name: "Markelle Fultz",
        "FG%": ".588",
        "Player-additional": "fultzma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628365.png",
      },
      {
        id: 12,
        name: "Larry Nance Jr.",
        "FG%": ".588",
        "Player-additional": "nancela02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626204.png",
      },
      {
        id: 13,
        name: "Brook Lopez",
        "FG%": ".587",
        "Player-additional": "lopezbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201572.png",
      },
      {
        id: 14,
        name: "LeBron James",
        "FG%": ".566",
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 15,
        name: "Nikola Joki\u0107",
        "FG%": ".556",
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 16,
        name: "Andrew Nembhard",
        "FG%": ".556",
        "Player-additional": "nembhan01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629614.png",
      },
      {
        id: 17,
        name: "Josh Okogie",
        "FG%": ".556",
        "Player-additional": "okogijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629006.png",
      },
      {
        id: 18,
        name: "Evan Mobley",
        "FG%": ".555",
        "Player-additional": "mobleev01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630596.png",
      },
      {
        id: 19,
        name: "Jaylen Brown",
        "FG%": ".554",
        "Player-additional": "brownja02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627759.png",
      },
      {
        id: 20,
        name: "Dereck Lively II",
        "FG%": ".554",
        "Player-additional": "livelde01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1641726.png",
      },
      {
        id: 21,
        name: "Kevin Durant",
        "FG%": ".552",
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 22,
        name: "Daniel Gafford",
        "FG%": ".548",
        "Player-additional": "gaffoda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629655.png",
      },
      {
        id: 23,
        name: "Pascal Siakam",
        "FG%": ".539",
        "Player-additional": "siakapa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627783.png",
      },
    ],
  },
};
let allstar = {
  problem: {
    prompt: "Career All Star Selections",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "LeBron James",
        Count: 20,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 2,
        name: "Kareem Abdul-Jabbar",
        Count: 19,
        "Player-additional": "abdulka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76003.png",
      },
      {
        id: 3,
        name: "Kobe Bryant",
        Count: 18,
        "Player-additional": "bryanko01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/977.png",
      },
      {
        id: 4,
        name: "Tim Duncan",
        Count: 15,
        "Player-additional": "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 5,
        name: "Kevin Garnett",
        Count: 15,
        "Player-additional": "garneke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/708.png",
      },
      {
        id: 7,
        name: "Kevin Durant",
        Count: 14,
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 8,
        name: "Dirk Nowitzki",
        Count: 14,
        "Player-additional": "nowitdi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1717.png",
      },
      {
        id: 9,
        name: "Karl Malone",
        Count: 14,
        "Player-additional": "malonka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/252.png",
      },
      {
        id: 10,
        name: "Michael Jordan",
        Count: 14,
        "Player-additional": "jordami01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/893.png",
      },
      {
        id: 11,
        name: "Jerry West",
        Count: 14,
        "Player-additional": "westje01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/78497.png",
      },
      {
        id: 12,
        name: "Dwyane Wade",
        Count: 13,
        "Player-additional": "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2548.png",
      },
      {
        id: 13,
        name: "John Havlicek",
        Count: 13,
        "Player-additional": "havlijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76970.png",
      },
      {
        id: 14,
        name: "Wilt Chamberlain",
        Count: 13,
        "Player-additional": "chambwi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76375.png",
      },
      {
        id: 15,
        name: "Bob Cousy",
        Count: 13,
        "Player-additional": "cousybo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/600003.png",
      },
      {
        id: 16,
        name: "Chris Paul",
        Count: 12,
        "Player-additional": "paulch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101108.png",
      },
      {
        id: 17,
        name: "Hakeem Olajuwon",
        Count: 12,
        "Player-additional": "olajuha01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/165.png",
      },
      {
        id: 18,
        name: "Isiah Thomas",
        Count: 12,
        "Player-additional": "thomais01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/78318.png",
      },
      {
        id: 19,
        name: "Larry Bird",
        Count: 12,
        "Player-additional": "birdla01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1449.png",
      },
      {
        id: 20,
        name: "Moses Malone",
        Count: 12,
        "Player-additional": "malonmo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77449.png",
      },
      {
        id: 21,
        name: "Elvin Hayes",
        Count: 12,
        "Player-additional": "hayesel01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76979.png",
      },
      {
        id: 22,
        name: "Oscar Robertson",
        Count: 12,
        "Player-additional": "roberos01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/600015.png",
      },
      {
        id: 23,
        name: "Bill Russell",
        Count: 12,
        "Player-additional": "russebi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/78049.png",
      },
      {
        id: 25,
        name: "Chris Bosh",
        Count: 11,
        "Player-additional": "boshch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2547.png",
      },
    ],
  },
};
let careerturnovers = {
  problem: {
    prompt: "Career Turnovers",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "LeBron James",
        TOV: 5211,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 2,
        name: "Russell Westbrook",
        TOV: 4587,
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 3,
        name: "Karl Malone",
        TOV: 4524,
        "Player-additional": "malonka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/252.png",
      },
      {
        id: 4,
        name: "John Stockton",
        TOV: 4244,
        "Player-additional": "stockjo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/304.png",
      },
      {
        id: 5,
        name: "Kobe Bryant",
        TOV: 4010,
        "Player-additional": "bryanko01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/977.png",
      },
      {
        id: 6,
        name: "Jason Kidd",
        TOV: 4003,
        "Player-additional": "kiddja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/429.png",
      },
      {
        id: 7,
        name: "James Harden",
        TOV: 3919,
        "Player-additional": "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 8,
        name: "Moses Malone",
        TOV: 3804,
        "Player-additional": "malonmo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77449.png",
      },
      {
        id: 9,
        name: "Isiah Thomas",
        TOV: 3682,
        "Player-additional": "thomais01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/78318.png",
      },
      {
        id: 10,
        name: "Hakeem Olajuwon",
        TOV: 3667,
        "Player-additional": "olajuha01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/165.png",
      },
      {
        id: 11,
        name: "Patrick Ewing",
        TOV: 3537,
        "Player-additional": "ewingpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/121.png",
      },
      {
        id: 12,
        name: "Paul Pierce",
        TOV: 3532,
        "Player-additional": "piercpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1718.png",
      },
      {
        id: 13,
        name: "Magic Johnson",
        TOV: 3506,
        "Player-additional": "johnsma02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77142.png",
      },
      {
        id: 14,
        name: "Reggie Theus",
        TOV: 3493,
        "Player-additional": "theusre01",
        img_url:
          "https://big3.com/wp-content/uploads/2019/04/BIVOUAC__REGGIE-THEUS-1-1.png",
      },
      {
        id: 15,
        name: "Steve Nash",
        TOV: 3478,
        "Player-additional": "nashst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/959.png",
      },
      {
        id: 16,
        name: "Tim Duncan",
        TOV: 3381,
        "Player-additional": "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 17,
        name: "Charles Barkley",
        TOV: 3376,
        "Player-additional": "barklch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/787.png",
      },
      {
        id: 18,
        name: "Kevin Durant",
        TOV: 3364,
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 19,
        name: "Dwyane Wade",
        TOV: 3326,
        "Player-additional": "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2548.png",
      },
      {
        id: 20,
        name: "Shaquille O'Neal",
        TOV: 3310,
        "Player-additional": "onealsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/406.png",
      },
      {
        id: 21,
        name: "Dwight Howard",
        TOV: 3302,
        "Player-additional": "howardw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2730.png",
      },
      {
        id: 22,
        name: "Allen Iverson",
        TOV: 3262,
        "Player-additional": "iversal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/947.png",
      },
      {
        id: 23,
        name: "Scottie Pippen",
        TOV: 3257,
        "Player-additional": "pippesc01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/937.png",
      },
    ],
  },
};
let pacers3pm = {
  problem: {
    prompt: "Most 3PM by Indiana Pacer",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Reggie Miller",
        "3P": 2560,
        "Player-additional": "millere01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/397.png",
      },
      {
        id: 2,
        name: "Danny Granger",
        "3P": 964,
        "Player-additional": "grangda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101122.png",
      },
      {
        id: 3,
        name: "Paul George",
        "3P": 897,
        "Player-additional": "georgpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202331.png",
      },
      {
        id: 4,
        name: "Myles Turner",
        "3P": 600,
        "Player-additional": "turnemy01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626167.png",
      },
      {
        id: 5,
        name: "Buddy Hield",
        "3P": 505,
        "Player-additional": "hieldbu01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627741.png",
      },
      {
        id: 6,
        name: "George Hill",
        "3P": 487,
        "Player-additional": "hillge01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201588.png",
      },
      {
        id: 7,
        name: "Chuck Person",
        "3P": 466,
        "Player-additional": "persoch01",
        img_url:
          "https://ashof.org/wp-content/uploads/2019/08/chuck-person.jpeg",
      },
      {
        id: 8,
        name: "C.J. Miles",
        "3P": 465,
        "Player-additional": "milescj01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101139.png",
      },
      {
        id: 9,
        name: "Justin Holiday",
        "3P": 434,
        "Player-additional": "holidju01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203200.png",
      },
      {
        id: 10,
        name: "Troy Murphy",
        "3P": 419,
        "Player-additional": "murphtr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2211.png",
      },
      {
        id: 11,
        name: "Tyrese Haliburton",
        "3P": 413,
        "Player-additional": "halibty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630169.png",
      },
      {
        id: 12,
        name: "Mike Dunleavy",
        "3P": 408,
        "Player-additional": "dunlemi02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2399.png",
      },
      {
        id: 13,
        name: "Austin Croshere",
        "3P": 376,
        "Player-additional": "croshau01",
        img_url:
          "https://friars.com/images/2019/7/1/Austin_Croshere_HOF.jpg?width=300",
      },
      {
        id: 14,
        name: "Doug McDermott",
        "3P": 341,
        "Player-additional": "mcderdo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203926.png",
      },
      {
        id: 15,
        name: "Jamaal Tinsley",
        "3P": 340,
        "Player-additional": "tinslja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2224.png",
      },
      {
        id: 16,
        name: "Bojan Bogdanovi\u0107",
        "3P": 319,
        "Player-additional": "bogdabo02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202711.png",
      },
      {
        id: 17,
        name: "Brandon Rush",
        "3P": 295,
        "Player-additional": "rushbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201575.png",
      },
      {
        id: 18,
        name: "Victor Oladipo",
        "3P": 293,
        "Player-additional": "oladivi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203506.png",
      },
      {
        id: 19,
        name: "Malcolm Brogdon",
        "3P": 278,
        "Player-additional": "brogdma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627763.png",
      },
      {
        id: 20,
        name: "Mark Jackson",
        "3P": 273,
        "Player-additional": "jacksma01",
        img_url:
          "https://www.si.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MjAyMjU2MjYxMjkzNTQ4NTU2/mark-jackson.jpg",
      },
      {
        id: 21,
        name: "Stephen Jackson",
        "3P": 261,
        "Player-additional": "jacksst02",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/378.png",
      },
      {
        id: 22,
        name: "Aaron Nesmith",
        "3P": 255,
        "Player-additional": "nesmiaa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630174.png",
      },
      {
        id: 23,
        name: "Jalen Rose",
        "3P": 251,
        "Player-additional": "roseja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/147.png",
      },
    ],
  },
};
let threeptfgmc = {
  problem: {
    prompt: "Most 3Pt FGM by Center in 2023-24 Season",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Naz Reid",
        "3P": 169,
        "Player-additional": "reidna01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629675.png",
      },
      {
        id: 2,
        name: "Brook Lopez",
        "3P": 148,
        "Player-additional": "lopezbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201572.png",
      },
      {
        id: 3,
        name: "Chet Holmgren",
        "3P": 129,
        "Player-additional": "holmgch01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631096.png",
      },
      {
        id: 4,
        name: "Victor Wembanyama",
        "3P": 128,
        "Player-additional": "wembavi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1641705.png",
      },
      {
        id: 5,
        name: "Jaren Jackson Jr.",
        "3P": 117,
        "Player-additional": "jacksja02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628991.png",
      },
      {
        id: 6,
        name: "Myles Turner",
        "3P": 116,
        "Player-additional": "turnemy01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626167.png",
      },
      {
        id: 7,
        name: "Kristaps Porzi\u0146\u0123is",
        "3P": 110,
        "Player-additional": "porzikr01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3102531.png",
      },
      {
        id: 8,
        name: "Al Horford",
        "3P": 108,
        "Player-additional": "horfoal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201143.png",
      },
      {
        id: 9,
        name: "Nikola Vu\u010devi\u0107",
        "3P": 92,
        "Player-additional": "vucevni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202696.png",
      },
      {
        id: 10,
        name: "Duop Reath",
        "3P": 88,
        "Player-additional": "reathdu01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4066268.png&w=350&h=254",
      },
      {
        id: 11,
        name: "Nikola Joki\u0107",
        "3P": 83,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 12,
        name: "Dario \u0160ari\u0107",
        "3P": 74,
        "Player-additional": "saricda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203967.png",
      },
      {
        id: 13,
        name: "Wendell Carter Jr.",
        "3P": 64,
        "Player-additional": "cartewe01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628976.png",
      },
      {
        id: 14,
        name: "Jalen Smith",
        "3P": 61,
        "Player-additional": "smithja04",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630188.png",
      },
      {
        id: 15,
        name: "Kelly Olynyk",
        "3P": 60,
        "Player-additional": "olynyke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203482.png",
      },
      {
        id: 16,
        name: "Zach Collins",
        "3P": 58,
        "Player-additional": "colliza01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628380.png",
      },
      {
        id: 17,
        name: "Joel Embiid",
        "3P": 54,
        "Player-additional": "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203954.png",
      },
      {
        id: 18,
        name: "Jaylin Williams",
        "3P": 53,
        "Player-additional": "willija07",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1631119.png",
      },
      {
        id: 19,
        name: "Moritz Wagner",
        "3P": 38,
        "Player-additional": "wagnemo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629021.png",
      },
      {
        id: 20,
        name: "Jonas Valan\u010di\u016bnas",
        "3P": 37,
        "Player-additional": "valanjo01",
        img_url: "https://a.espncdn.com/i/headshots/nba/players/full/6477.png",
      },
      {
        id: 21,
        name: "Domantas Sabonis",
        "3P": 33,
        "Player-additional": "sabondo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627734.png",
      },
      {
        id: 22,
        name: "Alperen Sengun",
        "3P": 33,
        "Player-additional": "sengual01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630578.png",
      },
      {
        id: 23,
        name: "Maxi Kleber",
        "3P": 32,
        "Player-additional": "klebima01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628467.png",
      },
    ],
  },
};
let stealplayoffgame = {
  problem: {
    prompt: "Most Steals in 2023 Playoffs",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Jimmy Butler",
        STL: 39,
        "Player-additional": "butleji01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202710.png",
      },
      {
        id: 2,
        name: "Jamal Murray",
        STL: 30,
        "Player-additional": "murraja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627750.png",
      },
      {
        id: 3,
        name: "Kentavious Caldwell-Pope",
        STL: 26,
        "Player-additional": "caldwke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203484.png",
      },
      {
        id: 4,
        name: "Marcus Smart",
        STL: 25,
        "Player-additional": "smartma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203935.png",
      },
      {
        id: 5,
        name: "Kyle Lowry",
        STL: 24,
        "Player-additional": "lowryky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200768.png",
      },
      {
        id: 6,
        name: "Anthony Davis",
        STL: 22,
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203076.png",
      },
      {
        id: 7,
        name: "Al Horford",
        STL: 22,
        "Player-additional": "horfoal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201143.png",
      },
      {
        id: 8,
        name: "Bruce Brown",
        STL: 21,
        "Player-additional": "brownbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628971.png",
      },
      {
        id: 9,
        name: "Jaylen Brown",
        STL: 21,
        "Player-additional": "brownja02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627759.png",
      },
      {
        id: 10,
        name: "Nikola Joki\u0107",
        STL: 21,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 11,
        name: "Jayson Tatum",
        STL: 21,
        "Player-additional": "tatumja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628369.png",
      },
      {
        id: 12,
        name: "Bam Adebayo",
        STL: 20,
        "Player-additional": "adebaba01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628389.png",
      },
      {
        id: 13,
        name: "James Harden",
        STL: 20,
        "Player-additional": "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 14,
        name: "Caleb Martin",
        STL: 20,
        "Player-additional": "martica02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628997.png",
      },
      {
        id: 15,
        name: "Devin Booker",
        STL: 19,
        "Player-additional": "bookede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626164.png",
      },
      {
        id: 16,
        name: "Gabe Vincent",
        STL: 19,
        "Player-additional": "vincega01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629216.png",
      },
      {
        id: 17,
        name: "Draymond Green",
        STL: 18,
        "Player-additional": "greendr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203110.png",
      },
      {
        id: 18,
        name: "Jalen Brunson",
        STL: 17,
        "Player-additional": "brunsja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628973.png",
      },
      {
        id: 19,
        name: "LeBron James",
        STL: 17,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 20,
        name: "Dennis Schr\u00f6der",
        STL: 16,
        "Player-additional": "schrode01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203471.png",
      },
      {
        id: 21,
        name: "De'Aaron Fox",
        STL: 15,
        "Player-additional": "foxde01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628368.png",
      },
      {
        id: 22,
        name: "Tyrese Maxey",
        STL: 15,
        "Player-additional": "maxeyty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630178.png",
      },
      {
        id: 23,
        name: "Stephen Curry",
        STL: 13,
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201939.png",
      },
    ],
  },
};
let youngguns = {
  problem: {
    prompt: "Youngest Player to score 30+ points in playoff game",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Derrick Rose",
        Age: "20-196",
        "Player-additional": "rosede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201565.png",
      },
      {
        id: 2,
        name: "Brandon Jennings",
        Age: "20-206",
        "Player-additional": "jennibr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201943.png",
      },
      {
        id: 3,
        name: "Tyler Herro",
        Age: "20-247",
        "Player-additional": "herroty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629639.png",
      },
      {
        id: 4,
        name: "Anthony Edwards",
        Age: "20-254",
        "Player-additional": "edwaran01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630162.png",
      },
      {
        id: 6,
        name: "Magic Johnson",
        Age: "20-276",
        "Player-additional": "johnsma02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77142.png",
      },
      {
        id: 7,
        name: "Daniel Gibson",
        Age: "21-095",
        "Player-additional": "gibsoda01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3006.png",
      },
      {
        id: 8,
        name: "LeBron James",
        Age: "21-113",
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 14,
        name: "Luguentz Dort",
        Age: "21-136",
        "Player-additional": "dortlu01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629652.png",
      },
      {
        id: 17,
        name: "Tyrese Maxey",
        Age: "21-163",
        "Player-additional": "maxeyty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630178.png",
      },
      {
        id: 18,
        name: "Paolo Banchero",
        Age: "21-165",
        "Player-additional": "banchpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1631094.png",
      },
      {
        id: 20,
        name: "Luka Don\u010di\u0107",
        Age: "21-171",
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 22,
        name: "Jaylen Brown",
        Age: "21-175",
        "Player-additional": "brownja02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627759.png",
      },
      {
        id: 27,
        name: "Carl Braun",
        Age: "21-187",
        "Player-additional": "braunca01",
        img_url:
          "https://upload.wikimedia.org/wikipedia/commons/1/11/Carl_Braun_1959.jpeg",
      },
      {
        id: 29,
        name: "Kevin Durant",
        Age: "21-203",
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 31,
        name: "Donovan Mitchell",
        Age: "21-228",
        "Player-additional": "mitchdo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628378.png",
      },
      {
        id: 33,
        name: "Kobe Bryant",
        Age: "21-248",
        "Player-additional": "bryanko01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/977.png",
      },
      {
        id: 40,
        name: "Ja Morant",
        Age: "21-289",
        "Player-additional": "moranja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629630.png",
      },
      {
        id: 41,
        name: "Alvan Adams",
        Age: "21-316",
        "Player-additional": "adamsal01",
        img_url:
          "https://upload.wikimedia.org/wikipedia/commons/c/cf/Alvan_Adams_-_Phoenix_Suns.jpg",
      },
      {
        id: 42,
        name: "Bradley Beal",
        Age: "21-317",
        "Player-additional": "bealbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203078.png",
      },
      {
        id: 43,
        name: "Tracy McGrady",
        Age: "21-333",
        "Player-additional": "mcgratr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1503.png",
      },
      {
        id: 46,
        name: "Tony Parker",
        Age: "21-354",
        "Player-additional": "parketo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2225.png",
      },
      {
        id: 47,
        name: "Tim Duncan",
        Age: "21-363",
        "Player-additional": "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 48,
        name: "Luol Deng",
        Age: "22-005",
        "Player-additional": "denglu01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2736.png",
      },
    ],
  },
};
let thunderpoints = {
  problem: {
    prompt: "Career Points for Thunder Sonics organization",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Russell Westbrook",
        PTS: "18859",
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 2,
        name: "Gary Payton",
        PTS: "18207",
        "Player-additional": "paytoga01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/56.png",
      },
      {
        id: 3,
        name: "Kevin Durant",
        PTS: "17566",
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 4,
        name: "Fred Brown",
        PTS: "14018",
        "Player-additional": "brownfr01",
        img_url:
          "https://yesterdayshoops.files.wordpress.com/2010/02/retired_jerseys_brown.jpg",
      },
      {
        id: 5,
        name: "Jack Sikma",
        PTS: "12034",
        "Player-additional": "sikmaja01",
        img_url:
          "https://images.seattletimes.com/wp-content/uploads/2019/04/04052019_Sikma_203903.jpg?d=780x620",
      },
      {
        id: 6,
        name: "Rashard Lewis",
        PTS: "10251",
        "Player-additional": "lewisra02",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/469.png",
      },
      {
        id: 7,
        name: "Shawn Kemp",
        PTS: "10148",
        "Player-additional": "kempsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/431.png",
      },
      {
        id: 8,
        name: "Gus Williams",
        PTS: "9676",
        "Player-additional": "willigu01",
        img_url:
          "https://images.squarespace-cdn.com/content/v1/5f6a2443c6004d000a8d74df/1609965908671-APHXIEN6MXGPJWOVY4O2/Blog+Gus.jpg",
      },
      {
        id: 9,
        name: "Dale Ellis",
        PTS: "9405",
        "Player-additional": "ellisda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/107.png",
      },
      {
        id: 10,
        name: "Xavier McDaniel",
        PTS: "8438",
        "Player-additional": "mcdanxa01",
        img_url:
          "https://www.basketballnetwork.net/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg3MTYxMDAzMDc2MjMzMTAx/the-time-xavier-mcdaniel-beat-up-his-teammate.jpg",
      },
      {
        id: 11,
        name: "Spencer Haywood",
        PTS: "8131",
        "Player-additional": "haywosp01",
        img_url:
          "https://m.media-amazon.com/images/I/91XJWsYbRDL._UF1000,1000_QL80_.jpg",
      },
      {
        id: 12,
        name: "Tom Chambers",
        PTS: "8028",
        "Player-additional": "chambto01",
        img_url:
          "https://cdn.nba.com/teams/legacy/www.nba.com/suns/sites/suns/files/tom-chambers.jpg",
      },
      {
        id: 13,
        name: "Shai Gilgeous-Alexander",
        PTS: "7921",
        "Player-additional": "gilgesh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628983.png",
      },
      {
        id: 14,
        name: "Ray Allen",
        PTS: "7273",
        "Player-additional": "allenra02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/951.png",
      },
      {
        id: 15,
        name: "Detlef Schrempf",
        PTS: "6870",
        "Player-additional": "schrede01",
        img_url:
          "https://cdn.vox-cdn.com/thumbor/d_1mXiqz_Zr_knhCOQHFRuRykp0=/0x0:399x266/1200x800/filters:focal(0x0:399x266)/cdn.vox-cdn.com/uploads/chorus_image/image/24986127/detlef_schrempf.0.jpg",
      },
      {
        id: 16,
        name: "Dick Snyder",
        PTS: "6507",
        "Player-additional": "snydedi01",
        img_url: "https://www.cavshistory.com/images/players/Dick_Snyder.jpg",
      },
      {
        id: 17,
        name: "Derrick McKey",
        PTS: "6179",
        "Player-additional": "mckeyde01",
        img_url:
          "https://ashof.org/wp-content/uploads/2019/07/derrick-mckey.jpg",
      },
      {
        id: 18,
        name: "Serge Ibaka",
        PTS: "6054",
        "Player-additional": "ibakase01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201586.png",
      },
      {
        id: 19,
        name: "Lenny Wilkens",
        PTS: "6010",
        "Player-additional": "wilkele01",
        img_url:
          "https://cdn.nba.com/manage/2021/09/lenny-wilkens-driving-blazers.jpg",
      },
      {
        id: 20,
        name: "Bob Rule",
        PTS: "5646",
        "Player-additional": "rulebo01",
        img_url:
          "https://images.seattletimes.com/wp-content/uploads/2019/09/212931.jpg?d=1560x2178",
      },
      {
        id: 21,
        name: "Nick Collison",
        PTS: "5359",
        "Player-additional": "collini01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2555.png",
      },
      {
        id: 22,
        name: "Steven Adams",
        PTS: "5191",
        "Player-additional": "adamsst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203500.png",
      },
      {
        id: 23,
        name: "Vin Baker",
        PTS: "5054",
        "Player-additional": "bakervi01",
        img_url:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Lipofsky-Vin_Baker.jpg/220px-Lipofsky-Vin_Baker.jpg",
      },
    ],
  },
};
let assistplayoffgame = {
  problem: {
    prompt: "Most Assists in Single Playoff Game Since 2005",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Steve Nash",
        AST: 23,
        "Player-additional": "nashst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/959.png",
      },
      {
        id: 2,
        name: "Rajon Rondo",
        AST: 21,
        "Player-additional": "rondora01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200765.png",
      },
      {
        id: 4,
        name: "Jason Kidd",
        AST: 19,
        "Player-additional": "kiddja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/429.png",
      },
      {
        id: 7,
        name: "James Harden",
        AST: 18,
        "Player-additional": "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 8,
        name: "Tony Parker",
        AST: 18,
        "Player-additional": "parketo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2225.png",
      },
      {
        id: 11,
        name: "Nikola Joki\u0107",
        AST: 17,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 13,
        name: "Chris Paul",
        AST: 17,
        "Player-additional": "paulch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101108.png",
      },
      {
        id: 16,
        name: "John Wall",
        AST: 17,
        "Player-additional": "walljo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202322.png",
      },
      {
        id: 17,
        name: "Deron Williams",
        AST: 17,
        "Player-additional": "willide01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101114.png",
      },
      {
        id: 18,
        name: "Tyrese Haliburton",
        AST: 16,
        "Player-additional": "halibty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630169.png",
      },
      {
        id: 19,
        name: "Jrue Holiday",
        AST: 16,
        "Player-additional": "holidjr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201950.png",
      },
      {
        id: 20,
        name: "LeBron James",
        AST: 16,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 21,
        name: "Tracy McGrady",
        AST: 16,
        "Player-additional": "mcgratr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1503.png",
      },
      {
        id: 31,
        name: "Russell Westbrook",
        AST: 16,
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 32,
        name: "Giannis Antetokounmpo",
        AST: 15,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203507.png",
      },
      {
        id: 33,
        name: "Mike Conley",
        AST: 15,
        "Player-additional": "conlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201144.png",
      },
      {
        id: 34,
        name: "Stephen Curry",
        AST: 15,
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201939.png",
      },
      {
        id: 35,
        name: "T.J. Ford",
        AST: 15,
        "Player-additional": "fordtj01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2551.png",
      },
      {
        id: 39,
        name: "Allen Iverson",
        AST: 15,
        "Player-additional": "iversal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/947.png",
      },
      {
        id: 43,
        name: "Caris LeVert",
        AST: 15,
        "Player-additional": "leverca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627747.png",
      },
      {
        id: 44,
        name: "Ja Morant",
        AST: 15,
        "Player-additional": "moranja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629630.png",
      },
      {
        id: 56,
        name: "Ben Simmons",
        AST: 15,
        "Player-additional": "simmobe01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627732.png",
      },
      {
        id: 57,
        name: "Dwyane Wade",
        AST: 15,
        "Player-additional": "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2548.png",
      },
    ],
  },
};
let mavsplayoffpoints = {
  problem: {
    prompt: "Mavericks Playoff Points Since 2000",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Dirk Nowitzki",
        PTS: 3663,
        "Player-additional": "nowitdi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1717.png",
      },
      {
        id: 2,
        name: "Jason Terry",
        PTS: 1466,
        "Player-additional": "terryja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1891.png",
      },
      {
        id: 3,
        name: "Luka Don\u010di\u0107",
        PTS: 1109,
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 4,
        name: "Michael Finley",
        PTS: 995,
        "Player-additional": "finlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/922.png",
      },
      {
        id: 5,
        name: "Josh Howard",
        PTS: 961,
        "Player-additional": "howarjo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2572.png",
      },
      {
        id: 6,
        name: "Steve Nash",
        PTS: 682,
        "Player-additional": "nashst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/959.png",
      },
      {
        id: 7,
        name: "Jerry Stackhouse",
        PTS: 628,
        "Player-additional": "stackje01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/802.png&w=350&h=254",
      },
      {
        id: 8,
        name: "Nick Van Exel",
        PTS: 478,
        "Player-additional": "vanexni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/89.png",
      },
      {
        id: 9,
        name: "Devin Harris",
        PTS: 460,
        "Player-additional": "harride01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2734.png",
      },
      {
        id: 10,
        name: "Jason Kidd",
        PTS: 446,
        "Player-additional": "kiddja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/429.png",
      },
      {
        id: 11,
        name: "Jalen Brunson",
        PTS: 444,
        "Player-additional": "brunsja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628973.png",
      },
      {
        id: 12,
        name: "Shawn Marion",
        PTS: 408,
        "Player-additional": "mariosh01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/510.png",
      },
      {
        id: 13,
        name: "J.J. Barea",
        PTS: 393,
        "Player-additional": "bareajo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200826.png",
      },
      {
        id: 14,
        name: "Dorian Finney-Smith",
        PTS: 344,
        "Player-additional": "finnedo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627827.png",
      },
      {
        id: 15,
        name: "Monta Ellis",
        PTS: 273,
        "Player-additional": "ellismo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101145.png",
      },
      {
        id: 16,
        name: "Erick Dampier",
        PTS: 271,
        "Player-additional": "dampier01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/956.png",
      },
      {
        id: 17,
        name: "Maxi Kleber",
        PTS: 268,
        "Player-additional": "klebima01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628467.png",
      },
      {
        id: 18,
        name: "Spencer Dinwiddie",
        PTS: 255,
        "Player-additional": "dinwisp01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203915.png",
      },
      {
        id: 19,
        name: "Raef LaFrentz",
        PTS: 250,
        "Player-additional": "lafrera01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1711.png",
      },
      {
        id: 20,
        name: "Tim Hardaway Jr.",
        PTS: 234,
        "Player-additional": "hardati02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203501.png",
      },
      {
        id: 21,
        name: "Marquis Daniels",
        PTS: 219,
        "Player-additional": "daniema01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2605.png",
      },
      {
        id: 22,
        name: "Tyson Chandler",
        PTS: 218,
        "Player-additional": "chandty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2199.png",
      },
      {
        id: 23,
        name: "Reggie Bullock",
        PTS: 190,
        "Player-additional": "bullore01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203493.png",
      },
    ],
  },
};
let playoffdebut = {
  problem: {
    prompt: "Points in Playoff Debut Since 2011",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Luka Don\u010di\u0107",
        PTS: 42,
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 2,
        name: "De'Aaron Fox",
        PTS: 38,
        "Player-additional": "foxde01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628368.png",
      },
      {
        id: 3,
        name: "Anthony Edwards",
        PTS: 36,
        "Player-additional": "edwaran01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630162.png",
      },
      {
        id: 4,
        name: "Anthony Davis",
        PTS: 35,
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203076.png",
      },
      {
        id: 5,
        name: "Devin Booker",
        PTS: 34,
        "Player-additional": "bookede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626164.png",
      },
      {
        id: 6,
        name: "Malik Monk",
        PTS: 32,
        "Player-additional": "monkma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628370.png",
      },
      {
        id: 7,
        name: "Trae Young",
        PTS: 32,
        "Player-additional": "youngtr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629027.png",
      },
      {
        id: 8,
        name: "Dillon Brooks",
        PTS: 31,
        "Player-additional": "brookdi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628415.png",
      },
      {
        id: 9,
        name: "Damian Lillard",
        PTS: 31,
        "Player-additional": "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203081.png",
      },
      {
        id: 10,
        name: "Kyrie Irving",
        PTS: 30,
        "Player-additional": "irvinky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202681.png",
      },
      {
        id: 11,
        name: "Jordan Poole",
        PTS: 30,
        "Player-additional": "poolejo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629673.png",
      },
      {
        id: 12,
        name: "Donovan Mitchell",
        PTS: 27,
        "Player-additional": "mitchdo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628378.png",
      },
      {
        id: 13,
        name: "Timoth\u00e9 Luwawu-Cabarrot",
        PTS: 26,
        "Player-additional": "luwawti01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3893019.png&w=350&h=254",
      },
      {
        id: 14,
        name: "Ja Morant",
        PTS: 26,
        "Player-additional": "moranja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629630.png",
      },
      {
        id: 15,
        name: "D'Angelo Russell",
        PTS: 26,
        "Player-additional": "russeda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626156.png",
      },
      {
        id: 16,
        name: "Paolo Banchero",
        PTS: 24,
        "Player-additional": "banchpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1631094.png",
      },
      {
        id: 17,
        name: "Marc Gasol",
        PTS: 24,
        "Player-additional": "gasolma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201188.png",
      },
      {
        id: 18,
        name: "Joel Embiid",
        PTS: 23,
        "Player-additional": "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203954.png",
      },
      {
        id: 19,
        name: "Caris LeVert",
        PTS: 23,
        "Player-additional": "leverca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627747.png",
      },
      {
        id: 20,
        name: "Austin Reaves",
        PTS: 23,
        "Player-additional": "reaveau01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630559.png",
      },
      {
        id: 21,
        name: "Tyler Hansbrough",
        PTS: 22,
        "Player-additional": "hansbty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201946.png",
      },
      {
        id: 22,
        name: "Isaiah Thomas",
        PTS: 22,
        "Player-additional": "thomais02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202738.png",
      },
      {
        id: 23,
        name: "Klay Thompson",
        PTS: 22,
        "Player-additional": "thompkl01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202691.png",
      },
    ],
  },
};
let doubledoubles = {
  problem: {
    prompt: "Double Doubles since 1980",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Tim Duncan",
        Count: 1005,
        "Player-additional": "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 2,
        name: "Karl Malone",
        Count: 938,
        "Player-additional": "malonka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/252.png",
      },
      {
        id: 3,
        name: "Hakeem Olajuwon",
        Count: 875,
        "Player-additional": "olajuha01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/165.png",
      },
      {
        id: 4,
        name: "Shaquille O'Neal",
        Count: 869,
        "Player-additional": "onealsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/406.png",
      },
      {
        id: 5,
        name: "Kevin Garnett",
        Count: 828,
        "Player-additional": "garneke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/708.png",
      },
      {
        id: 6,
        name: "Dwight Howard",
        Count: 820,
        "Player-additional": "howardw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2730.png",
      },
      {
        id: 7,
        name: "Charles Barkley",
        Count: 802,
        "Player-additional": "barklch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/787.png",
      },
      {
        id: 8,
        name: "John Stockton",
        Count: 801,
        "Player-additional": "stockjo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/304.png",
      },
      {
        id: 9,
        name: "Moses Malone",
        Count: 787,
        "Player-additional": "malonmo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77449.png",
      },
      {
        id: 10,
        name: "Magic Johnson",
        Count: 772,
        "Player-additional": "johnsma02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77142.png",
      },
      {
        id: 11,
        name: "LeBron James",
        Count: 714,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 12,
        name: "Robert Parish",
        Count: 686,
        "Player-additional": "parisro01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/305.png",
      },
      {
        id: 13,
        name: "Patrick Ewing",
        Count: 659,
        "Player-additional": "ewingpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/121.png",
      },
      {
        id: 14,
        name: "Larry Bird",
        Count: 615,
        "Player-additional": "birdla01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1449.png",
      },
      {
        id: 15,
        name: "David Robinson",
        Count: 613,
        "Player-additional": "robinda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/764.png",
      },
      {
        id: 16,
        name: "Buck Williams",
        Count: 608,
        "Player-additional": "willibu01",
        img_url:
          "https://cdn.nba.com/teams/legacy/www.nba.com/nets/sites/nets/files/05_buck_albert.jpg",
      },
      {
        id: 17,
        name: "Pau Gasol",
        Count: 591,
        "Player-additional": "gasolpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2200.png",
      },
      {
        id: 18,
        name: "Chris Paul",
        Count: 583,
        "Player-additional": "paulch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101108.png",
      },
      {
        id: 19,
        name: "Russell Westbrook",
        Count: 560,
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 20,
        name: "Jason Kidd",
        Count: 537,
        "Player-additional": "kiddja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/429.png",
      },
      {
        id: 21,
        name: "Nikola Vu\u010devi\u0107",
        Count: 523,
        "Player-additional": "vucevni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202696.png",
      },
      {
        id: 22,
        name: "Zach Randolph",
        Count: 520,
        "Player-additional": "randoza01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2216.png",
      },
      {
        id: 23,
        name: "Kevin Willis",
        Count: 516,
        "Player-additional": "willike02",
        img_url:
          "https://cdn.nba.com/teams/legacy/www.nba.com/hawks/sites/hawks/files/legacy/photos/HWK_Classic_Willis_3.jpg",
      },
    ],
  },
};
let oldestactive = {
  problem: {
    prompt: "Oldest Active NBA Players",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "LeBron James",
        Age: 39,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 2,
        name: "P.J. Tucker",
        Age: 38,
        "Player-additional": "tuckepj01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200782.png",
      },
      {
        id: 3,
        name: "Al Horford",
        Age: 37,
        "Player-additional": "horfoal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201143.png",
      },
      {
        id: 4,
        name: "Kyle Lowry",
        Age: 37,
        "Player-additional": "lowryky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200768.png",
      },
      {
        id: 5,
        name: "Mike Conley",
        Age: 36,
        "Player-additional": "conlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201144.png",
      },
      {
        id: 6,
        name: "Joe Ingles",
        Age: 36,
        "Player-additional": "inglejo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/204060.png",
      },
      {
        id: 7,
        name: "Nicolas Batum",
        Age: 35,
        "Player-additional": "batumni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201587.png",
      },
      {
        id: 8,
        name: "Patrick Beverley",
        Age: 35,
        "Player-additional": "beverpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201976.png",
      },
      {
        id: 9,
        name: "Kevin Durant",
        Age: 35,
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 10,
        name: "Danilo Gallinari",
        Age: 35,
        "Player-additional": "gallida01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201568.png",
      },
      {
        id: 11,
        name: "Eric Gordon",
        Age: 35,
        "Player-additional": "gordoer01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201569.png",
      },
      {
        id: 12,
        name: "DeAndre Jordan",
        Age: 35,
        "Player-additional": "jordade01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201599.png",
      },
      {
        id: 13,
        name: "Brook Lopez",
        Age: 35,
        "Player-additional": "lopezbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201572.png",
      },
      {
        id: 14,
        name: "Kevin Love",
        Age: 35,
        "Player-additional": "loveke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201567.png",
      },
      {
        id: 15,
        name: "Patty Mills",
        Age: 35,
        "Player-additional": "millspa02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201988.png",
      },
      {
        id: 16,
        name: "Russell Westbrook",
        Age: 35,
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 17,
        name: "Thaddeus Young",
        Age: 35,
        "Player-additional": "youngth01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201152.png",
      },
      {
        id: 18,
        name: "Bojan Bogdanovi\u0107",
        Age: 34,
        "Player-additional": "bogdabo02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202711.png",
      },
      {
        id: 19,
        name: "James Harden",
        Age: 34,
        "Player-additional": "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 20,
        name: "Justin Holiday",
        Age: 34,
        "Player-additional": "holidju01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203200.png",
      },
      {
        id: 21,
        name: "Marcus Morris",
        Age: 34,
        "Player-additional": "morrima03",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6462.png",
      },
      {
        id: 22,
        name: "Isaiah Thomas",
        Age: 34,
        "Player-additional": "thomais02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202738.png",
      },
      {
        id: 23,
        name: "Jae Crowder",
        Age: 33,
        "Player-additional": "crowdja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203109.png",
      },
    ],
  },
};
let offensiverebounds = {
  problem: {
    prompt: "Offensive Rebounds 2024 Playoffs",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Josh Hart",
        ORB: 22,
        "Player-additional": "hartjo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628404.png",
      },
      {
        id: 2,
        name: "Mitchell Robinson",
        ORB: 22,
        "Player-additional": "robinmi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629011.png",
      },
      {
        id: 3,
        name: "Isaiah Hartenstein",
        ORB: 21,
        "Player-additional": "harteis01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628392.png",
      },
      {
        id: 4,
        name: "Joel Embiid",
        ORB: 19,
        "Player-additional": "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203954.png",
      },
      {
        id: 5,
        name: "Aaron Gordon",
        ORB: 19,
        "Player-additional": "gordoaa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203932.png",
      },
      {
        id: 6,
        name: "Nikola Joki\u0107",
        ORB: 19,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 7,
        name: "Jonas Valan\u010di\u016bnas",
        ORB: 18,
        "Player-additional": "valanjo01",
        img_url: "https://a.espncdn.com/i/headshots/nba/players/full/6477.png",
      },
      {
        id: 8,
        name: "Bobby Portis",
        ORB: 16,
        "Player-additional": "portibo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626171.png",
      },
      {
        id: 9,
        name: "Jarrett Allen",
        ORB: 15,
        "Player-additional": "allenja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628386.png",
      },
      {
        id: 10,
        name: "Anthony Davis",
        ORB: 15,
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203076.png",
      },
      {
        id: 11,
        name: "Rudy Gobert",
        ORB: 15,
        "Player-additional": "goberru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203497.png",
      },
      {
        id: 12,
        name: "Larry Nance Jr.",
        ORB: 15,
        "Player-additional": "nancela02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626204.png",
      },
      {
        id: 13,
        name: "Pascal Siakam",
        ORB: 14,
        "Player-additional": "siakapa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627783.png",
      },
      {
        id: 14,
        name: "Ivica Zubac",
        ORB: 14,
        "Player-additional": "zubaciv01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627826.png",
      },
      {
        id: 15,
        name: "Jusuf Nurki\u0107",
        ORB: 13,
        "Player-additional": "nurkiju01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203994.png",
      },
      {
        id: 16,
        name: "Terance Mann",
        ORB: 12,
        "Player-additional": "mannte01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629611.png",
      },
      {
        id: 17,
        name: "Jaden McDaniels",
        ORB: 12,
        "Player-additional": "mcdanja02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630183.png",
      },
      {
        id: 18,
        name: "Khris Middleton",
        ORB: 12,
        "Player-additional": "middlkh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203114.png",
      },
      {
        id: 19,
        name: "Bam Adebayo",
        ORB: 11,
        "Player-additional": "adebaba01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628389.png",
      },
      {
        id: 20,
        name: "Brook Lopez",
        ORB: 11,
        "Player-additional": "lopezbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201572.png",
      },
      {
        id: 21,
        name: "Paolo Banchero",
        ORB: 9,
        "Player-additional": "banchpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1631094.png",
      },
      {
        id: 22,
        name: "Nicolas Batum",
        ORB: 9,
        "Player-additional": "batumni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201587.png",
      },
      {
        id: 23,
        name: "Jaylen Brown",
        ORB: 9,
        "Player-additional": "brownja02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627759.png",
      },
    ],
  },
};

let undraftedactive = {
  problem: {
    prompt: "Undrafted Active Players' Career Points",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Wesley Matthews",
        PTS: 11210,
        "Player-additional": "matthwe02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202083.png",
      },
      {
        id: 2,
        name: "Fred VanVleet",
        PTS: 7360,
        "Player-additional": "vanvlfr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627832.png",
      },
      {
        id: 3,
        name: "Robert Covington",
        PTS: 6637,
        "Player-additional": "covinro01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203496.png",
      },
      {
        id: 4,
        name: "Ish Smith",
        PTS: 5712,
        "Player-additional": "smithis01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202397.png",
      },
      {
        id: 5,
        name: "Joe Ingles",
        PTS: 5707,
        "Player-additional": "inglejo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/204060.png",
      },
      {
        id: 6,
        name: "Justin Holiday",
        PTS: 5444,
        "Player-additional": "holidju01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203200.png",
      },
      {
        id: 7,
        name: "Kent Bazemore",
        PTS: 5414,
        "Player-additional": "bazemke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203145.png",
      },
      {
        id: 8,
        name: "Seth Curry",
        PTS: 5032,
        "Player-additional": "curryse01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203552.png",
      },
      {
        id: 9,
        name: "T.J. McConnell",
        PTS: 4676,
        "Player-additional": "mccontj01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/204456.png",
      },
      {
        id: 10,
        name: "Christian Wood",
        PTS: 4617,
        "Player-additional": "woodch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626174.png",
      },
      {
        id: 11,
        name: "Garrett Temple",
        PTS: 4529,
        "Player-additional": "templga01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202066.png",
      },
      {
        id: 12,
        name: "Dorian Finney-Smith",
        PTS: 4360,
        "Player-additional": "finnedo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627827.png",
      },
      {
        id: 13,
        name: "JaMychal Green",
        PTS: 4208,
        "Player-additional": "greenja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203210.png",
      },
      {
        id: 14,
        name: "Duncan Robinson",
        PTS: 3981,
        "Player-additional": "robindu01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629130.png",
      },
      {
        id: 15,
        name: "Bryn Forbes",
        PTS: 3776,
        "Player-additional": "forbebr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627854.png",
      },
      {
        id: 16,
        name: "Luguentz Dort",
        PTS: 3723,
        "Player-additional": "dortlu01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629652.png",
      },
      {
        id: 17,
        name: "Langston Galloway",
        PTS: 3656,
        "Player-additional": "gallola01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/204038.png",
      },
      {
        id: 18,
        name: "Royce O'Neale",
        PTS: 3564,
        "Player-additional": "onealro01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626220.png",
      },
      {
        id: 19,
        name: "Naz Reid",
        PTS: 3561,
        "Player-additional": "reidna01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629675.png",
      },
      {
        id: 20,
        name: "Dewayne Dedmon",
        PTS: 3255,
        "Player-additional": "dedmode01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203473.png",
      },
      {
        id: 21,
        name: "Chris Boucher",
        PTS: 3110,
        "Player-additional": "bouchch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628449.png",
      },
      {
        id: 22,
        name: "Derrick Jones Jr.",
        PTS: 2809,
        "Player-additional": "jonesde02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627884.png",
      },
      {
        id: 23,
        name: "Daniel Theis",
        PTS: 2742,
        "Player-additional": "theisda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628464.png",
      },
    ],
  },
};
let lebronpointteams = {
  problem: {
    prompt: "Teams LeBron James has scored the most career points against",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Bulls",
        PTS: 1803,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/chi.png&h=200&w=200",
      },
      {
        id: 2,
        name: "Pacers",
        PTS: 1785,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/ind.png&h=200&w=200",
      },
      {
        id: 3,
        name: "Hawks",
        PTS: 1752,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/atl.png&h=200&w=200",
      },
      {
        id: 4,
        name: "Bucks",
        PTS: 1751,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/mil.png&h=200&w=200",
      },
      {
        id: 5,
        name: "Celtics",
        PTS: 1701,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/bos.png&h=200&w=200",
      },
      {
        id: 6,
        name: "Nets",
        PTS: 1696,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/bkn.png&h=200&w=200",
      },
      {
        id: 7,
        name: "Raptors",
        PTS: 1622,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/tor.png&h=100&w=100",
      },
      {
        id: 8,
        name: "Wizards",
        PTS: 1586,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/wsh.png&h=200&w=200",
      },
      {
        id: 9,
        name: "Magic",
        PTS: 1580,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/orl.png&h=200&w=200",
      },
      {
        id: 10,
        name: "Hornets",
        PTS: 1576,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/cha.png&h=200&w=200",
      },
      {
        id: 11,
        name: "Knicks",
        PTS: 1566,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/ny.png&h=200&w=200",
      },
      {
        id: 12,
        name: "76ers",
        PTS: 1524,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/phi.png&h=200&w=200",
      },
      {
        id: 13,
        name: "Pistons",
        PTS: 1521,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/det.png&h=200&w=200",
      },
      {
        id: 14,
        name: "Pelicans",
        PTS: 1351,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/no.png&h=200&w=200",
      },
      {
        id: 15,
        name: "Heat",
        PTS: 1248,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/mia.png&h=200&w=200",
      },
      {
        id: 16,
        name: "Trail Blazers",
        PTS: 1218,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/por.png&h=200&w=200",
      },
      {
        id: 17,
        name: "Grizzlies",
        PTS: 1202,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/mem.png&h=200&w=200",
      },
      {
        id: 18,
        name: "Suns",
        PTS: 1199,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/phx.png&h=200&w=200",
      },
      {
        id: 19,
        name: "Spurs",
        PTS: 1198,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/sa.png&h=200&w=200",
      },
      {
        id: 20,
        name: "Mavericks",
        PTS: 1168,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/dal.png&h=200&w=200",
      },
      {
        id: 21,
        name: "Warriors",
        PTS: 1146,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/gs.png&h=200&w=200",
      },
      {
        id: 22,
        name: "Clippers",
        PTS: 1136,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/lac.png&h=200&w=200",
      },
      {
        id: 23,
        name: "Jazz",
        PTS: 1133,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/utah.png",
      },
    ],
  },
};

let FGAttempts = {
  problem: {
    prompt: "Field Goal Attempts in a game since 2005",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Kobe Bryant",
        FGA: 50,
        "Player-additional": "bryanko01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/977.png",
      },
      {
        id: 2,
        name: "Jalen Brunson",
        FGA: 47,
        "Player-additional": "brunsja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628973.png",
      },
      {
        id: 7,
        name: "Dejounte Murray",
        FGA: 44,
        "Player-additional": "murrade01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627749.png",
      },
      {
        id: 8,
        name: "Russell Westbrook",
        FGA: 44,
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 14,
        name: "Joel Embiid",
        FGA: 41,
        "Player-additional": "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203954.png",
      },
      {
        id: 15,
        name: "James Harden",
        FGA: 41,
        "Player-additional": "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 16,
        name: "Tyrese Maxey",
        FGA: 41,
        "Player-additional": "maxeyty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630178.png",
      },
      {
        id: 17,
        name: "Devin Booker",
        FGA: 40,
        "Player-additional": "bookede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626164.png",
      },
      {
        id: 18,
        name: "Zach Randolph",
        FGA: 40,
        "Player-additional": "randoza01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2216.png",
      },
      {
        id: 20,
        name: "Giannis Antetokounmpo",
        FGA: 39,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203507.png",
      },
      {
        id: 24,
        name: "Monta Ellis",
        FGA: 39,
        "Player-additional": "ellismo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101145.png",
      },
      {
        id: 26,
        name: "Kawhi Leonard",
        FGA: 39,
        "Player-additional": "leonaka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202695.png",
      },
      {
        id: 27,
        name: "CJ McCollum",
        FGA: 39,
        "Player-additional": "mccolcj01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203468.png",
      },
      {
        id: 28,
        name: "Klay Thompson",
        FGA: 39,
        "Player-additional": "thompkl01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202691.png",
      },
      {
        id: 29,
        name: "Dwyane Wade",
        FGA: 39,
        "Player-additional": "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2548.png",
      },
      {
        id: 32,
        name: "Bradley Beal",
        FGA: 38,
        "Player-additional": "bealbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203078.png",
      },
      {
        id: 36,
        name: "Stephen Curry",
        FGA: 38,
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201939.png",
      },
      {
        id: 38,
        name: "DeMar DeRozan",
        FGA: 38,
        "Player-additional": "derozde01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201942.png",
      },
      {
        id: 40,
        name: "LeBron James",
        FGA: 38,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 41,
        name: "Damian Lillard",
        FGA: 38,
        "Player-additional": "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203081.png",
      },
      {
        id: 43,
        name: "Gilbert Arenas",
        FGA: 37,
        "Player-additional": "arenagi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2240.png",
      },
      {
        id: 53,
        name: "Luka Don\u010di\u0107",
        FGA: 37,
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 54,
        name: "Kevin Durant",
        FGA: 37,
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
    ],
  },
};

let usagerate = {
  problem: {
    prompt: "Highest usage rate in the 2023-24 regular season",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Joel Embiid",
        "USG%": 39.6,
        "Player-additional": "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203954.png",
      },
      {
        id: 2,
        name: "Luka Don\u010di\u0107",
        "USG%": 36.0,
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 3,
        name: "Giannis Antetokounmpo",
        "USG%": 33.0,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203507.png",
      },
      {
        id: 4,
        name: "Shai Gilgeous-Alexander",
        "USG%": 32.8,
        "Player-additional": "gilgesh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628983.png",
      },
      {
        id: 5,
        name: "Jalen Brunson",
        "USG%": 32.5,
        "Player-additional": "brunsja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628973.png",
      },
      {
        id: 6,
        name: "Anthony Edwards",
        "USG%": 32.3,
        "Player-additional": "edwaran01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630162.png",
      },
      {
        id: 7,
        name: "Victor Wembanyama",
        "USG%": 32.2,
        "Player-additional": "wembavi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1641705.png",
      },
      {
        id: 8,
        name: "Donovan Mitchell",
        "USG%": 31.4,
        "Player-additional": "mitchdo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628378.png",
      },
      {
        id: 9,
        name: "Stephen Curry",
        "USG%": 31.3,
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201939.png",
      },
      {
        id: 10,
        name: "De'Aaron Fox",
        "USG%": 31.0,
        "Player-additional": "foxde01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628368.png",
      },
      {
        id: 11,
        name: "Cade Cunningham",
        "USG%": 30.8,
        "Player-additional": "cunnica01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630595.png",
      },
      {
        id: 12,
        name: "Cam Thomas",
        "USG%": 30.5,
        "Player-additional": "thomaca02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630560.png",
      },
      {
        id: 13,
        name: "Trae Young",
        "USG%": 30.5,
        "Player-additional": "youngtr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629027.png",
      },
      {
        id: 14,
        name: "Jaren Jackson Jr.",
        "USG%": 30.4,
        "Player-additional": "jacksja02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628991.png",
      },
      {
        id: 15,
        name: "Jayson Tatum",
        "USG%": 30.2,
        "Player-additional": "tatumja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628369.png",
      },
      {
        id: 16,
        name: "Julius Randle",
        "USG%": 29.9,
        "Player-additional": "randlju01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203944.png",
      },
      {
        id: 17,
        name: "Devin Booker",
        "USG%": 29.8,
        "Player-additional": "bookede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626164.png",
      },
      {
        id: 18,
        name: "Kyle Kuzma",
        "USG%": 29.7,
        "Player-additional": "kuzmaky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628398.png",
      },
      {
        id: 19,
        name: "Paolo Banchero",
        "USG%": 29.7,
        "Player-additional": "banchpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1631094.png",
      },
      {
        id: 20,
        name: "Zion Williamson",
        "USG%": 29.7,
        "Player-additional": "willizi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1629627.png",
      },
      {
        id: 21,
        name: "Nikola Joki\u0107",
        "USG%": 29.3,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 22,
        name: "LeBron James",
        "USG%": 29.2,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 23,
        name: "Kevin Durant",
        "USG%": 29.0,
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
    ],
  },
};
let centerassists = {
  problem: {
    prompt: "Most assists by Center since 2000",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Nikola Joki\u0107",
        AST: 4667,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 2,
        name: "Al Horford",
        AST: 3501,
        "Player-additional": "horfoal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201143.png",
      },
      {
        id: 3,
        name: "Marc Gasol",
        AST: 2996,
        "Player-additional": "gasolma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201188.png",
      },
      {
        id: 4,
        name: "Nikola Vu\u010devi\u0107",
        AST: 2556,
        "Player-additional": "vucevni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202696.png",
      },
      {
        id: 5,
        name: "Tim Duncan",
        AST: 2133,
        "Player-additional": "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 6,
        name: "Pau Gasol",
        AST: 2122,
        "Player-additional": "gasolpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2200.png",
      },
      {
        id: 7,
        name: "Brad Miller",
        AST: 2091,
        "Player-additional": "millebr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1802.png",
      },
      {
        id: 8,
        name: "DeMarcus Cousins",
        AST: 1979,
        "Player-additional": "couside01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202326.png",
      },
      {
        id: 9,
        name: "Mason Plumlee",
        AST: 1911,
        "Player-additional": "plumlma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203486.png",
      },
      {
        id: 10,
        name: "Joakim Noah",
        AST: 1907,
        "Player-additional": "noahjo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201149.png",
      },
      {
        id: 11,
        name: "Domantas Sabonis",
        AST: 1609,
        "Player-additional": "sabondo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627734.png",
      },
      {
        id: 12,
        name: "Shaquille O'Neal",
        AST: 1596,
        "Player-additional": "onealsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/406.png",
      },
      {
        id: 13,
        name: "Joel Embiid",
        AST: 1573,
        "Player-additional": "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203954.png",
      },
      {
        id: 14,
        name: "Karl-Anthony Towns",
        AST: 1486,
        "Player-additional": "townska01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626157.png",
      },
      {
        id: 15,
        name: "Dwight Howard",
        AST: 1476,
        "Player-additional": "howardw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2730.png",
      },
      {
        id: 16,
        name: "Brook Lopez",
        AST: 1472,
        "Player-additional": "lopezbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201572.png",
      },
      {
        id: 17,
        name: "Zaza Pachulia",
        AST: 1433,
        "Player-additional": "pachuza01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2585.png",
      },
      {
        id: 18,
        name: "Marcus Camby",
        AST: 1403,
        "Player-additional": "cambyma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/948.png",
      },
      {
        id: 19,
        name: "Kelly Olynyk",
        AST: 1388,
        "Player-additional": "olynyke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203482.png",
      },
      {
        id: 20,
        name: "Andrew Bogut",
        AST: 1354,
        "Player-additional": "bogutan01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101106.png",
      },
      {
        id: 21,
        name: "Jusuf Nurki\u0107",
        AST: 1353,
        "Player-additional": "nurkiju01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203994.png",
      },
      {
        id: 22,
        name: "Bam Adebayo",
        AST: 1339,
        "Player-additional": "adebaba01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628389.png",
      },
      {
        id: 23,
        name: "Al Jefferson",
        AST: 1293,
        "Player-additional": "jeffeal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2744.png",
      },
    ],
  },
};
let celticsplayoffs = {
  problem: {
    prompt: "Boston Celtics Career Playoff Points Leaders since 1970",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Larry Bird",
        PTS: 3897,
        "Player-additional": "birdla01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1449.png",
      },
      {
        id: 2,
        name: "Kevin McHale",
        PTS: 3182,
        "Player-additional": "mchalke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1450.png",
      },
      {
        id: 3,
        name: "Paul Pierce",
        PTS: 2843,
        "Player-additional": "piercpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1718.png",
      },
      {
        id: 4,
        name: "Robert Parish",
        PTS: 2683,
        "Player-additional": "parisro01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/305.png",
      },
      {
        id: 5,
        name: "Jayson Tatum",
        PTS: 2287,
        "Player-additional": "tatumja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628369.png",
      },
      {
        id: 6,
        name: "Jaylen Brown",
        PTS: 1961,
        "Player-additional": "brownja02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627759.png",
      },
      {
        id: 7,
        name: "Dennis Johnson",
        PTS: 1802,
        "Player-additional": "johnsde01",
        img_url:
          "https://www.hoophall.com/files/cache/97afd33578682c1247f5e12ecdae2d96_f437.jpg",
      },
      {
        id: 8,
        name: "Jo Jo White",
        PTS: 1720,
        "Player-additional": "whitejo01",
        img_url:
          "https://www.basketball-reference.com/req/202106291/images/headshots/whitejo01.jpg",
      },
      {
        id: 9,
        name: "Dave Cowens",
        PTS: 1684,
        "Player-additional": "cowenda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76462.png",
      },
      {
        id: 10,
        name: "John Havlicek",
        PTS: 1668,
        "Player-additional": "havlijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/76970.png",
      },
      {
        id: 11,
        name: "Kevin Garnett",
        PTS: 1469,
        "Player-additional": "garneke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/708.png",
      },
      {
        id: 12,
        name: "Ray Allen",
        PTS: 1410,
        "Player-additional": "allenra02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/951.png",
      },
      {
        id: 13,
        name: "Marcus Smart",
        PTS: 1377,
        "Player-additional": "smartma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203935.png",
      },
      {
        id: 14,
        name: "Rajon Rondo",
        PTS: 1336,
        "Player-additional": "rondora01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200765.png",
      },
      {
        id: 15,
        name: "Danny Ainge",
        PTS: 1211,
        "Player-additional": "aingeda01",
        img_url:
          "https://images.fineartamerica.com/images/artworkimages/mediumlarge/3/2-danny-ainge-dick-raphael.jpg",
      },
      {
        id: 16,
        name: "Al Horford",
        PTS: 1121,
        "Player-additional": "horfoal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201143.png",
      },
      {
        id: 17,
        name: "Cedric Maxwell",
        PTS: 1052,
        "Player-additional": "maxwece01",
        img_url:
          "https://www.sportscasting.com/wp-content/uploads/2023/07/Cedric-Maxwell-new.jpg",
      },
      {
        id: 18,
        name: "Don Nelson",
        PTS: 826,
        "Player-additional": "nelsodo01",
        img_url:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Don_nelson_celtics.jpg/220px-Don_nelson_celtics.jpg",
      },
      {
        id: 19,
        name: "Reggie Lewis",
        PTS: 734,
        "Player-additional": "lewisre01",
        img_url: "https://a.espncdn.com/photo/2011/0509/bos_g_rlewis_200.jpg",
      },
      {
        id: 20,
        name: "Antoine Walker",
        PTS: 627,
        "Player-additional": "walkean02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/952.png",
      },
      {
        id: 21,
        name: "Gerald Henderson",
        PTS: 597,
        "Player-additional": "hendege01",
        img_url:
          "https://upload.wikimedia.org/wikipedia/commons/3/3f/Gerald_Henderson_Celtics.jpg",
      },
      {
        id: 22,
        name: "Isaiah Thomas",
        PTS: 564,
        "Player-additional": "thomais02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202738.png",
      },
      {
        id: 23,
        name: "Paul Silas",
        PTS: 563,
        "Player-additional": "silaspa01",
        img_url:
          "https://upload.wikimedia.org/wikipedia/commons/e/e0/Paul_Silas_1977_press_photo_by_Seattle_SuperSonics.jpg",
      },
    ],
  },
};
let HardenWins = {
  problem: {
    prompt: "Overall Wins as James Harden Teammate",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Russell Westbrook",
        W: 251,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 2,
        name: "Clint Capela",
        W: 244,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203991.png",
      },
      {
        id: 3,
        name: "P.J. Tucker",
        W: 232,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200782.png",
      },
      {
        id: 4,
        name: "Trevor Ariza",
        W: 221,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2772.png",
      },
      {
        id: 5,
        name: "Kevin Durant",
        W: 203,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 6,
        name: "Patrick Beverley",
        W: 180,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201976.png",
      },
      {
        id: 7,
        name: "Eric Gordon",
        W: 190,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201569.png",
      },
      {
        id: 8,
        name: "Thabo Sefolosha",
        W: 179,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200757.png",
      },
      {
        id: 9,
        name: "Serge Ibaka",
        W: 165,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201586.png",
      },
      {
        id: 10,
        name: "Nick Collison",
        W: 157,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2555.png",
      },
      {
        id: 11,
        name: "Corey Brewer",
        W: 128,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201147.png",
      },
      {
        id: 12,
        name: "Donatas Motiej\u016bnas",
        W: 130,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202700.png",
      },
      {
        id: 13,
        name: "Dwight Howard",
        W: 117,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2730.png",
      },
      {
        id: 14,
        name: "Jeff Green",
        W: 122,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201145.png",
      },
      {
        id: 15,
        name: "Terrence Jones",
        W: 115,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203093.png",
      },
      {
        id: 16,
        name: "Nen\u00ea",
        W: 131,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2403.png",
      },
      {
        id: 17,
        name: "Jason Terry",
        W: 100,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1891.png",
      },
      {
        id: 18,
        name: "Eric Maynor",
        W: 107,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4001.png",
      },
      {
        id: 19,
        name: "Danuel House Jr.",
        W: 103,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1627863.png",
      },
      {
        id: 20,
        name: "Ryan Anderson",
        W: 109,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201583.png",
      },
      {
        id: 21,
        name: "Jeremy Lin",
        W: 88,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202391.png",
      },
      {
        id: 22,
        name: "Chandler Parsons",
        W: 89,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202718.png",
      },
      {
        id: 23,
        name: "Montrezl Harrell",
        W: 85,
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626149.png",
      },
    ],
  },
};
let blocksgame = {
  problem: {
    prompt: "Most 5+ Block Games in a Single Season Post 2006-07",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Marcus Camby",
        Season: "2007-08",
        Count: 27,
        "Player-additional": "cambyma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/948.png",
      },
      {
        id: 2,
        name: "Victor Wembanyama",
        Season: "2023-24",
        Count: 24,
        "Player-additional": "wembavi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1641705.png",
      },
      {
        id: 3,
        name: "Hassan Whiteside",
        Season: "2015-16",
        Count: 22,
        "Player-additional": "whiteha01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202355.png",
      },
      {
        id: 4,
        name: "Serge Ibaka",
        Season: "2011-12",
        Count: 21,
        "Player-additional": "ibakase01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201586.png",
      },
      {
        id: 9,
        name: "Josh Smith",
        Season: "2007-08",
        Count: 16,
        "Player-additional": "smithjo03",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2746.png",
      },
      {
        id: 10,
        name: "Dwight Howard",
        Season: "2008-09",
        Count: 15,
        "Player-additional": "howardw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2730.png",
      },
      {
        id: 11,
        name: "Larry Sanders",
        Season: "2012-13",
        Count: 15,
        "Player-additional": "sandela01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202336.png",
      },
      {
        id: 12,
        name: "Anthony Davis",
        Season: "2017-18",
        Count: 14,
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203076.png",
      },
      {
        id: 14,
        name: "Jaren Jackson Jr.",
        Season: "2022-23",
        Count: 14,
        "Player-additional": "jacksja02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628991.png",
      },
      {
        id: 15,
        name: "Samuel Dalembert",
        Season: "2005-06",
        Count: 13,
        "Player-additional": "dalemsa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2223.png",
      },
      {
        id: 17,
        name: "Roy Hibbert",
        Season: "2013-14",
        Count: 13,
        "Player-additional": "hibbero01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201579.png",
      },
      {
        id: 19,
        name: "Emeka Okafor",
        Season: "2006-07",
        Count: 12,
        "Player-additional": "okafoem01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2731.png",
      },
      {
        id: 21,
        name: "Tim Duncan",
        Season: "2012-13",
        Count: 12,
        "Player-additional": "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 24,
        name: "Myles Turner",
        Season: "2020-21",
        Count: 12,
        "Player-additional": "turnemy01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1626167.png",
      },
      {
        id: 25,
        name: "Brook Lopez",
        Season: "2022-23",
        Count: 12,
        "Player-additional": "lopezbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201572.png",
      },
      {
        id: 26,
        name: "Ben Wallace",
        Season: "2005-06",
        Count: 11,
        "Player-additional": "wallabe01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1112.png",
      },
      {
        id: 27,
        name: "DeAndre Jordan",
        Season: "2013-14",
        Count: 11,
        "Player-additional": "jordade01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201599.png",
      },
      {
        id: 30,
        name: "Walker Kessler",
        Season: "2022-23",
        Count: 11,
        "Player-additional": "kesslwa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1631117.png",
      },
      {
        id: 32,
        name: "Alonzo Mourning",
        Season: "2005-06",
        Count: 10,
        "Player-additional": "mournal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/297.png",
      },
      {
        id: 33,
        name: "Elton Brand",
        Season: "2005-06",
        Count: 10,
        "Player-additional": "brandel01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1882.png",
      },
      {
        id: 34,
        name: "Jermaine O'Neal",
        Season: "2006-07",
        Count: 10,
        "Player-additional": "onealje01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/615.png",
      },
      {
        id: 35,
        name: "Chris Kaman",
        Season: "2007-08",
        Count: 10,
        "Player-additional": "kamanch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2549.png",
      },
      {
        id: 37,
        name: "Chris Andersen",
        Season: "2008-09",
        Count: 10,
        "Player-additional": "anderch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2365.png",
      },
    ],
  },
};
let playoffboards = {
  problem: {
    prompt: "Most Playoff Rebounds Since 2010",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "LeBron James",
        TRB: 2067,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 2,
        name: "Draymond Green",
        TRB: 1391,
        "Player-additional": "greendr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203110.png",
      },
      {
        id: 3,
        name: "Kevin Durant",
        TRB: 1314,
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 4,
        name: "Al Horford",
        TRB: 1244,
        "Player-additional": "horfoal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201143.png",
      },
      {
        id: 5,
        name: "Kawhi Leonard",
        TRB: 1080,
        "Player-additional": "leonaka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202695.png",
      },
      {
        id: 6,
        name: "Serge Ibaka",
        TRB: 965,
        "Player-additional": "ibakase01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201586.png",
      },
      {
        id: 7,
        name: "Giannis Antetokounmpo",
        TRB: 946,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203507.png",
      },
      {
        id: 8,
        name: "Dwight Howard",
        TRB: 903,
        "Player-additional": "howardw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2730.png",
      },
      {
        id: 9,
        name: "James Harden",
        TRB: 892,
        "Player-additional": "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 10,
        name: "Nikola Joki\u0107",
        TRB: 852,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 11,
        name: "Tim Duncan",
        TRB: 844,
        "Player-additional": "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 12,
        name: "Russell Westbrook",
        TRB: 844,
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 13,
        name: "Paul George",
        TRB: 821,
        "Player-additional": "georgpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202331.png",
      },
      {
        id: 14,
        name: "Stephen Curry",
        TRB: 786,
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201939.png",
      },
      {
        id: 15,
        name: "Marc Gasol",
        TRB: 746,
        "Player-additional": "gasolma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201188.png",
      },
      {
        id: 16,
        name: "Jimmy Butler",
        TRB: 735,
        "Player-additional": "butleji01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202710.png",
      },
      {
        id: 17,
        name: "Clint Capela",
        TRB: 734,
        "Player-additional": "capelca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203991.png",
      },
      {
        id: 18,
        name: "Tristan Thompson",
        TRB: 733,
        "Player-additional": "thomptr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202684.png",
      },
      {
        id: 19,
        name: "Pau Gasol",
        TRB: 725,
        "Player-additional": "gasolpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2200.png",
      },
      {
        id: 20,
        name: "Kevin Love",
        TRB: 724,
        "Player-additional": "loveke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201567.png",
      },
      {
        id: 21,
        name: "Jayson Tatum",
        TRB: 723,
        "Player-additional": "tatumja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1628369.png",
      },
      {
        id: 22,
        name: "Paul Millsap",
        TRB: 716,
        "Player-additional": "millspa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200794.png",
      },
      {
        id: 23,
        name: "Andre Iguodala",
        TRB: 686,
        "Player-additional": "iguodan01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2738.png",
      },
    ],
  },
};
let lakersgames = {
  problem: {
    prompt: "Most Games Played for Lakers Since 1990",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Kobe Bryant",
        G: 1346,
        "Player-additional": "bryanko01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/977.png",
      },
      {
        id: 2,
        name: "Derek Fisher",
        G: 915,
        "Player-additional": "fishede01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/246.png",
      },
      {
        id: 3,
        name: "Elden Campbell",
        G: 618,
        "Player-additional": "campbel01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/922.png",
      },
      {
        id: 4,
        name: "Lamar Odom",
        G: 519,
        "Player-additional": "odomla01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/617.png",
      },
      {
        id: 5,
        name: "Shaquille O'Neal",
        G: 514,
        "Player-additional": "onealsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/406.png",
      },
      {
        id: 6,
        name: "Luke Walton",
        G: 493,
        "Player-additional": "waltolu01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2027.png",
      },
      {
        id: 7,
        name: "Rick Fox",
        G: 486,
        "Player-additional": "foxri01",
        img_url:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Lipofsky-Rick_Fox.jpg/220px-Lipofsky-Rick_Fox.jpg",
      },
      {
        id: 8,
        name: "Vlade Divac",
        G: 453,
        "Player-additional": "divacvl01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/124.png",
      },
      {
        id: 9,
        name: "Robert Horry",
        G: 448,
        "Player-additional": "horryro01",
        img_url:
          "https://hoopshype.com/wp-content/uploads/sites/92/2018/10/i_84_ab_79_robert-horry.png?w=190&h=190&crop=1",
      },
      {
        id: 10,
        name: "Pau Gasol",
        G: 429,
        "Player-additional": "gasolpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2200.png",
      },
      {
        id: 11,
        name: "Devean George",
        G: 429,
        "Player-additional": "georgde01",
        img_url:
          "https://upload.wikimedia.org/wikipedia/commons/4/46/Devean_George_Feb_24_2008_crop.jpg",
      },
      {
        id: 12,
        name: "Sasha Vuja\u010di\u0107",
        G: 420,
        "Player-additional": "vujacsa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2756.png",
      },
      {
        id: 13,
        name: "Andrew Bynum",
        G: 392,
        "Player-additional": "bynuman01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2748.png",
      },
      {
        id: 14,
        name: "Sedale Threatt",
        G: 386,
        "Player-additional": "threase01",
        img_url:
          "https://www.latimes.com/includes/projects/img/lakers/bio_photos/sedale_threatt.jpg",
      },
      {
        id: 15,
        name: "Nick Van Exel",
        G: 378,
        "Player-additional": "vanexni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/89.png",
      },
      {
        id: 16,
        name: "Metta World Peace",
        G: 358,
        "Player-additional": "artesro01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1897.png",
      },
      {
        id: 17,
        name: "LeBron James",
        G: 349,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 18,
        name: "Jordan Farmar",
        G: 342,
        "Player-additional": "farmajo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200770.png",
      },
      {
        id: 19,
        name: "A.C. Green",
        G: 328,
        "Player-additional": "greenac01",
        img_url:
          "https://www.latimes.com/includes/projects/img/lakers/bio_photos/a_c_green.jpg",
      },
      {
        id: 20,
        name: "Tony Smith",
        G: 316,
        "Player-additional": "smithto02",
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsv2nfJP1ksgTmB2fIKoxzEI1pT0Fe9Ockd682ed3Kzw&s",
      },
      {
        id: 21,
        name: "Eddie Jones",
        G: 314,
        "Player-additional": "jonesed02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/224.png",
      },
      {
        id: 22,
        name: "Byron Scott",
        G: 301,
        "Player-additional": "scottby01",
        img_url:
          "https://cdn.nba.com/teams/legacy/www.nba.com/cavaliers/sites/cavaliers/files/legacy/main_photo/byron_121211.jpg",
      },
      {
        id: 23,
        name: "James Worthy",
        G: 294,
        "Player-additional": "worthja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1460.png",
      },
    ],
  },
};
let playoffwins = {
  problem: {
    prompt: "Most Playoff Wins Since 2010",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "GSW",
        W: 108,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/gs.png&h=200&w=200",
      },
      {
        id: 2,
        name: "MIA",
        W: 106,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/mia.png&h=200&w=200",
      },
      {
        id: 3,
        name: "BOS",
        W: 97,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/bos.png&h=200&w=200",
      },
      {
        id: 4,
        name: "SAS",
        W: 68,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/sa.png&h=200&w=200",
      },
      {
        id: 5,
        name: "CLE",
        W: 63,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/cle.png&h=200&w=200",
      },
      {
        id: 6,
        name: "OKC",
        W: 58,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/okc.png&h=200&w=200",
      },
      {
        id: 7,
        name: "LAL",
        W: 51,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/lal.png&h=200&w=200",
      },
      {
        id: 8,
        name: "MIL",
        W: 50,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/mil.png&h=200&w=200",
      },
      {
        id: 9,
        name: "TOR",
        W: 46,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/tor.png&h=200&w=200",
      },
      {
        id: 10,
        name: "DEN",
        W: 46,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/den.png&h=200&w=200",
      },
      {
        id: 11,
        name: "LAC",
        W: 45,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/lac.png&h=200&w=200",
      },
      {
        id: 12,
        name: "ATL",
        W: 44,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/atl.png&h=200&w=200",
      },
      {
        id: 13,
        name: "HOU",
        W: 42,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/hou.png&h=200&w=200",
      },
      {
        id: 14,
        name: "PHI",
        W: 40,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/phi.png&h=200&w=200",
      },
      {
        id: 15,
        name: "MEM",
        W: 38,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/mem.png&h=200&w=200",
      },
      {
        id: 16,
        name: "PHO",
        W: 37,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/phx.png&h=200&w=200",
      },
      {
        id: 17,
        name: "DAL",
        W: 37,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/dal.png&h=200&w=200",
      },
      {
        id: 18,
        name: "IND",
        W: 34,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/ind.png&h=200&w=200",
      },
      {
        id: 19,
        name: "CHI",
        W: 27,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/chi.png&h=200&w=200",
      },
      {
        id: 20,
        name: "POR",
        W: 26,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/por.png&h=200&w=200",
      },
      {
        id: 21,
        name: "UTA",
        W: 25,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/utah.png&h=200&w=200",
      },
      {
        id: 22,
        name: "WAS",
        W: 22,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/wsh.png&h=200&w=200",
      },
      {
        id: 23,
        name: "Bid",
        W: 18,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nba/500/bkn.png&h=200&w=200",
      },
    ],
  },
};

let playoddblocks = {
  problem: {
    prompt: "Playoff Blocks since 2015",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Draymond Green",
        BLK: 192,
        "Player-additional": "greendr01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6589.png&w=350&h=254",
      },
      {
        id: 2,
        name: "Al Horford",
        BLK: 160,
        "Player-additional": "horfoal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201143.png",
      },
      {
        id: 3,
        name: "LeBron James",
        BLK: 133,
        "Player-additional": "jamesle01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1966.png&w=350&h=254",
      },
      {
        id: 4,
        name: "Anthony Davis",
        BLK: 125,
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203076.png",
      },
      {
        id: 5,
        name: "Brook Lopez",
        BLK: 115,
        "Player-additional": "lopezbr01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3448.png&w=350&h=254",
      },
      {
        id: 6,
        name: "Kevin Durant",
        BLK: 111,
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201142.png",
      },
      {
        id: 7,
        name: "Clint Capela",
        BLK: 110,
        "Player-additional": "capelca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203991.png",
      },
      {
        id: 8,
        name: "Giannis Antetokounmpo",
        BLK: 105,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
      {
        id: 9,
        name: "Rudy Gobert",
        BLK: 94,
        "Player-additional": "goberru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203497.png",
      },
      {
        id: 10,
        name: "Serge Ibaka",
        BLK: 94,
        "Player-additional": "ibakase01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201586.png",
      },
      {
        id: 11,
        name: "Joel Embiid",
        BLK: 93,
        "Player-additional": "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203954.png",
      },
      {
        id: 12,
        name: "Jayson Tatum",
        BLK: 87,
        "Player-additional": "tatumja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628369.png",
      },
      {
        id: 13,
        name: "Robert Williams",
        BLK: 78,
        "Player-additional": "williro04",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4066211.png&w=350&h=254",
      },
      {
        id: 14,
        name: "Andrew Bogut",
        BLK: 75,
        "Player-additional": "bogutan01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2747.png",
      },
      {
        id: 15,
        name: "Danny Green",
        BLK: 74,
        "Player-additional": "greenda02",
        img_url: "https://a.espncdn.com/i/headshots/nba/players/full/3988.png",
      },
      {
        id: 16,
        name: "Klay Thompson",
        BLK: 73,
        "Player-additional": "thompkl01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6475.png&w=350&h=254",
      },
      {
        id: 17,
        name: "Andre Iguodala",
        BLK: 71,
        "Player-additional": "iguodan01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2386.png&w=350&h=254",
      },
      {
        id: 18,
        name: "Paul Millsap",
        BLK: 71,
        "Player-additional": "millspa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/200794.png",
      },
      {
        id: 19,
        name: "Tristan Thompson",
        BLK: 69,
        "Player-additional": "thomptr01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6474.png&w=350&h=254",
      },
      {
        id: 20,
        name: "Dwight Howard",
        BLK: 65,
        "Player-additional": "howardw01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2384.png&w=350&h=254",
      },
      {
        id: 21,
        name: "Jimmy Butler",
        BLK: 62,
        "Player-additional": "butleji01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6430.png&w=350&h=254",
      },
      {
        id: 22,
        name: "James Harden",
        BLK: 62,
        "Player-additional": "hardeja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3992.png&w=350&h=254",
      },
      {
        id: 23,
        name: "Nikola Jokić",
        BLK: 62,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
    ],
  },
};

let jjreddick = {
  problem: {
    prompt: "Games Played with JJ Redick",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Dwight Howard",
        G: 370,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2384.png&w=350&h=254",
      },
      {
        id: 2,
        name: "Hedo Türkoğlu",
        G: 353,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2045.png",
      },
      {
        id: 3,
        name: "Jameer Nelson",
        G: 345,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2749.png",
      },
      {
        id: 4,
        name: "DeAndre Jordan",
        G: 303,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3442.png&w=350&h=254",
      },
      {
        id: 5,
        name: "Jamal Crawford",
        G: 283,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/165.png",
      },
      {
        id: 6,
        name: "Chris Paul",
        G: 266,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2779.png&w=350&h=254",
      },
      {
        id: 7,
        name: "Blake Griffin",
        G: 223,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3989.png&w=350&h=254",
      },
      {
        id: 8,
        name: "Matt Barnes",
        G: 220,
        img_url: "https://a.espncdn.com/i/headshots/nba/players/full/1765.png",
      },
      {
        id: 9,
        name: "Rashard Lewis",
        G: 219,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/469.png",
      },
      {
        id: 10,
        name: "Glen Davis",
        G: 201,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3200.png",
      },
      {
        id: 11,
        name: "Austin Rivers",
        G: 191,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203085.png",
      },
      {
        id: 12,
        name: "Marcin Gortat",
        G: 189,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2758.png&w=350&h=254",
      },
      {
        id: 13,
        name: "Ryan Anderson",
        G: 187,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3412.png",
      },
      {
        id: 14,
        name: "Luc Mbah a Moute",
        G: 179,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3466.png&w=350&h=254",
      },
      {
        id: 15,
        name: "Ben Simmons",
        G: 165,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3907387.png&w=350&h=254",
      },
      {
        id: 16,
        name: "Brandon Bass",
        G: 165,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3015.png&w=350&h=254",
      },
      {
        id: 17,
        name: "Mickaël Piétrus",
        G: 162,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2173.png",
      },
      {
        id: 18,
        name: "T.J. McConnell",
        G: 154,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/204456.png",
      },
      {
        id: 19,
        name: "Wesley Johnson",
        G: 147,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4247.png&w=350&h=254",
      },
      {
        id: 20,
        name: "Joel Embiid",
        G: 133,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3059319.png&w=350&h=254",
      },
      {
        id: 21,
        name: "Amir Johnson",
        G: 122,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2769.png",
      },
      {
        id: 22,
        name: "Jason Williams",
        G: 109,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1715.png",
      },
      {
        id: 23,
        name: "Anthony Johnson",
        G: 106,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/396.png",
      },
    ],
  },
};

let assistpg1213 = {
  date: "3/25/2024",
  problem: {
    prompt: "2012-13 Assists per Game Leaderboard",
    sourceText: "Basketball-Reference",
    sourceLink: "https://www.basketball-reference.com/",
    options: [
      {
        id: 1,
        name: "Rajon Rondo",
        AST: 11.1,
        Player_additional: "rondora01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/200765.png",
      },
      {
        id: 2,
        name: "Chris Paul",
        AST: 9.7,
        Player_additional: "paulch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101108.png",
      },
      {
        id: 3,
        name: "Greivis Vásquez",
        AST: 9.0,
        Player_additional: "vasqugr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202349.png",
      },
      {
        id: 4,
        name: "Jrue Holiday",
        AST: 8.0,
        Player_additional: "holidjr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201950.png",
      },
      {
        id: 5,
        name: "Deron Williams",
        AST: 7.7,
        Player_additional: "willide01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101114.png",
      },
      {
        id: 6,
        name: "John Wall",
        AST: 7.6,
        Player_additional: "walljo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202322.png",
      },
      {
        id: 7,
        name: "Tony Parker",
        AST: 7.6,
        Player_additional: "parketo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2225.png",
      },
      {
        id: 8,
        name: "Russell Westbrook",
        AST: 7.4,
        Player_additional: "westbru01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3468.png&w=350&h=254",
      },
      {
        id: 9,
        name: "Goran Dragić",
        AST: 7.4,
        Player_additional: "dragigo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201609.png",
      },
      {
        id: 10,
        name: "Jameer Nelson",
        AST: 7.4,
        Player_additional: "nelsoja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2749.png",
      },
      {
        id: 11,
        name: "Ricky Rubio",
        AST: 7.3,
        Player_additional: "rubiori01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201937.png",
      },
      {
        id: 12,
        name: "LeBron James",
        AST: 7.3,
        Player_additional: "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2544.png",
      },
      {
        id: 13,
        name: "Jeff Teague",
        AST: 7.2,
        Player_additional: "teaguje01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201952.png",
      },
      {
        id: 14,
        name: "José Calderón",
        AST: 7.1,
        Player_additional: "caldejo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101181.png",
      },
      {
        id: 15,
        name: "Stephen Curry",
        AST: 6.9,
        Player_additional: "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201939.png",
      },
      {
        id: 16,
        name: "Ty Lawson",
        AST: 6.9,
        Player_additional: "lawsoty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201951.png",
      },
      {
        id: 17,
        name: "Steve Nash",
        AST: 6.7,
        Player_additional: "nashst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/959.png",
      },
      {
        id: 18,
        name: "Brandon Jennings",
        AST: 6.5,
        Player_additional: "jennibr01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3997.png",
      },
      {
        id: 19,
        name: "Damian Lillard",
        AST: 6.5,
        Player_additional: "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203081.png",
      },
      {
        id: 20,
        name: "Kyle Lowry",
        AST: 6.4,
        Player_additional: "lowryky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/200768.png",
      },
      {
        id: 21,
        name: "Mo Williams",
        AST: 6.2,
        Player_additional: "willima01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2590.png",
      },
      {
        id: 22,
        name: "Mike Conley",
        AST: 6.1,
        Player_additional: "conlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201144.png",
      },
      {
        id: 23,
        name: "Jeremy Lin",
        AST: 6.1,
        Player_additional: "linje01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202391.png",
      },
    ],
  },
};
let threepointpct = {
  problem: {
    prompt: "Highest 2023-24 3PT FG%",
    sourceText: "",
    sourceLink: "",
    options: [
      {
        id: 1,
        name: "Grayson Allen",
        "3P%": ".461",
        "Player-additional": "allengr01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628960.png",
      },
      {
        id: 2,
        name: "Luke Kennard",
        "3P%": ".450",
        "Player-additional": "kennalu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628379.png",
      },
      {
        id: 3,
        name: "Mike Conley",
        "3P%": ".442",
        "Player-additional": "conlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201144.png",
      },
      {
        id: 4,
        name: "Garrison Mathews",
        "3P%": ".440",
        "Player-additional": "mathega01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629726.png",
      },
      {
        id: 5,
        name: "Norman Powell",
        "3P%": ".435",
        "Player-additional": "powelno01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626181.png",
      },
      {
        id: 6,
        name: "Bradley Beal",
        "3P%": ".430",
        "Player-additional": "bealbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203078.png",
      },
      {
        id: 7,
        name: "CJ McCollum",
        "3P%": ".429",
        "Player-additional": "mccolcj01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2490149.png&w=350&h=254",
      },
      {
        id: 8,
        name: "Jrue Holiday",
        "3P%": ".429",
        "Player-additional": "holidjr01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3995.png",
      },
      {
        id: 9,
        name: "Jalen Williams",
        "3P%": ".427",
        "Player-additional": "willija06",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631114.png",
      },
      {
        id: 10,
        name: "Jamal Murray",
        "3P%": ".425",
        "Player-additional": "murraja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3936299.png&w=350&h=254",
      },
      {
        id: 11,
        name: "Sam Hauser",
        "3P%": ".424",
        "Player-additional": "hausesa01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630573.png",
      },
      {
        id: 12,
        name: "Rui Hachimura",
        "3P%": ".422",
        "Player-additional": "hachiru01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4066648.png&w=350&h=254",
      },
      {
        id: 13,
        name: "Aaron Nesmith",
        "3P%": ".419",
        "Player-additional": "nesmiaa01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630174.png",
      },
      {
        id: 14,
        name: "Cason Wallace",
        "3P%": ".419",
        "Player-additional": "wallaca01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4683692.png&w=350&h=254",
      },
      {
        id: 15,
        name: "Al Horford",
        "3P%": ".419",
        "Player-additional": "horfoal01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3213.png&w=350&h=254",
      },
      {
        id: 16,
        name: "Herbert Jones",
        "3P%": ".418",
        "Player-additional": "joneshe01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4277813.png&w=350&h=254",
      },
      {
        id: 17,
        name: "Kawhi Leonard",
        "3P%": ".417",
        "Player-additional": "leonaka01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6450.png&w=350&h=254",
      },
      {
        id: 18,
        name: "Isaiah Joe",
        "3P%": ".416",
        "Player-additional": "joeis01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630198.png",
      },
      {
        id: 19,
        name: "Karl-Anthony Towns",
        "3P%": ".416",
        "Player-additional": "townska01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3136195.png&w=350&h=254",
      },
      {
        id: 20,
        name: "D'Angelo Russell",
        "3P%": ".415",
        "Player-additional": "russeda01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626156.png",
      },
      {
        id: 21,
        name: "Naz Reid",
        "3P%": ".414",
        "Player-additional": "reidna01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629675.png",
      },
      {
        id: 22,
        name: "Tyus Jones",
        "3P%": ".414",
        "Player-additional": "jonesty01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626145.png",
      },
      {
        id: 23,
        name: "Malik Beasley",
        "3P%": ".413",
        "Player-additional": "beaslma01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627736.png",
      },
    ],
  },
};

let pointsinseason2024 = {
  problem: {
    prompt: "Points Scored Single Game this Season",
    sourceText: "NBA/BAA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Luka Dončić",
        Points: 73,
        Player_additional: "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 2,
        name: "Joel Embiid",
        Points: 70,
        Player_additional: "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203954.png",
      },
      {
        id: 3,
        name: "Giannis Antetokounmpo",
        Points: 64,
        Player_additional: "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
      {
        id: 4,
        name: "Devin Booker",
        Points: 62,
        Player_additional: "bookede01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626164.png",
      },
      {
        id: 5,
        name: "Karl-Anthony Towns",
        Points: 62,
        Player_additional: "townska01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626157.png",
      },
      {
        id: 6,
        name: "Jalen Brunson",
        Points: 61,
        Player_additional: "brunsja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628973.png",
      },
      {
        id: 7,
        name: "Stephen Curry",
        Points: 60,
        Player_additional: "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201939.png",
      },
      {
        id: 11,
        name: "Tyrese Maxey",
        Points: 52,
        Player_additional: "maxeyty01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630178.png",
      },
      {
        id: 12,
        name: "Anthony Edwards",
        Points: 51,
        Player_additional: "edwaran01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630162.png",
      },
      {
        id: 14,
        name: "Zach LaVine",
        Points: 51,
        Player_additional: "lavinza01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203897.png",
      },
      {
        id: 19,
        name: "Malachi Flynn",
        Points: 50,
        Player_additional: "flynnma01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4066668.png&w=350&h=254",
      },
      {
        id: 21,
        name: "Desmond Bane",
        Points: 49,
        Player_additional: "banede01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630217.png",
      },
      {
        id: 23,
        name: "Jerami Grant",
        Points: 49,
        Player_additional: "grantje01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203924.png",
      },
      {
        id: 27,
        name: "Kyrie Irving",
        Points: 48,
        Player_additional: "irvinky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202681.png",
      },
      {
        id: 29,
        name: "Keegan Murray",
        Points: 47,
        Player_additional: "murrake02",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631099.png",
      },
      {
        id: 33,
        name: "DeMar DeRozan",
        Points: 46,
        Player_additional: "derozde01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201942.png",
      },
      {
        id: 34,
        name: "Mikal Bridges",
        Points: 45,
        Player_additional: "bridgmi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628969.png",
      },
      {
        id: 35,
        name: "Miles Bridges",
        Points: 45,
        Player_additional: "bridgmi02",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628970.png",
      },
      {
        id: 41,
        name: "Kevin Durant",
        Points: 45,
        Player_additional: "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201142.png",
      },
      {
        id: 42,
        name: "Damian Lillard",
        Points: 45,
        Player_additional: "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203081.png",
      },
      {
        id: 43,
        name: "Donovan Mitchell",
        Points: 45,
        Player_additional: "mitchdo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628378.png",
      },
      {
        id: 45,
        name: "Alperen Sengun",
        Points: 45,
        Player_additional: "sengual01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630578.png",
      },
      {
        id: 46,
        name: "Jayson Tatum",
        Points: 45,
        Player_additional: "tatumja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628369.png",
      },
    ],
  },
};

let rookiepoints = {
  problem: {
    prompt: "Total Points Scored by a Rookie in a Season Since 2000",
    sourceText: "-9999",
    sourceLink: "-9999",
    options: [
      {
        id: 1,
        name: "Blake Griffin",
        PTS: 1845,
        "Player-additional": "griffbl01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3989.png",
      },
      {
        id: 2,
        name: "Carmelo Anthony",
        PTS: 1725,
        "Player-additional": "anthoca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2546.png",
      },
      {
        id: 3,
        name: "LeBron James",
        PTS: 1654,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2544.png",
      },
      {
        id: 4,
        name: "Kevin Durant",
        PTS: 1624,
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201142.png",
      },
      {
        id: 5,
        name: "Donovan Mitchell",
        PTS: 1616,
        "Player-additional": "mitchdo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628378.png",
      },
      {
        id: 6,
        name: "Damian Lillard",
        PTS: 1562,
        "Player-additional": "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203081.png",
      },
      {
        id: 7,
        name: "Trae Young",
        PTS: 1549,
        "Player-additional": "youngtr01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629027.png",
      },
      {
        id: 8,
        name: "Luka Dončić",
        PTS: 1526,
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 9,
        name: "Victor Wembanyama",
        PTS: 1522,
        "Player-additional": "wembavi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1641705.png",
      },
      {
        id: 10,
        name: "O.J. Mayo",
        PTS: 1516,
        "Player-additional": "mayooj01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201564.png",
      },
      {
        id: 11,
        name: "Karl-Anthony Towns",
        PTS: 1503,
        "Player-additional": "townska01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626157.png",
      },
      {
        id: 12,
        name: "Tyreke Evans",
        PTS: 1450,
        "Player-additional": "evansty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201936.png",
      },
      {
        id: 13,
        name: "Pau Gasol",
        PTS: 1441,
        "Player-additional": "gasolpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2200.png",
      },
      {
        id: 14,
        name: "Paolo Banchero",
        PTS: 1437,
        "Player-additional": "banchpa01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631094.png",
      },
      {
        id: 15,
        name: "Stephen Curry",
        PTS: 1399,
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201939.png",
      },
      {
        id: 16,
        name: "Anthony Edwards",
        PTS: 1392,
        "Player-additional": "edwaran01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630162.png",
      },
      {
        id: 17,
        name: "Andrew Wiggins",
        PTS: 1387,
        "Player-additional": "wiggian01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203952.png",
      },
      {
        id: 18,
        name: "Collin Sexton",
        PTS: 1371,
        "Player-additional": "sextoco01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629012.png",
      },
      {
        id: 19,
        name: "Derrick Rose",
        PTS: 1361,
        "Player-additional": "rosede01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201565.png",
      },
      {
        id: 20,
        name: "Chet Holmgren",
        PTS: 1357,
        "Player-additional": "holmgch01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4433255.png",
      },
      {
        id: 21,
        name: "Bennedict Mathurin",
        PTS: 1302,
        "Player-additional": "mathube01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631097.png",
      },
      {
        id: 22,
        name: "Brandon Miller",
        PTS: 1279,
        "Player-additional": "millebr03",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4433287.png&w=350&h=254",
      },
      {
        id: 23,
        name: "Ben Simmons",
        PTS: 1279,
        "Player-additional": "simmobe01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3907387.png",
      },
    ],
  },
};

let totalpointsseason = {
  problem: {
    prompt: "Total Points Scored in a Season Since 1990",
    sourceText: "ESPN or NBA",
    sourceLink: "-9999",
    options: [
      {
        id: 1,
        name: "Kobe Bryant",
        PTS: 2832,
        "Player-additional": "bryanko01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/110.png&w=350&h=254",
      },
      {
        id: 2,
        name: "James Harden",
        PTS: 2818,
        "Player-additional": "hardeja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3992.png&w=350&h=254",
      },
      {
        id: 3,
        name: "Kevin Durant",
        PTS: 2593,
        "Player-additional": "duranke01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3202.png&w=350&h=254",
      },
      {
        id: 4,
        name: "Michael Jordan",
        PTS: 2580,
        "Player-additional": "jordami01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/893.png",
      },
      {
        id: 5,
        name: "Russell Westbrook",
        PTS: 2558,
        "Player-additional": "westbru01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3468.png&w=350&h=254",
      },
      {
        id: 8,
        name: "LeBron James",
        PTS: 2478,
        "Player-additional": "jamesle01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1966.png&w=350&h=254",
      },
      {
        id: 13,
        name: "Tracy McGrady",
        PTS: 2407,
        "Player-additional": "mcgratr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1503.png",
      },
      {
        id: 15,
        name: "Dwyane Wade",
        PTS: 2386,
        "Player-additional": "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2548.png",
      },
      {
        id: 16,
        name: "David Robinson",
        PTS: 2383,
        "Player-additional": "robinda01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/764.png",
      },
      {
        id: 17,
        name: "Karl Malone",
        PTS: 2382,
        "Player-additional": "malonka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/252.png",
      },
      {
        id: 18,
        name: "Jerry Stackhouse",
        PTS: 2380,
        "Player-additional": "stackje01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/802.png",
      },
      {
        id: 19,
        name: "Allen Iverson",
        PTS: 2377,
        "Player-additional": "iversal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/947.png",
      },
      {
        id: 20,
        name: "Shaquille O'Neal",
        PTS: 2377,
        "Player-additional": "onealsh01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/614.png",
      },
      {
        id: 22,
        name: "Stephen Curry",
        PTS: 2375,
        "Player-additional": "curryst01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3975.png&w=350&h=254",
      },
      {
        id: 23,
        name: "Luka Dončić",
        PTS: 2370,
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 26,
        name: "Gilbert Arenas",
        PTS: 2346,
        "Player-additional": "arenagi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2240.png",
      },
      {
        id: 37,
        name: "Shai Gilgeous-Alexander",
        PTS: 2254,
        "Player-additional": "gilgesh01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628983.png",
      },
      {
        id: 42,
        name: "Jayson Tatum",
        PTS: 2225,
        "Player-additional": "tatumja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628369.png",
      },
      {
        id: 43,
        name: "Giannis Antetokounmpo",
        PTS: 2222,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
      {
        id: 46,
        name: "Jalen Brunson",
        PTS: 2212,
        "Player-additional": "brunsja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628973.png",
      },
      {
        id: 49,
        name: "Isaiah Thomas",
        PTS: 2199,
        "Player-additional": "thomais02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202738.png",
      },
      {
        id: 53,
        name: "Hakeem Olajuwon",
        PTS: 2184,
        "Player-additional": "olajuha01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/165.png",
      },
      {
        id: 54,
        name: "Joel Embiid",
        PTS: 2183,
        "Player-additional": "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203954.png",
      },
    ],
  },
};

let turnovers = {
  date: "3/28/2024",
  problem: {
    prompt: "Most Turnovers This Season",
    sourceText: "Various sources",
    sourceLink: "-9999",
    options: [
      {
        id: 1,
        name: "Luka Dončić",
        TOV: 282,
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 2,
        name: "Domantas Sabonis",
        TOV: 268,
        "Player-additional": "sabondo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627734.png",
      },
      {
        id: 3,
        name: "Victor Wembanyama",
        TOV: 260,
        "Player-additional": "wembavi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1641705.png",
      },
      {
        id: 4,
        name: "Giannis Antetokounmpo",
        TOV: 250,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
      {
        id: 5,
        name: "Paolo Banchero",
        TOV: 244,
        "Player-additional": "banchpa01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631094.png",
      },
      {
        id: 6,
        name: "LeBron James",
        TOV: 241,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2544.png",
      },
      {
        id: 7,
        name: "Kevin Durant",
        TOV: 240,
        "Player-additional": "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201142.png",
      },
      {
        id: 8,
        name: "Anthony Edwards",
        TOV: 236,
        "Player-additional": "edwaran01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630162.png",
      },
      {
        id: 9,
        name: "Nikola Jokić",
        TOV: 233,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 10,
        name: "Trae Young",
        TOV: 227,
        "Player-additional": "youngtr01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629027.png",
      },
      {
        id: 11,
        name: "Cade Cunningham",
        TOV: 212,
        "Player-additional": "cunnica01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630595.png",
      },
      {
        id: 12,
        name: "Scoot Henderson",
        TOV: 212,
        "Player-additional": "hendesc01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4683678.png",
      },
      {
        id: 13,
        name: "Stephen Curry",
        TOV: 210,
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201939.png",
      },
      {
        id: 14,
        name: "Dejounte Murray",
        TOV: 198,
        "Player-additional": "murrade01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627749.png",
      },
      {
        id: 15,
        name: "De'Aaron Fox",
        TOV: 193,
        "Player-additional": "foxde01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628368.png",
      },
      {
        id: 16,
        name: "Kyle Kuzma",
        TOV: 192,
        "Player-additional": "kuzmaky01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628398.png",
      },
      {
        id: 17,
        name: "Damian Lillard",
        TOV: 191,
        "Player-additional": "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203081.png",
      },
      {
        id: 18,
        name: "Jalen Green",
        TOV: 190,
        "Player-additional": "greenja05",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630224.png",
      },
      {
        id: 19,
        name: "Zion Williamson",
        TOV: 189,
        "Player-additional": "willizi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629627.png",
      },
      {
        id: 20,
        name: "Jaden Ivey",
        TOV: 188,
        "Player-additional": "iveyja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631093.png",
      },
      {
        id: 21,
        name: "Jordan Poole",
        TOV: 188,
        "Player-additional": "poolejo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629673.png",
      },
      {
        id: 22,
        name: "Jayson Tatum",
        TOV: 188,
        "Player-additional": "tatumja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628369.png",
      },
      {
        id: 23,
        name: "Keyonte George",
        TOV: 187,
        "Player-additional": "georgke01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4433627.png&w=350&h=254",
      },
    ],
  },
};

let heatpoints = {
  problem: {
    prompt: "Heat Franchise Leader Points",
    sourceText: "Various sources",
    sourceLink: "Various links",
    options: [
      {
        id: 1,
        name: "Dwyane Wade",
        PTS: 21556,
        "Player-additional": "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2548.png",
      },
      {
        id: 2,
        name: "Alonzo Mourning",
        PTS: 9459,
        "Player-additional": "mournal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/297.png",
      },
      {
        id: 3,
        name: "Glen Rice",
        PTS: 9248,
        "Player-additional": "ricegl01",
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJp2dDb1pl0G4RCCRXc9_KBMvaYena952RdpQZrOaoPw&s",
      },
      {
        id: 4,
        name: "LeBron James",
        PTS: 7919,
        "Player-additional": "jamesle01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1966.png",
      },
      {
        id: 5,
        name: "Bam Adebayo",
        PTS: 7496,
        "Player-additional": "adebaba01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628389.png",
      },
      {
        id: 6,
        name: "Chris Bosh",
        PTS: 6914,
        "Player-additional": "boshch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2547.png",
      },
      {
        id: 7,
        name: "Rony Seikaly",
        PTS: 6742,
        "Player-additional": "seikaro01",
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRu06y5htc4g3od0HQpc0dku5MGPurkerFQ9SlYPkmjw&s",
      },
      {
        id: 8,
        name: "Udonis Haslem",
        PTS: 6586,
        "Player-additional": "hasleud01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2617.png",
      },
      {
        id: 9,
        name: "Goran Dragić",
        PTS: 6348,
        "Player-additional": "dragigo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201609.png",
      },
      {
        id: 10,
        name: "Tim Hardaway",
        PTS: 6335,
        "Player-additional": "hardati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203501.png",
      },
      {
        id: 11,
        name: "Eddie Jones",
        PTS: 6194,
        "Player-additional": "jonesed02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/224.png",
      },
      {
        id: 12,
        name: "Jimmy Butler",
        PTS: 6189,
        "Player-additional": "butleji01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202710.png",
      },
      {
        id: 13,
        name: "Grant Long",
        PTS: 5473,
        "Player-additional": "longgr01",
        img_url:
          "https://www.ajc.com/resizer/wC5Rx-FafGlqDay3emazNEYD13o=/fit-in/850x480/cloudfront-us-east-1.images.arcpublishing.com/ajc/6EJFFAQHZXZBRPGFBWIQRPI6OQ.jpg",
      },
      {
        id: 14,
        name: "Tyler Herro",
        PTS: 5130,
        "Player-additional": "herroty01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629639.png",
      },
      {
        id: 15,
        name: "Mario Chalmers",
        PTS: 4641,
        "Player-additional": "chalmma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201596.png",
      },
      {
        id: 16,
        name: "Hassan Whiteside",
        PTS: 4554,
        "Player-additional": "whiteha01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4262.png&w=350&h=254",
      },
      {
        id: 17,
        name: "Kevin Edwards",
        PTS: 4362,
        "Player-additional": "edwarke01",
        img_url:
          "https://www.legendsofbasketball.com/wp-content/uploads/2018/04/3-1-181x175.png",
      },
      {
        id: 18,
        name: "Bimbo Coles",
        PTS: 4031,
        "Player-additional": "colesbi01",
        img_url: "https://www.cavshistory.com/images/players/Bimbo_Coles.jpg",
      },
      {
        id: 19,
        name: "Shaquille O'Neal",
        PTS: 4010,
        "Player-additional": "onealsh01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/614.png",
      },
      {
        id: 20,
        name: "Duncan Robinson",
        PTS: 3981,
        "Player-additional": "robindu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629130.png",
      },
      {
        id: 21,
        name: "Josh Richardson",
        PTS: 3559,
        "Player-additional": "richajo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626196.png",
      },
      {
        id: 22,
        name: "Brian Grant",
        PTS: 3433,
        "Player-additional": "grantbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/258.png",
      },
      {
        id: 23,
        name: "Michael Beasley",
        PTS: 2927,
        "Player-additional": "beaslmi01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3418.png",
      },
    ],
  },
};

let steals24 = {
  problem: {
    prompt: "Most Steals 2023-24 Season",
    sourceText: "Various sources",
    sourceLink: "Various links",
    options: [
      {
        id: 1,
        name: "Shai Gilgeous-Alexander",
        STL: 150,
        "Player-additional": "gilgesh01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628983.png",
      },
      {
        id: 2,
        name: "De'Aaron Fox",
        STL: 143,
        "Player-additional": "foxde01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628368.png",
      },
      {
        id: 3,
        name: "Alex Caruso",
        STL: 115,
        "Player-additional": "carusal01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2991350.png&w=350&h=254",
      },
      {
        id: 4,
        name: "Matisse Thybulle",
        STL: 113,
        "Player-additional": "thybuma01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629680.png",
      },
      {
        id: 5,
        name: "Paul George",
        STL: 112,
        "Player-additional": "georgpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202331.png",
      },
      {
        id: 6,
        name: "Kawhi Leonard",
        STL: 111,
        "Player-additional": "leonaka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202695.png",
      },
      {
        id: 7,
        name: "Dejounte Murray",
        STL: 110,
        "Player-additional": "murrade01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627749.png",
      },
      {
        id: 8,
        name: "Jalen Suggs",
        STL: 105,
        "Player-additional": "suggsja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630591.png",
      },
      {
        id: 9,
        name: "Donte DiVincenzo",
        STL: 103,
        "Player-additional": "divindo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628978.png",
      },
      {
        id: 10,
        name: "Nikola Jokić",
        STL: 102,
        "Player-additional": "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 11,
        name: "Herbert Jones",
        STL: 102,
        "Player-additional": "joneshe01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4277813.png&w=350&h=254",
      },
      {
        id: 12,
        name: "Fred VanVleet",
        STL: 101,
        "Player-additional": "vanvlfr01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627832.png",
      },
      {
        id: 13,
        name: "Luka Dončić",
        STL: 99,
        "Player-additional": "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 14,
        name: "Anthony Edwards",
        STL: 98,
        "Player-additional": "edwaran01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630162.png",
      },
      {
        id: 15,
        name: "Kentavious Caldwell-Pope",
        STL: 97,
        "Player-additional": "caldwke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203484.png",
      },
      {
        id: 16,
        name: "Donovan Mitchell",
        STL: 97,
        "Player-additional": "mitchdo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628378.png",
      },
      {
        id: 17,
        name: "Bogdan Bogdanović",
        STL: 94,
        "Player-additional": "bogdabo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203992.png",
      },
      {
        id: 18,
        name: "Anthony Davis",
        STL: 88,
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203076.png",
      },
      {
        id: 19,
        name: "Giannis Antetokounmpo",
        STL: 87,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
      {
        id: 20,
        name: "Victor Wembanyama",
        STL: 87,
        "Player-additional": "wembavi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1641705.png",
      },
      {
        id: 21,
        name: "DeMar DeRozan",
        STL: 86,
        "Player-additional": "derozde01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3978.png&w=350&h=254",
      },
      {
        id: 22,
        name: "Jordan Poole",
        STL: 86,
        "Player-additional": "poolejo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629673.png",
      },
      {
        id: 23,
        name: "Mike Conley",
        STL: 85,
        "Player-additional": "conlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201144.png",
      },
    ],
  },
};

let points16 = {
  problem: {
    prompt: "Most Points in Game 2016-17 Season",
    sourceText: "Various sources",
    sourceLink: "Various links",
    options: [
      {
        id: 1,
        name: "Devin Booker",
        Points: 70,
        "Player-additional": "bookede01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626164.png",
      },
      {
        id: 2,
        name: "Klay Thompson",
        Points: 60,
        "Player-additional": "thompkl01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202691.png",
      },
      {
        id: 3,
        name: "Damian Lillard",
        Points: 59,
        "Player-additional": "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203081.png",
      },
      {
        id: 4,
        name: "Russell Westbrook",
        Points: 58,
        "Player-additional": "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201566.png",
      },
      {
        id: 6,
        name: "DeMarcus Cousins",
        Points: 55,
        "Player-additional": "couside01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4258.png",
      },
      {
        id: 7,
        name: "James Harden",
        Points: 53,
        "Player-additional": "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201935.png",
      },
      {
        id: 8,
        name: "Isaiah Thomas",
        Points: 53,
        "Player-additional": "thomais02",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6472.png",
      },
      {
        id: 9,
        name: "Jimmy Butler",
        Points: 52,
        "Player-additional": "butleji01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202710.png",
      },
      {
        id: 11,
        name: "John Wall",
        Points: 52,
        "Player-additional": "walljo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202322.png",
      },
      {
        id: 15,
        name: "Anthony Davis",
        Points: 50,
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203076.png",
      },
      {
        id: 17,
        name: "Kyrie Irving",
        Points: 49,
        "Player-additional": "irvinky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202681.png",
      },
      {
        id: 21,
        name: "Karl-Anthony Towns",
        Points: 47,
        "Player-additional": "townska01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626157.png",
      },
      {
        id: 24,
        name: "Andrew Wiggins",
        Points: 47,
        "Player-additional": "wiggian01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203952.png",
      },
      {
        id: 26,
        name: "Stephen Curry",
        Points: 46,
        "Player-additional": "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201939.png",
      },
      {
        id: 30,
        name: "Carmelo Anthony",
        Points: 45,
        "Player-additional": "anthoca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2546.png",
      },
      {
        id: 39,
        name: "LeBron James",
        Points: 44,
        "Player-additional": "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2544.png",
      },
      {
        id: 43,
        name: "DeMar DeRozan",
        Points: 43,
        "Player-additional": "derozde01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3978.png&w=350&h=254",
      },
      {
        id: 44,
        name: "Paul George",
        Points: 43,
        "Player-additional": "georgpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202331.png",
      },
      {
        id: 45,
        name: "Blake Griffin",
        Points: 43,
        "Player-additional": "griffbl01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3989.png",
      },
      {
        id: 48,
        name: "Kawhi Leonard",
        Points: 43,
        "Player-additional": "leonaka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202695.png",
      },
      {
        id: 49,
        name: "CJ McCollum",
        Points: 43,
        "Player-additional": "mccolcj01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203468.png",
      },
      {
        id: 51,
        name: "Bradley Beal",
        Points: 42,
        "Player-additional": "bealbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203078.png",
      },

      {
        id: 65,
        name: "Giannis Antetokounmpo",
        Points: 41,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
    ],
  },
};

let Lefty = {
  problem: {
    prompt: "Most Points Since 2000 (Shoot Lefty)",
    sourceText: "Various sources",
    sourceLink: "Various links",
    options: [
      {
        id: 1,
        name: "James Harden",
        PTS: 25881,
        player_additional: "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201935.png",
      },
      {
        id: 2,
        name: "Zach Randolph",
        PTS: 18578,
        player_additional: "randoza01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1017.png",
      },
      {
        id: 3,
        name: "Chris Bosh",
        PTS: 17189,
        player_additional: "boshch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2547.png",
      },
      {
        id: 4,
        name: "Mike Conley",
        PTS: 15839,
        player_additional: "conlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201144.png",
      },
      {
        id: 5,
        name: "Thaddeus Young",
        PTS: 14220,
        player_additional: "youngth01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201152.png",
      },
      {
        id: 6,
        name: "Manu Ginóbili",
        PTS: 14043,
        player_additional: "ginobma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1938.png",
      },
      {
        id: 7,
        name: "Josh Smith",
        PTS: 12996,
        player_additional: "smithjo03",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2746.png",
      },
      {
        id: 8,
        name: "Goran Dragić",
        PTS: 12568,
        player_additional: "dragigo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201609.png",
      },
      {
        id: 9,
        name: "Julius Randle",
        PTS: 12229,
        player_additional: "randlju01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203944.png",
      },
      {
        id: 10,
        name: "Michael Redd",
        PTS: 11972,
        player_additional: "reddmi01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/692.png",
      },
      {
        id: 11,
        name: "Lamar Odom",
        PTS: 11522,
        player_additional: "odomla01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/617.png&w=350&h=254",
      },
      {
        id: 12,
        name: "Tayshaun Prince",
        PTS: 11272,
        player_additional: "princta01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2419.png",
      },
      {
        id: 13,
        name: "David Lee",
        PTS: 11232,
        player_additional: "leeda02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101135.png",
      },
      {
        id: 14,
        name: "Cuttino Mobley",
        PTS: 10200,
        player_additional: "moblecu01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1749.png",
      },
      {
        id: 15,
        name: "D'Angelo Russell",
        PTS: 10113,
        player_additional: "russeda01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626156.png",
      },
      {
        id: 16,
        name: "De'Aaron Fox",
        PTS: 9860,
        player_additional: "foxde01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628368.png",
      },
      {
        id: 17,
        name: "Isaiah Thomas",
        PTS: 9720,
        player_additional: "thomais02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202738.png",
      },
      {
        id: 18,
        name: "DeAndre Jordan",
        PTS: 9274,
        player_additional: "jordade01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3442.png",
      },
      {
        id: 19,
        name: "Derek Fisher",
        PTS: 9143,
        player_additional: "fishede01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/246.png",
      },
      {
        id: 20,
        name: "Domantas Sabonis",
        PTS: 9003,
        player_additional: "sabondo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627734.png",
      },
      {
        id: 21,
        name: "Jalen Rose",
        PTS: 8502,
        player_additional: "roseja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/147.png",
      },
      {
        id: 22,
        name: "Greg Monroe",
        PTS: 8402,
        player_additional: "monrogr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202328.png",
      },
      {
        id: 23,
        name: "C.J. Miles",
        PTS: 8108,
        player_additional: "milescj01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101139.png",
      },
    ],
  },
};

let uconnpoints = {
  problem: {
    prompt: "Career NBA Points from UConn Alumni",
    sourceText: "NBA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Ray Allen",
        PTS: 24505,
        player_additional: "allenra02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/951.png",
      },
      {
        id: 2,
        name: "Clifford Robinson",
        PTS: 19591,
        player_additional: "robincl02",
        img_url:
          "https://akns-images.eonline.com/eol_images/Entire_Site/2020729/rs_634x1024-200829100035-634-cliff-robinson.cm.82920.jpg?fit=around%7C634:1024&output-quality=90&crop=634:1024;center,top",
      },
      {
        id: 3,
        name: "Rudy Gay",
        PTS: 17642,
        player_additional: "gayru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/200752.png",
      },
      {
        id: 4,
        name: "Richard Hamilton",
        PTS: 15708,
        player_additional: "hamilri01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/294.png",
      },
      {
        id: 5,
        name: "Kemba Walker",
        PTS: 14486,
        player_additional: "walkeke02",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6479.png",
      },
      {
        id: 6,
        name: "Caron Butler",
        PTS: 12430,
        player_additional: "butleca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2406.png",
      },
      {
        id: 7,
        name: "Ben Gordon",
        PTS: 11084,
        player_additional: "gordobe01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2732.png",
      },
      {
        id: 8,
        name: "Andre Drummond",
        PTS: 10996,
        player_additional: "drumman01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203083.png",
      },
      {
        id: 9,
        name: "Donyell Marshall",
        PTS: 10716,
        player_additional: "marshdo01",
        img_url:
          "https://www.cavshistory.com/images/players/Donyell_Marshall.jpg",
      },
      {
        id: 10,
        name: "Emeka Okafor",
        PTS: 7370,
        player_additional: "okafoem01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2731.png",
      },
      {
        id: 11,
        name: "Charlie Villanueva",
        PTS: 6808,
        player_additional: "villach01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101111.png",
      },
      {
        id: 12,
        name: "Jeremy Lamb",
        PTS: 5805,
        player_additional: "lambje01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203087.png",
      },
      {
        id: 13,
        name: "Toby Kimball",
        PTS: 3470,
        player_additional: "kimbato01",
        img_url:
          "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg9DXrczzYu70yhRs6ckcEO1BkA3Yp28sf5tsg0fbn85nErCzDHEF4kmDgIW7-cEj5yP1SsEURtcC6JCoMmitrUPQdqxFBG4LaoH7DWUlcTRSPgnrGjvpgpxcDPLZ-CRbpPr6NS7AkbJgbx/w1200-h630-p-k-no-nu/kimball.jpg",
      },
      {
        id: 14,
        name: "Scott Burrell",
        PTS: 2647,
        player_additional: "burresc01",
        img_url:
          "https://static.foxnews.com/foxnews.com/content/uploads/2020/05/Scott-Burrell-.jpg",
      },
      {
        id: 15,
        name: "Kevin Ollie",
        PTS: 2496,
        player_additional: "ollieke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1563.png",
      },
      {
        id: 16,
        name: "Shabazz Napier",
        PTS: 2433,
        player_additional: "napiesh01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2530780.png",
      },
      {
        id: 17,
        name: "Jake Voskuhl",
        PTS: 1814,
        player_additional: "voskuja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/879.png",
      },
      {
        id: 18,
        name: "A.J. Price",
        PTS: 1523,
        player_additional: "priceaj01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4010.png&w=350&h=254",
      },
      {
        id: 19,
        name: "Josh Boone",
        PTS: 1340,
        player_additional: "boonejo01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2990.png",
      },
      {
        id: 20,
        name: "Travis Knight",
        PTS: 1276,
        player_additional: "knightr01",
        img_url:
          "https://cdn.bleacherreport.net/images_root/slides/photos/000/813/064/369620_original.jpg?1301185451",
      },
      {
        id: 21,
        name: "Chris Smith",
        PTS: 1140,
        player_additional: "smithch03",
        img_url:
          "https://www.uconnhooplegends.com/images_menslegends/images_menslegdpgs/SmithChris_headshot.jpg",
      },
      {
        id: 22,
        name: "Marcus Williams",
        PTS: 1128,
        player_additional: "willima03",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3240.png&w=350&h=254",
      },
      {
        id: 23,
        name: "Hilton Armstrong",
        PTS: 880,
        player_additional: "armsthi01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2984.png",
      },
    ],
  },
};

let mostcollegewins = {
  date: "3/30/2024",
  problem: {
    prompt: "Colleges with Most NCAA Wins",
    sourceText: "NCAA",
    sourceLink: "https://www.ncaa.com/",
    options: [
      {
        id: 1,
        name: "North Carolina",
        value: 352,
        img_url:
          "https://loodibee.com/wp-content/uploads/North_Carolina_Tar_Heels_logo-480x480.png",
      },
      {
        id: 2,
        name: "Duke",
        value: 332,
        img_url:
          "https://loodibee.com/wp-content/uploads/Duke_Blue_Devils_logo-480x480.png",
      },
      {
        id: 3,
        name: "Kentucky",
        value: 314,
        img_url:
          "https://loodibee.com/wp-content/uploads/Kentucky_Wildcats_logo-480x480.png",
      },
      {
        id: 4,
        name: "Kansas",
        value: 268,
        img_url:
          "https://loodibee.com/wp-content/uploads/Kansas_Jayhawks_logo-480x480.png",
      },
      {
        id: 5,
        name: "Indiana",
        value: 247,
        img_url:
          "https://loodibee.com/wp-content/uploads/Indiana_Hoosiers_logo-480x480.png",
      },
      {
        id: 6,
        name: "UCLA",
        value: 234,
        img_url:
          "https://loodibee.com/wp-content/uploads/UCLA_Bruins-480x480.png",
      },
      {
        id: 7,
        name: "Michigan State",
        value: 224,
        img_url:
          "https://loodibee.com/wp-content/uploads/Michigan_State_Spartans_logo-480x480.png",
      },
      {
        id: 8,
        name: "Ohio State",
        value: 210,
        img_url:
          "https://loodibee.com/wp-content/uploads/Ohio_State_Buckeyes_logo-480x480.png",
      },
      {
        id: 9,
        name: "Villanova",
        value: 202,
        img_url:
          "https://loodibee.com/wp-content/uploads/Villanova-Wildcats-logo-480x480.png",
      },
      {
        id: 10,
        name: "NC State",
        value: 198,
        img_url:
          "https://loodibee.com/wp-content/uploads/North_Carolina_State_Wolfpack_logo-480x480.png",
      },
      {
        id: 11,
        name: "Michigan",
        value: 197,
        img_url:
          "https://loodibee.com/wp-content/uploads/Michigan_Wolverines_logo-480x480.png",
      },
      {
        id: 12,
        name: "Illinois",
        value: 195,
        img_url:
          "https://loodibee.com/wp-content/uploads/Illinois_Fighting_Illini_logo-480x480.png",
      },
      {
        id: 13,
        name: "Louisville",
        value: 194,
        img_url:
          "https://loodibee.com/wp-content/uploads/Louisville_Cardinals_logo-480x480.png",
      },
      {
        id: 14,
        name: "Iowa",
        value: 192,
        img_url:
          "https://loodibee.com/wp-content/uploads/Iowa_Hawkeyes_logo-480x480.png",
      },
      {
        id: 15,
        name: "Purdue",
        value: 190,
        img_url:
          "https://loodibee.com/wp-content/uploads/Purdue_Boilermakers_logo-480x480.png",
      },
      {
        id: 16,
        name: "Syracuse",
        value: 179,
        img_url:
          "https://loodibee.com/wp-content/uploads/Syracuse_Orange_logo-480x480.png",
      },
      {
        id: 17,
        name: "Maryland",
        value: 177,
        img_url:
          "https://loodibee.com/wp-content/uploads/Maryland_Terrapins_logo-480x480.png",
      },
      {
        id: 18,
        name: "Virginia",
        value: 172,
        img_url:
          "https://loodibee.com/wp-content/uploads/Virginia_Cavaliers_logo-480x480.png",
      },
      {
        id: 19,
        name: "Wisconsin",
        value: 167,
        img_url:
          "https://loodibee.com/wp-content/uploads/Wisconsin_Badgers_logo-480x480.png",
      },
      {
        id: 20,
        name: "Arizona",
        value: 167,
        img_url:
          "https://loodibee.com/wp-content/uploads/Arizona_Wildcats_logo-480x480.png",
      },
      {
        id: 21,
        name: "Notre Dame",
        value: 163,
        img_url:
          "https://loodibee.com/wp-content/uploads/Notre_Dame_Fighting_Irish_logo-480x480.png",
      },
      {
        id: 22,
        name: "Connecticut",
        value: 162,
        img_url:
          "https://loodibee.com/wp-content/uploads/Connecticut_Huskies_logo-480x480.png",
      },
      {
        id: 23,
        name: "Oklahoma",
        value: 160,
        img_url:
          "https://loodibee.com/wp-content/uploads/Oklahoma_Sooners_logo-480x480.png",
      },
    ],
  },
};

let nbaseasonblocks = {
  date: "3/22/2024",
  problem: {
    prompt: "Most Regular Season Blocks in Single Season since 2010-11",
    sourceText: "Basketball-Reference",
    sourceLink: "https://www.basketball-reference.com/",
    options: [
      {
        id: 1,
        name: "Hassan Whiteside",
        Blocks: 269,
        "Player-additional": "whiteha01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4262.png&w=350&h=254",
      },
      {
        id: 2,
        name: "Serge Ibaka",
        Blocks: 242,
        "Player-additional": "ibakase01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201586.png",
      },
      {
        id: 4,
        name: "Victor Wembanyama",
        Blocks: 238,
        "Player-additional": "wembavi01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/5104157.png&w=350&h=254",
      },
      {
        id: 6,
        name: "Rudy Gobert",
        Blocks: 214,
        "Player-additional": "goberru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203497.png",
      },
      {
        id: 7,
        name: "Roy Hibbert",
        Blocks: 206,
        "Player-additional": "hibbero01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3448.png",
      },
      {
        id: 8,
        name: "DeAndre Jordan",
        Blocks: 203,
        "Player-additional": "jordade01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3442.png",
      },
      {
        id: 9,
        name: "Larry Sanders",
        Blocks: 201,
        "Player-additional": "sandela01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202336.png",
      },
      {
        id: 10,
        name: "Anthony Davis",
        Blocks: 200,
        "Player-additional": "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203076.png",
      },
      {
        id: 11,
        name: "Myles Turner",
        Blocks: 199,
        "Player-additional": "turnemy01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626167.png",
      },
      {
        id: 15,
        name: "Brook Lopez",
        Blocks: 193,
        "Player-additional": "lopezbr01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3448.png",
      },
      {
        id: 16,
        name: "JaVale McGee",
        Blocks: 193,
        "Player-additional": "mcgeeja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3452.png&w=350&h=254",
      },
      {
        id: 18,
        name: "Nic Claxton",
        Blocks: 189,
        "Player-additional": "claxtni01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4278067.png&w=350&h=254",
      },
      {
        id: 21,
        name: "Jaren Jackson Jr.",
        Blocks: 189,
        "Player-additional": "jacksja02",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628991.png",
      },
      {
        id: 23,
        name: "Dwight Howard",
        Blocks: 186,
        "Player-additional": "howardw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2730.png",
      },
      {
        id: 25,
        name: "Tim Duncan",
        Blocks: 183,
        "Player-additional": "duncati01",
        img_url: "https://a.espncdn.com/i/headshots/nba/players/full/215.png",
      },
      {
        id: 28,
        name: "Chet Holmgren",
        Blocks: 181,
        "Player-additional": "holmgch01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4433255.png",
      },
      {
        id: 34,
        name: "Walker Kessler",
        Blocks: 173,
        "Player-additional": "kesslwa01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631117.png",
      },
      {
        id: 36,
        name: "Andrew Bogut",
        Blocks: 168,
        "Player-additional": "bogutan01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2747.png",
      },
      {
        id: 39,
        name: "Mitchell Robinson",
        Blocks: 161,
        "Player-additional": "robinmi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629011.png",
      },
      {
        id: 46,
        name: "Andre Drummond",
        Blocks: 153,
        "Player-additional": "drumman01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203083.png",
      },
      {
        id: 47,
        name: "Al Jefferson",
        Blocks: 153,
        "Player-additional": "jeffeal01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2389.png",
      },
      {
        id: 48,
        name: "Giannis Antetokounmpo",
        Blocks: 151,
        "Player-additional": "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
      {
        id: 51,
        name: "Amar'e Stoudemire",
        Blocks: 150,
        "Player-additional": "stoudam01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2405.png",
      },
    ],
  },
};

let ncaaboards = {
  date: "4/4/2024",
  problem: {
    prompt: "Most Total Rebounds in an individual NCAA Tournament since 2005",
    sourceText: "NCAA",
    sourceLink: "https://www.ncaa.com/",
    options: [
      {
        id: 1,
        name: "Armando Bacot",
        Rebounds: 99,
        "Player-additional": "armando-bacot-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4431674.png&w=350&h=254",
      },
      {
        id: 2,
        name: "Thomas Robinson",
        Rebounds: 75,
        "Player-additional": "thomas-robinson-2",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6618.png",
      },
      {
        id: 3,
        name: "Anthony Davis",
        Rebounds: 74,
        "Player-additional": "anthony-davis-5",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203076.png",
      },
      {
        id: 4,
        name: "Kennedy Meeks",
        Rebounds: 69,
        "Player-additional": "kennedy-meeks-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3074764.png",
      },
      {
        id: 5,
        name: "Al Horford",
        Rebounds: 68,
        "Player-additional": "al-horford-1",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201143.png",
      },
      {
        id: 6,
        name: "Jordan Bell",
        Rebounds: 66,
        "Player-additional": "jordan-bell-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628395.png",
      },
      {
        id: 7,
        name: "Zach Edey",
        Rebounds: 65,
        "Player-additional": "zach-edey-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4600663.png&w=350&h=254",
      },
      {
        id: 8,
        name: "Goran Suton",
        Rebounds: 64,
        "Player-additional": "goran-suton-1",
        img_url:
          "https://www.proballers.com/media/cache/resize_300/ul/player/backup/19860-1-5d7ea7e521d52.jpg",
      },
      {
        id: 9,
        name: "Mitch McGary",
        Rebounds: 64,
        "Player-additional": "mitch-mcgary-1",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203956.png",
      },
      {
        id: 10,
        name: "Blake Griffin",
        Rebounds: 60,
        "Player-additional": "blake-griffin-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3989.png",
      },
      {
        id: 11,
        name: "Brian Zoubek",
        Rebounds: 60,
        "Player-additional": "brian-zoubek-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4297.png",
      },
      {
        id: 12,
        name: "Norchad Omier",
        Rebounds: 60,
        "Player-additional": "norchad-omier-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4702134.png&w=350&h=254",
      },
      {
        id: 13,
        name: "Adama Sanogo",
        Rebounds: 59,
        "Player-additional": "adama-sanogo-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4702023.png&w=350&h=254",
      },
      {
        id: 14,
        name: "Alex Oriakhi",
        Rebounds: 59,
        "Player-additional": "alex-oriakhi-1",
        img_url:
          "https://2.bp.blogspot.com/-R1wNWjFIybI/Tz7ARYMsaVI/AAAAAAAAA6o/T8Dv4OGvGOk/s1600/alex-oriakhi.jpg",
      },
      {
        id: 15,
        name: "Julius Randle",
        Rebounds: 59,
        "Player-additional": "julius-randle-1",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203944.png",
      },
      {
        id: 16,
        name: "Jalen Wilson",
        Rebounds: 58,
        "Player-additional": "jalen-wilson-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4431714.png&w=350&h=254",
      },
      {
        id: 17,
        name: "Joakim Noah",
        Rebounds: 58,
        "Player-additional": "joakim-noah-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3224.png",
      },
      {
        id: 18,
        name: "Brice Johnson",
        Rebounds: 56,
        "Player-additional": "brice-johnson-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627744.png",
      },
      {
        id: 19,
        name: "Frank Kaminsky",
        Rebounds: 56,
        "Player-additional": "frank-kaminsky-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626163.png",
      },
      {
        id: 20,
        name: "Justise Winslow",
        Rebounds: 56,
        "Player-additional": "justise-winslow-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3135047.png&w=350&h=254",
      },
      {
        id: 21,
        name: "Tyler Roberson",
        Rebounds: 56,
        "Player-additional": "tyler-roberson-1",
        img_url:
          "https://images.sidearmdev.com/crop?url=https%3A%2F%2Fd3d4f9a2nwdz4m.cloudfront.net%2Fimages%2F2016%2F8%2F29%2FRobersonTyler16.jpg&width=180&height=270",
      },
      {
        id: 22,
        name: "DeJuan Blair",
        Rebounds: 55,
        "Player-additional": "dejuan-blair-1",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201971.png",
      },
      {
        id: 23,
        name: "Greg Oden Jr",
        Rebounds: 55,
        "Player-additional": "greg-oden-1",
        img_url:
          "https://butlersports.com/images/2022/10/6/20220930_Greg_Oden_004.jpg?width=300",
      },
    ],
  },
};

let ncaatournmaent = {
  date: "4/4/2024",
  problem: {
    prompt: "Most Total Points in an indivdual NCAA Tournament since 2010-11 ",
    sourceText: "NCAA",
    sourceLink: "https://www.ncaa.com/",
    options: [
      {
        id: 1,
        name: "Kemba Walker",
        PTS: 141,
        "Player-additional": "kemba-walker-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6479.png",
      },
      {
        id: 2,
        name: "Carsen Edwards",
        PTS: 139,
        "Player-additional": "carsen-edwards-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629035.png",
      },
      {
        id: 3,
        name: "Johnny Juzang",
        PTS: 137,
        "Player-additional": "johnny-juzang-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4432817.png&w=350&h=254",
      },
      {
        id: 4,
        name: "Russ Smith",
        PTS: 134,
        "Player-additional": "russ-smith-1",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203893.png",
      },
      {
        id: 5,
        name: "Frank Kaminsky",
        PTS: 132,
        "Player-additional": "frank-kaminsky-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626163.png",
      },
      {
        id: 6,
        name: "Shabazz Napier",
        PTS: 127,
        "Player-additional": "shabazz-napier-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2530780.png",
      },
      {
        id: 7,
        name: "Buddy Hield",
        PTS: 126,
        "Player-additional": "buddy-hield-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627741.png",
      },
      {
        id: 8,
        name: "Drew Timme",
        PTS: 122,
        "Player-additional": "drew-timme-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1631166.png",
      },
      {
        id: 9,
        name: "Shelvin Mack",
        PTS: 122,
        "Player-additional": "shelvin-mack-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6454.png",
      },
      {
        id: 10,
        name: "Zach Edey",
        PTS: 120,
        "Player-additional": "zach-edey-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4600663.png&w=350&h=254",
      },
      {
        id: 11,
        name: "Tyler Dorsey",
        PTS: 119,
        "Player-additional": "tyler-dorsey-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628416.png",
      },
      {
        id: 12,
        name: "Adama Sanogo",
        PTS: 118,
        "Player-additional": "adama-sanogo-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1641766.png",
      },
      {
        id: 13,
        name: "Sindarius Thornwell",
        PTS: 118,
        "Player-additional": "sindarius-thornwell-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628414.png",
      },
      {
        id: 14,
        name: "Justin Jackson",
        PTS: 117,
        "Player-additional": "justin-jackson-4",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628382.png",
      },
      {
        id: 15,
        name: "Sam Dekker",
        PTS: 115,
        "Player-additional": "sam-dekker-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626155.png",
      },
      {
        id: 16,
        name: "Brice Johnson",
        PTS: 114,
        "Player-additional": "brice-johnson-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627744.png",
      },
      {
        id: 17,
        name: "Brady Manek",
        PTS: 113,
        "Player-additional": "brady-manek-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4277902.png",
      },
      {
        id: 18,
        name: "Caleb Love",
        PTS: 113,
        "Player-additional": "caleb-love-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4433144.png",
      },
      {
        id: 19,
        name: "Jarrett Culver",
        PTS: 111,
        "Player-additional": "jarrett-culver-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629633.png",
      },
      {
        id: 20,
        name: "Malik Newman",
        PTS: 108,
        "Player-additional": "malik-newman-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629005.png",
      },
      {
        id: 21,
        name: "Jamie Skeen",
        PTS: 105,
        "Player-additional": "jamie-skeen-1",
        img_url:
          "https://vcuathletics.com/images/2020/4/16//jamie_skeen_mbkb_2010_11.jpg?width=300",
      },
      {
        id: 22,
        name: "Dalton Knecht",
        PTS: 104,
        "Player-additional": "dalton-knecht-1",
        img_url:
          "https://a.espncdn.com/i/headshots/mens-college-basketball/players/full/4897332.png",
      },
      {
        id: 23,
        name: "Zion Williamson",
        PTS: 104,
        "Player-additional": "zion-williamson-1",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629627.png",
      },
    ],
  },
};

let finalfourpoint = {
  date: "4/4/2024",
  problem: {
    prompt: "Most Total Final Four Points since 2010-2011 Season",
    sourceText: "NCAA",
    sourceLink: "https://www.ncaa.com/",
    options: [
      {
        id: 1,
        name: "Justin Jackson",
        PTS: 63,
        "Player-additional": "justin-jackson-4",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/3130069.png",
      },
      {
        id: 2,
        name: "Joel Berry II",
        PTS: 61,
        "Player-additional": "joel-berry-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/3928719.png",
      },
      {
        id: 3,
        name: "Kennedy Meeks",
        PTS: 51,
        "Player-additional": "kennedy-meeks-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/3936259.png",
      },
      {
        id: 4,
        name: "Frank Kaminsky",
        PTS: 49,
        "Player-additional": "frank-kaminsky-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/2583638.png",
      },
      {
        id: 5,
        name: "Donte DiVincenzo",
        PTS: 46,
        "Player-additional": "donte-divincenzo-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/3130514.png",
      },
      {
        id: 6,
        name: "Doron Lamb",
        PTS: 45,
        "Player-additional": "doron-lamb-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/5133.png",
      },
      {
        id: 7,
        name: "Sam Dekker",
        PTS: 43,
        "Player-additional": "sam-dekker-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/66294.png",
      },
      {
        id: 8,
        name: "Luke Hancock",
        PTS: 42,
        "Player-additional": "luke-hancock-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/5131.png",
      },
      {
        id: 9,
        name: "Mikal Bridges",
        PTS: 42,
        "Player-additional": "mikal-bridges-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/3136194.png",
      },
      {
        id: 10,
        name: "Phil Booth",
        PTS: 42,
        "Player-additional": "phil-booth-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/3936254.png",
      },
      {
        id: 11,
        name: "Shabazz Napier",
        PTS: 42,
        "Player-additional": "shabazz-napier-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/5137.png",
      },
      {
        id: 12,
        name: "Caleb Love",
        PTS: 41,
        "Player-additional": "caleb-love-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/4567350.png",
      },
      {
        id: 13,
        name: "De'Andre Hunter",
        PTS: 41,
        "Player-additional": "deandre-hunter-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/3136778.png",
      },
      {
        id: 14,
        name: "David McCormack",
        PTS: 40,
        "Player-additional": "david-mccormack-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/4277840.png",
      },
      {
        id: 15,
        name: "Moritz Wagner",
        PTS: 40,
        "Player-additional": "moritz-wagner-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/4066261.png",
      },
      {
        id: 16,
        name: "Jalen Brunson",
        PTS: 39,
        "Player-additional": "jalen-brunson-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/4066423.png",
      },
      {
        id: 17,
        name: "Jared Butler",
        PTS: 39,
        "Player-additional": "jared-butler-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/4432801.png",
      },
      {
        id: 18,
        name: "Kyle Guy",
        PTS: 39,
        "Player-additional": "kyle-guy-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/4066266.png",
      },
      {
        id: 19,
        name: "Russ Smith",
        PTS: 39,
        "Player-additional": "russ-smith-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/51172.png",
      },
      {
        id: 20,
        name: "Adama Sanogo",
        PTS: 38,
        "Player-additional": "adama-sanogo-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/4574317.png",
      },
      {
        id: 21,
        name: "Jalen Suggs",
        PTS: 38,
        "Player-additional": "jalen-suggs-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/4432802.png",
      },
      {
        id: 22,
        name: "Nigel Williams-Goss",
        PTS: 38,
        "Player-additional": "nigel-williams-goss-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/51901.png",
      },
      {
        id: 23,
        name: "Drew Timme",
        PTS: 37,
        "Player-additional": "drew-timme-1",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/ncaa/players/full/4594349.png",
      },
    ],
  },
};

let FoulsCommited = {
  date: "4/4/2024",
  problem: {
    prompt: "Most personal fouls in a single NBA season since 2000",
    sourceText: "NBA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Shawn Kemp",
        value: 371,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/431.png",
      },
      {
        id: 2,
        name: "Kurt Thomas",
        value: 344,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/846.png",
      },
      {
        id: 3,
        name: "DeMarcus Cousins",
        value: 332,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202326.png",
      },
      {
        id: 4,
        name: "Christian Laettner",
        value: 326,
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHAUIVIRem8njhuNqpwP_DgsGS7pyj6rXWhaki3wDkHQ&s",
      },
      {
        id: 5,
        name: "Jason Collins",
        value: 322,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/987.png",
      },
      {
        id: 6,
        name: "Aaron Williams",
        value: 319,
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8kK6Yhfj5yn8QKqB6w9D5DZ33dr-OGu5mXFttlrScaQ&s",
      },
      {
        id: 7,
        name: "Antonio McDyess",
        value: 316,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/686.png",
      },
      {
        id: 8,
        name: "Jason Thompson",
        value: 314,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201574.png",
      },
      {
        id: 9,
        name: "Zydrunas Ilgauskas",
        value: 313,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/980.png",
      },
      {
        id: 10,
        name: "Mikki Moore",
        value: 310,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1630.png",
      },
      {
        id: 11,
        name: "Alonzo Mourning",
        value: 308,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/297.png",
      },
      {
        id: 12,
        name: "Andris Biedriņš",
        value: 304,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2427.png",
      },
      {
        id: 13,
        name: "Michael Olowokandi",
        value: 304,
        img_url: "https://a.espncdn.com/i/headshots/nba/players/full/234.png",
      },
      {
        id: 14,
        name: "Joe Smith",
        value: 302,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/788.png",
      },
      {
        id: 15,
        name: "Al Harrington",
        value: 301,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/308.png",
      },
      {
        id: 16,
        name: "Amir Johnson",
        value: 301,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2769.png",
      },
      {
        id: 17,
        name: "Brian Grant",
        value: 300,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/258.png",
      },
      {
        id: 18,
        name: "Theo Ratliff",
        value: 300,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/689.png",
      },
      {
        id: 19,
        name: "Juwan Howard",
        value: 299,
        img_url: "https://a.espncdn.com/i/headshots/nba/players/full/351.png",
      },
      {
        id: 20,
        name: "Tyson Chandler",
        value: 298,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/984.png",
      },
      {
        id: 21,
        name: "Ervin Johnson",
        value: 298,
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlGULvfXNbA0Z9j8EQYPJPwL-RxgwiRA-pacJr8LkLPw&s",
      },
      {
        id: 22,
        name: "Yao Ming",
        value: 298,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2397.png",
      },
      {
        id: 23,
        name: "Nenad Krstić",
        value: 297,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2420.png",
      },
    ],
  },
};

let Rondo = {
  date: "3/30/2024",
  problem: {
    prompt: "Most assisted by Rajon Rondo",
    sourceText: "NBA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Kevin Garnett",
        value: 1072,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/708.png",
      },
      {
        id: 2,
        name: "Ray Allen",
        value: 1001,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/951.png",
      },
      {
        id: 3,
        name: "Paul Pierce",
        value: 891,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1718.png",
      },
      {
        id: 4,
        name: "Anthony Davis",
        value: 318,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203076.png",
      },
      {
        id: 5,
        name: "DeMarcus Cousins",
        value: 297,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202326.png",
      },
      {
        id: 6,
        name: "Kendrick Peidins",
        value: 254,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2570.png",
      },
      {
        id: 7,
        name: "Brandon Bass",
        value: 245,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101138.png",
      },
      {
        id: 8,
        name: "Glen Davis",
        value: 199,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201175.png",
      },
      {
        id: 9,
        name: "Avery Bradley",
        value: 161,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202340.png",
      },
      {
        id: 10,
        name: "Jeff Green",
        value: 160,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201145.png",
      },
      {
        id: 11,
        name: "Nikola Mirotić",
        value: 130,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202703.png",
      },
      {
        id: 12,
        name: "Rudy Gay",
        value: 130,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/200752.png",
      },
      {
        id: 13,
        name: "Kentavious Caldwell-Pope",
        value: 116,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203484.png",
      },
      {
        id: 14,
        name: "Jared Sullinger",
        value: 115,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203096.png",
      },
      {
        id: 15,
        name: "Kyle Kuzma",
        value: 113,
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628398.png",
      },
      {
        id: 16,
        name: "E'Twaun Moore",
        value: 112,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202734.png",
      },
      {
        id: 17,
        name: "Marco Belinelli",
        value: 110,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201158.png",
      },
      {
        id: 18,
        name: "Jrue Holiday",
        value: 110,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201950.png",
      },
      {
        id: 19,
        name: "LeBron James",
        value: 110,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2544.png",
      },
      {
        id: 20,
        name: "Jimmy Butler",
        value: 102,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202710.png",
      },
      {
        id: 21,
        name: "Tony Allen",
        value: 76,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2754.png",
      },
      {
        id: 22,
        name: "Eddie House",
        value: 76,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/348.png",
      },
      {
        id: 23,
        name: "Diid Nowitzki",
        value: 74,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1717.png",
      },
    ],
  },
};

let Minutes = {
  date: "3/30/2024",
  problem: {
    prompt: "Minutes Played in a Single Season Since 2000",
    sourceText: "NBA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Allen Iverson",
        value: 3485,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/947.png",
      },
      {
        id: 2,
        name: "Michael Finley",
        value: 3443,
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQshAh9QIzi5h3-1n6f3x4MZ1NyHN-RYE5JVZT44rEJwg&s",
      },
      {
        id: 4,
        name: "Antoine Walker",
        value: 3406,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/952.png",
      },
      {
        id: 5,
        name: "Kobe Bryant",
        value: 3401,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/977.png",
      },
      {
        id: 7,
        name: "Antawn Jamison",
        value: 3394,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/385.png",
      },
      {
        id: 8,
        name: "LeBron James",
        value: 3388,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1966.png",
      },
      {
        id: 9,
        name: "Gilbert Arenas",
        value: 3384,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2240.png",
      },
      {
        id: 10,
        name: "Shawn Marion",
        value: 3373,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/510.png",
      },
      {
        id: 12,
        name: "Jalen Rose",
        value: 3351,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/147.png",
      },
      {
        id: 13,
        name: "Joe Johnson",
        value: 3343,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2207.png",
      },
      {
        id: 16,
        name: "Tim Duncan",
        value: 3329,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1495.png",
      },
      {
        id: 17,
        name: "Latrell Sprewell",
        value: 3326,
        img_url:
          "https://hoopshype.com/wp-content/uploads/sites/92/2021/11/i_d3_a6_92_latrell-sprewell.png?w=190&h=190&crop=1",
      },
      {
        id: 18,
        name: "Kevin Garnett",
        value: 3321,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/708.png",
      },
      {
        id: 19,
        name: "Jamal Mashburn",
        value: 3321,
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqpsMqdZx1m1BeFaPwo0_nGoDkhATQRCs8JdmvzpeNmg&sr",
      },
      {
        id: 20,
        name: "Baron Davis",
        value: 3318,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1884.png",
      },
      {
        id: 21,
        name: "Steve Francis",
        value: 3318,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1883.png",
      },
      {
        id: 22,
        name: "Paul Pierce",
        value: 3302,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1718.png",
      },
      {
        id: 23,
        name: "Gary Payton",
        value: 3301,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/56.png",
      },
      {
        id: 25,
        name: "Stephon Marbury",
        value: 3281,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/950.png",
      },
      {
        id: 28,
        name: "Andre Iguodala",
        value: 3269,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2386.png&w=350&h=254",
      },
      {
        id: 29,
        name: "Peja Stojaković",
        value: 3264,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/978.png",
      },
      {
        id: 32,
        name: "Anthony Mason",
        value: 3254,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/193.png",
      },
      {
        id: 35,
        name: "Shareef Abdur-Rahim",
        value: 3241,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/949.png",
      },
    ],
  },
};

let FTA = {
  date: "3/30/2024",
  problem: {
    prompt: "Most Free Throw Attempts (Single Game Since 2000)",
    sourceText: "NBA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Dwight Howard",
        FTA: 39,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2384.png&w=350&h=254",
      },
      {
        id: 3,
        name: "Andre Drummond",
        FTA: 36,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203083.png",
      },
      {
        id: 4,
        name: "DeAndre Jordan",
        FTA: 34,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3442.png&w=350&h=254",
      },
      {
        id: 6,
        name: "Giannis Antetokounmpo",
        FTA: 32,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
      {
        id: 7,
        name: "Ben Simmons",
        FTA: 29,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3907387.png&w=350&h=254",
      },
      {
        id: 8,
        name: "Jerami Grant",
        FTA: 28,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203924.png",
      },
      {
        id: 9,
        name: "LeBron James",
        FTA: 28,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1966.png&w=350&h=254",
      },
      {
        id: 11,
        name: "Shaquille O'Neal",
        FTA: 28,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/614.png",
      },
      {
        id: 13,
        name: "Gilbert Arenas",
        FTA: 27,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/974.png",
      },
      {
        id: 14,
        name: "Kobe Bryant",
        FTA: 27,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/110.png&w=350&h=254",
      },
      {
        id: 16,
        name: "Vince Carter",
        FTA: 27,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1713.png",
      },
      {
        id: 17,
        name: "Anthony Davis",
        FTA: 27,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6583.png&w=350&h=254",
      },
      {
        id: 18,
        name: "Joel Embiid",
        FTA: 27,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203954.png",
      },
      {
        id: 19,
        name: "James Harden",
        FTA: 27,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3992.png&w=350&h=254",
      },
      {
        id: 20,
        name: "Allen Iverson",
        FTA: 27,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/947.png",
      },
      {
        id: 21,
        name: "Devin Booker",
        FTA: 26,
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626164.png",
      },
      {
        id: 25,
        name: "Kevin Durant",
        FTA: 26,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3202.png&w=350&h=254",
      },
      {
        id: 27,
        name: "Kevin Martin",
        FTA: 26,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2755.png",
      },
      {
        id: 28,
        name: "Tracy McGrady",
        FTA: 26,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1503.png",
      },
      {
        id: 29,
        name: "Josh Smith",
        FTA: 26,
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR30Zvq3scfgdITnhBJSUZWJUnoVbMwtaZJj0zQJnTbVQ&s",
      },
      {
        id: 33,
        name: "Jimmy Butler",
        FTA: 25,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6430.png&w=350&h=254",
      },
      {
        id: 34,
        name: "DeMar DeRozan",
        FTA: 25,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3978.png&w=350&h=254",
      },
      {
        id: 40,
        name: "Donovan Mitchell",
        FTA: 25,
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628378.png",
      },
    ],
  },
};
let CollegeNBA = {
  date: "3/30/2024",
  problem: {
    prompt: "Colleges with Most Active NBA Players",
    sourceText: "NBA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Kentucky",
        active_players: 28,
        avg_2023_cap_hit: "$13,948,000",
        earnings: "$1,474,319,225",
        img_url:
          "https://loodibee.com/wp-content/uploads/Kentucky_Wildcats_logo-480x480.png",
      },
      {
        id: 2,
        name: "Duke",
        active_players: 25,
        avg_2023_cap_hit: "$11,942,271",
        earnings: "$1,270,631,453",
        img_url:
          "https://loodibee.com/wp-content/uploads/Duke_Blue_Devils_logo-480x480.png",
      },
      {
        id: 3,
        name: "UCLA",
        active_players: 16,
        avg_2023_cap_hit: "$9,352,223",
        earnings: "$1,324,667,931",
        img_url:
          "https://loodibee.com/wp-content/uploads/UCLA_Bruins-480x480.png",
      },
      {
        id: 4,
        name: "Michigan",
        active_players: 13,
        avg_2023_cap_hit: "$8,121,325",
        earnings: "$368,772,934",
        img_url:
          "https://loodibee.com/wp-content/uploads/Michigan_Wolverines_logo-480x480.png",
      },
      {
        id: 5,
        name: "Kansas",
        active_players: 12,
        avg_2023_cap_hit: "$8,562,016",
        earnings: "$714,916,352",
        img_url:
          "https://loodibee.com/wp-content/uploads/Kansas_Jayhawks_logo-480x480.png",
      },
      {
        id: 6,
        name: "Arizona",
        active_players: 11,
        avg_2023_cap_hit: "$9,103,650",
        earnings: "$388,699,818",
        img_url:
          "https://loodibee.com/wp-content/uploads/Arizona_Wildcats_logo-480x480.png",
      },
      {
        id: 7,
        name: "USC",
        active_players: 10,
        avg_2023_cap_hit: "$7,496,417",
        earnings: "$625,587,317",
        img_url:
          "https://loodibee.com/wp-content/uploads/USC_Trojans_logo-480x480.png",
      },
      {
        id: 8,
        name: "Villanova",
        active_players: 10,
        avg_2023_cap_hit: "$8,312,649",
        earnings: "$492,277,011",
        img_url:
          "https://loodibee.com/wp-content/uploads/Villanova-Wildcats-logo-480x480.png",
      },
      {
        id: 9,
        name: "Gonzaga",
        active_players: 10,
        avg_2023_cap_hit: "$10,465,897",
        earnings: "$355,243,952",
        img_url:
          "https://loodibee.com/wp-content/uploads/Gonzaga-Bulldogs-logo-480x480.png",
      },
      {
        id: 10,
        name: "Texas",
        active_players: 9,
        avg_2023_cap_hit: "$11,974,582",
        earnings: "$851,314,656",
        img_url:
          "https://loodibee.com/wp-content/uploads/Texas_Longhorns_logo-480x480.png",
      },
      {
        id: 11,
        name: "North Carolina",
        active_players: 9,
        avg_2023_cap_hit: "$7,756,664",
        earnings: "$353,162,323",
        img_url:
          "https://loodibee.com/wp-content/uploads/North_Carolina_Tar_Heels_logo-480x480.png",
      },
      {
        id: 12,
        name: "Ohio State",
        active_players: 8,
        avg_2023_cap_hit: "$7,229,570",
        earnings: "$466,010,456",
        img_url:
          "https://loodibee.com/wp-content/uploads/Ohio_State_Buckeyes_logo-480x480.png",
      },
      {
        id: 13,
        name: "Florida State",
        active_players: 8,
        avg_2023_cap_hit: "$6,782,879",
        earnings: "$218,946,264",
        img_url:
          "https://loodibee.com/wp-content/uploads/Florida_State_Seminoles_logo-480x480.png",
      },
      {
        id: 14,
        name: "Virginia",
        active_players: 8,
        avg_2023_cap_hit: "$6,321,002",
        earnings: "$190,391,760",
        img_url:
          "https://loodibee.com/wp-content/uploads/Virginia_Cavaliers_logo-480x480.png",
      },
      {
        id: 15,
        name: "Tennessee",
        active_players: 7,
        avg_2023_cap_hit: "$8,341,837",
        earnings: "$353,246,016",
        img_url:
          "https://loodibee.com/wp-content/uploads/Tennessee_Volunteers_logo-480x480.png",
      },
      {
        id: 16,
        name: "Washington",
        active_players: 7,
        avg_2023_cap_hit: "$8,145,413",
        earnings: "$280,418,610",
        img_url:
          "https://loodibee.com/wp-content/uploads/Washington_Huskies_logo-480x480.png",
      },
      {
        id: 17,
        name: "LSU",
        active_players: 7,
        avg_2023_cap_hit: "$8,664,363",
        earnings: "$250,888,261",
        img_url:
          "https://loodibee.com/wp-content/uploads/LSU_Tigers-480x480.png",
      },
      {
        id: 18,
        name: "Oregon",
        active_players: 7,
        avg_2023_cap_hit: "$7,065,579",
        earnings: "$154,272,255",
        img_url:
          "https://loodibee.com/wp-content/uploads/Oregon_Ducks_logo-480x480.png",
      },
      {
        id: 19,
        name: "Florida",
        active_players: 6,
        avg_2023_cap_hit: "$12,310,833",
        earnings: "$593,231,027",
        img_url:
          "https://loodibee.com/wp-content/uploads/Florida_Gators_logo-480x480.png",
      },
      {
        id: 20,
        name: "Michigan State",
        active_players: 6,
        avg_2023_cap_hit: "$12,332,579",
        earnings: "$402,700,922",
        img_url:
          "https://loodibee.com/wp-content/uploads/Michigan_State_Spartans_logo-480x480.png",
      },
      {
        id: 21,
        name: "Indiana",
        active_players: 6,
        avg_2023_cap_hit: "$5,200,308",
        earnings: "$356,456,134",
        img_url:
          "https://loodibee.com/wp-content/uploads/Indiana_Hoosiers_logo-480x480.png",
      },
      {
        id: 22,
        name: "Memphis",
        active_players: 6,
        avg_2023_cap_hit: "$4,382,462",
        earnings: "$230,053,727",
        img_url:
          "https://loodibee.com/wp-content/uploads/Memphis_Tigers_logo-480x480.png",
      },
      {
        id: 23,
        name: "Wake Forest",
        active_players: 5,
        avg_2023_cap_hit: "$12,023,525",
        earnings: "$565,950,062",
        img_url:
          "https://loodibee.com/wp-content/uploads/Wake_Forest_Demon_Deacons_logo-480x480.png",
      },
    ],
  },
};

let KobePoints = {
  date: "3/30/2024",
  problem: {
    prompt: "Most Points Scored Against Kobe Bryant",
    sourceText: "NBA/BAA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Diid Nowitzki",
        points: 1116,
        Player_additional: "nowitdi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1717.png",
      },
      {
        id: 2,
        name: "Kevin Garnett",
        points: 996,
        Player_additional: "garneke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/708.png",
      },
      {
        id: 3,
        name: "Tim Duncan",
        points: 964,
        Player_additional: "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1495.png",
      },
      {
        id: 4,
        name: "Steve Nash",
        points: 754,
        Player_additional: "nashst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/959.png",
      },
      {
        id: 5,
        name: "Shawn Marion",
        points: 736,
        Player_additional: "mariosh01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/510.png",
      },
      {
        id: 6,
        name: "Allen Iverson",
        points: 733,
        Player_additional: "iversal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/947.png",
      },
      {
        id: 7,
        name: "Ray Allen",
        points: 712,
        Player_additional: "allenra02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/951.png",
      },
      {
        id: 8,
        name: "Kevin Durant",
        points: 683,
        Player_additional: "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201142.png",
      },
      {
        id: 9,
        name: "Carmelo Anthony",
        points: 681,
        Player_additional: "anthoca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2546.png",
      },
      {
        id: 10,
        name: "Mike Bibby",
        points: 661,
        Player_additional: "bibbymi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1710.png",
      },
      {
        id: 11,
        name: "Chris Paul",
        points: 657,
        Player_additional: "paulch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101108.png",
      },
      {
        id: 12,
        name: "Tony Parker",
        points: 657,
        Player_additional: "paideto01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2225.png",
      },
      {
        id: 13,
        name: "Michael Finley",
        points: 647,
        Player_additional: "finlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/922.png",
      },
      {
        id: 14,
        name: "Rasheed Wallace",
        points: 641,
        Player_additional: "wallara01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/739.png",
      },
      {
        id: 15,
        name: "Jason Terry",
        points: 629,
        Player_additional: "terryja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1891.png",
      },
      {
        id: 16,
        name: "LeBron James",
        points: 620,
        Player_additional: "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2544.png",
      },
      {
        id: 17,
        name: "Shareef Abdur-Rahim",
        points: 616,
        Player_additional: "abdursh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/949.png",
      },
      {
        id: 18,
        name: "Karl Malone",
        points: 614,
        Player_additional: "malonka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/252.png",
      },
      {
        id: 19,
        name: "Corey Maggette",
        points: 605,
        Player_additional: "maggeco01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/497.png",
      },
      {
        id: 20,
        name: "Stephon Marbury",
        points: 603,
        Player_additional: "marbust01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/950.png",
      },
      {
        id: 21,
        name: "Andre Miller",
        points: 601,
        Player_additional: "millean01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1889.png",
      },
      {
        id: 22,
        name: "Gary Payton",
        points: 592,
        Player_additional: "paytoga01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/893.png",
      },
      {
        id: 23,
        name: "Paul Pierce",
        points: 584,
        Player_additional: "piercpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1718.png",
      },
    ],
  },
};

let benchpoints = {
  date: "3/28/2024",
  problem: {
    prompt: "Most bench points in a single game this season",
    sourceText: "NBA/BAA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "P.J. Washington",
        PTS: 43,
        Player_additional: "washipj01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629023.png",
      },
      {
        id: 2,
        name: "Bogdan Bogdanović",
        PTS: 40,
        Player_additional: "bogdabo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203992.png",
      },
      {
        id: 3,
        name: "Malik Monk",
        PTS: 39,
        Player_additional: "monkma01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628370.png",
      },
      {
        id: 4,
        name: "Jordan Claidson",
        PTS: 38,
        Player_additional: "claidjo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203903.png",
      },
      {
        id: 7,
        name: "Cam Thomas",
        PTS: 36,
        Player_additional: "thomaca02",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630560.png",
      },
      {
        id: 8,
        name: "Klay Thompson",
        PTS: 35,
        Player_additional: "thompkl01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202691.png",
      },
      {
        id: 9,
        name: "Alec Buids",
        PTS: 34,
        Player_additional: "buidsal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202692.png",
      },
      {
        id: 11,
        name: "Trey Murphy III",
        PTS: 34,
        Player_additional: "murphtr02",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630530.png",
      },
      {
        id: 12,
        name: "Jordan Poole",
        PTS: 34,
        Player_additional: "poolejo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629673.png",
      },
      {
        id: 13,
        name: "Naz Reid",
        PTS: 34,
        Player_additional: "reidna01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629675.png",
      },
      {
        id: 16,
        name: "Georges Niang",
        PTS: 33,
        Player_additional: "niangge01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627777.png",
      },
      {
        id: 18,
        name: "Tim Hardaway Jr.",
        PTS: 32,
        Player_additional: "hardati02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203501.png",
      },
      {
        id: 21,
        name: "Dalano Banton",
        PTS: 31,
        Player_additional: "bantoda01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630625.png",
      },

      {
        id: 23,
        name: "Jonathan Kuminga",
        PTS: 31,
        Player_additional: "kuminjo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630228.png",
      },
      {
        id: 24,
        name: "Caris LeVert",
        PTS: 31,
        Player_additional: "leverca01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627747.png",
      },
      {
        id: 26,
        name: "Bobby Portis",
        PTS: 31,
        Player_additional: "portibo01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3064482.png",
      },
      {
        id: 28,
        name: "Cole Anthony",
        PTS: 30,
        Player_additional: "anthoco01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630175.png",
      },

      {
        id: 30,
        name: "Scoot Henderson",
        PTS: 30,
        Player_additional: "hendesc01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630703.png",
      },
      {
        id: 32,
        name: "Kelly Oubre Jr.",
        PTS: 30,
        Player_additional: "oubreke01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626162.png",
      },
      {
        id: 33,
        name: "Keldon Johnson",
        PTS: 29,
        Player_additional: "johnske04",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629640.png",
      },
      {
        id: 36,
        name: "Nickeil Alexander-Walker",
        PTS: 28,
        Player_additional: "alexani01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629638.png",
      },
    ],
  },
};

let pointersthisseason = {
  date: "3/28/2024",
  problem: {
    prompt: "Most 3-Point FG's This Season",
    sourceText: "NBA/BAA",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Stephen Curry",
        "3P": 318,
        Player_additional: "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201939.png",
      },
      {
        id: 2,
        name: "Luka Dončić",
        "3P": 244,
        Player_additional: "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 3,
        name: "Donte DiVincenzo",
        "3P": 239,
        Player_additional: "divindo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628978.png",
      },
      {
        id: 4,
        name: "Klay Thompson",
        "3P": 235,
        Player_additional: "thompkl01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202691.png",
      },
      {
        id: 5,
        name: "Paul George",
        "3P": 220,
        Player_additional: "georgpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202331.png",
      },
      {
        id: 6,
        name: "Bogdan Bogdanović",
        "3P": 212,
        Player_additional: "bogdabo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203992.png",
      },
      {
        id: 7,
        name: "Jayson Tatum",
        "3P": 212,
        Player_additional: "tatumja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628369.png",
      },
      {
        id: 8,
        name: "Damian Lillard",
        "3P": 207,
        Player_additional: "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203081.png",
      },
      {
        id: 9,
        name: "Malik Beasley",
        "3P": 202,
        Player_additional: "beaslma01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627736.png",
      },
      {
        id: 10,
        name: "Buddy Hield",
        "3P": 200,
        Player_additional: "hieldbu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627741.png",
      },
      {
        id: 11,
        name: "Michael Porter Jr.",
        "3P": 199,
        Player_additional: "portemi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629008.png",
      },
      {
        id: 12,
        name: "D'Angelo Russell",
        "3P": 197,
        Player_additional: "russeda01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626156.png",
      },
      {
        id: 13,
        name: "Fred VanVleet",
        "3P": 197,
        Player_additional: "vanvlfr01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1627832.png",
      },
      {
        id: 14,
        name: "Tim Hardaway Jr.",
        "3P": 196,
        Player_additional: "hardati02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203501.png",
      },
      {
        id: 15,
        name: "Tyrese Maxey",
        "3P": 194,
        Player_additional: "maxeyty01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630178.png",
      },
      {
        id: 16,
        name: "Mikal Bridges",
        "3P": 193,
        Player_additional: "bridgmi01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628969.png",
      },
      {
        id: 17,
        name: "Coby White",
        "3P": 193,
        Player_additional: "whiteco01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629632.png",
      },
      {
        id: 18,
        name: "CJ McCollum",
        "3P": 188,
        Player_additional: "mccolcj01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203468.png",
      },
      {
        id: 19,
        name: "Grayson Allen",
        "3P": 186,
        Player_additional: "allengr01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628960.png",
      },
      {
        id: 20,
        name: "Duncan Robinson",
        "3P": 181,
        Player_additional: "robindu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629130.png",
      },
      {
        id: 21,
        name: "Jalen Brunson",
        "3P": 179,
        Player_additional: "brunsja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628973.png",
      },
      {
        id: 22,
        name: "De'Aaron Fox",
        "3P": 177,
        Player_additional: "foxde01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628368.png",
      },
      {
        id: 23,
        name: "Jalen Green",
        "3P": 177,
        Player_additional: "greenja05",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630224.png",
      },
    ],
  },
};

let nbaTripleDoubleLeaders = {
  date: "03/27/2024",
  problem: {
    prompt: "Youngest Players to Record a Triple-Double in NBA History",
    sourceText: "NBA.com",
    sourceLink: "https://www.nba.com/",
    options: [
      {
        id: 1,
        name: "Josh Giddey",
        Age: "19 years, 84 days",
        Player_additional: "giddejo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630581.png",
      },
      {
        id: 2,
        name: "LaMelo Ball",
        Age: "19 years, 140 days",
        Player_additional: "ballla01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630163.png",
      },
      {
        id: 3,
        name: "Maidelle Fultz",
        Age: "19 years, 317 days",
        Player_additional: "fultzma01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4066636.png&w=350&h=254",
      },
      {
        id: 4,
        name: "Luka Dončić",
        Age: "20 years, 65 days",
        Player_additional: "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 5,
        name: "Victor Wembanyama",
        Age: "17 years, 108 days",
        Player_additional: "wembavi01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/5104157.png&w=350&h=254",
      },
      {
        id: 6,
        name: "Lonzo Ball",
        Age: "20 years, 15 days",
        Player_additional: "balllo01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4066421.png",
      },
      {
        id: 7,
        name: "LeBron James",
        Age: "20 years, 20 days",
        Player_additional: "jamesle01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1966.png",
      },
      {
        id: 8,
        name: "Dennis Smith Jr.",
        Age: "20 years, 34 days",
        Player_additional: "smithde03",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4065697.png",
      },
      {
        id: 9,
        name: "Lamar Odom",
        Age: "20 years, 53 days",
        Player_additional: "odomla01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/617.png",
      },
      {
        id: 10,
        name: "Cade Cunningham",
        Age: "20 years, 74 days",
        Player_additional: "cunnica01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1630595.png",
      },
      {
        id: 11,
        name: "John Wall",
        Age: "20 years, 65 days",
        Player_additional: "walljo01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4237.png",
      },
      {
        id: 12,
        name: "Magic Johnson",
        Age: "20 years, 75 days",
        Player_additional: "johnsma02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/77142.png",
      },
      {
        id: 13,
        name: "Aleksej Pokusevski",
        Age: "20 years, 105 days",
        Player_additional: "pokusal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1630197.png",
      },
      {
        id: 14,
        name: "Russell Westbrook",
        Age: "20 years, 111 days",
        Player_additional: "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 15,
        name: "Shareef Abdur-Rahim",
        Age: "20 years, 227 days",
        Player_additional: "abdursh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/949.png",
      },
      {
        id: 16,
        name: "Alperen Sengun",
        Age: "20 years, 229 days",
        Player_additional: "sengual01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4871144.png&w=350&h=254",
      },
      {
        id: 17,
        name: "Trae Young",
        Age: "20 years, 265 days",
        Player_additional: "youngtr01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4277905.png&w=350&h=254",
      },
      {
        id: 18,
        name: "Tyreke Evans",
        Age: "20 years, 286 days",
        Player_additional: "evansty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201936.png",
      },
      {
        id: 19,
        name: "Ja Morant",
        Age: "20 years, 315 days",
        Player_additional: "moranja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629630.png",
      },
      {
        id: 20,
        name: "Tony Wroten",
        Age: "20 years, 315 days",
        Player_additional: "wroteto01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203100.png",
      },
      {
        id: 21,
        name: "Jrue Holiday",
        Age: "20 years, 347 days",
        Player_additional: "holidjr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201950.png",
      },
      {
        id: 22,
        name: "Antoine Walker",
        Age: "20 years, 354 days",
        Player_additional: "walkean02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/952.png",
      },
      {
        id: 23,
        name: "Chris Webber",
        Age: "20 years, 362 days",
        Player_additional: "webbech01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/185.png",
      },
    ],
  },
};

let nbaPointLeaders = {
  date: "3/22/2024",
  problem: {
    prompt:
      "Highest Single Season Scoring Averages since 2000-01 (excluding duplicate players)",
    sourceText: "Basketball-Reference",
    sourceLink: "https://www.basketball-reference.com/",
    options: [
      {
        id: 1,
        name: "James Harden",
        PTS: 36.1,
        Player_additional: "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201935.png",
      },
      {
        id: 2,
        name: "Kobe Bryant",
        PTS: 35.4,
        Player_additional: "bryanko01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/977.png",
      },
      {
        id: 3,
        name: "Joel Embiid",
        PTS: 35.3,
        Player_additional: "embiijo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203954.png",
      },
      {
        id: 4,
        name: "Luka Dončić",
        PTS: 34.1,
        Player_additional: "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 5,
        name: "Allen Iverson",
        PTS: 33.0,
        Player_additional: "iversal01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/947.png",
      },
      {
        id: 9,
        name: "Damian Lillard",
        PTS: 32.2,
        Player_additional: "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203081.png",
      },
      {
        id: 10,
        name: "Tracy McGrady",
        PTS: 32.1,
        Player_additional: "mcgratr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1503.png",
      },
      {
        id: 11,
        name: "Kevin Durant",
        PTS: 32.0,
        Player_additional: "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201142.png",
      },
      {
        id: 12,
        name: "Stephen Curry",
        PTS: 32.0,
        Player_additional: "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201939.png",
      },
      {
        id: 15,
        name: "Shai Gilgeous-Alexander",
        PTS: 31.4,
        Player_additional: "gilgesh01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629647.png",
      },
      {
        id: 17,
        name: "LeBron James",
        PTS: 31.4,
        Player_additional: "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2544.png",
      },
      {
        id: 18,
        name: "Bradley Beal",
        PTS: 31.3,
        Player_additional: "bealbr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203078.png",
      },
      {
        id: 19,
        name: "Giannis Antetokounmpo",
        PTS: 31.1,
        Player_additional: "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
      {
        id: 29,
        name: "Dwyane Wade",
        PTS: 30.2,
        Player_additional: "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2548.png",
      },
      {
        id: 30,
        name: "Jayson Tatum",
        PTS: 30.1,
        Player_additional: "tatumja01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628369.png",
      },
      {
        id: 37,
        name: "Jerry Stackhouse",
        PTS: 29.8,
        Player_additional: "stackje01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/802.png",
      },
      {
        id: 39,
        name: "Trae Young",
        PTS: 29.6,
        Player_additional: "youngtr01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629027.png",
      },
      {
        id: 42,
        name: "Gilbert Arenas",
        PTS: 29.3,
        Player_additional: "arenagi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2240.png",
      },
      {
        id: 46,
        name: "Carmelo Anthony",
        PTS: 28.9,
        Player_additional: "anthoca01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2546.png",
      },
      {
        id: 47,
        name: "Isaiah Thomas",
        PTS: 28.9,
        Player_additional: "thomais02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202738.png",
      },
      {
        id: 51,
        name: "Shaquille O'Neal",
        PTS: 28.7,
        Player_additional: "onealsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/406.png",
      },
      {
        id: 60,
        name: "Donovan Mitchell",
        PTS: 28.3,
        Player_additional: "mitchdo01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1628378.png",
      },
      {
        id: 66,
        name: "Anthony Davis",
        PTS: 28.1,
        Player_additional: "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203076.png",
      },
    ],
  },
};

let nbaforeignAssistLeaders = {
  date: "3/25/2024",
  problem: {
    prompt: "Most Regular Season Assists since 2000-01 (Born outside of US)",
    sourceText: "Basketball-Reference",
    sourceLink: "https://www.basketball-reference.com/",
    options: [
      {
        id: 1,
        name: "Steve Nash",
        AST: 9444,
        Player_additional: "nashst01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/592.png",
      },
      {
        id: 2,
        name: "Tony Parker",
        AST: 7036,
        Player_additional: "paideto01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2225.png",
      },
      {
        id: 3,
        name: "Ricky Rubio",
        AST: 5160,
        Player_additional: "rubiori01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201937.png",
      },
      {
        id: 4,
        name: "José Calderón",
        AST: 5148,
        Player_additional: "caldejo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101181.png",
      },
      {
        id: 5,
        name: "Nikola Jokić",
        AST: 4570,
        Player_additional: "jokicni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
      },
      {
        id: 6,
        name: "Goran Dragić",
        AST: 4405,
        Player_additional: "dragigo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201609.png",
      },
      {
        id: 7,
        name: "Kyrie Irving",
        AST: 4086,
        Player_additional: "irvinky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202681.png",
      },
      {
        id: 8,
        name: "Manu Ginóbili",
        AST: 4001,
        Player_additional: "ginobma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1938.png",
      },
      {
        id: 9,
        name: "Pau Gasol",
        AST: 3925,
        Player_additional: "gasolpa01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/996.png&w=350&h=254",
      },
      {
        id: 10,
        name: "Giannis Antetokounmpo",
        AST: 3799,
        Player_additional: "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png",
      },
      {
        id: 11,
        name: "Boris Diaw",
        AST: 3684,
        Player_additional: "diawbo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2564.png",
      },
      {
        id: 12,
        name: "Tim Duncan",
        AST: 3646,
        Player_additional: "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1495.png",
      },
      {
        id: 13,
        name: "Dennis Schröder",
        AST: 3632,
        Player_additional: "schrode01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203471.png",
      },
      {
        id: 14,
        name: "Reggie Jackson",
        AST: 3516,
        Player_additional: "jacksre01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202704.png",
      },
      {
        id: 15,
        name: "Al Horford",
        AST: 3487,
        Player_additional: "horfoal01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3213.png&w=350&h=254",
      },
      {
        id: 16,
        name: "Nicolas Batum",
        AST: 3438,
        Player_additional: "batumni01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201587.png",
      },
      {
        id: 17,
        name: "Diid Nowitzki",
        AST: 3401,
        Player_additional: "nowitdi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1717.png",
      },
      {
        id: 18,
        name: "J.J. Barea",
        AST: 3270,
        Player_additional: "bareajo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/200826.png",
      },
      {
        id: 19,
        name: "Luka Dončić",
        AST: 3229,
        Player_additional: "doncilu01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1629029.png",
      },
      {
        id: 20,
        name: "Marc Gasol",
        AST: 2996,
        Player_additional: "gasolma01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3206.png&w=350&h=254",
      },
      {
        id: 21,
        name: "Beno Udrih",
        AST: 2858,
        Player_additional: "udrihbe01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2757.png",
      },
      {
        id: 22,
        name: "Hedo Tüidoğlu",
        AST: 2832,
        Player_additional: "tuidohe01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2045.png",
      },
      {
        id: 23,
        name: "Joe Ingles",
        AST: 2532,
        Player_additional: "inglejo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/204060.png",
      },
    ],
  },
};

let nbaBlockLeaders = {
  date: "3/22/2024",
  problem: {
    prompt: "Most Regular Season Blocks since 2000-01",
    sourceText: "Basketball-Reference",
    sourceLink: "https://www.basketball-reference.com/",
    options: [
      {
        id: 1,
        name: "Tim Duncan",
        BLK: 2523,
        Player_additional: "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1495.png",
      },
      {
        id: 2,
        name: "Dwight Howard",
        BLK: 2228,
        Player_additional: "howardw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2730.png",
      },
      {
        id: 3,
        name: "Pau Gasol",
        BLK: 1941,
        Player_additional: "gasolpa01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/996.png&w=350&h=254",
      },
      {
        id: 4,
        name: "Brook Lopez",
        BLK: 1890,
        Player_additional: "lopezbr01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3448.png&w=350&h=254",
      },
      {
        id: 5,
        name: "Ben Wallace",
        BLK: 1834,
        Player_additional: "wallabe01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1112.png",
      },
      {
        id: 6,
        name: "Marcus Camby",
        BLK: 1781,
        Player_additional: "cambyma01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/948.png",
      },
      {
        id: 7,
        name: "Serge Ibaka",
        BLK: 1759,
        Player_additional: "ibakase01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201586.png",
      },
      {
        id: 8,
        name: "Josh Smith",
        BLK: 1713,
        Player_additional: "smithjo03",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2746.png",
      },
      {
        id: 9,
        name: "Elton Brand",
        BLK: 1696,
        Player_additional: "brandel01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1882.png",
      },
      {
        id: 10,
        name: "Anthony Davis",
        BLK: 1686,
        Player_additional: "davisan02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203076.png",
      },
      {
        id: 11,
        name: "Jermaine O'Neal",
        BLK: 1667,
        Player_additional: "onealje01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/615.png",
      },
      {
        id: 12,
        name: "Rudy Gobert",
        BLK: 1589,
        Player_additional: "goberru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/203497.png",
      },
      {
        id: 13,
        name: "Samuel Dalembert",
        BLK: 1546,
        Player_additional: "dalemsa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2223.png",
      },
      {
        id: 14,
        name: "DeAndre Jordan",
        BLK: 1539,
        Player_additional: "jordade01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3442.png",
      },
      {
        id: 15,
        name: "Andrei Kirilenko",
        BLK: 1461,
        Player_additional: "kirilan01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/434.png",
      },
      {
        id: 16,
        name: "Kevin Garnett",
        BLK: 1384,
        Player_additional: "garneke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/708.png",
      },
      {
        id: 17,
        name: "Tyson Chandler",
        BLK: 1335,
        Player_additional: "chandty01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2199.png",
      },
      {
        id: 18,
        name: "Shaquille O'Neal",
        BLK: 1296,
        Player_additional: "onealsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/406.png",
      },
      {
        id: 19,
        name: "JaVale McGee",
        BLK: 1258,
        Player_additional: "mcgeeja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3452.png&w=350&h=254",
      },
      {
        id: 20,
        name: "Marc Gasol",
        BLK: 1254,
        Player_additional: "gasolma01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3206.png&w=350&h=254",
      },
      {
        id: 21,
        name: "Myles Turner",
        BLK: 1248,
        Player_additional: "turnemy01",
        img_url:
          "https://cdn.nba.com/headshots/nba/latest/1040x760/1626167.png",
      },
      {
        id: 22,
        name: "Al Horford",
        BLK: 1243,
        Player_additional: "horfoal01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3213.png&w=350&h=254",
      },
      {
        id: 23,
        name: "LaMarcus Aldridge",
        BLK: 1187,
        Player_additional: "aldrila01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2983.png",
      },
    ],
  },
};

let nbaTouchdownLeaders = {
  date: "3/23/2024",
  problem: {
    prompt: "Most Career Losses against Lebron James",
    sourceText: "Basketball-Reference",
    sourceLink: "https://www.basketball-reference.com/",
    options: [
      {
        id: 1,
        name: "Al Horford",
        losses: 49,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3213.png&w=350&h=254",
      },
      {
        id: 2,
        name: "Thaddeus Young",
        losses: 44,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201152.png",
      },
      {
        id: 3,
        name: "DeMar DeRozan",
        losses: 43,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201942.png",
      },
      {
        id: 4,
        name: "Andre Iguodala",
        losses: 39,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2738.png",
      },
      {
        id: 5,
        name: "Tayshaun Prince",
        losses: 37,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2419.png",
      },
      {
        id: 6,
        name: "Marvin Williams",
        losses: 36,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101107.png",
      },
      {
        id: 7,
        name: "Paul Pierce",
        losses: 35,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/1718.png",
      },
      {
        id: 8,
        name: "Paul George",
        losses: 35,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202331.png",
      },
      {
        id: 9,
        name: "Jonas Valančiūnas",
        losses: 35,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6477.png&w=350&h=254",
      },
      {
        id: 10,
        name: "Amir Johnson",
        losses: 35,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/101161.png",
      },
      {
        id: 11,
        name: "Zaza Pachulia",
        losses: 34,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2585.png",
      },
      {
        id: 12,
        name: "David West",
        losses: 33,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2561.png",
      },
      {
        id: 13,
        name: "Kiid Hinrich",
        losses: 33,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2550.png",
      },
      {
        id: 14,
        name: "Kyle Lowry",
        losses: 33,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/200768.png",
      },
      {
        id: 15,
        name: "Jeff Teague",
        losses: 33,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201952.png",
      },
      {
        id: 16,
        name: "Taj Gibson",
        losses: 32,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201959.png",
      },
      {
        id: 17,
        name: "Joakim Noah",
        losses: 32,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201149.png",
      },
      {
        id: 18,
        name: "Joe Johnson",
        losses: 32,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/2207.png",
      },
      {
        id: 19,
        name: "Roy Hibbert",
        losses: 32,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/201579.png",
      },
      {
        id: 20,
        name: "Jeff Green",
        losses: 32,
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3209.png&w=350&h=254",
      },
      {
        id: 21,
        name: "Kevin Garnett",
        losses: 31,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/708.png",
      },
      {
        id: 22,
        name: "Rajon Rondo",
        losses: 31,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/200765.png",
      },
      {
        id: 23,
        name: "Cory Joseph",
        losses: 31,
        img_url: "https://cdn.nba.com/headshots/nba/latest/1040x760/202709.png",
      },
    ],
  },
};

let nflTouchdownLeaders = {
  date: "3/22/2024",
  problem: {
    prompt: "NFL Career Rush Touchdown Leaders Post 2000",
    sourceText: "Football-Reference",
    sourceLink: "https://www.football-reference.com/",
    options: [
      {
        id: 1,
        name: "LaDainian Tomlinson",
        touchdowns: 145,
        playerId: "TomlLa00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2553.png",
      },
      {
        id: 2,
        name: "Adrian Peterson",
        touchdowns: 120,
        playerId: "PeteAd01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/10452.png",
      },
      {
        id: 3,
        name: "Shaun Alexander",
        touchdowns: 100,
        playerId: "AlexSh00",
        img_url:
          "https://advancelocal-adapter-image-uploads.s3.amazonaws.com/image.al.com/home/bama-media/width2048/img/sports_impact/photo/alexander-shaunjpg-0e60ada630364236.jpg",
      },
      {
        id: 4,
        name: "Derrick Henry",
        touchdowns: 90,
        playerId: "HenrDe00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3043078.png",
      },
      {
        id: 5,
        name: "Marshawn Lynch",
        touchdowns: 85,
        playerId: "LyncMa00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/10456.png",
      },
      {
        id: 6,
        name: "Frank Gore",
        touchdowns: 81,
        playerId: "GoreFr00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/8479.png",
      },
      {
        id: 7,
        name: "Priest Holmes",
        touchdowns: 78,
        playerId: "HolmPr00",
        img_url:
          "https://production.listennotes.com/podcasts/the-slacker/kc-chiefs-priest-holmes-NfdyLTXRzCB-Ww1o2AiQ6TL.1400x1400.jpg",
      },
      {
        id: 8,
        name: "Cam Newton",
        touchdowns: 75,
        playerId: "NewtCa00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13994.png&w=350&h=254",
      },
      {
        id: 9,
        name: "Clinton Portis",
        touchdowns: 75,
        playerId: "PortCl00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3579.png",
      },
      {
        id: 10,
        name: "LeSean McCoy",
        touchdowns: 73,
        playerId: "McCoLe01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/12514.png",
      },
      {
        id: 11,
        name: "Ezekiel Elliott",
        touchdowns: 71,
        playerId: "ElliEz00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3051392.png",
      },
      {
        id: 12,
        name: "Steven Jackson",
        touchdowns: 69,
        playerId: "JackSt00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/5549.png&w=350&h=254",
      },
      {
        id: 13,
        name: "Maurice Jones-Drew",
        touchdowns: 68,
        playerId: "DrewMa00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/9646.png&w=350&h=254",
      },
      {
        id: 14,
        name: "Thomas Jones",
        touchdowns: 68,
        playerId: "JoneTh00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2138.png",
      },
      {
        id: 15,
        name: "Todd Gurley",
        touchdowns: 67,
        playerId: "GurlTo01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2977644.png",
      },
      {
        id: 16,
        name: "Edgerrin James",
        touchdowns: 67,
        playerId: "JameEd00",
        img_url:
          "https://staticg.sportskeeda.com/editor/2021/08/354ea-16283502972756-800.jpg",
      },
      {
        id: 17,
        name: "Michael Turner",
        touchdowns: 66,
        playerId: "TurnMi00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/5679.png",
      },
      {
        id: 18,
        name: "Maid Ingram",
        touchdowns: 65,
        playerId: "IngrMa01",
        img_url:
          "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/qyju9wutyssrf0b26ran",
      },
      {
        id: 19,
        name: "Willis McGahee",
        touchdowns: 65,
        playerId: "McGaWi00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4481.png",
      },
      {
        id: 20,
        name: "Ricky Williams",
        touchdowns: 64,
        playerId: "WillRi00",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/1756.png&w=350&h=254",
      },
      {
        id: 21,
        name: "Corey Dillon",
        touchdowns: 63,
        playerId: "DillCo00",
        img_url:
          "https://imagez.tmz.com/image/46/1by1/2014/08/01/460c061c128551daa0f2600b2b463b23_xl.jpg",
      },
      {
        id: 22,
        name: "DeAngelo Williams",
        touchdowns: 61,
        playerId: "WillDe02",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/9613.png",
      },
      {
        id: 23,
        name: "Brandon Jacobs",
        touchdowns: 60,
        playerId: "JacoBr00",
        img_url: "https://a.espncdn.com/i/headshots/nfl/players/full/8524.png",
      },
    ],
  },
};

let ncaaCrossoverGridTrivia = {
  date: "3/2q/2024",
  problem: {
    prompt: "Most NCAA Tournament Game Wins (Mens)",
    sourceText: "NCAA",
    sourceLink: "https://www.ncaa.com/",
    options: [
      {
        id: 1,
        name: "Mike Krzyzewski",
        wins: 101,
        coachId: "krzyzmi01",
        img_url:
          "https://www.si.com/longform/big-interview/mike-krzyzewski-2016-olympics-rio-big-interview/img/coachk-head.jpg",
      },
      {
        id: 2,
        name: "Roy Williams",
        wins: 79,
        coachId: "williro02",
        img_url:
          "https://m.media-amazon.com/images/M/MV5BMjU2NDVkNjMtNDdiMi00M2NmLTg3MmYtZGUxOWUwY2EwOGY4XkEyXkFqcGdeQXVyNjUxMjc1OTM@._V1_.jpg",
      },
      {
        id: 3,
        name: "Dean Smith",
        wins: 65,
        coachId: "smithde03",
        img_url:
          "https://cdn.britannica.com/88/143088-050-D7DFFE74/Dean-Smith.jpg",
      },
      {
        id: 4,
        name: "Jim Boeheim",
        wins: 61,
        coachId: "boeheji01",
        img_url:
          "https://www.caa.com/caaspeakers/partyHeadshot/jim-boeheim.png",
      },
      {
        id: 5,
        name: "John Calipari",
        wins: 58,
        coachId: "calipjo01",
        img_url:
          "https://www.kychamber.com/sites/default/files/images/Events/Calipari%2C%20John-web-2022.jpg",
      },
      {
        id: 6,
        name: "Bill Self",
        wins: 48,
        coachId: "selfbi01",
        img_url:
          "https://www.billselfbasketballcamp.com/images/staff/Self_Bill_10102018-1-600x500.jpg",
      },
      {
        id: 7,
        name: "Tom Izzo",
        wins: 51,
        coachId: "izzoto01",
        img_url:
          "https://www.leadingauthorities.com/sites/default/files/styles/650x430/public/images/izzo-tom-speaker2.original.jpg",
      },
      {
        id: 8,
        name: "Jim Calhoun",
        wins: 51,
        coachId: "calhoji01",
        img_url:
          "https://www.wsb.com/wp-content/uploads/2021/06/Calhoun_Jim_ORIGINAL-682x830.jpg?bust=2fdbe0dde4d7ce84d84d8d9f25cbe6d3",
      },
      {
        id: 9,
        name: "John Wooden",
        wins: 47,
        coachId: "woodejo01",
        img_url:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnG7kEUCfEyHvmIcgxcfE9wjYwCYEzQIG_Dg&usqp=CAU",
      },
      {
        id: 10,
        name: "Lute Olson",
        wins: 46,
        coachId: "olsonlu01",
        img_url:
          "https://www.college-sports-journal.com/wp-content/uploads/2022/07/Lute-Olson-1.jpg",
      },
      {
        id: 11,
        name: "Bob Knight",
        wins: 45,
        coachId: "knighbo01",
        img_url:
          "https://m.media-amazon.com/images/M/MV5BMTEyNTJjNGItYzhiMi00M2Y0LTkxZTEtZGNmYjIxMWFjZjVhXkEyXkFqcGdeQXVyNDUzOTQ5MjY@._V1_.jpg",
      },
      {
        id: 12,
        name: "Scott Drew",
        wins: 29,
        coachId: "drewsc01",
        img_url:
          "https://www.harrywalker.com/media/21049702/drew_scott-official-1012021-hdsht.jpg?anchor=center&mode=crop&width=362&height=449&rnd=133524675390000000",
      },
      {
        id: 13,
        name: "Sean Miller",
        wins: 21,
        coachId: "millese01",
        img_url:
          "https://d11rxijfksshz7.cloudfront.net/images/2013/8/29/FUHQRYDOTOZWUJF.20130829164406.jpg?width=300",
      },
      {
        id: 14,
        name: "Chris Holtmann",
        wins: 11,
        coachId: "holtmch01",
        img_url:
          "https://lh5.googleusercontent.com/IE0y1lEVoYhNEpwB7RJhBdVoVQvajWnLkuGCrJ3exlyJWp2Hipk1SaOwWRMOPgzpzKUJQYM70O__VA92vi2L-LvKuqaQ3x4sHugT0E3sLnPst6-ooHfpvqHJsdE6BWvkhMloZ0Qx",
      },
      {
        id: 15,
        name: "Matt Painter",
        wins: 14,
        coachId: "paintma01",
        img_url:
          "https://www.cla.purdue.edu/alumni/awards/images/painter-matt-da.jpg",
      },
      {
        id: 16,
        name: "Rick Barnes",
        wins: 27,
        coachId: "barnere01",
        img_url:
          "https://pbs.twimg.com/profile_images/585850481353691137/4z6vqZft_400x400.jpg",
      },
      {
        id: 17,
        name: "Bob Huggins",
        wins: 30,
        coachId: "huggibo01",
        img_url:
          "https://d2bw7v1ep7mzfe.cloudfront.net/images/2017/10/12/huggins_bob_11_08_2_.jpg?width=300",
      },
      {
        id: 18,
        name: "Dana Altman",
        wins: 23,
        coachId: "altmanda01",
        img_url:
          "https://d2560u4h06m0te.cloudfront.net/images/2018/5/2/Altman_Dana.jpg?width=300",
      },
      {
        id: 19,
        name: "Chris Mack",
        wins: 10,
        coachId: "mackch01",
        img_url:
          "https://www.athletespeakers.com/storage/celebrities/1654630442_chris-mack_750xx1350-1800-36-0.jpg",
      },
      {
        id: 20,
        name: "Chris Beard",
        wins: 14,
        coachId: "beardch01",
        img_url:
          "https://dxa7m90h2v1am.cloudfront.net/images/2022/7/31/Chris_Beard_headshot_summer_22.jpg?width=300",
      },
      {
        id: 21,
        name: "Maid Few",
        wins: 32,
        coachId: "fewma01",
        img_url:
          "https://www.usab.com/imgproxy/zm7_DhAZ-FURS_RwGTzUWNFW10-XHi34QNATbsJE398/rs:fit:3000:0:0:g:ce/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL3VzYWItY29tLXByb2QvdXBsb2FkLzIwMjMvMDgvMDkvMTNmYWVhZDQtZDdkYi00MzU1LWI5MzEtYTNlZTcxOTBmYTRmLkpQRw.png",
      },
      {
        id: 22,
        name: "Shaka Smart",
        wins: 11,
        coachId: "smartsh01",
        img_url:
          "https://dxa7m90h2v1am.cloudfront.net/images/2019/10/19/smart_shaka_2017.jpg?width=300",
      },
      {
        id: 23,
        name: "Greg McDermott",
        wins: 8,
        coachId: "mcdergr01",
        img_url:
          "https://d2560u4h06m0te.cloudfront.net/images/2017/9/15/Greg_McDermott_mug_17.JPG?width=300",
      },
    ],
  },
};

let nbaCrossoverGridTrivia2 = {
  date: "3/20/2024",
  problem: {
    prompt: "Most NBA Playoff Points Post 2000",
    sourceText: "Basketball-Reference",
    sourceLink: "https://www.basketball-reference.com/",
    options: [
      {
        id: 1,
        name: "LeBron James",
        points: 8023,
        playerId: "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 2,
        name: "Kobe Bryant",
        points: 5312,
        playerId: "bryanko01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/977.png",
      },
      {
        id: 3,
        name: "Kevin Durant",
        points: 4878,
        playerId: "duranke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201142.png",
      },
      {
        id: 4,
        name: "Tim Duncan",
        points: 4591,
        playerId: "duncati01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1495.png",
      },
      {
        id: 5,
        name: "Tony Parker",
        points: 4045,
        playerId: "paideto01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2225.png",
      },
      {
        id: 6,
        name: "Stephen Curry",
        points: 3966,
        playerId: "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201939.png",
      },
      {
        id: 7,
        name: "Dwyane Wade",
        points: 3954,
        playerId: "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2548.png",
      },
      {
        id: 8,
        name: "Diid Nowitzki",
        points: 3663,
        playerId: "nowitdi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1717.png",
      },
      {
        id: 9,
        name: "James Harden",
        points: 3637,
        playerId: "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 10,
        name: "Shaquille O'Neal",
        points: 3488,
        playerId: "onealsh01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/406.png",
      },
      {
        id: 11,
        name: "Paul Pierce",
        points: 3180,
        playerId: "piercpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1718.png",
      },
      {
        id: 12,
        name: "Manu Ginóbili",
        points: 3054,
        playerId: "ginobma01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/272.png",
      },
      {
        id: 13,
        name: "Klay Thompson",
        points: 3032,
        playerId: "thompkl01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202691.png",
      },
      {
        id: 14,
        name: "Chris Paul",
        points: 2981,
        playerId: "paulch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101108.png",
      },
      {
        id: 15,
        name: "Kawhi Leonard",
        points: 2934,
        playerId: "leonaka01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202695.png",
      },
      {
        id: 16,
        name: "Russell Westbrook",
        points: 2845,
        playerId: "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 17,
        name: "Ray Allen",
        points: 2682,
        playerId: "allenra02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/951.png",
      },
      {
        id: 18,
        name: "Richard Hamilton",
        points: 2571,
        playerId: "hamilri01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/294.png",
      },
      {
        id: 19,
        name: "Jimmy Butler",
        points: 2534,
        playerId: "butleji01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202710.png",
      },
      {
        id: 20,
        name: "Chauncey Billups",
        points: 2526,
        playerId: "billuch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1497.png",
      },
      {
        id: 21,
        name: "Kevin Garnett",
        points: 2383,
        playerId: "garneke01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/708.png",
      },
      {
        id: 22,
        name: "Paul George",
        points: 2299,
        playerId: "georgpa01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202331.png",
      },

      {
        id: 24,
        name: "Giannis Antetokounmpo",
        points: 2105,
        playerId: "antetgi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203507.png",
      },
    ],
  },
};

let nbaCrossoverGridTrivia = {
  date: "3/19/2024",
  problem: {
    prompt: "Most NBA Assists Post 2000",
    sourceText: "Basketball-Reference",
    sourceLink: "https://www.basketball-reference.com/",
    options: [
      {
        id: 1,
        name: "Chris Paul",
        assists: 11755,
        playerId: "paulch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101108.png",
      },
      {
        id: 2,
        name: "LeBron James",
        assists: 10847,
        playerId: "jamesle01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2544.png",
      },
      {
        id: 3,
        name: "Steve Nash",
        assists: 9444,
        playerId: "nashst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/959.png",
      },
      {
        id: 4,
        name: "Russell Westbrook",
        assists: 9418,
        playerId: "westbru01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201566.png",
      },
      {
        id: 5,
        name: "Jason Kidd",
        assists: 8243,
        playerId: "kiddja01",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/429.png",
      },
      {
        id: 6,
        name: "Andre Miller",
        assists: 8048,
        playerId: "millean02",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1889.png",
      },
      {
        id: 7,
        name: "Rajon Rondo",
        assists: 7584,
        playerId: "rondora01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200765.png",
      },
      {
        id: 8,
        name: "James Harden",
        assists: 7463,
        playerId: "hardeja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201935.png",
      },
      {
        id: 9,
        name: "Tony Parker",
        assists: 7036,
        playerId: "paideto01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2225.png",
      },
      {
        id: 10,
        name: "Kyle Lowry",
        assists: 6926,
        playerId: "lowryky01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/200768.png",
      },
      {
        id: 11,
        name: "Deron Williams",
        assists: 6819,
        playerId: "willide01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101114.png",
      },
      {
        id: 12,
        name: "Mike Conley",
        assists: 6205,
        playerId: "conlemi01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201144.png",
      },
      {
        id: 13,
        name: "Jrue Holiday",
        assists: 6134,
        playerId: "holidjr01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201950.png",
      },
      {
        id: 14,
        name: "Stephen Curry",
        assists: 6025,
        playerId: "curryst01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/201939.png",
      },
      {
        id: 15,
        name: "John Wall",
        assists: 5735,
        playerId: "walljo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/202322.png",
      },
      {
        id: 16,
        name: "Baron Davis",
        assists: 5716,
        playerId: "davisba01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1884.png",
      },
      {
        id: 17,
        name: "Dwyane Wade",
        assists: 5701,
        playerId: "wadedw01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2548.png",
      },
      {
        id: 18,
        name: "Damian Lillard",
        assists: 5529,
        playerId: "lillada01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/203081.png",
      },
      {
        id: 19,
        name: "Kobe Bryant",
        assists: 5503,
        playerId: "bryanko01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/977.png",
      },
      {
        id: 20,
        name: "José Calderón",
        assists: 5148,
        playerId: "caldejo01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/101181.png",
      },
      {
        id: 21,
        name: "Andre Iguodala",
        assists: 5147,
        playerId: "iguodan01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/2738.png",
      },
      {
        id: 22,
        name: "Chauncey Billups",
        assists: 5110,
        playerId: "billuch01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1497.png",
      },
      {
        id: 23,
        name: "Jason Terry",
        assists: 5069,
        playerId: "terryja01",
        img_url: "https://cdn.nba.com/headshots/nba/latest/260x190/1891.png",
      },
    ],
  },
};

let nbaCrossoverGridTriviold = {
  date: "2/1/2024",
  problem: {
    prompt: "Top 5 Rushing Touchdowns (NFL)",
    sourceText: "Wikipedia + James Clear",
    sourceLink: "https://jamesclear.com/best-books/best-selling",
    options: [
      {
        id: 1,
        name: "Emmitt Smith",
        img_url:
          "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/ub78gyoyi3x1wlaow49n",
      },
      {
        id: 2,
        name: "LaDainian Tomlinson",
        img_url:
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2553.png",
      },
      {
        id: 3,
        name: "Marcus Allen",
        img_url:
          "https://static.clubs.nfl.com/image/private/f_auto/raiders/l0eofdbujuumh5xhay7c",
      },
      {
        id: 4,
        name: "Adrian Peterson",
        img_url:
          "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/ug2ua3skqc9f6vkbowxc",
      },
      {
        id: 5,
        name: "Walter Payton",
        img_url:
          "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/yjatyzmf5yhgs0v9qcze",
      },
      {
        id: 6,
        name: "Jim Brown",
        img_url:
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/putro8sfykabuanlfcu1",
      },
      {
        id: 7,
        name: "John Riggins",
        img_url:
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/wehkyczr6mbhgqeeon5i",
      },
      {
        id: 8,
        name: "Shaun Alexander",
        img_url:
          "https://static.www.nfl.com/image/private/t_editorial_landscape_mobile/f_auto/league/iqlgu6vraoynvlggjs27.jpg",
      },
      {
        id: 9,
        name: "Marshall Faulk",
        img_url:
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/ker7p0exticomqhcbtjt",
      },
      {
        id: 10,
        name: "Barry Sanders",
        img_url:
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/pj2ldftirqvwh3c9pljr",
      },
      {
        id: 11,
        name: "Jerome Bettis",
        img_url:
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/zqdmcbadbq7eo8tvjwas",
      },
      {
        id: 12,
        name: "Franco Harris",
        img_url:
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/ajagqtwni1q9a0lcbzwq",
      },
      {
        id: 13,
        name: "Derrick Henry",
        img_url:
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/xpewyoieoj7zeub4fvff",
      },
      {
        id: 14,
        name: "Curtis Martin",
        img_url:
          "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/ikb6en50ga5g4wc8oekd",
      },
      {
        id: 15,
        name: "Eric Dickerson",
        img_url:
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/jhnq2bvn8ryvxiwlfhk8",
      },
      {
        id: 16,
        name: "Priest Holmes",
        img_url:
          "https://static.clubs.nfl.com/image/private/f_auto/chiefs/wse7jf6jig5iuk3dntfr",
      },
      {
        id: 17,
        name: "Marshawn Lynch",
        img_url:
          "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/ddhpebfqtr9yxsnbx9ht",
      },
      {
        id: 18,
        name: "Jim Taylor",
        img_url:
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/iegpmghocdl2shgkb4qf",
      },
      {
        id: 19,
        name: "Corey Dillon",
        img_url:
          "https://static.clubs.nfl.com/image/private/t_landscape_tablet/bengals/i755ozg8ee2r4lt99tdj.jpg",
      },
      {
        id: 20,
        name: "Frank Gore",
        img_url:
          "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/gfuklcqmphmdy9ncwrdt",
      },
      {
        id: 21,
        name: "Ottis Anderson",
        img_url:
          "https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/giants/kxrdzpldt4jggulyegtc",
      },
      {
        id: 22,
        name: "Edgerrin James",
        img_url:
          "https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/colts/xwb2scg204lwwgmjvyoo",
      },
      {
        id: 23,
        name: "Ricky Watters",
        img_url:
          "https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/49ers/tf9oe0t1klpcppuwt8iy",
      },
    ],
    facts: [
      "500 Million",
      "200 Million",
      "150 Million",
      "142 Million",
      "107 Million",
    ],
  },
};

let CrossoverGridTriviaData = {
  date: "12/11/2022",
  problem: {
    prompt: "5 Best-Selling Non-Religious Books of All-Time",
    sourceText: "Wikipedia + James Clear",
    sourceLink: "https://jamesclear.com/best-books/best-selling",
    options: [
      "Don Quixote",
      "A Tale of Two Cities",
      "The Lord of the Rings",
      "The Little Prince",
      "Harry Potter & the Sorcerer's Stone",
      "And Then There Were None",
      "The Dream of the Red Chamber",
      "The Hobbit",
      "The Lion, the Witch and the Wardrobe",
      "The Da Vinci Code",
      "Think and Grow Rich",
      "Harry Potter & the Half-Blood Prince",
      "The Catcher in the Rye",
      "The Alchemist",
      "Harry Potter & the Chamber of Secrets",
      "Harry Potter & the Prisoner of Azkaban",
      "Harry Potter & the Goblet of Fire",
      "Harry Potter & the Order of the Phoenix",
      "Harry Potter & the Deathly Hallows",
      "Anne of Green Gables",
      "Charlotte’s Web",
      "Black Beauty",
      "Lolita",
    ],
    facts: [
      "500 Million",
      "200 Million",
      "150 Million",
      "142 Million",
      "107 Million",
    ],
  },
};

const CrossoverGridTriviaPage = () => {
  const [passwordValidated, setPasswordValidated] = useState(true);
  const [passwordInput, setPasswordInput] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const autoJoin = false;
  const imageOriginalHeight = 7900;
  const isDesktop = window.innerWidth >= 800;
  const windowHeight = window.innerHeight;
  const scalingFactor = windowHeight / imageOriginalHeight;
  const newY = isDesktop ? 250 * scalingFactor : 200 * scalingFactor;
  const isWaitlistMode = true;

  // const [statsOpen, setStatsOpen] = useState(false);
  const [isPrimeAuthed, setIsPrimeAuthed] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false); // Used to toggle the How to Play modal
  const [rememberMe, setRememberMe] = useState(false);
  const [page_name, setPageName] = useState(""); // Define page_name state
  const [sport_id, setSportId] = useState(""); // Define sport_id state
  const currentSport = sports.find((sport) => sport.id === sport_id.toString());

  const handleRememberMeCheckbox = (event) => {
    setRememberMe(event.target.checked);
  };

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  };

  const validatePassword = () => {
    if (passwordInput === "Amazon123") {
      if (rememberMe) {
        localStorage.setItem("primePreviewPasswordValidated", true);
      }
      setPasswordValidated(true);
    } else {
      alert("Incorrect password. Please try again.");
      setPasswordInput("");
    }
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const signature = new URLSearchParams(window.location.search).get(
    "Signature"
  );

  const {
    date = "",
    problem: {
      prompt = "",
      options = [...Array(23).map((i) => "")],
      sourceText,
      sourceLink,
      facts = [],
    },
    // config: { popup, bannerText, statisticsPageText, instagamLink },
  } = mvp;
  let statisticsPageText = "stats";

  const shuffledOptions = useMemo(() => {
    return options
      .map((player, index) => {
        // console.log(player);
        return { text: player.name, img_url: player.img_url, id: index };
      })
      .sort(() => Math.random() - 0.5);
  }, [options]);

  const game = new CrossoverGridTrivia({
    options: shuffledOptions,
    isLoaded: true,
    date: "",
  });

  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };
  const handleCloseHowToPlay = () => {
    setShowHowToPlay(false);
  };

  useEffect(() => {
    onAdRefresh();
    const isPWChecked =
      localStorage.getItem("primePreviewPasswordValidated") || false;
    const savedIsPrimeAuthed = localStorage.getItem("isPrimeAuthed") || false;
    if (isPWChecked) {
      setPasswordValidated(true);
    }
    if (savedIsPrimeAuthed) {
      setIsPrimeAuthed(true);
    }
  }, []);

  useEffect(() => {
    console.log(signature);
    if (signature !== null) {
      checkPrimeAuth();
    }
  }, [signature]);

  const checkPrimeAuth = () => {
    axios
      .post("https://staging.crossoverapi.com/auth/prime-auth", { signature })
      .then((res) => {
        console.log(res);
        let isAuthed = res.data.isAuthed || false;
        if (isAuthed) {
          localStorage.setItem("isPrimeAuthed", true);
        }
        setIsPrimeAuthed(isAuthed);
      })
      .catch((err) => {
        console.log("something went wrong authenticating with Prime Gaming...");
        setIsPrimeAuthed(false);
      });
  };

  useEffect(() => {
    if (autoJoin) {
      navigate(`/play/waiting`);
    }
  }, []);
  const useStyles = makeMuiStyles((theme, prefersDaidMode) => ({
    root: {
      flexGrow: 1,
      // padding: 2,  // 2 or '2px' or '2rem', as per your requirements
    },
    card: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    close: {
      position: "absolute",
      // right: 1,  // 1 or '1px' or '1rem', as per your requirements
      // top: 1,  // 1 or '1px' or '1rem', as per your requirements
      color: "#bdbdbd", // color from theme.palette.grey[500]
    },
    boxCard: {
      backgroundColor: theme.palette.background.gridCard,
      "&:hover": {
        backgroundColor: theme.palette.background.gridCardHover,
      },
    },
    boxCardSelected: {
      backgroundColor: theme.palette.background.gridCardSelect,
    },
    link: {
      color: theme.palette.text.link,
      fontSize: isDesktop ? "16px" : "12px",
      fontWeight: "600",
    },
    searchBox: {
      backgroundColor: "#58D18490",
      position: "relative",
      backdropFilter: "blur(10px)",
      color: "white",
      boxShadow: "0 0 15px rgba(0, 0, 0, 0.4)",
    },
    searchInput: {
      color: theme.palette.text.primary,
    },
    result: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      cursor: "pointer",
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: theme.palette.background.resultHover,
      },
    },
    sponsorshipLink: {
      marginTop: "20px",
      color: theme.palette.text.link,
      textDecoration: "none",
      borderBottom: `1px solid ${theme.palette.text.link}`,
    },
    sponsorshipLinkUnderline: {
      color: theme.palette.text.link,
      textDecoration: "none",
    },
    remainingGuesses: {
      position: "absolute",
      right: isDesktop ? "min(calc(90vw - 400px), 5vw)" : 0,
      top: "50%",
    },
    bars: {
      fontSize: "12px",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
    barLabelsXAxis: {
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: "bold",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
    boardImage: {
      filter: theme.palette.mode === "daid" ? "invert(1)" : "invert(0)",
      marginTop: 20,
    },
  }));

  const classes = useStyles();
  return (
    <div className="app">
      {!passwordValidated ? (
        <div style={{ padding: 20 }}>
          <input
            type="password"
            value={passwordInput}
            onChange={handlePasswordChange}
            placeholder="Enter password"
            style={{ marginRight: 10 }}
          />
          <button onClick={validatePassword}>Submit</button>
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={handleRememberMeCheckbox}
              />
            }
            label="Remember me?"
          />
        </div>
      ) : (
        <>
          <Container>
            {showHowToPlay && <HowToPlayModal onClose={handleCloseHowToPlay} />}
            <nav className="navbar">
              <a
                href="https://crossovergrid.com"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img src={crossover_logo} alt="Logo" className="navbar-logo" />
                <p
                  style={{
                    marginLeft: "10px",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  CROSSOVER GRID
                </p>
              </a>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FadeMenu />{" "}
                {/* Here is the FadeMenu component for the hamburger menu */}
                <IconButton onClick={() => setShowHowToPlay(true)}>
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </nav>
          </Container>
          <div className="sport-picker">
            <div className="links">
              {sports.map((sport) => (
                <Link
                  key={sport.id}
                  className={`sportLink ${
                    sport.id === "21" ? "prime-gaming-logo" : ""
                  } ${classes.link} ${
                    page_name === sport.id ? "selected" : ""
                  }`}
                  to={sport.path}
                  // onClick={() => onAdRefresh()}
                >
                  {sport.emoji.startsWith("http") ? (
                    <img src={sport.emoji} alt={sport.name} />
                  ) : (
                    <span>{sport.emoji}</span>
                  )}
                  <br />
                  {sport.name.length < 4 ? (
                    <span>{sport.name}</span>
                  ) : (
                    <span style={{ fontSize: 12 }}>{sport.name}</span>
                  )}
                </Link>
              ))}
            </div>
          </div>

          <GameInterface
            game={game}
            prompt={prompt}
            options={shuffledOptions}
            // setStatsOpen={setStatsOpen}
          />
          {!isPrimeAuthed && (
            //
            <>
              <Backdrop open={true} style={{ zIndex: 1000 }} />
              <Dialog open={true}>
                <PrimeUnauth />
              </Dialog>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CrossoverGridTriviaPage;
