import { Dialog } from "@mui/material";
import { useState, useEffect, useRef, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DEFAULT_TIMEOUT = 10000;

export const useWebSocket = (url) => {
  const [socket, setSocket] = useState(null);
  const socketRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);

  const connectWebSocket = useCallback(() => {
    const ws = new WebSocket(url);

    ws.onopen = () => {
      console.info("WebSocket connected");
      setSocket(ws);
      socketRef.current = ws;
      setIsConnected(true);
    };

    ws.onclose = () => {
      // console.info("WebSocket disconnected");
      // setSocket(null);
      // socketRef.current = null;
      // setIsConnected(false);
      // setTimeout(connectWebSocket, DEFAULT_TIMEOUT);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      // ws.close();
    };

    ws.onmessage = (message) => {
      console.log("WebSocket message received:", message);
    };
  }, [url]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socketRef.current) {
        toast.warn("User Disconnected");
        socketRef.current.close();
      }
    };
  }, [connectWebSocket]);

  useEffect(() => {
    // const handleVisibilityChange = () => {
    //   if (document.visibilityState === "hidden") {
    //     if (socketRef.current) {
    //       // socketRef.current.close();
    //     }
    //   } else if (!isConnected) {
    //     connectWebSocket();
    //   }
    // };
    // document.addEventListener("visibilitychange", handleVisibilityChange);
    // return () => {
    //   document.removeEventListener("visibilitychange", handleVisibilityChange);
    // };
  }, [isConnected, connectWebSocket]);

  return socket;
};

// Make sure to include ToastContainer in your component tree
const App = () => (
  <div>
    <ToastContainer />
    {/* other components */}
  </div>
);

export default App;
