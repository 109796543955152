import React from "react";
import PropTypes from "prop-types";

const GameHeader = ({ teamData, isRowHeader }) => {
  return (
    <div className={isRowHeader ? "row-header" : "header"}>
      {teamData.type === 2 ? (
        <p className={"rowHeaderText"}>{teamData.text}</p>
      ) : (
        <span style={{ textAlign: "center", marginBottom: "1rem" }}>
          <img
            src={teamData.img_url}
            alt={teamData.name}
            className={"team-logo"}
          />
        </span>
      )}
    </div>
  );
};

GameHeader.propTypes = {
  teamData: PropTypes.object.isRequired,
  isRowHeader: PropTypes.bool.isRequired,
};

const ColumnHeader = (teamData) => GameHeader(teamData, false);
const RowHeader = (teamData) => GameHeader(teamData, true);

export { ColumnHeader, RowHeader };
