import { useCallback, useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useUserContext } from "../../contexts/UserContext";

const API_URL = process.env.REACT_APP_MULTIPLAYER_API_URL;

export const useQueryStats = () => {
  const { userId, username } = useUserContext();
  const {
    stats,
    sportsStats,
    userGames,
    leaderboard,
    sportId,
    page,
    isLoading,
    error,
    saveStats,
    saveSportsStats,
    saveUserGames,
    setLeaderboard,
    setSportId,
    setPage,
    setLoading,
    setError,
  } = useAppContext();

  const fetchStats = useCallback(async () => {
    if (!userId) return;

    try {
      const response = await fetch(`${API_URL}/user/stats?user=${userId}`);
      const data = await response.json();

      saveSportsStats(data);

      // Calculate global stats
      const globalStats = data.reduce(
        (acc, curr) => ({
          counter: acc.counter + 1,
          rank: acc.rank + (curr.rank ? Number(curr.rank) : 0),
          wins: acc.wins + curr.wins,
          losses: acc.losses + curr.losses,
          draws: acc.draws + curr.draws,
          games: acc.games + curr.games,
          elo: acc.elo + curr.elo,
        }),
        { counter: 0, rank: 0, wins: 0, losses: 0, draws: 0, games: 0, elo: 0 }
      );

      saveStats(globalStats);

      // Fetch User Games
      const url = `${API_URL}/user/games?user=${userId}`;
      const responseGames = await fetch(url);
      const dataResponseGames = await responseGames.json();

      saveUserGames(dataResponseGames);

      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [userId, saveSportsStats, saveStats, saveUserGames, setLoading, setError]);

  const fetchLeaderBoard = useCallback(
    async (sportId, newPage = 1) => {
      try {
        const response = await fetch(
          `${API_URL}/user/leaderboard?sport=${sportId}&page=${newPage}`
        );
        const data = await response.json();

        setLeaderboard((prev) => {
          const ret = prev.find((block) => block.sportId === sportId);

          if (ret) {
            return prev.map((block) => {
              if (block.sportId === sportId) {
                return {
                  sportId,
                  page: newPage,
                  data: [...(block.data ? block.data : []), ...data],
                };
              }
              return block;
            });
          }

          return [...prev, { sportId, page: newPage, data }];
        });
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    },
    [setLeaderboard, setLoading, setError]
  );

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  return {
    fetchLeaderBoard,
    username,
    sportId,
    setSportId,
    page,
    setPage,
    stats: stats ?? {
      counter: 0,
      rank: 0,
      wins: 0,
      losses: 0,
      draws: 0,
      games: 0,
      elo: 0,
    }, // Ensure stats is not null
    sportsStats: sportsStats ?? [], // Ensure sportsStats is not null
    leaderboard,
    userGames: userGames ?? [], // Ensure userGames is not null
    isLoading,
    error,
  };
};
