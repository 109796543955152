//Auth.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Modal,
  Button,
  Typography,
  Box,
  Dialog,
  Divider,
  TextField,
  Paper,
} from "@mui/material";
import crossover_logo from "../assets/nba.svg";

import Axios from "axios";

const Login = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);
  const navigate = useNavigate();

  const checkAuth = async () => {
    Axios.get(`${process.env.REACT_APP_SERVER_URL}/auth/check-auth`, {
      withCredentials: true,
    })
      .then((res) => {
        console.log(res);
        if (res?.data?.isAuthed) {
          navigate("/play");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    // Make an API call to your backend to initiate the magic link process
    Axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/request-magic-link`, {
      email: email,
    })
      .then((res) => {
        console.log(res);
        setIsSent(true);
      })
      .catch((err) => console.log(err));
  };

  const renderInput = () => {
    return (
      <>
        <Typography variant="h5" align="center">
          Login to Crossover Grid
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{ marginTop: "20px", marginBottom: "20px" }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi.
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Email"
          label="Email"
          sx={{ marginBottom: "40px" }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button variant="contained" onClick={handleLogin}>
          Send Magic Link
        </Button>
      </>
    );
  };

  const renderCheckEmail = () => {
    return (
      <>
        <Typography variant="h5" align="center">
          Check your email
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{ marginTop: "20px", marginBottom: "20px" }}
        >
          We’ve emailed your individual signin link to <b>{email}</b>. Didn’t
          get an email? Wait 1-2 minutes, or try checking your spam folder.
        </Typography>
        <Button variant="contained" onClick={() => setIsSent(false)}>
          Try another email
        </Button>
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      // onClose={() => setSearchOpen(false)}
      autoFocus={false}
      sx={{
        touchAction: "none",
      }}
    >
      <Paper
        sx={{
          width: "100vw",
          maxWidth: "450px",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <Divider>
          <img
            src={crossover_logo}
            alt="Logo"
            // className="navbar-logo"
            width={"50px"}
            height={"50px"}
          />
        </Divider>
        {!isSent ? renderInput() : renderCheckEmail()}
      </Paper>
    </Dialog>
  );
};

export default Login;
