import InfoIcon from "@mui/icons-material/Info"; // Importing MUI Info icon
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import crossover_logo from "../assets/nba.svg";
import { useScreenSize } from "../hooks/useScreenSize";
import User from "./Auth/User";
import { PlayMenu } from "./Nav/PlayMenu";

const Navbar = ({ style }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleLogoClick = () => {
    navigate("/play"); // Redirecting to /play
  };

  const modalStyles = {
    display: "flex",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.8)",
    justifyContent: "center",
    alignItems: "center",
  };

  const lightStyles = {
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #888",
  };

  const darkStyles = {
    backgroundColor: "#2A2A2A",
    color: "#fff",
    border: "1px solid #555",
  };

  const modalContentStyles = {
    margin: "auto",
    padding: "20px",
    width: "80%",
    maxWidth: "500px",
    maxHeight: "80%",
    overflowY: "auto",
    position: "relative",
    borderRadius: "8px",
  };

  const closeStyles = {
    position: "absolute",
    top: "10px",
    right: "20px",
    color: "#aaa",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const boardStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: "5px",
    margin: "20px 0",
  };

  const cellStyles = {
    width: "60px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#eee",
    border: "1px solid #888",
    fontSize: "24px",
    fontWeight: "bold",
  };

  const headerStyles = {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "10px 0",
  };

  const scoreboardStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0",
  };

  const arrowStyles = (turn) => ({
    fontSize: "24px",
    fontWeight: "bold",
    color: "green",
    marginLeft: "10px",
    marginRight: "10px",
  });

  const [moves, setMoves] = useState(["", "", "", "", "", "", "", "", ""]);
  const [currentMove, setCurrentMove] = useState(0);
  const [turn, setTurn] = useState("X");
  const [winner, setWinner] = useState("");
  const [gameIndex, setGameIndex] = useState(0);

  const games = [
    [
      { index: 0, player: "X" },
      { index: 0, player: "O" }, // Override
      { index: 1, player: "X" },
      { index: 4, player: "O" },
      { index: 2, player: "X" }, // Winning move
    ],
    [
      { index: 0, player: "X" },
      { index: 1, player: "O" },
      { index: 2, player: "X" },
      { index: 3, player: "O" },
      { index: 4, player: "X" },
      { index: 5, player: "O" },
      { index: 7, player: "O" }, // Losing move
    ],
    [
      { index: 0, player: "X" },
      { index: 0, player: "O" }, // Override
      { index: 1, player: "X" },
      { index: 1, player: "O" }, // Override
      { index: 2, player: "X" },
      { index: 3, player: "O" },
      { index: 4, player: "X" },
      { index: 4, player: "O" }, // Override
      { index: 5, player: "X" },
      { index: 6, player: "O" },
      { index: 8, player: "X" }, // Winning move
    ],
  ];

  useEffect(() => {
    if (currentMove < games[gameIndex].length && !winner) {
      const timeout = setTimeout(() => {
        const newMoves = [...moves];
        newMoves[games[gameIndex][currentMove].index] =
          games[gameIndex][currentMove].player;
        setMoves(newMoves);
        setTurn(turn === "X" ? "O" : "X");
        setCurrentMove(currentMove + 1);
        checkWinner(newMoves);
      }, 1000);

      return () => clearTimeout(timeout);
    } else if (currentMove >= games[gameIndex].length) {
      const timeout = setTimeout(() => {
        setCurrentMove(0);
        setMoves(["", "", "", "", "", "", "", "", ""]);
        setTurn("X");
        setWinner("");
        setGameIndex((gameIndex + 1) % games.length);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [currentMove, turn, winner]);

  const checkWinner = (moves) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (moves[a] && moves[a] === moves[b] && moves[a] === moves[c]) {
        setWinner(moves[a]);
        return;
      }
    }
  };

  return (
    <>
      <nav
        className="navbar"
        style={{
          marginTop: 0,
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: "#2A2A2A80",
          borderBottom: "1px solid lightgrey",
          alignSelf: "flex-start",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer", // Adding cursor pointer to indicate clickable area
          }}
          onClick={handleLogoClick} // Adding click handler
        >
          <img src={crossover_logo} alt="Logo" width={"30px"} height={"30px"} />
          {!isMobile && (
            <p
              style={{
                marginLeft: "10px",
                fontWeight: "bold",
                fontSize: 20,
                marginTop: "0px",
                marginBottom: "0px",
                color: "white",
              }}
              className="scoreboard-font"
            >
              CROSSOVER LIVE
            </p>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <PlayMenu />
          <User />
          <button
            onClick={openModal}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              ...(isMobile ? { padding: 0 } : {}),
            }}
          >
            <InfoIcon style={{ color: "white" }} />
          </button>
        </div>
      </nav>

      {modalOpen && (
        <div style={modalStyles} onClick={closeModal}>
          <div
            style={{
              ...modalContentStyles,
              ...(document.documentElement.classList.contains("dark")
                ? darkStyles
                : lightStyles),
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <span style={closeStyles} onClick={closeModal}>
              &times;
            </span>
            <h1>Crossover Live: How to Play</h1>
            <ul>
              <li>
                <strong>Selection:</strong> 📋 Pick a cell and choose a player,
                movie, or Pokémon that fits the criteria for both the row and
                column.
              </li>
              <li>
                <strong>Unlimited Override:</strong> 🔄 Override any of your
                opponent's cells with another qualifying choice. Cells can be
                overridden endlessly, but always with new, qualifying choices.
              </li>
            </ul>

            <div style={headerStyles}>Crossover Live Game</div>
            <div style={scoreboardStyles}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>Home (X)</p>
                {turn === "X" && <p style={arrowStyles(turn)}>←</p>}
                <p></p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p></p>
                {turn === "O" && <p style={arrowStyles(turn)}>→</p>}
                <p>Away (O)</p>
              </div>
            </div>
            <div style={boardStyles}>
              {moves.map((move, index) => (
                <div key={index} style={cellStyles}>
                  {move}
                </div>
              ))}
            </div>
            {winner && (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color:
                    winner === "Draw"
                      ? "gray"
                      : winner === "X"
                      ? "blue"
                      : "red",
                }}
              >
                {winner === "Draw"
                  ? "It's a Draw!"
                  : winner === "X"
                  ? "Home Wins!"
                  : "Away Wins!"}
              </p>
            )}
            <ul>
              <li>
                <strong>Notifications:</strong> 🔔 Stay updated! You'll be
                notified when it's your turn, when your opponent makes a move,
                or if one of your selected cells gets overridden.
              </li>
              <li>
                <strong>Winning:</strong> 🏆 Connect three of your choices in a
                row—horizontally, vertically, or diagonally—to win. A full board
                without a winner results in a draw.
              </li>
              <li>
                <strong>Franchise History:</strong> 🕰️ For sports, remember
                franchise histories when picking players. For example, Expos
                players can represent the Nationals, and Browns players can
                stand in for the Orioles.
              </li>
              <li>
                <strong>Time Limit:</strong> ⏰ Each move must be made within 30
                seconds. If you hesitate, you'll skip a turn.
              </li>
              <li>
                <strong>Endgame & Rematch:</strong> 🔄 At the end of the game,
                review the selections and get ready for a rematch.
              </li>
              <li>
                <strong>Leaderboard & Achievements:</strong> 🏅 Earn points for
                each win and strategic move. Aim for the top of the global
                leaderboard.
              </li>
            </ul>
            <p>
              This online version of Tic Tac Toe combines sports trivia, movie
              knowledge, and Pokémon mastery with fast-paced strategy, all while
              racing against the clock. Game on!
            </p>

            <h2>Contact Us</h2>
            <ul>
              <li>
                <strong>Report a bug or Give Feedback:</strong>{" "}
                Feedback@crossovergrid.com
              </li>
              <li>
                <strong>Sponsorships:</strong> Sponsorships@crossovergrid.com
              </li>
              <li>
                <strong>Twitter:</strong>{" "}
                <a
                  href="https://twitter.com/CrossoverGrid"
                  target="_blank"
                  rel="noreferrer"
                >
                  @CrossoverGrid
                </a>
              </li>
              <li>
                <strong>Instagram:</strong>{" "}
                <a
                  href="https://instagram.com/CrossoverGrid"
                  target="_blank"
                  rel="noreferrer"
                >
                  @CrossoverGrid
                </a>
              </li>
              <li>
                <strong>TikTok:</strong>{" "}
                <a
                  href="https://tiktok.com/@CrossoverGrid"
                  target="_blank"
                  rel="noreferrer"
                >
                  @CrossoverGrid
                </a>
              </li>
              <li>
                <strong>Threads:</strong>{" "}
                <a
                  href="https://threads.net/@CrossoverGrid"
                  target="_blank"
                  rel="noreferrer"
                >
                  @CrossoverGrid
                </a>
              </li>
              <li>
                <strong>Website:</strong>{" "}
                <a
                  href="https://CrossoverGrid.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  CrossoverGrid.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
