const setBoxStyle = (index) => {
  if (index == 0) {
    return { borderTopLeftRadius: "6px" };
  } else if (index == 2) {
    return { borderTopRightRadius: "6px" };
  } else if (index == 6) {
    return { borderBottomLeftRadius: "6px" };
  } else if (index == 8) {
    return { borderBottomRightRadius: "6px" };
  }
};

const setNameContainerStyle = (index) => {
  if (index == 6) {
    return { borderBottomLeftRadius: "6px" };
  } else if (index == 8) {
    return { borderBottomRightRadius: "6px" };
  }
};

function abbreviateFullName(fullName) {
  const nameParts = fullName.split(" ");
  const firstName = nameParts[0];
  const lastName = nameParts[nameParts.length - 1];

  let abbreviatedFirstName = "";
  if (firstName.length > 1) {
    abbreviatedFirstName = firstName.substr(0, 1) + ".";
  } else {
    abbreviatedFirstName = firstName;
  }

  let abbreviatedMiddleName = "";
  if (nameParts.length > 2) {
    const middleName = nameParts.slice(1, -1).join(" ");
    if (middleName.length > 1) {
      abbreviatedMiddleName = middleName.substr(0, 1) + ".";
    } else {
      abbreviatedMiddleName = middleName;
    }
  }

  return abbreviatedFirstName + " " + abbreviatedMiddleName + " " + lastName;
}

export { setBoxStyle, setNameContainerStyle, abbreviateFullName };
