import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { grey } from "@mui/material/colors";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useMemo } from "react";

const MINUTE = 1000 * 60;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0 * MINUTE,
      gcTime: 5 * MINUTE,
    },
  },
});

const font = "'Inter', sans-serif";

const getDesignTokens = (mode: "dark" | "light") => ({
  palette: {
    mode,
    ...(mode === "dark"
      ? {
          background: {
            default: "#212121",
            gameBoard: "#21212195",
            gridCard: "#ffffff30",
            gridCardHover: "#4d527080",
            gridCardSelect: "#ffffff80",
            blurCard: "#2A2A2A90",
            resultHover: "#535353",
          },
          text: {
            primary: "#ffffff",
            secondary: grey[500],
            link: "#dcdcdc",
          },
        }
      : {
          background: {
            default: "#ffffff",
            gameBoard: "#ffffff95",
            gridCard: "#ffffff30",
            gridCardHover: "#dcdcdc80",
            gridCardSelect: "#007F8B80",
            blurCard: "#ffffff95",
            resultHover: "#53535330",
          },
          text: {
            primary: grey[900],
            secondary: grey[800],
            link: "#000000",
          },
        }),
  },
  typography: {
    fontFamily: font,
  },
});

export const Providers = ({ children }: React.PropsWithChildren<{}>) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(() => {
    return createTheme(getDesignTokens(prefersDarkMode ? "dark" : "light"));
  }, [prefersDarkMode]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </QueryClientProvider>
  );
};
