import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import GameBoard from "./GameBoard/GameBoard";
import Keyboard from "./Keyboard/Keyboard";
import { Link } from "@mui/material";
import Navbar from "../../Nav";
import useTheme from "@mui/material/styles/useTheme";
import { makeStyles as makeMuiStyles } from "@mui/styles";
import StatisticsModal from "../Modals/StatisticsModal";

const PromptBlock = ({ prompt, sourceText, sourceLink }) => {
  return (
    <div style={{width: '100%'}}>
      <div style={{ textAlign: "center", margin: "10px 10px", padding: '10px 10px', borderStyle:'solid', borderWidth: '1px', borderColor: 'black'  }}>
        <Typography variant="h7" component="div" style={{fontWeight: 'bold'}}>
          {prompt}
        </Typography>
        {sourceLink && sourceText ? (
          <Link
            href={sourceLink}
            underline="always"
            target="_blank"
            rel="noopener"
            color={"#FFF"}
          >
            <Typography variant="body" component="div">
              {sourceText}
            </Typography>
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const VerticlePadding = () => {
  return <div style={{ minHeight: 10, maxHeight: 20, height: "3vh" }}></div>;
};

export default observer(
  ({ options, prompt, game, sourceText, sourceLink, facts }) => {
      const theme = useTheme();
      const [statsOpen, setStatsOpen] = useState(false);
      if (game) {
        game.onComplete = ({ win }) => {
          setTimeout(() => {
            setStatsOpen(true);
          }, 1100);
        };
      }
const useStyles = makeMuiStyles((theme, prefersDarkMode) => ({
    gameBackground: {
      position: 'sticky', top: 0, zIndex: 10,  backgroundColor: theme.palette.background.gameBoard, backdropFilter: 'blur(20px)', width: '100%'}
    }))
    const classes = useStyles()
    // component of entire CrossoverGridTrivia game board and keyboard
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: 'center',
          marginTop: 0,
          paddingTop: 0,
          maxWidth: "100%",
          width: "100%",
          height: '100vh',
        }}
      >
        <div className={classes.gameBackground}>
          <div style={{maxWidth: '500px', width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column', margin: 'auto'}}>
        <PromptBlock
          prompt={prompt}
          sourceText={sourceText}
          sourceLink={sourceLink}
          facts={facts}
        />
        <GameBoard game={game} facts={facts} setStatsOpen={setStatsOpen} />
        <VerticlePadding />
        </div>
        </div>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 250}}>
        <Keyboard
          options={options}
          game={game}
          onSelect={(option) => {
            game.onSelect(option);
          }}
          onBackspace={() => {
            game.onBackspace();
          }}
          onEnter={async () => {
            await game.onEnter();
          }}
        />
        </div>
        <StatisticsModal
            game={game}
            prompt={prompt}
            open={statsOpen}
            onClose={() => setStatsOpen(false)}
            statisticsPageText={" "}
            instagamLink={"instagamLink"}
          />
       
        <VerticlePadding />
      </div>
    );
  }
);
