import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React from "react";
import { AUTH_METHODS } from "../../constants";
import EmailLoginDialog from "./EmailLoginDialog";
import "./tailwind.css"; // Assuming TailwindCSS is imported via a CSS file

const SignInMenu = (props) => {
  const { anchorEl, onClose, open } = props;
  const [openEmailModal, setOpenEmailModal] = React.useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (option) => {
    if (option.provider === "email") {
      setOpenEmailModal(true);
    } else {
      window.location.href = option.authUrl;
    }
    onClose();
  };

  const googleSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 48 48" width="24" height="24">
      <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
    </svg>
  );

  const twitterSvg = (
    <svg className="icon" viewBox="0 0 24 24" width="24" height="24">
      <path d="M22.46 6c-.77.35-1.6.58-2.47.69a4.29 4.29 0 0 0 1.88-2.38c-.8.47-1.7.8-2.65.98a4.28 4.28 0 0 0-7.3 3.9 12.13 12.13 0 0 1-8.8-4.46 4.28 4.28 0 0 0 1.32 5.7 4.28 4.28 0 0 1-1.94-.54v.05c0 2 .95 3.67 2.39 4.7a4.28 4.28 0 0 1-1.93.07 4.29 4.29 0 0 0 4 2.98A8.58 8.58 0 0 1 2 18.54a12.1 12.1 0 0 0 6.56 1.93c7.88 0 12.2-6.54 12.2-12.2 0-.19 0-.38-.01-.57A8.65 8.65 0 0 0 24 5.64a8.64 8.64 0 0 1-2.54.7z"/>
    </svg>
  );

  const appleSvg = (
    <svg className="icon" viewBox="0 0 24 24" width="24" height="24">
      <path d="M18.84 13.08c-.04-3.22 2.64-4.79 2.75-4.86-1.51-2.22-3.84-2.52-4.66-2.56-1.98-.21-3.85 1.17-4.85 1.17s-2.54-1.15-4.17-1.12c-2.15.03-4.15 1.24-5.26 3.16-2.2 3.8-.56 9.41 1.57 12.48 1.04 1.53 2.27 3.24 3.89 3.17 1.58-.07 2.18-1.03 4.1-1.03 1.91 0 2.47 1.03 4.18 1 1.74-.03 2.85-1.54 3.88-3.09 1.1-1.57 1.56-3.1 1.59-3.19-.04-.02-3.05-1.18-3.09-4.66zM16.5 2.64c.85-1.03 1.43-2.45 1.27-3.87-1.23.05-2.72.82-3.59 1.85-.78.91-1.48 2.36-1.29 3.76 1.35.1 2.76-.68 3.61-1.74z"/>
    </svg>
  );

  const emailSvg = (
    <svg className="icon" viewBox="0 0 24 24" width="24" height="24">
      <path d="M12 12.713l11.449-7.564c.254-.167.55-.255.851-.255.309 0 .613.097.874.273.528.358.826.983.752 1.621v14.466c0 .811-.657 1.468-1.468 1.468h-22.064c-.81 0-1.468-.657-1.468-1.468v-14.466c-.073-.637.224-1.263.752-1.621.261-.176.565-.273.874-.273.301 0 .597.088.851.255l11.449 7.564zm0 2.196l-11.45-7.563v12.291h22.9v-12.291l-11.45 7.563z"/>
    </svg>
  );

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            backgroundColor: document.documentElement.classList.contains("dark")
              ? "#333"
              : "#fff",
            color: document.documentElement.classList.contains("dark")
              ? "#fff"
              : "#000",
          },
        }}
      >
        {AUTH_METHODS.OPTIONS.map((option) => (
          <MenuItem
            key={option.provider}
            onClick={() => handleListItemClick(option)}
            style={{
              color: document.documentElement.classList.contains("dark")
                ? "#fff"
                : "#000",
            }}
          >
            <ListItemIcon>
              {option.provider === "google" && googleSvg}
{/*               {option.provider === "twitter" && twitterSvg}
              {option.provider === "apple" && appleSvg} */}
              {option.provider === "email" && emailSvg}
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant="body1"
                fontFamily="Inter, sans-serif"
              >
                {option.label}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>

      <EmailLoginDialog
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
      />
    </>
  );
};

export default SignInMenu;
