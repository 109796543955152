import React, { useState, useEffect, useRef, memo } from "react";
import crossover_logo from "../../assets/nba.svg";

const MobileNavbar = () => {
  return (
    <nav
      className="navbar"
      style={{
        fontFamily: "Inter, sans-serif",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img src={crossover_logo} alt="Logo" className="navbar-logo" />
        <p
          style={{
            marginLeft: "10px",
            fontWeight: "bold", // Make the text bold
            fontSize: 20,
            marginTop: "0px",
            marginBottom: "0px",
          }}
        >
          CROSSOVER TRIVIA
        </p>
      </div>
      <a
        href="https://crossovergrid.com/"
        className="grid-link"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span role="img" aria-label="Grids" style={{ paddingRight: "5px" }}>
          📊
        </span>
        <span style={{ fontWeight: "bold" }}>Grids</span>
      </a>
    </nav>
  );
};

export default memo(MobileNavbar);
