import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
  } from "react";
  import Axios from "axios";
  
  import "../../App.scss";
  import pgaStyles2 from "./pga2.module.scss";
  
  import "../Discover/discover.scss";
  import { makeStyles as makeMuiStyles } from "@mui/styles";
  import { Link } from "react-router-dom";
  import crossover_logo from "../../assets/nba.svg";
  import opening_drive_logo from "../../assets/PGATOUR_Opening Drive_Logo_RGB[84][60][30] 2.png";
  import ReactGA from "react-ga";
  import { Analytics } from "@vercel/analytics/react";
  import { Button } from "@mui/material";
  import Twitter from "../../assets/twitter.svg";
  import TwitterWhite from "../../assets/twitter_white.svg";
  import useTheme from "@mui/material/styles/useTheme";
  import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
  import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
  import Box from "@mui/material/Box";
  import Typography from "@mui/material/Typography";
  import Modal from "@mui/material/Modal";
  import Dialog from "@mui/material/Dialog";
  import Divider from "@mui/material/Divider";
  import FlipNumbers from "../FlipNumbers/index";
  import {
    VerticalBarSeries,
    XYPlot,
    makeWidthFlexible,
    LabelSeries,
    XAxis,
    HorizontalGridLines,
  } from "react-vis";
  import sports from "../../sports";
  // import useDebounce from "../../utilities/debounce";
  import useThrottle from "../../utilities/throttle";
  
  <script defer src="/_vercel/insights/script.js"></script>;
  <script
    defer
    src="https://go.metabet.io/js/global.js?siteID=crossovergrid"
  ></script>;
  
  const SERVER_URL_NEW = "https://crossoverapi.com";
  const RARITY_SERVER_URL = "https://rarity.crossoverapi.com";
  const SEARCH_SERVER_URL = "https://cf.crossoverapi.com";
  // const SEARCH_SERVER_URL = "https://322e-71-219-22-1.ngrok-free.app";
  // const SERVER_URL_NEW = "https://5ded-71-219-22-1.ngrok-free.app";
  const FlexibleXYPlot = makeWidthFlexible(XYPlot);
  
  // let playerData = {};
  const Grid = ({
    sport_id,
    grid_id,
    page_name,
    emoji,
    twitter_handle,
    pgalogo,
    fallbackPlayerImage,
    metabet,
    sponsored = false,
    sponsor_logo,
  }) => {
    const theme = useTheme();
    const isDesktop = window.innerWidth >= 800;
    const maxLength = isDesktop >= 800 ? 30 : 18;
    // Initialize Google Analytics
    ReactGA.initialize("G-MHGRFQTFDL");
  
    // Record a pageview for the given page URL
  
    const searchInputRef = useRef();
    const setGameMode = (mode) => {
      localStorage.setItem("gameMode", mode);
      if (mode === "unlimited") {
        setIsUnlimitedMode(true);
      } else {
        setIsUnlimitedMode(false);
      }
    };
    const [showYesterdayLinks, setShowYesterdayLinks] = useState(false);
    const [showBonusLinks, setShowBonusLinks] = useState(false);
    const [showMainSportLinks, setShowMainSportLinks] = useState(false);
    const toggleYesterdayLinks = () => {
      setShowYesterdayLinks((prev) => !prev);
    };
  
    const toggleBonusLinks = () => {
      setShowBonusLinks((prev) => !prev);
    };
  
    const toggleMainSportLinks = () => {
      setShowMainSportLinks((prev) => !prev);
    };
  
    const [playerData, setPlayerData] = useState([]);
    const [boxElements, setBoxElements] = useState(Array(8).fill({}));
    const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [guessesRemaining, setGuessesRemaining] = useState(8);
    const [isGameOver, setIsGameOver] = useState(false);
    const [shareCopy, setShareCopy] = useState("");
    const [correctGuesses, setCorrectGuesses] = useState(Array(8).fill(false));
    const [rarities, setRarities] = useState(Array(8).fill(100));
    const [gridRank, setGridRank] = useState(null);
    const [totalGrids, setTotalGrids] = useState(null);
    const [usedPlayers, setUsedPlayers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [instructionsExpanded, setInstructionsExpanded] = React.useState(false);
    const [searchOpen, setSearchOpen] = React.useState(false);
    const [totalGridScore, setTotalGridScore] = React.useState(null);
    const [statsModalOpen, setStatsModalOpen] = React.useState(false);
    const [correctAnswerDistribution, setCorrectAnswerDistribution] =
      React.useState(Array(8).fill({ x: 0, y: 0 }));
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);
    const [isUnlimitedMode, setIsUnlimitedMode] = useState(false);
  
    const handleStatsOpen = () => setStatsModalOpen(true);
    const handleStatsClose = () => setStatsModalOpen(false);
  
    const [gridHeaders, setGridHeaders] = React.useState(null);
    const [answerKey, setAnswerKey] = React.useState({});
    const currentSportId = sports.find(
      (sport) => sport.id === sport_id.toString()
    );
    const currentSport = sports.find((sport) => sport.id === "16");
  
    const [rarityData, setRarityData] = React.useState([]);
    const [showGridHistory, setShowGridHistory] = useState(false);
  
    function abbreviateFullName(fullName) {
      const nameParts = fullName.split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts[nameParts.length - 1];
  
      let abbreviatedFirstName = "";
      if (firstName.length > 1) {
        abbreviatedFirstName = firstName.substr(0, 1) + ".";
      } else {
        abbreviatedFirstName = firstName;
      }
  
      let abbreviatedMiddleName = "";
      if (nameParts.length > 2) {
        const middleName = nameParts.slice(1, -1).join(" ");
        if (middleName.length > 1) {
          abbreviatedMiddleName = middleName.substr(0, 1) + ".";
        } else {
          abbreviatedMiddleName = middleName;
        }
      }
  
      return abbreviatedFirstName + " " + abbreviatedMiddleName + " " + lastName;
    }
    if (theme.palette.mode === "dark") {
      document.body.setAttribute("data-theme", "dark");
    } else {
      document.body.removeAttribute("data-theme");
    }
  
    const useStyles = makeMuiStyles((theme) => ({
      root: {
        flexGrow: 1,
        // padding: 2,  // 2 or '2px' or '2rem', as per your requirements
      },
      card: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      },
      media: {
        height: 0,
        paddingTop: "100%",
      },
      close: {
        position: "absolute",
        // right: 1,  // 1 or '1px' or '1rem', as per your requirements
        // top: 1,  // 1 or '1px' or '1rem', as per your requirements
        color: "#bdbdbd", // color from theme.palette.grey[500]
      },
      boxCard: {
        backgroundColor: theme.palette.background.gridCard,
        "&:hover": {
          backgroundColor: theme.palette.background.gridCardHover,
        },
      },
      boxCardSelected: {
        backgroundColor: theme.palette.background.gridCardSelect,
      },
      link: {
        color: theme.palette.text.link,
        fontSize: isDesktop ? "16px" : "12px",
        fontWeight: "600",
      },
      searchBox: {
        backgroundColor: theme.palette.background.blurCard,
        position: "relative",
        backdropFilter: "blur(10px)",
        color: theme.palette.text.primary,
      },
      searchInput: {
        color: theme.palette.text.primary,
      },
      result: {
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        cursor: "pointer",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: theme.palette.background.resultHover,
        },
      },
      sponsorshipLink: {
        marginTop: "20px",
        color: theme.palette.text.link,
        textDecoration: "none",
        borderBottom: `1px solid ${theme.palette.text.link}`,
      },
      sponsorshipLinkUnderline: {
        color: theme.palette.text.link,
        textDecoration: "none",
      },
      remainingGuesses: {
        position: "absolute",
        right: isDesktop ? "min(calc(90vw - 400px), 5vw)" : 0,
        top: "50%",
      },
      bars: {
        fontSize: "12px",
        textAnchor: "middle",
        fill: theme.palette.text.primary,
      },
      barLabelsXAxis: {
        fontSize: "14px",
        marginTop: "5px",
        fontWeight: "bold",
        textAnchor: "middle",
        fill: theme.palette.text.primary,
      },
    }));
  
    const classes = useStyles();
    function toggleVisibility(id) {
      const element = document.getElementById(id);
  
      if (element.style.display === "none") {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  
    useEffect(() => {
      Axios.get(`${SERVER_URL_NEW}/game/players/${sport_id}`, {})
        .then((response) => {
          setPlayerData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [sport_id]);
  
    useEffect(() => {
      if (!isUnlimitedMode && guessesRemaining === 0) {
        setIsGameOver(true);
        setStatsModalOpen(true);
        getGridRank();
      }
    }, [guessesRemaining]);
    useEffect(() => {
      if (!isUnlimitedMode && guessesRemaining === 0) {
        setIsGameOver(true);
      }
  
      if (isUnlimitedMode) {
        const correctCount = correctGuesses.filter(Boolean).length; // Counting the number of true values
        if (correctCount === 8) {
          setIsGameOver(true);
        }
      }
    }, [guessesRemaining, isUnlimitedMode, correctGuesses]);
    useEffect(() => {
      localStorage.setItem("isUnlimitedMode", JSON.stringify(isUnlimitedMode));
    }, [isUnlimitedMode]);
  
    useEffect(() => {
      if (isGameOver) {
        setStatsModalOpen(true);
        getGridRank();
      }
    }, [isGameOver]);
  
    const correctCount = correctGuesses.filter(Boolean).length;
  
    useEffect(() => {
      const shareCopyText = `${
        gridHeaders?.title || ""
      } ${emoji} @${twitter_handle}  ${correctCount} / 8 ${boxElements
        .map((id, index) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
        .join("")}`;
  
      setShareCopy(shareCopyText);
    }, [gridHeaders, emoji, twitter_handle, correctCount, boxElements]);
  
    useEffect(() => {
      if (
        isGameOver &&
        gridHeaders?.grid_id !== undefined &&
        rarityData.length > 1
      ) {
        calculateGridRank();
      }
    }, [boxElements]);
  
    useEffect(() => {
      setupGame();
    }, [sport_id, grid_id]);
  
    const setupGame = () => {
      Axios.get(`${SERVER_URL_NEW}/game/specific/${grid_id}`, {})
        .then((response) => {
          setGridHeaders(response.data);
          Axios.get(`${SERVER_URL_NEW}/game/answers/${response.data.grid_id}`, {})
            .then((r) => {
              setAnswerKey(r.data);
            })
            .catch((error) => {
              console.log(error);
            });
          Axios.get(
            `${SERVER_URL_NEW}/game/rarity/grid/${response.data.grid_id}`,
            {}
          )
            .then((res) => {
              setRarityData(res.data.player_distribution);
              console.log("RD: ", res.data.player_distribution);
              if (
                localStorage.getItem(`grid_id_${page_name}`) !=
                parseInt(response.data.grid_id)
              ) {
                localStorage.setItem(
                  `grid_id_${page_name}`,
                  response.data.grid_id
                );
                handleReset();
              } else {
                // find existing players in localStorage and reload them
                var updatedBoxElements = Array(8).fill({});
                var updatedCorrectGuesses = Array(8).fill(false);
                let currentGuesses = Array(8).fill({});
                currentGuesses =
                  JSON.parse(
                    localStorage.getItem(`player_guesses_${page_name}`)
                  ) || currentGuesses;
                for (var i = 0; i < currentGuesses.length; i++) {
                  if (Object.keys(currentGuesses[i]).length > 0) {
                    updatedBoxElements[i] = currentGuesses[i];
                    updatedCorrectGuesses[i] = true;
                  }
                }
                setBoxElements(updatedBoxElements);
                setCorrectGuesses(updatedCorrectGuesses);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
  
        .catch((error) => {
          console.log(error);
        });
      if (localStorage.getItem(`rank${page_name.toUpperCase()}`)) {
        setGridRank(localStorage.getItem(`rank${page_name.toUpperCase()}`));
        setTotalGrids(localStorage.getItem(`total${page_name.toUpperCase()}`));
      }
      if (!JSON.parse(localStorage.getItem("instructionsShown"))) {
        setModalOpen(true);
        localStorage.setItem("instructionsShown", JSON.stringify(true));
      }
    };
  
    useEffect(() => {
      const gr = localStorage.getItem(
        `guessesRemaining${page_name.toUpperCase()}`
      );
      const up = localStorage.getItem(`player_guesses_${page_name}`);
      if (up) {
        setUsedPlayers(JSON.parse(up));
      }
      if (gr) {
        setGuessesRemaining(gr);
        if (parseInt(gr) === 0) {
          setIsGameOver(true);
        } else {
          setIsGameOver(false);
        }
      } else {
        setIsGameOver(false);
      }
    }, [sport_id]);
  
    const setBoxStyle = (index) => {
      if (index === 0) {
        return { borderTopLeftRadius: "6px" };
      } else if (index === 3) { // Updated for 4 columns
        return { borderTopRightRadius: "6px" };
      } else if (index === 4) {
        return { borderBottomLeftRadius: "6px" };
      } else if (index === 7) { // Updated for 4 columns
        return { borderBottomRightRadius: "6px" };
      }
    };
  
    const setNameContainerStyle = (index) => {
      if (index === 4) {
        return { borderBottomLeftRadius: "6px" };
      } else if (index === 7) { // Updated for 4 columns
        return { borderBottomRightRadius: "6px" };
      }
    };
  
    const calculateRarityRatio = (player_rarity, player_id) => {
      if (!player_rarity || player_rarity?.length == 0) {
        return 100;
      }
      // Search for the playerID in the array of objects
      const playerStats = player_rarity?.find(
        (obj) => obj.player_id == player_id
      );
      // If no match found, return the ratio divided by the count of the first element
      if (!playerStats) {
        const firstPlayerStats = player_rarity[0];
        return firstPlayerStats.ratio / firstPlayerStats.count;
      }
  
      // Return the ratio value for the matched playerID
      return playerStats.ratio;
    };
    const calculateMinRarity = (index) => {
      if (
        !rarityData ||
        rarityData.length === 0 ||
        Object.keys(answerKey).length === 0
      ) {
        return;
      }
  
      const playerRarity = rarityData[index]?.player_rarity;
      if (!playerRarity || !Array.isArray(playerRarity)) {
        return; // Exit if playerRarity is undefined or not an array
      }
  
      const filteredRarity = playerRarity.filter((data) =>
        answerKey[`matches_box_${index + 1}`].includes(`${data.player_id}`)
      );
  
      if (!Array.isArray(filteredRarity) || filteredRarity.length === 0) {
        return; // Exit if filteredRarity is undefined or an empty array
      }
  
      const highestValue = Math.max(...filteredRarity.map((item) => item.ratio));
      const leastRare = filteredRarity.find(
        (item) => item.ratio === highestValue
      );
  
      return leastRare;
    };
  
    const calculateDeciles = (numbersArray) => {
      // Sort the numbers in ascending order
      const sortedNumbers = numbersArray.sort((a, b) => a - b);
  
      // Initialize arrays to store the threshold values and count of items in each decile
      const decileThresholds = [];
      const decileCounts = Array.from({ length: 10 }, () => 0);
  
      // Calculate the threshold values and count the items in each decile
      sortedNumbers.forEach((number, index) => {
        const decileIndex = Math.floor((index / sortedNumbers.length) * 10);
        decileCounts[decileIndex]++;
        if (!decileThresholds[decileIndex]) {
          decileThresholds[decileIndex] = number;
        }
      });
  
      return { decileThresholds, decileCounts };
    };
  
    const createBox = (row, col, isStats = false, gridType = "standard") => {
      // Calculate the box number based on row and column
      const box_number = (row - 1) * 4 + col; // Updated for 4 columns
      const index = box_number - 1;
  
      const playerRarity = rarityData[index]?.player_rarity;
  
      let playerID;
      let rarity;
      let hasBothTeams;
      let isPlayerUsed;
      let playerInfo;
      if (gridType == "standard") {
        playerID = boxElements[index]?.id;
        rarity = calculateRarityRatio(playerRarity, playerID);
        hasBothTeams = answerKey[`matches_box_${index}`]?.includes(playerID);
        isPlayerUsed = usedPlayers.includes(playerID);
        playerInfo = playerData.find((item) => item.id === playerID);
      } else if (gridType == "maxRarity") {
        playerInfo = calculateMinRarity(index);
        rarity = playerInfo?.rarity;
      }
  
      return (
        <div
          key={index}
          id={`box-${index}`}
          className={`box ${classes.boxCard} ${
            selectedBoxIndex === index ? classes.boxCardSelected : ""
          } ${hasBothTeams ? "" : ""} ${
            playerID ? `${pgaStyles2.green} correct` : ""
          } `}
          style={setBoxStyle(index)}
          onClick={() => {
            // Only allow selection if the player has not been used
            // TODO FIX
            if ((!isPlayerUsed && gridType == "standard") || isUnlimitedMode) {
              handleBoxClick(index);
            }
          }}
        >
          {/* THIS SETS THE WATERMARK FOR THE GRID - FEELS A LITTLE HACKY BUT WORKS
             WOULD BE NICE IF WE COULD USE A DIFFERENT APPROACH, WHICH WOULD ENABLE US
             TO USE OVERFLOW: HIDDEN ON BOXES IN THE GRID AND HELP WITH HEADSHOT SIZE */}
     
          {playerInfo && (
            <>
              <div className="player-box">
                <img
                  src={playerInfo && playerInfo.img_url}
                  alt={""}
                  className="player-image"
                  style={setBoxStyle(index)}
                  onError={(event) => {
                    // Replace the image source with the fallback image source
                    event.target.src = fallbackPlayerImage;
                  }}
                />
                <div
                  className="rarity"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    borderBottomRightRadius: "6px",
                    borderTopLeftRadius: index == 0 ? "6px" : "0px",
                  }}
                >
                  <Typography variant="caption">
                    {rarity !== 100 ? rarity?.toPrecision(2) + "%" : "1st"}
                  </Typography>
                </div>
  
                <div className="player-image-container"></div>
                <div
                  className="player-name-container"
                  style={{
                    ...setNameContainerStyle(index),
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <p
                    variant="caption"
                    className="player-name"
                    style={{ maxLines: 1, color: "white", padding: 0, margin: 0 }}
                  >
                    {playerInfo?.name?.length > maxLength
                      ? abbreviateFullName(playerInfo?.name)
                      : playerInfo?.name}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      );
  
      
    };
    const placeholderGrid = () => {
      return (
        <>
          <div className="row-header" />
          <div className={`box ${classes.boxCard}`} style={setBoxStyle(0)}>
    
          </div>
          <div className={`box ${classes.boxCard}`} />
          <div className={`box ${classes.boxCard}`} />
          <div className={`box ${classes.boxCard}`} />
  
          <div className="row-header" />
          <div className={`box ${classes.boxCard}`} />
          <div className={`box ${classes.boxCard}`} />
        </>
      );
    };
  
    const quizGrid = () => {
      let team_left = gridHeaders?.rows;
      let team_right = gridHeaders?.columns;
      return (
        <>
          <div className="quiz-header" />
          <div className="quiz-header" />
          <div className="quiz-header" />
          <div className="quiz-header" /> {/* Updated for 4 columns */}
          <div className="row-header" />
          <div className={`box ${classes.boxCard}`} style={setBoxStyle(0)}>
            {quizCard(
              1,
              team_left[0].img_url,
              team_right[0].img_url,
              team_left[0].name,
              team_right[0].name
            )}
          </div>
          <div className={`box ${classes.boxCard}`}>
            {quizCard(
              2,
              team_left[0].img_url,
              team_right[1].img_url,
              team_left[0].name,
              team_right[1].name
            )}
          </div>
          <div className={`box ${classes.boxCard}`}>
            {quizCard(
              3,
              team_left[0].img_url,
              team_right[2].img_url,
              team_left[0].name,
              team_right[2].name
            )}
          </div>
          <div className={`box ${classes.boxCard}`}>
            {quizCard(
              4,
              team_left[0].img_url,
              team_right[3].img_url,
              team_left[0].name,
              team_right[3].name
            )}
          </div>
  
          <div className="row-header" />
          <div className={`box ${classes.boxCard}`}>
            {quizCard(
              5,
              team_left[1].img_url,
              team_right[0].img_url,
              team_left[1].name,
              team_right[0].name
            )}
          </div>
          <div className={`box ${classes.boxCard}`}>
            {quizCard(
              6,
              team_left[1].img_url,
              team_right[1].img_url,
              team_left[1].name,
              team_right[1].name
            )}
          </div>
          <div className={`box ${classes.boxCard}`}>
            {quizCard(
              7,
              team_left[1].img_url,
              team_right[2].img_url,
              team_left[1].name,
              team_right[2].name
            )}
          </div>
          <div className={`box ${classes.boxCard}`}>
            {quizCard(
              8,
              team_left[1].img_url,
              team_right[3].img_url,
              team_left[1].name,
              team_right[3].name
            )}
          </div>
        </>
      );
    };
  
    const quizCard = (box_number, img_left, img_right, team_left, team_right) => {
      return (
        <Link
          // key={quiz.quiz_id}
          to={`../quiz/${gridHeaders?.grid_id}/${box_number}`}
          state={{
            sport_id: sport_id,
            team_left: team_left,
            team_right: team_right,
            img_left: img_left,
            img_right: img_right,
          }}
          className="quiz-card"
          style={{ padding: "2.5px", borderRadius: 0 }}
        >
          <div className="diagonal-container-small">
            <div className="diagonal-left">
              {img_left ? ( // Render image if img_left is provided
                <>
              <img
                src={img_left}
                loading="lazy"
                // src={require("../../assets/nba_silhouette.png")}
                alt="Left"
                className="left-image"
                style={{
                  width: "55%",
                  height: "70%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 200,
                  objectFit: "contain",
                }}
              />
              <img
                src={img_left}
                // src={require("../../assets/nba_silhouette.png")}
                alt="Left"
                className="left-image-background"
                style={{ opacity: 0.25 }}
                loading="lazy"
              />
                </>
              ) : (
                <span className="team-text">{team_left}</span> // Display team text if img_left is not provided
              )}
            </div>
            <div className="diagonal-right">
              {img_right ? ( // Render image if img_right is provided
                <>
              <img
                src={img_right}
                // src={require("../../assets/nba_silhouette.png")}
                alt="Right"
                className="right-image-background"
                style={{ opacity: 0.25 }}
                loading="lazy"
              />
              <img
                src={img_right}
                // src={require("../../assets/nba_silhouette.png")}
                alt="Right"
                className="right-image"
                style={{
                  width: "55%",
                  height: "70%",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  zIndex: 200,
                  objectFit: "contain",
                }}
                loading="lazy"
              />
                </>
              ) : (
                <span className="team-text">{team_right}</span> // Display team text if img_right is not provided
              )}
            </div>
            {/* <div
                style={{
                  ...setNameContainerStyle(box_number - 1),
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "#00000080",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  backdropFilter: "blur(5px)",
                }}
              >
                <Typography
                  style={{
                    marginLeft: "10px",
                    color: "#ffffff",
                    fontWeight: "600",
                    fontSize: isDesktop ? "14px" : "12px",
                  }}
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                  }}
                >
                  {`${team_left} & ${team_right}`}
                </Typography>
              </div> */}
          </div>
        </Link>
      );
    };
    function resizeText() {
      const diagonalContainers = document.querySelectorAll(
        ".diagonal-container-small"
      );
  
      diagonalContainers.forEach((container) => {
        const teamText = container.querySelector(".team-text");
  
        // Check if the teamText element exists
        if (teamText) {
          const containerWidth = container.offsetWidth;
          const containerHeight = container.offsetHeight;
  
          // Calculate the maximum font size based on the container's dimensions
          const maxWidth = containerWidth * 0.8; // Use 80% of the container's width
          const maxHeight = containerHeight * 0.8; // Use 80% of the container's height
  
          // Measure the width and height of the text element
          const textWidth = teamText.offsetWidth;
          const textHeight = teamText.offsetHeight;
  
          // Calculate the font size that fits both width and height
          let fontSize =
            Math.min(maxWidth / textWidth, maxHeight / textHeight) * 16; // 16px is the default font size
  
          // Apply the calculated font size
          teamText.style.fontSize = `${fontSize}px`;
        }
      });
    }
  
    const createHeader = (text, isRowHeader, isStats = false) => {
      const sport_id = 16; // Set the sport ID to 16
      const selectedSport = sports.find(
        (sport) => sport.id === sport_id.toString()
      );
  
      if (text === "" && !isRowHeader) {
        return (
          <div className="header">
            {selectedSport && (
              <div className="sport-card-top-left">
                <Link
                  className={`sportLinkTopLeft ${
                    page_name === selectedSport.id ? "selected" : ""
                  }`}
                  to={selectedSport.path}
                >
                  {selectedSport.emoji}
                  <br />
                  {selectedSport.name.length < 4 ? (
                    <span>{selectedSport.name}</span>
                  ) : (
                    <span style={{ fontSize: 12 }}>{selectedSport.name}</span>
                  )}
                </Link>
              </div>
            )}
          </div>
        );
      }
  
      let teamData;
      if (isRowHeader) {
        teamData = gridHeaders.rows[text - 1];
      } else {
        teamData = gridHeaders.columns[text - 1];
      }
  
      return (
        <div className={isRowHeader ? "row-header" : "header"}>
          {teamData.type == 2 ? (
            <p className={isStats ? "rowHeaderTextStats" : "rowHeaderText"}>
              {teamData.text}
            </p>
          ) : (
            <span style={{ textAlign: "center" }}>
              <img
                src={teamData.img_url}
                alt={teamData.name}
                className={isStats ? "team-logo-stats" : "team-logo"}
                // style={{ width: "100%" }}
              />
            </span>
          )}
        </div>
      );
    };
    const createGrid = (isStats = false, gridType = "standard") => {
      const grid = [];
  
      for (let i = 0; i < 3; i++) {
        // Change this to 3 for 3 rows
        for (let j = 0; j < 5; j++) {
          // Change this to 3 for 3 columns
          if (i === 0 && j === 0) {
            grid.push(createHeader("", false, isStats));
          } else if (i === 0) {
            // These are column headers
            grid.push(createHeader(j, false, isStats));
          } else if (j === 0) {
            // These are row headers
            grid.push(createHeader(i, true, isStats));
          } else {
            switch (gridType) {
              case "standard":
                grid.push(createBox(i, j, isStats));
                break;
              case "maxRarity":
                grid.push(createBox(i, j, isStats, gridType));
              default:
                break;
            }
          }
        }
      }
  
      return grid; // Don't forget to return the grid
    };
  
    const handleBoxClick = (index) => {
      // If the game is over, the box already has a correct answer, or the player has already been used, do nothing
      if (
        isGameOver ||
        (!isUnlimitedMode && guessesRemaining <= 0) || // <-- Modified this line
        boxElements[index].keys?.length > 0 ||
        usedPlayers.includes(boxElements[index])
      ) {
        return;
      }
      // Otherwise open search
      setSelectedBoxIndex(index);
      openPopup();
      setSearchInput("");
      setSearchResults([]);
      // Don't display search results until user starts typing
      setSearchResults([]);
    };
  
    const openPopup = () => {
      setSearchOpen(true);
      // searchInputRef.current.focus();
    };
  
    const closePopup = () => {
      // const popup = document.getElementById("popup");
      // popup.style.display = "none";
      setSearchOpen(false);
      setSelectedBoxIndex(null);
    };
  
    let ac = undefined;
  
    const debouncedRequest = useThrottle(async () => {
      // cancel any in-flight searches
      ac?.abort();
      const abortController = new AbortController();
      ac = abortController;
      let sr = await Axios.get(
        `${SEARCH_SERVER_URL}/game/fuse/search/players?sportId=${sport_id}&name=${searchInput}`,
        {
          signal: ac.signal,
        }
      ).catch(function (e) {
        if (Axios.isCancel(e)) {
          console.log("CANCELING");
          console.error(e.message);
          return;
        } else {
          // handle HTTP error...
          return;
        }
      });
      if (sr) {
        console.log(sr);
        setSearchResults(sr.data);
      }
    });
  
    const handleInputChange = async (event) => {
      const input = event.target.value;
      setSearchInput(input);
      // if (input == "") {
      // setSearchResults([]);
      // } else {
      debouncedRequest();
      // }
  
      // if (input.trim().length > 1) {
      // const sanitizedInput = removeAccents(input.toLowerCase()).replace(
      //   /[~']/g,
      //   ""
      // );
      // const filteredResults = playerData.filter((item) => {
      // const sanitizedPlayerName = removeAccents(
      //   item.name.toLowerCase()
      // ).replace(/[~']/g, "");
      // console.log(sanitizedPlayerName);
      //   item.name.toLowerCase().includes(sanitizedInput);
      // });
      // const filteredResults = playerData.filter((item) =>
      //   removeAccents(item.name.toLowerCase()).includes(
      //     removeAccents(input.toLowerCase())
      //   )
      // );
  
      // setSearchResults(results);
      // } else {
      // setSearchResults([]);
      // }
    };
  
    const handleResultsClick = (player) => {
        // Check if the player has already been used
        if (usedPlayers.includes(player.id)) {
          return;
        }
          // Get selected box index
      const hasBothTeams = answerKey[
        `matches_box_${selectedBoxIndex + 1}`
      ].includes(player.id);
  
      // Check if the player played for both teams
      // const hasBothTeams = boxTeams.every((team) => playerTeams.includes(team));
      // if the guess is correct
      if (hasBothTeams) {
        const updatedBoxElements = [...boxElements];
        updatedBoxElements[selectedBoxIndex] = player;
        setBoxElements(updatedBoxElements);
  
        const updatedCorrectGuesses = [...correctGuesses];
        updatedCorrectGuesses[selectedBoxIndex] = true;
        setCorrectGuesses(updatedCorrectGuesses);
  
        // save the team names to storage, to reset players on team change
        localStorage.setItem(
          `player_guesses_${page_name}`,
          JSON.stringify(updatedBoxElements)
        );
  
        // ADD ONE TO SELECTED BOX INDEX BECAUSE THE BOX NUMBERS START AT 1
        // (instead of index which starts at 0)
        saveGuess(player.id, selectedBoxIndex + 1);
      } else {
        let updatedGuessesRemaining = guessesRemaining - 1;
        setGuessesRemaining(updatedGuessesRemaining);
        localStorage.setItem(
          `guessesRemaining${page_name.toUpperCase()}`,
          updatedGuessesRemaining
        );
      }
  
      closePopup();
      // Update the used players state
      setUsedPlayers((prevUsedPlayers) => [...prevUsedPlayers, player.id]);
    };
  
    
    // save the guess to the database
    const saveGuess = (player_id, box_number) => {
      // var team1 = boxTeams[0];
      // var team2 = boxTeams[1];
      Axios.post(`${SERVER_URL_NEW}/game/guess`, {
        grid_id: gridHeaders.grid_id,
        player_id: parseInt(player_id),
        box_number: box_number,
      })
        .then((response) => {
          let updatedGuessesRemaining = guessesRemaining - 1;
          setGuessesRemaining(updatedGuessesRemaining);
          localStorage.setItem(
            `guessesRemaining${page_name.toUpperCase()}`,
            updatedGuessesRemaining
          );
 Axios.get(
          `${RARITY_SERVER_URL}/game/rarityLive/grid/${gridHeaders.grid_id}`,
          {}
        )
            .then((res) => {
              if (res.data?.player_distribution) {
                setRarityData(res.data.player_distribution);
              }
            })
            .catch((error) => {
              console.log(error);
              console.log("ERROR FETCHING RARITY");
            });
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    const getScoreForGrid = (answers, rarities) => {
      let total_score = 0;
      for (var j = 0; j < answers.length; j++) {
        const rarity_data = rarities[j]?.player_rarity?.find((obj) => {
          return parseInt(obj.player_id) == parseInt(answers[j]);
        });
        if (rarity_data) {
          const box_score = parseFloat(rarity_data.ratio);
          total_score += box_score;
        } else {
          total_score += 100;
        }
      }
      return total_score;
    };
  
    // get the grid rank
    const getGridRank = () => {
      // Get an array of player_id's for the answers
      const answers = boxElements.map((obj) =>
        obj.hasOwnProperty("id") ? obj.id : null
      );
      // save the grid to the DB, with a grid_id and the array of their answers
      Axios.post(`${SERVER_URL_NEW}/game/grid/save`, {
        grid_id: gridHeaders ? gridHeaders.grid_id : null,
        answers: answers,
        url: window.location,
      })
        .then((response) => {
          // see where they are in the overall rankings
          calculateGridRank();
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    const calculateGridRank = () => {
      // FETCH ALL THE OTHER GRID SCORES,
      Axios.get(
        `${RARITY_SERVER_URL}/game/grid/distribution/${gridHeaders.grid_id}`,
        {}
      ).then((res) => {
        setCorrectAnswerDistribution(res.data);
      });
      Axios.get(
        `${RARITY_SERVER_URL}/game/rankings/${gridHeaders.grid_id}`,
        {}
      ).then((sorted_array) => {
        // Get an array of player_id's for the answers
        const answers = boxElements.map((obj) =>
          obj.hasOwnProperty("id") ? obj.id : null
        );
        // Calculate the user's score the same way we would in the API
        let grid_score = getScoreForGrid(answers, rarityData);
        setTotalGridScore(grid_score);
        //see where the user's score would fall in this list
        const rank = findIndexInSortedArray(sorted_array.data, grid_score);
        setGridRank(rank);
        setTotalGrids(sorted_array.data.length + 1);
        // IDK if we actually need to do this anymore
        localStorage.setItem(`rank${page_name.toUpperCase()}`, rank);
        localStorage.setItem(
          `total${page_name.toUpperCase()}`,
          sorted_array.data.length + 1
        );
      });
    };
  
    const findIndexInSortedArray = (sortedArray, value) => {
      const index = sortedArray.findIndex((num) => num >= value);
      return index === -1 ? sortedArray.length : index;
    };
    const handleCopyClick = () => {
      // Calculate the correctCount
      const correctCount = correctGuesses.filter(Boolean).length;
  
      const gridText = [
        boxElements
          .slice(0, 4) // First row (4 elements)
          .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
          .join(" "),
        boxElements
          .slice(4, 8) // Second row (4 elements)
          .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
          .join(" "),
      ].join("\n");
  
      // Update the share text to reflect 2x2 grid (4 total guesses)
      const shareCopyText = `${
        gridHeaders?.title || ""
      } ${emoji} @${twitter_handle} ${correctCount} / 8:\n\n${gridText}`;
  
      navigator.clipboard.writeText(shareCopyText);
    };
    const handleTweetClick = () => {
      const correctCount = correctGuesses.filter(Boolean).length;
      const gridText = [
        boxElements
          .slice(0, 4) // First row (4 elements)
          .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
          .join(" "),
        boxElements
          .slice(4, 8) // Second row (4 elements)
          .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
          .join(" "),
      ].join("\n");
  
      // Update the tweet text to reflect 2x2 grid (4 total guesses)
      const tweetText = `${
        gridHeaders?.title || ""
      } ${emoji} @${twitter_handle} ${correctCount} / 8:\n\n${gridText}`;
      const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        tweetText
      )}`;
      window.open(tweetUrl, "_blank");
    };
  
    const handleReset = () => {
      localStorage.removeItem(`player_guesses_${page_name}`);
      setGuessesRemaining(8);
      localStorage.setItem(`guessesRemaining${page_name.toUpperCase()}`, 4);
      localStorage.removeItem(`boxElements${page_name.toUpperCase()}`);
      localStorage.removeItem(`correctGuesses${page_name.toUpperCase()}`);
      localStorage.removeItem(`usedPlayers${page_name.toUpperCase()}`);
      localStorage.removeItem(`lastResetTime${page_name.toUpperCase()}`);
      localStorage.removeItem(`rank${page_name.toUpperCase()}`);
      localStorage.removeItem(`total${page_name.toUpperCase()}`);
      localStorage.removeItem(`total${page_name.toUpperCase()}`);
      setBoxElements(Array(8).fill({}));
      setCorrectGuesses(Array(8).fill(false));
      setUsedPlayers([]);
      setIsGameOver(false);
      setupGame();
      setGameMode();
    };
  
    const renderRemainingGuesses = () => {
      if (isGameOver && !isUnlimitedMode) {
        return (
          <div className="game-over-container">
            <div>
              <Typography align="center">
                <b>Score:</b>
                {totalGridScore > 0 &&
                  ` ${Math.round(totalGridScore * 100) / 100}`}
                <br />
                <b>Rank:</b>
                {gridRank !== undefined && totalGrids !== undefined
                  ? ` ${(parseInt(gridRank) + 1).toLocaleString()}/${(
                      parseInt(totalGrids) + 2
                    ).toLocaleString()}`
                  : "..."}
              </Typography>
            </div>
            <div className="remaining-guesses">Try again tomorrow!</div>
          </div>
        );
      }
  
      if (isUnlimitedMode) {
        return (
          <div className="remaining-guesses" style={{ textAlign: "center" }}>
            <Typography align="center">
              {totalGridScore !== null &&
                totalGridScore !== undefined &&
                totalGridScore > 0 && (
                  <>
                    <b>Score:</b>
                    {` ${Math.round(totalGridScore * 100) / 100}`}
                    <br />
                  </>
                )}
              {gridRank !== null &&
                gridRank !== undefined &&
                totalGrids !== null &&
                totalGrids !== undefined && (
                  <>
                    <b>Rank:</b>
                    {` ${(parseInt(gridRank) + 1).toLocaleString()}/${(
                      parseInt(totalGrids) + 2
                    ).toLocaleString()}`}
                  </>
                )}
            </Typography>
            <span>Unlimited Guesses Mode</span>
          </div>
        );
      }
  
      return (
        <div className="remaining-guesses" style={{ textAlign: "center" }}>
          <span>Guesses Remaining</span>
          <div style={{ height: "8px" }} />
          {
            <FlipNumbers
              height={38}
              width={100}
              background="transparent"
              play
              perspective={100}
              numberStyle={{ fontWeight: "normal", fontSize: "20px" }}
              duration={1}
              delay={0}
              numbers={`${guessesRemaining}`}
            />
          }
        </div>
      );
    };
  
    const chartdata = [
      {
        name: "Amphibians",
        "Number of threatened species": 2488,
      },
      {
        name: "Birds",
        "Number of threatened species": 1445,
      },
      {
        name: "Crustaceans",
        "Number of threatened species": 743,
      },
    ];
  
    const renderStats = () => {
      if (isGameOver) {
        return (
          <Dialog
            open={statsModalOpen}
            onClose={handleStatsClose}
            scroll={"paper"}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ boxShadow: 24, margin: 0, padding: "5px" }}
            fullWidth={true}
            maxWidth="sm"
          >
            {/* <DialogContent> */}
            <div style={{ marginTop: 0, padding: 0 }}>
              <div
                style={
                  {
                    // backgroundImage: `url(${backgroundImage})`,
                    // backgroundSize: "100%",
                    // backgroundRepeat: "no-repeat",
                  }
                }
              >
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  sx={{
                    fontWeight: "700",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  Thanks for Playing
                </Typography>
  
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, fontSize: 14, textAlign: "center" }}
                >
                  <Typography sx={{ fontSize: "40px", lineHeight: "48px" }}>
                    {boxElements
                      .slice(0, 4) // First row (4 elements)
                      .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
                      .join(" ")}
                    <br />
                    {boxElements
                      .slice(4, 8) // Second row (4 elements)
                      .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
                      .join(" ")}
                    <br />
                    {/* {boxElements
                      .slice(6)
                      .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
                      .join(" ")} */}
                  </Typography>
                  <Typography align="center" sx={{ marginTop: "10px" }}>
                    {emoji} {gridHeaders?.title || ""}
                  </Typography>
                  <div className="share-copy-modal">
                    <Typography variant="caption" align="center">
                      @{twitter_handle}{" "}
                      <b>{correctGuesses?.filter(Boolean).length} / 8</b>
                    </Typography>
                  </div>
                  <div className="game-over-container-popup">
                    <div />
                    <div>
                      <Typography align="center">
                        <b>Score</b>
                        {totalGridScore > 0 && (
                          <FlipNumbers
                            height={16}
                            width={12}
                            // color="white"
                            background="transparent"
                            play
                            perspective={100}
                            // numberStyle={{ height: "26px" }}
                            duration={1}
                            delay={0}
                            numbers={`${Math.round(totalGridScore * 100) / 100}`}
                          />
                        )}
                      </Typography>
                    </div>
                    {/* <div style={{ width: "50px" }} /> */}
                    <div>
                      <Typography align="center">
                        <b>Rank</b>
                        {totalGridScore > 0 && (
                          <FlipNumbers
                            height={16}
                            width={12}
                            // color="white"
                            background="transparent"
                            play
                            perspective={100}
                            // numberStyle={{ height: "26px" }}
                            duration={1}
                            delay={0}
                            numbers={
                              gridRank !== undefined && totalGrids !== undefined
                                ? `${(parseInt(gridRank) + 1).toLocaleString()}`
                                : "..."
                            }
                            nonNumberStyle={{ padding: 0 }}
                          />
                        )}
                      </Typography>
                    </div>
                    <div />
                  </div>
                  <div className="remaining-guesses">Try again tomorrow!</div>
                  {isGameOver && (
                    <div className="share-section">
                      <div className="share-copy"></div>
  
                      <div style={{ flexDirection: "row", marginTop: "10px" }}>
                        <button
                          className="share-button"
                          onClick={handleCopyClick}
                        >
                          Copy Grid
                        </button>
                        <button
                          className="share-button"
                          onClick={handleTweetClick}
                        >
                          Tweet Grid
                        </button>
                      </div>
                      <div style={{ height: "20px" }} />
                    </div>
                  )}
                </Typography>
              </div>
            </div>
            <Divider />
            <Typography
              id="modal-modal-title"
              variant="h8"
              sx={{
                fontWeight: "700",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              🌟 Crossover Trivia 🌟
            </Typography>
            <Typography
              variant="body"
              sx={{
                textAlign: "center",
                marginTop: "10px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              Ready for the ultimate challenge? Pick a box below and try to guess
              every player, or see which ones slipped your mind.
            </Typography>
            <div className={pgaStyles2.statsGrid}>
              {gridHeaders?.columns?.length > 1 && quizGrid()}
            </div>
            <Divider sx={{ marginTop: "30px" }} />
            <Typography
              id="modal-modal-title"
              variant="h8"
              sx={{
                fontWeight: "700",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              Today's Top Picks
            </Typography>
            <div
              className={pgaStyles2.statsGrid}
              style={{ paddingBottom: "80px" }}
            >
              {gridHeaders?.columns?.length > 1 &&
              // playerData.length > 0 &&
              rarityData != undefined &&
              rarityData.length > 0
                ? createGrid(true, "maxRarity")
                : placeholderGrid()}
            </div>
            <Divider sx={{ marginTop: "30px" }} />
            <div
              style={{
                width: "100%",
                position: "relative",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h8"
                sx={{
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Player Scores
              </Typography>
              <FlexibleXYPlot
                xType="ordinal"
                height={350}
                style={{
                  overflow: "visible",
                  // marginTop: "30px",
                  // transform: "translateX(-20px)",
                }}
                margin={{ left: 0, right: 0, top: 30 }}
              >
                <XAxis className={classes.barLabelsXAxis} />
                <HorizontalGridLines />
  
                <VerticalBarSeries
                  barWidth={0.005}
                  // width={600}
                  height={320}
                  fill="#A2E5B2"
                  stroke="#A2E5B2"
                  strokeOpacity={0.5}
                  style={{
                    strokeLinejoin: "round",
                    strokeLinecap: "round",
                    strokeWidth: 8,
                    overflow: "visible",
                    postion: "absolute",
                    left: 0,
                    bottom: 0,
                  }}
                  data={correctAnswerDistribution}
                />
                <Divider sx={{ marginLeft: "20px", marginRight: "20px" }} />
                <LabelSeries
                  data={correctAnswerDistribution.map((d) => ({
                    x: d.x,
                    y: d.y,
                    label: d.y.toLocaleString(), // Display the Y value as the label
                    yOffset: -15, // Adjust this value to control the distance of the label above the bar
                  }))}
                  labelAnchorX="middle" // Align the label to the middle of the bar
                  labelAnchorY="end" // Position the label above the bar
                  className={classes.barLabels}
                />
              </FlexibleXYPlot>
              <Typography
                id="modal-modal-title"
                sx={{
                  marginTop: "15px",
                  marginBottom: "20px",
                  fontSize: "11px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                SCORE
              </Typography>
            </div>
          </Dialog>
        );
      }
    };
  
    return (
      <div className="app">
        {/* <img
         src={retro_bg}
         width={"100%"}
         style={{
           position: "absolute",
           bottom: 0,
           left: 0,
           zIndex: -1,
           opacity: 0.1,
         }}
       /> */}
        <nav className="navbar">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
  
            <div
              style={{
                flexDirection: "row",
              }}
            >
              <a
                className={pgaStyles2.twitter}
                href={`https://twitter.com/${twitter_handle}`}
                target="_blank"
              >
                <img
                  src={theme.palette.mode == "dark" ? TwitterWhite : Twitter}
                  width={isDesktop ? 30 : 25}
                  style={{ filter: "invert(0)" }}
                  alt="Twitter Logo"
                />
              </a>
              {isGameOver && (
                <InsertChartOutlinedIcon
                  style={
                    isDesktop
                      ? {
                          height: "30px",
                          width: "30px",
                          marginLeft: 10,
                          cursor: "pointer",
                        }
                      : { height: "25px", width: "25px", marginLeft: 10 }
                  }
                  onClick={() => setStatsModalOpen(true)}
                />
              )}
  
              <HelpOutlineIcon
                style={
                  isDesktop
                    ? {
                        height: "30px",
                        width: "30px",
                        marginLeft: 10,
                        cursor: "pointer",
                      }
                    : { height: "25px", width: "25px", marginLeft: 10 }
                }
                onClick={() => handleOpen()}
              />
            </div>
          </div>
        </nav>
        <div className="sport-picker">
          <div className="links" style={{ marginBottom: 10 }}>
            {currentSport.gridHistory.map((grid, index) => (
              <Link
                key={index}
                to={grid.href}
                className={`sportLink ${classes.link} ${
                  page_name === grid.gridId ? "selected" : ""
                }`}
                // onClick={() => onAdRefresh()}
              >
                {grid.emoji}
                <br />
                {grid.gridId}
              </Link>
            ))}
          </div>
          <Typography variant="caption">
            Powered by:{" "}
            <a href="https://crossovergrid.com" target="_blank">
              crossovergrid.com
            </a>
          </Typography>
        </div>
  
        {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            
        </div> */}
  
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "98%",
              maxWidth: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              maxHeight: "90%",
              overflow: "auto",
              ":focus": {
                outline: "none",
              },
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ fontWeight: "700" }}
            >
              How to Play
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 14 }}>
              Your goal is to identify a specific player for each grid cell,
              following the rules defined by the row and column of that cell.
              {instructionsExpanded && (
                <div>
                  <p style={{ marginBottom: 8 }}>
                    A player qualifies for a team if they've played at least one
                    NBA regular season game for that team. For NFL they must have
                    played in a Regular Season game for the team or be on their
                    current roster.
                  </p>
                  <p style={{ marginBottom: 8 }}>
                    If a cell corresponds to a team and an award, they could have
                    received the award on any team.
                  </p>
                  <p style={{ marginBottom: 8 }}>
                    If a cell refers to a team and a specific season stat, choose
                    a player who achieved that stat during any time with any team.
                  </p>
                  <p style={{ marginBottom: 8 }}>
                    When selecting a player for a cell with a rate stat (like FG%,
                    FT%, etc.), ensure the player has recorded this stat over an
                    entire season and they qualify for the leaderboard.
                  </p>
                  <p style={{ marginBottom: 8 }}>
                    Each player can only be chosen once.
                  </p>
                  <p style={{ marginBottom: 8 }}>
                    You have the chance to make 9 guesses in total to fill in the
                    grid.
                  </p>
                  <p style={{ marginBottom: 8 }}>
                    Every guess you make, whether right or wrong, will count as
                    one of your 9 guesses.
                  </p>
                  <p style={{ marginBottom: 8 }}>
                    Players can be either active or retired NBA professionals.
                  </p>
                  <p style={{ marginBottom: 8 }}>
                    Expect a fresh grid every day.
                  </p>
                </div>
              )}
            </Typography>
            {!instructionsExpanded && (
              <Button
                variant="outlined"
                sx={{ mt: 2, mr: 2 }}
                onClick={() => setInstructionsExpanded(true)}
              >
                Learn More
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              color="primary"
              onClick={() => handleClose()}
            >
              Let's Go
            </Button>
          </Box>
        </Modal>
        {/* <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "0px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: "500px",
              borderRadius: "16px", // Increased border-radius for the container
              boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)", // Added a more prominent shadow
            }}
          ></div>
        </Modal> */}
  
  <div className="grid-container">
        <div className={pgaStyles2.grid}>
          <img
            src={pgalogo}
            className={pgaStyles2.pgalogo}
            alt="PGA Logo"
          />
          {gridHeaders?.columns?.length > 1 &&
          playerData.length > 0 &&
          rarityData != undefined
            ? createGrid()
            : placeholderGrid()}
        </div>
      </div>
      
        {renderRemainingGuesses()}
        <Modal
          open={searchOpen}
          onClose={() => setSearchOpen(false)}
          autoFocus={false}
          sx={{ touchAction: "none" }}
        >
          <Box
            sx={{
              maxHeight: "50%",
              width: "90%",
              maxWidth: "550px",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              boxShadow: 3,
            }}
            // sx={{ maxHeight: "90%" }}
            className={`popup-content ${classes.searchBox}`}
            // onClick={(e) => e.stopPropagation()}
          >
            {/* <span className="close-button" onClick={() => setSearchOpen(false)}>
             &times;
           </span> */}
  
            <input
              ref={searchInputRef}
              autoFocus={true}
              type="text"
              className={`search-input ${classes.searchInput}`}
              value={searchInput}
              onChange={handleInputChange}
              placeholder="Search player..."
              style={{
                zIndex: 11,
                position: "sticky",
                backgroundColor: "transparent",
                // color: "#ffffff",
                fontWeight: "700",
                top: 0,
              }}
            />
            {searchResults.length > 0 && <Divider />}
            <div style={{ overflow: "auto" }}>
              <div className="results">
                {searchResults.length > 0 && (
                  <p
                    style={{
                      fontSize: 11,
                      marginLeft: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Results
                  </p>
                )}
                {searchResults.map((result) => (
                  <div
                    key={result.refIndex}
                    className={classes.result}
                    onClick={() => handleResultsClick(result.item)}
                  >
                    <span>{result.item.name}</span>
                    <span style={{ fontSize: "12px" }}>
                      {result.item.seasons || ""}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Box>
        </Modal>
        {renderStats()}
        {/* {isGameOver && (
         <div className="share-section">
           <div className="share-copy">
             <Typography sx={{ lineHeight: "20px" }}>
               {boxElements
                 .slice(0, 3)
                 .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
                 .join(" ")}{" "}
               <br />
               {boxElements
                 .slice(3, 6)
                 .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
                 .join(" ")}
               <br />
               {boxElements
                 .slice(6)
                 .map((id) => (Object.keys(id).length !== 0 ? "🟩" : "⬜️"))
                 .join(" ")}
             </Typography>
           </div>
           <div className="share-copy" style={{ marginTop: "10px" }}>
             <Typography>
               {gridHeaders?.title || ""} {emoji}
             </Typography>
           </div>
           <div className="share-copy">
             <Typography variant="caption">
               @{twitter_handle}{" "}
               <b>{correctGuesses?.filter(Boolean).length} / 9</b>
             </Typography>
           </div>
           <div style={{ flexDirection: "row", marginTop: "10px" }}>
             <button className="share-button" onClick={handleCopyClick}>
               Copy Grid
             </button>
             <button className="share-button" onClick={handleTweetClick}>
               Tweet Grid
             </button>
           </div>
         </div>
       )} */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={handleReset}
            style={{ border: "none", background: "transparent" }}
          >
            <p className={`${classes.link} myLink`}>🔄 Start over</p>
          </button>
          {!isGameOver && (
            <button
              onClick={() => {
                setIsUnlimitedMode((prev) => {
                  const newMode = !prev ? "unlimited" : "standard";
                  localStorage.setItem("gameMode", newMode);
                  return !prev;
                });
              }}
              style={{ border: "none", background: "transparent" }}
            >
              <p className={`${classes.link} myLink`}>
                {isUnlimitedMode ? "8 Guesses" : "♾️ Unlimited Guesses"}
              </p>
            </button>
          )}
        </div>
        <footer style={{ marginBottom: 150 }}></footer>
      </div>
    );
  };
  
  export default Grid;
  