import React from "react";

const RecoverForm = ({ errors }) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          Email Address
        </label>
        <input
          autoFocus
          required
          id="email"
          name="email"
          type="email"
          placeholder="Email Address"
          className="w-full p-2 mt-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-100 text-gray-900"
        />
        {errors.email && <div className="text-red-500 text-sm mt-1">{errors.email}</div>}
      </div>
      {errors.general && <div className="text-red-500 text-sm mt-1">{errors.general}</div>}
    </div>
  );
};

export default RecoverForm;
