export const ContentScoring = () => {
  return (
    <div>
      <h3>Scoring</h3>
      <p><strong>Initial Selection (30 Players):</strong></p>
      <p>No points are awarded for the initial selection.</p>
      <p><strong>After 70 to 50 Cut:</strong></p>
      <ul>
        <li><strong>Correct Pick (Top 50 Players):</strong> 1 point for each player correctly picked to advance to the top 50.</li>
      </ul>
      <p><strong>After 50 to 30 Cut:</strong></p>
      <ul>
        <li><strong>Correct Pick (Top 30 Players):</strong> 3 points for each player correctly picked to advance to the top 30.</li>
      </ul>
      <p><strong>Bonus Points:</strong></p>
      <ul>
        <li>1 additional point for each correctly predicted player who started inside the top 30 and stayed inside the top 30.</li>
        <li>4 additional points for each correctly predicted player who started between 31-50 and moved into the top 30.</li>
        <li>8 additional points for each correctly predicted player who started between 51-70 and moved into the top 30.</li>
      </ul>
      <p><strong>Final Rankings (Top 30 Players):</strong></p>
      <ul>
        <li><strong>Exact Ranking:</strong> 15 points for each player correctly ranked in their exact final position. If players are tied, points will be awarded if the guessed position falls within the tied range.</li>
      </ul>
      <p><strong>Bonus Points:</strong></p>
      <ul>
        <li><strong>Top 10 Prediction:</strong> An additional 5 points for each player correctly picked and ranked within the top 10.</li>
        <li><strong>Top 5 Prediction:</strong> An additional 10 points for each player correctly picked and ranked within the top 5.</li>
        <li><strong>Winner Prediction:</strong> An additional 20 points for correctly predicting the FedExCup champion.</li>
      </ul>
      <p><strong>Handling Ties:</strong></p>
      <ul>
        <li><strong>General Rule:</strong> When players are tied, a guess that falls within the range of tied positions is counted as correct, and the user receives full points for that position.</li>
      </ul>
      <p><strong>Example:</strong></p>
      <ul>
        <li>Assume 10 players are tied for 3rd place. If a user guessed a player in 8th place and that player is among the 10 tied for 3rd, the user receives full points for the exact ranking.</li>
      </ul>
      <p><strong>Tie Break:</strong></p>
      <ul>
        <li><strong>Combined Strokes of Top 5 Players:</strong> The combined strokes under par (including starting strokes) of the top 5 players will be used as a tie-breaker.</li>
        <li><strong>Total Birdies for Top 5 Players:</strong> The combined birdies of the top 5 players will be used as a second tie-breaker if needed.</li>
      </ul>
      <p><strong>Example Calculation:</strong></p>
      <ul>
        <li>Initial Selection (30 Players): No points.</li>
        <li><strong>After 70 to 50 Cut:</strong> 20 players correctly picked to advance to the top 50: 20 × 1 = 20 points.</li>
        <li><strong>After 50 to 30 Cut:</strong> 15 players correctly picked to advance to the top 30: 15 × 3 = 45 points.</li>
        <li><strong>Bonus points for long shots and upsets:</strong>
          <ul>
            <li>5 players started inside the top 30 and stayed: 5 × 1 = 5 points.</li>
            <li>6 players started between 31-50 and moved into top 30: 6 × 4 = 24 points.</li>
            <li>4 players started between 51-70 and moved into top 30: 4 × 8 = 32 points.</li>
          </ul>
        </li>
        <li><strong>Final Rankings:</strong>
          <ul>
            <li>Scottie Scheffler correctly ranked 1st: 1 point (for advancing to the top 50) + 3 points (for advancing to the top 30) + 15 points (exact ranking) + 5 points (top 10) + 10 points (top 5) + 20 points (winner) = 54 points.</li>
            <li>Rory McIlroy correctly ranked 2nd: 1 point (for advancing to the top 50) + 3 points (for advancing to the top 30) + 15 points (exact ranking) + 5 points (top 10) + 10 points (top 5) = 34 points.</li>
            <li>Akshay Bhatia correctly ranked in the top 30: 1 point (for advancing to the top 50) + 3 points (for advancing to the top 30) + 15 points (exact ranking) = 19 points.</li>
            <li>7 other players correctly ranked in the top 30: 7 × (1 + 3 + 15) = 133 points.</li>
          </ul>
        </li>
        <li><strong>Bonus Points:</strong>
          <ul>
            <li>2 players in the exact top 10 positions: 2 × 5 = 10 points.</li>
            <li>1 player in the exact top 5 position: 10 points.</li>
            <li>Champion correctly predicted: 20 points.</li>
          </ul>
        </li>
      </ul>
      <p><strong>Total Score:</strong></p>
      <p>20 (top 50 correct picks) + 45 (top 30 correct picks) + 5 (inside top 30 bonus) + 24 (31-50 bonus) + 32 (51-70 bonus) + 54 (final ranking for Scottie Scheffler) + 34 (final ranking for Rory McIlroy) + 19 (final ranking for Akshay Bhatia) + 133 (final ranking for other players) + 10 (top 10 bonus) + 10 (top 5 bonus) + 20 (champion bonus) = 406 points.</p>
    </div>
  );
};
