// src/utils/cryptoUtils.ts
import * as CryptoJS from "crypto-js";

// Function to convert a UUID to an AES key using SHA-256
export const uuidToAesKey = (uuid: string): string => {
  const hash = CryptoJS.SHA256(uuid);
  const key = CryptoJS.enc.Hex.stringify(hash).substring(0, 32);
  return key;
};

// Function to decrypt a ciphertext using an AES key derived from a UUID
export const decrypt = (ciphertext: string, uuid: string): any => {
  const key = uuidToAesKey(uuid);
  const decrypted = CryptoJS.AES.decrypt(
    ciphertext,
    CryptoJS.enc.Hex.parse(key),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedStr); // Assuming the decrypted string is a JSON object
};
