import boardImage from "../../../../assets/board.png";
import { useStyles } from "./LockerRoom.styles";

export const ImageTicTacToe = () => {
  const isDesktop = window.innerWidth >= 800;
  const classes = useStyles();

  return (
    <img
      src={boardImage}
      width={isDesktop ? 300 : 230}
      height={isDesktop ? 300 : 230}
      className={classes.boardImage}
      alt="Tic tac toe game"
    />
  );
};
