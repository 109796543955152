import page_1 from './page_1.json';
import page_2 from './page_2.json';
import page_3 from './page_3.json';
import page_4 from './page_4.json';
import page_5 from './page_5.json';
import page_6 from './page_6.json';
import page_7 from './page_7.json';
import page_8 from './page_8.json';
import page_9 from './page_9.json';
import page_10 from './page_10.json';
import page_11 from './page_11.json';
import page_12 from './page_12.json';
import page_13 from './page_13.json';
import page_14 from './page_14.json';
import page_15 from './page_15.json';
import page_16 from './page_16.json';
import page_17 from './page_17.json';
import page_18 from './page_18.json';
import page_19 from './page_19.json';
import page_20 from './page_20.json';
import page_21 from './page_21.json';
import page_22 from './page_22.json';
import page_23 from './page_23.json';
import page_24 from './page_24.json';
import page_25 from './page_25.json';
import page_26 from './page_26.json';
import page_27 from './page_27.json';
import page_28 from './page_28.json';
import page_29 from './page_29.json';
import page_30 from './page_30.json';
import page_31 from './page_31.json';
import page_32 from './page_32.json';
import page_33 from './page_33.json';
import page_34 from './page_34.json';
import page_35 from './page_35.json';
import page_36 from './page_36.json';
import page_37 from './page_37.json';
import page_38 from './page_38.json';
import page_39 from './page_39.json';
import page_40 from './page_40.json';
import page_41 from './page_41.json';
import page_42 from './page_42.json';
import page_43 from './page_43.json';
import page_44 from './page_44.json';
import page_45 from './page_45.json';
import page_46 from './page_46.json';
import page_47 from './page_47.json';
import page_48 from './page_48.json';
import page_49 from './page_49.json';
import page_50 from './page_50.json';
import page_51 from './page_51.json';
import page_52 from './page_52.json';
import page_53 from './page_53.json';
import page_54 from './page_54.json';
import page_55 from './page_55.json';
import page_56 from './page_56.json';
import page_57 from './page_57.json';
import page_58 from './page_58.json';
import page_59 from './page_59.json';
import page_60 from './page_60.json';
import page_61 from './page_61.json';
import page_62 from './page_62.json';
import page_63 from './page_63.json';
import page_64 from './page_64.json';
import page_65 from './page_65.json';
import page_66 from './page_66.json';
import page_67 from './page_67.json';
import page_68 from './page_68.json';
import page_69 from './page_69.json';
import page_70 from './page_70.json';
import page_71 from './page_71.json';
import page_72 from './page_72.json';
import page_73 from './page_73.json';
import page_74 from './page_74.json';
import page_75 from './page_75.json';
import page_76 from './page_76.json';
import page_77 from './page_77.json';
import page_78 from './page_78.json';
import page_79 from './page_79.json';
import page_80 from './page_80.json';
import page_81 from './page_81.json';
import page_82 from './page_82.json';
import page_83 from './page_83.json';
import page_84 from './page_84.json';
import page_85 from './page_85.json';
import page_86 from './page_86.json';
import page_87 from './page_87.json';
import page_88 from './page_88.json';
import page_89 from './page_89.json';
import page_90 from './page_90.json';
import page_91 from './page_91.json';
import page_92 from './page_92.json';
import page_93 from './page_93.json';
import page_94 from './page_94.json';
import page_95 from './page_95.json';
import page_96 from './page_96.json';
import page_97 from './page_97.json';
import page_98 from './page_98.json';

const pages = {
  1: page_1,
  2: page_2,
  3: page_3,
  4: page_4,
  5: page_5,
  6: page_6,
  7: page_7,
  8: page_8,
  9: page_9,
  10: page_10,
  11: page_11,
  12: page_12,
  13: page_13,
  14: page_14,
  15: page_15,
  16: page_16,
  17: page_17,
  18: page_18,
  19: page_19,
  20: page_20,
  21: page_21,
  22: page_22,
  23: page_23,
  24: page_24,
  25: page_25,
  26: page_26,
  27: page_27,
  28: page_28,
  29: page_29,
  30: page_30,
  31: page_31,
  32: page_32,
  33: page_33,
  34: page_34,
  35: page_35,
  36: page_36,
  37: page_37,
  38: page_38,
  39: page_39,
  40: page_40,
  41: page_41,
  42: page_42,
  43: page_43,
  44: page_44,
  45: page_45,
  46: page_46,
  47: page_47,
  48: page_48,
  49: page_49,
  50: page_50,
  51: page_51,
  52: page_52,
  53: page_53,
  54: page_54,
  55: page_55,
  56: page_56,
  57: page_57,
  58: page_58,
  59: page_59,
  60: page_60,
  61: page_61,
  62: page_62,
  63: page_63,
  64: page_64,
  65: page_65,
  66: page_66,
  67: page_67,
  68: page_68,
  69: page_69,
  70: page_70,
  71: page_71,
  72: page_72,
  73: page_73,
  74: page_74,
  75: page_75,
  76: page_76,
  77: page_77,
  78: page_78,
  79: page_79,
  80: page_80,
  81: page_81,
  82: page_82,
  83: page_83,
  84: page_84,
  85: page_85,
  86: page_86,
  87: page_87,
  88: page_88,
  89: page_89,
  90: page_90,
  91: page_91,
  92: page_92,
  93: page_93,
  94: page_94,
  95: page_95,
  96: page_96,
  97: page_97,
  98: page_98,
};

export default pages;
