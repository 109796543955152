import React, { useState, useEffect } from "react";
import styles from "./golfgame.module.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModalHelp } from "./components/ModalHelp";
import { useToggle } from "../../hooks/useToggle";
import { players } from "./types/players.constants";
import axios from "axios";
import FedexCupLogo from '../../assets/PGAT Perfect 30-LocaliQ RGB_H-Pos.png';

const GolfGame = () => {
  const { value: showHelpModal, toggle: toggleHelpModal } = useToggle();



  // Function to render the player list or card view
  const renderPlayers = () => {
    return (
      <div className={styles.playerSelection}>
        {players.map((player, index) => (
          <div
            key={index}
            className={styles.playerButton}
          >
            <img
              src={player.img}
              alt={player.name}
              className={styles.playerImage}
            />
            <span className={styles.playerName}>{player.name}</span>
            <span className={styles.playerDetails}>
              <span className={styles.points}>
                Current Rank: {player.rank2024}
              </span>
       
              <span className={styles.analytics}>
  <strong>Winner:</strong> {player.top1Percentage}<br/>
  <strong>Top 5:</strong> {player.top5Percentage}<br/>
  <strong>Top 10:</strong> {player.top10Percentage}<br/>
  <strong>Top 30:</strong> {player.top30Percentage}<br/>

              </span>
            </span>
            <a
              href={player.profileUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.viewProfileLink}
            >
              View Profile
            </a>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <header className={styles.headerContainer}>
        <img src={FedexCupLogo} alt="FedExCup Playoffs" className={styles.logo} />
        <div className={styles.buttonsContainer}>
          <button
            className={styles.helpButton}
            onClick={() => window.open("https://www.pgatour.com/article/news/latest/2024/08/11/perfect-30-fedexcup-playoffs-final-tour-championship-official-rules-game-predict", "_blank")}
          >
            Official Rules
          </button>
        </div>
        <div className={styles.viewSwitchContainer}>
        </div>
      </header>

      <h6 className={styles.subtitle}>
        Submissions are now closed. See the player analytics below.<br />
        Please <a href="/perfect/leaderboard" className={styles.leaderboardLink}>view our leaderboard</a> to see how you are doing.

      </h6>
      {renderPlayers()}

      <footer className={styles.footer}>
        <span>
          Powered by{" "}
          <a
            href="https://crossovergrid.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            crossovergrid.com
          </a>
        </span>
      </footer>
      <ModalHelp open={showHelpModal} onClose={toggleHelpModal} />
      <ToastContainer 
        position="top-center" 
        limit={2} 
        newestOnTop 
        closeOnClick 
        pauseOnHover={false}
      />
    </div>
  );
};

export default GolfGame;
