import React, { useState, useEffect } from 'react';
import playersData from './playerstask.json';
import styles from './generator.module.css';

import { Link } from "react-router-dom";
import sports from "../../sports";
import crossover_logo from "../../assets/nfl.svg";

function RandomPlayerGenerator() {

    const [page_name, setPageName] = useState(""); // Define page_name state
    const teamLogos = {
        ARI: "https://loodibee.com/wp-content/uploads/nfl-arizona-cardinals-team-logo-2-768x768.png",
        ATL: "https://loodibee.com/wp-content/uploads/nfl-atlanta-falcons-team-logo-2-768x768.png",
        BAL: "https://loodibee.com/wp-content/uploads/nfl-baltimore-ravens-team-logo-2-768x768.png",
        BUF: "https://loodibee.com/wp-content/uploads/nfl-buffalo-bills-team-logo-2-768x768.png",
        CAR: "https://loodibee.com/wp-content/uploads/nfl-carolina-panthers-team-logo-2-768x768.png",
        CHI: "https://loodibee.com/wp-content/uploads/nfl-chicago-bears-team-logo-2-768x768.png",
        CIN: "https://loodibee.com/wp-content/uploads/nfl-cincinnati-bengals-team-logo-2-768x768.png",
        CLE: "https://loodibee.com/wp-content/uploads/nfl-cleveland-browns-team-logo-2-768x768.png",
        DAL: "https://loodibee.com/wp-content/uploads/nfl-dallas-cowboys-team-logo-2-768x768.png",
        DEN: "https://loodibee.com/wp-content/uploads/nfl-denver-broncos-team-logo-2-768x768.png",
        DET: "https://loodibee.com/wp-content/uploads/nfl-detroit-lions-team-logo-2-768x768.png",
        GNB: "https://loodibee.com/wp-content/uploads/nfl-green-bay-packers-team-logo-2-768x768.png",
        HOU: "https://loodibee.com/wp-content/uploads/nfl-houston-texans-team-logo-2-768x768.png",
        IND: "https://loodibee.com/wp-content/uploads/nfl-indianapolis-colts-team-logo-2-768x768.png",
        JAX: "https://loodibee.com/wp-content/uploads/nfl-jacksonville-jaguars-team-logo-2-768x768.png",
        KAN: "https://loodibee.com/wp-content/uploads/nfl-kansas-city-chiefs-team-logo-2-768x768.png",
        LAR: "https://loodibee.com/wp-content/uploads/nfl-los-angeles-rams-team-logo-2-768x768.png",
        LVR: "https://loodibee.com/wp-content/uploads/nfl-oakland-raiders-team-logo.png",
        MIA: "https://loodibee.com/wp-content/uploads/nfl-miami-dolphins-team-logo-2-768x768.png",
        MIN: "https://loodibee.com/wp-content/uploads/nfl-minnesota-vikings-team-logo-2-768x768.png",
        NOR: "https://loodibee.com/wp-content/uploads/nfl-new-orleans-saints-team-logo-2-768x768.png",
        NWE: "https://loodibee.com/wp-content/uploads/nfl-new-england-patriots-team-logo-2-768x768.png",
        NYG: "https://loodibee.com/wp-content/uploads/nfl-new-york-giants-team-logo-2-768x768.png",
        NYJ: "https://loodibee.com/wp-content/uploads/nfl-new-york-jets-team-logo.png",
        PHI: "https://loodibee.com/wp-content/uploads/nfl-philadelphia-eagles-team-logo-2-768x768.png",
        PIT: "https://loodibee.com/wp-content/uploads/nfl-pittsburgh-steelers-team-logo-2-768x768.png",
        SFO: "https://loodibee.com/wp-content/uploads/nfl-san-francisco-49ers-team-logo-2-768x768.png",
        SEA: "https://loodibee.com/wp-content/uploads/nfl-seattle-seahawks-team-logo-2-768x768.png",
        TAM: "https://loodibee.com/wp-content/uploads/nfl-tampa-bay-buccaneers-team-logo-2-768x768.png",
        TEN: "https://loodibee.com/wp-content/uploads/nfl-tennessee-titans-team-logo-2-768x768.png",
        WAS: "https://loodibee.com/wp-content/uploads/washington-commanders-logo.png"
      };
 
      
    const [generatedPlayers, setGeneratedPlayers] = useState([]);
    const [numberOfPlayers, setNumberOfPlayers] = useState('');
    const [teams, setTeams] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState(new Set());
    const [positions, setPositions] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState(new Set());
    const [isGenerated, setIsGenerated] = useState(false);
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form Submitted'); // Logging
        const form = new FormData(event.currentTarget);
        generatePlayers(form);
    };

    useEffect(() => {
               // Positions array without empty strings
               const positions = [
                "QB",    // Quarterback
                "RB",    // Running Back
                "FB",    // Fullback
                "WR",    // Wide Receiver
                "TE",    // Tight End
                "OL",    // Offensive Line (grouped)
                "LT",    // Left Tackle
                "LG",    // Left Guard
                "C",     // Center
                "RG",    // Right Guard
                "RT",    // Right Tackle
                "DE",    // Defensive End
                "LDE",   // Left Defensive End
                "RDE",   // Right Defensive End
                "DT",    // Defensive Tackle
                "NT",    // Nose Tackle
                "LB",    // Linebacker
                "MLB",   // Middle Linebacker
                "LILB",  // Left Inside Linebacker
                "RILB",  // Right Inside Linebacker
                "OLB",   // Outside Linebacker
                "CB",    // Cornerback
                "LCB",   // Left Cornerback
                "RCB",   // Right Cornerback
                "SS",    // Strong Safety
                "FS",    // Free Safety
                "S",     // Safety (generic)
                "DB",    // Defensive Back (generic)
                "K",     // Kicker
                "P",     // Punter
                "LS"     // Long Snapper
            ];
                
            // Combining specific teams
            let teams = ["ARI", "ATL", "BAL", "BUF", "CAR", "CHI", "CIN", "CLE", "DAL", "DEN", "DET", "GNB", "HOU", "IND", "JAX", "KAN", "LAR", "LVR", "MIA", "MIN", "NOR", "NWE", "NYG", "NYJ", "PHI", "PIT", "SFO", "SEA", "TAM", "TEN", "WAS"];
                setPositions(positions);
        setTeams(teams);
    }, []);
    const toggleTeam = (team, event) => {
        event.stopPropagation();  // Prevents the event from propagating up to other handlers
        const newSet = new Set(selectedTeams);
        if (newSet.has(team)) {
            newSet.delete(team);
        } else {
            newSet.add(team);
        }
        setSelectedTeams(newSet);
    };
    
    const togglePosition = (position, event) => {
        event.stopPropagation();  // Prevents the event from propagating up to other handlers
        const newSet = new Set(selectedPositions);
        if (newSet.has(position)) {
            newSet.delete(position);
        } else {
            newSet.add(position);
        }
        setSelectedPositions(newSet);
    };
    
      // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  
  }, []);

  // Omitting existing useEffects and functions for brevity...


  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };
  const generatePlayers = (form) => {
    console.log('Generating Players...');
    const selectedTeamsArray = Array.from(selectedTeams);
    const selectedPositionsArray = Array.from(selectedPositions);
    const fromYear = parseInt(form.get('fromYear'), 10) || 0;
    const toYear = parseInt(form.get('toYear'), 10) || new Date().getFullYear();
    const minSeasons = parseInt(form.get('minSeasons'), 10) || 0;
    const minHeight = parseInt(form.get('minHeight'), 10) || 0;
    const maxHeight = parseInt(form.get('maxHeight'), 10) || Number.MAX_SAFE_INTEGER;
    const minWeight = parseInt(form.get('minWeight'), 10) || 0;
    const maxWeight = parseInt(form.get('maxWeight'), 10) || Number.MAX_SAFE_INTEGER;
    const minGames = parseInt(form.get('minGames'), 10) || 0;
    const maxGames = parseInt(form.get('maxGames'), 10) || Number.MAX_SAFE_INTEGER;
    const minGamesStarted = parseInt(form.get('minGamesStarted'), 10) || 0;
    const maxGamesStarted = parseInt(form.get('maxGamesStarted'), 10) || Number.MAX_SAFE_INTEGER;
    const numPlayers = parseInt(numberOfPlayers, 10) || playersData.length;

    let filteredPlayers = playersData.filter(player => {
        const playerHeightInches = convertHeightToInches(player['Ht.']);
        const playerWeight = parseInt(player.Wt, 10);
        const playerGames = parseInt(player.G, 10);
        const playerGamesStarted = parseInt(player.GS, 10);
        // Continue with other conditions and add new ones
            const playerFrom = parseInt(player.From, 10);
            const playerTo = parseInt(player.To, 10);
            const seasonsCount = playerTo - playerFrom + 1;
            return (!fromYear || playerFrom >= fromYear) &&
            (!toYear || playerTo <= toYear) &&
            seasonsCount >= minSeasons &&
            (selectedTeamsArray.length === 0 || selectedTeamsArray.some(team => player.Team.includes(team))) &&
               (selectedPositionsArray.length === 0 || selectedPositionsArray.some(position => player.Pos.includes(position)));
    });

    if (filteredPlayers.length > 0) {
        filteredPlayers = shuffleArray(filteredPlayers);
        console.log('Shuffled Players:', filteredPlayers); // Logging to see the shuffled result
        setGeneratedPlayers(filteredPlayers.slice(0, numberOfPlayers));
        setIsGenerated(true);
    } else {
        setGeneratedPlayers([]);
        setIsGenerated(false);
    }
};


    const convertHeightToInches = (height) => {
        const [feet, inches] = height.split('-').map(num => parseInt(num, 10));
        return feet * 12 + inches;
    };
    const getSeasonsCount = (from, to) => {
        return to - from + 1;
    };
    const clearGeneratedPlayers = () => {
        setGeneratedPlayers([]);
        setIsGenerated(false);
    };
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };
    const toggleFilters = () => {
        setShowAdvancedFilters(!showAdvancedFilters); // Toggle the visibility
    };
    
    return (
        <div className={styles.container}>
            <nav className="navbar">
                <a href="https://crossovergrid.com"
                    style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <img src={crossover_logo} alt="Logo" className="navbar-logo" />
                    <p style={{
                        margin: 0,
                        fontWeight: "bold",
                        fontSize: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        CROSSOVER GRID NFL
                    </p>
                </a>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                    {/* potentially more content here */}
                </div>
            </nav>
            <div className="sport-picker">
                <div className="links">
                    {sports.map((sport) => (
                        <Link key={sport.id} className={`sportLink ${sport.id === "21" ? "prime-gaming-logo" : ""} ${page_name === sport.id ? "selected" : ""}`} to={sport.path}>
                            {sport.emoji.startsWith("http") ? <img src={sport.emoji} alt={sport.name} /> : <span>{sport.emoji}</span>}
                            <br />
                            {sport.name.length < 4 ? <span>{sport.name}</span> : <span style={{ fontSize: 12 }}>{sport.name}</span>}
                        </Link>
                    ))}
                </div>
            </div>
    
            <div className={styles.body}>
                <h2 className={styles.title}>Random Football Player Generator</h2>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div>
                        <label>Number of Players: <input type="number" name="numberOfPlayers" value={numberOfPlayers} onChange={e => setNumberOfPlayers(e.target.value)} className={styles.input} required /></label>
                    </div>
                    <button type="button" onClick={toggleFilters} className={styles.button}>Show/Hide Advanced Filters</button>
                    {showAdvancedFilters && (
                        <div>
                                 <div className={styles.filterRow}>
                                {teams.map((team, index) => (
                                    <button key={index} onClick={(e) => toggleTeam(team, e)} className={`${styles.filterButton} ${selectedTeams.has(team) ? styles.filterButtonSelected : ''}`}>
                                        <img src={teamLogos[team]} alt={team} className={styles.teamLogo} />
                                        {team}
                                    </button>
                                ))}
                            </div>
                            <div className={styles.filterRow}>
                                {positions.map((position, index) => (
                                    <button key={index}
                                        onClick={(e) => togglePosition(position, e)}
                                        className={`${styles.filterButton} ${selectedPositions.has(position) ? styles.filterButtonSelected : ''}`}>
                                        {position}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                <button type="submit" className={styles.button}>Generate</button>
                </form>
                {isGenerated && generatedPlayers.length > 0 && (
                    <div className={styles.results}>
                        <h3>Generated Players:</h3>
                        <ul className={styles.resultsList}>
                            {generatedPlayers.map((player, index) => (
                                <li key={index} className={styles.resultItem}>
                                    <strong>{player.Player}</strong> - Pos: {player.Pos.join(', ')} - Teams: {player.Team.join(', ')} - From: {player.From} - To: {player.To}
                                    <img src={player['Image Url']} alt={`${player.Player}'s profile`} className={styles.playerImage} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
    
}

export default RandomPlayerGenerator;