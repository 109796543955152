
import React, { useState, useEffect } from 'react';
import data from './math.json'; // Ensure the data structure matches your provided JSON.
import styles from './lebron.module.css';

function MathApp() {
  const [correctSequence, setCorrectSequence] = useState([]);
  const [targetScore, setTargetScore] = useState(0);
  const [sequence, setSequence] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [guessHistory, setGuessHistory] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [showCorrectSequence, setShowCorrectSequence] = useState(false);
  const [correctSequenceMessage, setCorrectSequenceMessage] = useState([]);
  
  useEffect(() => {
    const augmentedData = augmentDataWithValues(data);
    setFilteredPlayers(shuffleArray(augmentedData));
    generateAndSetCorrectSequence();
  }, []);

  function augmentDataWithValues(data) {
    return Object.entries(data).flatMap(([jerseyNumber, players]) =>
      players.map(player => ({
        ...player,
        value: parseInt(jerseyNumber),
      }))
    );
  }

  function generateAndSetCorrectSequence() {
    const sequence = generateCorrectSequence(data);
    setCorrectSequence(sequence);
    const newTargetScore = calculateTargetScore(sequence);
    setTargetScore(newTargetScore);
  }
  function evaluateGuesses(guessedSequence, correctSequence) {
    // Ensure that guessedSequence and correctSequence are aligned; this might involve normalization
    const feedback = guessedSequence.map((guessedItem, index) => {
      const correctItem = correctSequence[index];
  
      if (guessedItem.type !== correctItem.type) {
        return 'incorrect'; // Early return for mismatched types
      }
  
      // Handling player type guesses
      if (guessedItem.type === 'player') {
        if (guessedItem.value.player === correctItem.value.player && guessedItem.value.team === correctItem.value.team) {
          return 'correct'; // Exact match
        } else if (guessedItem.value.team === correctItem.value.team) {
          return 'sameTeam'; // Same team, wrong player
        } else if (guessedItem.value.value === correctItem.value.value) {
          return 'sameNumber'; // Same jersey number, wrong player
        } else {
          return 'incorrect'; // No match
        }
      }
  
      // Handling operation type guesses
      if (guessedItem.type === 'operation') {
        return guessedItem.value === correctItem.value ? 'correct' : 'incorrect';
      }
  
      return 'incorrect'; // Default case
    });
  
    return feedback;
  }
  
  
  function generateCorrectSequence(playersData) {
    let sequence = [];
    const keys = Object.keys(playersData);
    for (let i = 0; i < 3; i++) { // 3 players
        const numberKey = keys[getRandomNumber(0, keys.length - 1)];
        const playersInCategory = playersData[numberKey];
        const randomPlayerIndex = Math.floor(Math.random() * playersInCategory.length);
        const randomPlayer = { ...playersInCategory[randomPlayerIndex], value: parseInt(numberKey) };
        sequence.push({ type: 'player', value: randomPlayer });

        if (i < 2) { // 2 operations, after the first and second player
            const randomOperation = getRandomOperation();
            sequence.push({ type: 'operation', value: randomOperation });
        }
    }
    return sequence;
}


  function calculateTargetScore(sequence) {
    let score = sequence.reduce((acc, item, index) => {
      if (item.type === 'player') {
        const playerValue = item.value.value;
        if (index === 0) return playerValue;
        const prevItem = sequence[index - 1];
        if (prevItem.type === 'operation') {
          switch (prevItem.value) {
            case '+': return acc + playerValue;
            case '-': return acc - playerValue;
            case '*': return acc * playerValue;
            case '/': return playerValue !== 0 ? acc / playerValue : 'Error: Division by Zero';
            default: return acc;
          }
        }
      }
      return acc;
    }, 0);
    return Math.round(score);
  }
  
  // Function to generate a random number between min and max (inclusive)
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Function to generate a random arithmetic operation (+, -, *, /)
  function getRandomOperation() {
    const operations = ['+', '-', '*', '/'];
    const randomIndex = Math.floor(Math.random() * operations.length);
    return operations[randomIndex];
  }

  // Function to shuffle an array
  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  const handlePlayerSelect = (player) => {
    // Players can only be added at positions 0, 2, 4
    if ([0, 2, 4].includes(sequence.length)) {
        setSequence([...sequence, { type: 'player', value: player }]);
    }
};

const handleOperationSelect = (operation) => {
  // Ensure an operation is only added if there are exactly 1 or 3 items in the sequence
  if (sequence.length === 1 || sequence.length === 3) {
    setSequence([...sequence, { type: 'operation', value: operation }]);
  }
};



  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
    if (!event.target.value) {
      const shuffled = shuffleArray(Object.values(data).flat());
      setFilteredPlayers(shuffled);
      console.log("Shuffled Players:", shuffled); // Debug log
    } else {
      const filtered = Object.values(data).flat().filter(player =>
        player.player.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setFilteredPlayers(filtered);
      console.log("Filtered Players:", filtered); // Debug log
    }
  };
  function calculateScore() {
    let result = 0;
    let currentOperation = null;
    let calculationProcess = []; // To keep track of the calculation steps for history
  
    sequence.forEach(item => {
      if (item.type === 'player') {
        // Assuming each player object has a 'player' and 'team' property
        const playerName = item.value.player;
        const playerTeam = item.value.team;
        // Find the player's jersey number based on both name and team
        const playerJerseyNumber = findPlayerJerseyNumber(playerName, playerTeam);
        if (playerJerseyNumber === undefined) {
          console.error("Jersey number not found for player", playerName, "in team", playerTeam);
          result = 'Error: Player data not found';
          return; // Exit the loop early on error
        }
        calculationProcess.push(`${playerJerseyNumber}`);
        if (currentOperation) {
          if (currentOperation === '/' && playerJerseyNumber === 0) {
            result = 'Error: Division by Zero';
            return;
          } else {
            result = evaluateOperation(result, playerJerseyNumber, currentOperation);
          }
        } else {
          result = playerJerseyNumber;
        }
      } else if (item.type === 'operation') {
        currentOperation = item.value;
        calculationProcess.push(currentOperation);
      }
    });
  
    return {
      result: typeof result === 'number' ? result : 'Error', // Ensure number or 'Error'
      calculationProcess: calculationProcess.join(' ')
    };
  }
  function sequencesMatch(guessedSequence, correctSequence) {
    // Check if both sequences have the same length
    if (guessedSequence.length !== correctSequence.length) {
      return false;
    }
  
    // Compare each element in the sequences
    for (let i = 0; i < guessedSequence.length; i++) {
      const guessedItem = guessedSequence[i];
      const correctItem = correctSequence[i];
  
      // Check if both items are of the same type (player or operation)
      if (guessedItem.type !== correctItem.type) {
        return false;
      }
  
      // If the type is 'player', check both player name and team
      if (guessedItem.type === 'player' && (guessedItem.value.player !== correctItem.value.player || guessedItem.value.team !== correctItem.value.team)) {
        return false;
      }
  
      // If the type is 'operation', check if the operation matches
      if (guessedItem.type === 'operation' && guessedItem.value !== correctItem.value) {
        return false;
      }
    }
  
    // If all elements match, the sequences match
    return true;
  }
  function checkGuessCorrectness(guess, correctSequence) {
    return guess.map((guessedItem, index) => {
      const correctItem = correctSequence[index];
  
      // Check for exact match (correct player in the right spot)
      if (guessedItem.player === correctItem.player && guessedItem.team === correctItem.team) {
        return 'correct';
      } 
      // Check if the guessed player is from the same team
      else if (guessedItem.team === correctItem.team) {
        return 'sameTeam';
      }
      // Check if the guessed player has the same jersey number (assuming jersey number is a unique identifier across teams)
      else if (data[guessedItem.player]?.jerseyNumber === data[correctItem.player]?.jerseyNumber) {
        return 'sameNumber';
      } 
      // If none of the above, the guess is incorrect
      else {
        return 'incorrect';
      }
    });
  }
  
  function findPlayerJerseyNumber(playerName, playerTeam) {
    // Assuming 'data' is an object where keys are jersey numbers and values are arrays of player objects
    for (const [jerseyNumber, players] of Object.entries(data)) {
      const playerFound = players.find(player => player.player === playerName && player.team === playerTeam);
      if (playerFound) {
        return parseInt(jerseyNumber); // Return the jersey number as an integer
      }
    }
    return undefined; // Return undefined if the player is not found
  }
  
  
  function evaluateOperation(result, playerValue, operation) {
    console.log('Evaluating:', result, operation, playerValue); // Log operation details
    switch (operation) {
      case '+': return result + playerValue;
      case '-': return result - playerValue;
      case '*': return result * playerValue;
      case '/':
        if (playerValue === 0) {
          return 'Error: Division by Zero'; // Explicit error message
        } else {
          return result / playerValue;
        }
      default: 
        console.error('Invalid operation:', operation); // Log invalid operations
        return result;
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Calculate the score of the guessed sequence
    const { result, calculationProcess } = calculateScore();
  
    // Use the sequencesMatch function to compare the user's sequence against the correct sequence
    const guessIsCorrect = sequencesMatch(sequence, correctSequence);
  
    // Construct the feedback message
    let message;
    if (guessIsCorrect) {
      message = `Congratulations! You found the correct sequence. Score: ${result}.`;
    } else {
      message = `Score: ${result}`;
    }
  
    // Show the feedback message
    alert(message);
    
    // Clear the current sequence for the next guess
    setSequence([]);
  
    // Update guess history, including whether the guess was correct and the score
    setGuessHistory([...guessHistory, { sequence: [...sequence], result: message, calculationProcess }]);
  };
  
  const giveUp = () => {
    console.log(correctSequence); // Log to debug
  
    const correctSequenceElements = correctSequence.map((item, index) => {
      if (item.type === 'player' && item.value) {
        return (
          <span key={index} className={styles.correctPlayer}>
            {item.value.player} - {item.value.team} ({item.value.value})
          </span>
        );
      } else if (item.type === 'operation') {
        return (
          <span key={index} className={styles.correctOperation}>
            {item.value}
          </span>
        );
      }
      return null;
    });
  
    // Instead of alert, update the state to show the correct sequence in the UI
    setCorrectSequenceMessage(correctSequenceElements);
    setShowCorrectSequence(true); // Trigger to show the sequence in your UI
  };
  

  return (
    <div className={styles.container}>
    {showCorrectSequence && (
      <div className={styles.correctSequenceModal}>
        <p>The correct sequence is:</p>
        <div className={styles.correctSequence}>{correctSequenceMessage}</div>
        <button onClick={() => setShowCorrectSequence(false)}>Close</button>
      </div>
    )}    <h1 className={styles.heading}>Jersey Math Game</h1>
    <p className={styles.score}>Target Score: <strong>{targetScore}</strong></p>
    <section className={styles.section}>
    <div className={styles.actionButtons}>
    <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
    <button onClick={giveUp} className={styles.giveUpButton}>Give Up</button>
    </div>
    <section className={styles.guessHistory}>
  <h2 className={styles.heading}>Guess History</h2>
  <div className={styles.roundContainer}>
  {guessHistory.map((round, index) => (
  <div key={index} className={styles.round}>
    <div className={styles.historyList}>
      {evaluateGuesses(round.sequence, correctSequence).map((feedbackClass, itemIndex) => {
        const item = round.sequence[itemIndex];
        let itemClass;
        switch (feedbackClass) {
          case 'correct':
            itemClass = styles.correct; // Apply green background for correct guesses
            break;
          case 'sameTeam':
            itemClass = styles.sameTeam; // Orange, applicable only to players
            break;
          case 'sameNumber':
            itemClass = styles.sameNumber; // Blue, applicable only to players
            break;
          default:
            itemClass = styles.incorrect; // Grey for incorrect guesses
            break;
        }

        return (
          <span key={itemIndex} className={`${styles.historyItem} ${itemClass}`}>
            {item.type === 'player' ? `${item.value.player} - ${item.value.team}` : item.value}
          </span>
        );
      })}
      <span className={styles.neutral}> = {round.result}</span>
    </div>
  </div>
))}


</div>

</section>
<h2 className={styles.heading}>Select Players and Operations</h2>

<div className={styles.selections}>
  {sequence.map((item, index) =>
    item.type === 'player' ? (
      <span key={index} className={styles.selectedPlayer}>{item.value.player} - {item.value.team}</span>
    ) : (
      <span key={index} className={styles.selectedOperation}>{item.value}</span>
    )
  )}
  {/* Only show operation buttons if the sequence has 1 or 3 items */}
  {sequence.length === 1 || sequence.length === 3 ? (
    <div className={styles.operations}>
      <button onClick={() => handleOperationSelect('+')}>+</button>
      <button onClick={() => handleOperationSelect('-')}>-</button>
      <button onClick={() => handleOperationSelect('*')}>*</button>
      <button onClick={() => handleOperationSelect('/')}>/</button>
    </div>
  ) : null}
</div>



    </section>
    <section className={styles.section}>
    <input
           type="text"
           value={searchInput}
           onChange={handleSearchInputChange}
           placeholder="Search players..."
           className={styles.searchInput}
         />
    <ul className={styles.playerList}>
    {filteredPlayers.map((player, index) => (
  <li
    key={`${player.player}-${player.team}-${index}`} // Construct a more unique key
    onClick={() => handlePlayerSelect(player)}
    className={
      sequence.find(s => s.type === 'player' && s.value.player === player.player) ? 
      styles.selectedPlayerItem : 
      styles.playerItem
    }
  >
    {player.player} - {player.team}
  </li>
))}


    </ul>
    </section>
   

    </div>
    );
    }
    
    export default MathApp;