import React, { useState, useEffect } from 'react';
import "./one.css"; // Make sure this path is correct for your CSS file

// Assuming CSV data is converted to JSON for simplicity
const playerData = [
  {
    "Player": "Manute Bol",
    "Ht.": "7-7",
    "Team": "GSW/PHI/WSB",
  },
  {
    "Player": "Gheorghe Mureșan",
    "Ht.": "7-7",
    "Team": "NJN/WSB",
  },
  // Add more players as needed
];

const BasketballGame = () => {
  const [guess, setGuess] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [guessHistory, setGuessHistory] = useState([]);
  const [score, setScore] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    // Select a random player to start
    startGame();
  }, []);

  const startGame = () => {
    const randomIndex = Math.floor(Math.random() * playerData.length);
    setSelectedPlayer(playerData[randomIndex]);
    setGameStarted(true);
    setGameOver(false);
    setGuessHistory([]);
    setScore(0); // Reset score for a new game
  };

  const handleGuess = () => {
    const normalizedGuess = guess.trim().toLowerCase();
    const normalizedPlayerName = selectedPlayer.Player.toLowerCase();

    if (normalizedGuess === normalizedPlayerName) {
      alert(`Correct! It was ${selectedPlayer.Player}.`);
      setGameOver(true);
    } else {
      setGuessHistory(prevHistory => [...prevHistory, guess]);
      setScore(prevScore => prevScore + 1); // Increment score for each guess
    }

    setGuess(''); // Reset guess input
  };

  return (
    <div className="basketball-game">
      <h1>Basketball Guessing Game</h1>
      {gameStarted && !gameOver && (
        <div>
          <p>Guess the player's name based on the hint: Height - {selectedPlayer["Ht."]}, Team - {selectedPlayer["Team"]}</p>
          <input
            type="text"
            value={guess}
            onChange={(e) => setGuess(e.target.value)}
            placeholder="Enter player's name"
          />
          <button onClick={handleGuess}>Guess</button>
        </div>
      )}
      {gameOver && (
        <div>
          <p>Game Over! You guessed it in {score} guesses.</p>
          <button onClick={startGame}>Play Again</button>
        </div>
      )}
      <ul>
        {guessHistory.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default BasketballGame;
