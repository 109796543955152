// ProgressSnackbarContent.js
import React, { forwardRef, useEffect, useState } from "react";

import {
  LinearProgress,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";

const ProgressSnackbarContent = forwardRef(({ text, duration }, ref) => {
  const [progress, setProgress] = useState(100);
  const isDesktop = window.innerWidth >= 800;
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        Math.max(prevProgress - 100 / (duration / 100), 0)
      );
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [duration]);

  return (
    <SnackbarContent
      message={
        <>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "4px",
              bgcolor: "#A2E5B2",
              "& .MuiLinearProgress-bar": {
                bgcolor: "#58D184",
              },
            }}
          />
          <p
            style={{
              position: "absolute",
              color: "black",
              // height: "50%",
              width: "100%",
              top: "0%",
              left: 10,
            }}
          >
            {text}
          </p>
        </>
      }
      style={{
        position: "relative",
        backgroundColor: "transparent",
        // color: "black",
        padding: 15,
        borderRadius: 4,
        minWidth: isDesktop ? "375px" : "auto",
        whiteSpace: "normal",
      }}
      ref={ref}
    />
  );
});

export default ProgressSnackbarContent;