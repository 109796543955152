
import { yellow } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './module.ConnectionsGame.css';
import crossover_logo from "../../assets/nfl.svg";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Select from "@mui/material/Select"; // Import Select component for dropdown
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem for dropdown items
import { track } from '@vercel/analytics';
import { makeStyles as makeMuiStyles } from "@mui/styles";
import ReactGA from "react-ga";
import Modal from 'react-modal';

import HomeIcon from '@mui/icons-material/Home';



const baseURL = "crossovergrid.com/NFL/connections";
const categories30 = [
  {
    "category": "🏈 #1 Overall NFL Draft Picks",
    "header": "NFL Players Drafted #1 Overall",
    "items": ["Jared Goff", "Joe Burrow", "Myles Garrett", "Jadeveon Clowney"],
    "commonality": "NFL players who were selected as the first overall pick in their respective NFL Drafts, marking significant beginnings of their professional careers",
    "color": "gold",
    "images": []
  },
  {
    "category": "🎓 Attended University of Oregon",
    "header": "NFL Players Who Attended the University of Oregon",
    "items": ["Bo Nix", "Marcus Mariota", "Kellen Clemens", "Justin Herbert"],
    "commonality": "NFL players who played college football at the University of Oregon, known for their significant contributions on the field",
    "color": "green",
    "images": []
  },
  {
    "category": "🏆 NFL MVP Winners",
    "header": "NFL Players Who Have Won the MVP Award",
    "items": ["Lamar Jackson", "LaDainian Tomlinson", "Aaron Rodgers", "Matt Ryan"],
    "commonality": "NFL players who have been honored with the Most Valuable Player (MVP) award, signifying their peak performances in particular seasons",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔠 NFL Players Last Names Starting with 'P'",
    "header": "NFL Players Whose Last Names Start with P",
    "items": ["Micah Parsons", "Brock Purdy", "Patrick Peterson", "Nathan Peterman"],
    "commonality": "NFL players whose last names begin with the letter 'P'",
    "color": "red",
    "images": []
  }
]

const categories29 = [
  {
    "category": "🔥 Roasted Tom Brady Last Night",
    "header": "NFL Personalities Who Roasted Tom Brady",
    "items": ["Peyton Manning", "Julian Edelman", "Rob Gronkowski", "Drew Bledsoe"],
    "commonality": "NFL figures who participated in a comedic roast of Tom Brady, highlighting their camaraderie and humor",
    "color": "purple",
    "images": []
  },
  {
    "category": "🏈 Caught Touchdowns from Patrick Mahomes",
    "header": "Players Who Caught Touchdown Passes from Patrick Mahomes",
    "items": ["LeSean McCoy", "Josh Gordon", "JuJu Smith-Schuster", "Sammy Watkins"],
    "commonality": "NFL players who have caught touchdown passes from Kansas City Chiefs quarterback Patrick Mahomes",
    "color": "green",
    "images": []
  },
  {
    "category": "🏈 2017 Starting San Francisco 49ers",
    "header": "Starting Players for the San Francisco 49ers in 2017",
    "items": ["Carlos Hyde", "Pierre Garcon", "Garrett Celek", "Brian Hoyer"],
    "commonality": "Players who were starters for the San Francisco 49ers during the 2017 NFL season",
    "color": "red",
    "images": []
  },
  {
    "category": "🔠 NFL Players Last Names Starting with 'J'",
    "header": "NFL Players Whose Last Names Start with J",
    "items": ["Lamar Jackson", "Josh Jacobs", "Grady Jarrett", "Daniel Jones"],
    "commonality": "NFL players whose last names begin with the letter 'J'",
    "color": "blue",
    "images": []
  }
]


const categories28 = [
  {
    "category": "🏈 Drafted in 2010",
    "header": "NFL Players Drafted in 2010",
    "items": ["Ndamukong Suh", "C.J. Spiller", "Dez Bryant", "Demaryius Thomas"],
    "commonality": "NFL players who were selected in the 2010 NFL Draft, marking the start of their professional football careers",
    "color": "blue",
    "images": []
  },
  {
    "category": "🎓 Attended Texas Christian University (TCU)",
    "header": "NFL Players Who Attended TCU",
    "items": ["Josh Doctson", "LaDainian Tomlinson", "Andy Dalton", "Jerry Hughes"],
    "commonality": "NFL players who played college football at Texas Christian University, contributing to the Horned Frogs' legacy",
    "color": "purple",
    "images": []
  },
  {
    "category": "🏈 NFL Fullbacks",
    "header": "Notable NFL Fullbacks",
    "items": ["Kyle Juszczyk", "John Kuhn", "Alec Ingold", "Mike Alstott"],
    "commonality": "Renowned NFL players known for playing the fullback position, pivotal in blocking and short-yardage situations",
    "color": "red",
    "images": []
  },
  {
    "category": "🏈 Caught Touchdowns from Jim Harbaugh",
    "header": "Players Who Caught Touchdown Passes from Jim Harbaugh",
    "items": ["Marshall Faulk", "Marvin Harrison", "Curtis Conway", "Neal Anderson"],
    "commonality": "NFL players who caught touchdown passes thrown by quarterback Jim Harbaugh during his NFL career",
    "color": "green",
    "images": []
  }
]

const categories27 = [
  {
    "category": "🏈 NFL Current Free Agents as of 5/1/2024",
    "header": "NFL Free Agents as of May 1, 2024",
    "items": ["Justin Simmons", "Stephon Gilmore", "Odell Beckham Jr.", "Ryan Tannehill"],
    "commonality": "NFL players who are free agents as of May 1, 2024, available for teams to sign",
    "color": "purple",
    "images": []
  },
  {
    "category": "🔵 Current Tennessee Titans Players",
    "header": "Players Currently on the Tennessee Titans Roster",
    "items": ["Tony Pollard", "Mason Rudolph", "L'Jarius Sneed", "Nick Folk"],
    "commonality": "Players who are part of the Tennessee Titans' roster, contributing to the team in the current season",
    "color": "blue",
    "images": []
  },
  {
    "category": "⭐ NFC Pro Bowlers Last Season",
    "header": "NFC Players Who Were Pro Bowlers in the Last Season",
    "items": ["DaRon Bland", "Brandon Aiyuk", "Haason Reddick", "Matthew Stafford"],
    "commonality": "Players from the NFC who were selected to participate in the Pro Bowl last season, recognized for their outstanding performances",
    "color": "red",
    "images": []
  },
  {
    "category": "🛌 Retired NFL Players in 2016",
    "header": "NFL Players Who Retired in 2016",
    "items": ["Calvin Johnson", "Arian Foster", "Charles Woodson", "Jared Allen"],
    "commonality": "NFL players who announced their retirement in the year 2016, after illustrious careers in professional football",
    "color": "green",
    "images": []
  }
]

const categories26 = [
  {
    "category": "🎓 Attended University of Wisconsin",
    "header": "NFL Players Who Attended the University of Wisconsin",
    "items": ["Joe Thomas", "Jonathan Taylor", "Chris Chambers", "T.J. Edwards"],
    "commonality": "NFL players who played college football at the University of Wisconsin, known for their significant contributions on the field",
    "color": "red",
    "images": []
  },
  {
    "category": "🛑 Played Safety for the New York Jets",
    "header": "Safeties Who Played for the New York Jets",
    "items": ["LaRon Landry", "Kerry Rhodes", "Marcus Maye", "Jordan Whitehead"],
    "commonality": "Defensive players who have held the position of safety for the New York Jets, contributing to the team's secondary",
    "color": "green",
    "images": []
  },
  {
    "category": "🏈 Pick 6 Against Matt Ryan",
    "header": "NFL Players Who Scored a Pick 6 Against Matt Ryan",
    "items": ["Eric Berry", "Glover Quin", "Ryan Kerrigan", "NaVorro Bowman"],
    "commonality": "Defensive NFL players who have intercepted Matt Ryan and returned the ball for a touchdown",
    "color": "purple",
    "images": []
  },
  {
    "category": "🏈 Caught a Touchdown Pass from Matt Ryan",
    "header": "Players Who Caught a Touchdown Pass from Matt Ryan",
    "items": ["Michael Pittman", "Calvin Ridley", "Jacquizz Rodgers", "Mo Alie-Cox"],
    "commonality": "NFL players who have caught touchdown passes thrown by quarterback Matt Ryan during their careers",
    "color": "blue",
    "images": []
  }
]

const categories25 = [
  {
    "category": "🎓 Attended Purdue University",
    "header": "NFL Players Who Attended Purdue University",
    "items": ["Raheem Mostert", "Drew Brees", "Rod Woodson", "Ryan Kerrigan"],
    "commonality": "Prominent NFL players who attended Purdue University and played for the Boilermakers",
    "color": "orange",
    "images": []
  },
  {
    "category": "🧠 Drafted by Andy Reid",
    "header": "NFL Players Drafted by Coach Andy Reid",
    "items": ["Patrick Mahomes", "Jason Kelce", "LeSean McCoy", "Kareem Hunt"],
    "commonality": "NFL players who were drafted to teams coached by Andy Reid, showcasing their significant impact in the league",
    "color": "red",
    "images": []
  },
  {
    "category": "🏈 Wore Jersey #80",
    "header": "NFL Players Who Wore #80",
    "items": ["Isaac Bruce", "Andre Johnson", "Jimmy Graham", "Plaxico Burress"],
    "commonality": "Distinguished NFL players known for wearing the jersey number 80",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔠 NFL Players Last Names Starting with 'N'",
    "header": "NFL Players with Last Names Starting with N",
    "items": ["Puka Nacua", "Joe Namath", "Quenton Nelson", "David Njoku"],
    "commonality": "NFL players whose last names begin with the letter 'N'",
    "color": "purple",
    "images": []
  }
]

const categories24 = [
  {
    "category": "🏈 Players Not Drafted in 1st Round",
    "header": "NFL Players Who Weren't Drafted in the First Round",
    "items": ["Ladd McConkey", "Adonai Mitchell", "Ga'Quincy 'Kool-Aid' McKinstry", "Cooper DeJean"],
    "commonality": "NFL players who began their professional careers despite not being selected in the first round of the NFL Draft",
    "color": "red",
    "images": []
  },
  {
    "category": "♻️ Players Picked with a Traded Draft Pick",
    "header": "NFL Players Selected with a Traded Draft Pick",
    "items": ["Caleb Williams", "Dallas Turner", "Xavier Worthy", "Terrion Arnold"],
    "commonality": "NFL players who were drafted with picks that were acquired through trades between teams",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔢 Picks 12-15",
    "header": "NFL Players Drafted Between Picks 12-15",
    "items": ["Bo Nix", "Brock Bowers", "Taliese Fuaga", "Laiatu Latu"],
    "commonality": "NFL Draft selections made between the 12th and 15th overall picks, showcasing mid-first-round talent",
    "color": "green",
    "images": []
  },
  {
    "category": "🌉 Drafted by Team in California or Seattle",
    "header": "NFL Players Drafted by Teams in California or Seattle",
    "items": ["Ricky Pearsall", "Joe Alt", "Jared Verse", "Byron Murphy II"],
    "commonality": "NFL players who were drafted by teams located in California or the Seattle Seahawks",
    "color": "purple",
    "images": []
  }
]

const categories23 = [
  {
    "category": "⚡ Fastest 40-Yard Dashes at NFL Combine",
    "header": "NFL Combine Participants with the Fastest 40-Yard Dash Times",
    "items": ["Xavier Worthy", "Brian Thomas Jr.", "Adonai Mitchell", "Devontez Walker"],
    "commonality": "Prospects who recorded some of the fastest 40-yard dash times during their respective NFL Combine",
    "color": "red",
    "images": []
  },
  {
    "category": "🐺 Michigan Wolverines",
    "header": "Current Michigan Wolverines Football Players",
    "items": ["J.J. McCarthy", "Blake Corum", "Roman Wilson", "Cornelius Johnson"],
    "commonality": "Players who are part of the University of Michigan Wolverines football team",
    "color": "blue",
    "images": []
  },
  {
    "category": "🛡️ Defensive Top Picks",
    "header": "Top Defensive Picks",
    "items": ["Laiatu Latu", "Byron Murphy II", "Dallas Turner", "Jared Verse"],
    "commonality": "Highly-touted defensive players selected as top picks in the NFL Draft or prospects expected to be top picks",
    "color": "green",
    "images": []
  },
  {
    "category": "🏈 Fathers Also Played in the NFL",
    "header": "NFL Players Whose Fathers Also Played in the NFL",
    "items": ["Brenden Rice", "Frank Gore Jr.", "Luke McCaffrey", "Marvin Harrison Jr."],
    "commonality": "NFL players who have followed in their fathers' footsteps to play professional football",
    "color": "purple",
    "images": []
  }
]

const categories22 = [
  {
    "category": "🏆 2005 Heisman Trophy Finalists",
    "header": "Finalists for the 2005 Heisman Trophy",
    "items": ["Reggie Bush", "Vince Young", "Matt Leinart", "Brady Quinn"],
    "commonality": "College football stars who were finalists for the Heisman Trophy in 2005",
    "color": "purple",
    "images": []
  },
  {
    "category": "🎓 Attended Texas Tech University",
    "header": "NFL Players Who Attended Texas Tech",
    "items": ["Danny Amendola", "Patrick Mahomes", "Michael Crabtree", "Zach Thomas"],
    "commonality": "NFL players who played college football at Texas Tech University",
    "color": "red",
    "images": []
  },
  {
    "category": "🌆 First Name is a Famous U.S. City",
    "header": "NFL Players Whose First Names are U.S. Cities",
    "items": ["Dallas Clark", "Austin Seferian-Jenkins", "Orlando Brown Jr.", "Boston Scott"],
    "commonality": "NFL players with first names that are also famous cities in the United States",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔙 Comeback Player of the Year 2022 Voting Top 4",
    "header": "Top Four in the 2022 NFL Comeback Player of the Year Voting",
    "items": ["Geno Smith", "Christian McCaffrey", "Saquon Barkley", "Brandon Graham"],
    "commonality": "NFL players who were top contenders for the Comeback Player of the Year award in 2022",
    "color": "green",
    "images": []
  }
]

const categories21 = [
  {
    "category": "🎓 Attended University of Pittsburgh",
    "header": "NFL Players Who Attended the University of Pittsburgh",
    "items": ["Larry Fitzgerald", "Aaron Donald", "LeSean McCoy", "James Conner"],
    "commonality": "NFL players who played college football at the University of Pittsburgh",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔠 NFL Players Last Names Starting with 'A'",
    "header": "NFL Players Whose Last Names Start with A",
    "items": ["Davante Adams", "Josh Allen", "Mark Andrews", "Nnamdi Asomugha"],
    "commonality": "NFL players whose last names begin with the letter 'A'",
    "color": "green",
    "images": []
  },
  {
    "category": "🏃‍♂️ 90+ Yard Rush",
    "header": "NFL Players with a 90+ Yard Rush",
    "items": ["Lamar Miller", "Derrick Henry", "Herschel Walker", "Colin Kaepernick"],
    "commonality": "NFL players who have recorded a single rush of 90 yards or more during a game",
    "color": "red",
    "images": []
  },
  {
    "category": "👬 Brothers in the NFL",
    "header": "NFL Players with a Brother Also in the NFL",
    "items": ["J.J. Watt", "Eli Manning", "Rob Gronkowski", "Cam Newton"],
    "commonality": "NFL players who have a sibling that also plays or has played in the NFL",
    "color": "orange",
    "images": []
  }
]

const categories20 = [
  {
    "category": "🎓 Attended University of Louisville",
    "header": "NFL Players Who Attended the University of Louisville",
    "items": ["Teddy Bridgewater", "Mekhi Becton", "Lamar Jackson", "DeVante Parker"],
    "commonality": "NFL players who played college football at the University of Louisville",
    "color": "red",
    "images": []
  },
  {
    "category": "🥉 Third Overall NFL Draft Picks",
    "header": "NFL Players Drafted Third Overall",
    "items": ["Joey Bosa", "Sam Darnold", "Larry Fitzgerald", "Vince Young"],
    "commonality": "Outstanding NFL talents selected with the third overall pick in the NFL Draft",
    "color": "purple",
    "images": []
  },
  {
    "category": "🏃 NFL Rushing Touchdown Leaders",
    "header": "NFL Leaders in Rushing Touchdowns",
    "items": ["Raheem Mostert", "Josh Allen", "Jalen Hurts", "Christian McCaffrey"],
    "commonality": "NFL players who led the league in rushing touchdowns during the season",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔠 NFL Players Whose Name Starts with 'B'",
    "header": "NFL Players with Names Beginning with the Letter B",
    "items": ["Ben Roethlisberger", "Brandon Marshall", "Bruce Irvin", "Bo Jackson"],
    "commonality": "NFL players whose first names start with the letter 'B'",
    "color": "green",
    "images": []
  }
]

const categories19 = [
  {
    "category": "🎓 Attended University of Mississippi",
    "header": "NFL Players Who Attended the University of Mississippi",
    "items": ["Eli Manning", "Patrick Willis", "A.J. Brown", "Michael Oher"],
    "commonality": "Prominent NFL players who attended Ole Miss during their college football careers",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏆 2009 New Orleans Saints Super Bowl Champions",
    "header": "Players from the 2009 Super Bowl-Winning New Orleans Saints",
    "items": ["Jeremy Shockey", "Jonathan Vilma", "Reggie Bush", "Darren Sharper"],
    "commonality": "Key contributors to the New Orleans Saints during their 2009 Super Bowl championship season",
    "color": "purple",
    "images": []
  },
  {
    "category": "🌟 Top 4 in 2010 NFL AP Offensive Player of the Year Voting",
    "header": "Top Finishers in the 2010 NFL AP Offensive Player of the Year",
    "items": ["Tom Brady", "Michael Vick", "Arian Foster", "Philip Rivers"],
    "commonality": "NFL players who were top contenders for the AP Offensive Player of the Year award in 2010",
    "color": "green",
    "images": []
  },
  {
    "category": "🔠 NFL Players Whose Name Starts with 'C'",
    "header": "NFL Players with First Names Starting with 'C'",
    "items": ["Cameron Jordan", "Christian McCaffrey", "Chris Jones", "Curtis Martin"],
    "commonality": "NFL players whose first names begin with the letter 'C', showcasing a diverse range of talent",
    "color": "red",
    "images": []
  }
]

const categories18 = [
  {
    "category": "🎓 Attended Notre Dame",
    "header": "NFL Players Who Attended Notre Dame",
    "items": ["Zack Martin", "Joe Montana", "Golden Tate", "DeShone Kizer"],
    "commonality": "NFL players who played college football at the University of Notre Dame",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏈 Caught Touchdowns from Joe Flacco",
    "header": "Players Who Caught a TD Pass from Joe Flacco",
    "items": ["Amari Cooper", "Breece Hall", "Mark Andrews", "Steve Smith Sr."],
    "commonality": "NFL wide receivers and tight ends who have caught touchdown passes thrown by quarterback Joe Flacco",
    "color": "purple",
    "images": []
  },
  {
    "category": "🥈 #2 Overall NFL Draft Picks",
    "header": "NFL Players Drafted #2 Overall",
    "items": ["Carson Wentz", "Von Miller", "Donovan McNabb", "Marshall Faulk"],
    "commonality": "Elite NFL talents who were selected with the #2 overall pick in the NFL Draft",
    "color": "red",
    "images": []
  },
  {
    "category": "🏆 Comeback Player of the Year Winners",
    "header": "NFL Comeback Player of the Year Award Recipients",
    "items": ["Rob Gronkowski", "Jordy Nelson", "Tedy Bruschi", "Keenan Allen"],
    "commonality": "NFL players who have won the Comeback Player of the Year award, signifying a return to form after adversity",
    "color": "green",
    "images": []
  }
]

const categories17 = [
  {
    "category": "🎓 Attended Texas A&M University",
    "header": "NFL Players Who Attended Texas A&M University",
    "items": ["Ryan Tannehill", "Myles Garrett", "Johnny Manziel", "Christian Kirk"],
    "commonality": "NFL players who played college football for the Texas A&M Aggies",
    "color": "red",
    "images": []
  },
  {
    "category": "🏈 2024 NFL Sack Leaders",
    "header": "Top NFL Sack Leaders in 2024",
    "items": ["T.J. Watt", "Trey Hendrickson", "Josh Allen", "Khalil Mack"],
    "commonality": "NFL defensive players who led the league in quarterback sacks during the 2024 season",
    "color": "purple",
    "images": []
  },
  {
    "category": "💪 First 4 Defensive Front Seven Players Drafted in 2015",
    "header": "First Four Defensive Linemen/Outside Linebackers Drafted in 2015",
    "items": ["Dante Fowler Jr.", "Leonard Williams", "Vic Beasley Jr.", "Danny Shelton"],
    "commonality": "The first four defensive linemen or outside linebackers selected in the 2015 NFL Draft",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔢 NFL Players Who Wear #1",
    "header": "NFL Players Donning the #1 Jersey",
    "items": ["Ahmad 'Sauce' Gardner", "Cam Newton", "Pat McAfee", "Ja'Marr Chase"],
    "commonality": "NFL players known for wearing the number 1 on their jerseys",
    "color": "green",
    "images": []
  }
]

const categories16 = [
  {
    "category": "🏈 2024 Conference Game QBs",
    "header": "Quarterbacks Who Played in Conference Games",
    "items": ["Patrick Mahomes", "Lamar Jackson", "Jared Goff", "Brock Purdy"],
    "commonality": "Quarterbacks who have played in conference games",
    "color": "orange",
    "images": [
      "https://a.espncdn.com/i/headshots/nfl/players/full/3139477.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3916387.png&w=350&h=254",
      "https://a.espncdn.com/i/headshots/nfl/players/full/3046779.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4361741.png"
    ]
  },
  {
    "category": "🎓 Attended Oregon",
    "header": "NFL Players Who Attended the University of Oregon",
    "items": ["Justin Herbert", "Arik Armstead", "LeGarrette Blount", "Kayvon Thibodeaux"],
    "commonality": "NFL players who honed their skills playing for the University of Oregon",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4038941.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2971275.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13213.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4426326.png&w=350&h=254"
    ]
  },
  {
    "category": "🎉 Drafted #7 Overall",
    "header": "NFL Players Who Were Drafted #7 Overall",
    "items": ["Josh Allen", "Mike Evans", "Adrian Peterson", "Mike Williams"],
    "commonality": "NFL players who were selected as the seventh overall picks in their respective draft years",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3918298.png&w=350&h=254",
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/w6eq27k2xs8vawwgkzbl",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/10452.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3045138.png&w=350&h=254"
    ]
  },
  {
    "category": "🐬 Drafted by Dolphins",
    "header": "NFL Players Drafted by the Miami Dolphins",
    "items": ["Tua Tagovailoa", "Kenyan Drake", "Vontae Davis", "Ted Ginn Jr."],
    "commonality": "NFL players who were drafted by the Miami Dolphins",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4241479.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2979843.png",
      "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/t0d66dvypk5mlma4ommp",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/10453.png"
    ]
  }
]

const categories15 = [
  {
    "category": "🎓 Attended Stanford University",
    "header": "NFL Players Who Attended Stanford University",
    "items": ["Christian McCaffrey", "John Elway", "Zach Ertz", "Richard Sherman"],
    "commonality": "NFL players who played college football at Stanford University",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3117251.png&w=350&h=254",
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/qt8ysjlaemnfczfe9ffl",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15835.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/14086.png&w=350&h=254"
    ]  },
  {
    "category": "🛫 Played for the New York Jets",
    "header": "NFL Players Who Played for the New York Jets",
    "items": ["Ryan Fitzpatrick", "Matt Forte", "C.J. Uzomah", "Darrelle Revis"],
    "commonality": "Notable NFL players who have been part of the New York Jets franchise",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/8664.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/11278.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2574576.png&w=350&h=254",
      "https://a.espncdn.com/i/headshots/nfl/players/full/10458.png"
    ]  },
  {
    "category": "🏆 Playoff Receptions Leaders",
    "header": "NFL Playoff Reception Leaders",
    "items": ["Travis Kelce", "Jerry Rice", "Julian Edelman", "Rob Gronkowski"],
    "commonality": "NFL players who have recorded the most receptions in NFL playoff history",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15847.png&w=350&h=254",
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/g1vjcnbryx3nmg0ppsnl",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/12649.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13229.png"
    ]  },
  {
    "category": "🥈 Drafted #2 Overall",
    "header": "NFL Players Drafted 2nd Overall",
    "items": ["C.J. Stroud", "Saquon Barkley", "Calvin Johnson", "Chris Long"],
    "commonality": "Elite NFL talents selected with the #2 pick in the NFL Draft",
    "color": "purple",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4432577.png&w=350&h=254",
      "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/o8tlhps5u1tvnaaxlpjk",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/10447.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/11236.png"
    ]  }
]
const categories14 = [
  {
    "category": "🅰️ NFL Players Whose Name Starts with 'R'",
    "header": "NFL Players with Names Beginning with the Letter R",
    "items": ["Rob Gronkowski", "Reggie Wayne", "Russell Wilson", "Randall Cobb"],
    "commonality": "Prominent NFL players whose first names start with the letter 'R'",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13229.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2578.png",
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/gebzv2aljujgleboe4ns",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/14053.png&w=350&h=254"
    ]
  },
  {
    "category": "🦅 2017-18 Super Bowl Champion Philadelphia Eagles",
    "header": "Members of the 2017-18 Super Bowl-Winning Philadelphia Eagles",
    "items": ["Jay Ajayi", "Torrey Smith", "Jordan Hicks", "Zach Ertz"],
    "commonality": "NFL players who were part of the Philadelphia Eagles roster that won the Super Bowl in the 2017-18 season",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2573300.png",
      "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/sqvbn4ybxagyfwva3i51",
      "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/z7mzakfzvfrvf3oxnsvq",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15835.png"
    ]
  },
  {
    "category": "🐏 Attended University of North Carolina",
    "header": "NFL Players Who Attended UNC",
    "items": ["Jeff Saturday", "Hakeem Nicks", "Julius Peppers", "Mitchell Trubisky"],
    "commonality": "NFL players who played college football at the University of North Carolina at Chapel Hill",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2055.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/12586.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3530.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3039707.png"
    ]
  },
  {
    "category": "🏃‍♂️ 75+ Yard Reception in the 2023 Season",
    "header": "NFL Players with a 75+ Yard Reception in 2023",
    "items": ["Gus Edwards", "George Pickens", "Puka Nacua", "Mike Evans"],
    "commonality": "NFL players who caught a pass for 75 yards or more during the 2023 season",
    "color": "purple",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3051926.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4426354.png&w=350&h=254",
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/br1ovzclyrebrec6q0mp",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/16737.png"
    ]
  }
]

const categories13 = [
  {
    "category": "🏆 2021 Heisman Trophy Top 4",
    "header": "Finalists for the 2021 Heisman Trophy",
    "items": ["Bryce Young", "Aidan Hutchinson", "Kenny Pickett", "C.J. Stroud"],
    "commonality": "College football players who were finalists for the Heisman Trophy in 2021, showcasing exceptional skill and performance",
    "color": "orange",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4685720.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4372099.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4240703.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4432577.png&w=350&h=254"]
  },
  {
    "category": "🦁 Attended Penn State University",
    "header": "NFL Players from Penn State University",
    "items": ["Allen Robinson II", "Saquon Barkley", "Micah Parsons", "Kerry Collins"],
    "commonality": "NFL players who honed their talents at Penn State University, known for its storied football program",
    "color": "red",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/16799.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3929630.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4361423.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/734.png"]
  },
  {
    "category": "🗽 Current New York Giants",
    "header": "Players Currently on the New York Giants Roster",
    "items": ["Devin Singletary", "Brian Burns", "Drew Lock", "Darren Waller"],
    "commonality": "NFL players who are currently part of the New York Giants team, contributing to the franchise",
    "color": "blue",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4040761.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4035631.png","https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/ca13jf9qaaosixobsdb0","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2576925.png"]
  },
  {
    "category": "🏈 4th Round NFL Draft Picks",
    "header": "NFL Stars Drafted in the 4th Round",
    "items": ["Darren Sproles", "Everson Griffen", "Kirk Cousins", "Brandon Marshall"],
    "commonality": "Prominent NFL players who were selected in the fourth round of the NFL Draft and made a significant impact in the league",
    "color": "purple",
    "images": ["https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/gvj5ng27blmwuz170aeo","https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/fqvc0dkghdix5fq9tfaq","https://static.www.nfl.com/image/private/t_headshot_desktop/league/viuxnyezrwdfjnxsfssb","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/9705.png"]
  }
]

const categories12 = [
  {
    "category": "🌀 Attended University of Miami",
    "header": "NFL Players Who Attended the University of Miami",
    "items": ["Devin Hester", "Braxton Berrios", "Jeremy Shockey", "Ray Lewis"],
    "commonality": "Distinguished NFL players who played college football at the University of Miami, also known as 'The U'",
    "color": "orange",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/9643.png&w=350&h=254","https://static.www.nfl.com/image/private/t_headshot_desktop/league/nypqf5vnhegeorkpmplr","https://a.espncdn.com/i/headshots/nfl/players/full/3542.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/964.png"]
  },
  {
    "category": "🐂 Played for Houston Texans",
    "header": "Notable Players for the Houston Texans",
    "items": ["J.J. Watt", "Jadeveon Clowney", "Arian Foster", "Matt Schaub"],
    "commonality": "Impactful NFL players who have been key members of the Houston Texans team",
    "color": "red",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13979.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/16734.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/12497.png&w=350&h=254","https://a.espncdn.com/i/headshots/nfl/players/full/5615.png"]
  },
  {
    "category": "⏳ 19+ NFL Seasons",
    "header": "NFL Players with Careers Spanning 19 or More Seasons",
    "items": ["Jason Peters", "Clay Matthews", "Jerry Rice", "Tom Brady"],
    "commonality": "Elite NFL players whose careers have been extended across 19 seasons or more, showcasing longevity and durability",
    "color": "blue",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/6012.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/12438.png","https://static.www.nfl.com/image/private/t_headshot_desktop/league/g1vjcnbryx3nmg0ppsnl","https://static.www.nfl.com/image/private/t_headshot_desktop/league/q7dpdlxyu5rs05rgh1le"]
  },
  {
    "category": "🏅 Top 4 in 2008 NFL MVP Voting",
    "header": "Top 4 Finishers in the 2008 NFL MVP Voting",
    "items": ["Peyton Manning", "Chad Pennington", "Michael Turner", "James Harrison"],
    "commonality": "NFL players who were in the top 4 for the Most Valuable Player award voting in 2008",
    "color": "green",
    "images": ["https://static.www.nfl.com/image/private/t_headshot_desktop/league/xpcd6auodk3w0vda6jal","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2149.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/5679.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4433.png"]
  }
]

const categories11 = [
  {
    "category": "🌽 Went to University of Nebraska",
    "header": "NFL Players from the University of Nebraska",
    "items": ["Ndamukong Suh", "Ameer Abdullah", "William Compton", "Rex Burkhead"],
    "commonality": "Prominent NFL players who attended and played football for the University of Nebraska Cornhuskers",
    "color": "red",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13234.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2576336.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/16324.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15971.png"]
  },
  {
    "category": "🏆 2016 Heisman Trophy Finalists",
    "header": "Finalists for the 2016 Heisman Trophy",
    "items": ["Lamar Jackson", "Baker Mayfield", "Dede Westbrook", "Jabrill Peppers"],
    "commonality": "Elite college football players who were finalists for the Heisman Trophy in 2016",
    "color": "blue",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3916387.png&w=350&h=254","https://static.www.nfl.com/image/private/t_headshot_desktop/league/haglbmidyaxtimhctzlp","https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/jjlxw2ircxecpx3ipeax","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3115962.png"]
  },
  {
    "category": "🐻 2014 Chicago Bears Roster",
    "header": "Players from the 2014 Chicago Bears",
    "items": ["Martellus Bennett", "Jared Allen", "Kyle Fuller", "Matt Forte"],
    "commonality": "Key NFL players who were part of the Chicago Bears squad in 2014",
    "color": "orange",
    "images": ["https://a.espncdn.com/i/headshots/nfl/players/full/11295.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/5651.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/16715.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/11278.png"]
  },
  {
    "category": "🥇 First Pick of the 2nd NFL Draft Round",
    "header": "NFL Players Drafted as the First Pick in the 2nd Round",
    "items": ["Drew Brees", "DeMeco Ryans", "Tee Higgins", "Joey Porter Jr."],
    "commonality": "NFL players selected as the very first pick of the second round in their respective NFL Draft years",
    "color": "green",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2580.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/9619.png","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4239993.png&w=350&h=254","https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4426506.png"]
  }
]

const categories10 = [
  {
    "category": "🛑 Sacked 60+ Times in a Season",
    "header": "Quarterbacks Sacked Over 60 Times in a Single Season",
    "items": ["Bryce Young", "Deshaun Watson", "Jon Kitna", "Sam Howell"],
    "commonality": "NFL quarterbacks who have endured the misfortune of being sacked more than 60 times in a single season",
    "color": "red",
    "images": []
  },
  {
    "category": "🚀 6+ Passing TDs in a Single Game",
    "header": "Quarterbacks with 6+ Touchdown Passes in a Game",
    "items": ["Tua Tagovailoa", "Mitchell Trubisky", "Matt Flynn", "Ryan Fitzpatrick"],
    "commonality": "NFL quarterbacks who have achieved the remarkable feat of throwing for six or more touchdowns in a single game",
    "color": "orange",
    "images": []
  },
  {
    "category": "💔 Started and Lost in the Super Bowl",
    "header": "Quarterbacks Who Started and Lost a Super Bowl",
    "items": ["Rex Grossman", "Donovan McNabb", "Jake Delhomme", "Jalen Hurts"],
    "commonality": "NFL quarterbacks who have led their teams to the Super Bowl only to fall short of the championship",
    "color": "blue",
    "images": []
  },
  {
    "category": "🌟 Undrafted Quarterback Success Stories",
    "header": "Successful Undrafted NFL Quarterbacks",
    "items": ["Tony Romo", "Warren Moon", "Case Keenum", "Jeff Garcia"],
    "commonality": "Remarkable quarterbacks who made it to the NFL and succeeded despite not being selected in the NFL Draft",
    "color": "green",
    "images": []
  }
]

const categories9 = [
  {
    "category": "🐻 Drafted by the Chicago Bears",
    "header": "Players Drafted by the Chicago Bears",
    "items": ["Kevin White", "Alshon Jeffery", "Kyle Long", "Roquan Smith"],
    "commonality": "NFL players who were drafted by the Chicago Bears, one of the storied franchises in the league",
    "color": "red",
    "images": []
  },  {
    "category": "✨ Matching First and Last Initials",
    "header": "NFL Players Whose First and Last Names Start with the Same Letter",
    "items": ["Justin Jefferson", "Calais Campbell", "Demario Davis", "Tyrod Taylor"],
    "commonality": "NFL players known for having the same initial for both their first and last names",
    "color": "green",
    "images": []
  },
  {
    "category": "🎓 Went to University of Michigan",
    "header": "NFL Players from the University of Michigan",
    "items": ["Jake Moody", "Brandon Graham", "Jake Long", "Braylon Edwards"],
    "commonality": "Prominent NFL players who attended and played football at the University of Michigan",
    "color": "blue",
    "images": []
  },
  {
    "category": "🌍 Born Outside the US",
    "header": "NFL Players Born Outside the United States",
    "items": ["Jordan Mailata", "Danielle Hunter", "Nelson Agholor", "Cairo Santos"],
    "commonality": "NFL players who were born outside the United States, showcasing the international diversity of the league",
    "color": "purple",
    "images": []
  },



]


const categories8 = [
  {
    "category": "🌊 2017 Seattle Seahawks Backfield",
    "header": "2017 Seattle Seahawks Running Back Corps",
    "items": ["Mike Davis", "Eddie Lacy", "J.D. McKissic", "Thomas Rawls"],
    "commonality": "The core group of running backs for the Seattle Seahawks in the 2017 season",
    "color": "blue",
    "images": []
  },
  {
    "category": "🌉 2018 San Francisco 49ers Backfield",
    "header": "2018 San Francisco 49ers Running Back Ensemble",
    "items": ["Matt Breida", "Alfred Morris", "Jeffery Wilson Jr.", "Raheem Mostert"],
    "commonality": "The ensemble of running backs that represented the San Francisco 49ers in the 2018 season",
    "color": "orange",
    "images": []
  },
  {
    "category": "🧀 Wisconsin Alumni",
    "header": "NFL Rushing Stars from Wisconsin University",
    "items": ["Montee Ball", "James White", "Melvin Gordon", "Jonathan Taylor"],
    "commonality": "Outstanding NFL running backs who harnessed their early skills at the University of Wisconsin",
    "color": "red",
    "images": []
  },
  {
    "category": "🏴‍☠️ Raiders Drafted",
    "header": "Stars Drafted by the Las Vegas Raiders",
    "items": ["Latavius Murray", "Darren McFadden", "Bo Jackson", "Josh Jacobs"],
    "commonality": "Elite NFL talent drafted into the league by the Raiders' franchise",
    "color": "purple",
    "images": []
  }
]

const categories7 = [

  {
    "category": "🔥 Multiple 2+ TD Games in 2023 Season",
    "items": ["Cole Kmet", "James Conner", "Travis Etienne", "Josh Allen"],
    "commonality": "Players with multiple games scoring 2 or more touchdowns in the 2023 NFL season",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4258595.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3045147.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4239996.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3918298.png&w=350&h=254"
    ]
  },
  {
    "category": "🎓 FSU Alumni in the NFL",
    "items": ["Dalvin Cook", "Cam Akers", "Asante Samuel Jr.", "Brian Burns"],
    "commonality": "NFL players who attended Florida State University",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3116593.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4240021.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4363034.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4035631.png"
    ]
  },
  {
    "category": "🏆 2007 Undefeated Regular Season Patriots",
    "items": ["Mike Vrabel", "Vince Wilfork", "Asante Samuel", "Matt Cassel"],
    "commonality": "Players who were part of the New England Patriots roster during their undefeated 2007 regular season",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/1257.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/5546.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4363034.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/8644.png"
    ]
  },
  {
    "category": "🛡️ Pick 6 Against Tom Brady",
    "items": ["Ty Law", "Jordan Poyer", "Logan Ryan", "Malcolm Jenkins"],
    "commonality": "Defensive players who have intercepted Tom Brady and returned the interception for a touchdown",
    "color": "purple",
    "images": [
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/eepyfx4wk22s1kctoeqk",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15979.png",
      "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/cx2auxfuxnuduesro2ww",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/12426.png"
    ]
  }
]

const categories6 = [

  {
    "category": "🏈 Current Bills Receivers",
    "header": "Buffalo Bills Wide Receivers",
    "items": ["Mack Hollins", "Andy Isabella", "Curtis Samuel", "Khalil Shakir"],
    "commonality": "Wide receivers currently on the Buffalo Bills roster",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2991662.png&w=350&h=254",
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/s3repurenhwlyxci2sqy",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3121427.png",
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/l1flqgo30hfowrdckdeu"
    ]
  },
  {
    "category": "🌟 1000+ Yards Receiving Last Season",
    "header": "1000+ Yards Receivers in the Last NFL Season",
    "items": ["Calvin Ridley", "Adam Thielen", "Jaylen Waddle", "Terry McLaurin"],
    "commonality": "NFL wide receivers who recorded over 1000 receiving yards last season",
    "color": "green",
    "images": [
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/jnknuowszoqaywqoopy7",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/16460.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4372016.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3121422.png&w=350&h=254"
    ]
  },
  {
    "category": "⚡ Receivers to Run Under 4.30 40 Yard Dash",
    "header": "Fastest NFL Receivers: Sub-4.30 40 Yard Dash",
    "items": ["John Ross III", "Marquise Goodwin", "Tyquan Thornton", "Mike Wallace"],
    "commonality": "NFL receivers who have run the 40-yard dash in under 4.30 seconds",
    "color": "orange",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3052177.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15839.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4362921.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/12601.png&w=350&h=254"
    ]
  },
  {
    "category": "🏈 Thrown TD Pass",
    "header": "Receivers Who Have Thrown a TD Pass",
    "items": ["Mohamed Sanu", "Braxton Berrios", "Larry Fitzgerald", "Jarvis Landry"],
    "commonality": "Wide receivers who have thrown a touchdown pass",
    "color": "red",
    "images": [
      "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/a6loroqhta6g65aqnsrk",
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/nypqf5vnhegeorkpmplr",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/5528.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/16790.png&w=350&h=254"
    ]
  }
]

const categories5 = [
  {
    "category": "🔒 5+ Interceptions 2023 and 2024 Seasons",
    "header": "Defensive Playmakers 2023-2024",
    "items": ["Rasul Douglas", "DaRon Bland", "Geno Stone", "Jessie Bates"],
    "commonality": "NFL players with 5 or more interceptions across the 2023 and 2024 seasons",
    "color": "teal",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3943270.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4248911.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4240575.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3919512.png&w=350&h=254"
    ]
  },
  {
    "category": "🏈 2020 NFL Draft Top 4 Picks",
    "header": "Top Picks of the 2020 NFL Draft",
    "items": ["Joe Burrow", "Chase Young", "Jeff Okudah", "Andrew Thomas"],
    "commonality": "Players selected in the top 4 of the 2020 NFL Draft",
    "color": "orange",
    "images": [
      "https://static.www.nfl.com/image/private/t_headshot_desktop/league/jzzqz5ubkilrn9dpxtlp",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4241986.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4241984.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4259566.png"
    ]
  },
  {
    "category": "🌟 USC Players in NFL",
    "header": "USC Alumni in the NFL",
    "items": ["Michael Pittman Jr.", "Sam Darnold", "Tyron Smith", "Leonard Williams"],
    "commonality": "NFL players who attended the University of Southern California",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4035687.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3912547.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13978.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2971622.png"
    ]
  },
  {
    "category": "🔥 Most Interceptions 2023-2024 Season",
    "header": "Quarterbacks with Most Interceptions in 2023-2024",
    "items": ["Sam Howell", "Josh Allen", "Jalen Hurts", "Patrick Mahomes"],
    "commonality": "Quarterbacks with the most interceptions in the 2023-2024 NFL season",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4426875.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3918298.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4040715.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3139477.png"
    ]
  }
]

const categories4 = [
  {
    "category": "🐾 Current Carolina Panther",
    "items": ["Jadeveon Clowney", "Diontae Johnson", "Miles Sanders", "Johnny Hekker"],
    "commonality": "Players currently on the Carolina Panthers roster",
    "color": "orange",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/16734.png&w=350&h=254", "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3932905.png", "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4045163.png", "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15153.png"]
  },
  {
    "category": "📺 CBS Sports Football Crew",
    "items": ["Phil Simms", "Nate Burleson", "Boomer Esiason", "J.J. Watt"],
    "commonality": "Members of the CBS Sports NFL broadcasting team",
    "color": "red",
    "images": ["https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/lhzj6vxddl0epe3sj0h8", "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4529.png", "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/afg59qxfnusj3ebuqwyv", "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13979.png&w=350&h=254"]
  },
  {
    "category": "🏆 Most Receiving Yards by a Tight End in 2015",
    "items": ["Rob Gronkowski", "Greg Olsen", "Delanie Walker", "Gary Barnidge"],
    "commonality": "Tight ends with the most receiving yards in the NFL during the 2015 season",
    "color": "blue",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13229.png", "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/10475.png", "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/9761.png", "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/11364.png&w=350&h=254"]
  },
  {
    "category": "👕 Wore #81",
    "items": ["Owen Daniels", "Amani Toomer", "Terrell Owens", "Tim Brown"],
    "commonality": "NFL players known for wearing the #81 jersey",
    "color": "green",
    "images": ["https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/9684.png&w=350&h=254", "https://media.gettyimages.com/id/75574727/photo/east-rutherford-nj-amani-toomer-of-the-new-york-giants-poses-for-his-2007-nfl-headshot-at-photo.jpg?s=612x612&w=gi&k=20&c=TfX6FftZFV8Bj2I7CpDX8hZRtSXwO6Br5qnhd4JFa7s=", "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/1056.png", "https://static.www.nfl.com/image/private/t_headshot_desktop/league/ra9kiq4ocliinelsnhlw"]
  }
]

const categories3 = [

  
    {
      "category": "📊 Led NFL in Catches in 2022-2023 Season",
      "items": ["Justin Jefferson", "Tyreek Hill", "Travis Kelce", "Stefon Diggs"],
      "commonality": "Players who led the NFL in total receptions during the 2022-2023 season",
      "color": "orange",
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4262921.png&w=350&h=254",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3116406.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15847.png&w=350&h=254",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2976212.png&w=350&h=254"
      ]
    },
    {
      "category": "🏈 Played QB in College, Not in NFL",
      "items": ["Denard Robinson", "Julian Edelman", "Brad Smith", "Terrelle Pryor"],
      "commonality": "Players who were quarterbacks in college but played different positions in the NFL",
      "color": "red",
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15951.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/12649.png&w=350&h=254",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/9689.png&w=350&h=254",
        "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/ac6blk5o4ztsr7hvtil9"
      ]
    },
    {
      "category": "💨 99 Yard Touchdowns",
      "items": ["Victor Cruz", "Wes Welker", "Tony Dorsett", "Derrick Henry"],
      "commonality": "Players who have scored a 99-yard touchdown, either rushing or receiving, in an NFL game",
      "color": "blue",
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13553.png&w=350&h=254",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/5941.png",
        "https://static.www.nfl.com/image/private/t_headshot_desktop/league/b6vvjgq2tpxrzhvwjt1y",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3043078.png&w=350&h=254"
      ]
    },
    {
      "category": "🔢 Known for #3 Jersey Number",
      "items": ["Russell Wilson", "Carson Palmer", "Stephen Gostkowski", "Damar Hamlin"],
      "commonality": "Players well-known for wearing the #3 jersey in the NFL",
      "color": "purple",
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/14881.png&w=350&h=254",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4459.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/9704.png",
        "https://static.www.nfl.com/image/private/t_headshot_desktop/league/pxgkvzqut7j3ypcafayo"
      ]
    }
  ]
const categories2 = [
    {
      "category": "🏈 Current Houston Texan",
      "items": ["Danielle Hunter", "Case Keenum", "Joe Mixon", "Robert Woods"],
      "commonality": "Players currently on the Houston Texans roster",
      "color": "orange", // Changing to orange for visibility
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2976560.png&w=350&h=254",
        "https://static.www.nfl.com/image/private/t_headshot_desktop/league/tymwoe3wpwzln78uzbvu",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3116385.png&w=350&h=254",
        "https://a.espncdn.com/i/headshots/nfl/players/full/15880.png"
      ]
    },

    {
      "category": "📈 QBs Drafted before Brock Purdy in 2022",
      "items": ["Kenny Pickett", "Desmond Ridder", "Bailey Zappe", "Sam Howell"],
      "commonality": "Quarterbacks selected in the 2022 NFL Draft before Brock Purdy",
      "color": "red",
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4240703.png&w=350&h=254",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4239086.png&w=350&h=254",
        "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/qr1opoisbs0vmu2pm8rz",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4426875.png&w=350&h=254"
      ]
    },
    {
      "category": "🌟 10+ Receiving Touchdowns in 2023-2024",
      "items": ["Jordan Addison", "Sam LaPorta", "CeeDee Lamb", "Amon-Ra St. Brown"],
      "commonality": "Players who recorded 10 or more receiving touchdowns in the 2023-2024 NFL season",
      "color": "blue",
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4429205.png&w=350&h=254",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4430027.png&w=350&h=254",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4241389.png&w=350&h=254",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4374302.png&w=350&h=254"
      ]
    },    {
        "category": "🏆 CFP National Champions",
        "items": ["Hunter Renfrow", "Ezekiel Elliott", "James Cook", "Joe Burrow"],
        "commonality": "Players who have won a College Football Playoff National Championship",
        "color": "purple",
        "images": [
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3135321.png",
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3051392.png&w=350&h=254",
          "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4379399.png&w=350&h=254",
          "https://static.www.nfl.com/image/private/t_headshot_desktop/league/jzzqz5ubkilrn9dpxtlp"
        ]
      },
  ];
  
// Puzzle number constant
const categories1 = [
    {
      "category": "🏈 Super Bowl MVPs from the 2010s",
      "items": ["Tom Brady", "Eli Manning", "Nick Foles", "Patrick Mahomes"],
      "commonality": "Players who won the Super Bowl MVP award during the 2010s",
      "color": "red",
      "images": [
        "https://static.www.nfl.com/image/private/t_player_profile_landscape_2x/f_auto/league/q7dpdlxyu5rs05rgh1le", // Tom Brady (Assuming placeholder since no specific image URL provided)
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/5526.png&w=350&h=254", // Eli Manning
        "https://a.espncdn.com/i/headshots/nfl/players/full/14877.png", // Nick Foles
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3139477.png&w=350&h=254"  // Patrick Mahomes
      ]
    },
    {
      "category": "🥇 NFL Draft First Overall Picks",
      "items": ["Kyler Murray", "Baker Mayfield", "Jared Goff", "Jameis Winston"],
      "commonality": "Players who were selected as the first overall pick in the NFL Draft",
      "color": "green",
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3917315.png&w=350&h=254", // Kyler Murray
        "https://static.www.nfl.com/image/private/t_headshot_desktop/league/haglbmidyaxtimhctzlp", // Baker Mayfield
        "https://a.espncdn.com/i/headshots/nfl/players/full/3046779.png", // Jared Goff
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2969939.png&w=350&h=254"  // Jameis Winston
      ]
    },
    {
      "category": "🎓 College Football Powerhouses: Alabama Alumni",
      "items": ["Derrick Henry", "Julio Jones", "Calvin Ridley", "Tua Tagovailoa"],
      "commonality": "NFL players who attended the University of Alabama",
      "color": "blue",
      "images": [
        "https://static.www.nfl.com/image/private/t_headshot_desktop/league/xpewyoieoj7zeub4fvff", // Derrick Henry
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/13982.png&w=350&h=254", // Julio Jones
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3925357.png", // Calvin Ridley
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4241479.png&w=350&h=254"  // Tua Tagovailoa
      ]
    },
    {
      "category": "🌟 NFL Comeback Players of the Year",
      "items": ["Adrian Peterson", "Matthew Stafford", "Alex Smith", "Ryan Tannehill"],
      "commonality": "Players who won the NFL Comeback Player of the Year award",
      "color": "purple",
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/10452.png", // Adrian Peterson
        "https://static.www.nfl.com/image/private/t_headshot_desktop/league/icjfdfsncqlsqe49iujv", // Matthew Stafford
        "https://a.espncdn.com/i/headshots/nfl/players/full/8416.png", // Alex Smith
        "https://static.www.nfl.com/image/private/t_player_profile_landscape/f_auto/league/yvwju12dl6haf3r8uzrm"  // Ryan Tannehill
      ]
    }
  ];
  
  
  

  

// Simulate loading categories for different puzzles (You should replace this with actual logic to load different puzzles based on selection)
const loadCategoriesForPuzzle = (puzzleNumber) => {
  const puzzles = {

    '1': categories1, // Categories set for puzzle 4
    '2': categories2, // Categories set for puzzle 4
    '3': categories3, // Categories set for puzzle 4
    '4': categories4, // Categories set for puzzle 4
    '5': categories5, // Categories set for puzzle 4
    '6': categories6, // Categories set for puzzle 4
    '7': categories7, // Categories set for puzzle 4
    '8': categories8, // Categories set for puzzle 4
    '9': categories9, // Categories set for puzzle 4
    '10': categories10, // Categories set for puzzle 4
    '11': categories11, // Categories set for puzzle 4
    '12': categories12, // Categories set for puzzle 4
    '13': categories13, // Categories set for puzzle 4
    '14': categories14, // Categories set for puzzle 4
    '15': categories15, // Categories set for puzzle 4
    '16': categories16, // Categories set for puzzle 4
    '17': categories17, // Categories set for puzzle 4
    '18': categories18, // Categories set for puzzle 4
    '19': categories19, // Categories set for puzzle 4
    '20': categories20, // Categories set for puzzle 4
    '21': categories21, // Categories set for puzzle 4
    '22': categories22, // Categories set for puzzle 4
    '23': categories23, // Categories set for puzzle 4
    '24': categories24, // Categories set for puzzle 4
    '25': categories25, // Categories set for puzzle 4
    '26': categories26, // Categories set for puzzle 4
    '27': categories27, // Categories set for puzzle 4
    '28': categories28, // Categories set for puzzle 4
    '29': categories29, // Categories set for puzzle 4

    '30': categories30, // Categories set for puzzle 4

  };

  return puzzles[puzzleNumber] || [];
};

// Function to get puzzle numbers dynamically from the puzzles object
const getPuzzleNumbers = () => {
  const puzzles = {
    '1': categories1, // Categories set for puzzle 4
    '2': categories2, // Categories set for puzzle 4
    '3': categories3, // Categories set for puzzle 4
    '4': categories4, // Categories set for puzzle 4
    '5': categories5, // Categories set for puzzle 4
    '6': categories6, // Categories set for puzzle 4
    '7': categories7, // Categories set for puzzle 4
    '8': categories8, // Categories set for puzzle 4
    '9': categories9, // Categories set for puzzle 4
    '10': categories10, // Categories set for puzzle 4
    '11': categories11, // Categories set for puzzle 4
    '12': categories12, // Categories set for puzzle 4
    '13': categories13, // Categories set for puzzle 4
    '14': categories14, // Categories set for puzzle 4
    '15': categories15, // Categories set for puzzle 4
    '16': categories16, // Categories set for puzzle 4
    '17': categories17, // Categories set for puzzle 4
    '18': categories18, // Categories set for puzzle 4
    '19': categories19, // Categories set for puzzle 4
    '20': categories20, // Categories set for puzzle 4
    '21': categories21, // Categories set for puzzle 4
    '22': categories22, // Categories set for puzzle 4
    '23': categories23, // Categories set for puzzle 4
    '24': categories24, // Categories set for puzzle 4
    '25': categories25, // Categories set for puzzle 4
    '26': categories26, // Categories set for puzzle 4
    '27': categories27, // Categories set for puzzle 4
    '28': categories28, // Categories set for puzzle 4
    '29': categories29, // Categories set for puzzle 4
    '30': categories30, // Categories set for puzzle 4

  };

  return Object.keys(puzzles);
};


// Function to shuffle array items
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const ConnectionsGame = () => {
  
  const [puzzleNumber, setPuzzleNumber] = useState("30"); // Default puzzle number
  const [selectedItems, setSelectedItems] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [foundCategories, setFoundCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [gameEndedSuccessfully, setGameEndedSuccessfully] = useState(false);
  const [categories, setCategories] = useState(loadCategoriesForPuzzle(puzzleNumber)); // Load categories based on puzzle number
  const maxAttempts = 5;
  const [hintsUsed, setHintsUsed] = useState(categories.map(() => ({ bigHint: false, smallHint: false })));
  const [howToPlayOpen, setHowToPlayOpen] = useState(false);
  const [gameHasEnded, setGameHasEnded] = useState(false);
  const [theme, setTheme] = useState('light'); // default theme

  // Add useEffect hook to update categories when puzzleNumber changes
  useEffect(() => {
    // Load new categories based on the selected puzzle number
    const newCategories = loadCategoriesForPuzzle(puzzleNumber);
    setCategories(newCategories);
  
    // Reset hints for the new categories
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
  
    // Shuffle items in the new categories
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    setAllItems(shuffledItems);
  
    // Reset game state to initial values
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setGuesses([]);
    setGameEndedSuccessfully(false);
    setGameHasEnded(false); // This ensures the game is no longer in the "ended" state
  
    // Optionally, if you're storing the game state in localStorage, you might want to update/clear it here
  
  }, [puzzleNumber]);
  
  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  
  }, []);

  // Omitting existing useEffects and functions for brevity...

  // Initial setup of game state based on selected puzzle
  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${puzzleNumber}`));
    if (storedState) {
      // Logic to load game state from local storage
    } else {
      const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
      setAllItems(shuffledItems);
      resetGameState(false); // Reset without clearing local storage
    }
  }, [categories]);

  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };
  useEffect(() => {
    setCategories(loadCategoriesForPuzzle(puzzleNumber));
    // Include other logic for when puzzleNumber changes, like resetting the game state
  }, [puzzleNumber]);

  // Dynamically generate puzzle number options for the dropdown
  const puzzleNumberOptions = getPuzzleNumbers();
  useEffect(() => {
    // Load the categories corresponding to the selected puzzle number
    const newCategories = loadCategoriesForPuzzle(puzzleNumber);
    
    // Set the loaded categories to the state
    setCategories(newCategories);
    
    // Reset the hints used for each category in the new puzzle
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
    
    // Shuffle all items from the new categories to randomize their order
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    
    // Set the shuffled items to the state, effectively resetting the game board
    setAllItems(shuffledItems);
    
    // Reset the selected items as we're starting a new puzzle
    setSelectedItems([]);
    
    // Reset the found categories as none have been found in the new puzzle
    setFoundCategories([]);
    
    // If there are additional states that depend on the puzzle number or categories,
    // reset them here to ensure the game correctly initializes for the new puzzle.
    // Example: Resetting guesses, attempts, or any other game-related states.
    
  }, [puzzleNumber]);
  

  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  }, []);
    const updateFoundCategories = () => {
      let remainingItems = [...allItems];
    
      categories.forEach((category, index) => {
        const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
        const isCategoryCompletelySelected = selectedInCategory.length === category.items.length;
    
        if (isCategoryCompletelySelected && !foundCategories.includes(category)) {
          setFoundCategories(prevFoundCategories => [...prevFoundCategories, category]);
          setHintsUsed(hints => {
            const newHints = [...hints];
            newHints[index].found = true;
            return newHints;
          });
          remainingItems = remainingItems.filter(item => !category.items.includes(item));
        }
      });
    
      setAllItems(remainingItems);
    };
    
 
  // Add this useEffect hook after your existing useEffect hooks
  useEffect(() => {
    // Check if all categories have been found
    if (foundCategories.length === categories.length) {
      handleEndGame(true); // Game ends successfully
    } else if (attempts >= maxAttempts) {
      // Check if max attempts have been reached without finding all categories
      handleEndGame(false); // Game ends unsuccessfully
    }
  }, [foundCategories, attempts]); // Depend on foundCategories and attempts

  // Initial setup of game state
  useEffect(() => {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState();
  }, []);
  // Handle toggling between displaying item names and images
  const toggleDisplay = () => {
    setShowImages(prevState => !prevState);
  };

  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${puzzleNumber}`));
    if (storedState) {
      setGuesses(storedState.guesses || []);
      setSelectedItems(storedState.selectedItems || []);
      setFoundCategories(storedState.foundCategories || []);
      setAttempts(storedState.attempts || 0);
      // Do not set modalOpen from stored state; always start with it closed
      // Additional state can be loaded here as needed
    }
  }, []);
  // Reset game state for new games or retries
  const resetGameState = (clearLocalStorage = true) => {
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setModalOpen(false);
    setGuesses([]);
    if (clearLocalStorage) {
      localStorage.removeItem(`gameState-${puzzleNumber}`);
      localStorage.removeItem(`guesses-${puzzleNumber}`); // Clear guess history from local storage
    }
};

// Save guesses to local storage
const saveGuessToLocalStorage = (guess) => {
  const key = `guesses-${puzzleNumber}`;
  const guesses = JSON.parse(localStorage.getItem(key)) || [];
  guesses.push(guess);
  localStorage.setItem(key, JSON.stringify(guesses));
};

// Emoji map for category colors
const colorEmojiMap = {
  'blue': '🟦',
  'red': '🟥',
  'orange': '🟧',
  'yellow': '🟨',
  'purple': '🟪',
  'green': '🟩',

};

const HowToPlayModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal">
          <h2>How to Play Football Connections</h2>
          <button className="modal-close" onClick={onClose}>×</button>
          <p>Find groups of four football-related items that share something in common.</p>
          <p>Select four items and tap 'Submit' to check if your guess is correct.</p>
          <p>Uncover all connections without making more than 4 mistakes to win!</p>
          <h3>Category Examples</h3>
          <p>🏆 Super Bowl MVPs: Tom Brady, Joe Montana, Terry Bradshaw, Emmitt Smith</p>
          <p>🌟 NFL Rushing Leaders: Emmitt Smith, Walter Payton, Barry Sanders, Adrian Peterson</p>
          <p>Note: Categories may include players, positions, records, and iconic moments in football history.</p>
          <p>Each puzzle has exactly one solution. Be mindful of items that may fit into more than one category!</p>
          <p>Each group is assigned a color, revealed as you solve.</p>
    
          <p>Ready to put your football knowledge to the test? Enjoy Football Connections!</p>
        </div>
      </div>
    );

  
};const StatsModal = ({ isOpen, onClose, guesses, congratulations = false, puzzleNumber }) => {
  if (!isOpen) return null;

  const safeGuesses = Array.isArray(guesses) ? guesses : [];
  const baseURL = "https://crossovergrid.com"; // Adjust as needed

  const copyToClipboard = async () => {
    const textToCopy = `Crossover Connections 🏈 Puzzle #${puzzleNumber} ${baseURL}/\n` +
      safeGuesses.map(guess =>
        guess.map(color => colorEmojiMap[color] || '⬜').join('')
      ).join('\n');
    await navigator.clipboard.writeText(textToCopy);
    // Assuming you have a toast notification system
    toast.success('Guesses copied to clipboard!');
  };

  const shareOnTwitter = () => {
    const puzzleURL = baseURL; // Adjust based on your actual URL structure
    let shareText = `Crossover Connections 🏈 Puzzle #${puzzleNumber} ${puzzleURL}\nGuesses:\n`;
  
    // Append guesses with emojis
    for (const guess of safeGuesses) {
      const guessLine = guess.map(color => colorEmojiMap[color] || '⬜').join('');
      shareText += `${guessLine}\n`;
    }
  
    // Manually replace critical characters to ensure the URL is valid
    shareText = shareText.replace(/\n/g, '%0A').replace(/#/g, '%23');
  
    const tweetURL = `https://twitter.com/intent/tweet?text=${shareText}`;
    window.open(tweetURL, '_blank');
  };
  

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <h2>Crossover Connections 🏈 Puzzle #{puzzleNumber}</h2>
        <button className="modal-close" onClick={onClose}>×</button>
        <div>
          <p>{congratulations ? "Congratulations! You've solved the puzzle!" : "Here were your guesses:"}</p>
          <button onClick={copyToClipboard}>Copy Guesses</button>
        <button onClick={shareOnTwitter} style={{ backgroundColor: '#1DA1F2', color: '#fff', marginLeft: '10px' }}>Tweet</button>
          {safeGuesses.map((guess, index) => (
            <div key={index} className="guess-emoji-row">
              {guess.map(color => colorEmojiMap[color] || '⬜').join('')}
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

// Load guesses from local storage on component mount
useEffect(() => {
  const storedGuesses = JSON.parse(localStorage.getItem(`guesses-${puzzleNumber}`)) || [];
  setGuesses(storedGuesses);
}, []);

// Handle item clicks to select or deselect items
const handleItemClick = (item) => {
  setSelectedItems(prevItems =>
    prevItems.includes(item) ? prevItems.filter(i => i !== item) : [...prevItems, item]
  );
};
const handleHintClick = (categoryIndex, hintSize) => {
  const category = categories[categoryIndex];
  if (hintSize === 'big') {
    toast.info(`The category is: ${category.category}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].bigHint = true;
      return newHints;
    });
  } else if (hintSize === 'small') {
    const miniHintItems = [category.items[0], category.items[1]]; // Take the first two items for the mini hint
    toast.info(`Two items in this category are: ${miniHintItems.join(' and ')}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].smallHint = true;
      return newHints;
    });
  }
  // Additionally, close the modal after displaying the hint
  toggleHintsModal();
};

const handleSubmit = () => {
  track('Signup');
  // Dismiss all existing toasts before showing a new one
  toast.dismiss();

  // Prevent any submissions if the game has ended
  if (gameEndedSuccessfully || attempts >= maxAttempts) {
    toast.error("Game has ended. No more submissions allowed.");
    return;
  }

  // Check for the correct number of selected items
  if (selectedItems.length !== 4) {
    toast.error("Please select exactly 4 items.");
    return;
  }

  // Extract the colors of the selected items
  const selectedColors = selectedItems.map(item =>
    categories.find(cat => cat.items.includes(item)).color
  );

  // Check if the guess has already been made
  const currentGuessSorted = [...selectedItems].sort().join(',');
  const guessAlreadyMade = guesses.some(guess =>
    [...guess].sort().join(',') === currentGuessSorted
  );

  if (guessAlreadyMade) {
    toast.error("You've already made this guess. Try a different combination.");
    return;
  }

  // Save the guess in state and localStorage
  setGuesses(prevGuesses => [...prevGuesses, selectedColors]);
  saveGuessToLocalStorage(selectedColors);

  // Find if a complete category is matched
  const matchedCategory = categories.find(category =>
    selectedItems.every(item => category.items.includes(item)) &&
    category.items.every(item => selectedItems.includes(item))
  );

  // Check for incorrect guesses
  if (!matchedCategory) {
    const threeOfAKind = categories.some(category => {
      const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
      return selectedInCategory.length === 3 && !foundCategories.includes(category);
    });

    // Provide feedback based on the correctness of the guess
    if (threeOfAKind) {
      toast.info("3 of a kind. One Away");
    } else {
      toast.error("That's not a correct group. Try again!");
    }

    // Update the number of attempts
    setAttempts(prevAttempts => prevAttempts + 1);

    // Check if the game should end
    if (attempts + 1 >= maxAttempts) {
      handleEndGame(false);
    }
  } else {
    // Handle a successful category match
    updateFoundCategories();
    setSelectedItems([]); // Clear selection for a new guess
    toast.success("Category Found!"); // Notify the user of a successful match
  }
};

// Apply toast.dismiss() similarly in other functions where toast notifications are shown

const handleEndGame = (success) => {
  setGameEndedSuccessfully(success);
  setGameHasEnded(true); // Indicate that the game has ended
  setModalOpen(true); // Optionally open the stats modal immediately if you prefer
};

// Inside ConnectionsGame component
const [hintsModalOpen, setHintsModalOpen] = useState(false);

// Function to open/close hints modal
const toggleHintsModal = () => {
  setHintsModalOpen(prevState => !prevState);
};


const handleResetGame = () => {
  if (window.confirm("Are you sure you want to reset the game? All progress will be lost.")) {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState(); // Function to reset game state
    // Also clear any relevant localStorage entries here
  }
};
const [showImages, setShowImages] = useState(false);
useEffect(() => {
  document.body.setAttribute('data-theme', theme);
}, [theme]);

return (
  <div className="connections-game">
   <nav className="navbar">
    {/* Left-aligned Logo and Crossover MLB text, centered alignment */}
    <a href="https://crossovergrid.com" 
       style={{ 
         textDecoration: "none", 
         color: "inherit", 
         display: "flex", 
         alignItems: "center", // This ensures vertical alignment
         justifyContent: "center" // This centers the content horizontally if the a-tag is a flex container
       }}>
      <img src={crossover_logo} alt="Logo" className="navbar-logo" />
      <p style={{ 
          margin: 0, // Removes default margin that might offset alignment
          fontWeight: "bold", 
          fontSize: 20,
          display: "flex", // Makes the paragraph itself a flex container
          alignItems: "center", // Centers the text inside the p-tag vertically
          justifyContent: "center" // Centers the text inside the p-tag horizontally
        }}>
        CROSSOVER NFL
      </p>
    </a>

    {/* Spacer to center the Crossover MLB logo and text in the navbar */}
    <div style={{ flexGrow: 1 }}></div>

    {/* Home and HowToPlayModal Icons */}
    <div>
      <IconButton href="/" title="Home">
        <HomeIcon />
      </IconButton>
      <IconButton onClick={() => setHowToPlayOpen(true)} title="How to Play">
        <HelpOutlineIcon />
      </IconButton>
    </div>

    <HowToPlayModal isOpen={howToPlayOpen} onClose={() => setHowToPlayOpen(false)} />
  </nav>


<ToastContainer position="top-center" />
    <h1>NFL Connections</h1>     <Select
  value={puzzleNumber}
  onChange={(e) => setPuzzleNumber(e.target.value)}
  displayEmpty
  inputProps={{ 'aria-label': 'Select puzzle' }}
  MenuProps={{
    PaperProps: {
      style: {
        maxHeight: 200, // Adjust based on your needs
        overflow: 'auto'
      }
    }
  }}
>
  {puzzleNumberOptions.map((number) => (
    <MenuItem key={number} value={number}>
      Puzzle {number}
    </MenuItem>
  ))}
</Select>

    <StatsModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      guesses={guesses}
      gameEndedSuccessfully={gameEndedSuccessfully}
      puzzleNumber={puzzleNumber} 

    />
    {!gameHasEnded && (
      <>
<div className="items-container">
  {allItems.map((item, index) => {
    const category = categories.find(category => category.items.includes(item));
    const itemIndexInCategory = category.items.indexOf(item);
    const imageUrl = category.images[itemIndexInCategory];
    const isSelected = selectedItems.includes(item);
    
    const itemStyle = isSelected
    ? theme === 'dark'
      ? { backgroundColor: '#ffcc00', color: 'black' } // Dark mode: bright background, dark text
      : { backgroundColor: '#ddd', color: 'black' } // Light mode: light background, dark text
    : {};
  
  return (
    <div
      key={index}
      className={`item ${showImages ? 'item-showing-image' : ''} ${isSelected ? 'selected' : ''}`}
      onClick={() => handleItemClick(item)}
      aria-pressed={isSelected ? 'true' : 'false'}
      style={itemStyle} // Apply the inline styles
    >
      {showImages ? (
        <img src={imageUrl} alt={item} />
      ) : (
        // The text color will change based on the style conditionally set above
        <span>{item}</span>
      )}
    </div>
  );
})}
</div>

      </>
    )}
 <div className="controls">
 {!gameHasEnded && (
      <> 
      <button onClick={() => setSelectedItems([])} disabled={selectedItems.length === 0}>
        Deselect All
      </button>
      <button onClick={handleSubmit} disabled={gameEndedSuccessfully || attempts >= maxAttempts}>
        Submit
      </button>
      <button onClick={toggleDisplay}>
        {showImages ? "Show Item Names" : "Show Images"}
      </button> </>
   )}
      {/* Conditionally render the View Stats button if the game has ended */}

  </div>
  

  {gameHasEnded && (
  <button onClick={() => setModalOpen(true)}>
    View Stats
  </button>
)}

{!gameHasEnded && (
<button onClick={toggleHintsModal}>View Hints</button>
)}
{/* JSX for the hints modal */}
{!gameHasEnded && (
  
 <Modal isOpen={hintsModalOpen} onClose={toggleHintsModal} style={{ content: { width: '50%', height: '50%', margin: 'auto' } }}>
 <div className="modal-content">
   {/* Close button */}
   <button onClick={toggleHintsModal} className="close-button">X</button>
   
   {/* Content of the modal */}
   <div className="hints-section">
     <h2>HINTS</h2>
     {categories.map((category, index) => {
       const isCategoryFound = foundCategories.includes(category);
       const categoryStyle = {
         borderColor: category.color,
         borderStyle: 'solid',
         borderWidth: '2px',
         backgroundColor: isCategoryFound ? category.color : 'transparent',
       };

       return (
         <div className="hint-category" key={index} style={categoryStyle}>
           <div className="category-name">{isCategoryFound ? category.category : `Category ${index + 1}`}</div>
           {!isCategoryFound && !hintsUsed[index].bigHint && (
             <div className="hints-buttons">
               <button onClick={() => handleHintClick(index, 'big')}>Big Hint</button>
               <button onClick={() => handleHintClick(index, 'small')}>Small Hint</button>
             </div>
           )}
           {isCategoryFound && (
             <div className="found-items">
               <p>Category Found!</p>
               <p>{category.items.join(", ")}</p>
             </div>
           )}
         </div>
       );
     })}
   </div>
 </div>
</Modal>


  )}
   {gameHasEnded && (
      <div className="end-game-display">
        <p>{gameEndedSuccessfully ? "Congratulations! You've completed the game!" : "Game Over. Better luck next time!"}</p>
        <div className="correct-answers">
          <h2>Correct Answers:</h2>
          {categories.map((category, index) => (
            <div key={index} className="category-display" style={{ borderColor: category.color, borderWidth: 1, borderStyle: 'solid', borderRadius: '10px', margin: '.5px 0', padding: '2px' }}>
              <h3 style={{ textAlign: 'center', color: category.color, margin: '2px 0', borderRadius: '10px' }}>{category.category}</h3>
              <div style={{ display: 'flex', justifyContent: 'space-around', padding: '2px', flexWrap: 'wrap' }}>
                {category.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="category-item" style={{ margin: '0px', textAlign: 'center', maxWidth: '120px' }}>
                    <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '2px', width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto 0px', backgroundColor: '#fff' }}>
                      <img src={category.images[itemIndex]} alt={item} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', borderRadius: '8px' }} />
                    </div>
                    <p style={{ fontSize: '0.8em', margin: '0' }}>{item}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )}



{!gameHasEnded && (
      <> 
    {/* Status display section */}
    <div className="status">
      <p>Mistakes Remaining: {'⚫️'.repeat(maxAttempts - attempts) + '⚪️'.repeat(attempts)}</p>
      <p>Found Categories: {foundCategories.map(c => c.category).join(", ")}</p>
    </div>

    {/* Hints section */}
    <div className="hints-section">
      <h2>HINTS</h2>
      {categories.map((category, index) => {
        const isCategoryFound = foundCategories.includes(category);
        const categoryStyle = {
          borderColor: category.color,
          borderStyle: 'solid',
          borderWidth: '2px',
          backgroundColor: isCategoryFound ? category.color : 'transparent',
        };

        return (
          <div className="hint-category" key={index} style={categoryStyle}>
            <div className="category-name">{isCategoryFound ? category.category : `Category ${index + 1}`}</div>
            {!isCategoryFound && !hintsUsed[index].bigHint && (
              <div className="hints-buttons">
                <button onClick={() => handleHintClick(index, 'big')}>Big Hint</button>
                <button onClick={() => handleHintClick(index, 'small')}>Small Hint</button>
              </div>
            )}
            {isCategoryFound && (
              <div className="found-items">
                <p>Category Found!</p>
                <p>{category.items.join(", ")}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
    </>
   )}
    {/* Reset game button */}
    <button onClick={handleResetGame} className="reset-game-button">
      Reset Game
    </button>
    </div>
  );
};


export default ConnectionsGame;