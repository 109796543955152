// Authenticate.js
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Typography, Dialog, Divider, Paper } from "@mui/material";
import Axios from "axios";
import crossover_logo from "../assets/nba.svg";
import { useUserContext } from "../contexts/UserContext";

const Authenticate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get("token");
  const { user, checkAuth } = useUserContext();
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    if (token) {
      // Call your backend to verify the token
      Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/verify-token`,
        { token },
        {
          withCredentials: true,
        }
      )
        .then((response) => {
          // Handle successful authentication
          // You might want to store the returned user data in local storage or context
          console.log("Authentication successful");
          checkAuth();
          // Then redirect the user to another page
          setTimeout(() => {
            navigate("/play"); // or wherever you want to redirect
          }, 1000);
        })
        .catch((error) => {
          // Handle errors, such as invalid or expired token
          console.log("Authentication error:", error);
          // Redirect to an error page or display a message
        });
    }
  }, []);

  return (
    <Dialog
      open={true}
      // onClose={() => setSearchOpen(false)}
      autoFocus={false}
      sx={{
        touchAction: "none",
      }}
    >
      <Paper
        sx={{
          width: "100vw",
          maxWidth: "450px",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <Divider>
          <img
            src={crossover_logo}
            alt="Logo"
            // className="navbar-logo"
            width={"50px"}
            height={"50px"}
          />
        </Divider>
        <Typography variant="h5" align="center">
          Please wait...
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{ marginTop: "20px", marginBottom: "20px" }}
        >
          You'll be automatically redirected if the link is valid. If you're not
          redirected in 5-10 seconds, please try again.
        </Typography>
        <Button variant="contained" onClick={() => navigate("/login")}>
          Request new link
        </Button>
      </Paper>
    </Dialog>
  );
};

export default Authenticate;
