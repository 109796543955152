import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SPORTS } from "../components/UserStats/userStats.constants";

const Context = createContext({
  loading: true,
  sportId: 0,
  leaderboard: [],
  sportLeaderboard: { page: 0, sportId: 0, leaderboard: [] },
  stats: null,
  sportsStats: [],
  userGames: [],
  error: null,
  setSportId: () => {},
  saveSportLeaderboard: () => {},
  saveStats: () => {},
  saveSportsStats: () => {},
  saveUserGames: () => {},
  setLoading: () => {},
  setError: () => {},
});

const Provider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [sportId, setSportId] = useState(SPORTS[0].id); // Default to general leaderboard
  const [leaderboard, setLeaderboard] = useState([]);
  const [sportLeaderboard, setSportLeaderboard] = useState(() => {
    const savedLeaderboard = JSON.parse(
      localStorage.getItem(`leaderboard_0`)
    ) || {
      page: 0,
      sportId: 0,
      leaderboard: [],
    };

    return savedLeaderboard;
  });

  const [stats, setStats] = useState(
    JSON.parse(localStorage.getItem("stats")) || null
  );
  const [sportsStats, setSportsStats] = useState(
    JSON.parse(localStorage.getItem("sportsStats")) || []
  );
  const [userGames, setUserGames] = useState(
    JSON.parse(localStorage.getItem("userGames")) || []
  );
  const [error, setError] = useState(null);

  const loadLeaderboard = useCallback(() => {
    setLoading(true);
    const savedLeaderboard = JSON.parse(
      localStorage.getItem(`leaderboard_${sportId}`)
    ) || {
      page: 0,
      sportId,
      leaderboard: [],
    };
    setSportLeaderboard(savedLeaderboard);
    setLoading(false);
  }, [sportId]);

  const saveSportLeaderboard = useCallback((sportId, page, newLeaderboard) => {
    setLeaderboard((prevState) => {
      const existingSportLeaderboard = prevState.find(
        (lb) => lb.sportId === sportId
      );

      let updatedLeaderboard;
      if (existingSportLeaderboard) {
        if (page === existingSportLeaderboard.page) {
          // Update the records if the page is the same
          updatedLeaderboard = {
            ...existingSportLeaderboard,
            page,
            leaderboard: newLeaderboard,
          };
        } else if (page < existingSportLeaderboard.page) {
          // Replace the leaderboard if the new page is less than the current page
          updatedLeaderboard = {
            ...existingSportLeaderboard,
            page,
            leaderboard: newLeaderboard,
          };
        } else {
          // Append the new records if the new page is greater
          updatedLeaderboard = {
            ...existingSportLeaderboard,
            page,
            leaderboard: [
              ...existingSportLeaderboard.leaderboard,
              ...newLeaderboard,
            ],
          };
        }
      } else {
        updatedLeaderboard = {
          page,
          sportId,
          leaderboard: newLeaderboard,
        };
      }

      const newLeaderboardState = prevState.map((lb) =>
        lb.sportId === sportId ? updatedLeaderboard : lb
      );

      if (!existingSportLeaderboard) {
        newLeaderboardState.push(updatedLeaderboard);
      }

      localStorage.setItem(
        `leaderboard_${sportId}`,
        JSON.stringify(updatedLeaderboard)
      );
      setSportLeaderboard(updatedLeaderboard);
      return newLeaderboardState;
    });
  }, []);

  const saveStats = useCallback((data) => {
    localStorage.setItem("stats", JSON.stringify(data));
    setStats(data);
  }, []);

  const saveSportsStats = useCallback((data) => {
    localStorage.setItem("sportsStats", JSON.stringify(data));
    setSportsStats(data);
  }, []);

  const saveUserGames = useCallback((data) => {
    localStorage.setItem("userGames", JSON.stringify(data));
    setUserGames(data);
  }, []);

  useEffect(() => {
    loadLeaderboard();
  }, [loadLeaderboard, sportId]);

  const values = useMemo(
    () => ({
      loading,
      sportId,
      leaderboard,
      sportLeaderboard,
      stats,
      sportsStats,
      userGames,
      error,
      setSportId,
      saveSportLeaderboard,
      saveStats,
      saveSportsStats,
      saveUserGames,
      setLoading,
      setError,
    }),
    [
      loading,
      sportId,
      leaderboard,
      sportLeaderboard,
      stats,
      sportsStats,
      userGames,
      error,
      saveSportLeaderboard,
      saveStats,
      saveSportsStats,
      saveUserGames,
    ]
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};

export const AppProvider = memo(Provider);

export const useAppContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};
