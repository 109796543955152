import BarChartIcon from "@mui/icons-material/BarChart";
import HomeIcon from "@mui/icons-material/Home";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { Box, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useScreenSize } from "../../hooks/useScreenSize";
import { Start } from "@mui/icons-material";
import { useUserContext } from "../../contexts";

const navLinks = [
  { label: "Play", path: "/play", icon: <HomeIcon /> },
  { label: "Stats", path: "/user/stats", icon: <Start /> },
  {
    label: "Leaderboard",
    path: "/leaderboard",
    icon: <LeaderboardIcon />,
  },
];

export const PlayMenu = () => {
  const { username, loading } = useUserContext();

  const navigate = useNavigate();
  const location = useLocation();
  const { screenWidth } = useScreenSize();

  if (loading || !username?.length) return null;

  const renderNavLink = (link) => (
    <Tooltip title={link.label} key={link.path}>
      <button
        onClick={() => navigate(link.path)}
        style={{
          background: "none",
          border: "#000",
          cursor: "pointer",
          padding: "0.25rem",
        }}
      >
        {screenWidth < 480 ? link.icon : link.label}
      </button>
    </Tooltip>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      {navLinks
        .filter((link) => link.path !== location.pathname)
        .map((link) => renderNavLink(link))}
    </Box>
  );
};
