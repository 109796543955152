import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import MenuIcon from "@mui/icons-material/Menu";
import prime_games from "../../assets/prime_gaming_logo.png";
import { useTheme } from '@mui/material/styles';

export default function FadeMenu() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>
          <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div
              style={{
                height: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                fontFamily: "'font-prime', sans-serif" // Apply 'font-prime' font family directly
              }}
            >
              <p>Power up more Grids with CrossoverGrid ↗</p>
              <img
   
                alt="Logo"
                width={"100px"}
                style={{ filter: theme.palette.mode === "dark" ? "invert(1)" : "none" }} // Invert the image in dark mode
              />
            </div>
          </a>
        </MenuItem>
      </Menu>
    </div>
  );
}
