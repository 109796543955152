// useTimer.js
import { useState, useEffect, useRef } from "react";
const TIMER_1_SEC = 1000;

export const useTimer = (initialTime, isRunning) => {
  const [timer, setTimer] = useState(initialTime);
  const timerInterval = useRef(null);

  useEffect(() => {
    if (isRunning) {
      timerInterval.current = setInterval(() => {
        setTimer((prevTimer) => Math.max(prevTimer - 1, 0));
      }, TIMER_1_SEC);
    } else {
      clearInterval(timerInterval.current);
    }

    return () => clearInterval(timerInterval.current);
  }, [isRunning]);

  useEffect(() => {
    setTimer(initialTime);
  }, [initialTime]);

  return [timer, setTimer];
};
