
import { yellow } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './module.ConnectionsGame.css';
import crossover_logo from "../../assets/mlb.svg";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Select from "@mui/material/Select"; // Import Select component for dropdown
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem for dropdown items
import HomeIcon from '@mui/icons-material/Home';
import ReactGA from "react-ga";


const baseURL = "crossovergrid.com/mlb/connections";
const categories22 = [
  {
    "category": "🟦 Batting Title Winners",
    "header": "MLB Batting Title Winners",
    "items": ["Jeff McNeil", "Jose Altuve", "Luis Arraez", "Trea Turner"],
    "commonality": "MLB players who have won the batting title, leading the league in batting average for a season",
    "color": "blue",
    "images": []
  },
  {
    "category": "🟩 Rookie of the Year Winners",
    "header": "MLB Rookie of the Year Award Winners",
    "items": ["Carlos Correa", "Jose Abreu", "Pete Alonso", "Yordan Alvarez"],
    "commonality": "Players who have been honored as the Rookie of the Year in Major League Baseball, signifying their impactful debut seasons",
    "color": "green",
    "images": []
  },
  {
    "category": "🟨 MVP Runner Ups",
    "header": "MLB MVP Runner-Up Players",
    "items": ["Alex Bregman", "Javier Baez", "Manny Machado", "Vlad Guerrero Jr."],
    "commonality": "MLB players who have finished as runners-up in the Most Valuable Player (MVP) voting, demonstrating their elite performance and value to their teams",
    "color": "yellow",
    "images": []
  },
  {
    "category": "🟪 200-K Seasons",
    "header": "MLB Players with 200-K Seasons",
    "items": ["Joey Gallo", "Kyle Schwarber", "Matt Chapman", "Teoscar Hernandez"],
    "commonality": "MLB players who have recorded 200 or more strikeouts in a single season, highlighting their power-hitting capabilities",
    "color": "purple",
    "images": []
  }
]

const categories21 = [
  {
    "category": "⚾️ 2020 American League MVP Voting",
    "header": "Top Finishers in the 2020 AL MVP Voting",
    "items": ["José Abreu", "José Ramírez", "DJ LeMahieu", "Shane Bieber"],
    "commonality": "MLB players who were the top contenders for the AL MVP award in 2020",
    "color": "blue",
    "images": []
  },
  {
    "category": "⚾️ 2016 American League MVP Voting",
    "header": "Top Finishers in the 2016 AL MVP Voting",
    "items": ["Mike Trout", "Mookie Betts", "Jose Altuve", "Josh Donaldson"],
    "commonality": "MLB players who were the top contenders for the AL MVP award in 2016",
    "color": "red",
    "images": []
  },
  {
    "category": "⚾️ 2014 National League MVP Voting",
    "header": "Top Finishers in the 2014 NL MVP Voting",
    "items": ["Clayton Kershaw", "Giancarlo Stanton", "Andrew McCutchen", "Jonathan Lucroy"],
    "commonality": "MLB players who were the top contenders for the NL MVP award in 2014",
    "color": "purple",
    "images": []
  },
  {
    "category": "⚾️ 2017 National League MVP Voting",
    "header": "Top Finishers in the 2017 NL MVP Voting",
    "items": ["Cody Bellinger", "Christian Yelich", "Anthony Rendon", "Ketel Marte"],
    "commonality": "MLB players who were the top contenders for the NL MVP award in 2017",
    "color": "green",
    "images": []
  }
]

const categories20 = [
  {
    "category": "💥 Most Home Runs Against Gerrit Cole",
    "header": "MLB Players with the Most Home Runs Off Gerrit Cole",
    "items": ["Rafael Devers", "Matt Carpenter", "Joey Gallo", "Lucas Duda"],
    "commonality": "MLB batters who have hit the most home runs against pitcher Gerrit Cole",
    "color": "blue",
    "images": []
  },
  {
    "category": "💥 Most Home Runs Against Clayton Kershaw",
    "header": "MLB Players with the Most Home Runs Off Clayton Kershaw",
    "items": ["Nolan Arenado", "Charlie Blackmon", "Christian Walker", "Adam Dunn"],
    "commonality": "MLB hitters who have gone deep the most times against pitcher Clayton Kershaw",
    "color": "red",
    "images": []
  },
  {
    "category": "💥 Most Home Runs Against Madison Bumgarner",
    "header": "MLB Players with the Most Home Runs Off Madison Bumgarner",
    "items": ["Wil Myers", "Carlos Gonzalez", "Trevor Story", "Justin Turner"],
    "commonality": "Batters who have hit the most career home runs against Madison Bumgarner",
    "color": "orange",
    "images": []
  },
  {
    "category": "💥 Most Home Runs Against Justin Verlander",
    "header": "MLB Players with the Most Home Runs Off Justin Verlander",
    "items": ["Carlos Santana", "Jim Thome", "Jose Abreu", "Kyle Seager"],
    "commonality": "Major League Baseball players who have the highest number of home runs against Justin Verlander",
    "color": "green",
    "images": []
  }
]

const categories19 = [
  {
    "category": "⚾️ 2007 Boston Red Sox World Series Champions",
    "header": "Key Players from the 2007 World Series Champion Boston Red Sox",
    "items": ["J.D. Drew", "Jonathan Papelbon", "Manny Ramirez", "Tim Wakefield"],
    "commonality": "Baseball players who were instrumental in the Boston Red Sox's victory in the 2007 World Series",
    "color": "red",
    "images": []
  },
  {
    "category": "🎬 Players Portrayed in 'Moneyball'",
    "header": "MLB Players Featured in the Movie 'Moneyball'",
    "items": ["David Justice", "Jeremy Giambi", "Carlos Peña", "Ricardo Rincón"],
    "commonality": "MLB players who were portrayed in the film 'Moneyball', which highlights the Oakland Athletics' analytical, evidence-based approach to assembling a competitive team",
    "color": "green",
    "images": []
  },
  {
    "category": "🇨🇺 MLB Players from Cuba",
    "header": "Cuban MLB Players",
    "items": ["Aroldis Chapman", "Yasiel Puig", "Luis Robert", "Adonis Garcia"],
    "commonality": "Cuban-born MLB players who have showcased their talents in Major League Baseball",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔄 Home Runs from Both Sides of the Plate in One Game",
    "header": "MLB Players Who Hit Home Runs from Both Sides in a Game",
    "items": ["José Ramírez", "José Reyes", "Nick Swisher", "Chase Headley"],
    "commonality": "MLB switch-hitters who have hit home runs from both sides of the plate within a single game",
    "color": "orange",
    "images": []
  }
]

const categories18 = [
  {
    "category": "🏅 1998 New York Yankees (114-48 Record)",
    "header": "Key Players of the 1998 New York Yankees",
    "items": ["Darryl Strawberry", "Orlando Hernández", "Derek Jeter", "Scott Brosius"],
    "commonality": "Impactful players on the 1998 New York Yankees team that finished the season with a record of 114-48",
    "color": "blue",
    "images": [  "https://securea.mlb.com/mlb/images/players/head_shot/122864.jpg",
    "https://upload.wikimedia.org/wikipedia/commons/3/3a/Orlando_Hernández.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/116539.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/111515.jpg"]
  
  },
  {
    "category": "⚾ 2022 Los Angeles Dodgers (111-51 Record)",
    "header": "Standout Players of the 2022 Los Angeles Dodgers",
    "items": ["Cody Bellinger", "Mookie Betts", "Craig Kimbrel", "Tyler Anderson"],
    "commonality": "Key contributors to the Los Angeles Dodgers during their impressive 2022 season with a 111-51 record",
    "color": "purple",
    "images": [  "https://securea.mlb.com/mlb/images/players/head_shot/641355.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/605141.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/518886.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/542881.jpg"]
  
  },
  {
    "category": "🎖️ 2001 Seattle Mariners (116-46 Record)",
    "header": "Main Contributors to the 2001 Seattle Mariners",
    "items": ["Ichiro Suzuki", "Jamie Moyer", "Bret Boone", "Mike Cameron"],
    "commonality": "Major players on the 2001 Seattle Mariners squad, which set an American League record for wins with a 116-46 finish",
    "color": "green",
    "images": [  "https://securea.mlb.com/mlb/images/players/head_shot/400085.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/119469.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/111214.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/111904.jpg"]
  
  },
  {
    "category": "🏆 Most Wins All-Time MLB Hitters",
    "header": "Hitters Who Played in the Most MLB Wins",
    "items": ["Pete Rose", "Hank Aaron", "Carl Yastrzemski", "Albert Pujols"],
    "commonality": "MLB hitters who have played in the most winning games over their careers",
    "color": "red",
    "images": [  "https://securea.mlb.com/mlb/images/players/head_shot/121454.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/110001.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/124650.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/405395.jpg"]
  
  },
]


const categories17 = [
  {
    "category": "🧢 Spent Entire Career with the Minnesota Twins",
    "header": "MLB Players Who Spent Their Entire Career with the Twins",
    "items": ["Royce Lewis", "Joe Mauer", "Kirby Puckett", "Max Kepler"],
    "commonality": "Baseball players who have dedicated their entire MLB career to the Minnesota Twins",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/40635.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/5378.png",
      "https://img.mlbstatic.com/mlb-photos/image/upload/d_people:generic:headshot:67:current.png/w_426,q_auto:best/v1/people/120790/headshot/67/current",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/31870.png&w=350&h=254"
    ]
  },
  {
    "category": "💥 Hit a Home Run in MLB Debut",
    "header": "Players Who Hit a Home Run in Their MLB Debut",
    "items": ["Jasson Dominguez", "Josh Jung", "Aaron Judge", "Mike Napoli"],
    "commonality": "MLB players who made a grand entrance by hitting a home run in their very first major league game",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/42401.png",
      "https://a.espncdn.com/i/headshots/mlb/players/full/32691.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/33192.png&w=350&h=254",
      "https://a.espncdn.com/i/headshots/mlb/players/full/28444.png"
    ]
  },
  {
    "category": "⚡ Inside-the-Park Home Run",
    "header": "MLB Players with Inside-the-Park Home Runs",
    "items": ["Brandon Nimmo", "Alcides Escobar", "Seiya Suzuki", "Elly De La Cruz"],
    "commonality": "MLB players who have showcased speed and agility by hitting inside-the-park home runs",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/32159.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/29229.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/4142424.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/4917694.png&w=350&h=254"
    ]
  },
  {
    "category": "🎩 Unassisted Triple Plays",
    "header": "MLB Players Who Completed an Unassisted Triple Play",
    "items": ["Asdrúbal Cabrera", "Troy Tulowitzki", "Rafael Furcal", "Randy Velarde"],
    "commonality": "Defensive wizards in the MLB who have completed the rare feat of an unassisted triple play",
    "color": "purple",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/28671.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/28567.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/4243.png",
      "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRP6qDEqQIzEk1LErmbglC2-ShIp2kRrRN2HEljO67oHJobQOyH"
    ]
  },
]

const categories16 = [
  {
    "category": "🔥 30+ Game Hit Streaks",
    "header": "MLB Players with 30+ Game Hitting Streaks",
    "items": ["Dan Uggla", "Luis Castillo", "Whit Merrifield", "Joe DiMaggio"],
    "commonality": "Baseball players who have achieved the rare feat of hitting in 30 or more consecutive games",
    "color": "red",
    "images": []
  },
  {
    "category": "⚾ 99% of Games Played at Catcher",
    "header": "MLB Players Who Played 99% of Games as Catcher",
    "items": ["Mike Zunino", "Jason Varitek", "David Ross", "Joe Girardi"],
    "commonality": "MLB players known for playing almost exclusively in the catcher position throughout their careers",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏆 2005 Chicago White Sox World Series Team",
    "header": "Members of the 2005 Chicago White Sox World Series Champions",
    "items": ["A.J. Pierzynski", "Juan Uribe", "Jermaine Dye", "Carl Everett"],
    "commonality": "Players who were part of the Chicago White Sox roster when they won the World Series in 2005",
    "color": "green",
    "images": []
  },
  {
    "category": "🌟 MLB Players Born in Texas",
    "header": "MLB Players Hailing from Texas",
    "items": ["Lance Berkman", "Matt Carpenter", "James Loney", "Charlie Blackmon"],
    "commonality": "Major League Baseball players who were born in the state of Texas",
    "color": "purple",
    "images": []
  }
]

const categories15 = [
  {
    "category": "⚾️ 2014 San Francisco Giants",
    "header": "2014 San Francisco Giants Roster",
    "items": ["Pablo Sandoval", "Ángel Pagán", "Brandon Belt", "Hunter Pence"],
    "commonality": "Players who were on the roster of the San Francisco Giants during their 2014 championship season",
    "color": "orange",
    "images": []
  },
  {
    "category": "🏆 MLB Home Run Leaders by Season",
    "header": "Seasonal Leaders in MLB Home Runs",
    "items": ["Luke Voit", "Khris Davis", "Salvador Perez", "Andruw Jones"],
    "commonality": "MLB players who led the league in home runs during a single season",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔢 Players Who Wore #1",
    "header": "MLB Players Who Wore Jersey #1",
    "items": ["Pee Wee Reese", "Carlos Correa", "Ozzie Albies", "Jeff McNeil"],
    "commonality": "MLB players past and present who have donned the number 1 jersey",
    "color": "red",
    "images": []
  },
  {
    "category": "💥 4+ World Series Homers",
    "header": "Players with 4+ Home Runs in World Series",
    "items": ["Bernie Williams", "Joe Carter", "Corey Seager", "Hideki Matsui"],
    "commonality": "MLB players who hit four or more home runs across their World Series appearances",
    "color": "green",
    "images": []
  }
]

const categories14 = [
  {
    "category": "🏃‍♂️ 2024 Leadoff Hitters",
    "header": "Premier Leadoff Hitters of 2024",
    "items": ["Marcus Semien", "Yandy Díaz", "Kyle Schwarber", "Jose Altuve"],
    "commonality": "Key MLB players tasked with setting the table as leadoff hitters for their teams in the 2024 season",
    "color": "blue",
    "images": []
  },
  {
    "category": "🛠️ Masters of the Hot Corner",
    "header": "Elite Third Basemen in the MLB",
    "items": ["Josh Donaldson", "Nolan Arenado", "Todd Frazier", "David Wright"],
    "commonality": "Renowned MLB third basemen known for their defensive prowess and offensive contributions",
    "color": "red",
    "images": []
  },
  {
    "category": "🔥 Streak of 17+ Consecutive Pitching Wins",
    "header": "Pitchers with a Winning Streak of 17+ Games",
    "items": ["Jake Arrieta", "Roger Clemens", "Jose Contreras", "Johan Santana"],
    "commonality": "Exceptional MLB pitchers who have achieved the remarkable feat of 17 or more consecutive wins in their careers",
    "color": "green",
    "images": []
  },
  {
    "category": "⚾️ Played for Padres, Cubs, Yankees",
    "header": "MLB Players Who Donned the Padres, Cubs, and Yankees Uniforms",
    "items": ["Anthony Rizzo", "Rich 'Goose' Gossage", "Rich Hill", "Cameron Maybin"],
    "commonality": "Major League Baseball players who have the unique distinction of playing for the San Diego Padres, Chicago Cubs, and New York Yankees",
    "color": "purple",
    "images": []
  }
]

const categories13 = [
  {
    "category": "🌟 Won Rookie of the Year at Age 27 or Older",
    "header": "Late Bloomer MLB Rookie of the Year Winners",
    "items": ["Kazuhiro Sasaki", "José Abreu", "Jackie Robinson", "Ichiro Suzuki"],
    "commonality": "MLB players who won the Rookie of the Year award at the age of 27 or older, showcasing their impressive debuts at a later age",
    "color": "blue",
    "images": []
  },
  {
    "category": "🥇 Pitchers Who Have Thrown a Perfect Game",
    "header": "MLB Pitchers Celebrated for Throwing a Perfect Game",
    "items": ["Domingo Germán", "Roy Halladay", "Dennis Martínez", "Félix Hernández"],
    "commonality": "Elite group of MLB pitchers who have achieved the rare feat of throwing a perfect game, etching their names in baseball history",
    "color": "red",
    "images": []
  },
  {
    "category": "🐟 MLB Debuts' with the Marlins",
    "header": "Stars Who Made Their MLB Debut with the Marlins",
    "items": ["Miguel Cabrera", "Dontrelle Willis", "Dan Uggla", "Jose Fernandez"],
    "commonality": "Prominent MLB players who started their major league careers with the Miami Marlins, making a splash from their very first games",
    "color": "green",
    "images": []
  },
  {
    "category": "📏 MLB Giants Taller Than 6'7\"",
    "header": "Tower of Power: MLB Players Standing Over 6'7\"",
    "items": ["Oneil Cruz", "Tyler Glasnow", "Aaron Judge", "Chris Young"],
    "commonality": "Some of the tallest players in MLB, each towering over 6'7\" tall, using their impressive height to their advantage on the field",
    "color": "purple",
    "images": []
  }
]

const categories12 = [
  {
    "category": "💥 Hit 4+ Home Runs in a Game",
    "header": "MLB Players with 4+ Home Runs in a Single Game",
    "items": ["J.D. Martinez", "Carlos Delgado", "Josh Hamilton", "Mike Schmidt"],
    "commonality": "Baseball players who have hit four or more home runs in a single MLB game",
    "color": "red",
    "images": [  "https://securea.mlb.com/mlb/images/players/head_shot/429667.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/425664.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/457918.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/430599.jpg"]
  },
  {
    "category": "🏃 4+ Stolen Bases in a Game",
    "header": "MLB Players with 4+ Stolen Bases in a Single Game",
    "items": ["Jon Berti", "Rajai Davis", "Jacoby Ellsbury", "Kenny Lofton"],
    "commonality": "Baseball players who have stolen four or more bases in a single MLB game",
    "color": "green",
    "images": [  "https://securea.mlb.com/mlb/images/players/head_shot/429667.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/425664.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/457918.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/430599.jpg"]
  },
  {
    "category": "💪 10+ RBI Game",
    "header": "MLB Players with 10+ RBIs in a Single Game",
    "items": ["Mark Reynolds", "Álex Rodríguez", "Nomar Garciaparra", "Reggie Jackson"],
    "commonality": "Baseball players who have batted in ten or more runs in a single MLB game",
    "color": "blue",
    "images": [  "https://securea.mlb.com/mlb/images/players/head_shot/429667.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/425664.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/457918.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/430599.jpg"]
  },
  {
    "category": "🔥 6+ Hits Game",
    "header": "MLB Players with 6+ Hits in a Single Game",
    "items": ["Luis García Jr.", "Ian Kinsler", "Raúl Ibañez", "Kirby Puckett"],
    "commonality": "Baseball players who have recorded six or more hits in a single MLB game",
    "color": "orange",
    "images": [  "https://securea.mlb.com/mlb/images/players/head_shot/429667.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/425664.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/457918.jpg",
    "https://securea.mlb.com/mlb/images/players/head_shot/430599.jpg"]
  }
]

const categories11 = [

  {
    "category": "⚾ Current Padres Rotation",
    "header": "San Diego Padres Pitching Rotation",
    "items": ["Yu Darvish", "Joe Musgrove", "Dylan Cease", "Michael King"],
    "commonality": "Pitchers currently in the starting rotation for the San Diego Padres",
    "color": "orange",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/32055.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/34848.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/34943.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/40429.png"
    ]
  },
  {
    "category": "💣 Pitchers with 4+ HRs in a Single Season",
    "header": "Pitchers Who Hit 4+ Home Runs in a Season",
    "items": ["Carlos Zambrano", "Madison Bumgarner", "Yovani Gallardo", "Mike Hampton"],
    "commonality": "MLB pitchers who hit four or more home runs in a single season",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/4499.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/29949.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/28650.png&w=350&h=254",
      "https://a.espncdn.com/i/headshots/mlb/players/full/2836.png"
    ]
  },
  {
    "category": "⭐ All Stars Last Season",
    "header": "MLB All-Stars from the Last Season",
    "items": ["Zac Gallen", "Michael Lorenzen", "Dansby Swanson", "Luis Robert Jr."],
    "commonality": "Baseball players who were MLB All-Stars in the last season",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/39910.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/33252.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/34895.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/39631.png&w=350&h=254"
    ]
  },
  {
    "category": "🏃‍♂️ 30+ Stolen Bases Last Season",
    "header": "MLB Players with 30+ Stolen Bases Last Season",
    "items": ["Josh Lowe", "Elly De La Cruz", "Bobby Witt Jr.", "CJ Abrams"],
    "commonality": "MLB players who stole thirty or more bases in the last season",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/40557.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/4917694.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/42403.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/42402.png"
    ]
  }
]

const categories10 = [

  {
    "category": "⚾ Most Strikeouts All-Time for Yankees",
    "header": "Yankees Strikeout Kings",
    "items": ["Andy Pettitte", "Whitey Ford", "Ron Guidry", "CC Sabathia"],
    "commonality": "Yankees pitchers with the most strikeouts all-time",
    "color": "blue",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/120485.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/114299.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/115228.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/282332.jpg"
    ]
  },
  {
    "category": "🏆 Phillies 2008 World Series Team",
    "header": "2008 World Series Champions",
    "items": ["Ryan Howard", "Shane Victorino", "J.A. Happ", "Joe Blanton"],
    "commonality": "Key players from the Phillies' 2008 World Series winning team",
    "color": "red",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/429667.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/425664.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/457918.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/430599.jpg"
    ]
  },
  {
    "category": "🏅 10+ Walk-off Home Runs",
    "header": "Walk-off Home Run Heroes",
    "items": ["Jim Thome", "Stan Musial", "Albert Pujols", "Ryan Zimmerman"],
    "commonality": "Players with over 10 walk-off home runs",
    "color": "purple",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/123272.jpg",
      "https://midfield.mlbstatic.com/v1/people/119602/spots/240?zoom=1.2",
      "https://securea.mlb.com/mlb/images/players/head_shot/405395.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/475582.jpg"
    ]
  },
  {
    "category": "🔢 Worn/Wear Number 50",
    "header": "Famous Number 50s",
    "items": ["Adam Wainwright", "Mookie Betts", "Jameson Taillon", "J.R. Richard"],
    "commonality": "Notable MLB players who have worn the number 50",
    "color": "green",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/425794.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/605141.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/592789.jpg",
      "https://midfield.mlbstatic.com/v1/people/121145/spots/240?zoom=1.2"
    ]
  }
]


const categories9 = [
  {
      "category": "🚫 Most Consecutive Hitless Innings Post-Expansion",
      "header": "Pitching No-Hit Masters",
      "items": ["Nolan Ryan", "Dwight Gooden", "Max Scherzer", "Dennis Eckersley"],
      "commonality": "Pitchers with the most hitless innings in the post-MLB expansion era",
      "color": "blue",
      "images": [
        "https://securea.mlb.com/mlb/images/players/head_shot/121597.jpg",
        "https://securea.mlb.com/mlb/images/players/head_shot/114947.jpg",
        "https://securea.mlb.com/mlb/images/players/head_shot/453286.jpg",
        "https://securea.mlb.com/mlb/images/players/head_shot/113726.jpg"
      ]
    },
    {
      "category": "💥 Rays Career Home Run Leaders",
      "header": "Tampa Bay Rays Power Hitters",
      "items": ["Evan Longoria", "Carlos Pena", "Aubrey Huff", "B.J. Upton"],
      "commonality": "Players leading in career home runs for the Tampa Bay Rays",
      "color": "orange",
      "images": [
        "https://securea.mlb.com/mlb/images/players/head_shot/446334.jpg",
        "https://securea.mlb.com/mlb/images/players/head_shot/150289.jpg",
        "https://a.espncdn.com/i/headshots/mlb/players/full/4479.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/5970.png"
      ]
    },
    {
      "category": "🏆 10+ Gold Gloves Winners",
      "header": "Defensive Legends",
      "items": ["Greg Maddux", "Nolan Arenado", "Andruw Jones", "Ivan Rodriguez"],
      "commonality": "Players awarded over 10 Gold Gloves for defensive excellence",
      "color": "green",
      "images": [
        "https://securea.mlb.com/mlb/images/players/head_shot/118120.jpg",
        "https://securea.mlb.com/mlb/images/players/head_shot/571448.jpg",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/3520.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/2523.png"
      ]
    },
    {
      "category": "⚾ Played All 9 Positions in One Game",
      "header": "Versatile MLB Players",
      "items": ["Cesar Tovar", "Scott Sheldon", "Shane Halter", "Andrew Romine"],
      "commonality": "Players who played all 9 positions in the field in the same game",
      "color": "yellow",
      "images": [
        "https://lh3.googleusercontent.com/proxy/VWAfRSdU87ndZLJ_Qi1zNfiNa_wcS61uAxwoKwkjm2XlF8IThl5QUCvy9g0EKdhDpT2fuHzhqQqnKl2LN4kvLFTwAPvd9Dyp",
        "https://media.gettyimages.com/id/3189310/photo/maryvale-az-scott-sheldon-of-the-milwaukee-brewers-on-february-29-2004-in-maryvale-arizona.jpg?s=612x612&w=gi&k=20&c=TAriGhV6VTj_vcauSchOf3hblomnewwU4ePlq9YiMzU=",
        "https://sabr.org/sites/default/files/Halter-Shane.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/29330.png"
      ]
    }
    // Add other categories here if needed
  ];


const categories8 = [
  {
    "category": "⚾ Switch-Hitting Home Run Leaders",
    "header": "Top Switch-Hitting Home Run Hitters",
    "items": ["Mickey Mantle", "Eddie Murray", "Chipper Jones", "Carlos Beltrán"],
    "commonality": "Players known for their power hitting from both sides of the plate, leading in home runs among switch hitters",
    "color": "blue",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/118258.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/119579.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/116706.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/136860.jpg"
    ]
  },
  {
    "category": "🔥 Hardest Throwing Pitchers",
    "header": "Flamethrowers: MLB's Hardest Throwers",
    "items": ["Jordan Hicks", "Aroldis Chapman", "Neftalí Feliz", "Jonathan Broxton"],
    "commonality": "Pitchers known for delivering some of the fastest pitches recorded in MLB",
    "color": "red",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/663855.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/547973.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/467008.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/455009.jpg"
    ]
  },
  {
    "category": "👑 2015 Royals World Series Rotation",
    "header": "2015 Kansas City Royals World Series Pitching Rotation",
    "items": ["Edinson Volquez", "Johnny Cueto", "Yordano Ventura", "Chris Young"],
    "commonality": "The core starting pitchers for the Kansas City Royals during their 2015 World Series championship run",
    "color": "royalBlue",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/450172.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/456501.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/570649.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/432934.jpg"
    ]
  },
  {
    "category": "🎓 College World Series MVPs",
    "header": "From College World Series MVP to MLB Success",
    "items": ["Huston Street", "Jackie Bradley Jr.", "Rob Refsnyder", "Dansby Swanson"],
    "commonality": "Players who earned the MVP award at the College World Series before making their mark in the MLB",
    "color": "green",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/434718.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/598265.jpg",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/33411.png&w=350&h=254",
      "https://securea.mlb.com/mlb/images/players/head_shot/621020.jpg"
    ]
  }
]

const categories7 = [
  {
    "category": "Most Strikeouts in 2023 Season",
    "items": ["Spencer Strider", "Kevin Gausman", "Pablo López", "Blake Snell"],
    "commonality": "Players with the most strikeouts in the 2023 MLB season",
    "color": "orange",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/663423.jpg", // Spencer Strider
      "https://securea.mlb.com/mlb/images/players/head_shot/592332.jpg", // Kevin Gausman
      "https://securea.mlb.com/mlb/images/players/head_shot/621381.jpg", // Pablo López
      "https://securea.mlb.com/mlb/images/players/head_shot/605483.jpg"  // Blake Snell
    ]
  },
  {
    "category": "NL Silver Slugger Winners 2023",
    "items": ["William Contreras", "Luis Arráez", "Cody Bellinger", "Juan Soto"],
    "commonality": "Players who won the NL Silver Slugger Award in 2023",
    "color": "blue",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/682633.jpg", // William Contreras
      "https://securea.mlb.com/mlb/images/players/head_shot/650859.jpg", // Luis Arráez
      "https://securea.mlb.com/mlb/images/players/head_shot/641355.jpg", // Cody Bellinger
      "https://securea.mlb.com/mlb/images/players/head_shot/665742.jpg"  // Juan Soto
    ]
  },
  {
    "category": "🎙️ MLB on TBS Crew",
    "items": ["Jeff Francoeur", "Curtis Granderson", "Jimmy Rollins", "Pedro Martinez"],
    "commonality": "Members of the MLB on TBS broadcasting crew",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/6368.png", // Jeff Francoeur
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/28999.png", // Curtis Granderson
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/5017.png", // Jimmy Rollins
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/3343.png"  // Pedro Martinez
    ]
  },
  {
    "category": "Drafted by the Yankees",
    "items": ["Fred McGriff", "Derek Jeter", "Anthony Volpe", "Ian Kennedy"],
    "commonality": "Players who were drafted by the New York Yankees",
    "color": "green",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/119269.jpg", // Fred McGriff
      "https://securea.mlb.com/mlb/images/players/head_shot/116539.jpg", // Derek Jeter
      "https://securea.mlb.com/mlb/images/players/head_shot/676774.jpg", // Anthony Volpe
      "https://securea.mlb.com/mlb/images/players/head_shot/453178.jpg"  // Ian Kennedy
    ]
  }
];
// Puzzle number constant
const categories6 =[

  {
    "category": "🌍 World Series MVPs from the 2010s",
    "items": ["Madison Bumgarner", "David Freese", "George Springer", "Steve Pearce"],
    "commonality": "Players who won the World Series MVP award during the 2010s",
    "color": "red",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/518516.jpg", // Madison Bumgarner
      "https://securea.mlb.com/mlb/images/players/head_shot/501896.jpg", // David Freese
      "https://securea.mlb.com/mlb/images/players/head_shot/543807.jpg", // George Springer
      "https://securea.mlb.com/mlb/images/players/head_shot/519025.jpg"  // Steve Pearce
    ]
  },
  {
    "category": "🥇 1st Overall MLB Draft Picks",
    "items": ["Royce Lewis", "Stephen Strasburg", "Carlos Correa", "Gerrit Cole"],
    "commonality": "Players who were selected as the 1st overall pick in the MLB Draft",
    "color": "green",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/668904.jpg", // Royce Lewis
      "https://securea.mlb.com/mlb/images/players/head_shot/544931.jpg", // Stephen Strasburg
      "https://securea.mlb.com/mlb/images/players/head_shot/621043.jpg", // Carlos Correa
      "https://securea.mlb.com/mlb/images/players/head_shot/543037.jpg"  // Gerrit Cole
    ]
  },
  {
    "category": "🎓 Attended LSU (Louisiana State University)",
    "items": ["Alex Bregman", "Aaron Nola", "DJ LeMahieu", "Kevin Gausman"],
    "commonality": "MLB players who attended Louisiana State University",
    "color": "blue",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/608324.jpg", // Alex Bregman
      "https://securea.mlb.com/mlb/images/players/head_shot/605400.jpg", // Aaron Nola
      "https://securea.mlb.com/mlb/images/players/head_shot/518934.jpg", // DJ LeMahieu
      "https://securea.mlb.com/mlb/images/players/head_shot/592332.jpg"  // Kevin Gausman
    ]
  },
  {
    "category": "🌟 Late Bloomers: All-Star for the First Time After 30",
    "items": ["José Bautista", "R.A. Dickey", "Rich Hill", "Justin Turner"],
    "commonality": "Players who made their first MLB All-Star game appearance after turning 30",
    "color": "purple",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/430832.jpg", // José Bautista
      "https://securea.mlb.com/mlb/images/players/head_shot/285079.jpg", // R.A. Dickey
      "https://securea.mlb.com/mlb/images/players/head_shot/448179.jpg", // Rich Hill
      "https://securea.mlb.com/mlb/images/players/head_shot/457759.jpg"  // Justin Turner
    ]
  }
]

const categories5 = [
  {
    "category": "⚾ Opening Day Starters",
    "items": ["Corbin Burnes", "Mitch Keller", "Frankie Montas", "Nathan Eovaldi"],
    "commonality": "Pitchers who have started on Opening Day for their respective teams",
    "color": "gold",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/669203.jpg", // Corbin Burnes
      "https://securea.mlb.com/mlb/images/players/head_shot/656605.jpg", // Mitch Keller
      "https://securea.mlb.com/mlb/images/players/head_shot/593423.jpg", // Frankie Montas
      "https://securea.mlb.com/mlb/images/players/head_shot/543135.jpg"  // Nathan Eovaldi
    ]
  },
  {
    "category": "⚾ Hit Home Run on Opening Day 2023",
    "items": ["Aaron Judge", "Gleyber Torres", "Adley Rutschman", "Oneil Cruz"],
    "commonality": "Players who hit home runs on Opening Day 2023",
    "color": "blue",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/592450.jpg", // Aaron Judge
      "https://securea.mlb.com/mlb/images/players/head_shot/650402.jpg", // Gleyber Torres
      "https://securea.mlb.com/mlb/images/players/head_shot/668939.jpg", // Adley Rutschman
      "https://securea.mlb.com/mlb/images/players/head_shot/665833.jpg"  // Oneil Cruz
    ]
  },
  {
    "category": "⚾ MLB Managers with New Teams",
    "items": ["Ron Washington", "Bob Melvin", "Craig Counsell", "Stephen Vogt"],
    "commonality": "Managers who joined new teams for the MLB season",
    "color": "gray",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/804.png&w=350&h=254", // Ron Washington
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/1587.png", // Bob Melvin
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/3386.png", // Craig Counsell
      "https://securea.mlb.com/mlb/images/players/head_shot/519390.jpg"  // Stephen Vogt
    ]
  },
  {
    "category": "⚾ 2024 Opening Day Starting Lineup - Los Angeles Angels",
    "items": ["Aaron Hicks", "Mike Trout", "Anthony Rendon", "Carlos Estévez"],
    "commonality": "Players starting in the 2024 Opening Day lineup for the Los Angeles Angels",
    "color": "red",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/543305.jpg", // Aaron Hicks
      "https://securea.mlb.com/mlb/images/players/head_shot/545361.jpg", // Mike Trout
      "https://securea.mlb.com/mlb/images/players/head_shot/543685.jpg", // Anthony Rendon
      "https://securea.mlb.com/mlb/images/players/head_shot/608032.jpg" 
    ]
  }
  // Add other categories here if needed
];

const categories4 = [
    {
      "category": "🟠 Cubs Rotation when won World Series",
      "items": ["Jon Lester", "Jake Arrieta", "Kyle Hendricks", "John Lackey"],
      "commonality": "Pitchers who were part of the Chicago Cubs rotation when they won the World Series",
      "color": "orange",
      "images": [
        "https://securea.mlb.com/mlb/images/players/head_shot/452657.jpg", // Jon Lester
        "https://securea.mlb.com/mlb/images/players/head_shot/453562.jpg", // Jake Arrieta
        "https://securea.mlb.com/mlb/images/players/head_shot/543294.jpg", // Kyle Hendricks
        "https://securea.mlb.com/mlb/images/players/head_shot/407793.jpg"  // John Lackey
      ]
    },
    {
      "category": "🟣 Won a Division Batting Title",
      "items": ["Hanley Ramírez", "Magglio Ordóñez", "José Reyes", "Derrek Lee"],
      "commonality": "Players who have won a division batting title in Major League Baseball",
      "color": "purple",
      "images": [
        "https://securea.mlb.com/mlb/images/players/head_shot/434670.jpg", // Hanley Ramírez
        "https://securea.mlb.com/mlb/images/players/head_shot/120044.jpg", // Magglio Ordóñez
        "https://securea.mlb.com/mlb/images/players/head_shot/408314.jpg", // José Reyes
        "https://securea.mlb.com/mlb/images/players/head_shot/117601.jpg"  // Derrek Lee
      ]
    },
    {
      "category": "🔴 Thrown No Hitter",
      "items": ["Michael Lorenzen", "James Paxton", "Jered Weaver", "Carlos Zambrano"],
      "commonality": "Pitchers who have thrown a no-hitter in Major League Baseball",
      "color": "red",
      "images": [
        "https://securea.mlb.com/mlb/images/players/head_shot/547179.jpg", // Michael Lorenzen
        "https://securea.mlb.com/mlb/images/players/head_shot/572020.jpg", // James Paxton
        "https://securea.mlb.com/mlb/images/players/head_shot/450308.jpg", // Jered Weaver
        "https://securea.mlb.com/mlb/images/players/head_shot/407296.jpg"  // Carlos Zambrano
      ]
    },
    {
      "category": "🟢 Attended Vanderbilt",
      "items": ["Dansby Swanson", "Sonny Gray", "David Price", "Walker Buehler"],
      "commonality": "Baseball players who attended Vanderbilt University",
      "color": "green",
      "images": [
        "https://securea.mlb.com/mlb/images/players/head_shot/621020.jpg", // Dansby Swanson
        "https://securea.mlb.com/mlb/images/players/head_shot/543243.jpg", // Sonny Gray
        "https://securea.mlb.com/mlb/images/players/head_shot/456034.jpg", // David Price
        "https://securea.mlb.com/mlb/images/players/head_shot/621111.jpg"  // Walker Buehler
      ]
    }
  ];
  

const categories3 = [
  {
    "category": "⚾ Sidearm Pitchers",
    "items": ["Chad Bradford", "Pat Neshek", "Brad Ziegler", "Darren O'Day"],
    "commonality": "Pitchers known for their unorthodox sidearm delivery, making them stand out on the mound",
    "color": "brown",
    "images": [
      "https://media.gettyimages.com/id/3221058/photo/phoenix-az-chad-bradford-of-the-oakland-athletics-on-march-1-2004-in-phoenix-arizona.jpg?s=612x612&w=gi&k=20&c=P0ywOzIToOMg2C-ociFlZ8r7v3XIQMAS5iyddq6ThBU=",
      "https://securea.mlb.com/mlb/images/players/head_shot/456696.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/519242.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/503285.jpg",
    ]
  },
  {
    "category": "⚾ Rolaids Relief Award Winners",
    "items": ["Josh Hader", "Mark Melancon", "Heath Bell", "John Smoltz"],
    "commonality": "Pitchers who have won the Rolaids Relief Award, recognizing the top relief pitchers in Major League Baseball",
    "color": "orange",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/623352.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/453343.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/431145.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/122477.jpg", // Corrected image for John Smoltz
    ]
  },
  {
    "category": "⚾ 50+ Career Triples",
    "items": ["Bobby Abreu", "Kevin Kiermaier", "Dee Strange-Gordon", "Raúl Ibañez"],
    "commonality": "Players who have hit 50 or more triples in their Major League Baseball careers",
    "color": "blue",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/110029.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/594777.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/543829.jpg",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/3504.png",
    ]
  },
  {
    "category": "⚾ Played Final Season in 2021",
    "items": ["Cameron Maybin", "Jay Bruce", "Todd Frazier", "Jake Arrieta"],
    "commonality": "Players who retired from Major League Baseball following the 2021 season",
    "color": "gray",
    "images": [
      "https://securea.mlb.com/mlb/images/players/head_shot/457727.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/457803.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/453943.jpg",
      "https://securea.mlb.com/mlb/images/players/head_shot/453562.jpg",
    ]
  }
];


const categories2 = [
    {
      "category": "🔵 New Mets Acquisitions for 2024",
      "items": ["Harrison Bader", "Luis Severino", "JD Martinez", "Sean Manaea"],
      "commonality": "Players newly acquired by the New York Mets for the 2024 season, expected to make a significant impact",
      "color": "blue",
      "images": [
        "https://midfield.mlbstatic.com/v1/people/664056/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/622663/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/502110/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/640455/spots/zoom",
      ]
    },
    {
      "category": "🔴 Spent Entire Career with One Team",
      "items": ["Dustin Pedroia", "Todd Helton", "Matt Cain", "Kyle Seager"],
      "commonality": "Players who spent their entire MLB career with a single team, exemplifying loyalty and a consistent career with one franchise",
      "color": "red",
      "images": [
        "https://midfield.mlbstatic.com/v1/people/456030/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/115732/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/430912/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/572122/spots/zoom",
      ]
    },
    {
      "category": "🟠 Born in the Dominican Republic",
      "items": ["Melky Cabrera", "Fernando Rodney", "Fernando Tatís", "Francisco Liriano"],
      "commonality": "Notable MLB players born in the Dominican Republic, a nation known for its contribution to baseball talent",
      "color": "orange",
      "images": [
        "https://midfield.mlbstatic.com/v1/people/572122/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/407845/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/665487/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/434538/spots/zoom",
      ]
    },
    {
      "category": "🟢 Switch Hitters",
      "items": ["Ben Zobrist", "Ian Happ", "Dexter Fowler", "Mark Teixeira"],
      "commonality": "Players celebrated for their switch-hitting capabilities, enabling them to hit from both the right and left sides of the plate. This skill set provides a tactical edge against pitchers, making these players valuable for their adaptability and strategic contributions to their teams.",
      "color": "green",
      "images": [
        "https://midfield.mlbstatic.com/v1/people/450314/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/664023/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/451594/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/407893/spots/zoom",
      ]
    }
  ]
  
  const categories1 = [
    {
      "category": "⚾ 200+ Career Wins",
      "items": ["Andy Pettitte", "Jamie Moyer", "Mark Buehrle", "Tim Wakefield"],
      "commonality": "All have achieved over 200 career wins in Major League Baseball",
      "color": "green",
      "images": [
        "https://midfield.mlbstatic.com/v1/people/120485/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/119469/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/279824/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/123801/spots/zoom",
      ]
    },
    {
      "category": "💥 40 HR 40 SB Club",
      "items": ["Ronald Acuña Jr.", "Álex Rodríguez", "Barry Bonds", "José Canseco"],
      "commonality": "Members of the exclusive 40 home runs and 40 stolen bases in a single season club",
      "color": "purple",
      "images": [
        "https://midfield.mlbstatic.com/v1/people/660670/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/121347/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/111188/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/111962/spots/zoom",
      ]
    },
    {
      "category": "🌟 All-Star Game MVPs",
      "items": ["Robinson Cano", "Prince Fielder", "Shane Bieber", "JD Drew"],
      "commonality": "Each player has won the MVP award at the MLB All-Star Game",
      "color": "silver",
      "images": [
        "https://midfield.mlbstatic.com/v1/people/429664/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/425902/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/669456/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/136770/spots/zoom",
      ]
    },
    {
      "category": "🔄 Played for At Least 7 Teams",
      "items": ["Gary Sheffield", "Curtis Granderson", "Dan Haren", "Tyler Clippard"],
      "commonality": "Each player has played for at least seven different teams in Major League Baseball",
      "color": "orange",
      "images": [
        "https://midfield.mlbstatic.com/v1/people/122111/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/434158/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/429717/spots/zoom",
        "https://midfield.mlbstatic.com/v1/people/461325/spots/zoom",
    ]
}
]

// Simulate loading categories for different puzzles (You should replace this with actual logic to load different puzzles based on selection)
const loadCategoriesForPuzzle = (puzzleNumber) => {
  const puzzles = {
    '1': categories1, // Categories set for puzzle 4
    '2': categories2, // Categories set for puzzle 4
    '3': categories3, // Categories set for puzzle 4
    '4': categories4, // Categories set for puzzle 4
    '5': categories5, // Categories set for puzzle 5
    '6': categories6, // Categories set for puzzle 6
    '7': categories7, // Categories set for puzzle 6
    '8': categories8, // Categories set for puzzle 6
    '9': categories9, // Categories set for puzzle 6
    '10': categories10, // Categories set for puzzle 6
    '11': categories11, // Categories set for puzzle 6
    '12': categories12, // Categories set for puzzle 6
    '13': categories13, // Categories set for puzzle 6
    '14': categories14, // Categories set for puzzle 6
    '15': categories15, // Categories set for puzzle 6
    '16': categories16, // Categories set for puzzle 6
    '17': categories17, // Categories set for puzzle 6
    '18': categories18, // Categories set for puzzle 6
    '19': categories19, // Categories set for puzzle 6
    '20': categories20, // Categories set for puzzle 6
    '21': categories21, // Categories set for puzzle 6
    '22': categories22, // Categories set for puzzle 6

  };

  return puzzles[puzzleNumber] || [];
};

// Function to get puzzle numbers dynamically from the puzzles object
const getPuzzleNumbers = () => {
  const puzzles = {
    '1': categories1, // Categories set for puzzle 4
    '2': categories2, // Categories set for puzzle 4
    '3': categories3, // Categories set for puzzle 4
    '4': categories4, // Categories set for puzzle 4
    '5': categories5, // Categories set for puzzle 5
    '6': categories6, // Categories set for puzzle 6
    '7': categories7, // Categories set for puzzle 6
    '8': categories8, // Categories set for puzzle 6
    '9': categories9, // Categories set for puzzle 6
    '10': categories10, // Categories set for puzzle 6
    '11': categories11, // Categories set for puzzle 6
    '12': categories12, // Categories set for puzzle 6
    '13': categories13, // Categories set for puzzle 6
    '14': categories14, // Categories set for puzzle 6
    '15': categories15, // Categories set for puzzle 6
    '16': categories16, // Categories set for puzzle 6
    '17': categories17, // Categories set for puzzle 6
    '18': categories18, // Categories set for puzzle 6
    '19': categories19, // Categories set for puzzle 6
    '20': categories20, // Categories set for puzzle 6
    '21': categories21, // Categories set for puzzle 6
    '22': categories22, // Categories set for puzzle 6

  };

  return Object.keys(puzzles);
};


// Function to shuffle array items
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const ConnectionsGame = () => {
  
  const [puzzleNumber, setPuzzleNumber] = useState("22"); // Default puzzle number
  const [selectedItems, setSelectedItems] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [foundCategories, setFoundCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [gameEndedSuccessfully, setGameEndedSuccessfully] = useState(false);
  const [categories, setCategories] = useState(loadCategoriesForPuzzle(puzzleNumber)); // Load categories based on puzzle number
  const maxAttempts = 5;
  const [hintsUsed, setHintsUsed] = useState(categories.map(() => ({ bigHint: false, smallHint: false })));
  const [howToPlayOpen, setHowToPlayOpen] = useState(false);
  const [gameHasEnded, setGameHasEnded] = useState(false);
  const [theme, setTheme] = useState('light'); // default theme
  const isDesktop = window.innerWidth >= 800;
  const maxLength = isDesktop >= 800 ? 30 : 18;
  // Initialize Google Analytics
  ReactGA.initialize("G-MHGRFQTFDL");
  // Add useEffect hook to update categories when puzzleNumber changes
  useEffect(() => {
    // Load new categories based on the selected puzzle number
    const newCategories = loadCategoriesForPuzzle(puzzleNumber);
    setCategories(newCategories);
  
    // Reset hints for the new categories
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
  
    // Shuffle items in the new categories
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    setAllItems(shuffledItems);
  
    // Reset game state to initial values
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setGuesses([]);
    setGameEndedSuccessfully(false);
    setGameHasEnded(false); // This ensures the game is no longer in the "ended" state
  
    // Optionally, if you're storing the game state in localStorage, you might want to update/clear it here
  
  }, [puzzleNumber]);
  
  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  
  }, []);

  // Omitting existing useEffects and functions for brevity...

  // Initial setup of game state based on selected puzzle
  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${puzzleNumber}`));
    if (storedState) {
      // Logic to load game state from local storage
    } else {
      const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
      setAllItems(shuffledItems);
      resetGameState(false); // Reset without clearing local storage
    }
  }, [categories]);

  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };
  useEffect(() => {
    setCategories(loadCategoriesForPuzzle(puzzleNumber));
    // Include other logic for when puzzleNumber changes, like resetting the game state
  }, [puzzleNumber]);

  // Dynamically generate puzzle number options for the dropdown
  const puzzleNumberOptions = getPuzzleNumbers();
  useEffect(() => {
    // Load the categories corresponding to the selected puzzle number
    const newCategories = loadCategoriesForPuzzle(puzzleNumber);
    
    // Set the loaded categories to the state
    setCategories(newCategories);
    
    // Reset the hints used for each category in the new puzzle
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
    
    // Shuffle all items from the new categories to randomize their order
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    
    // Set the shuffled items to the state, effectively resetting the game board
    setAllItems(shuffledItems);
    
    // Reset the selected items as we're starting a new puzzle
    setSelectedItems([]);
    
    // Reset the found categories as none have been found in the new puzzle
    setFoundCategories([]);
    
    // If there are additional states that depend on the puzzle number or categories,
    // reset them here to ensure the game correctly initializes for the new puzzle.
    // Example: Resetting guesses, attempts, or any other game-related states.
    
  }, [puzzleNumber]);
  

  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  }, []);
    const updateFoundCategories = () => {
      let remainingItems = [...allItems];
    
      categories.forEach((category, index) => {
        const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
        const isCategoryCompletelySelected = selectedInCategory.length === category.items.length;
    
        if (isCategoryCompletelySelected && !foundCategories.includes(category)) {
          setFoundCategories(prevFoundCategories => [...prevFoundCategories, category]);
          setHintsUsed(hints => {
            const newHints = [...hints];
            newHints[index].found = true;
            return newHints;
          });
          remainingItems = remainingItems.filter(item => !category.items.includes(item));
        }
      });
    
      setAllItems(remainingItems);
    };
    
 
  // Add this useEffect hook after your existing useEffect hooks
  useEffect(() => {
    // Check if all categories have been found
    if (foundCategories.length === categories.length) {
      handleEndGame(true); // Game ends successfully
    } else if (attempts >= maxAttempts) {
      // Check if max attempts have been reached without finding all categories
      handleEndGame(false); // Game ends unsuccessfully
    }
  }, [foundCategories, attempts]); // Depend on foundCategories and attempts

  // Initial setup of game state
  useEffect(() => {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState();
  }, []);
  // Handle toggling between displaying item names and images
  const toggleDisplay = () => {
    setShowImages(prevState => !prevState);
  };

  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${puzzleNumber}`));
    if (storedState) {
      setGuesses(storedState.guesses || []);
      setSelectedItems(storedState.selectedItems || []);
      setFoundCategories(storedState.foundCategories || []);
      setAttempts(storedState.attempts || 0);
      // Do not set modalOpen from stored state; always start with it closed
      // Additional state can be loaded here as needed
    }
  }, []);
  // Reset game state for new games or retries
  const resetGameState = (clearLocalStorage = true) => {
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setModalOpen(false);
    setGuesses([]);
    if (clearLocalStorage) {
      localStorage.removeItem(`gameState-${puzzleNumber}`);
      localStorage.removeItem(`guesses-${puzzleNumber}`); // Clear guess history from local storage
    }
};

// Save guesses to local storage
const saveGuessToLocalStorage = (guess) => {
  const key = `guesses-${puzzleNumber}`;
  const guesses = JSON.parse(localStorage.getItem(key)) || [];
  guesses.push(guess);
  localStorage.setItem(key, JSON.stringify(guesses));
};

// Emoji map for category colors
const colorEmojiMap = {
  'blue': '🟦',
  'red': '🟥',
  'orange': '🟧',
  'yellow': '🟨',
  'purple': '🟪',
  'green': '🟩',

};

const HowToPlayModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <h2>How to Play MLB Connections</h2>
        <button className="modal-close" onClick={onClose}>×</button>
        <p>Find groups of four baseball-related items that share something in common.</p>
        <p>Select four items and tap 'Submit' to check if your guess is correct.</p>
        <p>Uncover all connections without making more than 4 mistakes to win!</p>
        <h3>Category Examples</h3>
        <p>🏆 World Series Champions: Derek Jeter, Madison Bumgarner, David Ortiz, Clayton Kershaw</p>
        <p>🌟 MLB All-Stars: Mike Trout, Mookie Betts, Fernando Tatis Jr., Jacob deGrom</p>
        <p>Note: Categories will delve into specifics like awards, drafts, and memorable moments in baseball.</p>
        <p>Each puzzle has exactly one solution. Be mindful of items that may fit into more than one category!</p>
        <p>Each group is assigned a color, revealed as you solve:</p>
  
        <p>Ready to put your baseball knowledge to the test? Enjoy MLB Connections!</p>
      </div>
    </div>
  );
  
};const StatsModal = ({ isOpen, onClose, guesses, congratulations = false, puzzleNumber }) => {
  if (!isOpen) return null;

  const safeGuesses = Array.isArray(guesses) ? guesses : [];
  const baseURL = "https://crossovergrid.com"; // Adjust as needed

  const copyToClipboard = async () => {
    const textToCopy = `Crossover Connections ⚾️ Puzzle #${puzzleNumber} ${baseURL}/\n` +
      safeGuesses.map(guess =>
        guess.map(color => colorEmojiMap[color] || '⬜').join('')
      ).join('\n');
    await navigator.clipboard.writeText(textToCopy);
    // Assuming you have a toast notification system
    toast.success('Guesses copied to clipboard!');
  };

  const shareOnTwitter = () => {
    const puzzleURL = baseURL; // Adjust based on your actual URL structure
    let shareText = `Crossover Connections ⚾️ Puzzle #${puzzleNumber} ${puzzleURL}\nGuesses:\n`;
  
    // Append guesses with emojis
    for (const guess of safeGuesses) {
      const guessLine = guess.map(color => colorEmojiMap[color] || '⬜').join('');
      shareText += `${guessLine}\n`;
    }
  
    // Manually replace critical characters to ensure the URL is valid
    shareText = shareText.replace(/\n/g, '%0A').replace(/#/g, '%23');
  
    const tweetURL = `https://twitter.com/intent/tweet?text=${shareText}`;
    window.open(tweetURL, '_blank');
  };
  

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <h2>Crossover Connections ⚾️ Puzzle #{puzzleNumber}</h2>
        <button className="modal-close" onClick={onClose}>×</button>
        <div>
          <p>{congratulations ? "Congratulations! You've solved the puzzle!" : "Here were your guesses:"}</p>
          <button onClick={copyToClipboard}>Copy Guesses</button>
        <button onClick={shareOnTwitter} style={{ backgroundColor: '#1DA1F2', color: '#fff', marginLeft: '10px' }}>Tweet</button>
          {safeGuesses.map((guess, index) => (
            <div key={index} className="guess-emoji-row">
              {guess.map(color => colorEmojiMap[color] || '⬜').join('')}
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

// Load guesses from local storage on component mount
useEffect(() => {
  const storedGuesses = JSON.parse(localStorage.getItem(`guesses-${puzzleNumber}`)) || [];
  setGuesses(storedGuesses);
}, []);

// Handle item clicks to select or deselect items
const handleItemClick = (item) => {
  setSelectedItems(prevItems =>
    prevItems.includes(item) ? prevItems.filter(i => i !== item) : [...prevItems, item]
  );
};

const handleHintClick = (categoryIndex, hintSize) => {
  const category = categories[categoryIndex];
  if (hintSize === 'big') {
    toast.info(`The category is: ${category.category}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].bigHint = true;
      return newHints;
    });
  } else if (hintSize === 'small') {
    const miniHintItems = [category.items[0], category.items[1]]; // Take the first two items for the mini hint
    toast.info(`Two items in this category are: ${miniHintItems.join(' and ')}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].smallHint = true;
      return newHints;
    });
  }
};const handleSubmit = () => {
  // Dismiss all existing toasts before showing a new one
  toast.dismiss();

  // Prevent any submissions if the game has ended
  if (gameEndedSuccessfully || attempts >= maxAttempts) {
    toast.error("Game has ended. No more submissions allowed.");
    return;
  }

  // Check for the correct number of selected items
  if (selectedItems.length !== 4) {
    toast.error("Please select exactly 4 items.");
    return;
  }

  // Extract the colors of the selected items
  const selectedColors = selectedItems.map(item =>
    categories.find(cat => cat.items.includes(item)).color
  );

  // Check if the guess has already been made
  const currentGuessSorted = [...selectedItems].sort().join(',');
  const guessAlreadyMade = guesses.some(guess =>
    [...guess].sort().join(',') === currentGuessSorted
  );

  if (guessAlreadyMade) {
    toast.error("You've already made this guess. Try a different combination.");
    return;
  }

  // Save the guess in state and localStorage
  setGuesses(prevGuesses => [...prevGuesses, selectedColors]);
  saveGuessToLocalStorage(selectedColors);

  // Find if a complete category is matched
  const matchedCategory = categories.find(category =>
    selectedItems.every(item => category.items.includes(item)) &&
    category.items.every(item => selectedItems.includes(item))
  );

  // Check for incorrect guesses
  if (!matchedCategory) {
    const threeOfAKind = categories.some(category => {
      const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
      return selectedInCategory.length === 3 && !foundCategories.includes(category);
    });

    // Provide feedback based on the correctness of the guess
    if (threeOfAKind) {
      toast.info("3 of a kind. One Away");
    } else {
      toast.error("That's not a correct group. Try again!");
    }

    // Update the number of attempts
    setAttempts(prevAttempts => prevAttempts + 1);

    // Check if the game should end
    if (attempts + 1 >= maxAttempts) {
      handleEndGame(false);
    }
  } else {
    // Handle a successful category match
    updateFoundCategories();
    setSelectedItems([]); // Clear selection for a new guess
    toast.success("Category Found!"); // Notify the user of a successful match
  }
};

// Apply toast.dismiss() similarly in other functions where toast notifications are shown

const handleEndGame = (success) => {
  setGameEndedSuccessfully(success);
  setGameHasEnded(true); // Indicate that the game has ended
  setModalOpen(true); // Optionally open the stats modal immediately if you prefer
};


const handleResetGame = () => {
  if (window.confirm("Are you sure you want to reset the game? All progress will be lost.")) {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState(); // Function to reset game state
    // Also clear any relevant localStorage entries here
  }
};
const [showImages, setShowImages] = useState(false);
useEffect(() => {
  document.body.setAttribute('data-theme', theme);
}, [theme]);

return (
  <div className="connections-game">
  <nav className="navbar">
    {/* Left-aligned Logo and Crossover MLB text, centered alignment */}
    <a href="https://crossovergrid.com" 
       style={{ 
         textDecoration: "none", 
         color: "inherit", 
         display: "flex", 
         alignItems: "center", // This ensures vertical alignment
         justifyContent: "center" // This centers the content horizontally if the a-tag is a flex container
       }}>
      <img src={crossover_logo} alt="Logo" className="navbar-logo" />
      <p style={{ 
          margin: 0, // Removes default margin that might offset alignment
          fontWeight: "bold", 
          fontSize: 20,
          display: "flex", // Makes the paragraph itself a flex container
          alignItems: "center", // Centers the text inside the p-tag vertically
          justifyContent: "center" // Centers the text inside the p-tag horizontally
        }}>
        CROSSOVER MLB
      </p>
    </a>

    {/* Spacer to center the Crossover MLB logo and text in the navbar */}
    <div style={{ flexGrow: 1 }}></div>

    {/* Home and HowToPlayModal Icons */}
    <div>
      
      <IconButton href="/" title="Home">
        <HomeIcon />
      </IconButton>
      <IconButton onClick={() => setHowToPlayOpen(true)} title="How to Play">
        <HelpOutlineIcon />
      </IconButton>
    </div>

    <HowToPlayModal isOpen={howToPlayOpen} onClose={() => setHowToPlayOpen(false)} />
  </nav>



<ToastContainer position="top-center" />
    <h1>MLB Connections</h1>    <Select
  value={puzzleNumber}
  onChange={(e) => setPuzzleNumber(e.target.value)}
  displayEmpty
  inputProps={{ 'aria-label': 'Select puzzle' }}
  MenuProps={{
    PaperProps: {
      style: {
        maxHeight: 200, // Adjust based on your needs
        overflow: 'auto'
      }
    }
  }}
>
  {puzzleNumberOptions.map((number) => (
    <MenuItem key={number} value={number}>
      Puzzle {number}
    </MenuItem>
  ))}
</Select>

    <StatsModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      guesses={guesses}
      gameEndedSuccessfully={gameEndedSuccessfully}
      puzzleNumber={puzzleNumber} 

    />
    {!gameHasEnded && (
      <>
<div className="items-container">
  {allItems.map((item, index) => {
    const category = categories.find(category => category.items.includes(item));
    const itemIndexInCategory = category.items.indexOf(item);
    const imageUrl = category.images[itemIndexInCategory];
    const isSelected = selectedItems.includes(item);
    
    const itemStyle = isSelected
    ? theme === 'dark'
      ? { backgroundColor: '#ffcc00', color: 'black' } // Dark mode: bright background, dark text
      : { backgroundColor: '#ddd', color: 'black' } // Light mode: light background, dark text
    : {};
  
  return (
    <div
      key={index}
      className={`item ${showImages ? 'item-showing-image' : ''} ${isSelected ? 'selected' : ''}`}
      onClick={() => handleItemClick(item)}
      aria-pressed={isSelected ? 'true' : 'false'}
      style={itemStyle} // Apply the inline styles
    >
      {showImages ? (
        <img src={imageUrl} alt={item} />
      ) : (
        // The text color will change based on the style conditionally set above
        <span>{item}</span>
      )}
    </div>
  );
})}
</div>

      </>
    )}
 <div className="controls">
 {!gameHasEnded && (
      <> 
      <button onClick={() => setSelectedItems([])} disabled={selectedItems.length === 0}>
        Deselect All
      </button>
      <button onClick={handleSubmit} disabled={gameEndedSuccessfully || attempts >= maxAttempts}>
        Submit
      </button>
      <button onClick={toggleDisplay}>
        {showImages ? "Show Item Names" : "Show Images"}
      </button> </>
   )}
      {/* Conditionally render the View Stats button if the game has ended */}

  </div>
  



    {gameHasEnded && (
        <button onClick={() => setModalOpen(true)}>
          View Stats
        </button>
      )}
   {gameHasEnded && (
      <div className="end-game-display">
        <p>{gameEndedSuccessfully ? "Congratulations! You've completed the game!" : "Game Over. Better luck next time!"}</p>
        <div className="correct-answers">
          <h2>Correct Answers:</h2>
          {categories.map((category, index) => (
            <div key={index} className="category-display" style={{ borderColor: category.color, borderWidth: 1, borderStyle: 'solid', borderRadius: '10px', margin: '.5px 0', padding: '2px' }}>
              <h3 style={{ textAlign: 'center', color: category.color, margin: '2px 0', borderRadius: '10px' }}>{category.category}</h3>
              <div style={{ display: 'flex', justifyContent: 'space-around', padding: '2px', flexWrap: 'wrap' }}>
                {category.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="category-item" style={{ margin: '0px', textAlign: 'center', maxWidth: '120px' }}>
                    <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '2px', width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto 0px', backgroundColor: '#fff' }}>
                      <img src={category.images[itemIndex]} alt={item} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', borderRadius: '8px' }} />
                    </div>
                    <p style={{ fontSize: '0.8em', margin: '0' }}>{item}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )}



{!gameHasEnded && (
      <> 
    {/* Status display section */}
    <div className="status">
      <p>Mistakes Remaining: {'⚫️'.repeat(maxAttempts - attempts) + '⚪️'.repeat(attempts)}</p>
      <p>Found Categories: {foundCategories.map(c => c.category).join(", ")}</p>
    </div>

    {/* Hints section */}
    <div className="hints-section">
      <h2>HINTS</h2>
      {categories.map((category, index) => {
        const isCategoryFound = foundCategories.includes(category);
        const categoryStyle = {
          borderColor: category.color,
          borderStyle: 'solid',
          borderWidth: '2px',
          backgroundColor: isCategoryFound ? category.color : 'transparent',
        };

        return (
          <div className="hint-category" key={index} style={categoryStyle}>
            <div className="category-name">{isCategoryFound ? category.category : `Category ${index + 1}`}</div>
            {!isCategoryFound && !hintsUsed[index].bigHint && (
              <div className="hints-buttons">
                <button onClick={() => handleHintClick(index, 'big')}>Big Hint</button>
                <button onClick={() => handleHintClick(index, 'small')}>Small Hint</button>
              </div>
            )}
            {isCategoryFound && (
              <div className="found-items">
                <p>Category Found!</p>
                <p>{category.items.join(", ")}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
    </>
   )}
    {/* Reset game button */}
    <button onClick={handleResetGame} className="reset-game-button">
      Reset Game
    </button>
    </div>
  );
};


export default ConnectionsGame;