
import { yellow } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './module.ConnectionsGame.css';
import crossover_logo from "../../assets/mlb.svg";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Select from "@mui/material/Select"; // Import Select component for dropdown
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem for dropdown items
import { makeStyles as makeMuiStyles } from "@mui/styles";
import ReactGA from "react-ga";

import HomeIcon from '@mui/icons-material/Home';
const isDesktop = window.innerWidth >= 800;
const maxLength = isDesktop >= 800 ? 30 : 18;
// Initialize Google Analytics
ReactGA.initialize("G-MHGRFQTFDL");

const baseURL = "crossovergrid.com/mlb/connections";
const categories33 = [
  {
    "category": "🔴 Played Entire Career with the Detroit Red Wings",
    "header": "NHL Players Who Played Their Entire Career with the Detroit Red Wings",
    "items": ["Pavel Datsyuk", "Steve Yzerman", "Alex Delvecchio", "Jonathan Ericsson"],
    "commonality": "NHL players who spent their entire professional careers with the Detroit Red Wings, contributing to the team's storied history",
    "color": "red",
    "images": []
  },
  {
    "category": "🖤 Played Entire Career with the Chicago Blackhawks",
    "header": "NHL Players Who Played Their Entire Career with the Chicago Blackhawks",
    "items": ["Corey Crawford", "Eric Daze", "Stan Mikita", "Brent Seabrook"],
    "commonality": "NHL players who dedicated their entire playing careers to the Chicago Blackhawks",
    "color": "black",
    "images": []
  },
  {
    "category": "🟡 Played Entire Career with the Boston Bruins",
    "header": "NHL Players Who Played Their Entire Career with the Boston Bruins",
    "items": ["Dit Clapper", "David Krejci", "Terry O'Reilly", "Tuukka Rask"],
    "commonality": "NHL players who spent their entire careers with the Boston Bruins, leaving lasting legacies with the team",
    "color": "yellow",
    "images": []
  },
  {
    "category": "🔵 Played Entire Career with the Montreal Canadiens",
    "header": "NHL Players Who Played Their Entire Career with the Montreal Canadiens",
    "items": ["Andrei Markov", "Bob Gainey", "Jean-Claude Tremblay", "Claude Provost"],
    "commonality": "NHL players who were exclusively part of the Montreal Canadiens throughout their NHL careers",
    "color": "blue",
    "images": []
  }
]

const categories32 = [
  {
    "category": "🏒 NHL Players Who Wore #81",
    "header": "NHL Players Famous for Wearing Jersey #81",
    "items": ["Phil Kessel", "Marian Hossa", "Lars Eller", "Kyle Connor"],
    "commonality": "NHL players known for wearing the number 81 on their jerseys, a number associated with some of the sport's top talents",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏒 5th Round Draft Picks in 2015",
    "header": "NHL Players Drafted in the 5th Round of 2015",
    "items": ["Kirill Kaprizov", "Ethan Bear", "Conor Garland", "Troy Terry"],
    "commonality": "Players who were selected in the 5th round of the 2015 NHL Draft, highlighting late-round talents who made significant impacts",
    "color": "red",
    "images": []
  },
  {
    "category": "🏒 Played for the London Knights",
    "header": "NHL Players Who Played for the London Knights",
    "items": ["Mitchell Marner", "Max Domi", "Matthew Tkachuk", "John Tavares"],
    "commonality": "Players who spent part of their junior hockey careers with the London Knights, a major junior ice hockey team in the Ontario Hockey League",
    "color": "green",
    "images": []
  },
  {
    "category": "🏒 Norris Trophy Winners",
    "header": "NHL Players Who Have Won the Norris Trophy",
    "items": ["Oliver Ekman-Larsson", "José Théodore", "Erik Karlsson", "P.K. Subban"],
    "commonality": "Defensemen who have won the Norris Trophy, awarded annually to the NHL's top defenseman who demonstrates throughout the season the greatest all-round ability in the position",
    "color": "purple",
    "images": []
  }
]

const categories31 = [
  {
    "category": "🔵 Montreal Canadiens First Overall Picks",
    "header": "First Overall Draft Picks by the Montreal Canadiens",
    "items": ["Vyacheslav Fetisov", "Réjean Houle", "Doug Wickenheiser", "Guy Lafleur"],
    "commonality": "Players who were selected as the first overall pick in the NHL Draft by the Montreal Canadiens",
    "color": "red",
    "images": []
  },
  {
    "category": "🗽 New York Rangers First Round Draft Picks",
    "header": "First Round Draft Picks by the New York Rangers",
    "items": ["Manny Malhotra", "J.T. Miller", "Dan Cloutier", "Chris Kreider"],
    "commonality": "Players who were chosen by the New York Rangers in the first round of the NHL Draft",
    "color": "blue",
    "images": []
  },
  {
    "category": "🛢️ Edmonton Oilers Defensemen with 50+ Points in a Season",
    "header": "Oilers Defensemen Who Scored 50+ Points in a Season",
    "items": ["Evan Bouchard", "Paul Coffey", "Risto Siltanen", "Charlie Huddy"],
    "commonality": "Defensemen from the Edmonton Oilers who have scored at least 50 points in a single NHL season",
    "color": "orange",
    "images": []
  },
  {
    "category": "🏒 Coaches/Management for the Montreal Canadiens in 2023-2024",
    "header": "Coaches and Management of the Montreal Canadiens in the 2023-2024 Season",
    "items": ["Martin St. Louis", "Alexandre Burrows", "Vincent Lecavalier", "Paul Byron"],
    "commonality": "Individuals who served in coaching or management roles for the Montreal Canadiens during the 2023-2024 NHL season",
    "color": "green",
    "images": []
  }
]

const categories30 = [
  {
    "category": "🏒 NHL Players Who Wore #88",
    "header": "NHL Players Famous for Wearing Jersey #88",
    "items": ["Brent Burns", "Patrick Kane", "David Pastrňák", "Andrei Vasilevskiy"],
    "commonality": "NHL players known for wearing the number 88 on their jerseys, a number associated with some of the sport's top talents",
    "color": "blue",
    "images": []
  },
  {
    "category": "🦆 900+ Games Played with the Anaheim Ducks",
    "header": "Players Who Played Over 900 Games for the Anaheim Ducks",
    "items": ["Teemu Selänne", "Cam Fowler", "Corey Perry", "Ryan Getzlaf"],
    "commonality": "Key figures in Anaheim Ducks history who have played more than 900 games for the team, contributing significantly over long careers",
    "color": "orange",
    "images": []
  },
  {
    "category": "🐙 Seattle Kraken First-Round Picks",
    "header": "First Round Draft Picks by the Seattle Kraken",
    "items": ["Matty Beniers", "Adam Larsson", "Jordan Eberle", "Oliver Bjorkstrand"],
    "commonality": "Players who were first-round picks in the NHL Draft and are now part of the Seattle Kraken, either through draft or acquisition",
    "color": "red",
    "images": []
  },
  {
    "category": "🛡️ Vegas Golden Knights First-Round Picks",
    "header": "First Round Draft Picks of the Vegas Golden Knights",
    "items": ["Jack Eichel", "Peyton Krebs", "Cody Glass", "Nick Suzuki"],
    "commonality": "Players who were selected in the first round of the NHL Draft by the Vegas Golden Knights, reflecting the team's strategic choices in building its roster",
    "color": "purple",
    "images": []
  }
]

const categories29 = [
  {
    "category": "🇫🇮 Finnish Forwards Who Played for the Montreal Canadiens",
    "header": "Finnish Forwards of the Montreal Canadiens",
    "items": ["Jesperi Kotkaniemi", "Artturi Lehkonen", "Saku Koivu", "Joel Armia"],
    "commonality": "Finnish forwards who have played for the Montreal Canadiens, contributing to the team's international presence",
    "color": "red",
    "images": []
  },
  {
    "category": "🏒 2014 Top 4 NHL Draft Picks",
    "header": "Top 4 Picks of the 2014 NHL Draft",
    "items": ["Leon Draisaitl", "Aaron Ekblad", "Sam Reinhart", "Sam Bennett"],
    "commonality": "Players who were selected as the top four picks in the 2014 NHL Draft, marking significant beginnings of their professional careers",
    "color": "blue",
    "images": []
  },
  {
    "category": "🇸🇪 Swedish Players for the Anaheim Ducks",
    "header": "Swedish Players Who Have Played for the Anaheim Ducks",
    "items": ["Rickard Rakell", "Hampus Lindholm", "Jakob Silfverberg", "Isac Lundeström"],
    "commonality": "Swedish players who have been part of the Anaheim Ducks, contributing their skills to the team",
    "color": "orange",
    "images": []
  },
  {
    "category": "🏅 First Pick in the Second Round of an NHL Draft",
    "header": "First Selections in the Second Round of NHL Drafts",
    "items": ["Mikko Koskinen", "Shane Pinto", "Jacob Markström", "Mattias Samuelsson"],
    "commonality": "NHL players who were the first picks in the second round of their respective NHL Draft years",
    "color": "green",
    "images": []
  }
]

const categories28 = [
  {
    "category": "🐙 Seattle Kraken Defensemen",
    "header": "Defensemen of the Seattle Kraken",
    "items": ["Mark Giordano", "Will Borgen", "Justin Schultz", "Adam Larsson"],
    "commonality": "Defense players who are part of the Seattle Kraken's roster",
    "color": "purple",
    "images": []
  },
  {
    "category": "🛢️ Drafted Top 10 by the Oilers",
    "header": "Players Drafted in the Top 10 by the Edmonton Oilers",
    "items": ["Ryan Nugent-Hopkins", "Grant Fuhr", "Darnell Nurse", "Boyd Devereaux"],
    "commonality": "NHL players who were top 10 draft picks selected by the Edmonton Oilers",
    "color": "orange",
    "images": []
  },
  {
    "category": "🌟 Stars First Round Pick",
    "header": "First Round Draft Picks by the Dallas Stars",
    "items": ["Steve Ott", "Matt Niskanen", "Jack Campbell", "Jake Oettinger"],
    "commonality": "Players who were chosen by the Dallas Stars in the first round of the NHL Draft",
    "color": "green",
    "images": []
  },
  {
    "category": "🔵 Played 500 Games with the Columbus Blue Jackets",
    "header": "Players Who've Played 500 Games for the Columbus Blue Jackets",
    "items": ["Jared Boll", "David Savard", "Cam Atkinson", "Fyodor Tyutin"],
    "commonality": "Players who have played at least 500 games with the Columbus Blue Jackets, demonstrating loyalty and endurance",
    "color": "blue",
    "images": []
  }
]

const categories27 = [
  {
    "category": "🏒 Wore a Letter with the Seattle Kraken",
    "header": "Players Who Wore a Captain or Alternate's Letter for the Seattle Kraken",
    "items": ["Yanni Gourde", "Adam Larsson", "Jordan Eberle", "Mark Giordano"],
    "commonality": "NHL players who have been captains or alternates for the Seattle Kraken",
    "color": "blue",
    "images": []
  },
  {
    "category": "🌲 400+ Games with the Minnesota Wild",
    "header": "Players Who've Played Over 400 Games for the Minnesota Wild",
    "items": ["Matt Dumba", "Mikko Koivu", "Ryan Suter", "Jared Spurgeon"],
    "commonality": "NHL players who have been steadfast members of the Minnesota Wild, playing in more than 400 games",
    "color": "green",
    "images": []
  },
  {
    "category": "🇷🇺 Russian Forwards to Play for Pittsburgh",
    "header": "Russian Forwards Who Played for the Pittsburgh Penguins",
    "items": ["Evgeni Malkin", "Alexei Kovalev", "Alexei Morozov", "Tikko"],
    "commonality": "Russian forwards who have taken the ice for the Pittsburgh Penguins",
    "color": "red",
    "images": []
  },
  {
    "category": "🥅 Wore #87 in a Season",
    "header": "NHL Players Who Wore the Number 87",
    "items": ["Sidney Crosby", "Donald Brashear", "Liam O'Brien", "Pierre Turgeon"],
    "commonality": "NHL players known for wearing the number 87 during a season",
    "color": "orange",
    "images": []
  }
]

const categories26 = [
  {
    "category": "🏒 PPG Season with New York Islanders",
    "header": "Islanders Players with a Season Averaging One Point Per Game",
    "items": ["Mathew Barzal", "Bryan Trottier", "Zigmund Palffy", "Ray Ferraro"],
    "commonality": "New York Islanders players who have had seasons averaging one point per game",
    "color": "orange",
    "images": []
  },
  {
    "category": "🇷🇺 Russian Calder Trophy Winners",
    "header": "Russian Players Who Won the Calder Trophy",
    "items": ["Artemi Panarin", "Sergei Makarov", "Alexei Yashin", "Evgeni Nabokov"],
    "commonality": "Russian NHL players who have received the Calder Memorial Trophy as the league's top rookie",
    "color": "red",
    "images": []
  },
  {
    "category": "🛡️ Calder Winning Defenders",
    "header": "Defensemen Who Won the Calder Trophy",
    "items": ["Aaron Ekblad", "Tyler Myers", "Moritz Seider", "Bryan Berard"],
    "commonality": "Defensemen in the NHL who won the Calder Trophy during their rookie seasons",
    "color": "purple",
    "images": []
  },
  {
    "category": "🎯 Scored 100 Goals with the Columbus Blue Jackets",
    "header": "Players Who Scored 100 Goals with the Blue Jackets",
    "items": ["Oliver Bjorkstrand", "Boone Jenner", "R.J. Umberger", "Rick Nash"],
    "commonality": "Columbus Blue Jackets players who have scored at least 100 goals for the team",
    "color": "blue",
    "images": []
  }
]

const categories25 = [
  {
    "category": "🏒 NHL 3rd Overall Picks",
    "header": "NHL Players Drafted 3rd Overall",
    "items": ["Mason McTavish", "Jonathan Drouin", "Henrik Sedin", "Matt Duchene"],
    "commonality": "NHL players who were selected as the 3rd overall pick in their respective NHL Draft years",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏒 Players Who Wore #91 in the 2020/2023 Season",
    "header": "NHL Players Wearing #91 During the 2020/2023 Season",
    "items": ["Vladimir Tarasenko", "Steven Stamkos", "John Tavares", "Tyler Seguin"],
    "commonality": "NHL players known for wearing the number 91 on their jerseys in the 2020/2023 season",
    "color": "red",
    "images": []
  },
  {
    "category": "🏒 Finnish Hockey Hall of Fame Inductees",
    "header": "Inductees to the Finnish Hockey Hall of Fame",
    "items": ["Miikka Kiprusoff", "Kimmo Timonen", "Jari Kurri", "Sami Salo"],
    "commonality": "Finnish hockey players who have been inducted into the Finnish Hockey Hall of Fame",
    "color": "orange",
    "images": []
  },
  {
    "category": "🦈 San Jose Sharks Single Season Leading Scorers",
    "header": "Single Season Scoring Leaders for the San Jose Sharks",
    "items": ["Mikael Granlund", "Brent Burns", "Joe Thornton", "Patrick Marleau"],
    "commonality": "Players who led the San Jose Sharks in scoring for a single season",
    "color": "green",
    "images": []
  }
]
 const categories24  = [
  {
    "category": "🏆 Norris Trophy Winners Who Have Worn a 'C'",
    "header": "Norris Trophy Winners Who Have Served as Captain",
    "items": ["Erik Karlsson", "Chris Pronger", "Nicklas Lidström", "Zdeno Chára"],
    "commonality": "NHL defensemen who have won the Norris Trophy and have also worn the captain's 'C' during their careers",
    "color": "gold",
    "images": []
  },
  {
    "category": "🏒 Won a Cup with the Colorado Avalanche",
    "header": "NHL Players Who Won a Stanley Cup with the Avalanche",
    "items": ["Rob Blake", "Adam Foote", "Joe Sakic", "Mikko Rantanen"],
    "commonality": "NHL players who have won a Stanley Cup while playing with the Colorado Avalanche",
    "color": "maroon",
    "images": []
  },
  {
    "category": "🥅 Russian 40+ Goal Scorers",
    "header": "Russian NHL Players with 40+ Goals in a Season",
    "items": ["Alex Ovechkin", "Ilya Kovalchuk", "Alexander Mogilny", "Vladimir Tarasenko"],
    "commonality": "Russian NHL players who have scored 40 or more goals in a single season",
    "color": "red",
    "images": []
  },
  {
    "category": "🐻 Russians to Play for the Boston Bruins",
    "header": "Russian Players Who Have Played for the Bruins",
    "items": ["Dmitry Orlov", "Sergei Gonchar", "Sergei Samsonov", "Andrei Nazarov"],
    "commonality": "Russian NHL players who have been on the roster of the Boston Bruins",
    "color": "black",
    "images": []
  }
]

const categories23 = [
  {
    "category": "🏒 NHL 600+ Goal Scorers",
    "header": "NHL Players with 600 or More Career Goals",
    "items": ["Jaromir Jagr", "Alex Ovechkin", "Patrick Marleau", "Steve Yzerman"],
    "commonality": "Elite NHL players who have scored 600 or more goals in their careers",
    "color": "purple",
    "images": []
  },
  {
    "category": "🥅 Goalies Who Played with the Chicago Blackhawks",
    "header": "Goalkeepers Who Suited Up for the Chicago Blackhawks",
    "items": ["Dominik Hasek", "Ed Belfour", "Tony Esposito", "Jocelyn Thibault"],
    "commonality": "Goaltenders who have been between the pipes for the Chicago Blackhawks",
    "color": "red",
    "images": []
  },
  {
    "category": "🎖️ Goalies Who Played with the Washington Capitals",
    "header": "Netminders Who Played for the Washington Capitals",
    "items": ["Olaf Kölzig", "Semyon Varlamov", "Braden Holtby", "José Théodore"],
    "commonality": "NHL goaltenders who have been a part of the Washington Capitals",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏒 100+ Points in a Season with Edmonton Oilers",
    "header": "Players with 100+ Points in a Single Season for the Oilers",
    "items": ["Wayne Gretzky", "Paul Coffey", "Jari Kurri", "Connor McDavid"],
    "commonality": "NHL players who have tallied 100 points or more in one season while playing for the Edmonton Oilers",
    "color": "orange",
    "images": []
  }
]

const categories22 = [
  {
    "category": "🦑 Seattle Kraken Forwards",
    "header": "Forwards of the Seattle Kraken",
    "items": ["Matt Beniers", "Jordan Eberle", "Jaden Schwartz", "Jared McCann"],
    "commonality": "Offensive players who are part of the forward lineup for the Seattle Kraken",
    "color": "green",
    "images": []
  },
  {
    "category": "🐧 Pittsburgh Penguins Goaltenders",
    "header": "Goaltenders for the Pittsburgh Penguins",
    "items": ["Casey DeSmith", "Tristan Jarry", "Matt Murray", "Tom Barrasso"],
    "commonality": "Netminders who have taken the ice for the Pittsburgh Penguins",
    "color": "orange",
    "images": []
  },
  {
    "category": "🧥 Columbus Blue Jackets Wingers",
    "header": "Wingers Who Played for the Columbus Blue Jackets",
    "items": ["Artemi Panarin", "Johnny Gaudreau", "Brandon Saad", "Jakub Voracek"],
    "commonality": "Wingers who have been key offensive contributors for the Columbus Blue Jackets",
    "color": "blue",
    "images": []
  },
  {
    "category": "🥅 Goalies Who Became General Managers",
    "header": "NHL Goalies Turned General Managers",
    "items": ["Garth Snow", "Jim Rutherford", "Ron Hextall", "John Davidson"],
    "commonality": "Former NHL goaltenders who transitioned into management roles as general managers",
    "color": "red",
    "images": []
  }
]

const categories21 = [
  {
    "category": "🏅 NHL Award Namesakes",
    "header": "NHL Awards Named After Players",
    "items": ["Mark Messier", "Maurice 'Rocket' Richard", "Georges Vézina", "Ted Lindsay"],
    "commonality": "Legendary NHL players who have had prestigious league awards named in their honor",
    "color": "purple",
    "images": []
  },
  {
    "category": "🚨 70 Goals in a Season",
    "header": "NHL Players with 70 Goals in a Single Season",
    "items": ["Teemu Selänne", "Jari Kurri", "Brett Hull", "Wayne Gretzky"],
    "commonality": "Elite NHL goal scorers who reached or exceeded the 70-goal mark in a single season",
    "color": "red",
    "images": []
  },
  {
    "category": "🍏 100 Assists in a Season",
    "header": "NHL Players with 100 Assists in a Single Season",
    "items": ["Connor McDavid", "Nikita Kucherov", "Mario Lemieux", "Bobby Orr"],
    "commonality": "Playmakers in the NHL who have tallied 100 or more assists during one season",
    "color": "blue",
    "images": []
  },
  {
    "category": "🎽 Played More than 82 Regular Season Games in a Single Season",
    "header": "NHL Players Who Played More Than 82 Games in One Season",
    "items": ["Pat LaFontaine", "Tyson Barrie", "Jimmy Carson", "Bill Guerin"],
    "commonality": "NHL players who, due to trades and schedule peculiarities, played more than the standard 82 regular-season games in a single season",
    "color": "green",
    "images": []
  }
]

const categories20 = [
  {
    "category": "🏒 Top 4 Picks in the 2015 NHL Entry Draft",
    "header": "Top Four Selections of the 2015 NHL Entry Draft",
    "items": ["Connor McDavid", "Jack Eichel", "Mitchell Marner", "Dylan Strome"],
    "commonality": "NHL players who were the first four picks in the 2015 NHL Entry Draft",
    "color": "blue",
    "images": []
  },
  {
    "category": "🍁 Played 50 Games or Less with the Toronto Maple Leafs",
    "header": "NHL Players with 50 or Fewer Games for the Toronto Maple Leafs",
    "items": ["Nick Foligno", "Olli Jokinen", "Richie Rich", "Ryan O'Reilly"],
    "commonality": "NHL players who had a brief stint with the Toronto Maple Leafs, playing 50 or fewer games",
    "color": "navy",
    "images": []
  },
  {
    "category": "🥅 Scored 30 Goals in a Season with the Boston Bruins",
    "header": "Players Who Scored 30 Goals in a Season for the Bruins",
    "items": ["Jarome Iginla", "Glen Murray", "Brad Marchand", "David Pastrnak"],
    "commonality": "NHL players who have scored at least 30 goals in a single season while playing for the Boston Bruins",
    "color": "black",
    "images": []
  },
  {
    "category": "🌟 Fifth Overall NHL Draft Picks",
    "header": "NHL Players Drafted Fifth Overall",
    "items": ["Ryan Strome", "Noah Hanifin", "Elias Pettersson", "Carey Price"],
    "commonality": "NHL players who were selected as the fifth overall pick in the NHL Draft",
    "color": "red",
    "images": []
  }
]

const categories19 = [
  {
    "category": "🏒 7th Round NHL Draft Picks",
    "header": "Notable NHL 7th Round Draft Picks",
    "items": ["Dustin Wolf", "Jason Demers", "Pierre Engvall", "Troy Brouwer"],
    "commonality": "NHL players who were selected in the 7th round of the NHL Draft and made significant contributions to their teams",
    "color": "green",
    "images": []
  },
  {
    "category": "⚡ Tampa Bay Lightning Captains",
    "header": "Captains of the Tampa Bay Lightning",
    "items": ["Steven Stamkos", "Dave Andreychuk", "Tim Taylor", "Vincent Lecavalier"],
    "commonality": "Players who have served as captains for the Tampa Bay Lightning",
    "color": "blue",
    "images": []
  },
  {
    "category": "🇸🇪 Swedish Defenders Drafted in Top 5",
    "header": "Top 5 Drafted Swedish NHL Defenders",
    "items": ["Victor Hedman", "Rasmus Dahlin", "Adam Larsson", "Oliver Ekman-Larsson"],
    "commonality": "Swedish defensemen who were drafted within the top 5 picks in their respective NHL Draft years",
    "color": "orange",
    "images": []
  },
  {
    "category": "🔄 NHL Players Whose First and Last Names Start with the Same Letter",
    "header": "NHL Players with Matching Initials",
    "items": ["Drew Doughty", "Zarley Zalapski", "Matias Maccelli", "Emerson Etem"],
    "commonality": "NHL players whose first and last names begin with the same letter",
    "color": "red",
    "images": []
  }
]

const categories18 = [
  {
    "category": "🏒 Wore a Letter with the Arizona Coyotes",
    "header": "Players Who Wore a Captain or Alternate's Letter for the Coyotes",
    "items": ["Jeremy Roenick", "Shane Doan", "Rick Tocchet", "Clayton Keller"],
    "commonality": "NHL players who have worn a captain's 'C' or an alternate's 'A' for the Arizona Coyotes",
    "color": "purple",
    "images": []
  },
  {
    "category": "🏆 Won the Stanley Cup as a Captain",
    "header": "Players Who Won the Stanley Cup as Team Captains",
    "items": ["Mark Stone", "Steven Stamkos", "Sidney Crosby", "Alex Ovechkin"],
    "commonality": "NHL captains who led their teams to a Stanley Cup victory",
    "color": "orange",
    "images": []
  },
  {
    "category": "🎖️ New York Rangers Top Ten Draft Picks",
    "header": "Top Ten Draft Picks by the New York Rangers",
    "items": ["Lias Andersson", "Kaapo Kakko", "Alexis Lafrenière", "Vitali Kravtsov"],
    "commonality": "Players who were selected as top ten draft picks by the New York Rangers",
    "color": "blue",
    "images": []
  },
  {
    "category": "⚡ 300+ PIMs in a Single NHL Season",
    "header": "Players with 300+ Penalty Minutes in a Single Season",
    "items": ["Nick Kypreos", "Tiger Williams", "Donald Brashear", "Matthew Barnaby"],
    "commonality": "NHL enforcers known for accumulating over 300 penalty minutes in a single season",
    "color": "red",
    "images": []
  }
]

const categories17 = [
  {
    "category": "🏒 Lost and Won Stanley Cup Finals",
    "header": "NHL Players Who've Both Lost and Won Stanley Cup Finals",
    "items": ["Sidney Crosby", "Evgeni Malkin", "Marian Hossa", "Tyler Toffoli"],
    "commonality": "NHL players who have experienced both winning and losing in Stanley Cup Finals",
    "color": "orange",
    "images": []
  },
  {
    "category": "🗽 Played for Both Islanders and Rangers",
    "header": "NHL Players Who've Skated for Both NY Islanders and Rangers",
    "items": ["Steve Valiquette", "Ryan Strome", "Jaroslav Halak", "Doug Weight"],
    "commonality": "Players who have played for New York's rival teams, the Islanders and the Rangers",
    "color": "blue",
    "images": []
  },
  {
    "category": "⚔️ Wore a Letter with the Golden Knights",
    "header": "Golden Knights Players Who've Worn a Captain or Alternate's Letter",
    "items": ["Mark Stone", "Deryk Engelland", "Max Pacioretty", "Jack Eichel"],
    "commonality": "Vegas Golden Knights players who have served as captain or alternate captain",
    "color": "red",
    "images": []
  },
  {
    "category": "🥇 Norris Trophy Finalists (Non-Winners)",
    "header": "NHL Norris Trophy Finalists Who Did Not Win",
    "items": ["Shea Weber", "Ryan Suter", "Dion Phaneuf", "Mike Green"],
    "commonality": "Defensemen who have been finalists for the Norris Trophy but did not win the award",
    "color": "green",
    "images": []
  }
]

const categories16 = [
  {
    "category": "🎲 Vegas Golden Knights 1st Rounders",
    "header": "First Round Draft Picks by the Vegas Golden Knights",
    "items": ["Cody Glass", "Erik Brannstrom", "Nick Suzuki", "Peyton Krebs"],
    "commonality": "NHL players selected in the first round of the NHL Draft by the Vegas Golden Knights",
    "color": "gold",
    "images": []
  },
  {
    "category": "🔔 Philadelphia Flyers 1st Rounders",
    "header": "First Round Draft Picks by the Philadelphia Flyers",
    "items": ["Nolan Patrick", "Ivan Provorov", "Justin Williams", "Peter Forsberg"],
    "commonality": "NHL players who were Philadelphia Flyers' first-round selections in the NHL Draft",
    "color": "orange",
    "images": []
  },
  {
    "category": "🌟 Dallas Stars 1st Rounders",
    "header": "First Round Draft Picks by the Dallas Stars",
    "items": ["Julius Honka", "Jamie Oleksiak", "Jarome Iginla", "Todd Harvey"],
    "commonality": "NHL players picked in the first round of the NHL Draft by the Dallas Stars",
    "color": "green",
    "images": []
  },
  {
    "category": "🏛️ Ottawa Senators 1st Rounders",
    "header": "First Round Draft Picks by the Ottawa Senators",
    "items": ["Chris Phillips", "Alexei Yashin", "Jake Sanderson", "Radek Bonk"],
    "commonality": "Players who were the Ottawa Senators' first-round selections in the NHL Draft",
    "color": "red",
    "images": []
  }
]

const categories15 = [
  {
    "category": "🏒 One-Team Entire Career",
    "header": "NHL Players Who Spent Their Entire Career with One Team",
    "items": ["Stan Mikita", "Steve Yzerman", "Alex Delvecchio", "Carey Price"],
    "commonality": "Iconic NHL players who dedicated their entire professional career to a single franchise",
    "color": "red",
    "images": []
  },
  {
    "category": "🏒 2011 Vancouver Canucks Finalists",
    "header": "Players from the 2011 Canucks Stanley Cup Finals Team",
    "items": ["Roberto Luongo", "Raffi Torres", "Ryan Kesler", "Corey Schneider"],
    "commonality": "Members of the Vancouver Canucks' roster that made it to the Stanley Cup Finals in 2011",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏒 Boston Bruins Goalies",
    "header": "Notable Goalies for the Boston Bruins",
    "items": ["Tim Thomas", "Linus Ullmark", "Jaroslav Halak", "Andrew Raycroft"],
    "commonality": "Goalkeepers who have defended the net for the Boston Bruins",
    "color": "green",
    "images": []
  },
  {
    "category": "🏒 Los Angeles Kings Captains",
    "header": "Captains of the Los Angeles Kings",
    "items": ["Anze Kopitar", "Dustin Brown", "Rob Blake", "Mattias Norstrom"],
    "commonality": "Players who have worn the captain's 'C' for the Los Angeles Kings",
    "color": "purple",
    "images": []
  }
]

const categories14 = [
  {
    "category": "🏒 2+ Time Art Ross Trophy Winners",
    "header": "NHL Players with Multiple Art Ross Trophies",
    "items": ["Jaromir Jagr", "Connor McDavid", "Martin St. Louis", "Evgeni Malkin"],
    "commonality": "Legendary NHL players who have won the Art Ross Trophy, awarded to the leading scorer, more than once in their careers",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏒 Gordie Howe's Teammates",
    "header": "NHL Players Who Were Teammates with Gordie Howe",
    "items": ["Bobby Hull", "Mark Howe", "Alex Delvecchio", "Frank Mahovlich"],
    "commonality": "Esteemed NHL players who had the opportunity to play alongside the legendary Gordie Howe",
    "color": "red",
    "images": []
  },
  {
    "category": "🏒 Jaromir Jagr's Hall of Fame Teammates",
    "header": "Hall of Famers Who Played with Jaromir Jagr",
    "items": ["Mario Lemieux", "Mark Recchi", "Tom Barrasso", "Adam Oates"],
    "commonality": "Hall of Fame hockey players who shared the ice with the iconic Jaromir Jagr during their illustrious NHL careers",
    "color": "green",
    "images": []
  },
  {
    "category": "🏒 First Overall Picks Who Played with the Leafs",
    "header": "First Overall NHL Draft Picks Who Played for the Toronto Maple Leafs",
    "items": ["Joe Thornton", "Auston Matthews", "John Tavares", "Wendel Clark"],
    "commonality": "Number one overall NHL draft picks who, at some point in their careers, played for the Toronto Maple Leafs",
    "color": "purple",
    "images": []
  }
]

const categories13 = [
  {
    "category": "🏒 2018-19 USNTDP Players",
    "header": "2018-19 US National Team Development Program Players",
    "items": ["Jack Hughes", "Matthew Boldy", "John Beecher", "Matty Beniers"],
    "commonality": "Hockey players who were part of the US National Team Development Program for the 2018-19 season",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏆 Hobey Baker Award Winners",
    "header": "Hobey Baker Memorial Award Recipients",
    "items": ["Will Butcher", "Adam Fox", "Jimmy Vesey", "Cale Makar"],
    "commonality": "NHL players who have won the Hobey Baker Award, given annually to the top NCAA men's ice hockey player",
    "color": "red",
    "images": []
  },
  {
    "category": "🏅 One-Time Hart Trophy Winners",
    "header": "NHL Hart Memorial Trophy Winners",
    "items": ["Taylor Hall", "Sergei Fedorov", "Corey Perry", "Carey Price"],
    "commonality": "NHL players who have been honored with the Hart Memorial Trophy, awarded to the league's most valuable player, once in their careers",
    "color": "green",
    "images": []
  },
  {
    "category": "🌟 Exceptional Status Recipients",
    "header": "CHL Players Granted Exceptional Status",
    "items": ["Connor Bedard", "Aaron Ekblad", "John Tavares", "Connor McDavid"],
    "commonality": "NHL players who were granted Exceptional Status to play in the Canadian Hockey League at an age younger than typically permitted",
    "color": "purple",
    "images": []
  }
]

const categories12 =
[
  {
    "category": "🏒 Russian Calder Winners",
    "items": ["Kirill Kaprizov", "Evgeni Malkin", "Alex Ovechkin", "Artemi Panarin"],
    "commonality": "Russian NHL players who have won the Calder Memorial Trophy",
    "color": "red",
    "images": []
  },
  {
    "category": "🥅 Kraken Goaltenders",
    "items": ["Philipp Grubauer", "Joey Daccord", "Chris Driedger", "Martin Jones"],
    "commonality": "Goaltenders for the Seattle Kraken NHL team",
    "color": "teal",
    "images": []
  },
  {
    "category": "🏒 Captained 2+ Teams",
    "items": ["Mark Giordano", "Brian Gionta", "Mark Messier", "Derian Hatcher"],
    "commonality": "NHL players who have served as captains for two or more teams",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏆 1993 Habs Cup Winners",
    "items": ["Patrick Roy", "Denis Savard", "Mike Keane", "Brian Bellows"],
    "commonality": "Members of the Montreal Canadiens who won the Stanley Cup in 1993",
    "color": "navy",
    "images": []
  }
]

const categories11 =
[
  {
    "category": "🏒 2010 Olympic Gold Medal",
    "header": "2010 Olympic Gold Medal Hockey Players",
    "items": ["Sidney Crosby", "Roberto Luongo", "Rick Nash", "Ryan Getzlaf"],
    "commonality": "Hockey players who won the Olympic gold medal in 2010",
    "color": "gold",
    "images": [
      "Placeholder for Sidney Crosby",
      "Placeholder for Roberto Luongo",
      "Placeholder for Rick Nash",
      "Placeholder for Ryan Getzlaf"
    ]
  },
  {
    "category": "🏒 Played for the Sharks and Kings",
    "header": "NHL Players for Sharks and Kings",
    "items": ["Martin Jones", "Devin Setoguchi", "Rob Blake", "Marty McSorley"],
    "commonality": "Hockey players who played for both the San Jose Sharks and the Los Angeles Kings",
    "color": "blue",
    "images": [
      "Placeholder for Martin Jones",
      "Placeholder for Devin Setoguchi",
      "Placeholder for Rob Blake",
      "Placeholder for Marty McSorley"
    ]
  },
  {
    "category": "🏒 Russians Drafted Top 10",
    "header": "Top 10 Drafted Russian Hockey Players",
    "items": ["Viktor Kozlov", "Evgeni Malkin", "Andrei Svechnikov", "Alexander Ovechkin"],
    "commonality": "Russian hockey players who were drafted in the top 10 of the NHL Draft",
    "color": "red",
    "images": [
      "Placeholder for Viktor Kozlov",
      "Placeholder for Evgeni Malkin",
      "Placeholder for Andrei Svechnikov",
      "Placeholder for Alexander Ovechkin"
    ]
  },
  {
    "category": "🏒 Oilers 1st Overall Draft Picks",
    "header": "Edmonton Oilers 1st Overall Draft Picks",
    "items": ["Taylor Hall", "Ryan Nugent-Hopkins", "Connor McDavid", "Nail Yakupov"],
    "commonality": "Hockey players selected as the first overall draft pick by the Edmonton Oilers",
    "color": "orange",
    "images": [
      "Placeholder for Taylor Hall",
      "Placeholder for Ryan Nugent-Hopkins",
      "Placeholder for Connor McDavid",
      "Placeholder for Nail Yakupov"
    ]
  }
]

const categories10 =
[
  {
    "category": "Played for 1 Team Entire Career",
    "header": "One-Team Players",
    "items": ["Sidney Crosby", "Patrik Elias", "Andrei Markov", "Ken Daneyko"],
    "commonality": "Players who have played for only one team throughout their NHL careers",
    "color": "grey",
    "images": [
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8471675.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8465009.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8468493.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8448208.jpg"
    ]
  },
  {
    "category": "Won a cup with the Lightning",
    "header": "Stanley Cup Winners with the Lightning",
    "items": ["Vincent Lecavalier", "Steven Stamkos", "Andrei Vasilevskiy", "Nikolai Khabibulin"],
    "commonality": "Players who have won the Stanley Cup with the Tampa Bay Lightning",
    "color": "blue",
    "images": [
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8467329.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8474564.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8476883.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8458539.jpg"
    ]
  },
  {
    "category": "Played for the Leafs and Habs",
    "header": "Players for Leafs and Canadiens",
    "items": ["Tomas Plekanec", "Colby Armstrong", "Victor Mete", "Darcy Tucker"],
    "commonality": "Players who have played for both the Toronto Maple Leafs and Montreal Canadiens",
    "color": "blue",
    "images": [
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8470606.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8468498.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8480080.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8465007.jpg"
    ]
  },
  {
    "category": "Played For University of Michigan",
    "header": "University of Michigan Alumni",
    "items": ["Luke Hughes", "Marty Turco", "Kyle Connor", "Zach Werenski"],
    "commonality": "NHL players who played for the University of Michigan's hockey team",
    "color": "yellow",
    "images": [
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8481559.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8466139.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8479337.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8478460.jpg"
    ]
  }
]

const categories9 =
[
  {
    "category": "Played for 1 Team Entire Career",
    "header": "One-Team Players",
    "items": ["Sidney Crosby", "Patrik Elias", "Andrei Markov", "Ken Daneyko"],
    "commonality": "Players who have played for only one team throughout their NHL careers (or at least so far for active players)",
    "color": "grey",
    "images": [
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8471675.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8465009.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8468493.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8448208.jpg"
    ]
  },
  {
    "category": "Won a cup with the Lightning",
    "header": "Stanley Cup Winners with the Lightning",
    "items": ["Vincent Lecavalier", "Steven Stamkos", "Andrei Vasilevskiy", "Nikolai Khabibulin"],
    "commonality": "Players who have won the Stanley Cup with the Tampa Bay Lightning",
    "color": "blue",
    "images": [
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8467329.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8474564.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8476883.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8458539.jpg"
    ]
  },
  {
    "category": "Played for the Leafs and Habs",
    "header": "Players for Leafs and Canadiens",
    "items": ["Tomas Plekanec", "Colby Armstrong", "Victor Mete", "Darcy Tucker"],
    "commonality": "Players who have played for both the Toronto Maple Leafs and Montreal Canadiens",
    "color": "blue",
    "images": [
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8470606.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8468498.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8480080.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8465007.jpg"
    ]
  },
  {
    "category": "Played For University of Michigan",
    "header": "University of Michigan Alumni",
    "items": ["Luke Hughes", "Marty Turco", "Kyle Connor", "Zach Werenski"],
    "commonality": "NHL players who played for the University of Michigan's hockey team",
    "color": "yellow",
    "images": [
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8481559.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8466139.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8479337.jpg",
      "https://nhl.bamcontent.com/images/headshots/current/168x168/8478460.jpg"
    ]
  }
]

const categories8 =

  [
    {
      "category": "Wore #88",
      "items": ["Patrick Kane", "Andrei Vasilevskiy", "Eric Lindros", "Brent Burns"],
      "commonality": "Hockey players who have worn the number 88 during their NHL careers",
      "color": "black",
      "images": ["placeholder_for_patrick_kane_image_url", "placeholder_for_andrei_vasilevskiy_image_url", "placeholder_for_eric_lindros_image_url", "placeholder_for_brent_burns_image_url"]
    },
    {
      "category": "Wore #44",
      "items": ["Morgan Rielly", "Marc-Edouard Vlasic", "Zack Kassian", "Chris Pronger"],
      "commonality": "Hockey players who have worn the number 44 during their NHL careers",
      "color": "white",
      "images": ["placeholder_for_morgan_rielly_image_url", "placeholder_for_marc_edouard_vlasic_image_url", "placeholder_for_zack_kassian_image_url", "placeholder_for_chris_pronger_image_url"]
    },
    {
      "category": "Wore #77",
      "items": ["Pierre Turgeon", "Phil Esposito", "Jeff Carter", "Paul Coffey"],
      "commonality": "Hockey players who have worn the number 77 during their NHL careers",
      "color": "grey",
      "images": ["placeholder_for_pierre_turgeon_image_url", "placeholder_for_phil_esposito_image_url", "placeholder_for_jeff_carter_image_url", "placeholder_for_paul_coffey_image_url"]
    },
    {
      "category": "Wore #55",
      "items": ["Quinton Byfield", "Mark Giordano", "Keith Primeau", "Shane O'Brien"],
      "commonality": "Hockey players who have worn the number 55 during their NHL careers",
      "color": "dark grey",
      "images": ["placeholder_for_quinton_byfield_image_url", "placeholder_for_mark_giordano_image_url", "placeholder_for_keith_primeau_image_url", "placeholder_for_shane_obrien_image_url"]
    }
  ]
  

// Puzzle number constant
const categories7 =[
  
    {
      "category": "Scored 100+ Points with the Penguins",
      "items": ["Mario Lemieux", "Evgeni Malkin", "Jaromir Jagr", "Sidney Crosby"],
      "commonality": "Hockey players who have scored 100 or more points in a season with the Pittsburgh Penguins",
      "color": "red",
      "images": ["https://www.hockeydb.com/ihdb/photos/mario-lemieux-1996-50.jpg","https://www.hockeydb.com/ihdb/photos/evgeni-malkin-2024-50.jpg","https://www.hockeydb.com/ihdb/photos/jaromir-jagr-2017-234.jpg","https://www.hockeydb.com/ihdb/photos/sidney-crosby-2024-50.jpg"]
    },
    {
      "category": "Defenseman with 100+ Point Seasons",
      "items": ["Erik Karlsson", "Al MacInnis", "Bobby Orr", "Brian Leetch"],
      "commonality": "Defensemen who have recorded 100 or more points in a single NHL season",
      "color": "green",
      "images": ["https://www.hockeydb.com/ihdb/photos/erik-karlsson-2024-50.jpg","https://www.hockeydb.com/ihdb/photos/al-macinnis-1996-36.jpg","https://www.hockeydb.com/ihdb/photos/bobby-orr-1977-35.jpg","https://www.hockeydb.com/ihdb/photos/brian-leetch-1996-48.jpg"]
    },
    {
      "category": "Undrafted Goalies",
      "items": ["Charlie Lindgren", "Alexander Georgiev", "Glenn Healy", "Cam Talbot"],
      "commonality": "Goalies who were not selected in the NHL Draft but went on to play in the NHL",
      "color": "blue",
      "images": ["https://www.hockeydb.com/ihdb/photos/charlie-lindgren-2023-49.jpg","https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/4239677.png&w=350&h=254","https://www.hockeydb.com/ihdb/photos/glenn-healy-1997-48.jpg","https://www.hockeydb.com/ihdb/photos/cameron-talbot-2024-40.jpg"]
    },
    {
      "category": "European First Overall Picks",
      "items": ["Rasmus Dahlin", "Roman Hamrlik", "Ilya Kovalchuk", "Juraj Slafkovsky"],
      "commonality": "European hockey players who were selected first overall in the NHL Draft",
      "color": "purple",
      "images": ["https://www.hockeydb.com/ihdb/photos/rasmus-dahlin-2024-33.jpg","https://www.hockeydb.com/ihdb/photos/roman-hamrlik-2013-48.jpg","https://www.hockeydb.com/ihdb/photos/ilya-kovalchuk-2020-40.jpg","https://www.hockeydb.com/ihdb/photos/juraj-slafkovsky-2024-45.jpg"]
    }
  ]
  

const categories6 =[
  {
    "category": "🏒 Played for the Thrashers",
    "items": ["Dustin Byfuglien", "Andrew Brunette", "Ken Klee", "Bryan Little"],
    "commonality": "All played for the Atlanta Thrashers NHL team",
    "color": "blue",
    "images": [
      "https://www.hockeydb.com/ihdb/photos/dustin-byfuglien-2020-6378.jpg",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/118.png",
      "https://www.hockeydb.com/ihdb/photos/ken-klee-2001-49.jpg",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/3508.png&w=350&h=254"
    ]
  },
  {
    "category": "🏒 Played for the Nordiques",
    "items": ["Joe Sakic", "Peter Forsberg", "Ron Tugnutt", "Guy Lafleur"],
    "commonality": "All played for the Quebec Nordiques NHL team",
    "color": "red",
    "images": [
      "https://www.hockeydb.com/ihdb/photos/joe-sakic-1996-690.jpg",
      "https://www.hockeydb.com/ihdb/photos/peter-forsberg-1996-690.jpg",
      "https://www.hockeydb.com/ihdb/photos/ron-tugnutt-2002-2330.jpg",
      "https://www.hockeydb.com/ihdb/photos/guy-lafleur-1977-45.jpg"
    ]
  },
  {
    "category": "🏒 Played for the Whalers",
    "items": ["Ray Ferraro", "Mark Howe", "Gordie Howe", "Sean Burke"],
    "commonality": "All played for the Hartford Whalers NHL team",
    "color": "orange",
    "images": [
      "https://www.hockeydb.com/ihdb/photos/ray-ferraro-1996-48.jpg",
      "https://www.hockeydb.com/ihdb/photos/mark-howe-1979-169.jpg",
      "https://www.hockeydb.com/ihdb/photos/gordie-howe-1970-34.jpg",
      "https://www.hockeydb.com/ihdb/photos/sean-burke-1996-46.jpg"
    ]
  },
  {
    "category": "🏒 Played for the North Stars",
    "items": ["Lou Nanne", "Brian Lawton", "Mike Modano", "Ulf Dahlen"],
    "commonality": "All played for the Minnesota North Stars NHL team",
    "color": "yellow",
    "images": [
      "https://www.hockeydb.com/ihdb/photos/lou-nanne-1974-37.jpg",
      "https://www.hockeydb.com/ihdb/photos/brian-lawton-1993-44.jpg",
      "https://www.hockeydb.com/ihdb/photos/mike-modano-1996-233.jpg",
      "https://www.hockeydb.com/ihdb/photos/ulf-dahlen-2001-49.jpg"
    ]
  }
]


const categories5 =[
{
  "category": "🏒 Played in the NHL and became a GM",
  "items": ["Bill Guerin", "Marc Bergevin", "Steve Yzerman", "Jim Rutherford"],
  "commonality": "All have played in the NHL and later became a General Manager",
  "color": "purple",
  "images": [
    "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/329.png",
    "https://www.hockeydb.com/ihdb/photos/marc-bergevin-1996-34.jpg",
    "https://michigansportshof.org/mshof/wp-content/uploads/2019/01/Yzerman-head.jpg",
    "https://www.hhof.com/graphinduct/Rutherford01.jpg"
  ]
},
{
  "category": "🏒 Recorded 100+ Points in 2021-2022",
  "items": ["Auston Matthews", "Johnny Gaudreau", "Matthew Tkachuk", "Jonathan Huberdeau"],
  "commonality": "All recorded 100 or more points in the 2021-2022 NHL season",
  "color": "orange",
  "images": [
    "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/4024123.png",
    "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/2563039.png&w=350&h=254",
    "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/4024854.png&w=350&h=254",
    "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/2562606.png&w=350&h=254"
  ]
},
{
  "category": "🏒 Russians to reach 100 Points",
  "items": ["Nikita Kucherov", "Alexander Mogilny", "Alexander Ovechkin", "Sergei Fedorov"],
  "commonality": "All are Russian players who have reached 100 points in an NHL season",
  "color": "grey",
  "images": [
    "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/2563060.png",
    "https://www.hockeydb.com/ihdb/photos/alexander-mogilny-1996-39.jpg",
    "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/3101.png&w=350&h=254",
    "https://www.hockeydb.com/ihdb/photos/sergei-fedorov-2008-2330.jpg"
  ]
},
{
  "category": "🏒 Defenders with 20+ Goals in a season",
  "items": ["Morgan Rielly", "Kevin Hatcher", "Sandis Ozoliņš", "Doug Wilson"],
  "commonality": "All are defenders who have scored 20 or more goals in a single NHL season",
  "color": "black",
  "images": [
    "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/2976833.png",
    "https://www.hockeydb.com/ihdb/photos/kevin-hatcher-1996-233.jpg",
    "https://www.hockeydb.com/ihdb/photos/sandis-ozolinsh-1996-44.jpg",
    "https://upload.wikimedia.org/wikipedia/commons/4/4e/Doug_Wilson_1977.JPG"
  ]
}
]

const categories4 = [

  {
    "category": "🏒 Habs Captains",
    "items": ["Max Pacioretty", "Vincent Damphousse", "Henri Richard", "Bob Gainey"],
    "commonality": "All have served as captains for the Montreal Canadiens in the NHL",
    "color": "green",
    "images": []
  },
  {
    "category": "🏒 Selke Winners",
    "items": ["Guy Carbonneau", "Sean Couturier", "Ryan Kesler", "Patrice Bergeron"],
    "commonality": "All have won the Frank J. Selke Trophy in the NHL",
    "color": "red",
    "images": []
  },
  {
    "category": "🏒 Drafted in 2000",
    "items": ["Scott Hartnell", "Marcel Hossa", "Niklas Kronwall", "Ilya Bryzgalov"],
    "commonality": "All were drafted in the year 2000 in the NHL",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏒 Wore #11",
    "items": ["Daniel Alfredsson", "Anson Carter", "Adam Graves", "Anze Kopitar"],
    "commonality": "All have worn the number 11 jersey in the NHL",
    "color": "yellow",
    "images": []
  }
]

const categories3 = [

    {
        "category": "🏒 Played for the Kraken",
        "items": ["Derrick Pouliot", "Vince Dunn", "Yanni Gourde", "Adam Larsson"],
        "commonality": "All have played for the Seattle Kraken in the NHL",
        "color": "green",
        "images": []
      },
      {
        "category": "🥅 Defenders drafted first overall",
        "items": ["Ed Jovanovski", "Aaron Ekblad", "Erik Johnson", "Rasmus Dahlin"],
        "commonality": "All are defenders who were drafted first overall in the NHL",
        "color": "red",
        "images": []
      },
      {
        "category": "🏅 Mr. Irrelevant",
        "items": ["Patric Hornqvist", "Andy Brickley", "Kim Johnsson", "Jacob Middleton"],
        "commonality": "All were the last picks in their respective NHL Drafts, earning them the title of 'Mr. Irrelevant'",
      "color": "blue",
        "images": []
      },
      {
        "category": "🏆 Cup Winning Goal",
        "items": ["Artturi Lehkonen", "Alec Martinez", "Uwe Krupp", "Mike Bossy"],
        "commonality": "All scored a Stanley Cup-winning goal in the NHL",
        "color": "yellow",
        "images": []
    }
];
  const categories2 = [
    // ... (other categories)
    {
      "category": "🏒 Swedish Players for the Canucks",
      "items": ["Daniel Sedin", "Henrik Sedin", "Elias Pettersson", "Markus Naslund"],
      "commonality": "All are Swedish players who played for the Vancouver Canucks in the NHL",
      "color": "green",
      "images": [
"https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/836.png",  "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/837.png", "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/4233566.png&w=350&h=254" ,"https://m.media-amazon.com/images/M/MV5BMmFlNTJiMTctZDdiMi00NmJlLWJkNTktM2ZlZmQ5ODU5MTc0XkEyXkFqcGdeQXVyMjUyNDk2ODc@._V1_.jpg"  ]
    },
    {
      "category": "🥅 Czech Stanley Cup Winners",
      "items": ["Jaromir Jagr", "Dominik Hasek", "Ondrej Palat", "Tomas Kaberle"],
      "commonality": "All are Czech players who have won the Stanley Cup in the NHL",
      "color": "red",
      "images": [
"https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/405.png",  "https://www.hockeydb.com/ihdb/photos/dominik-hasek-2008-34.jpg" , "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/2590389.png" ,"https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/427.png" ]
    },
    {
      "category": "🇫🇷 French Players",
      "items": ["Pierre-Edouard Bellemare", "Cristobal Huet", "Alexandre Texier", "Antoine Roussel"],
      "commonality": "All are French players who have played in the NHL",
      "color": "blue",
      "images": [
"https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/3111908.png",  "https://static.wikia.nocookie.net/thenhl/images/7/71/CHuet.jpg/revision/latest?cb=20140104221712",  "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/4233880.png&w=350&h=254" , "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/2524606.png"]
    },
    {
      "category": "🏆 Norris Winners",
      "items": ["Larry Robinson", "Rob Langway", "P.K. Subban", "Victor Hedman"],
      "commonality": "All have won the James Norris Memorial Trophy as the NHL's top defenseman",
      "color": "yellow",
      "images": [
"https://www.hockeydb.com/ihdb/photos/larry-robinson-1977-45.jpg"   ,  "https://www.hockeydb.com/ihdb/photos/rod-langway-1996-688.jpg" ,"https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/4044.png&w=350&h=254","https://a.espncdn.com/i/headshots/nhl/players/full/5266.png"]
    }
  ];
  
// Categories array with updates for logic and consistency
  const categories1 = [
    {
    "category": "🏒 Finnish Goalies",
    "items": ["Pekka Rinne", "Miikka Kiprusoff", "Tuukka Rask", "Antti Niemi"],
    "commonality": "All are Finnish goaltenders in ice hockey",
    "color": "blue",
      "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/3157.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/454.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/3405.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/3937.png"
      ]
    },
    {
    "category": "🥇 1st Overall NHL Draft Picks",
    "items": ["Alexandre Daigle", "Sidney Crosby", "Rick Nash", "John Tavares"],
    "commonality": "All were selected first overall in their respective NHL Entry Draft years",
    "color": "red",
      "images": [
      "https://www.hockeydb.com/ihdb/photos/alexandre-daigle-1996-54.jpg",
      "https://a.espncdn.com/i/headshots/nhl/players/full/3114.png",
      "https://a.espncdn.com/i/headshots/nhl/players/full/1569.png",
      "https://a.espncdn.com/i/headshots/nhl/players/full/5160.png"
      ]
    },
    {
    "category": "👨‍👦 Players Whose Fathers Played In the NHL",
    "items": ["Kerby Rychel", "Matthew Tkachuk", "Nolan Foote", "William Nylander"],
    "commonality": "Each player has a father who also played in the NHL",
    "color": "orange",
      "images": [
      "https://kosportsinc.com/wp-content/uploads/2017/02/Kerby-Rychel-Headshot.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/4024854.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/4565248.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/3114736.png"
      ]
    },
    {
    "category": "🏅 Olympic Gold Medal",
    "items": ["Artyom Zub", "Shea Weber", "Owen Nolan", "Darius Kasparaitis"],
    "commonality": "Each player has won an Olympic gold medal in ice hockey",
    "color": "yellow",
      "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/4712021.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nhl/players/full/2278.png",
      "https://www.giantbomb.com/a/uploads/scale_small/46/462814/3180346-9633990062-owen-.jpg",
      "https://www.hockeydb.com/ihdb/photos/darius-kasparaitis-2008-48.jpg"
    ]
}
]
// Simulate loading categories for different puzzles (You should replace this with actual logic to load different puzzles based on selection)
const loadCategoriesForPuzzle = (puzzleNumber) => {
  const puzzles = {
    '1': categories1, // Categories set for puzzle 4
    '2': categories2, // Categories set for puzzle 4
    '3': categories3, // Categories set for puzzle 4
    '4': categories4, // Categories set for puzzle 4
    '5': categories5, // Categories set for puzzle 5
    '6': categories6, // Categories set for puzzle 6
    '7': categories7, // Categories set for puzzle 6
    '8': categories8, // Categories set for puzzle 6
    '9': categories9, // Categories set for puzzle 6
    '10': categories10, // Categories set for puzzle 6
    '11': categories11, // Categories set for puzzle 6
    '12': categories12, // Categories set for puzzle 6
    '13': categories13, // Categories set for puzzle 6
    '14': categories14, // Categories set for puzzle 6
    '15': categories15, // Categories set for puzzle 6
    '16': categories16, // Categories set for puzzle 6
    '17': categories17, // Categories set for puzzle 6
    '18': categories18, // Categories set for puzzle 6
    '19': categories19, // Categories set for puzzle 6
    '20': categories20, // Categories set for puzzle 6
    '21': categories21, // Categories set for puzzle 6
    '22': categories22, // Categories set for puzzle 6
    '23': categories23, // Categories set for puzzle 6
    '24': categories24, // Categories set for puzzle 6
    '25': categories25, // Categories set for puzzle 6
    '26': categories26, // Categories set for puzzle 6
    '27': categories27, // Categories set for puzzle 6
    '28': categories28, // Categories set for puzzle 6
    '29': categories29, // Categories set for puzzle 6
    '30': categories30, // Categories set for puzzle 6

    '31': categories31, // Categories set for puzzle 6
    '32': categories32, // Categories set for puzzle 6
    '33': categories33, // Categories set for puzzle 6

  };

  return puzzles[puzzleNumber] || [];
};

// Function to get puzzle numbers dynamically from the puzzles object
const getPuzzleNumbers = () => {
  const puzzles = {
    '1': categories1, // Categories set for puzzle 4
    '2': categories2, // Categories set for puzzle 4
    '3': categories3, // Categories set for puzzle 4
    '4': categories4, // Categories set for puzzle 4
    '5': categories5, // Categories set for puzzle 5
    '6': categories6, // Categories set for puzzle 6
    '7': categories7, // Categories set for puzzle 6
    '8': categories8, // Categories set for puzzle 6
    '9': categories9, // Categories set for puzzle 6
    '10': categories10, // Categories set for puzzle 6
    '11': categories11, // Categories set for puzzle 6
    '12': categories12, // Categories set for puzzle 6
    '13': categories13, // Categories set for puzzle 6
    '14': categories14, // Categories set for puzzle 6
    '15': categories15, // Categories set for puzzle 6
    '16': categories16, // Categories set for puzzle 6
    '17': categories17, // Categories set for puzzle 6
    '18': categories18, // Categories set for puzzle 6
    '19': categories19, // Categories set for puzzle 6
    '20': categories20, // Categories set for puzzle 6
    '21': categories21, // Categories set for puzzle 6
    '22': categories22, // Categories set for puzzle 6
    '23': categories23, // Categories set for puzzle 6
    '24': categories24, // Categories set for puzzle 6
    '25': categories25, // Categories set for puzzle 6
    '26': categories26, // Categories set for puzzle 6
    '27': categories27, // Categories set for puzzle 6
    '28': categories28, // Categories set for puzzle 6
    '29': categories29, // Categories set for puzzle 6
    '30': categories30, // Categories set for puzzle 6
    '31': categories31, // Categories set for puzzle 6
    '32': categories32, // Categories set for puzzle 6
    '33': categories33, // Categories set for puzzle 6

  };

  return Object.keys(puzzles);
};




// Function to shuffle array items
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const ConnectionsGame = () => {
  
  const [puzzleNumber, setPuzzleNumber] = useState("33"); // Default puzzle number
  const [selectedItems, setSelectedItems] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [foundCategories, setFoundCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [gameEndedSuccessfully, setGameEndedSuccessfully] = useState(false);
  const [categories, setCategories] = useState(loadCategoriesForPuzzle(puzzleNumber)); // Load categories based on puzzle number
  const maxAttempts = 5;
  const [hintsUsed, setHintsUsed] = useState(categories.map(() => ({ bigHint: false, smallHint: false })));
  const [howToPlayOpen, setHowToPlayOpen] = useState(false);
  const [gameHasEnded, setGameHasEnded] = useState(false);
  const [theme, setTheme] = useState('light'); // default theme

  const useStyles = makeMuiStyles((theme) => ({
    link: {
      color: theme.palette.text.link,
      fontSize: isDesktop ? "16px" : "12px",
      fontWeight: "600",
    },
  
    sponsorshipLink: {
      marginTop: "20px",
      color: theme.palette.text.link,
      textDecoration: "none",
      borderBottom: `1px solid ${theme.palette.text.link}`,
    },
    sponsorshipLinkUnderline: {
      color: theme.palette.text.link,
      textDecoration: "none",
    },
    pmLink: {
      visibility: "hidden",
      textDecoration: "none",
      cursor: "pointer",
      background: "transparent",
      border: "none",
    },
  
    pmLinkHover: {
      visibility: "visible",
      color: "grey",
    },
  }));
  const classes = useStyles();
  // Add useEffect hook to update categories when puzzleNumber changes
  useEffect(() => {
    // Load new categories based on the selected puzzle number
    const newCategories = loadCategoriesForPuzzle(puzzleNumber);
    setCategories(newCategories);
  
    // Reset hints for the new categories
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
  
    // Shuffle items in the new categories
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    setAllItems(shuffledItems);
  
    // Reset game state to initial values
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setGuesses([]);
    setGameEndedSuccessfully(false);
    setGameHasEnded(false); // This ensures the game is no longer in the "ended" state
  
    // Optionally, if you're storing the game state in localStorage, you might want to update/clear it here
  
  }, [puzzleNumber]);
  
  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  
  }, []);

  // Omitting existing useEffects and functions for brevity...

  // Initial setup of game state based on selected puzzle
  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${puzzleNumber}`));
    if (storedState) {
      // Logic to load game state from local storage
    } else {
      const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
      setAllItems(shuffledItems);
      resetGameState(false); // Reset without clearing local storage
    }
  }, [categories]);

  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };
  useEffect(() => {
    setCategories(loadCategoriesForPuzzle(puzzleNumber));
    // Include other logic for when puzzleNumber changes, like resetting the game state
  }, [puzzleNumber]);

  // Dynamically generate puzzle number options for the dropdown
  const puzzleNumberOptions = getPuzzleNumbers();
  useEffect(() => {
    // Load the categories corresponding to the selected puzzle number
    const newCategories = loadCategoriesForPuzzle(puzzleNumber);
    
    // Set the loaded categories to the state
    setCategories(newCategories);
    
    // Reset the hints used for each category in the new puzzle
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
    
    // Shuffle all items from the new categories to randomize their order
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    
    // Set the shuffled items to the state, effectively resetting the game board
    setAllItems(shuffledItems);
    
    // Reset the selected items as we're starting a new puzzle
    setSelectedItems([]);
    
    // Reset the found categories as none have been found in the new puzzle
    setFoundCategories([]);
    
    // If there are additional states that depend on the puzzle number or categories,
    // reset them here to ensure the game correctly initializes for the new puzzle.
    // Example: Resetting guesses, attempts, or any other game-related states.
    
  }, [puzzleNumber]);
  

  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  }, []);
    const updateFoundCategories = () => {
      let remainingItems = [...allItems];
    
      categories.forEach((category, index) => {
        const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
        const isCategoryCompletelySelected = selectedInCategory.length === category.items.length;
    
        if (isCategoryCompletelySelected && !foundCategories.includes(category)) {
          setFoundCategories(prevFoundCategories => [...prevFoundCategories, category]);
          setHintsUsed(hints => {
            const newHints = [...hints];
            newHints[index].found = true;
            return newHints;
          });
          remainingItems = remainingItems.filter(item => !category.items.includes(item));
        }
      });
    
      setAllItems(remainingItems);
    };
    
 
  // Add this useEffect hook after your existing useEffect hooks
  useEffect(() => {
    // Check if all categories have been found
    if (foundCategories.length === categories.length) {
      handleEndGame(true); // Game ends successfully
    } else if (attempts >= maxAttempts) {
      // Check if max attempts have been reached without finding all categories
      handleEndGame(false); // Game ends unsuccessfully
    }
  }, [foundCategories, attempts]); // Depend on foundCategories and attempts

  // Initial setup of game state
  useEffect(() => {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState();
  }, []);
  // Handle toggling between displaying item names and images
  const toggleDisplay = () => {
    setShowImages(prevState => !prevState);
  };

  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${puzzleNumber}`));
    if (storedState) {
      setGuesses(storedState.guesses || []);
      setSelectedItems(storedState.selectedItems || []);
      setFoundCategories(storedState.foundCategories || []);
      setAttempts(storedState.attempts || 0);
      // Do not set modalOpen from stored state; always start with it closed
      // Additional state can be loaded here as needed
    }
  }, []);
  // Reset game state for new games or retries
  const resetGameState = (clearLocalStorage = true) => {
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setModalOpen(false);
    setGuesses([]);
    if (clearLocalStorage) {
      localStorage.removeItem(`gameState-${puzzleNumber}`);
      localStorage.removeItem(`guesses-${puzzleNumber}`); // Clear guess history from local storage
    }
};

// Save guesses to local storage
const saveGuessToLocalStorage = (guess) => {
  const key = `guesses-${puzzleNumber}`;
  const guesses = JSON.parse(localStorage.getItem(key)) || [];
  guesses.push(guess);
  localStorage.setItem(key, JSON.stringify(guesses));
};

// Emoji map for category colors
const colorEmojiMap = {
  'blue': '🟦',
  'red': '🟥',
  'orange': '🟧',
  'yellow': '🟨',
  'purple': '🟪',
  'green': '🟩',

};

const HowToPlayModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <h2>How to Play NHL Connections</h2>
        <button className="modal-close" onClick={onClose}>×</button>
        <p>Find groups of four hockey-related items that share something in common.</p>
        <p>Select four items and tap 'Submit' to check if your guess is correct.</p>
        <p>Uncover all connections without making more than 4 mistakes to win!</p>
        <h3>Category Examples</h3>
        <p>🏆 Stanley Cup Champions: Jonathan Toews, Sidney Crosby, Alex Ovechkin, Anze Kopitar</p>
        <p>🌟 NHL All-Stars: Connor McDavid, Nathan MacKinnon, Auston Matthews, David Pastrnak</p>
        <p>Note: Categories will delve into specifics like awards, drafts, and memorable moments in hockey.</p>
        <p>Each puzzle has exactly one solution. Be mindful of items that may fit into more than one category!</p>
        <p>Each group is assigned a color, revealed as you solve:</p>
  
        <p>Ready to put your hockey knowledge to the test? Enjoy NHL Connections!</p>
      </div>
    </div>
  );
  
};const StatsModal = ({ isOpen, onClose, guesses, congratulations = false, puzzleNumber }) => {
  if (!isOpen) return null;

  const safeGuesses = Array.isArray(guesses) ? guesses : [];
  const baseURL = "https://crossovergrid.com"; // Adjust as needed

  const copyToClipboard = async () => {
    const textToCopy = `Crossover Connections 🏒 Puzzle #${puzzleNumber} ${baseURL}/\n` +
      safeGuesses.map(guess =>
        guess.map(color => colorEmojiMap[color] || '⬜').join('')
      ).join('\n');
    await navigator.clipboard.writeText(textToCopy);
    // Assuming you have a toast notification system
    toast.success('Guesses copied to clipboard!');
  };

  const shareOnTwitter = () => {
    const puzzleURL = baseURL; // Adjust based on your actual URL structure
    let shareText = `Crossover Connections 🏒 Puzzle #${puzzleNumber} ${puzzleURL}\nGuesses:\n`;
  
    // Append guesses with emojis
    for (const guess of safeGuesses) {
      const guessLine = guess.map(color => colorEmojiMap[color] || '⬜').join('');
      shareText += `${guessLine}\n`;
    }
  
    // Manually replace critical characters to ensure the URL is valid
    shareText = shareText.replace(/\n/g, '%0A').replace(/#/g, '%23');
  
    const tweetURL = `https://twitter.com/intent/tweet?text=${shareText}`;
    window.open(tweetURL, '_blank');
  };
  

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <h2>Crossover Connections 🏒 Puzzle #{puzzleNumber}</h2>
        <button className="modal-close" onClick={onClose}>×</button>
        <div>
          <p>{congratulations ? "Congratulations! You've solved the puzzle!" : "Here were your guesses:"}</p>
          <button onClick={copyToClipboard}>Copy Guesses</button>
        <button onClick={shareOnTwitter} style={{ backgroundColor: '#1DA1F2', color: '#fff', marginLeft: '10px' }}>Tweet</button>
          {safeGuesses.map((guess, index) => (
            <div key={index} className="guess-emoji-row">
              {guess.map(color => colorEmojiMap[color] || '⬜').join('')}
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};





// Load guesses from local storage on component mount
useEffect(() => {
  const storedGuesses = JSON.parse(localStorage.getItem(`guesses-${puzzleNumber}`)) || [];
  setGuesses(storedGuesses);
}, []);

// Handle item clicks to select or deselect items
const handleItemClick = (item) => {
  setSelectedItems(prevItems =>
    prevItems.includes(item) ? prevItems.filter(i => i !== item) : [...prevItems, item]
  );
};

const handleHintClick = (categoryIndex, hintSize) => {
  const category = categories[categoryIndex];
  if (hintSize === 'big') {
    toast.info(`The category is: ${category.category}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].bigHint = true;
      return newHints;
    });
  } else if (hintSize === 'small') {
    const miniHintItems = [category.items[0], category.items[1]]; // Take the first two items for the mini hint
    toast.info(`Two items in this category are: ${miniHintItems.join(' and ')}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].smallHint = true;
      return newHints;
    });
  }
};const handleSubmit = () => {
  // Dismiss all existing toasts before showing a new one
  toast.dismiss();

  // Prevent any submissions if the game has ended
  if (gameEndedSuccessfully || attempts >= maxAttempts) {
    toast.error("Game has ended. No more submissions allowed.");
    return;
  }

  // Check for the correct number of selected items
  if (selectedItems.length !== 4) {
    toast.error("Please select exactly 4 items.");
    return;
  }

  // Extract the colors of the selected items
  const selectedColors = selectedItems.map(item =>
    categories.find(cat => cat.items.includes(item)).color
  );

  // Check if the guess has already been made
  const currentGuessSorted = [...selectedItems].sort().join(',');
  const guessAlreadyMade = guesses.some(guess =>
    [...guess].sort().join(',') === currentGuessSorted
  );

  if (guessAlreadyMade) {
    toast.error("You've already made this guess. Try a different combination.");
    return;
  }

  // Save the guess in state and localStorage
  setGuesses(prevGuesses => [...prevGuesses, selectedColors]);
  saveGuessToLocalStorage(selectedColors);

  // Find if a complete category is matched
  const matchedCategory = categories.find(category =>
    selectedItems.every(item => category.items.includes(item)) &&
    category.items.every(item => selectedItems.includes(item))
  );

  // Check for incorrect guesses
  if (!matchedCategory) {
    const threeOfAKind = categories.some(category => {
      const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
      return selectedInCategory.length === 3 && !foundCategories.includes(category);
    });

    // Provide feedback based on the correctness of the guess
    if (threeOfAKind) {
      toast.info("3 of a kind. One Away");
    } else {
      toast.error("That's not a correct group. Try again!");
    }

    // Update the number of attempts
    setAttempts(prevAttempts => prevAttempts + 1);

    // Check if the game should end
    if (attempts + 1 >= maxAttempts) {
      handleEndGame(false);
    }
  } else {
    // Handle a successful category match
    updateFoundCategories();
    setSelectedItems([]); // Clear selection for a new guess
    toast.success("Category Found!"); // Notify the user of a successful match
  }
};

// Apply toast.dismiss() similarly in other functions where toast notifications are shown

const handleEndGame = (success) => {
  setGameEndedSuccessfully(success);
  setGameHasEnded(true); // Indicate that the game has ended
  setModalOpen(true); // Optionally open the stats modal immediately if you prefer
};


const handleResetGame = () => {
  if (window.confirm("Are you sure you want to reset the game? All progress will be lost.")) {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState(); // Function to reset game state
    // Also clear any relevant localStorage entries here
  }
};
const [showImages, setShowImages] = useState(false);
useEffect(() => {
  document.body.setAttribute('data-theme', theme);
}, [theme]);

return (
  <div className="connections-game">
   <nav className="navbar">
    {/* Left-aligned Logo and Crossover MLB text, centered alignment */}
    <a href="https://crossovergrid.com" 
       style={{ 
         textDecoration: "none", 
         color: "inherit", 
         display: "flex", 
         alignItems: "center", // This ensures vertical alignment
         justifyContent: "center" // This centers the content horizontally if the a-tag is a flex container
       }}>
      <img src={crossover_logo} alt="Logo" className="navbar-logo" />
      <p style={{ 
          margin: 0, // Removes default margin that might offset alignment
          fontWeight: "bold", 
          fontSize: 20,
          display: "flex", // Makes the paragraph itself a flex container
          alignItems: "center", // Centers the text inside the p-tag vertically
          justifyContent: "center" // Centers the text inside the p-tag horizontally
        }}>
        CROSSOVER NHL
      </p>
    </a>

    {/* Spacer to center the Crossover MLB logo and text in the navbar */}
    <div style={{ flexGrow: 1 }}></div>

    {/* Home and HowToPlayModal Icons */}
    <div>
      <IconButton href="/" title="Home">
        <HomeIcon />
      </IconButton>
      <IconButton onClick={() => setHowToPlayOpen(true)} title="How to Play">
        <HelpOutlineIcon />
      </IconButton>
    </div>

    <HowToPlayModal isOpen={howToPlayOpen} onClose={() => setHowToPlayOpen(false)} />
  </nav>


<ToastContainer position="top-center" />
    <h1>NHL Connections</h1>    <Select
  value={puzzleNumber}
  onChange={(e) => setPuzzleNumber(e.target.value)}
  displayEmpty
  inputProps={{ 'aria-label': 'Select puzzle' }}
  MenuProps={{
    PaperProps: {
      style: {
        maxHeight: 200, // Adjust based on your needs
        overflow: 'auto'
      }
    }
  }}
>
  {puzzleNumberOptions.map((number) => (
    <MenuItem key={number} value={number}>
      Puzzle {number}
    </MenuItem>
  ))}
</Select>

    <StatsModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      guesses={guesses}
      gameEndedSuccessfully={gameEndedSuccessfully}
      puzzleNumber={puzzleNumber} 

    />
    {!gameHasEnded && (
      <>
<div className="items-container">
  {allItems.map((item, index) => {
    const category = categories.find(category => category.items.includes(item));
    const itemIndexInCategory = category.items.indexOf(item);
    const imageUrl = category.images[itemIndexInCategory];
    const isSelected = selectedItems.includes(item);
    
    const itemStyle = isSelected
    ? theme === 'dark'
      ? { backgroundColor: '#ffcc00', color: 'black' } // Dark mode: bright background, dark text
      : { backgroundColor: '#ddd', color: 'black' } // Light mode: light background, dark text
    : {};
  
  return (
    <div
      key={index}
      className={`item ${showImages ? 'item-showing-image' : ''} ${isSelected ? 'selected' : ''}`}
      onClick={() => handleItemClick(item)}
      aria-pressed={isSelected ? 'true' : 'false'}
      style={itemStyle} // Apply the inline styles
    >
      {showImages ? (
        <img src={imageUrl} alt={item} />
      ) : (
        // The text color will change based on the style conditionally set above
        <span>{item}</span>
      )}
    </div>
  );
})}
</div>

      </>
    )}
 <div className="controls">
 {!gameHasEnded && (
      <> 
      <button onClick={() => setSelectedItems([])} disabled={selectedItems.length === 0}>
        Deselect All
      </button>
      <button onClick={handleSubmit} disabled={gameEndedSuccessfully || attempts >= maxAttempts}>
        Submit
      </button>
      <button onClick={toggleDisplay}>
        {showImages ? "Show Item Names" : "Show Images"}
      </button> </>
   )}
      {/* Conditionally render the View Stats button if the game has ended */}

  </div>
  



    {gameHasEnded && (
        <button onClick={() => setModalOpen(true)}>
          View Stats
        </button>
      )}
 {gameHasEnded && (
  <div className="end-game-display">
    <p>{gameEndedSuccessfully ? "Congratulations! You've completed the game!" : "Game Over. Better luck next time!"}</p>
    <div className="correct-answers">
      <h2>Correct Answers:</h2>
      {categories.map((category, index) => (
        <div key={index} className="category-display" style={{ borderColor: category.color, borderWidth: 1, borderStyle: 'solid', borderRadius: '10px', margin: '.5px 0', padding: '2px' }}>
          <h3 style={{ textAlign: 'center', color: category.color, margin: '1px 0', borderRadius: '10px' }}>{category.category}</h3>
          <div style={{ display: 'flex', justifyContent: 'space-around', padding: '2px', flexWrap: 'wrap' }}>
            {category.items.map((item, itemIndex) => (
              <div key={itemIndex} className="category-item" style={{ margin: '0px', textAlign: 'center', maxWidth: '100px', flexBasis: '25%', boxSizing: 'border-box' }}>
                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto 0px', backgroundColor: '#fff' }}>
                  <img src={category.images[itemIndex]} alt={item} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', borderRadius: '8px' }} />
                </div>
                <p style={{ fontSize: '0.8em', margin: '0' }}>{item}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
)}


{!gameHasEnded && (
      <> 
    {/* Status display section */}
    <div className="status">
      <p>Mistakes Remaining: {'⚫️'.repeat(maxAttempts - attempts) + '⚪️'.repeat(attempts)}</p>
      <p>Found Categories: {foundCategories.map(c => c.category).join(", ")}</p>
    </div>

    {/* Hints section */}
    <div className="hints-section">
      <h2>HINTS</h2>
      {categories.map((category, index) => {
        const isCategoryFound = foundCategories.includes(category);
        const categoryStyle = {
          borderColor: category.color,
          borderStyle: 'solid',
          borderWidth: '2px',
          backgroundColor: isCategoryFound ? category.color : 'transparent',
        };

        return (
          <div className="hint-category" key={index} style={categoryStyle}>
            <div className="category-name">{isCategoryFound ? category.category : `Category ${index + 1}`}</div>
            {!isCategoryFound && !hintsUsed[index].bigHint && (
              <div className="hints-buttons">
                <button onClick={() => handleHintClick(index, 'big')}>Big Hint</button>
                <button onClick={() => handleHintClick(index, 'small')}>Small Hint</button>
              </div>
            )}
            {isCategoryFound && (
              <div className="found-items">
                <p>Category Found!</p>
                <p>{category.items.join(", ")}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
    </>
   )}
    {/* Reset game button */}
    <button onClick={handleResetGame} className="reset-game-button">
      Reset Game
    </button>
    </div>
  );
};


export default ConnectionsGame;