// import qs from "qs";

import { Api } from "./api/Api";

const API = new Api({
  securityWorker: async () => {
    const headers: Record<string, string> = {
      // Authorization: `Bearer...`
      "Access-Control-Allow-Origin": "*",
    };

    return { headers };
  },
  baseApiParams: {
    secure: true,
    format: "json",
  },
  baseUrl: "http://localhost:4000",
});

export default API;
