import AlertModal from "./AlertModal";

const InviteModal = ({ isOpen, onClose, title, contentText, actions }) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      contentText={contentText}
      actions={actions}
    />
  );
};

const RematchModal = ({ isOpen, onClose, title, contentText, actions }) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      contentText={contentText}
      actions={actions}
    />
  );
};

const DrawModal = ({ isOpen, onClose, title, contentText, actions }) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      contentText={contentText}
      actions={actions}
    />
  );
};

const AwaitingResponseModal = ({
  isOpen,
  onClose,
  title,
  contentText,
  actions,
}) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      contentText={contentText}
      actions={actions}
    />
  );
};

export { InviteModal, RematchModal, DrawModal, AwaitingResponseModal };
