import React, { useState, useEffect } from 'react';
import playersData from './NBAMEGA.json';
import styles from './generator.module.css';
import { Link } from "react-router-dom";
import sports from "../../sports";
import crossover_logo from "../../assets/nba.svg";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Card, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

function RandomPlayerGenerator() {
    const [page_name, setPageName] = useState("");
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [activeStatus, setActiveStatus] = useState('all'); // State to handle active status filter
    const teamLogos = {
        ATL: "https://loodibee.com/wp-content/uploads/nba-atlanta-hawks-logo.png",
        BOS: "https://loodibee.com/wp-content/uploads/nba-boston-celtics-logo.png",
        BRK: "https://loodibee.com/wp-content/uploads/nba-brooklyn-nets-logo.png",
        CHA: "https://loodibee.com/wp-content/uploads/nba-charlotte-hornets-logo.png",
        CHI: "https://loodibee.com/wp-content/uploads/nba-chicago-bulls-logo.png",
        CLE: "https://loodibee.com/wp-content/uploads/nba-cleveland-cavaliers-logo.png",
        DAL: "https://loodibee.com/wp-content/uploads/nba-dallas-mavericks-logo.png",
        DEN: "https://loodibee.com/wp-content/uploads/nba-denver-nuggets-logo-2018.png",
        DET: "https://loodibee.com/wp-content/uploads/nba-detroit-pistons-logo.png",
        GSW: "https://loodibee.com/wp-content/uploads/nba-golden-state-warriors-logo.png",
        HOU: "https://loodibee.com/wp-content/uploads/nba-houston-rockets-logo.png",
        IND: "https://loodibee.com/wp-content/uploads/nba-indiana-pacers-logo.png",
        LAC: "https://loodibee.com/wp-content/uploads/NBA-LA-Clippers-logo-2024-480x480.png",
        LAL: "https://loodibee.com/wp-content/uploads/nba-los-angeles-lakers-logo.png",
        MEM: "https://loodibee.com/wp-content/uploads/nba-memphis-grizzlies-logo.png",
        MIA: "https://loodibee.com/wp-content/uploads/nba-miami-heat-logo.png",
        MIL: "https://loodibee.com/wp-content/uploads/nba-milwaukee-bucks-logo.png",
        MIN: "https://loodibee.com/wp-content/uploads/nba-minnesota-timberwolves-logo.png",
        NOP: "https://loodibee.com/wp-content/uploads/nba-new-orleans-pelicans-logo.png",
        NYK: "https://loodibee.com/wp-content/uploads/nba-new-york-knicks-logo.png",
        OKC: "https://loodibee.com/wp-content/uploads/nba-oklahoma-city-thunder-logo.png",
        ORL: "https://loodibee.com/wp-content/uploads/nba-orlando-magic-logo.png",
        PHI: "https://loodibee.com/wp-content/uploads/nba-philadelphia-76ers-logo.png",
        PHO: "https://loodibee.com/wp-content/uploads/nba-phoenix-suns-logo.png",
        POR: "https://loodibee.com/wp-content/uploads/nba-portland-trail-blazers-logo.png",
        SAC: "https://loodibee.com/wp-content/uploads/nba-sacramento-kings-logo.png",
        SAS: "https://loodibee.com/wp-content/uploads/nba-san-antonio-spurs-logo.png",
        TOR: "https://loodibee.com/wp-content/uploads/nba-toronto-raptors-logo.png",
        UTA: "https://loodibee.com/wp-content/uploads/nba-utah-jazz-logo.png",
        WAS: "https://loodibee.com/wp-content/uploads/nba-washington-wizards-logo.png"
    };
    
    const positions = ["G", "F", "C"];
    const [generatedPlayers, setGeneratedPlayers] = useState([]);
    const [numberOfPlayers, setNumberOfPlayers] = useState('');
    const [teams, setTeams] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState(new Set());
    const [selectedPositions, setSelectedPositions] = useState(new Set());
    const [isGenerated, setIsGenerated] = useState(false);
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

    useEffect(() => {
        setTeams(Object.keys(teamLogos));
    }, []);

    const theme = createTheme({
        palette: {
            mode: isDarkMode ? 'dark' : 'light',
        },
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        generatePlayers(form);
    };

    const toggleTeam = (team, event) => {
        event.stopPropagation();
        const newSet = new Set(selectedTeams);
        if (newSet.has(team)) {
            newSet.delete(team);
        } else {
            newSet.add(team);
        }
        setSelectedTeams(newSet);
    };
    
    const togglePosition = (position, event) => {
        event.stopPropagation();
        const newSet = new Set(selectedPositions);
        if (newSet.has(position)) {
            newSet.delete(position);
        } else {
            newSet.add(position);
        }
        setSelectedPositions(newSet);
    };

    const handleActiveStatusChange = (event) => {
        setActiveStatus(event.target.value);
    };

    const generatePlayers = (form) => {
        const selectedTeamsArray = Array.from(selectedTeams);
        const selectedPositionsArray = Array.from(selectedPositions);
        const fromYear = parseInt(form.get('fromYear'), 10) || 0;
        const toYear = parseInt(form.get('toYear'), 10) || new Date().getFullYear();
        const numPlayers = parseInt(numberOfPlayers, 10) || playersData.length;

        let filteredPlayers = playersData.filter(player => {
            const playerFrom = parseInt(player.From.split('-')[0], 10);
            const playerTo = parseInt(player.To.split('-')[0], 10);
            const isActive = player.Active === "Yes";
            return (!fromYear || playerFrom >= fromYear) &&
                (!toYear || playerTo <= toYear) &&
                (selectedTeamsArray.length === 0 || selectedTeamsArray.some(team => player.Team.includes(team))) &&
                (selectedPositionsArray.length === 0 || selectedPositionsArray.some(position => player.Pos.includes(position))) &&
                (activeStatus === 'all' || (activeStatus === 'active' && isActive) || (activeStatus === 'inactive' && !isActive));
        });

        if (filteredPlayers.length > 0) {
            filteredPlayers = shuffleArray(filteredPlayers);
            setGeneratedPlayers(filteredPlayers.slice(0, numPlayers));
            setIsGenerated(true);
        } else {
            setGeneratedPlayers([]);
            setIsGenerated(false);
        }
    };

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const toggleFilters = () => {
        setShowAdvancedFilters(!showAdvancedFilters);
    };

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={styles.container}>
                <nav className="navbar">
                    <a href="https://crossovergrid.com" className={styles.navLink}>
                        <img src={crossover_logo} alt="Logo" className="navbar-logo" />
                        <p className={styles.navTitle}>CROSSOVER GRID NBA</p>
                    </a>
                    <Button onClick={toggleDarkMode} variant="contained" color="primary">
                        {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
                    </Button>
                </nav>
                <div className="sport-picker">
                    <div className="links">
                        {sports.map((sport) => (
                            <Link key={sport.id} className={`sportLink ${page_name === sport.id ? "selected" : ""}`} to={sport.path}>
                                {sport.emoji.startsWith("http") ? <img src={sport.emoji} alt={sport.name} /> : <span>{sport.emoji}</span>}
                                <br />
                                {sport.name.length < 4 ? <span>{sport.name}</span> : <span style={{ fontSize: 12 }}>{sport.name}</span>}
                            </Link>
                        ))}
                    </div>
                </div>

                <div className={styles.body}>
                    <h2 className={styles.title}>Random NBA Player Generator</h2>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <div>
                            <InputLabel>Number of Players</InputLabel>
                            <input type="number" name="numberOfPlayers" value={numberOfPlayers} onChange={e => setNumberOfPlayers(e.target.value)} className={styles.input} required />
                        </div>
                        <div>
                            <FormControl fullWidth className={styles.selectSmall}>
                                <InputLabel>Active Status</InputLabel>
                                <Select
                                    value={activeStatus}
                                    onChange={handleActiveStatusChange}
                                    className={styles.input}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="active">Active</MenuItem>
                                    <MenuItem value="inactive">Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <Button onClick={toggleFilters} variant="contained" color="secondary">
                            Show/Hide Advanced Filters
                        </Button>
                        {showAdvancedFilters && (
                            <div>
                                <div className={styles.filterRow}>
                                    {teams.map((team, index) => (
                                        <Button key={index} onClick={(e) => toggleTeam(team, e)} variant="contained" className={`${styles.filterButton} ${selectedTeams.has(team) ? styles.filterButtonSelected : ''}`}>
                                            <img src={teamLogos[team]} alt={team} className={styles.teamLogo} />
                                            {team}
                                        </Button>
                                    ))}
                                </div>
                                <div className={styles.filterRow}>
                                    {positions.map((position, index) => (
                                        <Button key={index} onClick={(e) => togglePosition(position, e)} variant="contained" className={`${styles.filterButton} ${selectedPositions.has(position) ? styles.filterButtonSelected : ''}`}>
                                            {position}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        )}
                        <Button type="submit" variant="contained" color="primary">Generate</Button>
                    </form>
                    {isGenerated && generatedPlayers.length > 0 && (
                        <div className={styles.results}>
                            <h3>Generated Players:</h3>
                            <ul className={styles.resultsList}>
                                {generatedPlayers.map((player, index) => (
                                    <Card key={index} className={styles.resultItem} sx={{ bgcolor: 'background.paper', color: 'text.primary' }}>
                                        <div className={styles.playerDetails}>
                                            <strong>{player.Player}</strong> - Pos: {Array.isArray(player.Pos) ? player.Pos.join(', ') : player.Pos} - Teams: {Array.isArray(player.Team) ? player.Team.join(', ') : player.Team} - From: {player.From} - To: {player.To} - Active: {player.Active}
                                        </div>
                                        <div className={styles.imageContainer}>
                                            <img src={`https://www.basketball-reference.com/req/202106291/images/players/${player["Player-additional"]}.jpg`} alt={`${player.Player}'s profile`} className={styles.playerImage} />
                                        </div>
                                    </Card>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </ThemeProvider>
    );
}

export default RandomPlayerGenerator;
