import React, { useState, useEffect } from "react";
import styles from "./golfgame.module.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FedexCupLogo from '../../assets/PGAT Perfect 30-LocaliQ RGB_H-Pos.png';
import { ModalHelp } from "./components/ModalHelp";
import { useToggle } from "../../hooks/useToggle";
import pages from '../../assets/json_pages';  // Import the pages object

const ITEMS_PER_PAGE = 25;

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Object.keys(pages).length); // Initialize with the number of pages
  const { value: showHelpModal, toggle: toggleHelpModal } = useToggle();

  useEffect(() => {
    // Initialize data for the first page
    const data = pages[1];  // Get data for page 1
    if (data) {
      setLeaderboardData(data);
    } else {
      console.error('No data found for page 1');
    }
  }, []);

  useEffect(() => {
    // Load data for the current page
    const data = pages[currentPage];  // Get data for the current page
    if (data) {
      setLeaderboardData(data);
    } else {
      console.error(`No data found for page ${currentPage}`);
    }
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pagesToShow = 5;
    const paginationButtons = [];

    if (totalPages <= pagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        paginationButtons.push(
          <button
            key={i}
            className={`${styles.pageButton} ${currentPage === i ? styles.activePage : ''}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      let startPage = Math.max(currentPage - 2, 1);
      let endPage = Math.min(currentPage + 2, totalPages);

      if (currentPage <= 3) {
        endPage = 5;
      }
      if (currentPage >= totalPages - 2) {
        startPage = totalPages - 4;
      }

      if (startPage > 1) {
        paginationButtons.push(
          <button
            key={1}
            className={`${styles.pageButton} ${currentPage === 1 ? styles.activePage : ''}`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        );
        if (startPage > 2) {
          paginationButtons.push(<span key="start-ellipsis" className={styles.ellipsis}>...</span>);
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        paginationButtons.push(
          <button
            key={i}
            className={`${styles.pageButton} ${currentPage === i ? styles.activePage : ''}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          paginationButtons.push(<span key="end-ellipsis" className={styles.ellipsis}>...</span>);
        }
        paginationButtons.push(
          <button
            key={totalPages}
            className={`${styles.pageButton} ${currentPage === totalPages ? styles.activePage : ''}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        );
      }
    }

    return paginationButtons;
  };

  return (
    <div className={styles.container}>
      <header className={styles.headerContainer}>
        <img src={FedexCupLogo} alt="FedExCup Playoffs" className={styles.logo} />
        <button 
          className={styles.helpButton} 
          onClick={() => window.open("https://www.pgatour.com/article/news/latest/2024/08/11/perfect-30-fedexcup-playoffs-final-tour-championship-official-rules-game-predict", "_blank")}
        >
          Official Rules
        </button>
      </header>

      <h1 className={styles.header}>Leaderboard</h1>
      
      <p className={styles.subHeader}>
        Below is the leaderboard data as of the completion of the FedEx St. Jude Championship.
        <br />
        To view analytics of submissions, <a href="/perfect" className={styles.leaderboardLink}>click here</a>.
      </p>
      
      {leaderboardData.length === 0 ? (
        <p className={styles.noDataMessage}>
          Leaderboard data will populate after the completion of the FEDEX ST. JUDE CHAMPIONSHIP.
        </p>
      ) : (
        <>
          <div className={styles.leaderboardTable}>
            <div className={styles.leaderboardHeader}>
              <div className={styles.leaderboardCell}>Rank</div>
              <div className={styles.leaderboardCell}>Name</div>
              <div className={styles.leaderboardCell}>Points</div>
              <div className={styles.leaderboardCell}>Winner</div>
            </div>
            {leaderboardData.map((player, index) => (
              <div key={index} className={styles.leaderboardRow}>
                <div className={styles.leaderboardCell}>{player.Rank}</div>
                <div className={styles.leaderboardCell}>{player.Name}</div>
                <div className={styles.leaderboardCell}>{player.Points}</div>
                <div className={styles.leaderboardCell}>{player.Winner}</div>
              </div>
            ))}
          </div>

          <div className={styles.pagination}>
            {renderPagination()}
          </div>
        </>
      )}

      <ModalHelp open={showHelpModal} onClose={toggleHelpModal} />

      <footer className={styles.footer}>
        <span>Powered by <a href="https://crossovergrid.com" target="_blank" rel="noopener noreferrer">crossovergrid.com</a></span>
      </footer>

      <ToastContainer position="top-center" />
    </div>
  );
};

export default Leaderboard;
