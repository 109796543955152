// MultiplayerContext.js
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useTimer } from "./hooks/useTimer";
import PropTypes from "prop-types";

const MultiplayerContext = createContext();

export const MultiplayerProvider = ({ children }) => {
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [currentTimer, setCurrentTimer] = useTimer(30, isTimerRunning);

  const resetTimer = useCallback(
    (newTime) => {
      setCurrentTimer(newTime);
    },
    [setCurrentTimer]
  );

  const values = useMemo(
    () => ({
      currentTimer,
      setCurrentTimer,
      isTimerRunning,
      setIsTimerRunning,
      resetTimer,
    }),
    [currentTimer, setCurrentTimer, isTimerRunning, resetTimer]
  );

  return (
    <MultiplayerContext.Provider value={values}>
      {children}
    </MultiplayerContext.Provider>
  );
};

MultiplayerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useMultiplayerContext = () => useContext(MultiplayerContext);
