import React from "react";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Colors from "../../../Colors";
import useTheme from "@mui/material/styles/useTheme";
import { makeStyles as makeMuiStyles } from "@mui/styles";

export default ({
  text = "",
  onClick,
  wrapperStyle,
  tileStyle,
  textStyle,
  data,
  isActive=false
}) => {
  // data is statistic displayed under the text
    const longestWordLength = Math.max(
    ...text.split(" ").map((word) => word.length)
  );
  const longText = longestWordLength > 10 || text.length > 25;

  const smallScreen = useMediaQuery("(max-width:522px)");

  let fontSize;
  if (longText) {
    if (smallScreen) {
      fontSize = "10px";
    } else {
      fontSize = "11px";
    }
  } else {
    if (smallScreen) {
      fontSize = "0.7rem";
    } else {
      fontSize = "0.9rem";
    }
  }
  if (text !== "" ) {
  console.log(text, isActive)

  }
  const theme = useTheme();
  const useStyles = makeMuiStyles((theme, prefersDarkMode) => ({
    tileText: {
      textAlign: "center",
            lineHeight: 1,
            color: isActive ? '#ffffff' : theme.palette.text.primary,
            fontSize,
            fontWeight: 'bold'}
    }))
    const classes = useStyles()



  return (
    // tile spacer div
    <div
      style={{
        width: "20%",
        maxWidth: 100,
        boxSizing: "border-box",
        height: useMediaQuery("(max-height:700px)") ? 40 : 55,
        minHeight: 40,
        ...(wrapperStyle || {}),
      }}
    >
      {/* tile div */}
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 1,
          ...(tileStyle || {}),
        }}
        onClick={onClick}
      >
        {/* text */}
        <Typography
        className={classes.tileText}
          style={{
            fontWeight: 'bold',
            fontSize: fontSize,
            ...(textStyle || {}),
          }}
          variant="body1"
        >
          {text}
        </Typography>

        {data ? (
          <Typography
            style={{
              textAlign: "center",
              lineHeight: 1,
              color: Colors.WHITE,
              fontSize: "12px",
              fontWeight: 600,
            }}
            variant="body1"
          >
            {data}
          </Typography>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
