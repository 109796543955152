import React from "react";
import { useTheme } from "@mui/material/styles";

const LoginForm = ({ errors }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <div className="flex flex-col gap-4">
      <div>
        <label
          htmlFor="email"
          className={`block text-sm font-medium ${
            isDarkMode ? "text-gray-400" : "text-gray-700"
          }`}
        >
          Email Address
        </label>
        <input
          autoFocus
          required
          id="email"
          name="email"
          type="email"
          placeholder="Email Address"
          className={`w-full p-2 mt-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            isDarkMode
              ? "border-gray-400 placeholder-gray-400 text-gray-800"
              : "text-gray-900"
          }`}
        />
        {errors.email && (
          <div className="text-red-500 text-sm mt-1">{errors.email}</div>
        )}
      </div>
      <div>
        <label
          htmlFor="password"
          className={`block text-sm font-medium ${
            isDarkMode ? "text-gray-400" : "text-gray-700"
          }`}
        >
          Password
        </label>
        <input
          required
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          className={`w-full p-2 mt-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            isDarkMode
              ? "border-gray-400 placeholder-gray-400 text-gray-800"
              : "text-gray-900"
          }`}
        />
        {errors.password && (
          <div className="text-red-500 text-sm mt-1">{errors.password}</div>
        )}
      </div>
      {errors.general && (
        <div className="text-red-500 text-sm">{errors.general}</div>
      )}
    </div>
  );
};

export default LoginForm;
