import { useState, useEffect, useCallback } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { SPORTS } from "./userStats.constants";
import { useAppContext } from "../../contexts/AppContext";

const API_URL = process.env.REACT_APP_MULTIPLAYER_API_URL;

export const useQueryLeaderboard = () => {
  const { userId, username } = useUserContext();
  const {
    saveSportLeaderboard,
    sportLeaderboard,
    setSportId,
    loading,
    setLoading,
    sportId,
  } = useAppContext();
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [disabledMore, setDisabledMore] = useState(false);
  const [currentUserRank, setCurrentUserRank] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const fetchLeaderBoard = useCallback(
    async (sportId, newPage = 1) => {
      if (
        sportLeaderboard?.sportId === sportId &&
        sportLeaderboard?.page >= newPage
      )
        return;

      try {
        const response = await fetch(
          `${API_URL}/user/leaderboard?sport=${sportId}&page=${newPage}&size=10`
        );
        const data = await response.json();

        if (!data?.data?.length) {
          setDisabledMore(true);
          return;
        }

        saveSportLeaderboard(sportId, newPage, data.data);

        // Fetch current user rank
        const userResponse = await fetch(
          `${API_URL}/user/rank?user=${userId}&sport=${sportId}`
        );
        const userData = await userResponse.json();
        setCurrentUserRank(userData);
      } catch (error) {
        setError(error);
      } finally {
        // setLoading(false);
      }
    },
    [saveSportLeaderboard, sportLeaderboard, userId]
  );

  const onChangeSport = useCallback(
    (newSportId) => {
      setSportId(newSportId);
      setLoading(true);
      setIsLoadingMore(true);
      fetchLeaderBoard(newSportId);
      setDisabledMore(false);
      setLoading(false);
      setIsLoadingMore(false);
    },
    [fetchLeaderBoard, setLoading, setSportId]
  );

  const onChangePage = useCallback(
    async (newSportId) => {
      const nextPage = sportLeaderboard?.page + 1 || 1;

      setIsLoadingMore(true);
      await fetchLeaderBoard(newSportId, nextPage);
      setIsLoadingMore(false);
    },
    [fetchLeaderBoard, sportLeaderboard?.page]
  );

  return {
    fetchLeaderBoard,
    username,
    sportId: sportLeaderboard?.sportId,
    selectedSport: SPORTS.find((sport) => sportId === sport.id) ?? SPORTS[0],
    setSportId,
    page,
    setPage,
    leaderboard: sportLeaderboard?.leaderboard || [],
    loading,
    onChangeSport,
    onChangePage,
    disabledMore,
    error,
    currentUserRank,
    isLoadingMore,
  };
};
