import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import styles from './GeoGuess.module.css';
import players from './updated_players_with_sbmvp.json'; // Ensure the players.json is correctly imported

const GeoGuess = () => {
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
    const [guesses, setGuesses] = useState({});
    const [score, setScore] = useState(5000);
    const [opponentScore, setOpponentScore] = useState(5000);
    const [timeTaken, setTimeTaken] = useState(0);
    const [gameStarted, setGameStarted] = useState(false);
    const [gameId, setGameId] = useState(null); // To hold the gameId from the server
    const [totalRounds, setTotalRounds] = useState(players.length); // Assuming one round per player
    const currentPlayer = players[currentPlayerIndex];

    const socketRef = useRef();
    useEffect(() => {
        // Correctly configure the URL and path to match the server
        const socket = io('https://crossover-grid-511a4.web.app', {
            path: '/socket',
            transports: ['websocket', 'polling'] // Try to use WebSocket primarily
        });
        
    
        socketRef.current.on('connect', () => {
            console.log('Connected to server');
        });

    

        socketRef.current.on('gameCanStart', ({ gameId: newGameId }) => {
            setGameId(newGameId);
            setGameStarted(true);
            console.log('Game started with ID:', newGameId);
        });

        socketRef.current.on('scoreChanged', (data) => {
            setOpponentScore(data.score);
        });

        socketRef.current.on('gameOver', (winnerId) => {
            const message = winnerId === socketRef.current.id ? 'Congratulations! You won the game!' : 'Game over! You lost the game.';
            alert(message);
            setGameStarted(false);
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, []);

    const findGame = () => {
        socketRef.current.emit('requestGame');
    };

    const submitGuess = (e) => {
        e.preventDefault(); // Prevent form submit from reloading the page
        const correctAnswer = currentPlayer.DrftYr; // Assuming the guess is about the draft year
        const isCorrect = parseInt(guesses.year) === correctAnswer;

        let updatedScore = score;
        if (isCorrect) {
            updatedScore += 1000 - (timeTaken * 10); // Add points, subtract some based on time
        } else {
            updatedScore -= 500; // Penalize wrong answers
        }

        setScore(updatedScore);
        socketRef.current.emit('updateScore', { gameId, playerId: socketRef.current.id, score: updatedScore });

        // Move to the next player or end game if it's the last round
        if (currentPlayerIndex < players.length - 1) {
            setCurrentPlayerIndex(currentPlayerIndex + 1);
        } else {
            socketRef.current.emit('endGame', { gameId });
        }
    };

    return (
        <div className={styles.container}>
            {!gameStarted ? (
                <button className={styles.findGameButton} onClick={findGame}>
                    Find Game
                </button>
            ) : (
                <div className={styles.gameArea}>
                    <h1>Round {currentPlayerIndex + 1} of {totalRounds}</h1>
                    {currentPlayer && (
                        <div className={styles.playerInfo}>
                            <img src={currentPlayer['Image Url']} alt={`Guess the player: ${currentPlayer.Player}`} className={styles.playerImage} />
                            <form onSubmit={submitGuess}>
                                <div className={styles.inputFields}>
                                    <input type="text" value={guesses.year || ''} onChange={e => setGuesses({ ...guesses, year: e.target.value })} placeholder="Guess the draft year" />
                                    <input type="text" value={guesses.team || ''} onChange={e => setGuesses({ ...guesses, team: e.target.value })} placeholder="Guess the draft team" />
                                    <input type="text" value={guesses.round || ''} onChange={e => setGuesses({ ...guesses, round: e.target.value })} placeholder="Guess the draft round" />
                                    <input type="text" value={guesses.pick || ''} onChange={e => setGuesses({ ...guesses, pick: e.target.value })} placeholder="Guess the draft pick" />
                                    <button type="submit" className={styles.submitButton}>Submit Guess</button>
                                </div>
                            </form>
                            <div className={styles.scores}>
                                <p>Time Taken: {timeTaken} seconds</p>
                                <p>Your Score: {score}</p>
                                <p>Opponent Score: {opponentScore}</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default GeoGuess;
