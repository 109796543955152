
import React, { useState, useEffect } from 'react';
import styles from './tool.module.css';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import Modal from './Modal'; // Make sure you have a Modal component similar to the one used in SportsAnalogyFinder
import crossoverTriviaLogo from "../../assets/crossover_trivia.svg";
import sports from "../../sports";
import { makeStyles as makeMuiStyles } from "@mui/styles";
import ReactGA from "react-ga";

import { Link } from "react-router-dom";

const TweetTranslator = () => {
    const [tweet, setTweet] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [translationResult, setTranslationResult] = useState(null);
    const [translationDirection, setTranslationDirection] = useState('NFLtoNBA');
    const [modalIsOpen, setModalIsOpen] = useState(false); // State to control modal visibility
    const [page_name, setPageName] = useState(""); // Define page_name state
    const [sport_id, setSportId] = useState(""); // Define sport_id state
    const currentSport = sports.find((sport) => sport.id === sport_id.toString());
    const useStyles = makeMuiStyles((theme, prefersDarkMode) => ({  }));
    const classes = useStyles();

    // Function to toggle modal visibility
    const toggleModal = () => {
      setModalIsOpen(!modalIsOpen);
    };

    const howToPlayContent = (
        <>
          <h2>Welcome to Tweet Translator!</h2>
          <p>
            Dive into the fun of sports by translating tweets from one sports context to another. Whether it’s seeing how an NFL update would look in the NBA world or flipping a soccer commentary to fit baseball, Tweet Translator is your playground for cross-sport communication. Ready to get started? Here’s your game plan:
          </p>
          <h3>Getting Started:</h3>
          <ol>
            <li>
              <strong>Pick Your Translation Direction:</strong> Use the dropdown to select the sports you're translating between, like NFL to NBA or Soccer to Baseball.
            </li>
            <li>
              <strong>Type in Your Tweet:</strong> Enter the tweet you'd like to translate. Make it sporty, make it fun!
            </li>
            <li>
              <strong>Press Translate:</strong> Hit the translate button and watch as we convert your tweet into a whole new sports language.
            </li>
          </ol>
          <h3>What to Expect:</h3>
          <p>
            Your original tweet will be transformed to fit the context, terminology, and flair of the target sport. It's a great way to see how universal sports moments can be, across any field, court, or arena.
          </p>
          <h3>Tips for a Great Translation:</h3>
          <p>
            The clearer your original tweet, the better the translation. Think major moments, player movements, or game-day emotions that cross the boundaries of sport.
          </p>
          <button onClick={toggleModal} className={styles.closeModalButton}>Got it, let's go!</button>
        </>
      );
  
    // Profiles for NFL and NBA
const nflProfile = {
    img: "https://pbs.twimg.com/profile_images/793924061843914752/ycm8ibEE_400x400.jpg",
    name: "NFL Reporter",
    handle: "@NFL_Report"
  };

  const nbaProfile = {
    img: "https://pbs.twimg.com/profile_images/1509990164415893517/qIuzsMq6_400x400.jpg",
    name: "NBA Reporter",
    handle: "@NBA_Report"
  };

  const mlbProfile = {
    img: "https://pbs.twimg.com/profile_images/545915462609932288/X_vDhHiE_400x400.png",
    name: "MLB Reporter",
    handle: "@MLB_Report"
  };

  const nhlProfile = {
    img: "https://pbs.twimg.com/profile_images/1767197696962490368/1_OCgjgW_400x400.jpg",
    name: "NHL Reporter",
    handle: "@NHL_Report"
  };

  const mlsProfile = {
    img: "https://pbs.twimg.com/profile_images/1741753635158024192/j0m8Ucvv_400x400.jpg",
    name: "Soccer Reporter",
    handle: "@MLS_Report"
  };

  // Toggle profiles based on the translation direction
  let originalProfile, translatedProfile;
  switch (translationDirection) {
    case 'NFLtoNBA':
      originalProfile = nflProfile;
      translatedProfile = nbaProfile;
      break;
    case 'NBAtoNFL':
      originalProfile = nbaProfile;
      translatedProfile = nflProfile;
      break;
    case 'MLBtoNBA':
      originalProfile = mlbProfile;
      translatedProfile = nbaProfile;
      break;
    case 'NBAttoMLB':
      originalProfile = nbaProfile;
      translatedProfile = mlbProfile;
      break;
    case 'NHLtoNFL':
      originalProfile = nhlProfile;
      translatedProfile = nflProfile;
      break;
    case 'NFLtoNHL':
      originalProfile = nflProfile;
      translatedProfile = nhlProfile;
      break;
    case 'MLStoNBA':
      originalProfile = mlsProfile;
      translatedProfile = nbaProfile;
      break;
    case 'NBAtoMLS':
      originalProfile = nbaProfile;
      translatedProfile = mlsProfile;
      break;
    default:
      // Default to NFL to NBA translation
      originalProfile = nflProfile;
      translatedProfile = nbaProfile;
  }

  



    const generateTranslationPrompt = (originalTweet) => {
        let contextSpecificPrompt;
        switch (translationDirection) {
            case 'NFLtoNBA':
                contextSpecificPrompt = `Translate this NFL tweet to NBA context:`;
                break;
            case 'NBAtoNFL':
                contextSpecificPrompt = `Translate this NBA tweet to NFL context:`;
                break;
            case 'MLBtoNBA':
                contextSpecificPrompt = `Translate this MLB tweet to NBA context:`;
                break;
            case 'NBAttoMLB':
                contextSpecificPrompt = `Translate this NBA tweet to MLB context:`;
                break;
            case 'NHLtoNFL':
                contextSpecificPrompt = `Translate this NHL tweet to NFL context:`;
                break;
            case 'NFLtoNHL':
                contextSpecificPrompt = `Translate this NFL tweet to NHL context:`;
                break;
            case 'MLStoNBA':
                contextSpecificPrompt = `Translate this Professional Soccer tweet to NBA context:`;
                break;
            case 'NBAtoMLS':
                contextSpecificPrompt = `Translate this NBA tweet to Professional Soccer context:`;
                break;
            case 'NFLtoMLB':
                contextSpecificPrompt = `Translate this NFL tweet to MLB context:`;
                break;
            case 'MLBtoNFL':
                contextSpecificPrompt = `Translate this MLB tweet to NFL context:`;
                break;
            case 'NBAtoNHL':
                contextSpecificPrompt = `Translate this NBA tweet to NHL context:`;
                break;
            case 'NHLtoNBA':
                contextSpecificPrompt = `Translate this NHL tweet to NBA context:`;
                break;
            case 'MLStoNFL':
                contextSpecificPrompt = `Translate this Professional Soccer tweet to NFL context:`;
                break;
            case 'NFLtoMLS':
                contextSpecificPrompt = `Translate this NFL tweet to Professional Soccer context:`;
                break;
            case 'MLStoMLB':
                contextSpecificPrompt = `Translate this Professional Soccer tweet to MLB context:`;
                break;
            case 'MLBtoMLS':
                contextSpecificPrompt = `Translate this MLB tweet to Professional Soccer context:`;
                break;
            case 'MLStoNHL':
                contextSpecificPrompt = `Translate this Professional Soccer tweet to NHL context:`;
                break;
            case 'NHLtoMLS':
                contextSpecificPrompt = `Translate this NHL tweet to Professional Soccer context:`;
                break;
            default:
                contextSpecificPrompt = `Translate this NFL tweet to NBA context:`;
        
        
       
        
        }    const trainingExamples = [
        {
          original_tweet: "Bears are trading Justin Fields for a 2025 6th-round pick that goes to a 4th-round pick based on playtime, per sources.",
          translated_tweet: "Bulls are swapping Coby White for a 2025 2nd-round pick that escalates to a 1st-round pick contingent on performance, sources confirm."
        },
        {
          original_tweet: "Free-agent WR Braxton Berrios is re-signing with the Miami Dolphins on a one-year deal worth up to $3 million. Berrios’ agents, Drew Rosenhaus and Robert Bailey confirmed the deal.",
          translated_tweet: "Free Agent Patty Mills, known for his hustle, three-point shooting, and occasional flashes of brilliance, decided to re-sign with his current team, the San Antonio Spurs, for another year on a modest contract, Rich Paul and Jeff Schwartz confirm the deal."
        },
        {
          original_tweet: "Filed from @mspears96: Ravens free-agent LB Patrick Queen told @mspears96 that he plans to sign a three-year, $41 million contract with the Pittsburgh Steelers.",
          translated_tweet: "Filed from @mspears96: Celtics free-agent SG Marcus Smart told @mspears96 that he plans to sign a three-year, $50 million contract with the Los Angeles Lakers."
        },
        {
        original_tweet: "Trade: Eagles are sending edge-rusher Haason Reddick to the New York Jets for a conditional 2026 third-round pick that could be a second, sources tell ESPN. The 2026 pick becomes a 2nd if Reddick has 67.5% playtime this season and has 10 or more sacks. If not, it’s a 2023 third.",
        translated_tweet: "Trade: The Toronto Raptors are sending forward OG Anunoby to the Los Angeles Clippers for a conditional 2026 first-round pick that could become a top-10 pick, sources tell ESPN. The 2026 pick escalates to a top-10 pick if Anunoby participates in at least 67.5% of the season's games and averages more than 18 points per game. If not, it remains a 2026 first-round pick outside the top 10."
        }
      ];



    let promptTemplate = `
${contextSpecificPrompt}
Original tweet: "${originalTweet}"
When considering your translation, include a comprehensive analysis involving:
- A player comparison that aligns not just with the athlete's skills and contributions but also with their leadership, marketability, and impact both on and off the field/court.
- Team dynamics, including historical rivalry status, current standings, team culture, and the role the teams play in their respective leagues. Reflect on how these aspects influence fan engagement and team strategies.
- The implications of trades or signings on team chemistry, future prospects, and potential shifts in competitive balance within the league.
- Economic factors such as salary cap implications, revenue generation potential of the player involved, and how the financial aspect influences team building strategies and how it is different for the other sport. including the positional importance.
- Any historical matchups or significant moments between the teams involved that could add layers to the rivalry or cooperation in the context of the sport.
- Broader sports industry trends that might be relevant to understanding the significance of the move, such as shifts in audience preferences, media rights considerations, and sponsorship dynamics.
- make sure the palyers they are comparing are       This comprehensive request ensures all critical aspects of the player's career and attributes are captured, and it remains flexible to include any new or unexpected information that might emerge. This approach aids in creating a well-rounded profile that can serve as a solid basis for generating an analogy or further analysis
- see some example comparisons ulian Edelman (American Football, Wide Receiver) and Fred VanVleet (Basketball, Point Guard): Both athletes are celebrated for their critical performances in championship runs, showcasing resilience, clutch performances, and leadership.
DeAndre Swift (American Football, Running Back) and Mikal Bridges (Basketball, Small Forward): These players share agility and playmaking abilities, contributing significantly beyond basic statistics with Swift's versatility and Bridges' defensive prowess.
Taysom Hill (American Football, Utility Player) and Josh Hart (Basketball, Shooting Guard): Known for their unique versatility and all-around contributions, including defense and hustle plays, Hill and Hart embody the spirit of utility and adaptability in their teams.
Patrick Beverley (Basketball, Guard) and Tyrann Mathieu (American Football, Safety): Both celebrated for their relentless defense, leadership, and the ability to disrupt opponents, Beverley and Mathieu are pivotal in shaping their teams' defensive strategies. 
- This is crucial remember nba contracts are almost always fully guaranteed and typically way more than nfl but dont mention reflecting the NBA's higher salary structures and fully guaranteed contracts. 
- when giving hypothetical scenario beware of the players team history
- i know you want to do the same cities in a tweet but make sure if you are saying resigning the player has played there before
- Crucial:  THE PLAYERS MUST BE REAL, DO NOT MAKE UP PLAYERS

Do not include any text before or after it should just be the json and follow the same format as training examples



Training Examples of NFL to NBA With NBA to NFL should be reverse:
${trainingExamples.map(example => `original_tweet: ${example.original_tweet}\ntranslated_tweet: ${example.translated_tweet}`).join("\n\n")}
`;
    return promptTemplate;
  };


  
  const translateTweet = async () => {
    setIsLoading(true);
    const detailedAnalogyPrompt = generateTranslationPrompt(tweet);
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: 'gpt-4-turbo-preview',
          messages: [{ role: "system", content: "You are an AI knowledgeable about all sports." }, { role: "user", content: detailedAnalogyPrompt }],
        })
      });
      
      const data = await response.json();
      if (data.error) {
        console.error(data.error);
        setTranslationResult({ error: 'Failed to fetch the translation. Please try again.' });
      } else {
        let content = data.choices[0].message.content;
  
        // Remove the triple backticks and "json" if they are present in the response
        content = content.replace(/```json\n/, '').replace(/\n```/, '');
  
        // Attempt to parse the JSON content directly
        try {
          const parsedContent = JSON.parse(content);
          const originalTweet = parsedContent["original_tweet"];
          const translatedTweet = parsedContent["translated_tweet"];
    
          // Check if both original and translated tweets are present
          if (originalTweet && translatedTweet) {
            setTranslationResult({
              original: originalTweet,
              translation: translatedTweet,
            });
          } else {
            console.error('Missing original or translated tweet in the JSON object.');
            setTranslationResult({ error: 'Missing original or translated tweet data. Please try again.' });
          }
        } catch (jsonParseError) {
          console.error('JSON parsing error:', jsonParseError);
          setTranslationResult({ error: 'Failed to parse the translation. Please try again.' });
        }
      }
    } catch (error) {
      console.error('There was an error fetching the translation:', error);
      setTranslationResult({ error: 'An error occurred. Please check your internet connection and try again.' });
    } finally {
      setIsLoading(false);
    }
  };
  
  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };

    // Function to share the translated tweet
    const shareTranslation = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Translated Tweet',
                text: `Original Tweet: ${translationResult.original}, Translated Tweet: ${translationResult.translation} @CrossoverGrid Crossovergrid.com/ai`,
            }).then(() => {
                console.log('Shared successfully');
            }).catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            console.log('Web Share API not supported');
            // Fallback mechanism for sharing on unsupported browsers
            // Implement your own sharing mechanism here
        }
    };

    // Function to copy the translated tweet to clipboard
    const copyTranslation = () => {
        navigator.clipboard.writeText(`Original Tweet: ${translationResult.original}, Translated Tweet: ${translationResult.translation}, @CrossoverGrid Crossovergrid.com/ai`).then(() => {
            console.log('Copied to clipboard');
        }).catch((error) => {
            console.error('Error copying to clipboard:', error);
        });
    };

  const Header = () => (
    <div className="app-header">
      <nav className="navbar">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <a href="https://www.crossovergrid.com" target="_blank" rel="noopener noreferrer">
                <img
                  src={crossoverTriviaLogo}
                  alt="Logo"
                  className="navbar-logo"
                />
                <p
                  style={{
                    marginLeft: "10px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "0",
                    marginBottom: "0",
                  }}
                >
                  CROSSOVER GRID
                </p>
              </a>
            </div>
            <div>
              {/* Potential space for navigation or user actions */}
            </div>
          </div>
      </nav>
    </div>
  );


  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  }, []);


  const handleTranslateClick = () => {
    translateTweet();
  };

  const toggleTranslationDirection = () => {
    setTranslationDirection(prevDirection => prevDirection === 'NFLtoNBA' ? 'NBAtoNFL' : 'NFLtoNBA');
  };
  return (
    <div className={styles.container} style={{ paddingTop: '40px' }}>
        <Header />
        <div className="sport-picker">
  <div className="links">
    {sports.map((sport) => (
      <Link
        key={sport.id}
        className={`sportLink ${sport.id === "21" ? "prime-gaming-logo" : ""} ${classes.link} ${page_name === sport.id ? "selected" : ""}`}
        to={sport.path}
        // onClick={() => onAdRefresh()}
      >
        {sport.emoji.startsWith("http") ? (
          <img src={sport.emoji} alt={sport.name} />
        ) : (
          <span>{sport.emoji}</span>
        )}
        <br />
        {sport.name.length < 4 ? (
          <span>{sport.name}</span>
        ) : (
          <span style={{ fontSize: 12 }}>{sport.name}</span>
        )}
      </Link>
    ))}
  </div>
</div>
        <h1>FanLingo</h1>
        <button onClick={toggleModal} className={styles.infoButton}>How It Works</button>
        <Modal isOpen={modalIsOpen} onClose={toggleModal} title="How It Works">
            {howToPlayContent}
        </Modal>
        <div className={styles.toggleContainer}>
            <select id="translationDirection" value={translationDirection} onChange={(e) => setTranslationDirection(e.target.value)}>
          <option value="NFLtoNBA">NFL to NBA</option>
          <option value="NBAtoNFL">NBA to NFL</option>
          <option value="MLBtoNBA">MLB to NBA</option>
          <option value="NBAttoMLB">NBA to MLB</option>
          <option value="NHLtoNFL">NHL to NFL</option>
          <option value="NFLtoNHL">NFL to NHL</option>
          <option value="MLStoNBA">Professional Soccer to NBA</option>
          <option value="NBAtoMLS">NBA to Professional Soccer</option>
          <option value="NFLtoMLB">NFL to MLB</option>
          <option value="MLBtoNFL">MLB to NFL</option>
          <option value="NBAtoNHL">NBA to NHL</option>
          <option value="NHLtoNBA">NHL to NBA</option>
          <option value="MLStoNFL">Professional Soccer to NFL</option>
          <option value="NFLtoMLS">NFL to Professional Soccer</option>
          <option value="MLStoMLB">Professional Soccer to MLB</option>
          <option value="MLBtoMLS">MLB to Professional Soccer</option>
          <option value="MLStoNHL">Professional Soccer to NHL</option>
          <option value="NHLtoMLS">NHL to Professional Soccer</option>
            </select>
        </div>
        <textarea
            value={tweet}
            onChange={(e) => setTweet(e.target.value)}
            placeholder={
                translationDirection.startsWith('NFL')
                    ? "Enter NFL tweet"
                    : translationDirection.startsWith('NBA')
                    ? "Enter NBA tweet"
                    : translationDirection.startsWith('MLB')
                    ? "Enter MLB tweet"
                    : translationDirection.startsWith('NHL')
                    ? "Enter NHL tweet"
                    : translationDirection.startsWith('MLS')
                    ? "Enter MLS tweet"
                    : ""
            }
            className={styles.textarea}
        />
        <button onClick={handleTranslateClick} disabled={isLoading} className={styles.button}>
            {isLoading ? 'Translating...' : 'Translate Tweet'}
        </button>

        {translationResult && (
            
            <>          {/* Share and Copy buttons */}
            <div className={styles.shareCopyButtons}>
                <button onClick={shareTranslation} className={styles.button}>Share</button>
                <button onClick={copyTranslation} className={styles.button}>Copy</button>
            </div>
                {/* Original Tweet Container */}
                <div className={styles.tweetContainer}>
                    <div className={styles.tweetHeader}>
                        <img src={originalProfile.img} alt="Profile" className={styles.profileImg} />
                        <div>
                            <div className={styles.tweetAuthorDetails}>
                                <span className={styles.authorName}>{originalProfile.name}</span>
                                <span className={styles.authorHandle}>{originalProfile.handle}</span>
                            </div>
                            <div className={styles.tweetBody}>
                                <p>{translationResult.original}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tweetInteractions}>
                        <ChatBubbleOutlineIcon />
                        <RepeatIcon />
                        <FavoriteBorderIcon />
                    </div>
                </div>

                {/* Translated Tweet Container */}
                <div className={styles.tweetContainer}>
                    <div className={styles.tweetHeader}>
                        <img src={translatedProfile.img} alt="Profile" className={styles.profileImg} />
                        <div>
                            <div className={styles.tweetAuthorDetails}>
                                <span className={styles.authorName}>{translatedProfile.name}</span>
                                <span className={styles.authorHandle}>{translatedProfile.handle}</span>
                            </div>
                            <div className={styles.tweetBody}>
                                <p>{translationResult.translation}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tweetInteractions}>
                        <ChatBubbleOutlineIcon />
                        <RepeatIcon />
                        <FavoriteBorderIcon />
                    </div>
                </div>

      
            </>
        )}
        </div>
);


};


export default TweetTranslator;