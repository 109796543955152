import { makeStyles as makeMuiStyles } from "@mui/styles";

export const useStyles = makeMuiStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  media: {
    height: 0,
    paddingTop: "100%",
  },
  close: {
    position: "absolute",
    color: "#bdbdbd",
  },
  boxCard: {
    backgroundColor: theme.palette.background.gridCard,
    "&:hover": {
      backgroundColor: theme.palette.background.gridCardHover,
    },
  },
  boxCardSelected: {
    backgroundColor: theme.palette.background.gridCardSelect,
  },
  link: {
    color: theme.palette.text.link,
    fontSize: "12px",
    fontWeight: "600",
  },
  searchBox: {
    backgroundColor: "#58D18490",
    position: "relative",
    backdropFilter: "blur(10px)",
    color: "white",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.4)",
  },
  searchInput: {
    color: theme.palette.text.primary,
  },
  result: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    cursor: "pointer",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.background.resultHover,
    },
  },
  sponsorshipLink: {
    marginTop: "20px",
    color: theme.palette.text.link,
    textDecoration: "none",
    borderBottom: `1px solid ${theme.palette.text.link}`,
  },
  sponsorshipLinkUnderline: {
    color: theme.palette.text.link,
    textDecoration: "none",
  },
  remainingGuesses: {
    position: "absolute",
    top: "50%",
  },
  bars: {
    fontSize: "12px",
    textAnchor: "middle",
    fill: theme.palette.text.primary,
  },
  barLabelsXAxis: {
    fontSize: "14px",
    marginTop: "5px",
    fontWeight: "bold",
    textAnchor: "middle",
    fill: theme.palette.text.primary,
  },
  boardImage: {
    filter: theme.palette.mode === "dark" ? "invert(1)" : "invert(0)",
    marginTop: 20,
  },
}));
