import React, { useContext, useState } from "react";
import { z } from "zod";
import { UserContext } from "../../contexts/UserContext";
import {
  LoginSchema,
  RecoverSchema,
  RegisterSchema,
} from "../../schemas/auth-schemas";
import LoginForm from "./LoginForm";
import RecoverForm from "./RecoverForm";
import RegisterForm from "./RegisterForm";
import { AUTH_METHODS } from "../../constants";
import useTheme from "@mui/material/styles/useTheme";
import "./tailwind.css";

const EmailLoginDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const [formType, setFormType] = useState("login"); // login, register, recover
  const [errors, setErrors] = useState({});
  const { checkAuth } = useContext(UserContext);

  const handleSuccess = (message) => {
    alert(message); // Display the message to the user
    checkAuth();
    onClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());

    const schemaMap = {
      login: LoginSchema,
      register: RegisterSchema,
      recover: RecoverSchema,
    };

    const urlMap = {
      login: `${process.env.REACT_APP_API_URL}/auth/email/login`,
      register: `${process.env.REACT_APP_API_URL}/auth/email/register`,
      recover: `${process.env.REACT_APP_API_URL}/auth/email/recover`,
    };

    try {
      // Validate form data
      const schema = schemaMap[formType];
      schema.parse(formJson);

      // If valid, proceed with submission
      const url = urlMap[formType];
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formJson),
      });

      if (!response.ok) {
        throw new Error(
          response.status === 401
            ? "Unauthorized: Incorrect username or password"
            : `HTTP error! status: ${response.status}`
        );
      }

      const data = await response.json();
      if (data.error) {
        setErrors({
          general:
            data.message || "An error occurred while processing your request.",
        });
      } else {
        handleSuccess(
          `${formType.charAt(0).toUpperCase() + formType.slice(1)} successful`
        );
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        const fieldErrors = error.flatten().fieldErrors;
        setErrors(fieldErrors);
      } else {
        setErrors({ general: error.message });
      }
    }
  };

  const handleProviderClick = (authUrl) => {
    window.location.href = authUrl;
  };

  const googleSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      viewBox="0 0 48 48"
      width="24"
      height="24"
    >
      <path
        fill="#fbc02d"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      ></path>
      <path
        fill="#e53935"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      ></path>
      <path
        fill="#4caf50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      ></path>
      <path
        fill="#1565c0"
        d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      ></path>
    </svg>
  );

  const twitterSvg = (
    <svg className="icon" viewBox="0 0 24 24" width="24" height="24">
      <path d="M22.46 6c-.77.35-1.6.58-2.47.69a4.29 4.29 0 0 0 1.88-2.38c-.8.47-1.7.8-2.65.98a4.28 4.28 0 0 0-7.3 3.9 12.13 12.13 0 0 1-8.8-4.46 4.28 4.28 0 0 0 1.32 5.7 4.28 4.28 0 0 1-1.94-.54v.05c0 2 .95 3.67 2.39 4.7a4.28 4.28 0 0 1-1.93.07 4.29 4.29 0 0 0 4 2.98A8.58 8.58 0 0 1 2 18.54a12.1 12.1 0 0 0 6.56 1.93c7.88 0 12.2-6.54 12.2-12.2 0-.19 0-.38-.01-.57A8.65 8.65 0 0 0 24 5.64a8.64 8.64 0 0 1-2.54.7z" />
    </svg>
  );

  const appleSvg = (
    <svg className="icon" viewBox="0 0 24 24" width="24" height="24">
      <path d="M18.84 13.08c-.04-3.22 2.64-4.79 2.75-4.86-1.51-2.22-3.84-2.52-4.66-2.56-1.98-.21-3.85 1.17-4.85 1.17s-2.54-1.15-4.17-1.12c-2.15.03-4.15 1.24-5.26 3.16-2.2 3.8-.56 9.41 1.57 12.48 1.04 1.53 2.27 3.24 3.89 3.17 1.58-.07 2.18-1.03 4.1-1.03 1.91 0 2.47 1.03 4.18 1 1.74-.03 2.85-1.54 3.88-3.09 1.1-1.57 1.56-3.1 1.59-3.19-.04-.02-3.05-1.18-3.09-4.66zM16.5 2.64c.85-1.03 1.43-2.45 1.27-3.87-1.23.05-2.72.82-3.59 1.85-.78.91-1.48 2.36-1.29 3.76 1.35.1 2.76-.68 3.61-1.74z" />
    </svg>
  );

  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
      <div
        className={`bg-white ${
          isDarkMode ? "dark:bg-gray-800" : ""
        } p-8 rounded-lg shadow-lg max-w-md w-full relative`}
      >
        <div
          className={`absolute top-4 right-4 cursor-pointer text-gray-500 ${
            isDarkMode ? "dark:text-gray-300" : ""
          }`}
          onClick={onClose}
        >
          &times;
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <h1
            className={`text-2xl font-bold text-center text-gray-900 ${
              isDarkMode ? "dark:text-gray-100" : ""
            }`}
          >
            {formType === "login" && "Log In"}
            {formType === "register" && "Register a New Account"}
            {formType === "recover" && "Recover Your Password"}
          </h1>

          <div className="space-y-4">
            {formType === "login" && <LoginForm errors={errors} />}
            {formType === "register" && <RegisterForm errors={errors} />}
            {formType === "recover" && <RecoverForm errors={errors} />}
          </div>

          <div className="flex justify-between items-center">
            <button
              type="button"
              onClick={onClose}
              className={`px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-lg hover:bg-gray-700 ${
                isDarkMode ? "dark:bg-gray-700 dark:hover:bg-gray-600" : ""
              } focus:outline-none focus:ring-2 ${
                isDarkMode
                  ? "focus:ring-gray-500 dark:focus:ring-gray-400"
                  : "focus:ring-gray-500"
              }`}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full py-2 text-sm font-medium text-white rounded-lg"
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              {formType === "login" && "Sign In"}
              {formType === "register" && "Register"}
              {formType === "recover" && "Recover Password"}
            </button>
          </div>

          <div className="flex justify-center space-x-4 mt-4">
            {formType !== "login" && (
              <button
                type="button"
                onClick={() => setFormType("login")}
                className="text-sm font-medium hover:underline focus:outline-none"
                style={{ color: theme.palette.primary.main }}
              >
                Sign In
              </button>
            )}
            {formType !== "register" && (
              <button
                type="button"
                onClick={() => setFormType("register")}
                className={`text-sm font-medium hover:underline focus:outline-none bg-gray-200 text-gray-900`}
              >
                Register
              </button>
            )}
            {formType !== "recover" && (
              <button
                type="button"
                onClick={() => setFormType("recover")}
                className="text-sm font-medium hover:underline focus:outline-none bg-gray-200 text-gray-900"
              >
                Recover Password
              </button>
            )}
          </div>

          <div className="mt-6">
            <div className="flex justify-center space-x-4 mt-4">
              {AUTH_METHODS.OPTIONS.filter(
                (option) => option.provider !== "email"
              ).map((option) => (
                <button
                  key={option.provider}
                  type="button"
                  onClick={() => handleProviderClick(option.authUrl)}
                  className="flex items-center px-4 py-2 border rounded-lg bg-gray-200"
                  style={{
                    borderColor: theme.palette.divider,
                  }}
                >
                  <span className="w-5 h-5 mr-2">
                    {option.provider === "google" && googleSvg}
                    {/*                     {option.provider === "twitter" && twitterSvg}
                    {option.provider === "apple" && appleSvg} */}
                  </span>
                  <span className={`text-sm text-gray-900`}>
                    {option.label}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailLoginDialog;
