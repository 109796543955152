import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import { UserProvider } from "./contexts/UserContext";
import { AppProvider } from "./contexts/AppContext";

window.addEventListener("error", (event) => {
  console.error(event.message, event);
});

window.addEventListener("unhandledrejection", (event) => {
  console.error(event.message, event);
});

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <UserProvider>
    <AppProvider>
      <App tab="home" />
    </AppProvider>
  </UserProvider>
);
