import React, { useState, useEffect } from 'react';
import { toast, ToastContainer, Zoom, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './game.module.scss';

const GolfTrivia = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [shots, setShots] = useState(1); // Start at 1 instead of 0
  const [result, setResult] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [correctAnswerRevealed, setCorrectAnswerRevealed] = useState(false);
  const [dailyScores, setDailyScores] = useState(() => JSON.parse(localStorage.getItem('dailyScores')) || {});
  const [optionsDisabled, setOptionsDisabled] = useState(false);

  const today = new Date().getDate();

  useEffect(() => {
    const fetchTrivia = async () => {
      const fetchedQuestions = [
        {
          question: "Which golfer won the 2016 Open Championship, known for an intense final-round battle at Royal Troon?",
          options: ["Henrik Stenson", "Phil Mickelson", "Jordan Spieth", "Rory McIlroy"],
          correct: 0,
        },
        {
          question: "Which golfer secured his first major victory at the 2021 U.S. Open at Torrey Pines?",
          options: ["Collin Morikawa", "Jon Rahm", "Xander Schauffele", "Bryson DeChambeau"],
          correct: 1,
        },
        {
          question: "In which year did Jordan Spieth win both the Masters and U.S. Open, becoming the youngest U.S. Open champion since 1923?",
          options: ["2014", "2015", "2016", "2017"],
          correct: 1,
        },
      ];
      setQuestions(fetchedQuestions);
    };

    fetchTrivia();
  }, []);

  useEffect(() => {
    if (timeLeft > 0 && !gameOver) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0 && !gameOver) {
      handleTimeout();
    }
  }, [timeLeft, gameOver]);

  const handleAnswer = (index) => {
    if (gameOver || selectedOptions.includes(index) || optionsDisabled) return;

    setOptionsDisabled(true);
    setShots((prevShots) => prevShots + 1);

    if (index === questions[currentQuestionIndex].correct) {
      toast.success("Correct! 🎉", {
        position: "top-center",
        autoClose: 2000,
        transition: Slide,
      });
      setSelectedOptions([index]);
      setCorrectAnswerRevealed(true);
      setTimeout(() => {
        moveToNextQuestion();
      }, 1500);
    } else {
      setSelectedOptions([...selectedOptions, index]);
      let message = "Incorrect! 😓 Try Again!";
      
      // Custom notifications based on number of incorrect attempts
      if (shots === 1) {
        message = "You're in the First Cut of Rough! 🌿";
      } else if (shots === 2) {
        message = "You're in the Second Cut of Rough! 🟩";
      } else if (shots === 3) {
        message = "You hit it out of bounds! 🏖️";
      } else if (shots >= 4) {
        message = "You're in Trouble! 😨";
      }

      toast.error(message, {
        position: "top-center",
        autoClose: 2000,
        transition: Slide,
      });
      
      setTimeLeft((prevTime) => prevTime + 10);
      setOptionsDisabled(false);
    }
  };

  const handleTimeout = () => {
    setShots((prevShots) => prevShots + 4); // Increment shots on timeout
    moveToNextQuestion();
  };

  const moveToNextQuestion = () => {
    setSelectedOptions([]);
    setCorrectAnswerRevealed(false);
    setOptionsDisabled(false);
    if (currentQuestionIndex === questions.length - 1) {
      endGame();
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimeLeft(30);
    }
  };

  const endGame = () => {
    let resultMessage = '';
    const finalShots = shots; // Use shots directly since it starts from 1

    if (finalShots === 3) {
      resultMessage = 'Eagle 🦅';
    } else if (finalShots === 4) {
      resultMessage = 'Birdie 🐦';
    } else if (finalShots === 5) {
      resultMessage = 'Par ⛳';
    } else if (finalShots === 6) {
      resultMessage = 'Bogey 😅';
    } else if (finalShots === 7) {
      resultMessage = 'Double Bogey 😢';
    } else if (finalShots === 8) {
      resultMessage = 'Triple Bogey 😭';
    } else if (finalShots > 8) {
      resultMessage = 'Missed the Cut ❌';
    }

    // Save today's score
    const updatedScores = {
      ...dailyScores,
      [today]: finalShots,  // Save the actual shot count
    };

    setDailyScores(updatedScores);
    localStorage.setItem('dailyScores', JSON.stringify(updatedScores));

    setResult(resultMessage);
    setGameOver(true);
    toast.info(`You got a ${resultMessage}`, {
      position: "top-center",
      autoClose: 2000,
      transition: Zoom,
    });
  };

  const shareResult = () => {
    const shareText = `I played today's Par 5 Trivia and scored a ${result} on ${new Date().toLocaleDateString()}! Play here: [link]`;
    navigator.clipboard.writeText(shareText);
    toast.info("Result copied to clipboard!", {
      position: "top-center",
      autoClose: 2000,
      transition: Zoom,
    });
  };
  const renderShotTracker = () => {
    const displayedShots = gameOver ? shots - 1 : shots; // Subtract 1 if the game is over
    const baseShotsArray = [1, 2, 3, 4, 5]; // Always show 1, 2, 3, 4, 5
    const shotsArray = displayedShots > 5 ? [...baseShotsArray, displayedShots] : baseShotsArray;
  
    return (
      <div className={styles.shotTracker}>
        <div className={styles.holeNumber}>
          {`Hole ${today}`} {/* Display the current day as hole number */}
        </div>
        <div className={styles.shotLabel}>
          {`Shot Number ${displayedShots}`} {/* Display the adjusted shot number */}
        </div>
        {shotsArray.map((shot, index) => (
          <span
            key={index}
            className={`${styles.shotIcon} ${shot === displayedShots ? styles.currentShot : ''}`}
          >
            {shot}
          </span>
        ))}
      </div>
    );
  };const renderScorecard = () => {
    const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    const totalPar = daysInMonth * 5; // Assuming par 5 for each hole
    
    // Calculate total score, including max scores for missed days
    const totalScore = [...Array(today)].reduce((acc, _, day) => {
      const score = dailyScores[day + 1] || 10; // Use max score (10) if the day is missed
      return acc + (parseInt(score) || 0);
    }, 0);
  
    return (
      <div className={styles.scorecard}>
        <div className={styles.headerRow}>
          <div className={styles.label}>August Scores</div>
          {[...Array(daysInMonth)].map((_, i) => (
            <div key={i + 1} className={styles.holeNumber}>{i + 1}</div>
          ))}
          <div className={styles.totalValue}>Totals</div> {/* Add a totals label at the end */}
        </div>
        <div className={styles.parRow}>
          <div className={styles.label}>Par</div>
          {[...Array(daysInMonth)].map((_, i) => (
            <div key={i + 1} className={styles.parValue}>5</div>
          ))}
          <div className={styles.totalValue}>{totalPar}</div> {/* Display total par */}
        </div>
        <div className={styles.scoreRow}>
          <div className={styles.label}>Score</div>
          {[...Array(daysInMonth)].map((_, i) => {
            const day = i + 1;
            const score = dailyScores[day] || (day < today ? '10' : '-'); // Max for past days not played, nothing for future days
            return (
              <div key={day} className={`${styles.scoreValue} ${getScoreClass(score)}`}>
                {score}
              </div>
            );
          })}
          <div className={styles.totalValue}>{totalScore}</div> {/* Display total score */}
        </div>
      </div>
    );
  };
  

  const getScoreClass = (score) => {
    if (score === 3) return styles.eagle;
    if (score === 4) return styles.birdie;
    if (score === 5) return styles.par;
    if (score === 6) return styles.bogey;
    if (score === 7) return styles.doubleBogey;
    if (score === 8) return styles.tripleBogey;
    if (score === '10') return styles.max; // Max for missed days
    return '';
  };

  return (
    <div className={styles.golfTrivia}>
      <div className={styles.header}>
        <h1>Par 5 Trivia</h1>
        {!gameOver && (
          <div className={styles.timer}>⏳ Time Left: {timeLeft} seconds</div>
        )}
        <div className={styles.shotTracker}>
          {renderShotTracker()}
        </div>
      </div>
      <ToastContainer limit={2} />
      {!gameOver ? (
        <div className={styles.questionSection}>
          <h2>Question {currentQuestionIndex + 1}</h2>
          <h3>{questions[currentQuestionIndex]?.question}</h3>
          <div className={styles.options}>
            {questions[currentQuestionIndex]?.options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswer(index)}
                className={`${styles.optionButton} ${
                  selectedOptions.includes(index) ? (index === questions[currentQuestionIndex].correct && correctAnswerRevealed ? styles.correct : styles.incorrect) : ""
                }`}
                disabled={selectedOptions.includes(index) || optionsDisabled}
              >
                {option}
              </button>
            ))}
          </div>
          <div className={styles.shotCounter}>
          </div>
        </div>
      ) : (
        <div className={styles.resultSection}>
          <h2>{new Date().toLocaleDateString()} Score: {result}</h2>
          <button onClick={shareResult}>Share</button>
          <button onClick={() => window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(`I scored a ${result} on today's Par 5 Trivia!`), '_blank')}>Tweet</button>
          {renderScorecard()}
        </div>
      )}
    </div>
  );
};

export default GolfTrivia;
