import { memo } from "react";
import propsTypes from "prop-types";
import { Button } from "@mui/material";

const Component = ({
  isMyTurn,
  winner,
  onRequestDraw,
  onMakeMove,
  onForfeit,
}) => {
  const COMMON_PROPS = {
    variant: "contained",
    size: "small",
    style: {
      borderRadius: 15,
      height: 30,
      marginBottom: 10,
      fontSize: "11px",
    },
  };

  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-around",
        flexFlow: "row wrap",
        alignItems: "center",
        maxWidth: "400px",
        margin: "auto",
        marginTop: 40,
      }}
    >
      {winner === null && (
        <Button
          {...COMMON_PROPS}
          onClick={() => onMakeMove(-1, {})}
          disabled={!isMyTurn}
        >
          ⏭️ Skip Turn
        </Button>
      )}
      <Button {...COMMON_PROPS} onClick={onRequestDraw}>
        🚪 Request Draw
      </Button>
      <Button {...COMMON_PROPS} onClick={onForfeit} disabled={!isMyTurn}>
        <b>🚪 Give up</b>
      </Button>
    </div>
  );
};

Component.propTypes = {
  isMyTurn: propsTypes.bool,
  winner: propsTypes.string,
  onRequestDraw: propsTypes.func,
  onMakeMove: propsTypes.func,
  onForfeit: propsTypes.func,
};

export const GameControls = memo(Component);
