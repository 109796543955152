import basketballAnimation from "../assets/lottie/basketball.json";
import footballAnimation from "../assets/lottie/football.json";
import baseballAnimation from "../assets/lottie/baseball.json";

const supportedSports = [
  {
    id: 1,
    name: "Basketball",
    emoji: "🏀",
    subtext: "Hit the hardwood for NBA Tic-Tac-Toe",
    animation: basketballAnimation,
  },
  {
    id: 7,
    name: "Football",
    emoji: "🏈",
    subtext: "Hit the gridiron for NFL Tic-Tac-Toe",
    animation: footballAnimation,
  },
  {
    id: 5,
    name: "Baseball",
    emoji: "⚾",
    subtext: "Step up to the plate for MLB Tic-Tac-Toe",
    animation: baseballAnimation,
  },
  {
    id: 3,
    name: "Hockey",
    emoji: "🏒",
    subtext: "Hit the ice for NHL Tic-Tac-Toe",
    animation: baseballAnimation,
  },
  // {
  //   id: 22,
  //   name: "Movies",
  //   emoji: "🎬",
  //   subtext: "Lights, camera, action for Movie Tic-Tac-Toe",
  //   animation: baseballAnimation,
  // },
  {
    id: 21,
    name: "Pokémon",
    emoji: "🐾",
    subtext: "Catch 'em all in Pokémon Tic-Tac-Toe",
    animation: baseballAnimation,
  },
];

export default supportedSports;
