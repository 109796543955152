import React, { useEffect, useRef, useState } from 'react';

import playerData from './NHLMEGLADON.json';
import playerSchedule from './playerSchedule3.json'; // Import the schedule
import styles from './GuessThePlayer.module.css';
import { Checkbox, Container, Dialog, FormControlLabel, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import sports from "../../sports";
import crossover_logo from "../../assets/nhl.svg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HomeIcon from '@mui/icons-material/Home';

import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from "@mui/material/IconButton";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FilterListIcon from '@mui/icons-material/FilterList';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment-timezone';


const GuessThePlayer = () => {
    const [mode, setMode] = useState('Daily'); // Mode can be 'Daily' or 'Unlimited'
    const [currentPlayer, setCurrentPlayer] = useState(null);
    const [guessesLeft, setGuessesLeft] = useState(3);
    const [userGuess, setUserGuess] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [currentDate, setCurrentDate] = useState(moment().tz('America/New_York').format('YYYY-MM-DD'));
    const [playedToday, setPlayedToday] = useState(localStorage.getItem(currentDate) === 'played');
    const [hintsGiven, setHintsGiven] = useState([]);
    const [page_name, setPageName] = useState(""); // Define page_name state
    const [sport_id, setSportId] = useState(""); // Define sport_id state
    const currentSport = sports.find((sport) => sport.id === sport_id.toString());
    const [selectedTeams, setSelectedTeams] = useState(new Set());
    const [currentPlayerId, setCurrentPlayerId] = useState(null);
    const hasDraftDetails = currentPlayer && (currentPlayer.Round || currentPlayer.Pick || currentPlayer.DrftYr);
// Additional state within the GuessThePlayer component
const [leaderboard, setLeaderboard] = useState([]);
const [showLeaderboardModal, setShowLeaderboardModal] = useState(false);
const [showNoPlayersModal, setShowNoPlayersModal] = useState(false);

const [playerIDInput, setPlayerIDInput] = useState(localStorage.getItem('playerID') || '');
const [seenPlayerIDs, setSeenPlayerIDs] = useState(new Set());
const [outOfPlayers, setOutOfPlayers] = useState(false);
const [showEditPlayerIDModal, setShowEditPlayerIDModal] = useState(false);
const [showStreakModal, setShowStreakModal] = useState(false);
const [selectedAwards, setSelectedAwards] = useState(new Set());
const [tempSelectedAwards, setTempSelectedAwards] = useState(new Set(selectedAwards));
const awards  = ["Hart_Memorial", "Lady_Byng", "Vezina", "Calder", "Art_Ross", "Norris_Memorial", "Conn_Smythe", "Masterton_Memorial", "Ted_Lindsay", "Frank_Selke", "Jennings", "Clancy_Memorial", "Maurice_Richard", "Mark_Messier"]
; // Add all relevant awards

const toggleEditPlayerIDModal = () => {
    setShowEditPlayerIDModal(prev => !prev);
};
const EditPlayerIDModal = ({ open, onClose }) => {
    const [tempPlayerID, setTempPlayerID] = useState(playerIDInput);

    const handleSave = () => {
        savePlayerID(tempPlayerID);
        onClose();  // Close modal after save
    };

    useEffect(() => {
        if(open) {
            setTempPlayerID(playerIDInput);  // Reset local state when modal opens
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <div style={{ padding: 20 }}>
                <h2>Edit Player ID</h2>
                <TextField
                    fullWidth
                    label="Player ID"
                    value={tempPlayerID}
                    onChange={(e) => setTempPlayerID(e.target.value)}
                    margin="normal"
                />
                <Button onClick={handleSave} color="primary">Save</Button>
                <Button onClick={onClose} color="secondary">Cancel</Button>
            </div>
        </Dialog>
    );
};


    const [selectedPositions, setSelectedPositions] = useState(new Set());
    const [isActiveOnly, setIsActiveOnly] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [tempSelectedPositions, setTempSelectedPositions] = useState(new Set());
    const [tempSelectedTeams, setTempSelectedTeams] = useState(new Set());
    const [draftStatus, setDraftStatus] = useState('both'); // drafted, undrafted, both
    const [minGames, setMinGames] = useState(0); // Minimum number of games played
    const [tempDraftStatus, setTempDraftStatus] = useState('both');
    const [tempMinGames, setTempMinGames] = useState(0);
    
    const [tempIsActiveOnly, setTempIsActiveOnly] = useState(false);
    // Track different statistics for each game mode
    const [homeTotalGames, setHomeTotalGames] = useState(parseInt(localStorage.getItem('homeTotalGames') || 0));
    const [homeTotalWins, setHomeTotalWins] = useState(parseInt(localStorage.getItem('homeTotalWins') || 0));
    const [homeWinStreak, setHomeWinStreak] = useState(parseInt(localStorage.getItem('homeWinStreak') || 0));
    const [unlimitedTotalGames, setUnlimitedTotalGames] = useState(parseInt(localStorage.getItem('unlimitedTotalGames') || 0));
    const [unlimitedTotalWins, setUnlimitedTotalWins] = useState(parseInt(localStorage.getItem('unlimitedTotalWins') || 0));
    const [unlimitedWinStreak, setUnlimitedWinStreak] = useState(parseInt(localStorage.getItem('unlimitedWinStreak') || 0));
    const [homeHighestStreak, setHomeHighestStreak] = useState(parseInt(localStorage.getItem('homeHighestStreak') || 0));
const [unlimitedHighestStreak, setUnlimitedHighestStreak] = useState(parseInt(localStorage.getItem('unlimitedHighestStreak') || 0));
const [isDarkMode, setIsDarkMode] = useState(false);
const teams = [
   
    "ANA",
    "ARI",
    "ATF",
    "ATL",
    "BOS",
    "BUF",
    "CAR",
    "CBH",
    "CBJ",
    "CGS",
    "CGY",
    "CHI",
    "CLE",
    "CLR",
    "COL",
    "DAL",
    "DET",
    "EDM",
    "FLA",
    "HAR",
    "KCS",
    "LAK",
    "MDA",
    "MIN",
    "MNS",
    "MTL",
    "NJD",
    "NSH",
    "NYI",
    "NYR",
    "OTT",
    "PHI",
    "PHX",
    "PIT",
    "QUE",
    "SEA",
    "SJS",
    "STL",
    "TBL",
    "TOR",
    "VAN",
    "VEG",
    "WIN",
    "WPG",
    "WSH"

];
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(0);  // Assume you handle setting this based on the response
const [lastVisibleId, setLastVisibleId] = useState(null); // to manage cursor for pagination
const [hasNextPage, setHasNextPage] = useState(true);

useEffect(() => {
    const filters = {
      teams: [...selectedTeams],
      positions: [...selectedPositions],
      isActiveOnly,
      draftStatus,
      minGames,
      awards: [...selectedAwards], // Include selected awards in stored filters
    };
    localStorage.setItem('filters', JSON.stringify(filters));
  }, [selectedTeams, selectedPositions, isActiveOnly, draftStatus, minGames, selectedAwards]); // Add selectedAwards as a dependency

  useEffect(() => {
    const loadedFilters = JSON.parse(localStorage.getItem('filters')) || {};
    setSelectedTeams(new Set(loadedFilters.teams || []));
    setSelectedPositions(new Set(loadedFilters.positions || []));
    setIsActiveOnly(loadedFilters.isActiveOnly || 'both');
    setDraftStatus(loadedFilters.draftStatus || 'both');
    setMinGames(loadedFilters.minGames || 0);
    setSelectedAwards(new Set(loadedFilters.awards || [])); // Load selected awards from stored filters
  }, []); // No dependencies to trigger this effect initially

  const fetchLeaderboard = async (next = false) => {
    let nextPage = next ? currentPage + 1 : 1; // Increment or reset to first page
    let url = `https://us-central1-crossover-grid-511a4.cloudfunctions.net/api/getLeaderboard?page=${nextPage}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (response.ok) {
            if (next && data.leaderboard.length > 0) {
                setLeaderboard(prevLeaderboard => [...prevLeaderboard, ...data.leaderboard]);
                setCurrentPage(nextPage);
            } else if (!next) {
                setLeaderboard(data.leaderboard);
                setCurrentPage(1);
            }
            setHasNextPage(data.hasNextPage); // Update hasNextPage state based on the API response
        } else {
            toast.error(`Error: ${data.message || 'Failed to fetch leaderboard'}`);
            setLeaderboard([]);
            setHasNextPage(false); // Assume no more pages if an error occurs
        }
    } catch (error) {
        toast.error(`Network error: ${error.toString()}`);
        setHasNextPage(false); // Assume no more pages if a network error occurs
    }
};



const [showHowToPlay, setShowHowToPlay] = useState(false);

const toggleHowToPlayModal = () => {
    setShowHowToPlay(prev => !prev);
};

const HowToPlayModal = ({ open, onClose }) => (
    <Dialog open={open} onClose={onClose}>
        <div style={{ padding: 20 }}>
            <h2>📚 How to Play</h2>
            <p>Ready to test your player knowledge? Here's what you need to do:</p>
            <ul>
                <li><strong>🚀 Pick a mode:</strong> Choose between 'Daily' or 'Unlimited' to start playing.</li>
                <li><strong>🛠 Set your filters:</strong> Fine-tune the list of players by selecting specific teams, positions, or statuses to make your players more manageable.</li>
                <li><strong>🔍 Make your guess:</strong> Type the name of who you think it is into the input field and see if you're right.</li>
                <li><strong>💡 Use hints:</strong> If your first guess isn’t quite right, we’ll give you some hints like position and draft round to help steer you in the right direction.</li>
                <li><strong>🏆 Keep your streak alive:</strong> Guess as many as you can in a row. Also, don’t forget to save your username to compete on the leaderboard where your highest streak will appear!</li>
            </ul>
            <Button onClick={onClose} color="primary">Got it, let's play!</Button>
        </div>
    </Dialog>
);



const openLeaderboardModal = () => {
    fetchLeaderboard(); // Fetch with the initial page
    setShowLeaderboardModal(true);
};

const handleNextPage = () => {
    fetchLeaderboard(true); // Fetch next page
};


const closeLeaderboardModal = () => {
    setShowLeaderboardModal(false);
};


const handlePreviousPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
        setCurrentPage(prevPage);
        fetchLeaderboard(prevPage);
    }
};

const createMarkup = (htmlContent) => {
  return { __html: htmlContent };
};
const resetFiltersToDefault = () => {
    setSelectedTeams(new Set());
    setSelectedPositions(new Set());
    setIsActiveOnly('both');
    setDraftStatus('both');
    setMinGames(0);
    // Save these defaults back to localStorage
    localStorage.setItem('filters', JSON.stringify({
      teams: [],
      positions: [],
      isActiveOnly: 'both',
      draftStatus: 'both',
      minGames: 0,
    }));
  };
  
const savePlayerID = (newPlayerID) => {
    if (leaderboard.some(item => item.playerID.toString() === newPlayerID.toString()) || newPlayerID.trim() !== '') {
        setPlayerIDInput(newPlayerID);  // Update the global state
        localStorage.setItem('playerID', newPlayerID);
        alert('Player ID saved!');
    } else {
        alert('Player ID does not exist in the leaderboard!');
    }
};


const LeaderboardModal = ({ open, onClose, leaderboard, fetchMoreLeaderboard }) => {
    const [isLoading, setIsLoading] = useState(false);
    const loaderRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            // Ensure that the isIntersecting property is checked properly
            if (entries[0].isIntersecting && !isLoading) { // Also check isLoading to avoid duplicate calls
                setIsLoading(true); // Set loading state to true
                fetchLeaderboard(true).then(() => setIsLoading(false)); // Set loading state to false after fetching
            }
        }, { threshold: 1.0 });
    
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }
    
        return () => observer.disconnect();
    }, [loaderRef.current, isLoading]); // Include isLoading in the dependency array
    
    return (
        <Modal open={open} onClose={() => {
            onClose();
            setIsLoading(false); // Reset loading state on close
        }}>
            <div className={styles.leaderboardModalContainer}>
                <h2>Leaderboard</h2>
                <div className={styles.leaderboardModalContent}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Player</TableCell>
                                <TableCell align="right">Longest Streak</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leaderboard.map((row, index) => (
                                <TableRow key={index}>
                                <TableCell>{row.playerID}</TableCell>
                                    <TableCell align="right">{row.score}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {isLoading && (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <CircularProgress />
                        </div>
                    )}
              {hasNextPage && (
                <Button 
                    onClick={() => fetchLeaderboard(true)} 
                    variant="contained" 
                    color="primary" 
                    disabled={isLoading}
                    fullWidth
                >
                    Load More Scores
                </Button>
            )}
            
               
        
                </div>
                <Button onClick={onClose} color="secondary">Close</Button>
            </div>
        </Modal>
    );
    
    
};
const alumniLogos = 
{
    "Kitchener Rangers": "https://upload.wikimedia.org/wikipedia/en/thumb/3/34/Kitchener_Rangers_logo.svg/1200px-Kitchener_Rangers_logo.svg.png",
    "Ohio State": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Ohio_State_Buckeyes_logo.svg/1200px-Ohio_State_Buckeyes_logo.svg.png",
    "Portland Winterhawks": "https://upload.wikimedia.org/wikipedia/en/thumb/a/a6/Portland_Winterhawks_logo_2021.svg/1200px-Portland_Winterhawks_logo_2021.svg.png",
    "Maine": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Maine_Black_Bears_script.svg/640px-Maine_Black_Bears_script.svg.png",
    "Michigan State": "https://msuspartans.com/images/logos/site/site.png",
    "Minnesota": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Minnesota_Golden_Gophers_logo.svg/1200px-Minnesota_Golden_Gophers_logo.svg.png",
    "Cornell": "https://upload.wikimedia.org/wikipedia/en/thumb/8/88/Cornell_Big_Red.svg/1200px-Cornell_Big_Red.svg.png",
    "Vermont": "https://upload.wikimedia.org/wikipedia/en/thumb/3/34/Vermont_Catamounts_logo.svg/800px-Vermont_Catamounts_logo.svg.png",
    "Wisconsin": "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Wisconsin_Badgers_logo_hockey_red.svg/640px-Wisconsin_Badgers_logo_hockey_red.svg.png",
    "Lake Superior State": "https://upload.wikimedia.org/wikipedia/en/thumb/6/6c/Lake_Superior_State_University_Lakers_Hockey.svg/1200px-Lake_Superior_State_University_Lakers_Hockey.svg.png",
    "London Knights": "https://upload.wikimedia.org/wikipedia/en/thumb/f/f6/London_Knights_logo.svg/800px-London_Knights_logo.svg.png",
    "Brandon Wheat Kings": "https://upload.wikimedia.org/wikipedia/en/thumb/c/ce/Brandon_Wheat_Kings_logo.svg/1200px-Brandon_Wheat_Kings_logo.svg.png",
    "Bowling Green": "https://upload.wikimedia.org/wikipedia/en/thumb/2/2b/Bowling_Green_Falcons_logo.svg/800px-Bowling_Green_Falcons_logo.svg.png",
    "Michigan": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Michigan_Wolverines_logo.svg/1200px-Michigan_Wolverines_logo.svg.png",
    "Notre Dame": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Notre_Dame_Fighting_Irish_logo.svg/800px-Notre_Dame_Fighting_Irish_logo.svg.png",
    "RPI": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/RPI_Engineers.svg/800px-RPI_Engineers.svg.png",
    "Boston College": "https://sportdesigns.com/team-logos/bc_.png",
    "Edmonton Oil Kings": "https://upload.wikimedia.org/wikipedia/en/thumb/f/ff/Edmonton_Oil_Kings_logo.svg/800px-Edmonton_Oil_Kings_logo.svg.png",
    "Colorado College": "https://upload.wikimedia.org/wikipedia/en/thumb/f/ff/Colorado_College_Tigers_logo.svg/800px-Colorado_College_Tigers_logo.svg.png",
    "Boston University": "https://upload.wikimedia.org/wikipedia/en/1/15/Boston_University_Terriers_logo.svg",
    "Red Deer Rebels": "https://upload.wikimedia.org/wikipedia/en/thumb/a/a0/Red_Deer_Rebels_logo.svg/1200px-Red_Deer_Rebels_logo.svg.png",
    "Peterborough Petes": "https://upload.wikimedia.org/wikipedia/en/thumb/8/8f/Peterborough_Petes_logo.svg/1200px-Peterborough_Petes_logo.svg.png",
    "Moncton Wildcats": "https://upload.wikimedia.org/wikipedia/en/d/d6/Moncton-wildcats-logo-2021.jpg",
    "Guelph Storm": "https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Guelph_Storm_logo.svg/1200px-Guelph_Storm_logo.svg.png",
    "Windsor Spitfires": "https://upload.wikimedia.org/wikipedia/en/thumb/1/1c/Windsor_Spitfires_Logo.svg/1200px-Windsor_Spitfires_Logo.svg.png",
    "Denver": "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Denver_Pioneers_Athletics_logo.svg/1200px-Denver_Pioneers_Athletics_logo.svg.png",
    "Chicoutimi Sagueneens": "https://upload.wikimedia.org/wikipedia/en/thumb/9/9d/Chicoutimi_Saguenéens.svg/800px-Chicoutimi_Saguenéens.svg.png",
    "Victoriaville Tigres": "https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Victoriaville_Tigres.svg/1200px-Victoriaville_Tigres.svg.png",
    "Shawinigan Cataractes": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9knJGOrSWFoUtBX5Tl37kbajtKRXFqSLgdeyEmTQ0yQ&s",
    "Nebraska-Omaha": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Omaha_Mavericks_logo.svg/1200px-Omaha_Mavericks_logo.svg.png",
    "Sudbury Wolves": "https://upload.wikimedia.org/wikipedia/en/thumb/d/db/Sudbury_Wolves_logo.svg/1200px-Sudbury_Wolves_logo.svg.png",
    "Oshawa Generals": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Oshawa_Generals_Logo.svg/1200px-Oshawa_Generals_Logo.svg.png",
    "Ottawa 67's": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Ottawa_67%27s_logo.svg/1200px-Ottawa_67%27s_logo.svg.png",
    "Saskatoon Blades": "https://upload.wikimedia.org/wikipedia/en/thumb/6/65/Saskatoon_Blades_logo.svg/800px-Saskatoon_Blades_logo.svg.png",
    "Kamloops Blazers": "https://upload.wikimedia.org/wikipedia/en/8/82/Kamloops_Blazers_logo.svg",
    "Lethbridge Hurricanes": "https://upload.wikimedia.org/wikipedia/en/thumb/2/27/Lethbridge_Hurricanes_logo.svg/1200px-Lethbridge_Hurricanes_logo.svg.png",
    "Massachusetts": "https://sportdesigns.com/team-logos/uma.png",
    "Miami (Ohio)": "https://upload.wikimedia.org/wikipedia/commons/b/b4/Miami_Redhawks_logo.svg",
    "Barrie Colts": "https://upload.wikimedia.org/wikipedia/en/thumb/1/18/Barrie_Colts_Logo.svg/1200px-Barrie_Colts_Logo.svg.png",
    "Medicine Hat Tigers": "https://upload.wikimedia.org/wikipedia/en/a/af/Medicine_Hat_Tigers_Logo.svg",
    "Tri-City Americans": "https://upload.wikimedia.org/wikipedia/en/4/46/Tri-City_Americans_logo.svg",
    "Brown": "",
    "Colgate": "",
    "Seattle Thunderbirds": "https://upload.wikimedia.org/wikipedia/en/thumb/2/2f/Seattle_Thunderbirds_logo.svg/1200px-Seattle_Thunderbirds_logo.svg.png",
    "Providence": "",
    "Sault Ste. Marie Greyhounds": "https://upload.wikimedia.org/wikipedia/en/thumb/b/bb/Sault_Ste._Marie_Greyhounds_logo.svg/1200px-Sault_Ste._Marie_Greyhounds_logo.svg.png",
    "Union": "",
    "Connecticut": "",
    "Arizona State": "",
    "Everett Silvertips": "https://upload.wikimedia.org/wikipedia/en/thumb/4/46/Everett_Silvertips_logo.svg/1200px-Everett_Silvertips_logo.svg.png",
    "Cape Breton Screaming Eagles": "",
    "Alaska-Fairbanks": "",
    "Northeastern": "",
    "Minnesota-Duluth": "",
    "Owen Sound Attack": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRe1ruOkEnOaPBHmDZyOoUiobINNQDAHkrlmCb07egAdQ&s",
    "Massachusetts-Lowell": "https://upload.wikimedia.org/wikipedia/en/thumb/4/43/UMass_Lowell_River_Hawks_logo.svg/640px-UMass_Lowell_River_Hawks_logo.svg.png",
    "Regina Pats": "https://upload.wikimedia.org/wikipedia/en/d/d6/Regina_Pats_logo.svg",
    "Spokane Chiefs": "",
    "Michigan Tech": "",
    "Calgary Hitmen": "https://upload.wikimedia.org/wikipedia/en/c/c0/Calgary_Hitmen_logo.svg",
    "St. Cloud State": "",
    "Kingston Frontenacs": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Kingston_Frontenacs_Logo.png/150px-Kingston_Frontenacs_Logo.png",
    "Prince George Cougars": "https://upload.wikimedia.org/wikipedia/en/5/55/Prince_George_Cougars_logo_2015.png",
    "Kelowna Rockets": "https://upload.wikimedia.org/wikipedia/en/thumb/8/89/Kelowna_Rockets_logo.svg/1200px-Kelowna_Rockets_logo.svg.png",
    "Baie-Comeau Drakkar": "https://upload.wikimedia.org/wikipedia/en/thumb/3/36/Baie-Comeau_Drakkar.svg/1200px-Baie-Comeau_Drakkar.svg.png",
    "Erie Otters": "https://upload.wikimedia.org/wikipedia/en/thumb/b/be/Erie_Otters_logo.svg/1200px-Erie_Otters_logo.svg.png",
    "Saginaw Spirit": "https://upload.wikimedia.org/wikipedia/en/thumb/9/90/Saginaw_Spirit_Logo.svg/800px-Saginaw_Spirit_Logo.svg.png",
    "Harvard": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Harvard_Crimson_logo_2020.svg/1200px-Harvard_Crimson_logo_2020.svg.png",
    "Halifax Mooseheads": "https://upload.wikimedia.org/wikipedia/en/5/5e/Halifax_Mooseheads_logo_September_2022.png",
    "Gatineau Olympiques": "https://upload.wikimedia.org/wikipedia/en/thumb/d/d2/Gatineau_Olympiques_logo.svg/800px-Gatineau_Olympiques_logo.svg.png",
    "Quebec Remparts": "https://upload.wikimedia.org/wikipedia/en/thumb/b/bf/Quebec_Remparts.svg/1200px-Quebec_Remparts.svg.png",
    "Charlottetown Islanders": "",
    "Sarnia Sting": "",
    "Moose Jaw Warriors": "",
    "North Dakota": "",
    "Drummondville Voltigeurs": "",
    "Prince Albert Raiders": "",
    "New Hampshire": "https://www.hockeyeastonline.com/images/toplogos20/unh.png",
    "Vancouver Giants": "",
    "Clarkson": "",
    "Hamilton Bulldogs": "https://upload.wikimedia.org/wikipedia/en/thumb/5/5c/Hamilton_Bulldogs.svg/1200px-Hamilton_Bulldogs.svg.png",
    "Quinnipiac": "",
    "St. Lawrence": "",
    "Yale": "",
    "North Bay Battalion": "",
    "Acadie-Bathurst Titan": "",
    "Niagara IceDogs": "",
    "Rouyn-Noranda Huskies": "",
    "Northern Michigan": "",
    "Ferris State": "",
    "Saint John Sea Dogs": "",
    "Kootenay Ice": "",
    "Mississauga Steelheads": "",
    "Winnipeg Ice": "",
    "Swift Current Broncos": "https://upload.wikimedia.org/wikipedia/en/thumb/e/e4/Swift_Current_Broncos.svg/1200px-Swift_Current_Broncos.svg.png",
    "Western Michigan": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/Western_Michigan_Broncos_%282021%29_logo.svg/1200px-Western_Michigan_Broncos_%282021%29_logo.svg.png",
    "Penn State": "",
    "Val-d'Or Foreurs": "",
    "Victoria Royals": "",
    "Dartmouth": "",
    "Princeton": "",
    "Minnesota State": "",
    "Flint Firebirds": "",
    "Merrimack": "",
    "Rimouski Oceanic": "https://upload.wikimedia.org/wikipedia/en/2/2f/Rimouski_Oceanic_logo.jpg",
    "Niagara": "",
    "Sherbrooke Phoenix": "",
    "Blainville-Boisbriand Armada": ""
  }
const teamLogos = {
        "ANA": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-anaheim-ducks-logo-768x768.png",
        "ARI": "https://loodibee.com/wp-content/uploads/nhl-arizona-coyotes-logo-768x768.png",
        "ATF": "",
        "ATL": "https://upload.wikimedia.org/wikipedia/en/thumb/0/02/Atlanta_Thrashers.svg/440px-Atlanta_Thrashers.svg.png",
        "BOS": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-boston-bruins-logo-768x768.png",
        "BUF": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-buffalo-sabres-logo-768x768.png",
        "CAR": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-carolina-hurricanes-logo-768x768.png",
        "CBH": "",
        "CBJ": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-columbus-blue-jackets-logo-768x768.png",
        "CGS": "https://upload.wikimedia.org/wikipedia/en/thumb/1/17/California_Golden_Seals_Logo.svg/1200px-California_Golden_Seals_Logo.svg.png",
        "CGY": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-calgary-flames-logo-768x768.png",
        "CHI": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-chicago-blackhawks-logo-768x768.png",
        "CLE": "",
        "CLR": "",
        "COL": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-colorado-avalanche-logo-768x768.png",
        "DAL": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-dallas-stars-logo-768x768.png",
        "DET": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-detroit-red-wings-logo-768x768.png",
        "EDM": "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nhl/500/edm.png&h=200&w=200",
        "FLA": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-florida-panthers-logo-768x768.png",
        "HAR": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Hartford_Whalers_1992.svg/440px-Hartford_Whalers_1992.svg.png",
        "KCS": "",
        "LAK": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-los-angeles-kings-logo-768x768.png",
        "MDA": "",
        "MIN": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-minnesota-wild-logo-768x768.png",
        "MNS": "",
        "MTL": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-montreal-canadiens-logo-768x768.png",
        "NJD": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-new-jersey-devils-logo-768x768.png",
        "NSH": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-nashville-predators-logo-300x300.png",
        "NYI": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-new-york-islanders-logo-768x768.png",
        "NYR": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-new-york-rangers-logo-768x768.png",
        "OTT": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-ottawa-senators-logo-768x768.png",
        "PHI": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-philadelphia-flyers-logo-768x768.png",
        "PHX": "https://loodibee.com/wp-content/uploads/nhl-arizona-coyotes-logo-768x768.png",
        "PIT": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-pittsburgh-penguins-logo-768x768.png",
        "QUE": "",
        "SEA": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-seattle-kraken-logo-768x768.png",
        "SJS": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-san-jose-sharks-logo-768x768.png",
        "STL": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-st-louis-blues-logo-768x768.png",
        "TBL": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-tampa-bay-lightning-logo-768x768.png",
        "TOR": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-toronto-maple-leafs-logo-768x768.png",
        "VAN": "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nhl/500/van.png&h=200&w=200",
        "VEG": "https://loodibee.com/wp-content/uploads/nhl-vegas-golden-knights-logo-768x768.png",
        "WIN": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-winnipeg-jets-logo-768x768.png",
        "WPG": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-winnipeg-jets-logo-768x768.png",
        "WSH": "https://www.nicepng.com/png/full/223-2234913_washington-capitals-logo-washington-capitals-flag.png",
        "Anaheim Ducks": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-anaheim-ducks-logo-768x768.png",
        "ARIZONA COYOTES": "https://loodibee.com/wp-content/uploads/nhl-arizona-coyotes-logo-768x768.png",
        "BOSTON BRUINS": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-boston-bruins-logo-768x768.png",
        "Boston Bruins": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-boston-bruins-logo-768x768.png",
        "BUFFALO SABRES": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-buffalo-sabres-logo-768x768.png",
        "CALGARY FLAMES": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-calgary-flames-logo-768x768.png",
        "CAROLINA HURRICANES": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-carolina-hurricanes-logo-768x768.png",
        "CHICAGO BLACKHAWKS": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-chicago-blackhawks-logo-768x768.png",
        "Colorado Avalanche": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-colorado-avalanche-logo-768x768.png",
        "Columbus Blue Jackets": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-columbus-blue-jackets-logo-768x768.png",
        "Dallas Stars": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-dallas-stars-logo-768x768.png",
        "Detroit Red Wings": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-detroit-red-wings-logo-768x768.png",
        "Edmonton Oilers": "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nhl/500/edm.png&h=200&w=200",
        "Florida Panthers": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-florida-panthers-logo-768x768.png",
        "Los Angeles Kings": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-los-angeles-kings-logo-768x768.png",
        "Minnesota Wild": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-minnesota-wild-logo-768x768.png",
        "Montreal Canadiens": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-montreal-canadiens-logo-768x768.png",
        "Nashville Predators": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-nashville-predators-logo-300x300.png",
        "New Jersey Devils": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-new-jersey-devils-logo-768x768.png",
        "New York Islanders": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-new-york-islanders-logo-768x768.png",
        "New York Rangers": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-new-york-rangers-logo-768x768.png",
        "Ottawa Senators": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-ottawa-senators-logo-768x768.png",
        "Philadelphia Flyers": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-philadelphia-flyers-logo-768x768.png",
        "Pittsburgh Penguins": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-pittsburgh-penguins-logo-768x768.png",
        "San Jose Sharks": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-san-jose-sharks-logo-768x768.png",
        "Seattle Kraken": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-seattle-kraken-logo-768x768.png",
        "St. Louis Blues": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-st-louis-blues-logo-768x768.png",
        "Tampa Bay Lightning": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-tampa-bay-lightning-logo-768x768.png",
        "Toronto Maple Leafs": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-toronto-maple-leafs-logo-768x768.png",
        "Vancouver Canucks": "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nhl/500/van.png&h=200&w=200",
        "Vegas Golden Knights": "https://loodibee.com/wp-content/uploads/nhl-vegas-golden-knights-logo-768x768.png",
        "Washington Capitals": "https://www.nicepng.com/png/full/223-2234913_washington-capitals-logo-washington-capitals-flag.png",
        "Washington Capital": "https://www.nicepng.com/png/full/223-2234913_washington-capitals-logo-washington-capitals-flag.png",
        "Winnipeg Jets": "https://crossover-grid.s3.amazonaws.com/nhl/nhl-winnipeg-jets-logo-768x768.png"
        
};

  const toggleTeam = (team) => {
    // This assumes there is no distinction between temporary and final state,
    // which might not always be appropriate for settings dialogs.
    setTempSelectedTeams(prevTeams => {
        const newTeams = new Set(prevTeams);
        if (newTeams.has(team)) {
            newTeams.delete(team);
        } else {
            newTeams.add(team);
        }
        return newTeams;
    });

    setSelectedTeams(prev => {
        const updatedTeams = new Set(prev);
        if (updatedTeams.has(team)) {
            updatedTeams.delete(team);
        } else {
            updatedTeams.add(team);
        }
        return updatedTeams;
    });
};


useEffect(() => {
  // Check if the user prefers dark mode
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  setIsDarkMode(prefersDarkMode);

  // Listen for changes in the dark mode preference
  const mediaQueryListener = (e) => {
    setIsDarkMode(e.matches);
  };

  window.matchMedia('(prefers-color-scheme: dark)').addListener(mediaQueryListener);

  return () => {
    window.matchMedia('(prefers-color-scheme: dark)').removeListener(mediaQueryListener);
  };
}, []);
useEffect(() => {
  // Apply or remove the dark-mode class based on the isDarkMode state
  if (isDarkMode) {
    document.body.classList.add('dark-mode');
  } else {
    document.body.classList.remove('dark-mode');
  }
}, [isDarkMode]);

    const onAdRefresh = () => {
        window.freestar?.queue.push(function () {
          window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
          window.freestar?.refresh("crossovergrid_sidewall_left");
          window.freestar?.refresh("crossovergrid_sidewall_right");
        });
      };
    
    
    
      // Function to handle year range change and automatically close menu
      useEffect(() => {
        onAdRefresh();  }, []);
      
        useEffect(() => {
            // This effect should only run for changes in currentDate and mode, specifically when in Daily mode.
            if (mode === 'Daily') {
                const playerId = playerSchedule[currentDate];
                if (playerId) {
                    const scheduledPlayer = playerData.find(p => p['-9999'] === playerId);
                    if (scheduledPlayer) {
                        setCurrentPlayer(scheduledPlayer);
                    } else {
                        setCurrentPlayer(playerData[Math.floor(Math.random() * playerData.length)]);
                    }
                } else {
                    setCurrentPlayer(playerData[Math.floor(Math.random() * playerData.length)]);
                }
            }
        }, [currentDate, mode]);
    
        useEffect(() => {
            // This will refresh the currentDate at 7:00 AM EST every day
            const now = moment().tz('America/New_York');
            const tomorrow = now.clone().add(1, 'days').startOf('day');
            const msUntil7AM = tomorrow.add(7, 'hours').diff(now);
    
            const timer = setTimeout(() => {
                setCurrentDate(moment().tz('America/New_York').format('YYYY-MM-DD'));
                setPlayedToday(localStorage.getItem(currentDate) === 'played');
            }, msUntil7AM);
    
            return () => clearTimeout(timer);
        }, [currentDate]);
    
        
    useEffect(() => {
        // Fetch player data on mount or when mode or date changes
        const playerId = playerSchedule[currentDate];
        const scheduledPlayer = playerData.find(p => p['-9999'] === playerId);
        setCurrentPlayer(scheduledPlayer || playerData[Math.floor(Math.random() * playerData.length)]);
        setPlayedToday(localStorage.getItem(currentDate) === 'played');
    }, [mode, currentDate]);
    useEffect(() => {
        localStorage.setItem('homeHighestStreak', homeHighestStreak.toString());
        localStorage.setItem('unlimitedHighestStreak', unlimitedHighestStreak.toString());
    }, [homeHighestStreak, unlimitedHighestStreak]);
    
    const handleModeChange = () => {
        const newMode = mode === 'Daily' ? 'Unlimited' : 'Daily';
        setMode(newMode);
        resetGameState();
    };
    
    const resetGameState = () => {
        setGuessesLeft(3);
        setUserGuess('');
        setSuggestions([]);
        setHintsGiven([]);
        setSeenPlayerIDs(new Set()); // Optionally reset seen IDs on mode change
        fetchPlayer(mode); // Ensure this fetches according to the new mode
    };
    

    const generatePlayerID = () => {
        return 'id-' + Math.random().toString(36).substr(2, 9); // This creates a random alphanumeric string prefixed with 'id-'
    };
    
    const updateWinsAndStreak = () => {
        const updateWins = mode === 'Daily' ? setHomeTotalWins : setUnlimitedTotalWins;
        const updateStreak = mode === 'Daily' ? setHomeWinStreak : setUnlimitedWinStreak;
        const updateHighestStreak = mode === 'Daily' ? setHomeHighestStreak : setUnlimitedHighestStreak;
    
        updateWins(wins => wins + 1);
        updateStreak(streak => {
            const newStreak = streak + 1;
            if (newStreak > (mode === 'Daily' ? homeHighestStreak : unlimitedHighestStreak)) {
                updateHighestStreak(newStreak);
                localStorage.setItem(mode === 'Daily' ? 'homeHighestStreak' : 'unlimitedHighestStreak', newStreak.toString());
            }
            return newStreak;
        });
    };
    
    const resetStreak = async () => {
        const currentStreak = mode === 'Daily' ? homeWinStreak : unlimitedWinStreak;
    
        // Retrieve player ID from storage or generate a new one if not found
        let playerID = localStorage.getItem('playerID');
        if (!playerID) {
            playerID = generatePlayerID();
            localStorage.setItem('playerID', playerID); // Optionally store the newly generated ID back to local storage
        }
    
        // Post streak to leaderboard if a streak exists
        if (currentStreak > 0) {
            await postStreakToLeaderboard(playerID, currentStreak)
                .catch(error => {
                    toast.error('Error updating leaderboard: ' + error.message);
                });
        }
    
        // Reset the streak
        const reset = mode === 'Daily' ? setHomeWinStreak : setUnlimitedWinStreak;
        reset(0);  // Reset the streak
    };
    useEffect(() => {
        const storedMode = localStorage.getItem('gameMode');
        if (storedMode) {
            setMode(storedMode);
        }
    }, []);
    
    useEffect(() => {
        localStorage.setItem('gameMode', mode);
    }, [mode]);
    
    
    const updateGamesPlayed = () => {
        const updateGames = mode === 'Daily' ? setHomeTotalGames : setUnlimitedTotalGames;
        updateGames(games => games + 1);
    };
    const formatFilters = () => {
        const positionFilters = [...selectedPositions].join(', ');
        const teamFilters = [...selectedTeams].map(team => team).join(', ');
        const statusFilter = isActiveOnly === 'both' ? 'Active and Inactive' : isActiveOnly === 'active' ? 'Active' : 'Inactive';
        const draftFilter = draftStatus === 'both' ? 'Drafted and Undrafted' : draftStatus;
        const gamesFilter = `Minimum games: ${minGames}`;
        const awardFilters = [...selectedAwards].join(', '); // Include selected awards in the formatted filters
    
        return `Positions: ${positionFilters || 'All'}, Teams: ${teamFilters || 'All'}, Status: ${statusFilter}, Draft: ${draftFilter}, ${gamesFilter}, Awards: ${awardFilters || 'All'}`; // Include award filters in the returned string
    };
    
    const shareAchievement = (playerCount) => {
        const filtersDescription = formatFilters();
        const message = `I've guessed every available player under the current filters in Guess the Player! Total players guessed: ${playerCount}. Filters used: ${filtersDescription}. Can you match me?`;
        
        // Include social media or clipboard sharing functionality
        // This example uses Twitter sharing
        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
        window.open(tweetUrl, '_blank');
        toast.success('Achievement shared on Twitter!');
    };
    const awardsList = ["Hart_Memorial", "Lady_Byng", "Vezina", "Calder", "Art_Ross", "Norris_Memorial", "Conn_Smythe", "Masterton_Memorial", "Ted_Lindsay", "Frank_Selke", "Jennings", "Clancy_Memorial", "Maurice_Richard", "Mark_Messier"];


<div className={styles.section}>
    <h3>Awards</h3>
    {awardsList.map(award => (
        <FormControlLabel
            control={
                <Checkbox
                    checked={selectedAwards.has(award)}
                    onChange={() => toggleAwardSelection(award)}
                />
            }
            label={award}
            key={award}
        />
    ))}
</div>

    const formatCareerAwards = (player) => {
        const awards = [];
        // New awards with emojis and bolded
if (player.Hart_Memorial && player.Hart_Memorial.length) awards.push(`🏆 <strong>Hart Memorial:</strong> ${player.Hart_Memorial.join(', ')}`);
if (player.Lady_Byng && player.Lady_Byng.length) awards.push(`🎩 <strong>Lady Byng:</strong> ${player.Lady_Byng.join(', ')}`);
if (player.Vezina && player.Vezina.length) awards.push(`🥅 <strong>Vezina:</strong> ${player.Vezina.join(', ')}`);
if (player.Calder && player.Calder.length) awards.push(`🏅 <strong>Calder:</strong> ${player.Calder.join(', ')}`);
if (player.Art_Ross && player.Art_Ross.length) awards.push(`🎨 <strong>Art Ross:</strong> ${player.Art_Ross.join(', ')}`);
if (player.Norris_Memorial && player.Norris_Memorial.length) awards.push(`🛡️ <strong>Norris Memorial:</strong> ${player.Norris_Memorial.join(', ')}`);
if (player.Conn_Smythe && player.Conn_Smythe.length) awards.push(`🏒 <strong>Conn Smythe:</strong> ${player.Conn_Smythe.join(', ')}`);
if (player.Masterton_Memorial && player.Masterton_Memorial.length) awards.push(`🥇 <strong>Masterton Memorial:</strong> ${player.Masterton_Memorial.join(', ')}`);
if (player.Ted_Lindsay && player.Ted_Lindsay.length) awards.push(`🚀 <strong>Ted Lindsay:</strong> ${player.Ted_Lindsay.join(', ')}`);
if (player.Frank_Selke && player.Frank_Selke.length) awards.push(`🔒 <strong>Frank Selke:</strong> ${player.Frank_Selke.join(', ')}`);
if (player.Jennings && player.Jennings.length) awards.push(`🎖️ <strong>Jennings:</strong> ${player.Jennings.join(', ')}`);
if (player.Clancy_Memorial && player.Clancy_Memorial.length) awards.push(`🤠 <strong>Clancy Memorial:</strong> ${player.Clancy_Memorial.join(', ')}`);
if (player.Maurice_Richard && player.Maurice_Richard.length) awards.push(`🚨 <strong>Maurice Richard:</strong> ${player.Maurice_Richard.join(', ')}`);
if (player.Mark_Messier && player.Mark_Messier.length) awards.push(`💪 <strong>Mark Messier:</strong> ${player.Mark_Messier.join(', ')}`);

        
        return awards.map(award => `<p>${award}</p>`).join('');
      };
      const [filteredPlayers, setFilteredPlayers] = useState([]);
      const getFilteredRandomPlayer = () => {
        const players = playerData.filter(player => {
            const matchesPosition = selectedPositions.size === 0 || (player.Pos && player.Pos.some(pos => selectedPositions.has(pos)));
            const matchesTeam = selectedTeams.size === 0 || (player.Team && player.Team.some(team => selectedTeams.has(team)));
            const matchesActiveStatus = isActiveOnly === 'both' || (isActiveOnly === 'active' ? player.Active === "Yes" : player.Active === "No");
            const matchesDraftStatus = draftStatus === 'both' || (draftStatus === 'drafted' ? player.Drafted : !player.Drafted);
            const matchesGameCount = player.GP >= minGames;
            const matchesAwards = Array.from(selectedAwards).every(award => Array.isArray(player[award]) && player[award].length > 0);
            const notSeen = !seenPlayerIDs.has(player['-9999']);
            
            return matchesPosition && matchesTeam && matchesActiveStatus && matchesDraftStatus && matchesGameCount && matchesAwards && notSeen;
        });
    
        setFilteredPlayers(players); // Update the state with the filtered players
        if (players.length === 0) {
            setOutOfPlayers(true);
            return null;
        } else {
            setOutOfPlayers(false);
            return players[Math.floor(Math.random() * players.length)];
        }
    };
    
    
// Define a function to toggle the selection of an award
const toggleAwardSelection = (award) => {
    const updatedAwards = new Set(tempSelectedAwards);
    if (updatedAwards.has(award)) {
        updatedAwards.delete(award);
    } else {
        updatedAwards.add(award);
    }
    setTempSelectedAwards(updatedAwards);
};
    
    useEffect(() => {
        getFilteredRandomPlayer(); // Call this whenever filters change to refresh the list
    }, [selectedPositions, selectedTeams, isActiveOnly, draftStatus, minGames, selectedAwards]); // Include selectedAwards in the dependency array
    
    const checkAndHandleCompletion = () => {
        // Only check for completion in Unlimited mode
        if (mode === 'Unlimited' && seenPlayerIDs.size === filteredPlayers.length && filteredPlayers.length > 0) {
            toast.info("Congratulations! You have guessed every available player under the current filters!", {
                onClose: () => shareAchievement(seenPlayerIDs.size)
            });
            setShowNoPlayersModal(true); // Trigger the modal when all players are guessed.
        }
    };
    

// Call this in an effect hook that watches changes in the necessary dependencies
useEffect(() => {
    if (mode === 'Unlimited') {
        checkAndHandleCompletion();
    }
}, [seenPlayerIDs, filteredPlayers, mode]); // Include 'mode' to react to mode changes
    
const fetchPlayer = (modeToUse) => {
    if (modeToUse === 'Daily') {
        // Fetch the scheduled player
        const playerId = playerSchedule[currentDate];
        const scheduledPlayer = playerData.find(p => p['-9999'] === playerId);
        if (scheduledPlayer) {
            setCurrentPlayer(scheduledPlayer);
        } else {
            setCurrentPlayer(playerData[Math.floor(Math.random() * playerData.length)]);
        }
    } else {
        // 'Unlimited' mode, fetch any random player considering filters if applied
        const randomPlayer = getFilteredRandomPlayer(); // Ensure this function considers all active filters
        if (randomPlayer) {
            setCurrentPlayer(randomPlayer);
        } else {
            setCurrentPlayer(playerData[Math.floor(Math.random() * playerData.length)]);
        }
    }
};

    
const saveSettings = () => {
    // Check if there are changes to save
    const hasChanges = 
        !setsAreEqual(new Set(selectedTeams), new Set(tempSelectedTeams)) ||
        !setsAreEqual(new Set(selectedPositions), new Set(tempSelectedPositions)) ||
        !setsAreEqual(new Set(selectedAwards), new Set(tempSelectedAwards)) || // Check for changes in awards
        isActiveOnly !== tempIsActiveOnly ||
        draftStatus !== tempDraftStatus ||
        minGames !== Number(tempMinGames);

    if (hasChanges) {
        // Update states with new settings
        setSelectedTeams(new Set(tempSelectedTeams));
        setSelectedPositions(new Set(tempSelectedPositions));
        setSelectedAwards(new Set(tempSelectedAwards)); // Update awards state
        setIsActiveOnly(tempIsActiveOnly);
        setDraftStatus(tempDraftStatus);
        setMinGames(Number(tempMinGames));

        // Persist changes to local storage
        localStorage.setItem('selectedTeams', JSON.stringify([...tempSelectedTeams]));
        localStorage.setItem('selectedPositions', JSON.stringify([...tempSelectedPositions]));
        localStorage.setItem('selectedAwards', JSON.stringify([...tempSelectedAwards])); // Save awards
        localStorage.setItem('isActiveOnly', tempIsActiveOnly);
        localStorage.setItem('draftStatus', tempDraftStatus);
        localStorage.setItem('minGames', Number(tempMinGames).toString());

        // Close settings and refresh data
        setSettingsOpen(false);
        fetchPlayer(mode);
        setSeenPlayerIDs(new Set()); // Clear seen player IDs when switching modes
    } else {
        setSettingsOpen(false);
    }
};

    
    function setsAreEqual(setA, setB) {
        if (setA.size !== setB.size) return false;
        for (let a of setA) {
            if (!setB.has(a)) return false;
        }
        return true;
    }
    

// Helper function to compare sets
function setsAreEqual(setA, setB) {
    if (setA.size !== setB.size) return false;
    for (let a of setA) if (!setB.has(a)) return false;
    return true;
}

useEffect(() => {
    fetchPlayer(mode);
}, [mode, currentDate, selectedTeams, selectedPositions, isActiveOnly, draftStatus, minGames, selectedAwards]); // Include all relevant filters as dependencies

useEffect(() => {
    const loadedTeams = JSON.parse(localStorage.getItem('selectedTeams')) || [];
    setSelectedTeams(new Set(loadedTeams));
}, []);

useEffect(() => {
    fetchPlayer(mode);
}, [mode, currentDate]);

useEffect(() => {
    if (mode === 'Unlimited') {
        fetchPlayer('Unlimited');
    }
}, [mode, selectedPositions, isActiveOnly, selectedTeams, selectedAwards]); // Include all relevant filters as dependencies

const togglePositionSelection = (pos) => {
    const newPositions = new Set(tempSelectedPositions);
    if (newPositions.has(pos)) {
        newPositions.delete(pos);
    } else {
        newPositions.add(pos);
    }
    setTempSelectedPositions(newPositions);
};

const handleOpenSettings = (open) => {
    const currentStreak = mode === 'Daily' ? homeWinStreak : unlimitedWinStreak;
    if (currentStreak > 0) {
        alert("You cannot change filters during an active streak!");
        return;
    }
    setSettingsOpen(open);
    // Initialize temporary states with current settings
    setTempSelectedTeams(new Set(selectedTeams));
    setTempSelectedPositions(new Set(selectedPositions));
    setTempSelectedAwards(new Set(selectedAwards)); // Add this line
    setTempIsActiveOnly(isActiveOnly);
    setTempDraftStatus(draftStatus);
    setTempMinGames(minGames);
};
const settingsDialog = (
    <Dialog open={settingsOpen} onClose={() => setSettingsOpen(false)}>
        <div style={{ padding: 20 }}>
            <h2>Player Settings</h2>

            <div className={styles.section}>
                <h3>Positions</h3>
                {["D","F", "C","G","LW","RW"].map(pos => (
                    <FormControlLabel
                        control={<Checkbox checked={tempSelectedPositions.has(pos)} onChange={() => togglePositionSelection(pos)} />}
                        label={pos}
                        key={pos}
                        className={styles.radioLabel} // Apply styles to labels
                    />
                ))}
            </div>

            <div className={styles.section}>
                <h3>Teams</h3>
                <div className={styles.filterRow}>
                    {teams.map((team, index) => (
                        <button key={index} onClick={() => toggleTeam(team)} className={`${styles.filterButton} ${selectedTeams.has(team) ? styles.filterButtonSelected : ''}`}>
                            <img src={teamLogos[team]} alt={team} className={styles.teamLogo2} />
                            {team}
                        </button>
                    ))}
                </div>
            </div>

            <div className={styles.section}>
                <h3>Player Status</h3>
                <FormControl component="fieldset" className={styles.formControl}>
                    <RadioGroup
                        aria-label="player-status"
                        name="player-status"
                        value={tempIsActiveOnly}
                        onChange={(e) => setTempIsActiveOnly(e.target.value)}
                    >
                        <FormControlLabel value="active" control={<Radio />} label="Active" className={styles.radioLabel} />
                        <FormControlLabel value="inactive" control={<Radio />} label="Inactive" className={styles.radioLabel} />
                        <FormControlLabel value="both" control={<Radio />} label="Both" className={styles.radioLabel} />
                    </RadioGroup>
                </FormControl>
            </div>

            <div className={styles.section}>
                <h3>Draft Status</h3>
                <FormControl component="fieldset" className={styles.formControl}>
                    <RadioGroup
                        aria-label="draft-status"
                        name="draft-status"
                        value={tempDraftStatus}
                        onChange={(e) => setTempDraftStatus(e.target.value)}
                    >
                        <FormControlLabel value="drafted" control={<Radio />} label="Drafted" className={styles.radioLabel} />
                        <FormControlLabel value="undrafted" control={<Radio />} label="Undrafted" className={styles.radioLabel} />
                        <FormControlLabel value="both" control={<Radio />} label="Both" className={styles.radioLabel} />
                    </RadioGroup>
                </FormControl>
            </div>

            <div className={styles.section}>
                <h3>Minimum Games Played</h3>
                <TextField
                    label="Minimum Games Played"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={tempMinGames}
                    onChange={(e) => setTempMinGames(e.target.value)}
                    className={styles.textField} // Apply styles to TextField
                />
            </div>

            <div className={styles.section}>
                <h3>Awards</h3>
                {awards.map((award, index) => (
                    <FormControlLabel
                        control={<Checkbox checked={tempSelectedAwards.has(award)} onChange={() => toggleAwardSelection(award)} />}
                        label={award}
                        key={index}
                        className={styles.radioLabel} // Apply styles to labels
                    />
                ))}
            </div>

            <button onClick={saveSettings} className={`${styles.button} ${styles.saveButton}`}>Save Settings</button>
        </div>
    </Dialog>
);



const revealHint = () => {
    const newHints = [...hintsGiven];

    if (newHints.length === 0 && currentPlayer) {
        newHints.push(currentPlayer.Pos); // First hint: Position
    } else if (newHints.length === 1 && currentPlayer) {
        // Determine the draft detail or undrafted status based on the presence of a draft year
        const draftDetail = currentPlayer.Round && currentPlayer.Pick
          ? `Round: ${currentPlayer.Round}, Pick: ${currentPlayer.Pick}, Year: ${currentPlayer.DrftYr}`
          : `Undrafted, Started Playing: ${currentPlayer.From}`;
        
        newHints.push(draftDetail);
    }
    setHintsGiven(newHints);
};
const skaterStats = {
    "A": currentPlayer?.A || "N/A", // Correct use of optional chaining with default value
    "PTS": currentPlayer?.PTS || "N/A",
    "+/-": currentPlayer?.["+/-"] || "N/A", // Correct use of optional chaining with bracket notation
    "PIM": currentPlayer?.PIM || "N/A",
    "EV": currentPlayer?.EV || "N/A",
    "PPG": currentPlayer?.PPG || "N/A",
    "SHG": currentPlayer?.SHG || "N/A",
    "GWG": currentPlayer?.GWG || "N/A",
    "SOG": currentPlayer?.SOG || "N/A",
    "SPCT": currentPlayer?.SPCT || "N/A",
    "TOI": currentPlayer?.TOI || "N/A",
    "FOW": currentPlayer?.FOW || "N/A",
    "FOL": currentPlayer?.FOL || "N/A",
    "FO%": currentPlayer?.["FO%"] || "N/A", // Correct use of optional chaining with bracket notation
    "HIT": currentPlayer?.HIT || "N/A",
    "BL": currentPlayer?.BL || "N/A"
};

  
const goalieStats = {
    "W": currentPlayer?.W || 0,
    "L": currentPlayer?.L || 0,
    "T/O": currentPlayer?.["T/O"] || 0,
    "GA": currentPlayer?.GA || 0,
    "GAA": currentPlayer?.GAA || 0,
    "SV": currentPlayer?.SV || 0,
    "Shots": currentPlayer?.Shots || 0,
    "GA%-": currentPlayer?.["GA%-"] || 0,
    "GSAA": currentPlayer?.GSAA || 0,
    "SO": currentPlayer?.SO || 0,
    "PIM": currentPlayer?.PIM || 0,
    "MIN": currentPlayer?.MIN || 0,
    "QS": currentPlayer?.QS || 0,
    "QS%": currentPlayer?.["QS%"] || 0,
    "RBS": currentPlayer?.RBS || 0,
    "GPS": currentPlayer?.GPS || 0,
};


// Function to pick two random stats from an object where values are not null
const pickRandomStats = (stats) => {
const keys = Object.keys(stats).filter(key => stats[key] != null && stats[key] !== undefined); // Ensure that both null and undefined are filtered out
const randomKeys = keys.sort(() => 0.5 - Math.random()).slice(0, 2); // Randomly pick two keys
return randomKeys.map(key => ({ key, value: stats[key] }));
};

const alwaysShowStats = currentPlayer?.Role === "GOALIE" ?
{ 'Games Played': currentPlayer?.GP || 'N/A', 'Games Started': currentPlayer?.GS || 'N/A' } :
{ 'Games Played': currentPlayer?.GP || 'N/A', 'Goals': currentPlayer?.G || 'N/A' };
const randomStats = currentPlayer?.Role === "GOALIE" ?
pickRandomStats(goalieStats) :
pickRandomStats(skaterStats);

const postStreakToLeaderboard = async (playerID, streak) => {
    try {
        const response = await fetch('https://us-central1-crossover-grid-511a4.cloudfunctions.net/api/updateLeaderboard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ playerID, score: streak }),
        });
        const responseData = await response.json();
        if (response.ok) {
            toast.success('Streak updated on leaderboard!');
        } else {
            throw new Error(responseData.message || 'Failed to update streak');
        }
    } catch (error) {
        toast.error('Error updating leaderboard: ' + error.message);
    }
};

    
    const getRandomPlayer = () => {
        return playerData[Math.floor(Math.random() * playerData.length)];
    };
   
// Fetch player only when mode or currentDate changes.
useEffect(() => {
    fetchPlayer(mode);
}, [mode, currentDate]);
// Handle changes in settings for "Unlimited" mode separately
useEffect(() => {
    if (mode === 'Unlimited') {
        fetchPlayer('Unlimited');
    }
}, [mode, selectedPositions, isActiveOnly, selectedTeams, selectedAwards]);

    // Fetch the player data on component mount
    const handleGuessChange = (event) => {
        const value = event.target.value;
        setUserGuess(value);
    
        const trimmedValue = value.trim().toLowerCase(); // Prepare the input for comparison
    
        if (trimmedValue.length > 0) {
            // Create a map to accumulate players by name
            const playersByName = new Map();
            playerData.forEach(player => {
                const playerName = player.Player.toLowerCase();
                if (playerName.includes(trimmedValue)) {
                    if (!playersByName.has(player.Player)) {
                        playersByName.set(player.Player, []);
                    }
                    playersByName.get(player.Player).push(player);
                }
            });
    
            const filteredSuggestions = Array.from(playersByName).map(([name, players]) => ({
                Player: name,
                ids: players.map(p => p.PlayerId), // Store all relevant IDs
                details: players  // You could store additional details here if needed
            }));
    
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };
    
    
    
    
    const selectPlayer = (selectedName) => {
        const playerInfo = suggestions.find(s => s.Player === selectedName);
        if (playerInfo.ids.length === 1) {
            setCurrentPlayerId(playerInfo.ids[0]); // Setting the ID from the single ID found
        } else {
            // You might need logic here to handle multiple IDs
            // For now, let's just set the first one
            setCurrentPlayerId(playerInfo.ids[0]);
        }
        setUserGuess(selectedName);
        setSuggestions([]);
    };
    

      const handleSelectPlayer = (player) => {
        setUserGuess(player.Player);
        setSuggestions([]);
        // If needed, perform additional actions when a player is selected
      };const handleGuessSubmit = (event) => {
        event.preventDefault();
        toast.dismiss();
        if (mode === 'Unlimited' || (mode === 'Daily' && !playedToday)) {
            const isCorrectGuess = currentPlayer && userGuess.toLowerCase() === currentPlayer.Player.toLowerCase();
            if (isCorrectGuess) {
                // Correct Guess
                setSeenPlayerIDs(prev => new Set(prev.add(currentPlayer['-9999'])));  // Update seenPlayerIDs when a correct guess is made
                toast.success(`${currentPlayer.Player} was correct! Guessed in ${3 - guessesLeft + 1} tries!`);
                endGame(true);
            } else {
                // Incorrect Guess
                if (guessesLeft > 1) {
                    setGuessesLeft(guessesLeft - 1);
                    toast.error(`Incorrect guess: ${userGuess}. Try again!`);
                    revealHint();
                } else {
                    // No guesses left, end the game
                    endGame(false);
                }
            }
            setUserGuess('');
        }
    };
    const endGame = (gameWon) => {
        updateGamesPlayed();
        setGuessesLeft(3);
        setHintsGiven([]);
        setUserGuess('');
    
        if (gameWon) {
            updateWinsAndStreak();
            // Potential logic to handle a win situation
        } else {
            // Game ends because of an incorrect guess
            const currentStreak = mode === 'Daily' ? homeWinStreak : unlimitedWinStreak;
            if (currentStreak >= 5) {
                // Only show the streak modal if the game ends due to a wrong guess and the streak was 5 or more
                setShowStreakModal(true);
                // Defer streak reset until modal is dismissed
            } else {
                // Reset streak immediately if it was less than 5
                resetStreak();
            }
            toast.error(`No more guesses left! The correct player was ${currentPlayer.Player}.`);
        }
    
        setPlayedToday(mode === 'Daily');
        if (mode === 'Unlimited' || !gameWon) {
            fetchPlayer(mode);  // Fetch a new player for Unlimited or after a loss.
        }
    
        if (mode === 'Daily') {
            setPlayedToday(true);
            localStorage.setItem(currentDate, 'played');
        }
    };
    
    
    const NoPlayersAvailableModal = ({ open, onClose, onShare, onRestart }) => {
        return (
            <Dialog open={open} onClose={onClose}>
                <div style={{ padding: 20, textAlign: 'center' }}>
                    <h2>No More Players Available</h2>
                    <p>There are no more players available under the current filters.</p>
                    <div>
                        <Button onClick={onClose} color="primary" style={{ margin: '10px' }}>
                            Close
                        </Button>
                        <Button onClick={onShare} color="secondary" style={{ margin: '10px' }}>
                            Share Achievement
                        </Button>
                        <Button onClick={onRestart} color="secondary" style={{ margin: '10px' }}>
                            Restart Game
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    };
    const StreakModal = ({ open, onClose }) => {
        const currentStreak = mode === 'Daily' ? homeWinStreak : unlimitedWinStreak;
        return (
            <Dialog open={open} onClose={onClose} onBackdropClick={onClose} onEscapeKeyDown={onClose}>
                <div style={{ padding: 20, textAlign: 'center' }}>
                    <h2>Congratulations!</h2>
                    <p>You have achieved a streak of {currentStreak} correct guesses!</p>
                    <p>Your streak has been added to the leaderboard.</p>
                    <div>
                        <Button onClick={() => {
                            shareScore(); // Optional: trigger score sharing
                            onClose();  // This ensures all close actions lead through the close handler
                        }} color="primary" style={{ margin: '10px' }}>
                            Share on Twitter
                        </Button>
                        <Button onClick={onClose} color="secondary" style={{ margin: '10px' }}>
                            Close
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    };
    
    
    
    
    const handleResetGame = () => {
        setSeenPlayerIDs(new Set());
        setFiltersToDefault(); // Reset all filters to default state or as you define
        fetchPlayer('Unlimited'); // Fetch players again after resetting
        setShowNoPlayersModal(false); // Close the modal
    };
    
    const handleShareAchievement = () => {
        // Assume that player count is being tracked somewhere in state
        shareAchievement(seenPlayerIDs.size); 
        setShowNoPlayersModal(false); // Optionally close the modal after sharing
    };
    const handleStreakModalClose = () => {
        resetStreak(); // This will reset the streak to 0
        setShowStreakModal(false); // Close the modal
    };  // Stat pools
    
  
    
    const setFiltersToDefault = () => {
        setSelectedPositions(new Set());    // Assuming no positions are selected by default
        setSelectedTeams(new Set());        // Assuming no teams are selected by default
        setIsActiveOnly('both');            // Assuming the default is to show both active and inactive players
        setDraftStatus('both');             // Assuming the default is to show both drafted and undrafted players
        setMinGames(0);                     // Assuming the default is no minimum games played
        setSelectedAwards(new Set());       // Reset selected awards to an empty set
    
        // Reset any additional filter states here as needed
    };
    

    useEffect(() => {
        // Show modal only in Unlimited mode and when there are no more players to guess
        if (mode === 'Unlimited' && outOfPlayers && filteredPlayers.length === 0) {
            setShowNoPlayersModal(true);
        } else {
            setShowNoPlayersModal(false);
        }
    }, [mode, outOfPlayers, filteredPlayers.length]);
    

    useEffect(() => {
        localStorage.setItem('homeTotalGames', homeTotalGames);
        localStorage.setItem('homeTotalWins', homeTotalWins);
        localStorage.setItem('homeWinStreak', homeWinStreak);
    
        localStorage.setItem('unlimitedTotalGames', unlimitedTotalGames);
        localStorage.setItem('unlimitedTotalWins', unlimitedTotalWins);
        localStorage.setItem('unlimitedWinStreak', unlimitedWinStreak);
    }, [homeTotalGames, homeTotalWins, homeWinStreak, unlimitedTotalGames, unlimitedTotalWins, unlimitedWinStreak]);
    
    const shareScore = () => {
        const currentStreak = mode === 'Daily' ? homeWinStreak : unlimitedWinStreak;
        const filtersDescription = formatFilters();

        const tweetText = `I guessed ${currentStreak} players under the current filters in Guess the Player!  Filters used: ${filtersDescription}.`;
        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
        window.open(url, '_blank');
    };
    {showHowToPlay && (
        <HowToPlayModal open={showHowToPlay} onClose={toggleHowToPlayModal} />
    )}
    
    useEffect(() => {
        setPlayedToday(localStorage.getItem(currentDate) === 'played');
    }, [currentDate]);
    

    
    if (!currentPlayer) {
        return <div>Loading player data...</div>;
    }
// Early return if today's challenge has been completed
if (mode === 'Daily' && playedToday) {
    const currentStreak = mode === 'Daily' ? homeWinStreak : unlimitedWinStreak;
    const guessesUsed = 3 - guessesLeft + 1;
    return (
        <div className={styles.container}>
            <div className={styles.card}>
                
            <div className={styles.title}>
                
  Guess the Player
  {mode === 'Unlimited' && (
    <IconButton 
      onClick={() => handleOpenSettings(true)} 
      style={{ marginLeft: '10px', verticalAlign: 'middle' }}
      title="Filter settings"
    >
      <FilterListIcon />
    </IconButton>
  )}
</div>

                <p>You've already played today's challenge. Here's the result:</p>
                <p>The correct player was: {currentPlayer.Player}</p>

                <button onClick={handleModeChange} className={styles.toggleButton}>
                    Switch to Unlimited
                </button>
                
                {/* Share button for Daily mode */}
                <button onClick={() => {
                    const tweetText = `I guessed the @Daily correct player in ${guessesUsed} guess${guessesUsed > 1 ? 'es' : ''}! My win streak is ${currentStreak}! `;
                    const tweetUrl = '1782399386833616947'; // Only include the tweet ID
                    const encodedTweetText = encodeURIComponent(tweetText);
                    const encodedTweetUrl = encodeURIComponent(tweetUrl);
                    const url = `https://twitter.com/intent/tweet?text=${encodedTweetText}&in_reply_to=${encodedTweetUrl}`;
                    window.open(url, '_blank');
                }}>Share result on Twitter</button>

                {/* Copy button for Daily mode */}
                <button onClick={() => {
                    navigator.clipboard.writeText(`I guessed the correct player in ${guessesUsed} guess${guessesUsed > 1 ? 'es' : ''}! My win streak is ${currentStreak}! Can you beat me?`);
                }}>Copy result to Clipboard</button>
            </div>
        </div>
    );
}    const teamHeaderText = currentPlayer.Team.length > 1 ? 
'Teams Played For' : 
'Team Played For'; // This could be singular if only one team


return (
    <div className="app">
<ToastContainer position="top-center" limit={2} />

        <Container>
            
            <nav className="navbar">
                {/* Navbar content */}
                <a href="https://crossovergrid.com" 
                style={{ 
                    textDecoration: "none", 
                    color: "inherit", 
                    display: "flex", 
                    alignItems: "center", // This ensures vertical alignment
                    justifyContent: "center" // This centers the content horizontally if the a-tag is a flex container
                }}>
                    <img src={crossover_logo} alt="Logo" className="navbar-logo" />
                    <p style={{ 
                        margin: 0, // Removes default margin that might offset alignment
                        fontWeight: "bold", 
                        fontSize: 20,
                        display: "flex", // Makes the paragraph itself a flex container
                        alignItems: "center", // Centers the text inside the p-tag vertically
                        justifyContent: "center" // Centers the text inside the p-tag horizontally
                    }}>
                        CROSSOVER GRID NHL
                        </p>
                </a>
                <div>

                <IconButton href="/" title="Home">
  <HomeIcon />
</IconButton>
<IconButton onClick={() => handleOpenSettings(true)} style={{ color: 'inherit' }}>
    <SettingsIcon />
</IconButton>
</div>

            </nav>
        </Container>
        {settingsDialog}

        <div className="sport-picker">
            {/* Sport picker content */}
            <div className="links">
                {sports.map((sport) => (
                    <Link key={sport.id} className={`sportLink ${sport.id === "21" ? "prime-gaming-logo" : ""} ${page_name === sport.id ? "selected" : ""}`} to={sport.path}>
                        {sport.emoji.startsWith("http") ? <img src={sport.emoji} alt={sport.name} /> : <span>{sport.emoji}</span>}
                        <br />
                        {sport.name.length < 4 ? <span>{sport.name}</span> : <span style={{ fontSize: 12 }}>{sport.name}</span>}
                    </Link>
                ))}
            </div>
        </div>
        <button onClick={handleModeChange}>
                        {mode === 'Daily' ? 'Switch to Unlimited' : 'Switch to Daily'}
                    </button>

                    <div className={styles.container}>
            <div className={styles.card}>
    <h1 className={styles.title}>Guess the Player
    <IconButton onClick={toggleHowToPlayModal} title="How to Play" style={{ marginLeft: '10px' }}>
            <HelpOutlineIcon />
        </IconButton>
        {showHowToPlay && (
            <HowToPlayModal open={showHowToPlay} onClose={toggleHowToPlayModal} />
        )}
    </h1>
    {showEditPlayerIDModal && (
    <EditPlayerIDModal open={showEditPlayerIDModal} onClose={toggleEditPlayerIDModal} />
)}
{showNoPlayersModal && (
    <NoPlayersAvailableModal 
        open={showNoPlayersModal} 
        onClose={() => setShowNoPlayersModal(false)}
        onShare={handleShareAchievement}
        onRestart={handleResetGame}
    />
)}
{showStreakModal && (
    <StreakModal
        open={showStreakModal}
        onClose={handleStreakModalClose}  // Ensure this handles all close actions
    />
)}


    <div className={styles.controls}>
    {mode === 'Unlimited' && (
        <>
            <IconButton 
                onClick={() => handleOpenSettings(true)} 
                title="Filter settings"
                style={{ marginRight: '10px' }}
            >
                <SettingsIcon />
            </IconButton>
            <IconButton 
                onClick={toggleEditPlayerIDModal} 
                title="Edit Player ID"
                style={{ marginRight: '10px' }}
            >
                <EditIcon />
            </IconButton>
            <span className={styles.availablePlayersBox}>
                Available Players: {filteredPlayers.length}
            </span>
            <Button onClick={openLeaderboardModal} variant="contained" color="primary" style={{ marginLeft: '10px' }}>
                Show Leaderboard
            </Button>
        </>
    )}
</div>

           
         <div className={styles.toggleMode}>
              
                </div>
                <div className={styles.scoreboard}>
                <p>🏆 Win Percentage: {mode === 'Daily' ? (homeTotalGames ? (homeTotalWins / homeTotalGames * 100).toFixed(2) : 0) : (unlimitedTotalGames ? (unlimitedTotalWins / unlimitedTotalGames * 100).toFixed(2) : 0)}%</p>
<p>🎮 Total Games: {mode === 'Daily' ? homeTotalGames : unlimitedTotalGames}</p>
<p>🔥 Win Streak: {mode === 'Daily' ? homeWinStreak : unlimitedWinStreak}</p>
<p>⭐ Highest Streak: {mode === 'Daily' ? homeHighestStreak : unlimitedHighestStreak}</p>

    {mode === 'Unlimited' && guessesLeft === 0 && (
        <button onClick={shareScore} className={styles.shareButton}>Share on Twitter</button>
    )}
</div>     <div>

            <LeaderboardModal
                open={showLeaderboardModal}
                onClose={closeLeaderboardModal}
                leaderboard={leaderboard}
                fetchLeaderboard={fetchLeaderboard}
                
            />
        </div>

                {/* Form for player guesses */}
                <form onSubmit={handleGuessSubmit} className={styles.guessForm}>
                    <div className={styles.formControls}>
                        <input
                            type="text"
                            value={userGuess}
                            onChange={handleGuessChange}
                            className={styles.guessInput}
                            placeholder="Enter player's name"
                        />
                        <button type="submit" className={styles.guessButton}>Guess</button>
                    </div>
                    { suggestions.length > 0 && (
    <ul className={styles.suggestions}>
        {suggestions.map((suggestion, index) => (
            <li
                key={index}
                onClick={() => selectPlayer(suggestion.Player)}
                className={styles.suggestionItem}
            >
                {suggestion.Player}
            </li>
        ))}
    </ul>
)}


                </form>
                <div className={styles.guessesLeft}>Guesses Left: {guessesLeft}</div>

                {hintsGiven.map((hint, index) => {
    return (
        <div key={index} className={`${styles.infoBlock} ${styles.hint}`}>
            <p className={styles.info}>{index === 0 ? 'Position' : 'Draft Details'}</p>
            <div className={styles.infoValue}>{hint}</div>
        </div>
    );
})}
<div className={styles.infoContainer}> {/* Container for both info blocks */}
  {currentPlayer.Born && (
    <div className={styles.infoBlock}>
      <p className={styles.info}>Born in {currentPlayer.Born}</p>
      <div className={styles.infoValue}>
        {currentPlayer.Born}
      </div>
    </div>
  )}

  {currentPlayer.Alumni && (
    <div className={styles.infoBlock}>
      <p className={styles.info}>
        {currentPlayer.Drafted ? 
          `Alumni Drafted From ${currentPlayer.Form}` : 
          `Last Alumni Played For ${currentPlayer.Form}`}
      </p>
      <div className={styles.infoValue}>
        <img 
          src={alumniLogos[currentPlayer.Alumni] || 'https://crossover-grid.s3.amazonaws.com/Alumni-logos/default-logo.png'}
          alt={currentPlayer.Alumni}
          className={styles.teamLogo}
        />
        {currentPlayer.Alumni}
      </div>
    </div>
  )}

  {!currentPlayer.Born && !currentPlayer.Alumni && (
    <div className={styles.infoBlock}>
      <p className={styles.info}>Location Unknown</p>
    </div>
  )}
</div>




{hasDraftDetails && currentPlayer && (
  <div className={styles.infoBlock}>
  <p className={styles.info}>Team Drafted By</p>
    <img
      src={teamLogos[currentPlayer?.Drafted || currentPlayer.Team?.[0]]}
      alt={currentPlayer?.Drafted || currentPlayer.Team?.[0]}
      className={styles.teamLogo}
    />
  </div>
)}

      {/* Teams played for block */}
      <div className={styles.infoBlock}>
        <p className={styles.info}>{teamHeaderText}</p>
        <div className={styles.teamLogos}>
          {currentPlayer.Team.map(team => (  // Map through all teams without slicing
            <img
              src={teamLogos[team]}
              key={team}
              alt={team}
              className={styles.teamLogo}
            />
          ))}
        </div>
      </div>

        <div className={styles.infoBlock}>
        <p className={styles.info}>Career Info</p>
        {Object.entries(alwaysShowStats).map(([stat, value]) => (
          <p key={stat}>{`${stat}: ${value}`}</p>
        ))}
        {randomStats.map(stat => (
          <p key={stat.key}>{`${stat.key}: ${stat.value}`}</p>
        ))}

    <div 
      dangerouslySetInnerHTML={createMarkup(formatCareerAwards(currentPlayer))} 
    />      </div>

<div className={styles.inputGroup}>
    <div className={styles.input}>
        <strong>Player ID:</strong> {playerIDInput || "No Player ID set"}
    </div>
</div>

<div>
  Available players: {filteredPlayers.length}</div>
        </div>
      </div>
    </div>
  );
};

export default GuessThePlayer;