import { Box } from "@mui/material";
import Axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext, useWebSocketContext } from "../contexts";
import useThrottle from "../utilities/throttle";
import AlertModal from "./AlertModal";
import { GAME_OVER_TEXT } from "./constants";
import { DrawModal, RematchModal } from "./Modals";

import { useMultiplayerContext } from "../contexts/MultiplayerContext";
import Navbar from "./Nav";
import ProgressSnackbarContent from "./ProgressSnackbarContent";
import SearchModal from "./SearchModal";
import TicTacToe from "./TicTacToe";

const GUESS_NO_TURN = "NT";
const GUESS_CORRECT = "CT";
const GUESS_WRONG = "WT";
const GUESS_IDLE = "GT";

const GameController = () => {
  const socket = useWebSocketContext();
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const { username, userId } = useUserContext();
  // get the gameID from the URL
  const gameIdURL = new URLSearchParams(window.location.search).get("gameId");
  const sportIdURL = new URLSearchParams(window.location.search).get("sportId");
  const [gameId, setGameId] = useState(gameIdURL);

  const [opponent, setOpponent] = useState(null);
  const [opponentHere, setOpponentHere] = useState(true);
  const [order, setOrder] = useState(null);
  const [serverTimer, setServerTimer] = useState(24);
  const [isGuessTurn, setIsGuessTurn] = useState(GUESS_NO_TURN);
  const isGuessTurnRef = useRef(isGuessTurn);

  const [counterGuess, setCounterGuess] = useState(0);
  const [messageGuess, setMessageGuess] = useState(null);

  const gameIdRef = useRef(gameId);
  const opponentRef = useRef(null);
  const leaveGameTimerRef = React.useRef(null);

  const [headers, setHeaders] = useState({
    cols: [],
    rows: [],
  });
  const [gameState, setGameState] = useState({
    board: Array(9).fill(null),
    nextPlayerOrder: 0,
    winner: null,
    correctGuesses: {
      [username]: 0,
    },
  });
  const isMyTurn = order === gameState?.nextPlayerOrder;
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [usedPlayers, setUsedPlayers] = useState([]);
  const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
  const [isRematchRequest, setIsRematchRequest] = useState(false);
  const { setIsTimerRunning, setCurrentTimer, currentTimer } =
    useMultiplayerContext();

  const [winner, setWinner] = useState(null);

  const [isDrawModalOpen, setIsDrawModalOpen] = useState(false);
  const [isRematchModalOpen, setIsRematchModalOpen] = useState(false);

  const [isWinnerModalOpen, setIsWinnerModalOpen] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    return () => {
      if (leaveGameTimerRef.current !== null) {
        clearTimeout(leaveGameTimerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (username !== null && socket !== null && gameId !== null) {
      initializeWebsocket(true);
    } else if (username !== null && socket !== null) {
      initializeWebsocket(false);
    }
  }, [username, socket]);

  useEffect(() => {
    setWinner(gameState.winner);
    if (gameState?.winner != null) {
      setIsWinnerModalOpen(true);
      setIsRematchRequest(false);
    }
  }, [gameState?.winner]);

  // Update the ref whenever isGuessTurn changes
  useEffect(() => {
    isGuessTurnRef.current = isGuessTurn;
  }, [isGuessTurn]);

  const initializeWebsocket = (joining = false) => {
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === "gameCreated") {
        setGameId(data.gameId);
        gameIdRef.current = data.gameId;
        navigate(`/play/game?gameId=${data.gameId}`);
      } else if (data.type === "gameNotFound") {
        if (!isWinnerModalOpen) {
          setIsRematchRequest(false);
          // displayProgressSnackbar("Game not found...", () => {
          navigate("/play");
          // });
        }
      } else if (data.type === "rejoinGame") {
        setOpponentHere(true);
        setIsWinnerModalOpen(false);
        setIsRematchRequest(false);
        setGameState(data.gameState);
        setHeaders(data.gridHeaders);
        gameIdRef.current = data.gameId;
        setOpponent(data.opponent);
        opponentRef.current = data.opponent;
        setOrder(data.order);
        setIsTimerRunning(true);
        setCurrentTimer(data?.currentTimer);
        setServerTimer(data?.currentTimer);
      } else if (data.type === "gameUpdate" || data.type === "disconnect") {
        setSelectedBoxIndex(null);
        setSearchOpen(false);
        setGameState(data.gameState);
        setCurrentTimer(data?.currentTimer || serverTimer);

        if (data?.type === "disconnect") {
          setOpponentHere(false);
        }

        // Check if counter guess is diff
        const newCounterCorrect = data?.gameState?.correctGuesses[userId];
        if (isGuessTurnRef.current === GUESS_IDLE) {
          if (counterGuess < newCounterCorrect) {
            setCounterGuess(newCounterCorrect);
          }

          setTimeout(() => {
            setMessageGuess(null);
          }, 2000);

          setIsGuessTurn(GUESS_NO_TURN);
        }

        setIsGuessTurn(GUESS_NO_TURN);
      } else if (data.type === "drawRequest") {
        setIsWinnerModalOpen(false);
        setIsDrawModalOpen(true);
      } else if (data.type === "drawDenied") {
        if (gameState.winner !== null) {
          setIsWinnerModalOpen(true);
          setIsRematchRequest(false);
        }
        displayProgressSnackbar(
          "The other player has declined the draw request."
        );
      } else if (data.type === "drawAccepted") {
        setGameState((prevState) => ({
          ...prevState,
          winner: "draw",
        }));
        setIsDrawModalOpen(false);
        setIsWinnerModalOpen(true);
        setIsRematchRequest(false);
      } else if (data.type === "rematchRequest") {
        setIsWinnerModalOpen(false);
      } else if (data.type === "rematchDenied") {
        if (gameState.winner !== null) {
          setIsWinnerModalOpen(true);
          setIsRematchRequest(false);
        }
        displayProgressSnackbar(
          "The other player has declined the rematch request."
        );
      }
    };

    if (joining) {
      joinGameById();
    } else {
      startNewGame();
    }
  };

  const startNewGame = () => {
    const message = { type: "startNewGame", username };
    socket.send(JSON.stringify(message));
  };

  const joinGameById = () => {
    const message = { type: "joinMatch", gameId: gameIdRef.current };
    socket.send(JSON.stringify(message));
    setOpponentHere(true);
  };

  const handleMakeMove = (index, player) => {
    let p = player;
    p.guessedBy = userId;
    if (socket && socket.readyState === WebSocket.OPEN) {
      // Waiting for Guess Check
      setIsGuessTurn(GUESS_IDLE);

      socket.send(
        JSON.stringify({
          type: "gameUpdate",
          gameId: gameId,
          index: index,
          userId: userId,
          guess: p,
        })
      );
    }
  };

  const forfeit = () => {
    socket.send(JSON.stringify({ type: "forfeit", gameId }));
    // navigate("/play");
  };

  const leaveMatch = () => {
    socket.send(JSON.stringify({ type: "cleanup", gameId }));
    setOpponentHere(true);
    navigate("/play");
  };

  const handleRequestDraw = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: "requestDraw",
          gameId: gameIdRef.current,
        })
      );
      displayProgressSnackbar("Waiting for opponent to accept draw...");
    }
  };
  const handleRequestRematch = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      setIsRematchRequest(true);

      socket.send(
        JSON.stringify({
          type: "requestRematch",
          gameId: gameIdRef.current,
          opponent: opponent?.userId,
          userId: userId,
          game: gameState,
        })
      );
      displayProgressSnackbar("Waiting for opponent to accept rematch...");
      setIsRematchRequest(false);
    }
  };

  const displaySnackbar = (message, variant = "default") => {
    enqueueSnackbar(message, { variant });
  };

  const displayProgressSnackbar = (text, callback = () => {}) => {
    const key = enqueueSnackbar(text, {
      content: (key, message) => (
        <ProgressSnackbarContent
          text={message}
          duration={4000}
          snackbarKey={key}
          closeSnackbar={closeSnackbar}
        />
      ),
    });

    setTimeout(() => {
      closeSnackbar(key);
    }, 4500);
    leaveGameTimerRef.current = setTimeout(() => {
      callback();
    }, 4500);
  };

  let ac = undefined;
  const debouncedRequest = useThrottle(async () => {
    // cancel any in-flight searches
    ac?.abort();
    const abortController = new AbortController();
    ac = abortController;
    let sr = await Axios.get(
      `${process.env.REACT_APP_SEARCH_URL}/game/fuse/search/players?sportId=${sportIdURL}&name=${searchInput}`,
      {
        signal: ac.signal,
      }
    ).catch(function (e) {
      if (Axios.isCancel(e)) {
        console.error(e.message);
        return;
      } else {
        // handle HTTP error...
        return;
      }
    });
    if (sr) {
      setSearchResults(sr.data);
    }
  });

  const handleInputChange = async (event) => {
    const input = event.target.value;
    setSearchInput(input);
    debouncedRequest();
  };

  const handleBoxClick = (index) => {
    // If the game is over, the box already has a correct answer, or the player has already been used, do nothing
    // if (
    //   isGameOver ||
    //   (!isUnlimitedMode && guessesRemaining <= 0) || // <-- Modified this line
    //   boxElements[index].keys?.length > 0 ||
    //   usedPlayers.includes(boxElements[index])
    // ) {
    //   return;
    // }
    // Otherwise open search
    setSelectedBoxIndex(index);
    setSearchOpen(true);
    setSearchInput("");
    setSearchResults([]);
    // Don't display search results until user starts typing
    // setSearchResults([]);
  };

  const handleResultsClick = (player) => {
    if (!isMyTurn) return;
    const exists = gameState?.usedPlayers.some((obj) => obj?.id === player?.id);
    if (exists) {
      return;
    }
    setSearchOpen(false);
    setUsedPlayers((prevUsedPlayers) => [...prevUsedPlayers, player]);
    handleMakeMove(selectedBoxIndex, player);
    setSelectedBoxIndex(null);
  };

  const winnerModalText = () => {
    let gameText = GAME_OVER_TEXT[sportIdURL];
    if (!gameText) {
      return { header: "", body: "" };
    }

    if (winner === userId) {
      return {
        header: gameText.winner.header,
        body: gameText.winner.body,
      };
    } else if (winner === "draw") {
      return { header: "It's a draw", body: "No winners here..." };
    } else {
      return {
        header: gameText.loser.header,
        body: gameText.loser.body,
      };
    }
  };

  return (
    <>
      <Navbar />
      {gameId && (
        <>
          <SearchModal
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
            searchInput={searchInput}
            handleInputChange={handleInputChange}
            searchInputRef={searchInputRef}
            searchResults={searchResults}
            handleResultsClick={handleResultsClick}
            usedPlayers={gameState?.usedPlayers}
          />
          {!!messageGuess?.length && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1>{messageGuess}</h1>
            </Box>
          )}
          <TicTacToe
            username={username}
            userId={userId}
            headers={headers}
            gameState={gameState}
            onMakeMove={handleMakeMove}
            opponent={opponent}
            order={order}
            onRequestRematch={handleRequestRematch}
            onRequestDraw={handleRequestDraw}
            handleBoxClick={handleBoxClick}
            selectedBoxIndex={selectedBoxIndex}
            currentTimer={currentTimer}
            sportId={sportIdURL}
            forfeit={forfeit}
          />
        </>
      )}
      <AlertModal
        isOpen={isWinnerModalOpen}
        title={winnerModalText().header}
        contentText={winnerModalText().body}
        actions={[
          opponentHere &&
            {
              /*      label: isRematchRequest ? "Waiting for opponent" : "Rematch",
            onClick: () => {
              if (isRematchRequest) return;

              handleRequestRematch();
            }, */
            },
          {
     /*        label: "New opponent",
            onClick: () => {
              leaveMatch();
              navigate("/play/waiting", { state: { sport_id: sportIdURL } });
            }, */
          },
          { label: "Leave", onClick: () => leaveMatch() },
        ]}
      />
      <RematchModal
        isOpen={isRematchModalOpen}
        // onClose={() => setIsRematchModalOpen(false)}
        title={"Rematch?"}
        contentText={`Your opponent has requested a rematch. Do you accept?`}
        actions={[
          {
            label: "Accept",
            onClick: () => {
              socket.send(
                JSON.stringify({
                  type: "acceptRematch",
                  gameId: gameIdRef.current,
                })
              );
              setIsRematchModalOpen(false);
            },
          },
          {
            label: "Deny",
            onClick: () => {
              socket.send(
                JSON.stringify({
                  type: "denyRematch",
                  gameId: gameIdRef.current,
                  userId: username,
                })
              );
              setIsRematchModalOpen(false);
              if (gameState.winner !== null) {
                setIsWinnerModalOpen(true);
                setIsRematchRequest(false);
              }
            },
          },
        ]}
      />

      <DrawModal
        isOpen={isDrawModalOpen}
        title={"Draw?"}
        contentText={`Your opponent has requested a draw. Do you accept?`}
        actions={[
          {
            label: "Accept",
            onClick: () => {
              socket.send(
                JSON.stringify({
                  type: "acceptDraw",
                  gameId: gameIdRef.current,
                })
              );
              setIsDrawModalOpen(false);
            },
          },
          {
            label: "Deny",
            onClick: () => {
              socket.send(
                JSON.stringify({
                  type: "denyDraw",
                  gameId: gameIdRef.current,
                  userId: username,
                })
              );
              setIsDrawModalOpen(false);
              if (gameState.winner !== null) {
                setIsWinnerModalOpen(true);
                setIsRematchRequest(false);
              }
            },
          },
        ]}
      />
    </>
  );
};

export default GameController;
