export const ContentTerms = () => {
  return (
    <div>
      <h3>Terms</h3>
      <p>
        Please read and agree to the terms of service before participating. The
        terms outline the rules, eligibility, and other important information.
      </p>
      <p>
        To enter this Promotion, you must agree to and abide by these official
        rules ("Official Rules").
      </p>
      <p>
        <strong>
          NO PURCHASE NECESSARY. NO PURCHASE OR PAYMENT NECESSARY TO ENTER OR
          WIN. A PURCHASE OR PAYMENT WILL NOT IMPROVE ONE'S CHANCES OF WINNING.
          UNLESS OTHERWISE SPECIFIED IN SECTION 3 BELOW, VOID IN PUERTO RICO,
          OTHER U.S. TERRITORIES AND FOREIGN TERRITORIES, QUEBEC AND WHERE
          PROHIBITED BY LAW.
        </strong>
      </p>
      <p>
        (A) TO QUALIFY FOR THE GRAND PRIZE DRAWING, AN ENTRANT MUST CORRECTLY
        SELECT THE FINAL TOP 30 PLAYERS OF THE TOURNAMENT. AN ENTRANT WILL THEN
        BE ENTERED INTO THE GRAND PRIZE DRAWING FOR A RANDOM CHANCE TO WIN A
        GRAND PRIZE.
      </p>
      <p>
        (B) TO QUALIFY FOR THE FIRST PRIZE DRAWING, AN ENTRANT MUST COMPLETE ONE
        WITH ALL PICKS PRIOR TO FIRST TOURNAMENT ; AND, REGARDLESS IF ANY IS
        CORRECT, ENTRANT WILL BE ENTERED INTO THE FIRST PRIZE DRAWING FOR A
        RANDOM CHANCE TO WIN A FIRST PRIZE.
      </p>
      <p>
        (C) THE AWARDING OF POINTS FOR MAKING CORRECT PICKS AND ONE'S RANKING ON
        THE LEADER BOARD IS SOLELY FOR ENTERTAINMENT VALUE ONLY AND WILL NOT BE
        USED FOR PURPOSES OF SELECTING WINNERS OR AWARDING PRIZES.
      </p>
      <p>
        <strong>1. SPONSOR; PROMOTER.</strong> Sponsor and Promoter of this
        Promotion is FedExCup, with its principal place of business at the
        designated address.
      </p>
      <p>
        <strong>2. ADMINISTRATOR.</strong> This Promotion will be administered
        by the designated Administrator at the specified address.
      </p>
      <p>
        <strong>3. ELIGIBILITY.</strong> This promotion is open to legal
        residents of the 50 United States, District of Columbia, and Canada
        (excluding Quebec), who are at least eighteen (18) years or older as of
        the date of entry. The following are not eligible to participate in the
        Promotion or win a Prize: (i) employees and agents of Sponsor, Promoter,
        Administrator, their respective parent, subsidiary, and affiliated
        companies, and their respective advertising/promotion agencies; (ii)
        immediate family members and/or household members of any of the persons
        set forth in (i) above. Immediate family members shall mean parents,
        step-parents, children, step-children, siblings or spouses. Household
        members shall mean people who share the same residence at least three
        (3) months a year. This Promotion is void in all U.S. Territories and
        where prohibited or restricted by law.
      </p>
      <p>
        <strong>4. PROMOTION SCHEDULE.</strong> Registration for entry in the
        Promotion begins on August 10, 2024, at 12:00 PM (noon) ET and ends on
        August 20, 2024, at 7:05 PM ET ("Entry Period"). All entries must be
        received during the Entry Period. The Promotion involves selecting the
        top 30 players for the FedExCup Playoffs from an available field of 70
        players. The Promotion schedule is detailed as follows:
      </p>
      <ul>
        <li>FedEx St. Jude Championship: August 20-23, 2024</li>
        <li>BMW Championship: August 27-30, 2024</li>
        <li>TOUR Championship: September 3-6, 2024</li>
      </ul>
      <p>
        <strong>5. ENTRY.</strong> You must be a registered user to enter the
        Promotion. Register and enter via the official website or app. Limit of
        twenty-five (25) entries per person during the Entry Period. Multiple
        entries in violation of the limit will result in disqualification of all
        entries by such person and potential legal sanctions.
      </p>
      <p>
        <strong>6. HOW TO PLAY.</strong> Select your top 30 players from the
        field of 70. Picks lock at the start of the first tournament round on
        August 20, 2024. Entries must be submitted, received, and recorded by
        Sponsor's computer by the scheduled Lock Time.
      </p>
      <p>
        <strong>7. WINNER DETERMINATION/NOTIFICATION.</strong> Winners will be
        determined via a random drawing from among all eligible entries. Winners
        will be notified via email.
      </p>
      <p>
        <strong>8. OFFICIAL RULES AND WINNER LIST.</strong> For the Official
        Rules and names of winners, mail a self-addressed, stamped envelope to
        the specified address.
      </p>
    </div>
  );
};
