export const players = [
  {
    id: 46046,
    name: "Scottie Scheffler",
    rank2024: "1",
    position2023: "T6",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_46046.jpg",
    profileUrl: "https://www.pgatour.com/players/player.46046.scottie-scheffler.html",
    top10Percentage: "98.06%",
    top5Percentage: "96.79%",
    top30Percentage: "99.26%",
    top1Percentage: "72.93%"
  },
  {
    id: 48081,
    name: "Xander Schauffele",
    rank2024: "2",
    position2023: "2",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_48081.jpg",
    profileUrl: "https://www.pgatour.com/players/player.48081.xander-schauffele.html",
    top10Percentage: "96.79%",
    top5Percentage: "93.65%",
    top30Percentage: "98.64%",
    top1Percentage: "16.44%"
  },
  {
    id: 28237,
    name: "Rory McIlroy",
    rank2024: "3",
    position2023: "4",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_28237.jpg",
    profileUrl: "https://www.pgatour.com/players/player.28237.rory-mcilroy.html",
    top10Percentage: "91.31%",
    top5Percentage: "76.51%",
    top30Percentage: "97.98%",
    top1Percentage: "4.33%"
  },
  {
    id: 50525,
    name: "Collin Morikawa",
    rank2024: "4",
    position2023: "T6",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_50525.jpg",
    profileUrl: "https://www.pgatour.com/players/player.50525.collin-morikawa.html",
    top10Percentage: "89.99%",
    top5Percentage: "67.45%",
    top30Percentage: "97.65%",
    top1Percentage: "1.85%"
  },
  {
    id: 51766,
    name: "Wyndham Clark",
    rank2024: "5",
    position2023: "3",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_51766.jpg",
    profileUrl: "https://www.pgatour.com/players/player.51766.wyndham-clark.html",
    top10Percentage: "51.22%",
    top5Percentage: "20.85%",
    top30Percentage: "85.17%",
    top1Percentage: "0.33%"
  },
  {
    id: 52955,
    name: "Ludvig Åberg",
    rank2024: "6",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_52955.jpg",
    profileUrl: "https://www.pgatour.com/players/player.52955.ludvig-aberg.html",
    top10Percentage: "89.16%",
    top5Percentage: "60.36%",
    top30Percentage: "97.16%",
    top1Percentage: "1.48%"
  },
  {
    id: 51634,
    name: "Sahith Theegala",
    rank2024: "7",
    position2023: "31",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_51634.jpg",
    profileUrl: "https://www.pgatour.com/players/player.51634.sahith-theegala.html",
    top10Percentage: "68.40%",
    top5Percentage: "12.65%",
    top30Percentage: "93.82%",
    top1Percentage: "0.08%"
  },
  {
    id: 32839,
    name: "Hideki Matsuyama",
    rank2024: "8",
    position2023: "50",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_32839.jpg",
    profileUrl: "https://www.pgatour.com/players/player.32839.hideki-matsuyama.html",
    top10Percentage: "74.54%",
    top5Percentage: "20.19%",
    top30Percentage: "94.44%",
    top1Percentage: "0.12%"
  },
  {
    id: 39971,
    name: "Sungjae Im",
    rank2024: "9",
    position2023: "24",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_39971.jpg",
    profileUrl: "https://www.pgatour.com/players/player.39971.sungjae-im.html",
    top10Percentage: "49.94%",
    top5Percentage: "4.74%",
    top30Percentage: "88.46%",
    top1Percentage: "0.08%"
  },
  {
    id: 33204,
    name: "Shane Lowry",
    rank2024: "10",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_33204.jpg",
    profileUrl: "https://www.pgatour.com/players/player.33204.shane-lowry.html",
    top10Percentage: "44.87%",
    top5Percentage: "4.45%",
    top30Percentage: "89.25%",
    top1Percentage: "0.21%"
  },
  {
    id: 35450,
    name: "Patrick Cantlay",
    rank2024: "11",
    position2023: "5",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_35450.jpg",
    profileUrl: "https://www.pgatour.com/players/player.35450.patrick-cantlay.html",
    top10Percentage: "55.71%",
    top5Percentage: "12.20%",
    top30Percentage: "94.07%",
    top1Percentage: "0.29%"
  },
  {
    id: 33948,
    name: "Byeong Hun An",
    rank2024: "12",
    position2023: "44",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_33948.jpg",
    profileUrl: "https://www.pgatour.com/players/player.33948.byeong-hun-an.html",
    top10Percentage: "9.56%",
    top5Percentage: "0.78%",
    top30Percentage: "73.55%",
    top1Percentage: "0.04%"
  },
  {
    id: 34098,
    name: "Russell Henley",
    rank2024: "13",
    position2023: "T14",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_34098.jpg",
    profileUrl: "https://www.pgatour.com/players/player.34098.russell-henley.html",
    top10Percentage: "11.45%",
    top5Percentage: "1.73%",
    top30Percentage: "75.15%",
    top1Percentage: "0%"
  },
  {
    id: 29725,
    name: "Tony Finau",
    rank2024: "14",
    position2023: "T20",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_29725.jpg",
    profileUrl: "https://www.pgatour.com/players/player.29725.tony-finau.html",
    top10Percentage: "29.91%",
    top5Percentage: "4.12%",
    top30Percentage: "94.52%",
    top1Percentage: "0.08%"
  },
  {
    id: 56630,
    name: "Akshay Bhatia",
    rank2024: "15",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_56630.jpg",
    profileUrl: "https://www.pgatour.com/players/player.56630.akshay-bhatia.html",
    top10Percentage: "19.20%",
    top5Percentage: "2.72%",
    top30Percentage: "88.46%",
    top1Percentage: "0.04%"
  },
  {
    id: 48153,
    name: "Matthieu Pavon",
    rank2024: "16",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_48153.jpg",
    profileUrl: "https://www.pgatour.com/players/player.48153.matthieu-pavon.html",
    top10Percentage: "3.79%",
    top5Percentage: "0.54%",
    top30Percentage: "60.98%",
    top1Percentage: "0%"
  },
  {
    id: 52215,
    name: "Robert MacIntyre",
    rank2024: "17",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_52215.jpg",
    profileUrl: "https://www.pgatour.com/players/player.52215.robert-macintyre.html",
    top10Percentage: "6.51%",
    top5Percentage: "1.03%",
    top30Percentage: "73.51%",
    top1Percentage: "0%"
  },
  {
    id: 49960,
    name: "Sepp Straka",
    rank2024: "18",
    position2023: "T14",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_49960.jpg",
    profileUrl: "https://www.pgatour.com/players/player.49960.sepp-straka.html",
    top10Percentage: "4.99%",
    top5Percentage: "0.58%",
    top30Percentage: "74.87%",
    top1Percentage: "0%"
  },
  {
    id: 33448,
    name: "Justin Thomas",
    rank2024: "19",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_33448.jpg",
    profileUrl: "https://www.pgatour.com/players/player.33448.justin-thomas.html",
    top10Percentage: "13.35%",
    top5Percentage: "2.18%",
    top30Percentage: "82.20%",
    top1Percentage: "0.16%"
  },
  {
    id: 27644,
    name: "Brian Harman",
    rank2024: "20",
    position2023: "23",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_27644.jpg",
    profileUrl: "https://www.pgatour.com/players/player.27644.brian-harman.html",
    top10Percentage: "3.83%",
    top5Percentage: "0.33%",
    top30Percentage: "74.54%",
    top1Percentage: "0.04%"
  },
  {
    id: 35532,
    name: "Tom Hoge",
    rank2024: "21",
    position2023: "46",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_35532.jpg",
    profileUrl: "https://www.pgatour.com/players/player.35532.tom-hoge.html",
    top10Percentage: "1.28%",
    top5Percentage: "0.16%",
    top30Percentage: "48.41%",
    top1Percentage: "0%"
  },
  {
    id: 45522,
    name: "Christiaan Bezuidenhout",
    rank2024: "22",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_45522.jpg",
    profileUrl: "https://www.pgatour.com/players/player.45522.christiaan-bezuidenhout.html",
    top10Percentage: "1.32%",
    top5Percentage: "0.21%",
    top30Percentage: "47.05%",
    top1Percentage: "0%"
  },
  {
    id: 29420,
    name: "Billy Horschel",
    rank2024: "23",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_29420.jpg",
    profileUrl: "https://www.pgatour.com/players/player.29420.billy-horschel.html",
    top10Percentage: "11.58%",
    top5Percentage: "1.90%",
    top30Percentage: "83.15%",
    top1Percentage: "0.08%"
  },
  {
    id: 58168,
    name: "Davis Thompson",
    rank2024: "24",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_58168.jpg",
    profileUrl: "https://www.pgatour.com/players/player.58168.davis-thompson.html",
    top10Percentage: "1.73%",
    top5Percentage: "0.37%",
    top30Percentage: "46.31%",
    top1Percentage: "0%"
  },
  {
    id: 46414,
    name: "Aaron Rai",
    rank2024: "25",
    position2023: "68",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_46414.jpg",
    profileUrl: "https://www.pgatour.com/players/player.46414.aaron-rai.html",
    top10Percentage: "9.64%",
    top5Percentage: "1.77%",
    top30Percentage: "76.60%",
    top1Percentage: "0%"
  },
  {
    id: 28089,
    name: "Jason Day",
    rank2024: "26",
    position2023: "28",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_28089.jpg",
    profileUrl: "https://www.pgatour.com/players/player.28089.jason-day.html",
    top10Percentage: "3.30%",
    top5Percentage: "0.49%",
    top30Percentage: "70.79%",
    top1Percentage: "0.04%"
  },
  {
    id: 40250,
    name: "Taylor Pendrith",
    rank2024: "27",
    position2023: "N/A",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_40250.jpg",
    profileUrl: "https://www.pgatour.com/players/player.40250.taylor-pendrith.html",
    top10Percentage: "0.58%",
    top5Percentage: "0.04%",
    top30Percentage: "31.52%",
    top1Percentage: "0%"
  },
  {
    id: 30926,
    name: "Chris Kirk",
    rank2024: "28",
    position2023: "32",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_30926.jpg",
    profileUrl: "https://www.pgatour.com/players/player.30926.chris-kirk.html",
    top10Percentage: "0.74%",
    top5Percentage: "0.04%",
    top30Percentage: "30.37%",
    top1Percentage: "0%"
  },
  {
    id: 47504,
    name: "Sam Burns",
    rank2024: "29",
    position2023: "T9",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_47504.jpg",
    profileUrl: "https://www.pgatour.com/players/player.47504.sam-burns.html",
    top10Percentage: "2.72%",
    top5Percentage: "0.62%",
    top30Percentage: "65.84%",
    top1Percentage: "0.08%"
  },
  {
    id: 39997,
    name: "Corey Conners",
    rank2024: "30",
    position2023: "26",
    img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_39997.jpg",
    profileUrl: "https://www.pgatour.com/players/player.39997.corey-conners.html",
    top10Percentage: "3.46%",
    top5Percentage: "0.58%",
    top30Percentage: "55.09%",
    top1Percentage: "0.08%"
  },

    {
      id: 57366,
      name: "Cameron Young",
      rank2024: "31",
      position2023: "42",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_57366.jpg",
      profileUrl: "https://www.pgatour.com/players/player.57366.cameron-young.html",
      top10Percentage: "3.87%",
      top5Percentage: "0.58%",
      top30Percentage: "65.39%",
      top1Percentage: "0%"
    },
    {
      id: 30911,
      name: "Tommy Fleetwood",
      rank2024: "32",
      position2023: "T6",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_30911.jpg",
      profileUrl: "https://www.pgatour.com/players/player.30911.tommy-fleetwood.html",
      top10Percentage: "14.05%",
      top5Percentage: "2.64%",
      top30Percentage: "87.64%",
      top1Percentage: "0.21%"
    },
    {
      id: 36799,
      name: "Stephan Jaeger",
      rank2024: "33",
      position2023: "56",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_36799.jpg",
      profileUrl: "https://www.pgatour.com/players/player.36799.stephan-jaeger.html",
      top10Percentage: "11.66%",
      top5Percentage: "0.08%",
      top30Percentage: "11.66%",
      top1Percentage: "0%"
    },
    {
      id: 33653,
      name: "Thomas Detry",
      rank2024: "34",
      position2023: "55",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_33653.jpg",
      profileUrl: "https://www.pgatour.com/players/player.33653.thomas-detry.html",
      top10Percentage: "0.74%",
      top5Percentage: "0.12%",
      top30Percentage: "25.71%",
      top1Percentage: "0%"
    },
    {
      id: 39977,
      name: "Max Homa",
      rank2024: "35",
      position2023: "T9",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_39977.jpg",
      profileUrl: "https://www.pgatour.com/players/player.39977.max-homa.html",
      top10Percentage: "5.11%",
      top5Percentage: "0.54%",
      top30Percentage: "61.76%",
      top1Percentage: "0.04%"
    },
    {
      id: 49771,
      name: "J.T. Poston",
      rank2024: "36",
      position2023: "39",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_49771.jpg",
      profileUrl: "https://www.pgatour.com/players/player.49771.jt-poston.html",
      top10Percentage: "0.41%",
      top5Percentage: "0.04%",
      top30Percentage: "16.77%",
      top1Percentage: "0%"
    },
    {
      id: 33399,
      name: "Adam Hadwin",
      rank2024: "37",
      position2023: "45",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_33399.jpg",
      profileUrl: "https://www.pgatour.com/players/player.33399.adam-hadwin.html",
      top10Percentage: "0.54%",
      top5Percentage: "0.04%",
      top30Percentage: "14.92%",
      top1Percentage: "0%"
    },
    {
      id: 37455,
      name: "Si Woo Kim",
      rank2024: "38",
      position2023: "T20",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_37455.jpg",
      profileUrl: "https://www.pgatour.com/players/player.37455.si-woo-kim.html",
      top10Percentage: "1.11%",
      top5Percentage: "0.08%",
      top30Percentage: "27.73%",
      top1Percentage: "0%"
    },
    {
      id: 33141,
      name: "Keegan Bradley",
      rank2024: "39",
      position2023: "T9",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_33141.jpg",
      profileUrl: "https://www.pgatour.com/players/player.33141.keegan-bradley.html",
      top10Percentage: "1.28%",
      top5Percentage: "0.16%",
      top30Percentage: "30.20%",
      top1Percentage: "0%"
    },
    {
      id: 40098,
      name: "Matt Fitzpatrick",
      rank2024: "40",
      position2023: "T9",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_40098.jpg",
      profileUrl: "https://www.pgatour.com/players/player.40098.matt-fitzpatrick.html",
      top10Percentage: "1.81%",
      top5Percentage: "0.16%",
      top30Percentage: "35.23%",
      top1Percentage: "0%"
    },
    {
      id: 57362,
      name: "Austin Eckroat",
      rank2024: "41",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_57362.jpg",
      profileUrl: "https://www.pgatour.com/players/player.57362.austin-eckroat.html",
      top10Percentage: "0.41%",
      top5Percentage: "0.12%",
      top30Percentage: "8.69%",
      top1Percentage: "0%"
    },
    {
      id: 27349,
      name: "Alex Noren",
      rank2024: "42",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_27349.jpg",
      profileUrl: "https://www.pgatour.com/players/player.27349.alex-noren.html",
      top10Percentage: "0.41%",
      top5Percentage: "0.08%",
      top30Percentage: "13.47%",
      top1Percentage: "0%"
    },
    {
      id: 55182,
      name: "Tom Kim",
      rank2024: "43",
      position2023: "T20",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_55182.jpg",
      profileUrl: "https://www.pgatour.com/players/player.55182.tom-kim.html",
      top10Percentage: "5.48%",
      top5Percentage: "1.07%",
      top30Percentage: "50.47%",
      top1Percentage: "0.08%"
    },
    {
      id: 45157,
      name: "Cam Davis",
      rank2024: "44",
      position2023: "48",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_45157.jpg",
      profileUrl: "https://www.pgatour.com/players/player.45157.cam-davis.html",
      top10Percentage: "0.62%",
      top5Percentage: "0.04%",
      top30Percentage: "15.49%",
      top1Percentage: "0%"
    },
    {
      id: 47993,
      name: "Denny McCarthy",
      rank2024: "45",
      position2023: "33",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_47993.jpg",
      profileUrl: "https://www.pgatour.com/players/player.47993.denny-mccarthy.html",
      top10Percentage: "0.37%",
      top5Percentage: "0.12%",
      top30Percentage: "9.97%",
      top1Percentage: "0%"
    },
    {
      id: 24502,
      name: "Adam Scott",
      rank2024: "46",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_24502.jpg",
      profileUrl: "https://www.pgatour.com/players/player.24502.adam-scott.html",
      top10Percentage: "1.07%",
      top5Percentage: "0.08%",
      top30Percentage: "25.50%",
      top1Percentage: "0%"
    },
    {
      id: 51977,
      name: "Max Greyserman",
      rank2024: "47",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_51977.jpg",
      profileUrl: "https://www.pgatour.com/players/player.51977.max-greyserman.html",
      top10Percentage: "0.54%",
      top5Percentage: "0.25%",
      top30Percentage: "11.66%",
      top1Percentage: "0%"
    },
    {
      id: 35506,
      name: "Mackenzie Hughes",
      rank2024: "48",
      position2023: "51",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_35506.jpg",
      profileUrl: "https://www.pgatour.com/players/player.35506.mackenzie-hughes.html",
      top10Percentage: "0.21%",
      top5Percentage: "0%",
      top30Percentage: "7.99%",
      top1Percentage: "0%"
    },
    {
      id: 47483,
      name: "Will Zalatoris",
      rank2024: "49",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_47483.jpg",
      profileUrl: "https://www.pgatour.com/players/player.47483.will-zalatoris.html",
      top10Percentage: "0.70%",
      top5Percentage: "0%",
      top30Percentage: "17.02%",
      top1Percentage: "0.04%"
    },
    {
      id: 47420,
      name: "Jake Knapp",
      rank2024: "50",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_47420.jpg",
      profileUrl: "https://www.pgatour.com/players/player.47420.jake-knapp.html",
      top10Percentage: "0.33%",
      top5Percentage: "0.04%",
      top30Percentage: "7.33%",
      top1Percentage: "0%"
    },
    {
      id: 34099,
      name: "Harris English",
      rank2024: "51",
      position2023: "38",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_34099.jpg",
      profileUrl: "https://www.pgatour.com/players/player.34099.harris-english.html",
      top10Percentage: "0.12%",
      top5Percentage: "0%",
      top30Percentage: "6.96%",
      top1Percentage: "0%"
    },
    {
      id: 25493,
      name: "Nick Taylor",
      rank2024: "52",
      position2023: "25",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_25493.jpg",
      profileUrl: "https://www.pgatour.com/players/player.25493.nick-taylor.html",
      top10Percentage: "0.41%",
      top5Percentage: "0%",
      top30Percentage: "8.41%",
      top1Percentage: "0%"
    },
    {
      id: 36699,
      name: "Patrick Rodgers",
      rank2024: "53",
      position2023: "49",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_36699.jpg",
      profileUrl: "https://www.pgatour.com/players/player.36699.patrick-rodgers.html",
      top10Percentage: "0.16%",
      top5Percentage: "0%",
      top30Percentage: "2.27%",
      top1Percentage: "0%"
    },
    {
      id: 47591,
      name: "Eric Cole",
      rank2024: "54",
      position2023: "43",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_47591.jpg",
      profileUrl: "https://www.pgatour.com/players/player.47591.eric-cole.html",
      top10Percentage: "0.33%",
      top5Percentage: "0.16%",
      top30Percentage: "8.90%",
      top1Percentage: "0.08%"
    },
    {
      id: 22405,
      name: "Justin Rose",
      rank2024: "55",
      position2023: "34",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_22405.jpg",
      profileUrl: "https://www.pgatour.com/players/player.22405.justin-rose.html",
      top10Percentage: "1.15%",
      top5Percentage: "0.21%",
      top30Percentage: "22.37%",
      top1Percentage: "0%"
    },
    {
      id: 54591,
      name: "Ben Griffin",
      rank2024: "56",
      position2023: "63",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_54591.jpg",
      profileUrl: "https://www.pgatour.com/players/player.54591.ben-griffin.html",
      top10Percentage: "0.29%",
      top5Percentage: "0.12%",
      top30Percentage: "4.99%",
      top1Percentage: "0.08%"
    },
    {
      id: 46717,
      name: "Viktor Hovland",
      rank2024: "57",
      position2023: "1",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_46717.jpg",
      profileUrl: "https://www.pgatour.com/players/player.46717.viktor-hovland.html",
      top10Percentage: "4.57%",
      top5Percentage: "1.48%",
      top30Percentage: "35.35%",
      top1Percentage: "0.37%"
    },
    {
      id: 40006,
      name: "Erik van Rooyen",
      rank2024: "58",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_40006.jpg",
      profileUrl: "https://www.pgatour.com/players/player.40006.erik-van-rooyen.html",
      top10Percentage: "0.29%",
      top5Percentage: "0.12%",
      top30Percentage: "6.51%",
      top1Percentage: "0%"
    },
    {
      id: 46442,
      name: "Maverick McNealy",
      rank2024: "59",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_46442.jpg",
      profileUrl: "https://www.pgatour.com/players/player.46442.maverick-mcnealy.html",
      top10Percentage: "0.33%",
      top5Percentage: "0%",
      top30Percentage: "5.15%",
      top1Percentage: "0%"
    },
    {
      id: 49947,
      name: "Taylor Moore",
      rank2024: "60",
      position2023: "T29",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_49947.jpg",
      profileUrl: "https://www.pgatour.com/players/player.49947.taylor-moore.html",
      top10Percentage: "0.16%",
      top5Percentage: "0%",
      top30Percentage: "3.09%",
      top1Percentage: "0%"
    },
    {
      id: 34466,
      name: "Peter Malnati",
      rank2024: "61",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_34466.jpg",
      profileUrl: "https://www.pgatour.com/players/player.34466.peter-malnati.html",
      top10Percentage: "0.21%",
      top5Percentage: "0.12%",
      top30Percentage: "2.35%",
      top1Percentage: "0%"
    },
    {
      id: 37378,
      name: "Min Woo Lee",
      rank2024: "62",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_37378.jpg",
      profileUrl: "https://www.pgatour.com/players/player.37378.min-woo-lee.html",
      top10Percentage: "0.91%",
      top5Percentage: "0.37%",
      top30Percentage: "11.70%",
      top1Percentage: "0%"
    },
    {
      id: 34046,
      name: "Jordan Spieth",
      rank2024: "63",
      position2023: "27",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_34046.jpg",
      profileUrl: "https://www.pgatour.com/players/player.34046.jordan-spieth.html",
      top10Percentage: "1.11%",
      top5Percentage: "0.33%",
      top30Percentage: "18.95%",
      top1Percentage: "0.04%"
    },
    {
      id: 36801,
      name: "Mark Hubbard",
      rank2024: "64",
      position2023: "67",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_36801.jpg",
      profileUrl: "https://www.pgatour.com/players/player.36801.mark-hubbard.html",
      top10Percentage: "0.16%",
      top5Percentage: "0%",
      top30Percentage: "1.52%",
      top1Percentage: "0%"
    },
    {
      id: 30927,
      name: "Brendon Todd",
      rank2024: "65",
      position2023: "47",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_30927.jpg",
      profileUrl: "https://www.pgatour.com/players/player.30927.brendon-todd.html",
      top10Percentage: "0.21%",
      top5Percentage: "0.16%",
      top30Percentage: "2.55%",
      top1Percentage: "0.04%"
    },
    {
      id: 28252,
      name: "Seamus Power",
      rank2024: "66",
      position2023: "41",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_28252.jpg",
      profileUrl: "https://www.pgatour.com/players/player.28252.seamus-power.html",
      top10Percentage: "0.16%",
      top5Percentage: "0.08%",
      top30Percentage: "1.94%",
      top1Percentage: "0%"
    },
    {
      id: 59866,
      name: "Nick Dunlap",
      rank2024: "67",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_59866.jpg",
      profileUrl: "https://www.pgatour.com/players/player.59866.nick-dunlap.html",
      top10Percentage: "0.54%",
      top5Percentage: "0.12%",
      top30Percentage: "8.74%",
      top1Percentage: "0.04%"
    },
    {
      id: 27064,
      name: "Jhonattan Vegas",
      rank2024: "68",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_27064.jpg",
      profileUrl: "https://www.pgatour.com/players/player.27064.jhonattan-vegas.html",
      top10Percentage: "0.21%",
      top5Percentage: "0.12%",
      top30Percentage: "4.37%",
      top1Percentage: "0.08%"
    },
    {
      id: 31646,
      name: "Emiliano Grillo",
      rank2024: "69",
      position2023: "T29",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_31646.jpg",
      profileUrl: "https://www.pgatour.com/players/player.31646.emiliano-grillo.html",
      top10Percentage: "0.21%",
      top5Percentage: "0.08%",
      top30Percentage: "3.50%",
      top1Percentage: "0%"
    },
    {
      id: 47679,
      name: "Victor Perez",
      rank2024: "70",
      position2023: "N/A",
      img: "https://pga-tour-res.cloudinary.com/image/upload/c_thumb,g_face,z_0.85,q_auto,dpr_2.0,w_128,h_128,b_rgb:F2F2F2/d_stub:default_avatar_light.png/headshots_47679.jpg",
      profileUrl: "https://www.pgatour.com/players/player.47679.victor-perez.html",
      top10Percentage: "0.41%",
      top5Percentage: "0.16%",
      top30Percentage: "7.09%",
      top1Percentage: "0.12%"
    }
  ];
  
