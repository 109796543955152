import React, {useRef, useEffect} from "react";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import BoardTileRow from "./BoardTileRow";
import { GAME_STATUS, BOARD_TILE_STATUSES } from "../../CrossoverGridTrivia";
import Colors from "../../../../Colors"
import BoardTile from "./BoardTile";
import { Button } from "@mui/material";

const RankingBlock = () => {
  return (
    <div
      style={{
        margin: "auto",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 5,
      }}
    >
      {["1st", "2nd", "3rd", "4th", "5th"].map((string) => {
        return (
          <div style={{ width: 100 }}>
            <Typography variant="p" style={{ fontWeight: 600, fontSize: '1.2em' }}>
              {string}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

const statusToColor = {};
statusToColor[BOARD_TILE_STATUSES.CORRECT] = Colors.GREEN;
statusToColor[BOARD_TILE_STATUSES.PRESENT] = Colors.YELLOW;
statusToColor[BOARD_TILE_STATUSES.NOT_PRESENT] = Colors.DARK_GRAY;
export default observer(({ game, facts = [], setStatsOpen }) => {
    const longestSolution = Math.max(
    ...(game?.solution || []).map(({ text }) => (text + "").length)
  );

    const rowRefs = useRef([]);

  useEffect(() => {
    rowRefs.current = rowRefs.current.slice(0, game.board.length);
    game.setRowRefs(rowRefs.current);  // Passing the refs to the game model
  }, [game, game.board.length]);



  return (
    <div style={{padding: '0px 10px', width: '100%'}}>
      <RankingBlock />
      {game.board.map((row, index) => {
        // row is len 5 array of options
        return (
          <BoardTileRow ref={el => rowRefs.current[index] = el} >
            {row.map(({ text, status, isActive }) => {
              return (
                <BoardTile
                  text={text}
                  color={statusToColor[status]}
                  borderColor={isActive ? Colors.GRAY_BORDER : undefined}
                />
              );
            })}
          </BoardTileRow>
        );
      })}
      {game.status == GAME_STATUS.LOST || game.status == GAME_STATUS.WON ? (
        <div style={{textAlign: 'center'}}>
        <BoardTileRow>
          {game.solution.map(({ text }, i) => {
            return (
              <BoardTile
                text={text}
                data={facts[i]}
                color={"rgb(66, 111, 194)"}
                wrapperStyle={{ height: longestSolution > 30 ? 80 : 70 }}
              />
            );
          })}
        </BoardTileRow>
        <Button onClick={() => setStatsOpen(true)}>📊 View Stats</Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
});
