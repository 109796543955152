import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useMemo, useState } from "react";

import { useUserContext } from "../contexts";
import Navbar from "./Nav";
import { useQueryLeaderboard } from "./UserStats/useQueryLeaderboard";
import { useQueryRank } from "./UserStats/useQueryRank";
import { SPORTS } from "./UserStats/userStats.constants";

const columns = [
  { width: "15%", label: "Rank", dataKey: "rank" },
  { width: "20%", label: "Username", dataKey: "username" },
  { width: "15%", label: "Wins", dataKey: "wins", numeric: true },
  { width: "15%", label: "Losses", dataKey: "losses", numeric: true },
  { width: "15%", label: "Draws", dataKey: "draws", numeric: true },
  { width: "20%", label: "Rating", dataKey: "elo", numeric: true },
];

const Leaderboard = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const { username } = useUserContext();
  const [selectedSport, setSelectedSport] = useState(SPORTS[0]);

  const {
    loading,
    sportId,
    leaderboard,
    onChangeSport,
    onChangePage,
    disabledMore,
    isLoadingMore,
  } = useQueryLeaderboard();
  const { rank } = useQueryRank();

  const handleChangeSport = (newSportId) => {
    const newObjSport = SPORTS.find((sport) => sport.id === newSportId);
    setSelectedSport(newObjSport || SPORTS[0]);
    onChangeSport(newSportId);
  };

  const handleChangePage = async () => {
    await onChangePage(sportId);
  };

  const isUserLeaderboard = useMemo(
    () => Boolean(leaderboard?.find((item) => item.username === username)),
    [leaderboard, username]
  );

  const getRowClasses = useCallback((isUserRow, rowIndex) => {
    if (isUserRow) {
      return "bg-green-200 text-black font-bold";
    }
    if (rowIndex % 2 === 0) {
      return "bg-green-100 text-black";
    }
    return "bg-gray-100 text-black";
  }, []);

  const getDarkModeClasses = useCallback((isUserRow, rowIndex) => {
    if (!isDarkMode) {
      return "";
    }
    if (isUserRow) {
      return "dark:bg-green-200 dark:text-white";
    }
    if (rowIndex % 2 === 0) {
      return "dark:bg-green-100 dark:text-white";
    }
    return "dark:bg-gray-800 dark:text-white";
  }, [isDarkMode]);

  return (
    <div
      className={`w-full bg-background rounded-lg border-2 ${
        isDarkMode
          ? "bg-gray-900 border-gradient-dark"
          : "bg-gray-100 border-gradient-light"
      }`}
    >
      <Navbar />
      <div className="flex flex-col items-center max-w-6xl mx-auto py-4 px-2">
        <h1
          className={`text-2xl font-bold mb-4 ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >{`Crossover Live ${selectedSport?.label} Leaderboard`}</h1>
        <div className="flex flex-wrap justify-center mb-4 space-x-2">
          {SPORTS.map((sport) => (
            <button
              key={sport.id}
              className={`px-4 py-2 rounded-lg font-bold transition-colors ${
                selectedSport.id === sport.id
                  ? "bg-blue-600 text-white"
                  : "bg-green-200 text-black"
              } ${
                isDarkMode && selectedSport.id !== sport.id
                  ? "dark:bg-gray-800 dark:text-gray-300"
                  : ""
              }`}
              onClick={() => handleChangeSport(sport.id)}
            >
              {sport.label}
            </button>
          ))}
        </div>
        <div className="overflow-x-auto w-full">
          <table className="min-w-full table-auto border border-blue-600 rounded-lg">
            <thead className="bg-blue-600 text-white">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.dataKey}
                    className="px-4 py-3 text-center font-bold text-lg bg-blue-600 text-white"
                    style={{ width: column.width }}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {leaderboard?.map((row, rowIndex) => {
                const isUserRow = row.username === username;
                const rowClasses = getRowClasses(isUserRow, rowIndex);
                const darkModeClasses = getDarkModeClasses(isUserRow, rowIndex);

                return (
                  <tr
                    key={row.username}
                    className={`${rowClasses} ${darkModeClasses}`}
                  >
                    {columns.map((column) => (
                      <td
                        key={column.dataKey}
                        className="px-4 py-3"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {row[column.dataKey]}
                        {isUserRow && "*"}
                      </td>
                    ))}
                  </tr>
                );
              })}
              {rank && !isUserLeaderboard && (
                <tr
                  className={`${
                    isDarkMode
                      ? "bg-blue-600 text-white"
                      : "bg-green-200 text-gray-900"
                  }`}
                >
                  {columns.map((column) => (
                    <td
                      key={column.dataKey}
                      className="px-4 py-3"
                      style={{ textAlign: "center" }}
                    >
                      {rank[column.dataKey]}
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {!loading && (
          <div className="flex justify-center py-4">
            <Button
              onClick={handleChangePage}
              className="bg-black text-white px-4 py-2 rounded-lg disabled:bg-gray-500"
              disabled={disabledMore || loading || isLoadingMore}
            >
              {isLoadingMore ? "Loading..." : "Load More..."}
            </Button>
          </div>
        )}
        {loading && <div className="text-black">loading...</div>}
      </div>
    </div>
  );
};

export default Leaderboard;
