import React from 'react';
import './pressrelease.css';
// Assuming 'crossover_logo' is located in the same directory. Adjust the path if needed.
import crossover_logo from "../../assets/nba.svg";

function PressRelease() {
    return (
        <div className="press-release-container">
            <div className="navbar">
                <p className="navbar-text">Crossover Sports Entertainment LLC</p>
            </div>

            <div className="banner">
                <a href="https://crossovergrid.com" target="_blank" rel="noopener noreferrer">
                    <img src={crossover_logo} alt="Crossover Grid Logo" />
                    <span>crossovergrid.com</span>
                </a>
            </div>

            {/* Rest of your JSX content remains unchanged */}
            
            <h1>Crossover Grid Adds a New Dimension: Golf and Baseball Join the Family, with More Future Enhancements Lined Up</h1>
        <p>
          [August 16, 2023] - The world of sports trivia is abuzz, thanks to the dynamic offerings from Crossover Grid. The latest in our lineup is Golf, teeing up every Monday and Thursday, and closely following the warmly received addition of Baseball.
        </p>
        <p>
          At the heart of CrossoverGrid.com lies an essence where avid sports memories intertwine with engaging trivia. In just a month since our inception, we've seen an outpouring of participation, with our community expanding to hundreds of thousands of DAUs. Fans have found a space to revisit those thrilling 'remember when' moments across sports like 🏀 basketball, ⚽ soccer, 🏈 football, 🏒 hockey, ⛳ golf, and ⚾ baseball, all while competing in our unique trivia challenges.
        </p>
        <p>
          Our recent approach to Baseball, infused with categories designed to rekindle nostalgia, has been a grand slam with our users. The enthusiastic feedback reflects that we've tapped into a cherished sentiment shared by many.
        </p>
        <p>
          But our vision goes beyond the current offerings. We're sprinting towards the release of the Crossover Grid mobile app. This upcoming development is not just about portability; it's about reimagining the trivia trend with a fresh perspective, backed by a clear and transparent sponsorship model. We believe this will be a game-changer for our tech-savvy users and potential sponsors.
        </p>
        <p>
          For those eager to get a taste of this innovation, signing up for our newsletter grants early access to the app, along with special grids, evocative sports memories, and exclusive interviews.
        </p>
        <p>
          Collaboration is intrinsic to our growth. We're keen to join forces with strategic partners and brands that align with our essence and direction. Sponsors will find prime advertising opportunities on our platform, ensuring they connect with a demographic rich in athletes and passionate sports fans. And here's a little something to pique the interest further: we're on the cusp of unveiling a new game mode. Brands eager for a sneak peek are invited to reach out.
        </p>
        <p>
          Our journey is greatly enriched by the incredible community that rallies behind us. Their feedback, stories, and shared experiences are the wind beneath our wings, pushing us to innovate, excite, and foster deeper engagement.
        </p>
        <p>
          The road ahead is packed with promise. With plans to introduce another sport and an array of new game modes, we're set to redefine how sports trivia is consumed and celebrated.
        </p>
        <p>
          For more on partnerships, feedback, or insights into our new game mode:
          <a href="mailto:Feedback@CrossoverGrid.com">Feedback@CrossoverGrid.com</a>
        </p>
        <h2>About Crossover Grid:</h2>
        <p>
          Born in mid-June, Crossover Grid is not just a platform; it's a movement celebrating the legacy, moments, and magic of sports, powered by a passionate community and their shared narratives.
        </p>
            
        <p className="play-grid-link">
                <a href="https://crossovergrid.com" target="_blank" rel="noopener noreferrer">
                    Play grids 👉 CrossoverGrid.com
                </a>
            </p>

            <div className="additional-links">
                <a href="https://twitter.com/crossovergrid" target="_blank" rel="noopener noreferrer" className="twitter-link">
                    Crossover Grid Twitter
                </a>
            </div>

            <div className="newsletter-embed">
                <iframe
                    title="Newsletter Signup"
                    src="https://embeds.beehiiv.com/cad8a297-9171-4e31-8789-a71b0b3e1115"
                    data-test-id="beehiiv-embed"
                    width="100%"
                    height="320"
                    frameborder="0"
                    scrolling="no"
                    style={{
                        borderRadius: '12px',
                        border: '0px',
                        marginBottom: '16px'
                    }}
                />
            </div>
        </div>
    );
}

export default PressRelease;