
import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'; // For the Tweet Translator
import SearchIcon from '@mui/icons-material/Search'; // For the Sports Analogy Finder
import Modal from './Modal'; // Make sure you have a Modal component similar to the one used in SportsAnalogyFinder
import crossoverTriviaLogo from "../../assets/crossover_trivia.svg";

import styles from './tool.module.css';
// Header component

  
const SportsTranslatorHub = () => {
  // State to toggle between dark and light mode
  const [darkMode, setDarkMode] = useState(false);

  // Function to toggle the mode
  const toggleMode = () => {
    setDarkMode(!darkMode);
  };
  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };

  const Header = () => (
    <div className="app-header">
      <nav className="navbar">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <a href="https://www.crossovergrid.com" target="_blank" rel="noopener noreferrer">
                <img
                  src={crossoverTriviaLogo}
                  alt="Logo"
                  className="navbar-logo"
                />
                <p
                  style={{
                    marginLeft: "10px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "0",
                    marginBottom: "0",
                  }}
                >
                  CROSSOVER GRID
                </p>
              </a>
            </div>
            <div>
              {/* Potential space for navigation or user actions */}
            </div>
          </div>
      </nav>
    </div>
  );

  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  }, []);

  // Determine the theme class based on the state
  const themeClass = darkMode ? 'app-dark' : 'app-light';

  return (
    
    <div className={`${styles.app} ${styles[themeClass]}`}>
      <Header />
      <button onClick={toggleMode}>Toggle Theme</button>
      <header className={`${styles.header} ${styles[`${themeClass}-header`]}`}>
        <h1>Sports Translator Hub</h1>
        <p>Explore the convergence of sports and comparisons between other sports</p>
      </header>
      <nav className={`${styles.features} ${styles[`${themeClass}-features`]}`}>
        <div className={`${styles.feature} ${styles[`${themeClass}-feature`]}`}>
          <Link to="/analyze" className={`${styles.featureLink} ${styles[`${themeClass}-featureLink`]}`}>
            <SportsSoccerIcon className={`${styles.icon} ${styles[`${themeClass}-icon`]}`} />
            <span>Tweet Translator</span>
            <p>Translate tweets between different sports contexts.</p>
          </Link>
        </div>
        <div className={`${styles.feature} ${styles[`${themeClass}-feature`]}`}>
          <Link to="/compare" className={`${styles.featureLink} ${styles[`${themeClass}-featureLink`]}`}>
            <SearchIcon className={`${styles.icon} ${styles[`${themeClass}-icon`]}`} />
            <span>Sports Analogy Finder</span>
            <p>Find analogies between players across sports.</p>
          </Link>
        </div>
      </nav>
      {/* Example Routes Setup - Define your components for these routes */}
      <Routes>
        <Route path="/analyze" element={<div>Tweet Translator Component</div>} />
        <Route path="/compare" element={<div>Sports Analogy Finder Component</div>} />
        {/* Add more routes as needed */}
      </Routes>

    </div>
  );
};


export default SportsTranslatorHub;
