// import React from "react";

// export default ({ children, style = {} }) => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-between",
//         ...style,
//       }}
//     >
//       {children}
//     </div>
//   );
// };

import React from "react";

export default ({ children, style = {}, variant='default' }) => {
  // if (variant === 'default') {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          ...style,
        }}
      >
        {children}
      </div>
    );
  // } 
  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       flexDirection: "row",
  //       justifyContent: "space-between",
  //       ...style,
  //     }}
  //   >
  //     {React.Children.map(children, (child) => (
  //       <div style={{ width: '20%' }}> {/* Adjust this width as needed */}
  //         <div style={{ width: '100%', paddingBottom: '100%', position: 'relative' }}>
  //           <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
  //             {child}
  //           </div>
  //         </div>
  //       </div>
  //     ))}
  //   </div>
  // );
};
