import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const logout = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setUser(null);
          console.log("Logout successful");
        } else {
          throw new Error("Failed to logout");
        }
      })
      .catch((error) => console.error("Logout error:", error))
      .finally(() => setLoading(false));
  };

  const checkAuth = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error("Unauthorized");
      })
      .then((data) => {
        console.log("USER", data);
        setUser(data?.data?.d ?? null);
      })
      .catch(() => setUser(null))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeUsername = async (newUsername) => {
    if (!user?.id?.length) return; // user not logged in

    try {
      const response = await fetch(
        `${process.env.REACT_APP_MULTIPLAYER_API_URL}/user/update`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({ userId: user?.id, username: newUsername }),
        }
      );
      if (response.ok) {
        response.json();

        checkAuth();
      }

      throw new Error("Failed to update username");
    } catch (error) {
      console.error("Update username error:", error);
    }
  };

  const values = useMemo(
    () => ({
      loading,
      user,
      username: user?.username,
      userId: user?.id,
      checkAuth,
      logout,
      onChangeUsername,
    }),
    [checkAuth, loading, user]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
