import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import crossoverTriviaLogo from '../../assets/crossover_trivia.svg';
import User from '../Auth/User';
import styles from './PageHeader.module.scss';

const PageHeader = ({ toggleTheme }) => {
  const theme = useTheme();

  const themeClass = theme.palette.mode;

  return (
    <header className={`${styles.appHeader} ${styles[themeClass]}`}>
      <nav className={styles.navbar}>
        <Container>
          <div className={styles.container}>
            <Link to="/" className={`${styles['navbar-link']} ${styles[themeClass]}`}>
              <img src={crossoverTriviaLogo} alt="Logo" className={styles.logo} />
              <span>CROSSOVER GRID</span>
            </Link>
            <User />
          </div>
        </Container>
      </nav>
    </header>
  );
};

export default PageHeader;