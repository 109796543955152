import Lottie from "lottie-react";
import basketballAnimation from "../assets/lottie/basketball.json";
import footballAnimation from "../assets/lottie/football.json";
import baseballAnimation from "../assets/lottie/baseball.json";
import comboAnimation from "../assets/lottie/combo.json";

function loadingComponent(text, sport_id) {
  if (sport_id == 1) {
    return (
      <>
        <Lottie
          animationData={basketballAnimation}
          style={{ width: 200, height: 200, marginBottom: 40 }}
          // initialSegment={[0, 100]}
        />
        <div>{text}</div>
      </>
    );
  } else if (sport_id == 7) {
    return (
      <>
        <Lottie
          animationData={footballAnimation}
          style={{ width: 200, height: 200, marginBottom: 40 }}
          // initialSegment={[0, 100]}
        />
        <div>{text}</div>
      </>
    );
  } else if (sport_id == 5) {
    return (
      <>
        <Lottie
          animationData={baseballAnimation}
          style={{ width: 200, height: 200, marginBottom: 40 }}
          // initialSegment={[0, 100]}
        />
        <div>{text}</div>
      </>
    );
  }
  return (
    <>
      <Lottie
        animationData={comboAnimation}
        style={{ width: 200, height: 200, marginBottom: 40 }}
        // initialSegment={[0, 100]}
      />
      <div>{text}</div>
    </>
  );
}

export default loadingComponent;
