import { makeStyles as makeMuiStyles } from "@mui/styles";
import propsTypes from "prop-types";
import React, { useEffect } from "react";
import "../App.scss";
import { useUserContext } from "../contexts";
import { useMultiplayerContext } from "../contexts/MultiplayerContext";
import "../fonts/DS-Digital.css"; // Import the DS-Digital font
import { GameControls } from "./TicTacToe/GameControls";
import { LoadingTicTacToe } from "./TicTacToe/LoadingTicTacToe";
import { PlaceholderGrid } from "./TicTacToe/PlaceholderGrid";
import { Scoreboard } from "./TicTacToe/Scoreboard";
// TO-DO: Make the Grid higher up on the screen, so that when the
// video comes in it doesn't overlap the grid

const TicTacToe = ({
  username,
  headers,
  gameState,
  onMakeMove,
  opponent,
  order,
  onRequestDraw,
  selectedBoxIndex,
  handleBoxClick,
  forfeit,
  currentTimer,
  sportId,
}) => {
  const { cols, rows } = headers;
  const { userId } = useUserContext();
  const { isTimerRunning, setIsTimerRunning } = useMultiplayerContext();

  const isDesktop = window.innerWidth >= 800;
  const maxLength = isDesktop >= 800 ? 30 : 18;
  const isMyTurn = gameState.nextPlayerOrder === order;

  useEffect(
    () => setIsTimerRunning(gameState?.winner === null),
    [gameState?.winner, setIsTimerRunning]
  );

  useEffect(() => {
    if (opponent !== null && gameState.winner === null) setIsTimerRunning(true);
  }, [gameState.nextPlayerOrder, opponent]);

  const useStyles = getStyles();
  const classes = useStyles();

  return (
    <div style={{ textAlign: "center", paddingBottom: 200 }}>
      {gameState && (
        <>
          {
            <Scoreboard
              userId={userId}
              isMyTurn={isMyTurn}
              correctGuesses={gameState.correctGuesses}
              isTimerRunning={isTimerRunning}
              currentTimer={currentTimer}
              username={username}
              opponentUserId={opponent?.userId}
              opponentUserName={opponent?.username}
            />
          }
          <div className="grid-container">
            {cols.length > 0 ? (
              <PlaceholderGrid
                classes={classes}
                cols={cols}
                rows={rows}
                board={gameState.board}
                maxLength={maxLength}
                isMyTurn={isMyTurn}
                sportId={sportId}
                order={order}
                username={username}
                userId={userId}
                onSelectedBoxIndex={selectedBoxIndex}
                onBoxClick={handleBoxClick}
              />
            ) : (
              <LoadingTicTacToe sportId={sportId} />
            )}
          </div>
          <GameControls
            isMyTurn={isMyTurn}
            winner={gameState?.winner}
            onRequestDraw={onRequestDraw}
            onMakeMove={onMakeMove}
            onForfeit={forfeit}
          />
        </>
      )}
    </div>
  );
};

TicTacToe.propTypes = {
  username: propsTypes.string.isRequired,
  userId: propsTypes.string.isRequired,
  headers: propsTypes.object.isRequired,
  gameState: propsTypes.object.isRequired,
  onMakeMove: propsTypes.func.isRequired,
  opponent: propsTypes.object.isRequired,
  order: propsTypes.number.isRequired,
  onRequestDraw: propsTypes.func.isRequired,
  selectedBoxIndex: propsTypes.number.isRequired,
  handleBoxClick: propsTypes.func.isRequired,
  forfeit: propsTypes.func.isRequired,
  currentTimer: propsTypes.number.isRequired,
  sportId: propsTypes.number.isRequired,
};

const getStyles = () => {
  const isDesktop = window.innerWidth >= 800;

  return makeMuiStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    card: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    close: {
      position: "absolute",
      color: "#fafafa",
    },
    boxCard: {
      backgroundColor: theme.palette.background.gridCard,
      "&:hover": {
        backgroundColor: theme.palette.background.gridCardHover,
      },
    },
    boxCardSelected: {
      backgroundColor: theme.palette.background.gridCardSelect,
    },
    link: {
      color: theme.palette.text.link,
      fontSize: isDesktop ? "16px" : "12px",
      fontWeight: "600",
    },
    searchBox: {
      backgroundColor: theme.palette.background.blurCard,
      position: "relative",
      backdropFilter: "blur(10px)",
      color: theme.palette.text.primary,
    },
    searchInput: {
      color: theme.palette.text.primary,
    },
    result: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      cursor: "pointer",
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: theme.palette.background.resultHover,
      },
    },
    sponsorshipLink: {
      marginTop: "20px",
      color: theme.palette.text.link,
      textDecoration: "none",
      borderBottom: `1px solid ${theme.palette.text.link}`,
    },
    sponsorshipLinkUnderline: {
      color: theme.palette.text.link,
      textDecoration: "none",
    },
    remainingGuesses: {
      position: "absolute",
      right: isDesktop ? "min(calc(90vw - 400px), 5vw)" : 0,
      top: "50%",
    },
    bars: {
      fontSize: "12px",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
    barLabelsXAxis: {
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: "bold",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
  }));
};

export default TicTacToe;
