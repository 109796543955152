import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

// Media query for mobile devices
const mobileScreen = "@media (max-width: 768px)";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  ${mobileScreen} {
    display: block;
  }
`;

const Modal = styled.div`
  background: #fff;
  width: 90%;
  max-width: 600px;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${mobileScreen} {
    width: 100%;
    max-width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin: 0;
  ${mobileScreen} {
    font-size: 1.25rem;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  ${mobileScreen} {
    font-size: 1.25rem;
  }
`;

const Instructions = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
  color: #333;
  ${mobileScreen} {
    font-size: 0.9rem;
  }
`;

const ExampleSection = styled.div`
  margin-top: 20px;
`;

const ExampleTile = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgColor || "#ddd"};
  color: ${(props) => (props.bgColor ? "white" : "black")};
  margin: 5px;
  padding: 8px;
  font-weight: bold;
  border-radius: 4px;
  min-width: 120px;
  text-align: center;
  ${mobileScreen} {
    min-width: 90px;
    padding: 5px;
  }
`;

const PrimeExclusiveSection = styled.div`
  background: #00000015;
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 8px;
  text-align: center;
  ${mobileScreen} {
    padding: 0.5rem;
  }
`;
// This is correct as per your last snippet
const PrimeExclusiveText = styled.p`
  font-family: 'font-prime', sans-serif;
  font-size: 0.9rem;
  margin: 0.5rem 0;
  ${mobileScreen} {
    font-size: 0.8rem;
  }
`;

const SmallLink = styled.a`
  font-family: 'font-prime', sans-serif;
  color: #0073bb;
  margin-top: 1rem;
  text-decoration: none;
  font-size: 0.8rem;
  &:hover {
    text-decoration: underline;
  }
  ${mobileScreen} {
    margin-top: 0.5rem;
  }
`;



const HowToPlayModal = ({ onClose }) => {
  return (
    // <Backdrop>
    <Dialog open={true} onClose={onClose}>
      <div style={{ padding: "10px" }}>
        <Header>
          <Typography variant="h6">How To Play Crossover Trivia</Typography>
          <IconButton onClick={onClose} style={{ padding: "0" }}>
            <CloseIcon />
          </IconButton>
        </Header>
        <Typography variant="p">
          Guess the prompt in the correct order for NFL or NBA trivia in 5
          tries. Click the player cards at the bottom to rank them based on the
          prompt like Top 5 in Rushing Touchdowns. Once you submit, the feedback
          for each guess is as follows:
          <ul>
            <li>
              <span style={{ color: "#6aaa64" }}>Green</span>: Emmitt Smith is
              in the ranking and in the correct spot.
            </li>
            <li>
              <span style={{ color: "#c9b458" }}>Yellow</span>: Adrian Peterson
              is in the ranking but in a different spot.
            </li>
            <li>
              <span style={{ color: "#787c7e" }}>Grey</span>: Franco Harris is
              not in the top 5 ranking.
            </li>
          </ul>
        </Typography>
        <ExampleSection>
  {/* Example tiles with player names */}
  <ExampleTile bgColor="#6aaa64">Emmitt Smith</ExampleTile>
  <ExampleTile bgColor="#c9b458">Adrian Peterson</ExampleTile>
  <ExampleTile bgColor="#787c7e">Franco Harris</ExampleTile>
</ExampleSection>
<PrimeExclusiveSection>
  <PrimeExclusiveText>
    Crossover Trivia is only available for Amazon Prime members.
  </PrimeExclusiveText>
  <SmallLink href="https://www.crossovergrid.com">
    Don't have Prime? Go back to the grids.
  </SmallLink>
</PrimeExclusiveSection>

      </div>
    </Dialog>
    // </Backdrop>
  );
};

export default HowToPlayModal;
