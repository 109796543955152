import React, { useState, useContext } from "react";
import { Tooltip, Avatar } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Importing MUI AccountCircle icon

import { useUserContext } from "../../contexts/UserContext";
import SignInMenu from "./SignInMenu";
import UserMenu from "./UserMenu";
import { useScreenSize } from "../../hooks/useScreenSize";
import styles from "./User.module.scss";

const User = () => {
  const { user, username, loading } = useUserContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { screenWidth } = useScreenSize();

  const handleSignInBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    setOpen(false);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  if (username) {
    return (
      <>
        <div className={styles.profile} onClick={handleUserClick}>
          {screenWidth < 480 ? (
            <Tooltip title={user.username}>
              <AccountCircleIcon />
            </Tooltip>
          ) : (
            <>
              {user.avatar && <Avatar alt={user.username} src={user.avatar} />}
              <span>{user.username}</span>
            </>
          )}
        </div>

        <UserMenu anchorEl={anchorEl} open={open} onClose={handleClose} />
      </>
    );
  }

  return (
    <>
      <button className={styles.signInButton} onClick={handleSignInBtnClick}>
        {screenWidth < 480 ? (
          <Tooltip title="Sign In">
            <AccountCircleIcon />
          </Tooltip>
        ) : (
          "Sign In"
        )}
      </button>

      <SignInMenu anchorEl={anchorEl} open={open} onClose={handleClose} />
    </>
  );
};

export default User;
