import { Container, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext, useWebSocketContext } from "../contexts";
import loadingComponent from "./LoadingComponent";

function WaitingRoom() {
  const location = useLocation();
  const state = location.state || {};
  const { sport_id } = state;
  const socket = useWebSocketContext();
  const { username } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    window.history.forward();
    if (!sport_id) navigate("/play");

    const handleBeforeUnload = () => {
      if (!socket) return;
      socket.send(
        JSON.stringify({ type: "leaveWaitingRoom", sport_id: sport_id, userId: username })
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleBeforeUnload);

    return () => {
      if (!socket) return;
      socket.send(
        JSON.stringify({ type: "leaveWaitingRoom", sport_id: sport_id, userId: username })
      );
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleBeforeUnload);
    };
  }, [socket, sport_id, username, navigate]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        navigate("/play");
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [navigate]);

  useEffect(() => {
    if (!socket) return;
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: "findMatch",
          sport_id: parseInt(sport_id),
          userId: username,
        })
      );
    }

    const handleGameStart = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "matchFound" || data.type === "rejoinGame") {
        navigate(`/play/game/?gameId=${data.gameId}&sportId=${sport_id}`);
      }
    };

    socket.addEventListener("message", handleGameStart);
    return () => {
      socket.removeEventListener("message", handleGameStart);
    };
  }, [socket?.readyState, navigate, sport_id, username]);

  const handleCancelSearch = () => {
    if (!socket) return;
    socket.send(
      JSON.stringify({ type: "leaveWaitingRoom", sport_id: sport_id, userId: username })
    );
    navigate("/play");
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      {loadingComponent("Searching for a match...", null)}
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCancelSearch}
        sx={{ mt: 2 }}
      >
        Cancel Search
      </Button>
    </Container>
  );
}

export default WaitingRoom;
