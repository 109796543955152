import React, { useState, useEffect, useRef } from "react";
import "../../src/App.scss";
import useTheme from "@mui/material/styles/useTheme";
import { makeStyles as makeMuiStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

const SearchModal = ({
  searchOpen,
  setSearchOpen,
  searchInput,
  handleInputChange,
  searchInputRef,
  searchResults,
  handleResultsClick,
  usedPlayers,
}) => {
  const isDesktop = window.innerWidth >= 800;
  const useStyles = makeMuiStyles((theme) => ({
    root: {
      flexGrow: 1,
      // padding: 2,  // 2 or '2px' or '2rem', as per your requirements
    },
    card: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    close: {
      position: "absolute",
      // right: 1,  // 1 or '1px' or '1rem', as per your requirements
      // top: 1,  // 1 or '1px' or '1rem', as per your requirements
      color: "#bdbdbd", // color from theme.palette.grey[500]
    },
    boxCard: {
      backgroundColor: theme.palette.background.gridCard,
      "&:hover": {
        backgroundColor: theme.palette.background.gridCardHover,
      },
    },
    boxCardSelected: {
      backgroundColor: theme.palette.background.gridCardSelect,
    },
    link: {
      color: theme.palette.text.link,
      fontSize: isDesktop ? "16px" : "12px",
      fontWeight: "600",
    },
    searchBox: {
      backgroundColor: theme.palette.background.blurCard,
      position: "relative",
      backdropFilter: "blur(10px)",
      color: theme.palette.text.primary,
    },
    searchInput: {
      color: theme.palette.text.primary,
    },
    result: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      cursor: "pointer",
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: theme.palette.background.resultHover,
      },
    },
    sponsorshipLink: {
      marginTop: "20px",
      color: theme.palette.text.link,
      textDecoration: "none",
      borderBottom: `1px solid ${theme.palette.text.link}`,
    },
    sponsorshipLinkUnderline: {
      color: theme.palette.text.link,
      textDecoration: "none",
    },
    remainingGuesses: {
      position: "absolute",
      right: isDesktop ? "min(calc(90vw - 400px), 5vw)" : 0,
      top: "50%",
    },
    bars: {
      fontSize: "12px",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
    barLabelsXAxis: {
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: "bold",
      textAnchor: "middle",
      fill: theme.palette.text.primary,
    },
  }));

  const classes = useStyles();
  return (
    <Modal
      open={searchOpen}
      onClose={() => setSearchOpen(false)}
      autoFocus={false}
      sx={{ touchAction: "none" }}
    >
      <Box
        sx={{
          maxHeight: "50%",
          width: "90%",
          maxWidth: "550px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          boxShadow: 3,
        }}
        // sx={{ maxHeight: "90%" }}
        className={`popup-content ${classes.searchBox}`}
        // onClick={(e) => e.stopPropagation()}
      >
        {/* <span className="close-button" onClick={() => setSearchOpen(false)}>
           &times;
         </span> */}

        <input
          ref={searchInputRef}
          autoFocus={true}
          type="text"
          className={`search-input ${classes.searchInput}`}
          value={searchInput}
          onChange={handleInputChange}
          placeholder="Search player..."
          style={{
            zIndex: 11,
            position: "sticky",
            backgroundColor: "transparent",
            // color: "#ffffff",
            fontWeight: "700",
            top: 0,
          }}
        />
        {searchResults.length > 0 && <Divider />}
        <div style={{ overflow: "auto" }}>
          <div className="results">
            {searchResults.length > 0 && (
              <p
                style={{
                  fontSize: 11,
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Results
              </p>
            )}
            {searchResults.map((result) => (
              <div
                key={result.refIndex}
                className={classes.result}
                onClick={() => {
                  if (!usedPlayers?.includes(result.item.id)) {
                    handleResultsClick(result.item);
                  } else {
                    console.log(usedPlayers);
                  }
                }}
                style={
                  usedPlayers?.includes(result.item.id) ? { opacity: 0.5 } : {}
                }
              >
                <span>{result.item.name}</span>
                <span style={{ fontSize: "12px" }}>
                  {result.item.seasons || ""}
                </span>
              </div>
            ))}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SearchModal;
