import React, { useState } from "react";
import PropTypes from "prop-types";
import { ContentTerms } from "./ContentTerms";
import { ContentPrizes } from "./ContentPrizes";
import { ContentScoring } from "./ContentScoring";
import styles from "./modal.module.scss";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className={styles.tabPanel}>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ModalHelp = ({ open, onClose }) => {
  const [value, setValue] = useState(0);

  if (!open) return null;

  const handleChange = (newValue) => setValue(newValue);

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <div className={styles.tabs}>
            <button
              className={`${styles.tab} ${value === 0 ? styles.active : ""}`}
              onClick={() => handleChange(0)}
              {...a11yProps(0)}
            >
              Scoring
            </button>
            <button
              className={`${styles.tab} ${value === 1 ? styles.active : ""}`}
              onClick={() => handleChange(1)}
              {...a11yProps(1)}
            >
              Prizes
            </button>
            <button
              className={`${styles.tab} ${value === 2 ? styles.active : ""}`}
              onClick={() => handleChange(2)}
              {...a11yProps(2)}
            >
              Terms
            </button>
          </div>
          <button className={styles.closeButton} onClick={onClose}>
            ×
          </button>
        </div>
        <CustomTabPanel value={value} index={0}>
          <ContentScoring />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ContentPrizes />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ContentTerms />
        </CustomTabPanel>
      </div>
    </div>
  );
};

ModalHelp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
