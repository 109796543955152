import React from 'react';
import './pressrelease.css';
// Assuming 'crossover_logo' is located in the same directory. Adjust the path if needed.
import crossover_logo from "../../assets/nba.svg";

function PressRelease() {
    return (
        <div className="press-release-container">
            <div className="navbar">
                <p className="navbar-text">Crossover Sports Entertainment LLC</p>
            </div>

            <div className="banner">
                <a href="https://crossovergrid.com" target="_blank" rel="noopener noreferrer">
                    <img src={crossover_logo} alt="Crossover Grid Logo" />
                    <span>crossovergrid.com</span>
                </a>
                </div>

{/* Rest of your JSX content remains unchanged */}

<h1>Privacy Policy for Crossover Sports Entertainment LLC</h1>
<p>
Welcome to the daily trivia platform of Crossover Sports Entertainment LLC. We respect your privacy and are committed to ensuring your personal information remains confidential and secure. This policy highlights our practices regarding the collection, use, and sharing of your information.
</p>
<p>
When using our website and participating in our daily trivia games, you're agreeing to our collection, use, and disclosure practices outlined in this Privacy Policy.
</p>
<h2>COLLECTION OF DATA</h2>
<p>
Our website automatically captures general data like your IP address, browser type, device type, and cookie information. When you participate in our trivia games or create an account, you might also share details like your username and email address. Google Analytics is another tool we use to understand our audience better, collecting data as per its own privacy guidelines.
</p>
<h2>USE OF DATA</h2>
<p>
Your information is pivotal to enhance your experience, manage our daily trivia operations, offer you promotions and updates, ensure our platform's safety and security, and to align our advertising practices with partners like Freestar.
</p>
<h2>CHILDREN'S ONLINE PRIVACY PROTECTION ACT (COPPA)</h2>
<p>
Our website is designed for users who are 13 years of age and older. We do not intentionally gather personal information from visitors who are under the age of 13. If we discover we have collected information from children under 13 without parental consent, we will immediately take steps to remove that data.
</p>
<h2>COOKIES</h2>
<p>
Cookies help us improve your user experience. Some advertisements on our website may access cookies on your device. These cookies are governed by the privacy policies of the respective advertisers. However, these advertisers don't have access to our site's cookies. Curious about cookies and how to manage them? You can learn more <a href="https://www.networkadvertising.org/choices/">here</a>.
</p>
<h2>SHARING YOUR INFORMATION</h2>
<p>
Your privacy matters, and we don't sell your personal information. We might share data under certain conditions, like legal requirements or if there's a change in our site's ownership.
</p>
<h2>COMPLIANCE WITH REGULATIONS</h2>
<p>
Staying compliant with regulations like the General Data Protection Regulation (GDPR) for our EU users and the California Consumer Privacy Act (CCPA) for California users is imperative for us. Do review these regulations to understand our adherence fully.
</p>
<h2>YOUR CHOICES</h2>
<p>
Your preferences matter. Opt-out of data collection by third-party advertisers using the <a href="https://optout.networkadvertising.org/?c=1">NAI Consumer Opt-Out</a>. You can also control your communication preferences in your account settings.
</p>
<h2>ADVERTISING AND FREESTAR</h2>
<p>
This Site is affiliated with Publisher First, Inc. dba Freestar ("Freestar") for the purposes of placing advertising on the Site, and Freestar will collect and use certain data for advertising purposes. To learn more about Freestar’s data usage, <a href="https://freestar.com/privacy-policy/">click here</a>.
</p>
<h2>CHANGES TO THIS PRIVACY STATEMENT</h2>
<p>
Crossover Sports Entertainment LLC may update this Privacy Statement at any time. Any new or revised Privacy Statement will be posted on the Site, and the change will be highlighted for at least 60 days after the update. Regular users are encouraged to frequently check for updates. Your continued use of the platform after changes implies your acceptance of the revised policy.
</p>
<h2>SECURITY</h2>
<p>
While we adopt reasonable security measures, ensure you exercise caution when sharing personal details. No system is entirely fail-safe.
</p>
<h2>CONTACT US</h2>
<p>
Questions or suggestions about this Privacy Policy? Drop a note at <a href="mailto:Feedback@CrossoverGrid.com">Feedback@CrossoverGrid.com</a>.
</p>
<h2>About Crossover Grid:</h2>
<p>
Launched in mid-June, Crossover Grid is more than just a platform. It’s a movement that commemorates the legacy and enchantment of sports, fueled by an enthusiastic community and their collective stories.
</p>

            
        <p className="play-grid-link">
                <a href="https://crossovergrid.com" target="_blank" rel="noopener noreferrer">
                    Play grids 👉 CrossoverGrid.com
                </a>
            </p>

            <div className="additional-links">
                <a href="https://twitter.com/crossovergrid" target="_blank" rel="noopener noreferrer" className="twitter-link">
                    Crossover Grid Twitter
                </a>
            </div>

            <div className="newsletter-embed">
                <iframe
                    title="Newsletter Signup"
                    src="https://embeds.beehiiv.com/cad8a297-9171-4e31-8789-a71b0b3e1115"
                    data-test-id="beehiiv-embed"
                    width="100%"
                    height="320"
                    frameborder="0"
                    scrolling="no"
                    style={{
                        borderRadius: '12px',
                        border: '0px',
                        marginBottom: '16px'
                    }}
                />
            </div>
        </div>
    );
}

export default PressRelease;