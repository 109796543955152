

import React, { useState, useEffect,useRef } from 'react';
import "./one.css";
import { FaQuestionCircle, FaBars,FaShareAlt } from 'react-icons/fa';
import ReactGA from "react-ga";

const API_KEY = '28bbc79d002a6a0a4ffadafedaa036c5';
const BASE_URL = 'https://api.themoviedb.org/3';
  // Initialize Google Analytics

<script defer src="/_vercel/insights/script.js"></script>;

const MovieGame = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [correctMovieDetails, setCorrectMovieDetails] = useState({});
    const [feedback, setFeedback] = useState({});
    const [gameStarted, setGameStarted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [gameOver, setGameOver] = useState(false);
    const [guesses, setGuesses] = useState(0);
    const [guessHistory, setGuessHistory] = useState([]);
    const [isEasyMode, setIsEasyMode] = useState(false);
    const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
    const [minYear, setMinYear] = useState(1990);
    const [maxYear, setMaxYear] = useState(new Date().getFullYear());
    const [isCastModalOpen, setIsCastModalOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState('');
    const [hints, setHints] = useState({
        director: false,
        releaseYear: false,
        genres: false,
        cast: false,
        runtime: false,
        rating: false,
        boxOffice: false,
      });
      const [gameOutcome, setGameOutcome] = useState(""); // Tracks whether the user won or gave up
      const [correctGuess, setCorrectGuess] = useState(false);
      const [showHintTable, setShowHintTable] = useState(false);
      const [gameMode, setGameMode] = useState('unlimited'); // 'daily' or 'unlimited'
      const [showEndGameModal, setShowEndGameModal] = useState(false);

  // New state variables for total games and wins
  const [totalGames, setTotalGames] = useState(() => parseInt(localStorage.getItem('totalGames')) || 0);
  const [totalWins, setTotalWins] = useState(() => parseInt(localStorage.getItem('totalWins')) || 0);
  const [currentScore, setCurrentScore] = useState(1100);
  const [highScore, setHighScore] = useState(() => parseInt(localStorage.getItem('highScore')) || 0);
  // Function to close the EndGameModal
  // Function to close the EndGameModal
  const closeEndGameModal = () => {
    setShowEndGameModal(false); // Directly update the state controlling the modal visibility
  };
  
  if (gameOver && currentScore > highScore) {
    setHighScore(currentScore);
    localStorage.setItem('highScore', currentScore.toString());
  }
  
  const guessDeduction = 100; // Deduction per guess
  const hintDeductions = {
    cast: 400,
    director: 200,
    releaseYear: 150,
    rating: 50,
    boxOffice: 25,
    runtime:20,

    genres: 15,
  };
  
  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
    // Check if the user prefers dark mode
    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDarkMode(prefersDarkMode);

    // Listen for changes in the dark mode preference
    const mediaQueryListener = (e) => {
      setIsDarkMode(e.matches);
    };

    window.matchMedia('(prefers-color-scheme: dark)').addListener(mediaQueryListener);

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeListener(mediaQueryListener);
    };
  }, []);
  useEffect(() => {
    // Apply or remove the dark-mode class based on the isDarkMode state
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);
  // Function to update the game statistics
  const updateGameStats = (didWin) => {
    const updatedTotalGames = totalGames + 1;
    const updatedTotalWins = didWin ? totalWins + 1 : totalWins;
  
    setTotalGames(updatedTotalGames);
    setTotalWins(updatedTotalWins);
  
    // Persist to localStorage
    localStorage.setItem('totalGames', updatedTotalGames.toString());
    localStorage.setItem('totalWins', updatedTotalWins.toString());
  };
  
      const [yearSelectionStep, setYearSelectionStep] = useState(false); // Initially not in the year selection step
      const toggleDarkMode = () => {
        const body = document.body;
        body.classList.toggle('dark-mode');
    };
    const dailyMovieId = "23168"; // Replace "550" with your specific TMDB movie ID
    useEffect(() => {
        // Example of tracking a page view when game mode changes
        ReactGA.initialize("G-MHGRFQTFDL");
        ReactGA.pageview(`/game-mode/${gameMode}`); // Track page view with game mode
        const onAdRefresh = () => {
        window.freestar?.queue.push(function () {
          window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
          window.freestar?.refresh("crossovergrid_sidewall_left");
          window.freestar?.refresh("crossovergrid_sidewall_right");
        });
      };
      
    
        onAdRefresh(); // Call the ad refresh function whenever gameMode changes
    }, [gameMode]); // Dependency array includes gameMode, so this effect runs on gameMode changes
      // Function to handle year range change and close menu
  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  }, []);

  const generateShareableScore = (guessHistory) => {
    // Example generation of colored squares based on guesses
    // Replace this logic with your actual comparison logic
    const squares = guessHistory.map(() => {
      const types = ['⬛', '🟨', '🟩']; // Black, Yellow, Green squares
      return types[Math.floor(Math.random() * types.length)];
    }).join('');
  
    // Format the shareable score
    const score = `MovieGuess - X/${guessHistory.length}\n${squares}`;
    
    return score;
  };
  

        // Including "Choose Your Years" step in the render logic
  // Including "Choose Your Years" step in the render logic
  const yearSelectionContent = (
    <div className="year-selection-step">
      <p>Narrow down to your preferred year range</p>
      <div onClick={() => handleYearRangeChangeAndCloseMenu(1980, 1989, '1980s')}>1980s</div>
      <div onClick={() => handleYearRangeChangeAndCloseMenu(1990, 1999, '1990s')}>1990s</div>
      <div onClick={() => handleYearRangeChangeAndCloseMenu(2000, 2010, '2000s')}>2000s</div>
      <div onClick={() => handleYearRangeChangeAndCloseMenu(2010, 2020, '2010s')}>2010s</div>
      <div onClick={() => handleYearRangeChangeAndCloseMenu(2020, 2024, '2020s')}>2020s</div>

      {/* Repeat for other year ranges as needed */}
    </div>
  );
      const [hintSelectionActive, setHintSelectionActive] = useState(false);
const encodeGameState = () => {
  // Simplified encoding: Just the number of guesses and the correct movie ID
  const gameState = `${guessHistory.length},${correctMovieDetails.id}`;
  const encodedState = btoa(gameState); // Base64 encode to compact the string
  return `${window.location.origin}${window.location.pathname}?gameState=${encodedState}`;
};
useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const gameStateParam = urlParams.get('gameState');
    if (gameStateParam) {
      const decodedState = atob(gameStateParam); // Base64 decode
      const [guessCount, correctMovieId] = decodedState.split(',');
      // Here you would need to fetch the correct movie details using `correctMovieId`
      // and reconstruct the guess history based on `guessCount`
      // This is simplified; a real implementation might need more detailed state encoding/decoding
    }
  }, []);
  const handleYearRangeChangeAndCloseMenu = (startYear, endYear) => {
    setMinYear(startYear);
    setMaxYear(endYear);
    setYearSelectionStep(false); // Close the year selection step/modal
    setIsMenuOpen(false); // Additionally, close the navigation menu if it's open
    fetchRandomMovie(); // Fetch a new random movie based on the new year range
  };

const [isMenuOpen, setIsMenuOpen] = useState(false); // State for toggling the menu
const navRef = useRef(); // Ref for the navigation menu
const [hintsUsed, setHintsUsed] = useState(0); // State for tracking the number of hints used

// Toggle menu function
const toggleMenu = () => {
  // This assumes you have a state 'isMenuOpen' that controls the visibility of the menu
  setIsMenuOpen((prev) => !prev);
};

useEffect(() => {
  console.log('Menu state updated:', isMenuOpen);
}, [isMenuOpen]);

// useEffect for handling clicks outside the navigation menu
useEffect(() => {
  const handleClickOutside = (event) => {
    if (isMenuOpen && navRef.current && !navRef.current.contains(event.target)) {
      setIsMenuOpen(false); // Close the menu if it's open and the click is outside the nav
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => document.removeEventListener('mousedown', handleClickOutside);
}, [isMenuOpen]);

 // New state to manage the shareable results modal
 
 const [showShareResultsModal, setShowShareResultsModal] = useState(false);

 // Enhanced end-game statistics display (after game over)
 const EndGameStatsModal = ({ isOpen, onClose, totalGuesses, hintsUsed }) => {
   if (!isOpen) return null;
// Inside EndGameStatsModal component
const shareResults = () => {
    const gameStateUrl = encodeGameState();
    let resultsSummary = `I played MovieQuest!\n`;
  
    // Assuming feedback is an array of objects with boolean values for 'correct' and 'partial'
    guessHistory.forEach((guess) => {
      let guessString = '';
      const feedbackKeys = Object.keys(guess.feedback); // ['director', 'releaseYear', 'genres', ...]
  
      feedbackKeys.forEach((key) => {
        if (guess.feedback[key].startsWith('Correct')) {
          guessString += '🟩';
        } else if (guess.feedback[key].includes('Matched') || guess.feedback[key].includes('↑') || guess.feedback[key].includes('↓')) {
          guessString += '🟨';
        } else {
          guessString += '⬛';
        }
      });
  
      resultsSummary += `${guessString}\n`; // Add the guess string to the summary
    });
  
    resultsSummary += `Can you beat my score? Check out the game: ${gameStateUrl}`;
  
    navigator.clipboard.writeText(resultsSummary).then(() => {
      alert("Game summary and link copied to clipboard. Share it with your friends!");
    }).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };
  
  const closeModal = (event) => {
    if (event.currentTarget === event.target) {
        onClose();
    }
};
  return (
    <div className="modal-backdrop" onClick={closeModal}>
    <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">Game Stats</h2>
          <button onClick={onClose} className="close-button">&times;</button>
        </div>
        <div className="modal-body">
          <p>Congratulations on completing the game!</p>
          <p>Total Guesses: <strong>{totalGuesses}</strong></p>
          <p>Hints Used: <strong>{hintsUsed}</strong></p>
          <button onClick={shareResults} className="share-results-button">
            Copy Results to Share <FaShareAlt />
          </button>
        </div>
      </div>
    </div>
  );
  
 };

    const onAdRefresh = () => {
        window.freestar?.queue.push(function () {
          window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
          window.freestar?.refresh("crossovergrid_sidewall_left");
          window.freestar?.refresh("crossovergrid_sidewall_right");
        });
      };
      
      const handleShowHint = () => {
        setHintSelectionActive(prevState => !prevState);
      };
      
 
      
      const revealHint = (hintType) => {
        // Check if the hint has already been revealed
        if (!hints[hintType]) {
          setHints(prevHints => ({
            ...prevHints,
            [hintType]: true // Set the hint as revealed
          }));
          setCurrentScore(prevScore => prevScore - hintDeductions[hintType]); // Deduct score based on the hint type
          setHintsUsed(prevHintsUsed => prevHintsUsed + 1); // Assuming you're tracking the number of hints used for other purposes
          setHintSelectionActive(false); // Close hint selection mode

        } else {
          // Hint has already been revealed; Optionally, handle this case (e.g., show a message to the user)
          console.log(`${hintType} hint has already been revealed.`);
        }
      };
      
      useEffect(() => {
        fetchRandomMovie();
      }, [minYear, maxYear, isEasyMode]);
  
      const fetchRandomMovie = async () => {
        setIsLoading(true);
        try {
          let found = false;
          while (!found) {
            const randomReleaseYear = Math.floor(Math.random() * (maxYear - minYear + 1)) + minYear;
            const response = await fetch(`${BASE_URL}/discover/movie?api_key=${API_KEY}&primary_release_year=${randomReleaseYear}&sort_by=popularity.desc`);
            const data = await response.json();
  
            if (data.results.length > 0) {
              const randomIndex = Math.floor(Math.random() * data.results.length);
              const randomMovie = data.results[randomIndex];
              const movieResponse = await fetch(`${BASE_URL}/movie/${randomMovie.id}?api_key=${API_KEY}&append_to_response=credits`);
              const movieDetails = await movieResponse.json();
  
              if (!isEasyMode || (isEasyMode && movieDetails.revenue >= 1000000000)) {
                found = true;
                setCorrectMovieDetails({
                  id: randomMovie.id,
                  title: randomMovie.title,
                  director: movieDetails.credits.crew.find(member => member.job === 'Director')?.name || '',
                  releaseYear: movieDetails.release_date.split('-')[0],
                  genres: movieDetails.genres.map(genre => genre.name),
                  cast: movieDetails.credits.cast.map(actor => actor.name),
                  runtime: movieDetails.runtime,
                  rating: movieDetails.vote_average,
                  boxOffice: movieDetails.revenue,
                });
              }
            } else {
              console.error('No movies found for the given criteria');
              setIsLoading(false);
              return;
            }
          }
        } catch (error) {
          console.error('Error fetching random movie:', error);
        } finally {
          setIsLoading(false);
        }
      };
      const fetchDailyMovie = async () => {
        setIsLoading(true);
        try {
          // Directly fetch the movie details for the dailyMovieId
          const movieResponse = await fetch(`${BASE_URL}/movie/${dailyMovieId}?api_key=${API_KEY}&append_to_response=credits`);
          const movieDetails = await movieResponse.json();
      
          // No need to check for 'found' since we are fetching a specific movie
          setCorrectMovieDetails({
            id: movieDetails.id,
            title: movieDetails.title,
            director: movieDetails.credits.crew.find(member => member.job === 'Director')?.name || '',
            releaseYear: movieDetails.release_date.split('-')[0],
            genres: movieDetails.genres.map(genre => genre.name),
            cast: movieDetails.credits.cast.slice(0, 3).map(actor => actor.name), // Taking the top 3 for simplicity
            runtime: movieDetails.runtime,
            rating: movieDetails.vote_average,
            boxOffice: movieDetails.revenue,
          });
        } catch (error) {
          console.error('Error fetching daily movie:', error);
        } finally {
          setIsLoading(false);
        }
      };
      useEffect(() => {
        if (gameMode === 'daily') {
          fetchDailyMovie();
        } else {
          fetchRandomMovie();
        }
      }, [gameMode]); // Re-fetch when gameMode changes
            
      // Year range buttons
      const handleYearRangeChange = (startYear, endYear, rangeId) => {
        setMinYear(startYear);
        setMaxYear(endYear);
        setSelectedRange(rangeId);
    };
    
    // Include other useEffects, functions, and the return statement here without modifications
    const countHintsUsed = () => {
        return Object.values(hints).filter(value => value).length;
      };
      
    const FullCastModal = ({ isOpen, onClose, cast }) => {
        if (!isOpen) return null;
      
    const closeModal = (event) => {
      if (event.currentTarget === event.target) {
          onClose();
      }
  };
        return (
          <div className="modal-backdrop" onClick={closeModal}>
          <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">Full Cast</h2>
                <button onClick={onClose} className="close-button">&times;</button>
              </div>
              <div className="modal-body modal-body-scroll">
                <ul>
                  {cast.map((actor, index) => (
                    <li key={index}>{actor}</li>
                  ))}
                </ul>
              </div>
              <div className="modal-footer">
                <button onClick={onClose} className="close-modal-button">Close</button>
              </div>
            </div>
          </div>
        );
      };
      
      

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }

    const delayDebounce = setTimeout(() => {
      const searchMovies = async () => {
        setIsLoading(true);
        const searchUrl = `${BASE_URL}/search/movie?api_key=${API_KEY}&query=${encodeURIComponent(searchTerm)}`;
        const response = await fetch(searchUrl);
        const data = await response.json();
        setSearchResults(data.results);
        setIsLoading(false);
      };

      searchMovies();
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);
// Assuming this function is defined within your component
const compareValues = (actual, correct) => {
    if (typeof actual === 'number' && typeof correct === 'number') {
      if (actual === correct) {
        return `Correct (${actual})`;
      } else {
        return actual < correct ? `↑ ${actual}` : `↓ ${actual}`;
      }
    } else {
      return actual === correct ? `Correct (${actual})` : `Incorrect (${actual})`;
    }
  };
  

// Then, when updating the feedback for box office


  const selectMovie = async (movie) => {
    setIsLoading(true);
    const detailedUrl = `${BASE_URL}/movie/${movie.id}?api_key=${API_KEY}&append_to_response=credits`;
    const response = await fetch(detailedUrl);
    const movieData = await response.json();
  // Inside selectMovie function, before setting the game state based on the guess:
setGuesses((prevGuesses) => prevGuesses + 1);
setCurrentScore(prevScore => prevScore - guessDeduction);

    // Director match check (no changes here; provided for context)
    const guessedDirector = movieData.credits.crew.find((member) => member.job === 'Director')?.name || '';
    const directorFeedback = guessedDirector === correctMovieDetails.director ? `Correct: ${guessedDirector}` : `Incorrect: ${guessedDirector}`;
  
    // Genres match check: Update to list all genres with correct ones in green and others in red
    const genresFeedback = movieData.genres.map((genre) => {
      return correctMovieDetails.genres.includes(genre.name) ? { name: genre.name, correct: true } : { name: genre.name, correct: false };
    });
    // Genres match listing
    const matchedGenres = movieData.genres
      .map((genre) => genre.name)
      .filter((genreName) => correctMovieDetails.genres.includes(genreName));
  
    // Cast match listing
    const matchedCast = movieData.credits.cast
      .map((actor) => actor.name)
      .filter((actorName) => correctMovieDetails.cast.includes(actorName));
    const castFeedback = matchedCast.length > 0 ? `Matched: ${matchedCast.join(', ')}` : 'No matched cast members';
  
    // Feedback aggregation
    const newFeedback = {
      director: directorFeedback,
      releaseYear: compareValues(parseInt(movieData.release_date.split('-')[0]), parseInt(correctMovieDetails.releaseYear)),
      genres: genresFeedback,
      cast: castFeedback,
      runtime: compareValues(movieData.runtime, correctMovieDetails.runtime),
      rating: compareValues(movieData.vote_average, correctMovieDetails.rating),
      boxOffice: compareValues(movieData.revenue, correctMovieDetails.boxOffice),
    };
  // When calling CorrectAnswerModal in your component


    if (movie.id === correctMovieDetails.id) {
      // Correct guess logic
// If correct movie is guessed or user gives up
setGameOver(true);
setShowEndGameModal(true);


      setCorrectGuess(true); // Correct guess
      setGameOutcome("win");
      setTotalGames(totalGames + 1); // Increment total games
      setTotalWins(totalWins + 1); // Increment total wins since it's a win
      localStorage.setItem('totalGames', totalGames + 1); // Update localStorage
      localStorage.setItem('totalWins', totalWins + 1);
  } else {
      // Incorrect guess logic
      setGuessHistory((prevGuessHistory) => [
          ...prevGuessHistory,
          {
              title: movie.title,
              feedback: newFeedback
          }
      ]);
      setGuesses(guesses + 1); // Increment guesses count
      // No need to update totalGames or totalWins here since the game is not over
  }
  
  
    // Regardless of guess accuracy, reset for new input and stop loading
    setSearchTerm('');
    setSearchResults([]);
    setGameStarted(true);
    setIsLoading(false);
  };
  
  const shareResults = () => {
    const gameStateUrl = encodeGameState();
    const resultsSummary = `I played MovieQuest and guessed the movie in ${guesses} guesses with ${hintsUsed} hints used. Try to beat my score! Here's the game: ${gameStateUrl} @crossovergrid 🎬🎉`;

    navigator.clipboard.writeText(resultsSummary).then(() => {
      alert("Game summary and link copied to clipboard. Share it with your friends!");
    }).catch((err) => {
      console.error("Failed to copy: ", err);
    });
};



 
  const handleCorrectGuess = () => {
    setGameOutcome("win"); // Use the correctly named state update function
    updateGameStats(correctGuess);

  };
  
  
  const HowToPlayModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    const closeModal = (event) => {
        if (event.currentTarget === event.target) {
            onClose();
        }
    };

    return (
        <div className="modal-backdrop" onClick={closeModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">How To Play</h2>
                    <button onClick={onClose} className="close-button">&times;</button>
                </div>
                <div className="modal-body modal-body-scroll">
                    <p>Welcome to Movie Quest! Your goal is to guess the secret movie. Here’s how you can play and win:</p>
            <ol>
              <li><strong>Start Your Game:</strong> Select a year range to narrow down the possible movie pool, making your challenge easier or harder. You can also toggle between Easy Mode (where blockbuster films are chosen) and Hard Mode for a wider range of movies.</li>
              <li><strong>Make a Guess:</strong> Type in the title of the movie you think is the secret film. Don't worry about exact titles—our system will find the closest match for you to select.</li>
              <li><strong>Review Feedback:</strong> After each guess, you'll receive feedback comparing your guess to the secret movie across several criteria: director, release year, genres, cast, runtime, rating, and box office earnings. Use this information to refine your next guess.</li>
              <li><strong>Use Hints Wisely:</strong> Feeling stuck? You can reveal hints about the secret movie. Be strategic, though! The fewer hints you use, the more impressive your guesswork.</li>
              <li><strong>Win or Learn:</strong> Guess the movie correctly to win. If you give up or want to explore, we’ll reveal the movie so you can discover something new or enjoy a favorite again.</li>
            </ol>
            <p className="modal-note">Hints Available: Director, Release Year, Genres, Cast, Runtime, Rating, and Box Office. Each hint you reveal gets you closer to the answer but challenges your cinema knowledge!</p>
            <p>Ready to test your movie mastery? Close this guide, adjust your settings, and start guessing. Good luck!</p>
          </div>
                <div className="modal-footer">
                    <button onClick={onClose} className="close-modal-button">Close</button>
                </div>
            </div>
        </div>
    );
};
  
const handleGiveUp = () => {
// If correct movie is guessed or user gives up
setGameOver(true);
setShowEndGameModal(true);

  updateGameStats(false); // Player did not win
  setCorrectGuess(false); // Ensure this is set to false on give up
  setGameOutcome("giveUp"); // Clearly mark game outcome as 'giveUp'
  setCurrentScore("N/A"); // Set score to "N/A" since player gave up



    // Format genres for the correct answer in the same way as guesses
    const formattedGenres = correctMovieDetails.genres.map(genreName => ({
      name: genreName,
      correct: true // Since it's the correct answer, all genres are marked correct
    }));
  


      
    // Use the actual title from correctMovieDetails for the correct answer
    setGuessHistory(guessHistory.concat({
      title: correctMovieDetails.title, // Use the actual title here
      feedback: {
        director: `Correct: ${correctMovieDetails.director}`,
        releaseYear: `Correct: ${correctMovieDetails.releaseYear}`,
        genres: formattedGenres, // Use the formatted genres here
        cast: correctMovieDetails.cast.slice(0, 3).join(', ') + (correctMovieDetails.cast.length > 3 ? '...' : ''),
        runtime: `Correct: ${correctMovieDetails.runtime} minutes`,
        rating: `Correct: ${correctMovieDetails.rating}`,
        boxOffice: `Correct: $${correctMovieDetails.boxOffice.toLocaleString()}`,
      }
    }));
  };
  
  
  
  <HowToPlayModal isOpen={isHowToPlayOpen} onClose={() => setIsHowToPlayOpen(false)} />
  const handleReset = () => {
    setSearchTerm('');
    setSearchResults([]);
    setCorrectMovieDetails({});
    setFeedback({});
    setGameStarted(false);
    setIsLoading(true);
    setGameOver(false);
    setShowEndGameModal(false); // Ensure modal is not shown for the new game

    setGuesses(0);
    setGuessHistory([]);
    setCorrectGuess(false);
    setGameOutcome("");
    setCurrentScore(1100); // Reset the current score back to the starting value
    setHints({
      director: false,
      releaseYear: false,
      genres: false,
      cast: false,
      runtime: false,
      rating: false,
      boxOffice: false,
    }); // Reset all hints to false
  
    // You might also want to reset any additional UI state related to hints here
    setShowHintTable(false);
    setHintsUsed(0); // If you're tracking the number of hints used, reset this too
  
    // Fetch a new random movie for the next game
    fetchRandomMovie(); // Make sure this function is correctly implemented to fetch a new movie
  };
  
// EndGameModal onClose handler (If you're facing an 'onClose is not defined' error, ensure this is correctly implemented)


  
  const shareScore = () => {
    const score = generateShareableScore(guessHistory);
    navigator.clipboard.writeText(score).then(() => {
      alert("Your score was copied to clipboard!");
    }).catch(err => {
      console.error("Could not copy score: ", err);
    });
  };
  
  const winPercentage = totalGames === 0 ? 0 : (totalWins / totalGames * 100).toFixed(2);
// Make sure the EndGameModal component accepts an onClose prop and uses it for the close button's onClick handler
const EndGameModal = ({
  isOpen,
  onClose,
  didWin,
  correctMovieDetails = {}, // Ensure it's an object by default
  onReset,
  onShare,
  totalGames,
  winPercentage,
}) => {
  if (!isOpen || !correctMovieDetails.title) { // Also check if title exists
    return null;
  }

  
  const handleClose = () => {
    console.log("EndGameModal: Closing modal");
    onClose();
  };

  console.log("EndGameModal: Rendering modal");

  return (
<div className="modal-backdrop end-game-modal" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{didWin ? "Congratulations!" : "Game Over"}</h2>
          <button onClick={handleClose} className="close-button">&times;</button>
        </div>
        <div className="modal-body">
          {didWin
            ? <p>You've guessed correctly! The movie is: <strong>{correctMovieDetails.title}</strong>.</p>
            : <p>Better luck next time! The correct movie was: <strong>{correctMovieDetails.title}</strong>.</p>}
          <p>Total Games Played: <strong>{totalGames}</strong></p>
          <p>Win Percentage: <strong>{winPercentage}%</strong></p>
        </div>
  <p>Game Score: {currentScore}</p>
  <p>High Score: {highScore}</p>

        <div className="modal-footer">
          <button onClick={onReset}>Play Again</button>
          <button onClick={onShare}>Share</button>
          <button onClick={handleClose}>Close</button>
        </div>
      </div>
    </div>
  );
};
const Header = ({ setIsHowToPlayOpen, toggleMenu }) => {
  return (
    <header className="game-header">
      <div className="header-content">
        <h1 className="brand-name">🍿 MovieQuest 🎬</h1>
        <div className="icon-container">
          <button className="how-to-play-button" onClick={() => setIsHowToPlayOpen(true)}>How To Play</button>
          <FaBars className="menu-icon" onClick={toggleMenu} />
        </div>
      </div>
    </header>
  );
};
const Navigation = ({ isMenuOpen, setIsMenuOpen, toggleDarkMode, setIsEasyMode, isEasyMode, setYearSelectionStep }) => {
  return (
    <nav className={`navigation-menu ${isMenuOpen ? 'open' : ''}`}>
      <ul>
        <li><a href="#" onClick={(e) => { e.preventDefault(); setYearSelectionStep(true); setIsMenuOpen(false); }}>Choose Year Range</a></li>
        <li><a href="#" onClick={(e) => { e.preventDefault(); setIsEasyMode(!isEasyMode); setIsMenuOpen(false); }}>{isEasyMode ? 'Switch to Hard Mode' : 'Switch to Easy Mode'}</a></li>
        <li><a href="https://crossovergrid.com" target="_blank" rel="noopener noreferrer">CrossoverGrid</a></li>
        <li><button onClick={toggleDarkMode}>Toggle Dark Mode</button></li>
        
      </ul>
    </nav>
  );
};
const GameModeSwitcher = ({ gameMode, setGameMode }) => {
  return (
    <div className="game-mode-switcher">
      <span className="switch-label">Unlimited</span>
      <div className="toggle-switch">
        <input
          id="mode-switch"
          className="mode-switch-checkbox"
          type="checkbox"
          checked={gameMode === 'daily'}
          onChange={() => setGameMode(gameMode === 'unlimited' ? 'daily' : 'unlimited')}
        />
        <label className="mode-switch-label" htmlFor="mode-switch"></label>
      </div>
      <span className="switch-label">Daily</span>
    </div>
  );
};

  return (
    <div className="game-page">
      <Header setIsHowToPlayOpen={setIsHowToPlayOpen} toggleMenu={toggleMenu} />

      <Navigation
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        toggleDarkMode={toggleDarkMode}
        setIsEasyMode={setIsEasyMode}
        isEasyMode={isEasyMode}
        setYearSelectionStep={setYearSelectionStep}
      />

      <div className="game-container">
        <GameModeSwitcher gameMode={gameMode} setGameMode={setGameMode} />

        <div className="score-hint-container">
          <div className="scoreboard">
            <p>Game Score: {currentScore}</p>
            <p>High Score: {highScore}</p>
            <p>Total Games Played: <strong>{totalGames}</strong></p>
            <p>Win Percentage: <strong>{winPercentage}%</strong></p>
          </div>

          {!gameOver && (
            <div className="top-actions-container">
              <button onClick={() => setShowHintTable(!showHintTable)} className="hint-toggle-button">Show Hints</button>
              <button onClick={handleGiveUp} className="give-up-button">Give Up</button>
            </div>
          )}

          {gameOver && (
            <div className="game-over-actions">
              <button onClick={handleReset} className="reset-button">Reset Game</button>
              <button onClick={shareResults} className="share-game-button">Share Your Results</button>
              <button onClick={shareScore}>Share Your Score</button>
            </div>
          )}
        </div>

        <HowToPlayModal isOpen={isHowToPlayOpen} onClose={() => setIsHowToPlayOpen(false)} />
        <FullCastModal isOpen={isCastModalOpen} onClose={() => setIsCastModalOpen(false)} cast={correctMovieDetails.cast} />      {!gameOver && (
    <>
      <div className="hint-table-container" style={{ display: showHintTable ? 'block' : 'none' }}>
        <table>
          <thead>
            <tr>
              <th>Director</th>
              <th>Release Year</th>
              <th>Genres</th>
              <th>Cast</th>
              <th>Runtime</th>
              <th>Rating</th>
              <th>Box Office</th>
            </tr>
          </thead>
          <tbody>
          <tr>
  <td onClick={() => revealHint('director')}>
    <span className="reveal-hint-button">
      <span className="reveal-hint">{hints.director ? correctMovieDetails.director : "Reveal"}</span>
      <span className="hint-cost">{hints.director ? "" : `(${hintDeductions.director} points)`}</span>
    </span>
  </td>
  <td onClick={() => revealHint('releaseYear')}>
    <span className="reveal-hint-button">
      <span className="reveal-hint">{hints.releaseYear ? correctMovieDetails.releaseYear : "Reveal"}</span>
      <span className="hint-cost">{hints.releaseYear ? "" : `(${hintDeductions.releaseYear} points)`}</span>
    </span>
  </td>
  <td onClick={() => revealHint('genres')}>
    <span className="reveal-hint-button">
      <span className="reveal-hint">{hints.genres ? correctMovieDetails.genres.join(', ') : "Reveal"}</span>
      <span className="hint-cost">{hints.genres ? "" : `(${hintDeductions.genres} points)`}</span>
    </span>
  </td>
  <td onClick={() => revealHint('cast')}>
    <span className="reveal-hint-button">
      <span className="reveal-hint">{hints.cast ? `${correctMovieDetails.cast.slice(0, 3).join(', ')}${correctMovieDetails.cast.length > 3 ? ', ...' : ''}` : "Reveal"}</span>
      <span className="hint-cost">{hints.cast ? "" : `(${hintDeductions.cast} points)`}</span>
    </span>
  </td>
  <td onClick={() => revealHint('runtime')}>
    <span className="reveal-hint-button">
      <span className="reveal-hint">{hints.runtime ? `${correctMovieDetails.runtime} minutes` : "Reveal"}</span>
      <span className="hint-cost">{hints.runtime ? "" : `(${hintDeductions.runtime} points)`}</span>
    </span>
  </td>
  <td onClick={() => revealHint('rating')}>
    <span className="reveal-hint-button">
      <span className="reveal-hint">{hints.rating ? correctMovieDetails.rating : "Reveal"}</span>
      <span className="hint-cost">{hints.rating ? "" : `(${hintDeductions.rating} points)`}</span>
    </span>
  </td>
  <td onClick={() => revealHint('boxOffice')}>
    <span className="reveal-hint-button">
      <span className="reveal-hint">{hints.boxOffice ? `$${correctMovieDetails.boxOffice.toLocaleString()}` : "Reveal"}</span>
      <span className="hint-cost">{hints.boxOffice ? "" : `(${hintDeductions.boxOffice} points)`}</span>
    </span>
  </td>
</tr>


          </tbody>
        </table>
      </div>
      {yearSelectionStep && (
    <div className="modal-overlay">
        <div className="modal-content">
            <div className="modal-header">
                <span className="modal-title">Choose Year Range</span>
                <button onClick={() => setYearSelectionStep(false)} className="close-modal-button">&times;</button>
            </div>
            <div>
                {/* Example Year Range Buttons */}
                <button onClick={() => handleYearRangeChangeAndCloseMenu(1970, 1979, '1970s')} className="year-selection-button">1970s</button>

                <button onClick={() => handleYearRangeChangeAndCloseMenu(1980, 1989, '1980s')} className="year-selection-button">1980s</button>
                <button onClick={() => handleYearRangeChangeAndCloseMenu(1990, 1999, '1990s')} className="year-selection-button">1990s</button>
                <button onClick={() => handleYearRangeChangeAndCloseMenu(2000, 2009, '2000s')} className="year-selection-button">2000s</button>
                <button onClick={() => handleYearRangeChangeAndCloseMenu(2010, 2019, '2010s')} className="year-selection-button">2010s</button>
                <button onClick={() => handleYearRangeChangeAndCloseMenu(2020, 2024, '2020s')} className="year-selection-button">2020s</button>
                <button onClick={() => handleYearRangeChangeAndCloseMenu(1950, 2024, '1950-2024')} className="year-selection-button">1950-2024</button>

                {/* Add more buttons as needed */}
            </div>
        </div>
    </div>
)}


<div className="search-container">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
          placeholder="Search for movies..."
          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()} // Prevents form submission on enter
        />
      </div>
</>
)}

<EndGameModal
  isOpen={showEndGameModal}
  onClose={closeEndGameModal}
  didWin={correctGuess}
  correctMovieDetails={correctMovieDetails}
  onReset={handleReset}
  onShare={shareResults}
  totalGames={totalGames}
  winPercentage={winPercentage}
/>



<EndGameModal   isOpen={showEndGameModal}  onClose={() =>  setShowEndGameModal(false)} />


<ul className="search-results">
  {searchResults.map((movie) => (
    <li key={movie.id} onClick={() => selectMovie(movie)} className="movie-card">
      {movie.title} <span className="release-year">({new Date(movie.release_date).getFullYear()})</span>
    </li>
  ))}
</ul>


<div className="feedback-container">
  {Object.entries(feedback).map(([key, value]) => {
    const valueStr = typeof value === 'string' ? value : ''; // Ensure value is a string
    return (
      <div key={key} className={`feedback ${valueStr.startsWith('Correct') ? 'correct' : 'incorrect'}`}>
        {key}: {valueStr}
      </div>
    );
  })}
</div>

      <div className="guess-history">
  <table>
    <thead>
      <tr>
        <th>Guess #</th>
        <th>Title</th>
        <th>Director</th>
        <th>Release Year</th>
        <th>Genres</th>
        <th>Cast</th>
        <th>Runtime</th>
        <th>Rating</th>
        <th>Box Office</th>
      </tr>
    </thead>
    <tbody>
      {[...guessHistory].reverse().map((guess, index) => (
        <tr key={index}>
          <td>{guessHistory.length - index}</td>
          <td className={guess.title === correctMovieDetails.title ? 'correct' : 'incorrect'}>
        {guess.title}
      </td>
          <td className={guess.feedback.director.startsWith('Correct') ? 'correct' : 'incorrect'}>
            {guess.feedback.director}
          </td>
          <td className={guess.feedback.releaseYear.startsWith('Correct') ? 'correct' : 'incorrect'}>
            {guess.feedback.releaseYear}
          </td>
          <td>
            {Array.isArray(guess.feedback.genres) ? guess.feedback.genres.map((genre, index) => (
              <span key={index} className={genre.correct ? 'correct' : 'incorrect'}>
                {genre.name}{index < guess.feedback.genres.length - 1 ? ', ' : ''}
              </span>
            )) : 'Invalid genre data'}
          </td>
          <td className={guess.feedback.cast.startsWith('Matched') ? 'correct' : 'incorrect'}>
            {guess.feedback.cast}
          </td>
          <td className={guess.feedback.runtime.startsWith('Correct') ? 'correct' : 'incorrect'}>
            {guess.feedback.runtime}
          </td>
          <td className={guess.feedback.rating.startsWith('Correct') ? 'correct' : 'incorrect'}>
            {guess.feedback.rating}
          </td>
          <td className={
            guess.feedback.boxOffice.startsWith('Correct') ? 'correct' :
            guess.feedback.boxOffice.includes('↑') || guess.feedback.boxOffice.includes('↓') ? 'incorrect' : ''
          }>
            {guess.feedback.boxOffice.replace(/(\d+)/, (match) => `$${parseInt(match).toLocaleString()}`)}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>


{gameOver && !isLoading && (
  
  <div className="correct-answer-container">
    <p className="correct-answer"><strong>Movie:</strong> {correctMovieDetails.title}</p>
    <p className="correct-answer"><strong>Director:</strong> {correctMovieDetails.director}</p>
    <p className="correct-answer"><strong>Release Year:</strong> {correctMovieDetails.releaseYear}</p>
    <p className="correct-answer"><strong>Genres:</strong> {correctMovieDetails.genres.join(', ')}</p>
    <p className="correct-answer">
      <strong>Cast:</strong> {correctMovieDetails.cast.slice(0, 3).join(', ')}
      {correctMovieDetails.cast.length > 3 && (
        <button onClick={() => setIsCastModalOpen(true)} className="see-all-button">See All</button>
      )}
    </p>
    <p className="correct-answer"><strong>Runtime:</strong> {correctMovieDetails.runtime} minutes</p>
    <p className="correct-answer"><strong>Rating:</strong> {correctMovieDetails.rating}</p>
    <p className="correct-answer"><strong>Box Office:</strong> ${correctMovieDetails.boxOffice.toLocaleString()}</p>
  </div>
  
)}
<EndGameModal
  isOpen={showEndGameModal}
  onClose={closeEndGameModal}
  didWin={correctGuess}
  correctMovieDetails={correctMovieDetails}
  onReset={handleReset}
  onShare={shareResults}
  totalGames={totalGames}
  winPercentage={winPercentage}
/>



<EndGameModal   isOpen={showEndGameModal}  onClose={() =>  setShowEndGameModal(false)} />

{!gameOver ? (
        <button onClick={handleGiveUp} className="give-up-button">Give Up</button>
      ) : (
        <button onClick={handleReset} className="reset-button">Reset Game</button>
      )}
      {isLoading && <div className="loading-indicator">Loading...</div>}
    </div>
    </div>

  );
};

export default MovieGame;
