
import { yellow } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './module.ConnectionsGame.css';
import crossover_logo from "../../assets/nba.svg";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Select from "@mui/material/Select"; // Import Select component for dropdown
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem for dropdown items
import HomeIcon from '@mui/icons-material/Home';
import { makeStyles as makeMuiStyles } from "@mui/styles";
import ReactGA from "react-ga";


const baseURL = "crossovergrid.com/NBA/connections";
const categories30 = [
  {
    "category": "🐺 Drafted by the Minnesota Timberwolves",
    "header": "NBA Players Drafted by the Minnesota Timberwolves",
    "items": ["Kris Dunn", "Zach LaVine", "Derrick Williams", "Corey Brewer"],
    "commonality": "NBA players who were selected in the NBA Draft by the Minnesota Timberwolves, highlighting the team's draft picks over the years",
    "color": "blue",
    "images": []
  },
  {
    "category": "⚡ Drafted by the Oklahoma City Thunder",
    "header": "NBA Players Drafted by the Oklahoma City Thunder",
    "items": ["Reggie Jackson", "Cameron Payne", "Chet Holmgren", "James Harden"],
    "commonality": "NBA players who were drafted by the Oklahoma City Thunder, showcasing the talent chosen by this franchise",
    "color": "orange",
    "images": []
  },
  {
    "category": "🏀 Scored 40+ Points in the 2024 NBA Playoffs",
    "header": "NBA Players Who Scored 40+ Points in a 2024 Playoff Game",
    "items": ["Khris Middleton", "Donovan Mitchell", "Tyrese Maxey", "Kyrie Irving"],
    "commonality": "NBA players who achieved a scoring milestone of 40 or more points in a single game during the 2024 NBA playoffs",
    "color": "green",
    "images": []
  },
  {
    "category": "🚑 Injured Players for Current Playoff Teams",
    "header": "Injured NBA Players from Teams in the Current Playoffs",
    "items": ["Kristaps Porzingis", "Maxi Kleber", "Bennedict Mathurin", "Julius Randle"],
    "commonality": "NBA players who are currently injured during the playoffs, affecting their team's lineup and strategy",
    "color": "red",
    "images": []
  }
]

const categories29 = [
  {
    "category": "🏀 Highest Career PPG (Playoffs)",
    "header": "NBA Players with the Highest Career Points Per Game in Playoffs",
    "items": ["Michael Jordan", "Luka Dončić", "Anthony Edwards", "Allen Iverson"],
    "commonality": "NBA players who have the highest career points per game average in the playoffs, showcasing exceptional scoring abilities",
    "color": "red",
    "images": []
  },
  {
    "category": "🏀 Highest Career APG (Playoffs)",
    "header": "NBA Players with the Highest Career Assists Per Game in Playoffs",
    "items": ["Magic Johnson", "John Stockton", "John Wall", "Tyrese Haliburton"],
    "commonality": "NBA players who have the highest career assists per game average in the playoffs, highlighting their playmaking excellence",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔠 NBA Players Last Names Starting with 'P'",
    "header": "NBA Players Whose Last Names Start with P",
    "items": ["Chris Paul", "Jordan Poole", "Michael Porter Jr.", "Scottie Pippen"],
    "commonality": "NBA players whose last names begin with the letter 'P'",
    "color": "green",
    "images": []
  },
  {
    "category": "🏙️ Played for Knicks and Pacers",
    "header": "NBA Players Who Played for Both the New York Knicks and Indiana Pacers",
    "items": ["Chris Copeland", "Mark Jackson", "Al Harrington", "Bojan Bogdanović"],
    "commonality": "NBA players who have been part of both the New York Knicks and Indiana Pacers rosters during their careers",
    "color": "orange",
    "images": []
  }
]

const categories28 = [
  {
    "category": "🎓 Attended Syracuse University",
    "header": "NBA Players Who Attended Syracuse",
    "items": ["Michael Carter-Williams", "Jonny Flynn", "Dion Waiters", "Carmelo Anthony"],
    "commonality": "NBA players who played college basketball at Syracuse University, known for their contributions to the sport",
    "color": "orange",
    "images": []
  },
  {
    "category": "🔠 NBA Players Last Names Starting with 'L'",
    "header": "NBA Players Whose Last Names Start with L",
    "items": ["Damian Lillard", "Kevin Love", "Caris LeVert", "Zach LaVine"],
    "commonality": "NBA players whose last names begin with the letter 'L', showcasing a diverse range of talents and impacts in the league",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏀 Drafted by the Golden State Warriors",
    "header": "NBA Players Drafted by the Golden State Warriors",
    "items": ["Marco Belinelli", "Gilbert Arenas", "Mike Dunleavy Jr.", "Stephen Curry"],
    "commonality": "NBA players who were drafted by the Golden State Warriors, contributing to various stages of the franchise's history",
    "color": "yellow",
    "images": []
  },
  {
    "category": "🇩🇪 German NBA Players",
    "header": "NBA Players from Germany",
    "items": ["Dennis Schroder", "Isaiah Hartenstein", "Dirk Nowitzki", "Chris Kaman"],
    "commonality": "NBA players who are from Germany, including one of the most notable, Dirk Nowitzki, a legend in the sport",
    "color": "red",
    "images": []
  }
]


const categories27 = [
  {
    "category": "🌟 NBA Players Aged 25 or Under",
    "header": "NBA Players Who Are 25 Years Old or Younger",
    "items": ["Anthony Edwards", "Zion Williamson", "Luka Doncic", "Scoot Henderson"],
    "commonality": "Young NBA talents who are 25 years old or younger, showcasing potential and skill early in their careers",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏀 NBA Centers",
    "header": "Prominent NBA Centers",
    "items": ["Andre Drummond", "Myles Turner", "Mitchell Robinson", "Patrick Ewing"],
    "commonality": "NBA players known for playing the center position, pivotal in defense and scoring in the paint",
    "color": "red",
    "images": []
  },
  {
    "category": "🔠 NBA Players Last Names Starting with 'V'",
    "header": "NBA Players with Last Names Starting with V",
    "items": ["Charlie Villanueva", "Greivis Vásquez", "Fred VanVleet", "Gabe Vincent"],
    "commonality": "NBA players whose last names begin with the letter 'V'",
    "color": "green",
    "images": []
  },
  {
    "category": "⏱️ Over 57 Minutes Played in a Game",
    "header": "NBA Players Who Played Over 57 Minutes in a Single Game",
    "items": ["Pascal Siakam", "Jimmy Butler", "Allen Iverson", "Tim Hardaway Jr."],
    "commonality": "NBA players who have logged more than 57 minutes in at least one NBA game, a testament to their endurance and crucial roles in extended games",
    "color": "purple",
    "images": []
  }
]

const categories26 = [
  {
    "category": "🏀 Played in the Big East Conference",
    "header": "NBA Players Who Played in the Big East",
    "items": ["Doug McDermott", "Donte DiVincenzo", "Paul Reed Jr.", "Mikal Bridges"],
    "commonality": "NBA players who competed in college basketball within the Big East Conference",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔢 Worn Jersey #50",
    "header": "NBA Players Who Wore #50",
    "items": ["Cole Anthony", "Aaron Gordon", "Ralph Sampson", "Zach Randolph"],
    "commonality": "NBA players known for wearing the jersey number 50 during their careers",
    "color": "purple",
    "images": []
  },
  {
    "category": "🎙️ Broadcasted 2024 NBA Playoffs",
    "header": "Broadcasters of the 2024 NBA Playoffs",
    "items": ["Richard Jefferson", "Brendan Haywood", "Jamal Crawford", "Dennis Scott"],
    "commonality": "Former NBA players who transitioned to broadcasting and covered the 2024 NBA Playoffs",
    "color": "green",
    "images": []
  },
  {
    "category": "🏀 Drafted 9th Overall",
    "header": "NBA Players Drafted 9th Overall",
    "items": ["Kevin Knox II", "Frank Kaminsky", "Joakim Noah", "Jakob Poeltl"],
    "commonality": "NBA players who were selected with the 9th overall pick in their respective NBA Drafts",
    "color": "red",
    "images": []
  }
]

const categories25 = [
  {
    "category": "🎓 Attended University of California, Berkeley",
    "header": "NBA Players Who Attended UC Berkeley",
    "items": ["Jaylen Brown", "Jason Kidd", "Ryan Anderson", "Allen Crabbe"],
    "commonality": "NBA players who played college basketball at the University of California, Berkeley",
    "color": "purple",
    "images": []
  },
  {
    "category": "🏀 All-Time Single Game Playoff Scoring Leaders by Franchise",
    "header": "NBA Franchise Leaders in Single Game Playoff Scoring",
    "items": ["Michael Jordan", "Elgin Baylor", "Jalen Brunson", "Allen Iverson"],
    "commonality": "NBA players who hold the record for most points scored in a single playoff game for their respective franchises",
    "color": "red",
    "images": []
  },
  {
    "category": "🏆 Former NBA Players Who Won Coach of the Year",
    "header": "Former NBA Players Turned Coaches Who Won Coach of the Year",
    "items": ["Steve Kerr", "Avery Johnson", "Larry Bird", "Doc Rivers"],
    "commonality": "Former NBA players who transitioned to coaching and earned the Coach of the Year accolade",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏀 2005 NBA Draft Class",
    "header": "Notable Players from the 2005 NBA Draft",
    "items": ["Chris Paul", "Andrew Bogut", "Deron Williams", "Nate Robinson"],
    "commonality": "Prominent NBA players selected in the 2005 NBA Draft",
    "color": "green",
    "images": []
  }
]

const categories24 = [
  {
    "category": "🏀 Attended University of Oregon",
    "header": "NBA Players Who Attended the University of Oregon",
    "items": ["Dillon Brooks", "Payton Pritchard", "Bol Bol", "Luke Ridnour"],
    "commonality": "NBA players who played college basketball for the Oregon Ducks",
    "color": "green",
    "images": []
  },
  {
    "category": "⏱️ 2023-24 Kia NBA Clutch Player of the Year Top 4",
    "header": "Top 4 Contenders for the 2023-24 NBA Clutch Player of the Year",
    "items": ["DeMar DeRozan", "Shai Gilgeous-Alexander", "Stephen Curry", "Nikola Jokic"],
    "commonality": "NBA players recognized as top performers in clutch moments during the 2023-24 season",
    "color": "purple",
    "images": []
  },
  {
    "category": "🔠 NBA Players Whose Name Starts with 'W'",
    "header": "NBA Players with First Names Starting with 'W'",
    "items": ["Walt Frazier", "Wesley Matthews", "Wendell Carter Jr.", "Will Barton"],
    "commonality": "NBA players whose first names begin with the letter 'W'",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏀 Top 4 Picks of the 2012 NBA Draft",
    "header": "Top 4 Selections of the 2012 NBA Draft",
    "items": ["Anthony Davis", "Michael Kidd-Gilchrist", "Bradley Beal", "Dion Waiters"],
    "commonality": "The first four players selected in the 2012 NBA Draft, who have each made varying impacts in the league",
    "color": "red",
    "images": []
  }
]

const categories23 = [
  {
    "category": "🏅 Top 4 in 6th Man of the Year Voting 2023-2024",
    "header": "Top 4 Finishers in the 6th Man of the Year Voting for the 2023-2024 Season",
    "items": ["Tyrese Maxey", "Coby White", "Alperen Sengun", "Jalen Williams"],
    "commonality": "NBA players who were top contenders for the 6th Man of the Year award in the 2023-2024 season",
    "color": "purple",
    "images": []
  },
  {
    "category": "🔄 Bradley Beal Trade Participants",
    "header": "Players Involved in the Bradley Beal Trade",
    "items": ["Chris Paul", "Landry Shamet", "Bilal Coulibaly", "Bradley Beal"],
    "commonality": "NBA players who were part of a trade deal involving Bradley Beal",
    "color": "blue",
    "images": []
  },
  {
    "category": "🔢 Players Who Wore or Currently Wear #4",
    "header": "NBA Players with the Jersey Number 4",
    "items": ["Jrue Holiday", "Evan Mobley", "Jalen Suggs", "Charles Barkley"],
    "commonality": "NBA players past and present who have donned the jersey number 4",
    "color": "green",
    "images": []
  },
  {
    "category": "🎓 Attended Arizona State University",
    "header": "NBA Players Who Attended Arizona State",
    "items": ["Luguentz Dort", "James Harden", "Fat Lever", "Eddie House"],
    "commonality": "NBA players who played college basketball at Arizona State University",
    "color": "red",
    "images": []
  }
]
const categories22 = [
  {
    "category": "🎷 Current Utah Jazz Players",
    "header": "Players Currently on the Utah Jazz Roster",
    "items": ["Talen Horton-Tucker", "John Collins", "Kira Lewis Jr.", "Kris Dunn"],
    "commonality": "NBA players who are currently part of the Utah Jazz team for the latest season",
    "color": "purple",
    "images": []
  },
  {
    "category": "🎓 Attended UNLV",
    "header": "NBA Players Who Attended University of Nevada, Las Vegas",
    "items": ["Derrick Jones Jr.", "Shawn Marion", "Anthony Bennett", "Lou Amundson"],
    "commonality": "NBA players who played college basketball for the UNLV Runnin' Rebels",
    "color": "red",
    "images": []
  },
  {
    "category": "🤝 Most Recent Sportsmanship Award Winners",
    "header": "NBA Sportsmanship Award Recipients in Recent Years",
    "items": ["Mike Conley", "Patty Mills", "Jrue Holiday", "Vince Carter"],
    "commonality": "NBA players who have been recognized with the Sportsmanship Award for their ethical behavior, fair play, and integrity on the court",
    "color": "green",
    "images": []
  },
  {
    "category": "🏀 2012 NBA Slam Dunk Contest Participants",
    "header": "Participants in the 2012 NBA Slam Dunk Contest",
    "items": ["Chase Budinger", "Jeremy Evans", "Paul George", "Derrick Williams"],
    "commonality": "High-flying NBA players who competed in the Slam Dunk Contest during the 2012 NBA All-Star Weekend",
    "color": "blue",
    "images": []
  }
]


const categories21 = [
  {
    "category": "🍁 Canadian NBA Players",
    "header": "NBA Players from Canada",
    "items": ["Nik Stauskas", "Tristan Thompson", "Andrew Wiggins", "Jamal Murray"],
    "commonality": "NBA players who hail from Canada, contributing to the league's international diversity",
    "color": "orange",
    "images": []
  },
  {
    "category": "🔠 NBA Players Last Names Starting with 'V'",
    "header": "NBA Players Whose Last Names Start with V",
    "items": ["Gabe Vincent", "Nikola Vučević", "Devin Vassell", "Sasha Vujačić"],
    "commonality": "NBA players whose last names begin with the letter 'V', showcasing a diverse range of talents",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏀 Attended College at Maryland",
    "header": "NBA Players Who Attended College in Maryland",
    "items": ["Kevin Huerter", "Alex Len", "Joe Smith", "Steve Blake"],
    "commonality": "NBA players who played collegiate basketball at universities located in Maryland",
    "color": "red",
    "images": []
  },
  {
    "category": "0️⃣ NBA Players Who Wear #0",
    "header": "NBA Players Donning the #0 Jersey",
    "items": ["Christian Braun", "Kevin Love", "D'Angelo Russell", "Donte DiVincenzo"],
    "commonality": "NBA players known for wearing the number 0 on their jerseys",
    "color": "green",
    "images": []
  }
]

const categories20 = [
  {
    "category": "🎓 Attended University of Oklahoma",
    "header": "NBA Players Who Attended the University of Oklahoma",
    "items": ["Buddy Hield", "Austin Reaves", "Trae Young", "Blake Griffin"],
    "commonality": "NBA players who played college basketball at the University of Oklahoma",
    "color": "red",
    "images": []
  },
  {
    "category": "⏰ Recent Buzzer Beater Game Winners",
    "header": "NBA Players with Recent Buzzer Beater Game Winners",
    "items": ["Kyrie Irving", "Bam Adebayo", "Max Strus", "Dejounte Murray"],
    "commonality": "NBA players who have hit game-winning buzzer beaters in recent games",
    "color": "green",
    "images": []
  },
  {
    "category": "🏀 Most Points in a 2022-23 Playoff Game",
    "header": "Highest Scorers in a Single 2022-23 NBA Playoff Game",
    "items": ["Jimmy Butler", "Nikola Jokić", "Jayson Tatum", "Stephen Curry"],
    "commonality": "NBA players who scored the most points in a single game during the 2022-23 NBA playoffs",
    "color": "blue",
    "images": []
  },
  {
    "category": "💵 Under $10 Million Salary for 2023-24 Season",
    "header": "NBA Players Earning Under $10 Million in 2023-24",
    "items": ["Tyrese Haliburton", "Evan Mobley", "Scottie Barnes", "Malik Monk"],
    "commonality": "NBA players who are on a contract with an annual salary of less than $10 million for the 2023-24 season",
    "color": "purple",
    "images": []
  }
]

const categories19 = [
  {
    "category": "🎓 Attended University of Michigan",
    "header": "NBA Players Who Attended the University of Michigan",
    "items": ["Tim Hardaway Jr.", "Duncan Robinson", "Franz Wagner", "Jalen Rose"],
    "commonality": "NBA players who played college basketball at the University of Michigan",
    "color": "blue",
    "images": []
  },
  {
    "category": "🚫 Suspended After the Malice at the Palace",
    "header": "Players Suspended as a Result of the Malice at the Palace",
    "items": ["Ron Artest", "Stephen Jackson", "Jermaine O'Neal", "Anthony Johnson"],
    "commonality": "NBA players who faced suspension following the infamous Malice at the Palace incident",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏆 2008-09 Los Angeles Lakers",
    "header": "Players from the 2008-09 NBA Champion Los Angeles Lakers",
    "items": ["Kobe Bryant", "Trevor Ariza", "Adam Morrison", "Luke Walton"],
    "commonality": "Members of the Los Angeles Lakers team that won the NBA Championship in the 2008-09 season",
    "color": "purple",
    "images": []
  },
  {
    "category": "🔠 NBA Players with Initials J.W.",
    "header": "NBA Players Whose Initials Are J.W.",
    "items": ["Jerry West", "John Wall", "Justise Winslow", "Jalen Williams"],
    "commonality": "NBA players with the initials J.W., a shared characteristic among them",
    "color": "red",
    "images": []
  }
]

const categories18 = [
  {
    "category": "🏀 Attended Indiana University",
    "header": "NBA Players Who Attended Indiana University",
    "items": ["Eric Gordon", "OG Anunoby", "Cody Zeller", "Yogi Ferrell"],
    "commonality": "NBA players who played college basketball at Indiana University",
    "color": "red",
    "images": []
  },
  {
    "category": "🔠 NBA Players with Initials B.B.",
    "header": "NBA Players Whose Initials are B.B.",
    "items": ["Bradley Beal", "Bruce Brown", "Bismack Biyombo", "Bojan Bogdanović"],
    "commonality": "NBA players with the initials B.B., sharing a unique alphabetical connection",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏭 2018-19 Detroit Pistons",
    "header": "Players from the 2018-19 Detroit Pistons",
    "items": ["Andre Drummond", "Reggie Bullock", "Reggie Jackson", "Luke Kennard"],
    "commonality": "NBA players who were part of the Detroit Pistons roster during the 2018-19 season",
    "color": "purple",
    "images": []
  },
  {
    "category": "🇪🇸 2020 Spain Olympic Basketball Roster",
    "header": "Members of the 2020 Spanish Olympic Basketball Team",
    "items": ["Willy Hernangómez", "Rudy Fernández", "Pau Gasol", "Ricky Rubio"],
    "commonality": "Basketball players who represented Spain at the 2020 Tokyo Olympics",
    "color": "orange",
    "images": []
  }
]


const categories17 = [
  {
    "category": "🎷 Played for the Utah Jazz",
    "header": "Notable Players Who Played for the Utah Jazz",
    "items": ["Paul Millsap", "Kyle Korver", "Pete Maravich", "Donovan Mitchell"],
    "commonality": "Impactful NBA players who have been part of the Utah Jazz franchise",
    "color": "purple",
    "images": []
  },  {
    "category": "🎓 Attended Michigan State University",
    "header": "NBA Players Who Attended Michigan State University",
    "items": ["Zach Randolph", "Draymond Green", "Jaren Jackson, Jr.", "Magic Johnson"],
    "commonality": "NBA players who played college basketball at Michigan State University",
    "color": "green",
    "images": []
  },{
    "category": "🚗 2011 NBA Dunk Contest Participants",
    "header": "Participants of the 2011 NBA Slam Dunk Contest",
    "items": ["JaVale McGee", "DeMar DeRozan", "Serge Ibaka", "Blake Griffin"],
    "commonality": "NBA players who showcased their aerial talents in the 2011 NBA Slam Dunk Contest",
    "color": "red",
    "images": []
  },


  {
    "category": "🚫 2024 NBA Block Leaders",
    "header": "NBA Block Leaders in 2024",
    "items": ["Victor Wembanyama", "Chet Holmgren", "Brook Lopez", "Anthony Davis"],
    "commonality": "NBA players who are at the forefront of blocking shots in the 2024 season",
    "color": "blue",
    "images": []
  }
]

const categories16 = [

  {
  "category": "🥇 #1 Overall Picks",
  "header": "NBA Players Who Were #1 Overall Picks in the Draft",
  "items": ["Deandre Ayton", "Andrew Wiggins", "Derrick Rose", "Ben Simmons"],
  "commonality": "NBA players who were selected as the first overall picks in their respective draft years",
  "color": "blue",
  "images": [
    "https://cdn.nba.com/headshots/nba/latest/1040x760/1629028.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/203952.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/201565.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/1627732.png"
  ]
},
{
  "category": "🏔️ Former Denver Nuggets",
  "header": "Former Denver Nuggets Players",
  "items": ["Nikola Jokić", "Carmelo Anthony", "Danilo Gallinari", "J. R. Smith"],
  "commonality": "Former NBA players who have played for the Denver Nuggets",
  "color": "green",
  "images": [
    "https://cdn.nba.com/headshots/nba/latest/1040x760/203999.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/2546.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/201568.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/2747.png"
  ]
},
{
  "category": "🇺🇸 Selected for USA Olympics Roster",
  "header": "NBA Players Selected for the USA Olympics Roster",
  "items": ["Jayson Tatum", "Kevin Durant", "Jrue Holiday", "Bam Adebayo"],
  "commonality": "NBA players selected to represent the USA in the Olympics",
  "color": "red",
  "images": [
    "https://cdn.nba.com/headshots/nba/latest/1040x760/1628369.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/201142.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/201950.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/1628389.png"
  ]
},
{
  "category": "🎓 2011 NBA Draft",
  "header": "NBA Players from the 2011 NBA Draft",
  "items": ["Kyrie Irving", "Klay Thompson", "Kawhi Leonard", "Jimmy Butler"],
  "commonality": "NBA players who were drafted in the 2011 NBA Draft",
  "color": "purple",
  "images": [
    "https://cdn.nba.com/headshots/nba/latest/1040x760/202681.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/202691.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/202695.png",
    "https://cdn.nba.com/headshots/nba/latest/1040x760/202710.png"
  ]
}
]
const categories15 = [

  {
    "category": "🏀 NBA Slam Dunk Contest Participants",
    "header": "NBA Players Who Participated in the Slam Dunk Contest",
    "items": ["Jaylen Brown", "Paul George", "Kobe Bryant", "Vince Carter"],
    "commonality": "NBA players known for their aerial artistry and participation in the NBA Slam Dunk Contest",
    "color": "orange",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1627759.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/202331.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/977.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/136.png"
    ]  },
  {
    "category": "🎓 Attended University of Arizona",
    "header": "NBA Players Who Went to the University of Arizona",
    "items": ["Deandre Ayton", "Gilbert Arenas", "Steve Kerr", "Channing Frye"],
    "commonality": "NBA players who honed their skills playing for the Arizona Wildcats",
    "color": "red",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1629028.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/2240.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/70.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/101112.png"
    ]  },  {
      "category": "🌟 Western Conference Play-In Game Stars on Tuesday",
      "header": "Star Players in the Western Conference Play-In Games on Tuesday",
      "items": ["Zion Williamson", "LeBron James", "Stephen Curry", "De'Aaron Fox"],
      "commonality": "High-profile NBA players participating in the Western Conference play-in games scheduled for a Tuesday",
      "color": "purple",
      "images": [
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1629627.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/2544.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/201939.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1628368.png"
      ]  },
  {
    "category": "⬆️ 150+ Dunks this Season",
    "header": "NBA Players with 150+ Dunks in This Season",
    "items": ["Rudy Gobert", "Jalen Duren", "Daniel Gafford", "Victor Wembanyama"],
    "commonality": "NBA players who have showcased their rim-rocking talents with over 150 dunks in the current season",
    "color": "blue",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/203497.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1631105.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4278049.png&w=350&h=254",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1641705.png"
    ]  }
]

const categories14 = [
  {
    "category": "🎬 NBA Players in 'Hustle' Movie",
    "header": "NBA Players Featured in the Movie 'Hustle'",
    "items": ["Juancho Hernangómez", "Anthony Edwards", "Kyle Lowry", "Kenny Smith"],
    "commonality": "NBA players who acted in the basketball-themed movie 'Hustle'",
    "color": "red",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1627823.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4594268.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/200768.png",
      "https://www.teamwass.com/wp-content/uploads/2023/05/Kenny-Smith-e1684979282669.jpg"
    ]
  },
  {
    "category": "🏀 Attended University of Washington",
    "header": "NBA Players Who Attended the University of Washington",
    "items": ["Markelle Fultz", "Isaiah Thomas", "Spencer Hawes", "Brandon Roy"],
    "commonality": "NBA players who played college basketball for the Washington Huskies",
    "color": "purple",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4066636.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6472.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/201150.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3027.png"
    ]
  },
  {
    "category": "🔤 NBA Players Names Starting with 'T'",
    "header": "NBA Players Whose First Names Start with the Letter T",
    "items": ["Tracy McGrady", "Tacko Fall", "Terance Mann", "Tiago Splitter"],
    "commonality": "NBA players with first names that begin with the letter 'T'",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/i/headshots/nba/players/full/532.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3904625.png&w=350&h=254",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1629611.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3233.png"
    ]
  },
  {
    "category": "🌟 All-Rookie Team 2016-2017",
    "header": "2016-2017 NBA All-Rookie Team Members",
    "items": ["Joel Embiid", "Malcolm Brogdon", "Buddy Hield", "Willy Hernangómez"],
    "commonality": "Players who were selected for the NBA All-Rookie Team in the 2016-2017 season",
    "color": "green",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/203954.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1627763.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2990984.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2999409.png"
    ]
  }
]

const categories13 = [
  {
    "category": "🏀 Never Made NBA Playoffs",
    "header": "NBA Players Who've Never Made Playoff Appearances",
    "items": ["Brandon Knight", "Lauri Markkanen", "Dennis Smith Jr.", "Victor Wembanyama"],
    "commonality": "NBA players who, up to this point, have not participated in NBA playoff games",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6448.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1628374.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4065697.png&w=350&h=254",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1641705.png"
    ]
  },
  {
    "category": "🐊 Attended University of Florida",
    "header": "NBA Players Who Attended the University of Florida",
    "items": ["Al Horford", "Bradley Beal", "Chandler Parsons", "Joakim Noah"],
    "commonality": "NBA players who played college basketball for the Florida Gators",
    "color": "blue",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/201143.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/203078.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/202718.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3224.png"
    ]
  },
  {
    "category": "🏆 NBA Champion Milwaukee Bucks",
    "header": "Players from the NBA Champion Milwaukee Bucks Team",
    "items": ["Jeff Teague", "Torrey Craig", "Donte DiVincenzo", "Giannis Antetokounmpo"],
    "commonality": "NBA players who were part of the Milwaukee Bucks' championship-winning roster",
    "color": "green",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/201952.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1628470.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3934673.png&w=350&h=254",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/203507.png"
    ]
  },
  {
    "category": "🌟 All-NBA Third Team 2009-2010",
    "header": "All-NBA Third Team Selections for 2009-2010 Season",
    "items": ["Andrew Bogut", "Joe Johnson", "Brandon Roy", "Tim Duncan"],
    "commonality": "NBA players who were selected for the All-NBA Third Team in the 2009-2010 season",
    "color": "purple",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2747.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1007.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3027.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1495.png"
    ]
  },
]

const categories12 = [
  {
    "category": "🐗 Attended University of Arkansas",
    "header": "NBA Players Who Attended the University of Arkansas",
    "items": ["Anthony Black", "Moses Moody", "Bobby Portis", "Patrick Beverley"],
    "commonality": "NBA players who played college basketball for the Arkansas Razorbacks",
    "color": "red",
    "images": []
  },
  {
    "category": "🏀 Top 10 Pick in the 2008 NBA Draft",
    "header": "Top 10 Picks of the 2008 NBA Draft",
    "items": ["Brook Lopez", "D.J. Augustin", "Danilo Gallinari", "Michael Beasley"],
    "commonality": "Players who were selected as top 10 picks in the 2008 NBA Draft",
    "color": "blue",
    "images": []
  },
  {
    "category": "🏆 2008 NBA Champion Boston Celtics",
    "header": "2008 NBA Championship Team - Boston Celtics",
    "items": ["Rajon Rondo", "Glen Davis", "Tony Allen", "Brian Scalabrine"],
    "commonality": "Players who were part of the Boston Celtics roster that won the NBA Championship in 2008",
    "color": "green",
    "images": []
  },
  {
    "category": "🌟 All-Rookie Team 2007-2008",
    "header": "2007-2008 NBA All-Rookie Team Members",
    "items": ["Jeff Green", "Luis Scola", "Rodney Stuckey", "Carl Landry"],
    "commonality": "NBA players who were named to the All-Rookie Team for the 2007-2008 season",
    "color": "orange",
    "images": []
  }
]

const categories11 = [
  {
    "category": "🏀 1x NBA Champion",
    "header": "Players with One NBA Championship",
    "items": ["DeAndre Jordan", "J.J. Barea", "Chauncey Billups", "Julius Erving"],
    "commonality": "NBA players who have won a single NBA championship throughout their careers",
    "color": "green",
    "images": []
  },
  {
    "category": "🏀 2x NBA Champion",
    "header": "Players with Two NBA Championships",
    "items": ["Wilt Chamberlain", "Bill Walton", "Ray Allen", "Kawhi Leonard"],
    "commonality": "NBA players who have clinched two NBA championship titles",
    "color": "red",
    "images": []
  },
  {
    "category": "🏀 3x NBA Champion",
    "header": "Players with Three NBA Championships",
    "items": ["Dwyane Wade", "Shaun Livingston", "Larry Bird", "Sam Cassell"],
    "commonality": "NBA players who have been part of three NBA championship-winning teams",
    "color": "purple",
    "images": []
  },
  {
    "category": "🏀 4x NBA Champion",
    "header": "Players with Four NBA Championships",
    "items": ["Shaquille O'Neal", "Kurt Rambis", "Tony Parker", "LeBron James"],
    "commonality": "NBA greats who have secured four NBA championship rings",
    "color": "blue",
    "images": []
  }
]

const categories10 = [
  {
    "category": "🏀 20+ Points in NCAA Tournament Finals and Lost",
    "header": "Players Scoring 20+ Points in NCAA Finals but Didn’t Win",
    "items": ["Greg Oden", "Trey Burke", "Jalen Suggs", "Frank Kaminsky"],
    "commonality": "NCAA basketball players who scored 20 points or more in the NCAA Tournament Finals and yet their teams did not secure the championship",
    "color": "red",
    "images": []
  },
  {
    "category": "🏆 20+ Points in NCAA Tournament Finals and Won",
    "header": "Players Scoring 20+ Points and Won NCAA Championship",
    "items": ["Donte DiVincenzo", "De'Andre Hunter", "Emeka Okafor", "Tyus Jones"],
    "commonality": "NCAA basketball players who not only scored 20 points or more in the NCAA Tournament Finals but also went on to win the championship with their team",
    "color": "purple",
    "images": []
  },
  {
    "category": "🏀 Second Round NBA Draft Gems",
    "header": "Notable Second Round Picks in the NBA Draft",
    "items": ["Jordan Clarkson", "Draymond Green", "Chandler Parsons", "Gilbert Arenas"],
    "commonality": "NBA players who were selected in the second round of the NBA Draft and outperformed expectations based on their draft position",
    "color": "green",
    "images": []
  },
  {
    "category": "🏀 Played for Coach John Calipari",
    "header": "NBA Players Coached by John Calipari in College",
    "items": ["Tyreke Evans", "Brandon Knight", "Eric Bledsoe", "Jamal Murray"],
    "commonality": "NBA players who played college basketball under the guidance of renowned coach John Calipari",
    "color": "blue",
    "images": []
  }
]

const categories9= [
  {
    "category": "👋 Retired in 2023",
    "header": "NBA Players Who Retired in 2023",
    "items": ["Goran Dragic", "Otto Porter Jr.", "Terrence Ross", "Ricky Rubio"],
    "commonality": "NBA players who announced their retirement from professional basketball in the year 2023",
    "color": "purple",
    "images": []
  },
  {
    "category": "🎯 Highest 3-Point Percentage in 2022-2023",
    "header": "Top 3-Point Shooters of the 2022-2023 NBA Season",
    "items": ["Luke Kennard", "Al Horford", "Damion Lee", "Malcolm Brogdon"],
    "commonality": "NBA players with the highest three-point shooting percentage in the 2022-2023 season",
    "color": "blue",
    "images": []
  },
  {
    "category": "🍊 Went to University of Tennessee",
    "header": "NBA Players from the University of Tennessee",
    "items": ["Tobias Harris", "Bernard King", "Grant Williams", "Josh Richardson"],
    "commonality": "Prominent NBA players who played college basketball at the University of Tennessee",
    "color": "orange",
    "images": []
  },
  {
    "category": "🏀 Teammates of LeBron James",
    "header": "NBA Players Who Were Teammates with LeBron James",
    "items": ["J.R. Smith", "Kyle Kuzma", "Alex Caruso", "Jose Calderon"],
    "commonality": "NBA players who have shared the court as teammates with LeBron James during his illustrious career",
    "color": "green",
    "images": []
  }
]

const categories8 = [
  {
    "category": "🏆 2024 NCAA Final Four (UConn, Alabama, NC State, Purdue)",
    "header": "Alumni of 2024 NCAA Final Four Colleges in the NBA",
    "items": ["Emeka Okafor", "Collin Sexton", "Spud Webb", "Jaden Ivey"],
    "commonality": "NBA players who attended the universities that participated in the 2024 NCAA Final Four: UConn, Alabama, NC State, and Purdue",
    "color": "blue",
    "images": []
  },
  {
    "category": "🎯 Highest Score in 3-Point Contest Round",
    "header": "NBA Sharpshooters with Record Scores in a Single 3-Point Contest Round",
    "items": ["Stephen Curry", "Tyrese Haliburton", "Karl-Anthony Towns", "Luke Kennard"],
    "commonality": "Players who have scored the highest points in a single round of the NBA Three-Point Contest",
    "color": "red",
    "images": []
  },
  {
    "category": "⏲️ Knicks' Starters of 2017 4 OT Game",
    "header": "New York Knicks Players Who Started the 2017 Quadruple Overtime Game",
    "items": ["Brandon Jennings", "Courtney Lee", "Joakim Noah", "Kristaps Porziņģis"],
    "commonality": "New York Knicks players who were in the starting lineup for the 2017 marathon game against the Atlanta Hawks that went to four overtimes",
    "color": "orange",
    "images": []
  },
  {
    "category": "🥉 NBA Third Overall Draft Selections",
    "header": "Elite NBA Draft Picks: The #3 Selections",
    "items": ["Michael Jordan", "Pau Gasol", "Al Horford", "Otto Porter Jr."],
    "commonality": "Renowned NBA players who were selected with the third overall pick in their respective draft years",
    "color": "green",
    "images": []
  }
]

const categories7 =[

  {
    "category": "🏀 Represented Final 4 in 2021",
    "items": ["Quentin Grimes", "Corey Kispert", "Jaime Jaquez Jr.", "Davion Mitchell"],
    "commonality": "Players who represented their college teams in the Final Four of the 2021 NCAA Tournament",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4397014.png&w=350&h=254",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1630557.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4432848.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4278053.png"
    ]
  },
  {
    "category": "🏆 2023 6th Man of the Year Top 4 Votes",
    "items": ["Malcolm Brogdon", "Immanuel Quickley", "Bobby Portis Jr.", "Norman Powell"],
    "commonality": "Players who were in the top 4 for the 6th Man of the Year award votes in 2023",
    "color": "green",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1627763.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1630193.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1626171.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1626181.png"
    ]
  },
  {
    "category": "🏀 Led League in Rebounds for a Season",
    "items": ["Hassan Whiteside", "Clint Capela", "Dennis Rodman", "Charles Barkley"],
    "commonality": "Players who have led the NBA in total rebounds for a season",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4262.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3102529.png&w=350&h=254",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/23.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/787.png"
    ]
  },
  {
    "category": "🔥 20+ Rebounds in 2024 Season",
    "items": ["Jarrett Allen", "Mark Williams", "Giannis Antetokounmpo", "Jalen Duren"],
    "commonality": "Players who have recorded 20 or more rebounds in a single game during the 2024 NBA season",
    "color": "orange",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4066328.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4701232.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3032977.png&w=350&h=254",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1631105.png"
    ]
  }
]

const categories6 =[

  {
    "category": "🛑 6+ Steals in a Game This Season",
    "header": "Defensive Standouts: 6+ Steals",
    "items": ["Matisse Thybulle", "Jalen Suggs", "Victor Wembanyama", "De'Aaron Fox"],
    "commonality": "NBA players who have recorded 6 or more steals in a single game this season",
    "color": "blue",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1629680.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1630591.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1641705.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1628368.png"
    ]
  },
  {
    "category": "☀️ Current Phoenix Sun",
    "header": "2023-2024 Phoenix Suns Roster",
    "items": ["Bradley Beal", "Eric Gordon", "Thaddeus Young", "Isaiah Thomas"],
    "commonality": "Players currently on the Phoenix Suns roster for the 2023-2024 NBA season",
    "color": "orange",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6580.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3431.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/201152.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/6472.png"
    ]
  },
  {
    "category": "💰 Undrafted Getting Paid Most AAV",
    "header": "Top Earning Undrafted Players by Average Annual Value",
    "items": ["Fred VanVleet", "Duncan Robinson", "Luguentz Dort", "Max Strus"],
    "commonality": "Undrafted NBA players with the highest average annual contract value",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2991230.png&w=350&h=254",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1629130.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4397020.png&w=350&h=254",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4065778.png&w=350&h=254"
    ]
  },
  {
    "category": "🔄 Traded for Carmelo Anthony",
    "header": "Players Traded for Carmelo Anthony",
    "items": ["Wilson Chandler", "Raymond Felton", "Danilo Gallinari", "Timofey Mozgov"],
    "commonality": "Players included in the trade deal for Carmelo Anthony",
    "color": "red",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/201163.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2753.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/201568.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/202389.png"
    ]
  }
]

const categories5 =[
  {
    "category": "🔔 5+ NBA Buzzer Beaters",
    "header": "Clutch Performers with Buzzer Beaters",
    "items": ["Andre Iguodala", "Paul Pierce", "Gilbert Arenas", "Michael Jordan"],
    "commonality": "NBA players with five or more career buzzer-beater shots",
    "color": "golpurpd",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2386.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/662.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/2240.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/893.png"
    ]
  },
  {
    "category": "🚀 Part of Houston Rockets 2007-2008 Win Streak",
    "header": "2007-2008 Houston Rockets Win Streak Contributors",
    "items": ["Shane Battier", "Dikembe Mutombo", "Luis Scola", "Tracy McGrady"],
    "commonality": "Players who were part of the Houston Rockets during their historic 2007-2008 win streak",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/976.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/87.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/2449.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1503.png"
    ]
  },
  {
    "category": "🐻 Attended UCLA",
    "header": "NBA Players Who Attended UCLA",
    "items": ["Arron Afflalo", "Trevor Ariza", "Matt Barnes", "Reggie Miller"],
    "commonality": "NBA players who attended the University of California, Los Angeles",
    "color": "blue",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3187.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/2772.png",
      "https://a.espncdn.com/i/headshots/nba/players/full/1765.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/397.png"
    ]
  },
  {
    "category": "🌟 2003 NBA Draft",
    "header": "Stars from the 2003 NBA Draft",
    "items": ["LeBron James", "Kyle Korver", "Kendrick Perkins", "Leandro Barbosa"],
    "commonality": "Notable players selected in the 2003 NBA draft",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1966.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2011.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2018.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/2166.png&w=350&h=254"
    ]
  }
]

const categories4 =[
  {
    "category": "🐅 Played College Basketball at LSU",
    "header": "Players Who Played College Basketball at LSU",
    "items": ["Ben Simmons", "Shaquille O'Neal", "Pete Maravich", "Garrett Temple"],
    "commonality": "Players who played college basketball at Louisiana State University",
    "color": "purple",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3907387.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/614.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/77459.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/202066.png"
    ]
  },
  {
    "category": "✌️ Played College Basketball at USC",
    "header": "Players Who Played College Basketball at USC",
    "items": ["DeMar DeRozan", "Onyeka Okongwu", "Nick Young", "O.J. Mayo"],
    "commonality": "Players who played college basketball at the University of Southern California",
    "color": "red",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3978.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1630168.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3243.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3450.png"
    ]
  },
  {
    "category": "👕 Worn #99",
    "header": "Players Who Have Worn #99",
    "items": ["Jae Crowder", "Justin Champagnie", "George Mikan", "Tacko Fall"],
    "commonality": "NBA players who have worn the jersey number 99",
    "color": "blue",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/203109.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4432907.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/600012.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3904625.png&w=350&h=254"
    ]
  },
  {
    "category": "🌟 2010-11 ROTY Top 4 in Votes",
    "header": "Top 4 in 2010-11 Rookie of the Year Voting",
    "items": ["Blake Griffin", "John Wall", "DeMarcus Cousins", "Landry Fields"],
    "commonality": "Players who were in the top 4 for Rookie of the Year votes for the 2010-11 NBA season",
    "color": "green",
    "images": [
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/3989.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4237.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4258.png",
      "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4274.png"
    ]
  }
]


const categories3 = [
  {
    "category": "🏀 Second Round Picks",
    "header": "Notable Second Round NBA Draft Picks",
    "items": ["Manu Ginóbili", "Gilbert Arenas", "Kyle Korver", "Bruce Brown"],
    "commonality": "Players who were selected in the second round of the NBA Draft and had significant impacts in the league",
    "color": "purple",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1938.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/2240.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/2594.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1628971.png"
    ]
  },
  {
    "category": "🐂 2013-14 Chicago Bulls Guards",
    "header": "Guards of the 2013-14 Chicago Bulls",
    "items": ["Derrick Rose", "Kirk Hinrich", "Jimmer Fredette", "Jimmy Butler"],
    "commonality": "Guard players for the Chicago Bulls during the 2013-14 NBA season",
    "color": "red",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/201565.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/2550.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/202690.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/202710.png"
    ]
  },
  {
    "category": "🏀 Traditional Centers",
    "header": "Notable Traditional Centers in the NBA",
    "items": ["Clint Capela", "Alperen Şengün", "Jakob Poeltl", "Brook Lopez"],
    "commonality": "Players recognized as traditional centers, playing a classic center role in the NBA",
    "color": "blue",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/203991.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1630578.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/1627751.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/201572.png"
    ]
  },
  {
    "category": "🌟 ESPN High School Number 1",
    "header": "ESPN Number 1 High School Recruits",
    "items": ["Brandon Jennings", "Avery Bradley", "Harrison Barnes", "Andrew Wiggins"],
    "commonality": "Players who were ranked number 1 by ESPN coming out of high school",
    "color": "orange",
    "images": [
      "https://cdn.nba.com/headshots/nba/latest/1040x760/201943.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/202340.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/203084.png",
      "https://cdn.nba.com/headshots/nba/latest/1040x760/203952.png"
    ]
  }
]

const categories2 = [
    {
      "category": "🗽 Drafted by Knicks",
      "header": "Players Drafted by New York Knicks",
      "items": ["Patrick Ewing", "Trevor Ariza", "Thanasis Antetokounmpo", "Tim Hardaway Jr."],
      "commonality": "Players who were drafted by the New York Knicks organization",
      "color": "orange",
      "images": [
        "https://cdn.nba.com/headshots/nba/latest/1040x760/121.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/2772.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/203648.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/203501.png"
      ]
    },
    {
      "category": "👔 Played in NBA and Head Coached in NBA",
      "header": "Players Who Played in NBA and Later Coached",
      "items": ["Derek Fisher", "Steve Nash", "Chauncey Billups", "Kevin Ollie"],
      "commonality": "Players who have both played in the NBA and later became head coaches",
      "color": "blue",
      "images": [
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/246.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/592.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1497.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1563.png"
      ]
    },
    {
      "category": "🏀 Longest Game-Winners in NBA History",
      "header": "Longest Game-Winning Shots in NBA History",
      "items": ["Devonte' Graham", "Max Strus", "Mahmoud Abdul-Rauf", "Julius Erving"],
      "commonality": "Notable instances of game-winning shots from significant distances in NBA history",
      "color": "green",
      "images": [
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1628984.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4065778.png&w=350&h=254",
        "https://bloximages.newyork1.vip.townnews.com/nola.com/content/tncms/assets/v3/editorial/5/eb/5ebf9ee0-c7a5-11e9-b4ce-ff9aa264a7d8/5d633daa982b2.image.png?resize=400%2C300",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/76681.png"
      ]
    },
    {
      "category": "🔄 Traded Trade Deadline This Season",
      "header": "Players Involved in Trade Deadline Deals",
      "items": ["Doug McDermott", "Seth Curry", "Gordon Hayward", "Patrick Beverley"],
      "commonality": "Players involved in trades during the NBA trade deadline",
      "color": "yellow",
      "images": [
        "https://cdn.nba.com/headshots/nba/latest/1040x760/203926.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/203552.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/202330.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/201976.png"
      ]
    }
  ]
  
  
  
const categories1 = [
    {
      "category": "🚀 Surprising 50-Point Games",
      "items": ["Terrence Ross", "T.J. Warren", "Mo Williams", "Corey Brewer"],
      "commonality": "Players not typically known for high-scoring games who have scored 50 or more points in a single NBA game, showcasing unexpected performances",
      "color": "red",
      "images": [
        "https://cdn.nba.com/headshots/nba/latest/1040x760/203082.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/203933.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/2590.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/201147.png"
      ]
    },
    {
      "category": "🔮 Current Orlando Magic Players",
      "items": ["Anthony Black", "Joe Ingles", "Gary Harris", "Markelle Fultz"],
      "commonality": "Current players on the Orlando Magic roster, showcasing a blend of youth and experience within the team",
      "color": "green",
      "images": [
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1641710.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/204060.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/203914.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1628365.png"
      ]
    },
    {
      "category": "🌟 Rookies to Score 30+ Points in a Game This Season",
      "items": ["Jordan Hawkins", "Jaime Jaquez Jr.", "GG Jackson II", "Keyonte George"],
      "commonality": "Rookie players who have scored 30 or more points in a single game this season, indicating their potential and making an immediate impact",
      "color": "blue",
      "images": [
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1641722.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4432848.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/5105550.png",
        "https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/4433627.png"
      ]
    },
    {
      "category": "🔢 Currently Wear Number 1",
      "items": ["Gradey Dick", "Mikal Bridges", "Michael Porter Jr.", "Obi Toppin"],
      "commonality": "NBA players who currently wear the jersey number 1, showcasing a range of talents and roles across different teams",
      "color": "purple",
      "images": [
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1641711.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1628969.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1629008.png",
        "https://cdn.nba.com/headshots/nba/latest/1040x760/1630167.png"
      ]
    }
  ];
  
  
  const loadCategoriesForPuzzle = (puzzleNumber) => {
    const puzzles = {
  
      '1': categories1, // Categories set for puzzle 4
      '2': categories2, // Categories set for puzzle 4
      '3': categories3, // Categories set for puzzle 4
      '4': categories4, // Categories set for puzzle 4
      '5': categories5, // Categories set for puzzle 4
      '6': categories6, // Categories set for puzzle 4
      '7': categories7, // Categories set for puzzle 4
      '8': categories8, // Categories set for puzzle 4
      '9': categories9, // Categories set for puzzle 4
      '10': categories10, // Categories set for puzzle 4
      '11': categories11, // Categories set for puzzle 4
      '12': categories12, // Categories set for puzzle 4
      '13': categories13, // Categories set for puzzle 4
      '14': categories14, // Categories set for puzzle 4
      '15': categories15, // Categories set for puzzle 4
      '16': categories16, // Categories set for puzzle 4
      '17': categories17, // Categories set for puzzle 4
      '18': categories18, // Categories set for puzzle 4
      '19': categories19, // Categories set for puzzle 4
      '20': categories20, // Categories set for puzzle 4
      '21': categories21, // Categories set for puzzle 4
      '22': categories22, // Categories set for puzzle 4
      '23': categories23, // Categories set for puzzle 4
      '24': categories24, // Categories set for puzzle 4
      '25': categories25, // Categories set for puzzle 4
      '26': categories26, // Categories set for puzzle 4
      '27': categories27, // Categories set for puzzle 4
      '28': categories28, // Categories set for puzzle 4
      '29': categories29, // Categories set for puzzle 4
      '30': categories30, // Categories set for puzzle 4

    };
  
    return puzzles[puzzleNumber] || [];
  };
  
  // Function to get puzzle numbers dynamically from the puzzles object
  const getPuzzleNumbers = () => {
    const puzzles = {
      '1': categories1, // Categories set for puzzle 4
      '2': categories2, // Categories set for puzzle 4
      '3': categories3, // Categories set for puzzle 4
      '4': categories4, // Categories set for puzzle 4
      '5': categories5, // Categories set for puzzle 4
      '6': categories6, // Categories set for puzzle 4
      '7': categories7, // Categories set for puzzle 4
      '8': categories8, // Categories set for puzzle 4
      '9': categories9, // Categories set for puzzle 4
      '10': categories10, // Categories set for puzzle 4
      '11': categories11, // Categories set for puzzle 4
      '12': categories12, // Categories set for puzzle 4
      '13': categories13, // Categories set for puzzle 4
      '14': categories14, // Categories set for puzzle 4
      '15': categories15, // Categories set for puzzle 4
      '16': categories16, // Categories set for puzzle 4
      '17': categories17, // Categories set for puzzle 4
      '18': categories18, // Categories set for puzzle 4
      '19': categories19, // Categories set for puzzle 4
      '20': categories20, // Categories set for puzzle 4
      '21': categories21, // Categories set for puzzle 4
      '22': categories22, // Categories set for puzzle 4
      '23': categories23, // Categories set for puzzle 4
      '24': categories24, // Categories set for puzzle 4
      '25': categories25, // Categories set for puzzle 4
      '26': categories26, // Categories set for puzzle 4
      '27': categories27, // Categories set for puzzle 4
      '28': categories28, // Categories set for puzzle 4
      '29': categories29, // Categories set for puzzle 4
      '30': categories30, // Categories set for puzzle 4

    };
  
    return Object.keys(puzzles);
  };
  


// Function to shuffle array items
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const ConnectionsGame = () => {
  
  const [puzzleNumber, setPuzzleNumber] = useState("30"); // Default puzzle number
  const [selectedItems, setSelectedItems] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [foundCategories, setFoundCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [gameEndedSuccessfully, setGameEndedSuccessfully] = useState(false);
  const [categories, setCategories] = useState(loadCategoriesForPuzzle(puzzleNumber)); // Load categories based on puzzle number
  const maxAttempts = 5;
  const [hintsUsed, setHintsUsed] = useState(categories.map(() => ({ bigHint: false, smallHint: false })));
  const [howToPlayOpen, setHowToPlayOpen] = useState(false);
  const [gameHasEnded, setGameHasEnded] = useState(false);
  const [theme, setTheme] = useState('light'); // default theme

  // Add useEffect hook to update categories when puzzleNumber changes
  useEffect(() => {
    // Load new categories based on the selected puzzle number
    const newCategories = loadCategoriesForPuzzle(puzzleNumber);
    setCategories(newCategories);
  
    // Reset hints for the new categories
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
  
    // Shuffle items in the new categories
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    setAllItems(shuffledItems);
  
    // Reset game state to initial values
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setGuesses([]);
    setGameEndedSuccessfully(false);
    setGameHasEnded(false); // This ensures the game is no longer in the "ended" state
  
    // Optionally, if you're storing the game state in localStorage, you might want to update/clear it here
  
  }, [puzzleNumber]);
  
  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  
  }, []);

  // Omitting existing useEffects and functions for brevity...

  // Initial setup of game state based on selected puzzle
  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${puzzleNumber}`));
    if (storedState) {
      // Logic to load game state from local storage
    } else {
      const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
      setAllItems(shuffledItems);
      resetGameState(false); // Reset without clearing local storage
    }
  }, [categories]);

  const onAdRefresh = () => {
    window.freestar?.queue.push(function () {
      window.freestar?.newStickyFooter("crossovergrid_sticky_footer");
      window.freestar?.refresh("crossovergrid_sidewall_left");
      window.freestar?.refresh("crossovergrid_sidewall_right");
    });
  };
  useEffect(() => {
    setCategories(loadCategoriesForPuzzle(puzzleNumber));
    // Include other logic for when puzzleNumber changes, like resetting the game state
  }, [puzzleNumber]);

  // Dynamically generate puzzle number options for the dropdown
  const puzzleNumberOptions = getPuzzleNumbers();
  useEffect(() => {
    // Load the categories corresponding to the selected puzzle number
    const newCategories = loadCategoriesForPuzzle(puzzleNumber);
    
    // Set the loaded categories to the state
    setCategories(newCategories);
    
    // Reset the hints used for each category in the new puzzle
    setHintsUsed(newCategories.map(() => ({ bigHint: false, smallHint: false })));
    
    // Shuffle all items from the new categories to randomize their order
    const shuffledItems = shuffleArray(newCategories.flatMap(category => category.items));
    
    // Set the shuffled items to the state, effectively resetting the game board
    setAllItems(shuffledItems);
    
    // Reset the selected items as we're starting a new puzzle
    setSelectedItems([]);
    
    // Reset the found categories as none have been found in the new puzzle
    setFoundCategories([]);
    
    // If there are additional states that depend on the puzzle number or categories,
    // reset them here to ensure the game correctly initializes for the new puzzle.
    // Example: Resetting guesses, attempts, or any other game-related states.
    
  }, [puzzleNumber]);
  

  // Function to handle year range change and automatically close menu
  useEffect(() => {
    onAdRefresh();  }, []);
    const updateFoundCategories = () => {
      let remainingItems = [...allItems];
    
      categories.forEach((category, index) => {
        const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
        const isCategoryCompletelySelected = selectedInCategory.length === category.items.length;
    
        if (isCategoryCompletelySelected && !foundCategories.includes(category)) {
          setFoundCategories(prevFoundCategories => [...prevFoundCategories, category]);
          setHintsUsed(hints => {
            const newHints = [...hints];
            newHints[index].found = true;
            return newHints;
          });
          remainingItems = remainingItems.filter(item => !category.items.includes(item));
        }
      });
    
      setAllItems(remainingItems);
    };
    
 
  // Add this useEffect hook after your existing useEffect hooks
  useEffect(() => {
    // Check if all categories have been found
    if (foundCategories.length === categories.length) {
      handleEndGame(true); // Game ends successfully
    } else if (attempts >= maxAttempts) {
      // Check if max attempts have been reached without finding all categories
      handleEndGame(false); // Game ends unsuccessfully
    }
  }, [foundCategories, attempts]); // Depend on foundCategories and attempts

  // Initial setup of game state
  useEffect(() => {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState();
  }, []);
  // Handle toggling between displaying item names and images
  const toggleDisplay = () => {
    setShowImages(prevState => !prevState);
  };

  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(`gameState-${puzzleNumber}`));
    if (storedState) {
      setGuesses(storedState.guesses || []);
      setSelectedItems(storedState.selectedItems || []);
      setFoundCategories(storedState.foundCategories || []);
      setAttempts(storedState.attempts || 0);
      // Do not set modalOpen from stored state; always start with it closed
      // Additional state can be loaded here as needed
    }
  }, []);
  // Reset game state for new games or retries
  const resetGameState = (clearLocalStorage = true) => {
    setSelectedItems([]);
    setFoundCategories([]);
    setAttempts(0);
    setModalOpen(false);
    setGuesses([]);
    if (clearLocalStorage) {
      localStorage.removeItem(`gameState-${puzzleNumber}`);
      localStorage.removeItem(`guesses-${puzzleNumber}`); // Clear guess history from local storage
    }
};

// Save guesses to local storage
const saveGuessToLocalStorage = (guess) => {
  const key = `guesses-${puzzleNumber}`;
  const guesses = JSON.parse(localStorage.getItem(key)) || [];
  guesses.push(guess);
  localStorage.setItem(key, JSON.stringify(guesses));
};

// Emoji map for category colors
const colorEmojiMap = {
  'blue': '🟦',
  'red': '🟥',
  'orange': '🟧',
  'yellow': '🟨',
  'purple': '🟪',
  'green': '🟩',

};

const HowToPlayModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <h2>How to Play Basketball Connections</h2>
        <button className="modal-close" onClick={onClose}>×</button>
        <p>Find groups of four basketball-related items that share something in common.</p>
        <p>Select four items and tap 'Submit' to check if your guess is correct.</p>
        <p>Uncover all connections without making more than 4 mistakes to win!</p>
        <h3>Category Examples</h3>
        <p>🏆 NBA Champions: Michael Jordan, Tim Duncan, Kobe Bryant, LeBron James</p>
        <p>🏀 Three-Point Contest Winners: Stephen Curry, Ray Allen, Larry Bird, Reggie Miller</p>
        <p>Note: Categories may include players, positions, records, and iconic moments in basketball history.</p>
        <p>Each puzzle has exactly one solution. Be mindful of items that may fit into more than one category!</p>
        <p>Each group is assigned a color, revealed as you solve.</p>
  
        <p>Ready to put your basketball knowledge to the test? Enjoy Basketball Connections!</p>
      </div>
    </div>
  );



};const StatsModal = ({ isOpen, onClose, guesses, congratulations = false, puzzleNumber }) => {
if (!isOpen) return null;

const safeGuesses = Array.isArray(guesses) ? guesses : [];
const baseURL = "https://crossovergrid.com"; // Adjust as needed

const copyToClipboard = async () => {
  const textToCopy = `Crossover Connections 🏀 Puzzle #${puzzleNumber} ${baseURL}/\n` +
    safeGuesses.map(guess =>
      guess.map(color => colorEmojiMap[color] || '⬜').join('')
    ).join('\n');
  await navigator.clipboard.writeText(textToCopy);
  // Assuming you have a toast notification system
  toast.success('Guesses copied to clipboard!');
};

const shareOnTwitter = () => {
  const puzzleURL = baseURL; // Adjust based on your actual URL structure
  let shareText = `Crossover Connections 🏀 Puzzle #${puzzleNumber} ${puzzleURL}\nGuesses:\n`;

  // Append guesses with emojis
  for (const guess of safeGuesses) {
    const guessLine = guess.map(color => colorEmojiMap[color] || '⬜').join('');
    shareText += `${guessLine}\n`;
  }

  // Manually replace critical characters to ensure the URL is valid
  shareText = shareText.replace(/\n/g, '%0A').replace(/#/g, '%23');

  const tweetURL = `https://twitter.com/intent/tweet?text=${shareText}`;
  window.open(tweetURL, '_blank');
};


return (
  <div className="modal-overlay" onClick={onClose}>
    <div className="modal">
      <h2>Crossover Connections 🏀 Puzzle #{puzzleNumber}</h2>
      <button className="modal-close" onClick={onClose}>×</button>
      <div>
        <p>{congratulations ? "Congratulations! You've solved the puzzle!" : "Here were your guesses:"}</p>
        <button onClick={copyToClipboard}>Copy Guesses</button>
      <button onClick={shareOnTwitter} style={{ backgroundColor: '#1DA1F2', color: '#fff', marginLeft: '10px' }}>Tweet</button>
        {safeGuesses.map((guess, index) => (
          <div key={index} className="guess-emoji-row">
            {guess.map(color => colorEmojiMap[color] || '⬜').join('')}
          </div>
        ))}
      </div>

    </div>
  </div>
);
};
// Load guesses from local storage on component mount
useEffect(() => {
  const storedGuesses = JSON.parse(localStorage.getItem(`guesses-${puzzleNumber}`)) || [];
  setGuesses(storedGuesses);
}, []);

// Handle item clicks to select or deselect items
const handleItemClick = (item) => {
  setSelectedItems(prevItems =>
    prevItems.includes(item) ? prevItems.filter(i => i !== item) : [...prevItems, item]
  );
};
const handleHintClick = (categoryIndex, hintSize) => {
  const category = categories[categoryIndex];
  if (hintSize === 'big') {
    toast.info(`The category is: ${category.category}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].bigHint = true;
      return newHints;
    });
  } else if (hintSize === 'small') {
    const miniHintItems = [category.items[0], category.items[1]]; // Take the first two items for the mini hint
    toast.info(`Two items in this category are: ${miniHintItems.join(' and ')}`);
    setHintsUsed(hints => {
      const newHints = [...hints];
      newHints[categoryIndex].smallHint = true;
      return newHints;
    });
  }
};const handleSubmit = () => {
  // Dismiss all existing toasts before showing a new one
  toast.dismiss();

  // Prevent any submissions if the game has ended
  if (gameEndedSuccessfully || attempts >= maxAttempts) {
    toast.error("Game has ended. No more submissions allowed.");
    return;
  }

  // Check for the correct number of selected items
  if (selectedItems.length !== 4) {
    toast.error("Please select exactly 4 items.");
    return;
  }

  // Extract the colors of the selected items
  const selectedColors = selectedItems.map(item =>
    categories.find(cat => cat.items.includes(item)).color
  );

  // Check if the guess has already been made
  const currentGuessSorted = [...selectedItems].sort().join(',');
  const guessAlreadyMade = guesses.some(guess =>
    [...guess].sort().join(',') === currentGuessSorted
  );

  if (guessAlreadyMade) {
    toast.error("You've already made this guess. Try a different combination.");
    return;
  }

  // Save the guess in state and localStorage
  setGuesses(prevGuesses => [...prevGuesses, selectedColors]);
  saveGuessToLocalStorage(selectedColors);

  // Find if a complete category is matched
  const matchedCategory = categories.find(category =>
    selectedItems.every(item => category.items.includes(item)) &&
    category.items.every(item => selectedItems.includes(item))
  );

  // Check for incorrect guesses
  if (!matchedCategory) {
    const threeOfAKind = categories.some(category => {
      const selectedInCategory = category.items.filter(item => selectedItems.includes(item));
      return selectedInCategory.length === 3 && !foundCategories.includes(category);
    });

    // Provide feedback based on the correctness of the guess
    if (threeOfAKind) {
      toast.info("3 of a kind. One Away");
    } else {
      toast.error("That's not a correct group. Try again!");
    }

    // Update the number of attempts
    setAttempts(prevAttempts => prevAttempts + 1);

    // Check if the game should end
    if (attempts + 1 >= maxAttempts) {
      handleEndGame(false);
    }
  } else {
    // Handle a successful category match
    updateFoundCategories();
    setSelectedItems([]); // Clear selection for a new guess
    toast.success("Category Found!"); // Notify the user of a successful match
  }
};

// Apply toast.dismiss() similarly in other functions where toast notifications are shown

const handleEndGame = (success) => {
  setGameEndedSuccessfully(success);
  setGameHasEnded(true); // Indicate that the game has ended
  setModalOpen(true); // Optionally open the stats modal immediately if you prefer
};


const handleResetGame = () => {
  if (window.confirm("Are you sure you want to reset the game? All progress will be lost.")) {
    const shuffledItems = shuffleArray(categories.flatMap(category => category.items));
    setAllItems(shuffledItems);
    resetGameState(); // Function to reset game state
    // Also clear any relevant localStorage entries here
  }
};
const [showImages, setShowImages] = useState(false);
useEffect(() => {
  document.body.setAttribute('data-theme', theme);
}, [theme]);

return (
  <div className="connections-game">
   <nav className="navbar">
    {/* Left-aligned Logo and Crossover MLB text, centered alignment */}
    <a href="https://crossovergrid.com" 
       style={{ 
         textDecoration: "none", 
         color: "inherit", 
         display: "flex", 
         alignItems: "center", // This ensures vertical alignment
         justifyContent: "center" // This centers the content horizontally if the a-tag is a flex container
       }}>
      <img src={crossover_logo} alt="Logo" className="navbar-logo" />
      <p style={{ 
          margin: 0, // Removes default margin that might offset alignment
          fontWeight: "bold", 
          fontSize: 20,
          display: "flex", // Makes the paragraph itself a flex container
          alignItems: "center", // Centers the text inside the p-tag vertically
          justifyContent: "center" // Centers the text inside the p-tag horizontally
        }}>
        CROSSOVER NBA
      </p>
    </a>

    {/* Spacer to center the Crossover MLB logo and text in the navbar */}
    <div style={{ flexGrow: 1 }}></div>

    {/* Home and HowToPlayModal Icons */}
    <div>
      <IconButton href="/" title="Home">
        <HomeIcon />
      </IconButton>
      <IconButton onClick={() => setHowToPlayOpen(true)} title="How to Play">
        <HelpOutlineIcon />
      </IconButton>
    </div>

    <HowToPlayModal isOpen={howToPlayOpen} onClose={() => setHowToPlayOpen(false)} />
  </nav>


<ToastContainer position="top-center" />
    <h1>NBA Connections</h1>     <Select
  value={puzzleNumber}
  onChange={(e) => setPuzzleNumber(e.target.value)}
  displayEmpty
  inputProps={{ 'aria-label': 'Select puzzle' }}
  MenuProps={{
    PaperProps: {
      style: {
        maxHeight: 200, // Adjust based on your needs
        overflow: 'auto'
      }
    }
  }}
>
  {puzzleNumberOptions.map((number) => (
    <MenuItem key={number} value={number}>
      Puzzle {number}
    </MenuItem>
  ))}
</Select>

    <StatsModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      guesses={guesses}
      gameEndedSuccessfully={gameEndedSuccessfully}
      puzzleNumber={puzzleNumber} 

    />
    {!gameHasEnded && (
      <>
<div className="items-container">
  {allItems.map((item, index) => {
    const category = categories.find(category => category.items.includes(item));
    const itemIndexInCategory = category.items.indexOf(item);
    const imageUrl = category.images[itemIndexInCategory];
    const isSelected = selectedItems.includes(item);
    
    const itemStyle = isSelected
    ? theme === 'dark'
      ? { backgroundColor: '#ffcc00', color: 'black' } // Dark mode: bright background, dark text
      : { backgroundColor: '#ddd', color: 'black' } // Light mode: light background, dark text
    : {};
  
  return (
    <div
      key={index}
      className={`item ${showImages ? 'item-showing-image' : ''} ${isSelected ? 'selected' : ''}`}
      onClick={() => handleItemClick(item)}
      aria-pressed={isSelected ? 'true' : 'false'}
      style={itemStyle} // Apply the inline styles
    >
      {showImages ? (
        <img src={imageUrl} alt={item} />
      ) : (
        // The text color will change based on the style conditionally set above
        <span>{item}</span>
      )}
    </div>
  );
})}
</div>

      </>
    )}
 <div className="controls">
 {!gameHasEnded && (
      <> 
      <button onClick={() => setSelectedItems([])} disabled={selectedItems.length === 0}>
        Deselect All
      </button>
      <button onClick={handleSubmit} disabled={gameEndedSuccessfully || attempts >= maxAttempts}>
        Submit
      </button>
      <button onClick={toggleDisplay}>
        {showImages ? "Show Item Names" : "Show Images"}
      </button> </>
   )}
      {/* Conditionally render the View Stats button if the game has ended */}

  </div>
  

  {gameHasEnded && (
  <button onClick={() => setModalOpen(true)}>
    View Stats
  </button>
)}
   {gameHasEnded && (
      <div className="end-game-display">
        <p>{gameEndedSuccessfully ? "Congratulations! You've completed the game!" : "Game Over. Better luck next time!"}</p>
        <div className="correct-answers">
          <h2>Correct Answers:</h2>
          {categories.map((category, index) => (
            <div key={index} className="category-display" style={{ borderColor: category.color, borderWidth: 1, borderStyle: 'solid', borderRadius: '10px', margin: '.5px 0', padding: '2px' }}>
              <h3 style={{ textAlign: 'center', color: category.color, margin: '2px 0', borderRadius: '10px' }}>{category.category}</h3>
              <div style={{ display: 'flex', justifyContent: 'space-around', padding: '2px', flexWrap: 'wrap' }}>
                {category.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="category-item" style={{ margin: '0px', textAlign: 'center', maxWidth: '120px' }}>
                    <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '2px', width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto 0px', backgroundColor: '#fff' }}>
                      <img src={category.images[itemIndex]} alt={item} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', borderRadius: '8px' }} />
                    </div>
                    <p style={{ fontSize: '0.8em', margin: '0' }}>{item}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )}



{!gameHasEnded && (
      <> 
    {/* Status display section */}
    <div className="status">
      <p>Mistakes Remaining: {'⚫️'.repeat(maxAttempts - attempts) + '⚪️'.repeat(attempts)}</p>
      <p>Found Categories: {foundCategories.map(c => c.category).join(", ")}</p>
    </div>

    {/* Hints section */}
    <div className="hints-section">
      <h2>HINTS</h2>
      {categories.map((category, index) => {
        const isCategoryFound = foundCategories.includes(category);
        const categoryStyle = {
          borderColor: category.color,
          borderStyle: 'solid',
          borderWidth: '2px',
          backgroundColor: isCategoryFound ? category.color : 'transparent',
        };

        return (
          <div className="hint-category" key={index} style={categoryStyle}>
            <div className="category-name">{isCategoryFound ? category.category : `Category ${index + 1}`}</div>
            {!isCategoryFound && !hintsUsed[index].bigHint && (
              <div className="hints-buttons">
                <button onClick={() => handleHintClick(index, 'big')}>Big Hint</button>
                <button onClick={() => handleHintClick(index, 'small')}>Small Hint</button>
              </div>
            )}
            {isCategoryFound && (
              <div className="found-items">
                <p>Category Found!</p>
                <p>{category.items.join(", ")}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
    </>
   )}
    {/* Reset game button */}
    <button onClick={handleResetGame} className="reset-game-button">
      Reset Game
    </button>
    </div>
  );
};


export default ConnectionsGame;