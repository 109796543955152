import { useCallback, useEffect, useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { useAppContext } from "../../contexts/AppContext";

const API_URL = process.env.REACT_APP_MULTIPLAYER_API_URL;

export const useQueryRank = () => {
  const { userId } = useUserContext();
  const { sportId } = useAppContext();
  const [rank, setRank] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchRank = useCallback(async () => {
    if (!userId) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${API_URL}/user/rank?user=${userId}${
          sportId ? `&sport=${sportId}` : ""
        }`
      );
      const data = await response.json();

      setRank(data.rank);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }, [sportId, userId]);

  useEffect(() => {
    fetchRank();
  }, [fetchRank]);

  return {
    rank,
    isLoading,
    error,
    fetchRank,
  };
};
