import React from "react";
import Tile from "../Tile";
import Colors from "../../../../Colors";
import useTheme from "@mui/material/styles/useTheme";

export default ({ text, color, borderColor, data, wrapperStyle }) => {
  const theme = useTheme();
  return (
    <Tile
      text={text}
      data={data}
      wrapperStyle={{ padding: "0px 3px", ...(wrapperStyle || {}) }}
      textStyle={data ? { fontSize: "12px" } : undefined}
      tileStyle={{
        border: "2px solid " + Colors.DARK_GRAY,
        borderColor: borderColor || color || Colors.DARK_GRAY,
        backgroundColor: color,
        justifyContent: data ? "space-evenly" : "center",
        borderRadius: theme.shape.borderRadius, // Rounded corners
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
      }}
      isActive={color && true}
    />
  );
};
